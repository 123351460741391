import React, {
	useEffect,
	useState
} from 'react'
import { Form } from "react-bootstrap"
import { ARE_COMMON } from "../../../../utils/textsContent"
import { FilteredFieldTypeForChangeKey } from "../../../../utils/helpers/FilteredFieldTypeForChangeKey"
import { ARR_KEYS_TEMPLATE_CONTENT } from "../../../../utils/constants"
import { useDispatch } from 'react-redux'
import { resetDateChangeUnitTemplatesList } from '../../../store/slices/temlateContentSlice'
import { useTranslation } from 'react-i18next'


const arrFieldsType = [
	{
		value: 1,
		name: `${ARE_COMMON?.INT}`
	},
	{
		value: 2,
		name: `${ARE_COMMON?.SELECT}`
	},
	{
		value: 3,
		name: `${ARE_COMMON?.RADIO}`
	},
	{
		value: 4,
		name: `${ARE_COMMON?.STRING}`
	},
	{
		value: 5,
		name: `${ARE_COMMON?.BOOL}`
	},
	{
		value: 6,
		name: `${ARE_COMMON?.DIMENSIONS}`
	},
	{
		value: 7,
		name: `${ARE_COMMON?.ADDRESS}`
	},
	{
		value: 8,
		name: `${ARE_COMMON?.IMAGE}`
	},
	{
		value: 9,
		name: `${ARE_COMMON?.TITLE}`
	},
	{
		value: 11,
		name: 'Date(timestamp)'
	},
	{
		value: 12,
		name: 'Date range from - to'
	},
	{
		value: 101,
		name: 'Time range from - to'
	},
	{
		value: 13,
		name: 'Range from - to'
	}
]

const TypeBlockTemplateContent = ({
	handleChange,
	formDateUpdateHandler,
	form,
	setForm,
	edit,
	setChangeItemsArr
}) => {
	const [changeKeyValue, setChangeKeyValue] = useState('content')
	const [showListFieldTypeAfterChangeKey, setShowListFieldTypeAfterChangeKey] = useState([])
	const {t} = useTranslation()

	const dispatch = useDispatch()

	useEffect(() => {
		const res = FilteredFieldTypeForChangeKey(arrFieldsType, changeKeyValue)
		setShowListFieldTypeAfterChangeKey(res)
	}, [changeKeyValue])

	useEffect(() => {
		showListFieldTypeAfterChangeKey?.length === 1 && formDateUpdateHandler({type: Number(showListFieldTypeAfterChangeKey?.[0]?.value)})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showListFieldTypeAfterChangeKey])

	return (
		<div className='template-content_typeBlock'>
			<h2 className='template-content_typeBlock-title'>
				2. {t('tf_choose_fieldType')}
			</h2 >
			<Form.Group className='template-content_typeBlock-label'>
				<p >
					Key
				</p >
				<select
					aria-label='Default select example'
					className='template-content_typeBlock-select'
					name='key'
					defaultValue={form?.key}
					onChange={(e) => {
						// handleChange(e)
						formDateUpdateHandler({
							[e.target.name]: e.target.value
						})
						setChangeKeyValue(e.target.value)
						dispatch(resetDateChangeUnitTemplatesList())
					}}
				>
					{
						ARR_KEYS_TEMPLATE_CONTENT?.map((item, index) => (
								<option
									key={item + index}
									value={item}
								>
									{item}
								</option >
							)
						)
					}
				</select >
			</Form.Group >
			<Form.Group className='template-content_typeBlock-label_key'>
				<p >
					Type
				</p >
				<select
					aria-label='Default select example'
					className='template-content_typeBlock-select'
					name='changeType'
					value={form?.type}
					onChange={(e) => {
						handleChange(e)
						formDateUpdateHandler({
							type: Number(e.target.value)
						})
						// dispatch(resetDateChangeUnitTemplatesList())
					}}
					// disabled={edit || showListFieldTypeAfterChangeKey?.length === 1}
				>
				{
					showListFieldTypeAfterChangeKey
					&&
					showListFieldTypeAfterChangeKey?.map(item => (
						<option
							key={item?.value}
							value={item?.value}
						>
							{item?.name}
						</option >
					))
				}
				</select >

			</Form.Group >
			<div style={{display: "flex", gap: "20px"}}>
				<Form.Group className='template-content_typeBlock-checkbox'>
					<Form.Check
						type='checkbox'
						name='required'
						id='required'
						onKeyDown={e => {
							e.key === 'Enter' && e.preventDefault()
						}}
						className='template-content_typeBlock-checkboxInp'
						checked={form?.required}
						onChange={(e) => {
							handleChange(e)
							formDateUpdateHandler({
								[e.target.name]: e.target.checked
							})
						}}
					/>
					<label htmlFor='required'>
						{t('tf_checkbox_necessary')}
					</label >
				</Form.Group >
				<Form.Group className='template-content_typeBlock-checkbox'>
					<Form.Check
						type='checkbox'
						name='required'
						id='required'
						onKeyDown={e => {
							e.key === 'Enter' && e.preventDefault()
						}}
						className='template-content_typeBlock-checkboxInp'
						checked={form?.defaultSort}
						onChange={(e) => {
							setForm(prev => {
								return {
									...prev,
									defaultSort: e.target.checked
								}
							})
						}}
					/>
					<label htmlFor='required'>
						{t('tf_checkbox_sort')}
					</label >
				</Form.Group >
			</div>
		</div >
	)
}

export default TypeBlockTemplateContent