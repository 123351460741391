import { createSlice } from "@reduxjs/toolkit"
import { authApi } from "../apis/authApi"
import { usersApi } from "../apis/usersApi"

const initialState = {
	user: {},
	token: null,
	isLogin: false,
	status: null,
	isFirstLogin: false,
	permissions: [],
	lang: false
}

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		logout: () => initialState,
		setFirstLogin: (state, action) => {
			state.isFirstLogin = action.payload
		},
		setLang: (state, action) => {
			state.lang = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(authApi.endpoints.login.matchFulfilled, (
					state,
					action
				) => {
					state.token = action.payload.token
					state.user = action.payload.user
					state.isLogin = true
				}
			)
			.addMatcher(usersApi.endpoints.getPermissions.matchFulfilled, (
				state,
				action
			) => {
				state.permissions = action.payload.permissions
				state.user = action.payload.userData
				state.user.role = action.payload.role
			}
			)
	}
})

const {
	actions,
	reducer
} = userSlice
export const {
	logout,
	setFirstLogin,
	setLang
} = actions
export default reducer
