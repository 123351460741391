import React, { useState } from "react";
import s from "./feedbackStatistics.module.scss";
import { FeedbackItem } from "./feedbackItem/feedbackItem";
import google_play_icon from "../../../../../../utils/icons/google_play_icon.png";
import app_store_icon from "../../../../../../utils/icons/app_store_icon.png";
import PaginationCustom from "../../../../../components/PaginationCustom";
import { ReactComponent as LoadingIcon } from "../../../../../../utils/icons/loading-icon.svg";
import { t } from "i18next";

export const FeedbackStatistics = ({ data_google, data_apple, isLoading }) => {
  const reviews_view = { all: [] };
  const count_in_page = 6;

  const [starsButtons, setStarsButtons] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);

  data_google?.reviews?.forEach((el, index) => {
    const comment_date = parseInt(
      `${el.comments[0].userComment.lastModified.seconds}000`
    );
    if (!reviews_view[el.comments[0].userComment.starRating]?.length) {
      reviews_view[el.comments[0].userComment.starRating] = [];
    }
    reviews_view[el.comments[0].userComment.starRating].push(
      <FeedbackItem
        key={index}
        title={el.authorName}
        comment={el.comments[0].userComment.text}
        rating={el.comments[0].userComment.starRating}
        date={comment_date}
        icon={google_play_icon}
      />
    );
    reviews_view["all"].push(
      <FeedbackItem
        key={index + 1}
        title={el.authorName}
        comment={el.comments[0].userComment.text}
        rating={el.comments[0].userComment.starRating}
        date={comment_date}
        icon={google_play_icon}
      />
    );
  });

  data_apple?.data?.forEach((el, index) => {
    const comment_date = new Date(el.attributes.createdDate).getTime();
    if (!reviews_view[el.attributes.rating]) {
      reviews_view[el.attributes.rating] = [];
    }
    reviews_view[el.attributes.rating].push(
      <FeedbackItem
        key={el.id}
        title={el.attributes.reviewerNickname}
        comment={el.attributes.body}
        rating={el.attributes.rating}
        date={comment_date}
        icon={app_store_icon}
      />
    );
    reviews_view["all"].push(
      <FeedbackItem
        key={el.id}
        title={el.attributes.reviewerNickname}
        comment={el.attributes.body}
        rating={el.attributes.rating}
        date={comment_date}
        icon={app_store_icon}
      />
    );
  });

  for (let key in reviews_view) {
    reviews_view[key].sort((a, b) => b.props.date - a.props.date);
  }

  const buttons_view = Object.keys(reviews_view)
    .reverse()
    .map((el, index) => {
      let stars = t('rating');
      if (parseInt(el) === 1) stars = t('rating');
      if (parseInt(el) === 5) stars = t('rating');
      if (el === "all") stars = "";
      return (
        <div
          key={index}
          className={`${s.star_row} ${
            starsButtons === el ? s.active_star_row : ""
          }`}
          onClick={() => {
            setStarsButtons(el);
            setCurrentPage(1);
          }}
        >
          <p>{`${el === "all" ? t('all') : stars + el}`}</p>
          <span>{reviews_view[el].length}</span>
        </div>
      );
    });

  return (
    <div className={s.feedback_wrapper}>
      <div className={s.left}>{buttons_view}</div>
      <div
        className={s.right}
        style={{
          paddingBottom: reviews_view["all"].length > 6 ? 70 : 15,
          minHeight: reviews_view["all"].length > 6 ? 610 : 540,
        }}
      >
        {isLoading ? (
          <div className={s.loading_wrapper}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            {reviews_view[starsButtons].slice(
              (currentPage - 1) * count_in_page,
              (currentPage - 1) * count_in_page + count_in_page
            )}
            <div className={s.pagination}>
              {reviews_view["all"].length > 6 && (
                <PaginationCustom
                  itemsCount={count_in_page}
                  counterAllItems={reviews_view[starsButtons].length}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
