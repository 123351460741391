import React from 'react';
import s from './mediaModal.module.scss';
import MediaFilesPage from '../../pages/MediaFiles/MediaFilesPage';

export const MediaModal = ({close})=>{

    return(
        <div className={s.modal_wrapper}>
            <div className={s.modal}>
            <MediaFilesPage isModal={true} close={close}/>
            </div>
        </div>
    )
}