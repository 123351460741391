import React, {
	useEffect,
	useState
} from 'react'
import addPlus from "../../../utils/icons/addPlus.svg"
import SubscriptionsTab from "./Tabs/SubscriptionsTab"
import AdditionalFunctionsTab from "./Tabs/AdditionalFunctionsTab"
import AddEditSubscription from "../../components/modalsAddEdit/AddEditSubscription"
import { useSelector } from "react-redux"
import {
	useGetSubscriptionMutation,
	useGetSubscriptionPackageMutation
} from "../../store/apis/subscriptionApi"
import DeleteModal from "../../components/DeleteModal";
import AlertModal from "../../components/AlertModal";
import AddEditSubscriptionPackage from "../../components/modalsAddEdit/AddEditSubscriptionPackage";
import { PAGES_NAME } from "../../../utils/textsContent";

const SubscriptionsPage = () => {
	const { subscription, packagePrices } = useSelector( state => state.subscriptionStore )
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ tabSwitcher, setTabSwitcher ] = useState( 1 )
	const [ deleteSubscription, setDeleteSubscription ] = useState( {} )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ openEdit, setOpenEdit ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const [ getSubscription, { isLoading: isGetSubscriptionLoading } ] = useGetSubscriptionMutation()
	const [ getSubscriptionPackage, { isLoading: isGetSubscriptionPackageLoading } ] = useGetSubscriptionPackageMutation()

	useEffect( () => {
		const getSubscriptionList = async () => {
			await getSubscription()
			await getSubscriptionPackage()
		}
		getSubscriptionList()
	}, [getSubscriptionPackage, getSubscription] )

	const showModalHandler = () => {
		setAddEditModalShow( true )
		setOpenEdit( false )
	}

	const hideModalHandler = () => {
		setAddEditModalShow( false )
		setOpenEdit( false )
	}

	const toggleButtonsType = ( name, anchor ) => {
		const allButtonsType = document.querySelectorAll( '.terms-wrapper_search-types_button' )
		for ( let i = 0; i < allButtonsType.length; i++ ) {
			if ( allButtonsType[ i ].innerText === name ) {
				allButtonsType[ i ].classList.add( 'term-types_active' )
				setTabSwitcher( anchor )
			} else {
				allButtonsType[ i ].classList.remove( 'term-types_active' )
			}
		}
	}

	const toggleDeleteModal = ( value ) => {
		setDeleteSubscription( value )
		setDeleteModalShow( !deleteModalShow )
	}

	const openEditHandler = ( value ) => {
		showModalHandler()
		setOpenEdit( true )
		setEditDateForModal( value )
	}

	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}

	return (
		<div className='terms'>
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ deleteSubscription }
				fromPage={ tabSwitcher === 1 ? 'subscription' : 'package' }
				returnErrorForDel={ returnErrorForDel }
			/>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			{
				addEditModalShow
					?
					tabSwitcher === 1
						?
						<AddEditSubscription
							onHide={ hideModalHandler }
							edit={ openEdit }
							editDateForModal={ editDateForModal }
						/>
						:
						<AddEditSubscriptionPackage
							onHide={ hideModalHandler }
							edit={ openEdit }
							editDateForModal={ editDateForModal }
						/>
					:
					<>
						<h1 className='terms-title'>
							{PAGES_NAME?.SUBSCRIPTIONS}
						</h1 >
						<div className='terms-wrapper'>
							<div className='terms-wrapper_search-types'>
								<button
									type='button'
									className='terms-wrapper_search-types_button term-types_active'
									onClick={ () => {
										toggleButtonsType( `${PAGES_NAME?.SUBSCRIPTIONS}`, 1 )
									} }
								>
									{PAGES_NAME?.SUBSCRIPTIONS}
								</button >
								<button
									type='button'
									className='terms-wrapper_search-types_button'
									onClick={ () => {
										toggleButtonsType( 'Додаткові функції до підписок', 2 )
									} }
								>
									Додаткові функції до підписок
								</button >
							</div >
							<div className='subscription'>
								{
									tabSwitcher === 1
									&&
									<>
									<SubscriptionsTab
										subscription={ subscription }
										isGetSubscriptionLoading={ isGetSubscriptionLoading }
										deleteSubscriptionItemHandler={ toggleDeleteModal }
										openEditHandler={ openEditHandler }
										edit={ openEdit }
									/>
									<div className='subscription-button_wrapper'>
										<button
											className='terms-wrapper_addButton'
											style={ { whiteSpace: 'nowrap', width: '300px' } }
											type='button'
											onClick={ showModalHandler }
										>
											<img
												src={ addPlus }
												alt='Add Plus'
											/>
											Створити новий Пакет
										</button >
									</div>
									</>
								}
								{
									tabSwitcher === 2
									&&
									<AdditionalFunctionsTab
										packageArr={ packagePrices }
										isGetSubscriptionPackageLoading={ isGetSubscriptionPackageLoading }
										openEditHandler={ openEditHandler }
										deleteSubscriptionItemHandler={ toggleDeleteModal }
										edit={ openEdit }
									/>
								}
							</div >
						</div >
					</>
			}
  </div >
	)
}
export default SubscriptionsPage