import { createSlice } from "@reduxjs/toolkit"
import { subscriptionApi } from "../apis/subscriptionApi"
import { UniqueArrItems } from "../../../utils/helpers/UniqueArrItems"

const initialState = {
	subscription: [],
	packageArr: [],
	packagePrices: [],
	showModalChangeSubscription: false,
	dateModalChangeSubscription: []
}

const subscriptionSlice = createSlice( {
	name: "subscription",
	initialState,
	reducers: {
		setSubscriptionList: ( state, action ) => {
			state.subscription = action.payload
		},
		createNewSubscriptionItem: ( state, action ) => {
			state.subscription = [ action.payload, ...state.subscription ]
		},
		createNewSubscriptionPackageItem: ( state, action ) => {
			state.packageArr = [ action.payload, ...state.packageArr ]
		},
		editSubscriptionItem: ( state, action ) => {
			const index = state.subscription.findIndex(
				( term ) => {
					return term.id === action.payload.id
				}
			)
			state.subscription[index] = action.payload
		},
		editSubscriptionPackageItem: ( state, action ) => {
			const index = state.subscription.findIndex(
				( term ) => {
					return term.id === action.payload.id
				}
			)
			state.packageArr[index] = action.payload
		},
		deleteSubscriptionItem: ( state, action ) => {
			const termId = action.payload
			const termID = state.subscription.filter( term => term.id !== termId )
			state.subscription = termID
		},
		deleteSubscriptionPackageItem: ( state, action ) => {
			const termId = action.payload
			const termID = state.packageArr.filter( term => term.id !== termId )
			state.packageArr = termID
		},
		showChangeSubscription: ( state, action ) => {
			state.showModalChangeSubscription = action.payload
		},
		setDateChangeSubscription: ( state, action ) => {
			const uniq = UniqueArrItems( [ ...state.dateModalChangeSubscription, action.payload ] )
			state.dateModalChangeSubscription = uniq
		},
		resetDateChangeSubscription: ( state, action ) => {
			state.dateModalChangeSubscription = []
		},
		deleteDateChangeSubscriptionArr: ( state, action ) => {
			const Id = Number( action.payload )
			const ID = state.dateModalChangeSubscription.filter( item => Number( item ) !== Id )
			state.dateModalChangeSubscription = ID
		},
	},
	extraReducers: ( builder ) => {
		builder
			.addMatcher( subscriptionApi.endpoints.getSubscription.matchFulfilled, (
					state,
					action
				) => {
					state.subscription = action.payload.data
					state.packagePrices = action.payload.packagePrices
				}
			)
			.addMatcher( subscriptionApi.endpoints.getSubscriptionPackage.matchFulfilled, (
					state,
					action
				) => {
					state.packageArr = action.payload.data
				}
			)
	}
} )

const {
	actions,
	reducer
} = subscriptionSlice
export const {
	setSubscriptionList,
	createNewSubscriptionItem,
	deleteSubscriptionItem,
	editSubscriptionItem,
	showChangeSubscription,
	setDateChangeSubscription,
	resetDateChangeSubscription,
	deleteDateChangeSubscriptionArr,
	setSubscriptionPackageList,
	createNewSubscriptionPackageItem,
	deleteSubscriptionPackageItem,
	// editSubscriptionPackageItem,
	// showChangeSubscriptionPackage,
	// setDateChangeSubscriptionPackage,
	// resetDateChangeSubscriptionPackage,
	// deleteDateChangeSubscriptionPackageArr
} = actions
export default reducer
