import React, {
	useEffect,
	useState
} from 'react'
import {
	useDispatch,
	useSelector
} from "react-redux"
import AlertModal from "../../components/AlertModal"
import AddEditUnitGroupsItem from "../../components/modalsAddEdit/AddEditUnitGroupsItem"
import DeleteModal from "../../components/DeleteModal"
import ControlPanels from "../../components/environmentTabl/ControlPanels"
import { resetDateChangeUnitGroups } from "../../store/slices/unitGroupsSlice"
import { useTranslation } from 'react-i18next'

const UnitGroupPage = () => {
	const { unitGroups } = useSelector( ( state ) => state.unitGroupsStore )

	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ openEditModal, setOpenEditModal ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )
	const [ unitGroupsListArr, setUnitGroupsListArr ] = useState( [] )
	const [ errorMessage, setErrorMessage ] = useState( '' )

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const data = useSelector(store => store.unitGroupsStore.unitGroups)

	useEffect( () => {
		const getUnitGroupList = async () => {
			setUnitGroupsListArr( data?.unitgroups )
		}
		getUnitGroupList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ addEditModalShow, deleteModalShow ] )

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
		setOpenEditModal( false )
	}
	const hideModalHandler = () => {
		setAddEditModalShow( false )
		setOpenEditModal( false )
		setEditDateForModal( {} )
		dispatch( resetDateChangeUnitGroups() )
	}
	const toggleDeleteModal = ( value ) => {
		setDeleteTerm( value )
		setDeleteModalShow( !deleteModalShow )
	}

	const openEditModalHandler = ( value ) => {
		setEditDateForModal( value )
		setAddEditModalShow( true )
		setOpenEditModal( true )
	}

	useEffect( () => {
		setUnitGroupsListArr( unitGroups )
	}, [ unitGroups ] )

	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}

	return (
		<div className='terms'>
			{
				errorMessage && <AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<AddEditUnitGroupsItem
				show={ addEditModalShow }
				onHide={ hideModalHandler }
				edit={ openEditModal }
				editDateForModal={ editDateForModal }
			/>
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ deleteTerm }
				fromPage={ 'unitgroups' }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				returnErrorForDel={ returnErrorForDel }
			/>
			<h1 className='terms-title'>
				{t('_c_groupsUnitsMeasurement')}
			</h1 >
			<ControlPanels
				bigArrElements={ unitGroupsListArr }
				showModalHandler={ showModalHandler }
				openEditModalHandler={ openEditModalHandler }
				toggleDeleteModal={ toggleDeleteModal }
				fromPage={ 'unitGroups' }
				searchTermValue={ searchTermValue }
				loaderSorting={ loaderSorting }
				setLoaderSorting={ setLoaderSorting }
				toggleModalSearch={ toggleModalSearch }
				showModalSearch={ showModalSearch }
				showSearchBeforeDel={ showSearchBeforeDel }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				valueSearchInputHandler={ valueSearchInputHandler }
			/>
		</div >
	)
}

export default UnitGroupPage