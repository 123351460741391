import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	MEDIA_API,
	NOTIFICATION_API,
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const notificationApi = createApi( {
	reducerPath: "notificationApi",
	tagTypes: ['Sender', 'Notification', 'Media', 'EmailTemplate'],
	baseQuery: fetchBaseQuery( {
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{ getState }
		) => {
			const { token } = getState().userStore;
			if ( token ) {
				headers.set( "Authorization", `Bearer ${ token }` )
			}
			return headers
		}
	} ),
	endpoints: ( builder ) => (
		{
			getNotifications: builder.mutation( {
				query: ( { limit, page, date, title, email, sort, senderType } ) => (
					{
						url: `
					${ NOTIFICATION_API.GET_NOTIFICATIONS }?
					${ limit }
					${ page }
					${ date }
					${ title }
					${ email }
					${sort}
					${senderType}
					`,
						method: "GET",
					}
				)
			} ),
			getNotification: builder.query( {
				query: (id) => (
					{
						url: `${NOTIFICATION_API.GET_NOTIFICATION}?id=${id}`,
						method: "GET",
					}
				),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Notification', id })),
					{ type: 'Notification', id: 'LIST' },
					]
				: [{ type: 'Notification', id: 'LIST' }],
			} ),
			getNotificationStatistics: builder.query( {
				query: ({id, page, limit}) => (
					{
						url: `${NOTIFICATION_API.GET_NOTIFICATION_STATISTICS}?id=${id}&page=${page}&limit=${limit}`,
						method: "GET",
					}
				),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Notification', id })),
					{ type: 'Notification', id: 'LIST' },
					]
				: [{ type: 'Notification', id: 'LIST' }],
			} ),
			restoreNotification: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ NOTIFICATION_API.RESTORE_NOTIFICATION }`,
						method: "POST",
						body
					}
				)
			} ),
			promoteNotification: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ NOTIFICATION_API.PROMOTE_NOTIFICATION }`,
						method: "POST",
						body
					}
				)
			} ),
			searchNotification: builder.mutation( {
				query: ( search ) => (
					{
						url: `${ NOTIFICATION_API.SEARCH_NOTIFICATION }?search=${ search }`,
						method: "GET",
					}
				)
			} ),
			deleteNotification: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ NOTIFICATION_API.DELETE_NOTIFICATION }`,
						method: "POST",
						body
					}
				)
			} ),
			createNotification: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ NOTIFICATION_API.CREATE_NOTIFICATION }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Notification', id: 'LIST' }],
			} ),
			createEmailNotificationSender: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ NOTIFICATION_API.CREATE_EMAIL_NOTIFICATION_SENDER }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Sender', id: 'LIST' }],
			} ),

			getEmailNotificationSender: builder.query( {
				query: () => (
					{
						url: `${ NOTIFICATION_API.GET_EMAIL_NOTIFICATION_SENDERS }`,
						method: "GET"
					}
				),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Sender', id })),
					{ type: 'Sender', id: 'LIST' },
					]
				: [{ type: 'Sender', id: 'LIST' }],
			} ),

			updateEmailNotificationSender: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ NOTIFICATION_API.UPDATE_EMAIL_NOTIFICATION_SENDER }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Sender', id: 'LIST' }],
			} ),

			deleteEmailNotificationSender: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ NOTIFICATION_API.DELETE_EMAIL_NOTIFICATION_SENDER }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Sender', id: 'LIST' }],
			} ),

			getEmailTemplate: builder.query( {
				query: ( ) => (
					{
						url: `${ NOTIFICATION_API.GET_EMAIL_NOTIFICATION_TEMPLATE }`,
						method: "GET"
					}
				)
			} ),

			getNotificationsMedia: builder.query( {
				query: ({currentPage, countShow}) => (
					{
						url: `${ NOTIFICATION_API.GET_NOTIFICATIONS_MEDIA }?
						page=${currentPage}&
						limit=${countShow}
						`,
						method: "GET"
					}
				),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Media', id })),
					{ type: 'Media', id: 'LIST' },
					]
				: [{ type: 'Media', id: 'LIST' }],
			} ),

			uploadNotificationImage: builder.mutation( {
				query: ( body ) => (
					{
						url: MEDIA_API.ADD_IMAGE,
						method: "POST",
						headers: {
							"Access-Control-Allow-Origin": "*",
						},
						body: body
					}
				),
				invalidatesTags: [{ type: 'Media', id: 'LIST' }],
			} ),

			deleteNotificationImage: builder.mutation( {
				query: ( body ) => (
					{
						url: MEDIA_API.DELETE_IMAGE,
						method: "POST",
						headers: {
							"Access-Control-Allow-Origin": "*",
						},
						body: body
					}
				),
				invalidatesTags: [{ type: 'Media', id: 'LIST' }],
			} ),

			getEmailTemplates: builder.query( {
				query: ({currentPage, countShow, id}) => (
					{
						url: `${ NOTIFICATION_API.GET_EMAIL_TEMPLATES }?
						page=${currentPage}&
						limit=${countShow}&
						${id}
						`,
						method: "GET"
					}
				),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'EmailTemplate', id })),
					{ type: 'EmailTemplate', id: 'LIST' },
					]
				: [{ type: 'EmailTemplate', id: 'LIST' }],
			} ),

			getEmailTemplateV2: builder.query( {
				query: () => (
					{
						url: `${ NOTIFICATION_API.GET_EMAIL_TEMPLATE }`,
						method: "GET"
					}
				)
			} ),

			editEmailTemplates: builder.mutation( {
				query: ( body ) => (
					{
						url: NOTIFICATION_API.EDIT_EMAIL_TEMPLATES,
						method: "POST",
						headers: {
							"Access-Control-Allow-Origin": "*",
						},
						body: body
					}
				),
				invalidatesTags: [{ type: 'EmailTemplate', id: 'LIST' }],
			} ),
			createEmailTemplate: builder.mutation( {
				query: ( body ) => (
					{
						url: NOTIFICATION_API.CREATE_EMAIL_TEMPLATES,
						method: "POST",
						headers: {
							"Access-Control-Allow-Origin": "*",
						},
						body: body
					}
				),
				invalidatesTags: [{ type: 'EmailTemplate', id: 'LIST' }],
			} ),

			migrationEmailTemplate: builder.mutation( {
				query: ( body ) => (
					{
						url: NOTIFICATION_API.MIGRATION_EMAIL_TEMPLATES,
						method: "POST",
						headers: {
							"Access-Control-Allow-Origin": "*",
						},
						body: body
					}
				),
				invalidatesTags: [{ type: 'EmailTemplate', id: 'LIST' }],
			} ),
		}
	)
} )

export const {
	useGetNotificationsMutation,
	useGetNotificationQuery,
	useRestoreNotificationMutation,
	usePromoteNotificationMutation,
	useSearchNotificationMutation,
	useDeleteNotificationMutation,
	useCreateNotificationMutation,
	useCreateEmailNotificationSenderMutation,
	useGetEmailNotificationSenderQuery,
	useUpdateEmailNotificationSenderMutation,
	useDeleteEmailNotificationSenderMutation,
	useGetNotificationStatisticsQuery,
	useGetEmailTemplateQuery,
	useGetNotificationsMediaQuery,
	useUploadNotificationImageMutation,
	useDeleteNotificationImageMutation,
	useGetEmailTemplatesQuery,
	useEditEmailTemplatesMutation,
	useCreateEmailTemplateMutation,
	useGetEmailTemplateV2Query,
	useMigrationEmailTemplateMutation
} = notificationApi