import React from 'react';
import s from './pieChartComponent.module.scss';
import { Cell, Pie, PieChart } from 'recharts';
import {ReactComponent as LoadingIcon} from'../../../../../../utils/icons/loading-icon.svg'
import { useTranslation } from 'react-i18next';
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red'];

export const PieChartComponent = ({data, isLoading})=>{
    
    const { t } = useTranslation()
    let categories_gh_data = []
    
    if(data){
        categories_gh_data = Object.entries(data)?.map(el => {
            return {name: el[0], value: el[1].length, color: 'blue'}
        })
    }
    categories_gh_data.sort((a, b) => b.value - a.value);

    let full_column = 0
    categories_gh_data.forEach(el => {
        full_column = full_column + el.value;
    })

    const category_cellses = categories_gh_data?.map((el, index) => {
        return <Cell key={`cell-${index}`} fill={COLORS[index]} />
    })
    
    const categories_view = categories_gh_data.map((el, index) => {
        return <p key={index}>{`${el.name} - `}<span style={{color: COLORS[index]}}>{`${((el.value / full_column) * 100).toFixed(0)}%`}</span></p>
    })

    return(
        <div className={s.chart_wrapper}>
            <p className={s.title}>{t('d_pc')}</p>
            {isLoading? 
            <div className={s.loading_wrapper}>
                <LoadingIcon/>
            </div>
            :
            <>
                <PieChart width={300} height={300}>
                    <Pie
                        data={categories_gh_data}
                        innerRadius={50}
                        outerRadius={100}
                        paddingAngle={0}
                        dataKey="value"
                        >
                        {category_cellses}
                    </Pie>
                </PieChart>
                <div className={s.right}>
                    {categories_view}
                </div>
            </>}
        </div>
    )
}