import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import { useSelector } from "react-redux"
import DeleteModal from "../../components/DeleteModal"
import ControlPanels from "../../components/environmentTabl/ControlPanels"
import { useGetHtmlsMutation } from "../../store/apis/htmlsApi"
import AddEditHtmlsItem from "../../components/modalsAddEdit/AddEditHtmlsItem"
import AlertModal from "../../components/AlertModal"

const HtmlsPage = () => {
	const { htmls } = useSelector( ( state ) => state.htmlsStore )
	const [ getHtmls, { isLoading: isHtmlsLoading } ] = useGetHtmlsMutation()

	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ openEditModal, setOpenEditModal ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )
	const [ errorMessage, setErrorMessage ] = useState( '' )

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const getAllHtmls = useCallback( async () => {
		try {
			await getHtmls()
		} catch ( e ) {
			console.log( e )
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [] )

	useEffect( () => {
		getAllHtmls()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [] )
	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
		setOpenEditModal( false )
	}
	const hideModalHandler = () => {
		setAddEditModalShow( false )
		setOpenEditModal( false )
		setEditDateForModal( {} )
	}
	const toggleDeleteModal = ( value ) => {
		setDeleteTerm( value )
		setDeleteModalShow( !deleteModalShow )
	}

	const openEditModalHandler = ( value ) => {
		setEditDateForModal( value )
		setAddEditModalShow( true )
		setOpenEditModal( true )
	}
	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}
	return (
		<div className='terms'>
			{
				errorMessage && <AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<AddEditHtmlsItem
				show={ addEditModalShow }
				onHide={ hideModalHandler }
				edit={ openEditModal }
				editDateForModal={ editDateForModal }
			/>
		<DeleteModal
			show={ deleteModalShow }
			onHide={ toggleDeleteModal }
			value={ deleteTerm }
			fromPage={ 'htmls' }
			setShowSearchBeforeDel={ setShowSearchBeforeDel }
			returnErrorForDel={ returnErrorForDel }
		/>
		<h1 className='terms-title'>
			HTMLS
		</h1 >
		<ControlPanels
			bigArrElements={ htmls }
			showModalHandler={ showModalHandler }
			isLoading={ isHtmlsLoading }
			openEditModalHandler={ openEditModalHandler }
			toggleDeleteModal={ toggleDeleteModal }
			fromPage={ 'htmls' }
			searchTermValue={ searchTermValue }
			loaderSorting={ loaderSorting }
			setLoaderSorting={ setLoaderSorting }
			toggleModalSearch={ toggleModalSearch }
			showModalSearch={ showModalSearch }
			showSearchBeforeDel={ showSearchBeforeDel }
			setShowSearchBeforeDel={ setShowSearchBeforeDel }
			valueSearchInputHandler={ valueSearchInputHandler }
		/>
	</div >
	)
}
export default HtmlsPage
