import { createSlice } from "@reduxjs/toolkit"
import { noticeApi } from "../apis/noticeApi"

const initialState = {
	notice: [],
	getAnswersObj: {},
	getAnswersArr: [],
	searchNoticesForUser: [],
	noticesListForSlideItem : [],
	toggleForNextPrevPage: 0,
	filterData: {},
	nextPage: null,
	prevPage: null
}

const noticeSlice = createSlice({
	name: "notice",
	initialState,
	reducers: {
		setAnswersType: (state, action) => {
			state.getAnswersObj = action.payload
		},
		setFilterOptions: (state, action) => {
			state.filterData = action.payload
		},
		setNoticesList: (state, action) => {
			state.noticesListForSlideItem = action.payload
		},
		toggleForNextPrevPageHandler: (state, action) => {
			state.toggleForNextPrevPage = action.payload
		},
		deleteAnswerItem: (state, action) => {
			const answerId = action.payload
			const answerID = state.getAnswersArr.filter(answer => answer.id !== answerId)
			state.getAnswersArr = answerID
		},
		setNextPage: (state, action) => {
			state.nextPage = action.payload
		},
		setPrevPage: (state, action) => {
			state.prevPage = action.payload
		},
	},
	// extraReducers: (builder) => {
		// builder
			// .addMatcher(noticeApi.endpoints.getNotice.matchFulfilled, (
			// 		state,
			// 		action
			// 	) => {state.notice = action.payload.adverts}
			// )
			// .addMatcher(noticeApi.endpoints.getV2Notice.matchFulfilled, (
			// 		state,
			// 		action
			// 	) => {state.notice = action.payload.adverts}
			// )
			// .addMatcher(noticeApi.endpoints.searchNoticeForAuthor.matchFulfilled, (
			// 		state,
			// 		action
			// 	) => {state.searchNoticesForUser = action.payload.adverts}
			// )
			// .addMatcher(noticeApi.endpoints.getAnswer.matchFulfilled, (
			// 		state,
			// 		action
			// 	) => {state.getAnswersArr = action.payload.data}
			// )
	// }
})

const {
	actions,
	reducer
} = noticeSlice
export const {
	createNewNoticeItem,
	setAnswersType,
	deleteAnswerItem,
	setNoticesList,
	toggleForNextPrevPageHandler,
	setFilterOptions,
	setPrevPage,
	setNextPage
} = actions
export default reducer
