import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	RULES_API,
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const rulesApi = createApi({
	reducerPath: "rulesApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getRules: builder.mutation({
			query: () => (
				{
					url: RULES_API.GET_RULES,
					method: "GET",
				})
		}),
		createRules: builder.mutation({
			query: (body) => (
				{
					url: RULES_API.CREATE_RULES,
					method: "POST",
					body
				})
		}),
	})
});

export const {
	useGetRulesMutation,
	useCreateRulesMutation,
} = rulesApi
