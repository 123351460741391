import React, { useEffect, useState } from 'react';
import s from './createDealsMessages.module.scss';
import AlertModal from '../../../components/AlertModal';
import { useCreateDealsButtonMutation, useGetDealsButtonMutation, useUpdateDealsButtonMutation } from '../../../store/apis/dealsApi';
import { TextFieldsBlock } from '../../../components/environmentTabl/TextFieldsBlock/TextFieldsBlock';
import Loader from '../../../components/LoaderContent';
import LoaderIcon from '../../../components/Loader'
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const initialState = {
    title: {},
    type: 1,
    color: 1
}

export const CreateDealsMessages = () => {

    const {t} = useTranslation()

    const DEALS_BUTTONS_TYPES = [
        {type: 1, title: t('confirm')},
        {type: 2, title: t('reject')},
        {type: 3, title: t('pay')},
        {type: 4, title: t('track_p')},
        {type: 5, title: t('track_t')},
        {type: 6, title: t('add_ttn')},
        {type: 7, title: t('complete_deal')},
        {type: 8, title: t('write_review')},
        {type: 9, title: t('arrival_time')},
        {type: 10, title: t('add_your_price')},
    ]
    
    const DEALS_BUTTONS_COLORS = [
        {type: 1, title: 'Accept'},
        {type: 2, title: 'Cancel'},
        {type: 3, title: 'Info'}
    ]

    const [form, setForm] = useState(initialState)
    const [errorMessage, setErrorMessage] = useState(false)

    const langs = useSelector(store => store.langStore.langs)
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const dealsButtonId = searchParams.get('id')

    const [createButton, {isLoading: isCreateButtonLoading}] = useCreateDealsButtonMutation()
    const [updateButton, {isLoading: isUpdateButtonLoading}] = useUpdateDealsButtonMutation()
    const [getButton, {isLoading: isGetButtonLoading}] = useGetDealsButtonMutation()


    // ### EFFECTS

    useEffect(()=>{
        if(dealsButtonId && dealsButtonId.length){
            getDealsButton(dealsButtonId)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dealsButtonId]);

    async function getDealsButton(id){
        const response = await getButton(id)
        if(response.error){
            setErrorMessage(response.error?.data?.message)
        }
        else{
            const data = response.data[0]
            setForm(prev => {
                return{
                    ...prev,
                    title: {...data?.title?.title, id: data?.title?.id},
                    type: data?.type,
                    color: data?.color,
                    
                }
            })
        }
    }

    function changeName(value){
        setForm(prev => {
            return {...prev, title: {...prev.title, ...value}}
        })
    }

    function changeType(value){
        setForm(prev => {
            return {...prev, type: parseInt(value)}
        })
    }

    function changeColor(value){
        setForm(prev => {
            return {...prev, color: parseInt(value)}
        })
    }

    async function handleSubmit(){
        let error = false
        if(!Object.keys(form.title) || Object.keys(form.title)?.length < langs.length) error = 'Будь ласка, заповнiть "Title"'
        Object.values(form.title)?.forEach(el => {
            if(el.length < 2) error = 'Будь ласка, заповнiть "Title"'
        })

        if(error){
            setErrorMessage(error)
            return
        }

        const data = {
            ...form,
            id: dealsButtonId || undefined,
            // manual: currentManualConditions
        }
        let response = false
        if(dealsButtonId && dealsButtonId.length){
            response = await updateButton(data)
        }else{
            response = await createButton(data)
        }
        if(response.data?.status === 200) navigate('/deals/messages')
        else{
            setErrorMessage(response.error?.data?.message)
        }
    }

    if(isGetButtonLoading) return <Loader/>
    
    return(
        <div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			
			<h1 className='terms-title'>{t('deals_messages_title')}</h1 >
			
            <div className={`${s.message_wrapper} terms-wrapper`}>
                <div className={s.header}>
                    <div className={s.header_item}>
                        <TextFieldsBlock values={form.title} title="Title" changeValue={changeName} />
                    </div>
                    <div className={s.header_item}>
                        <span className={s.label}>Button type</span>
                        <select value={form.type} onChange={(e) => changeType(e.target.value)}>
                            {
                                DEALS_BUTTONS_TYPES.map((el, index) => {
                                    return(
                                        <option value={el.type}>{el.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className={s.header_item}>
                        <span className={s.label}>Button color</span>
                        <select value={form.color} onChange={e => changeColor(e.target.value)}>
                            {
                                DEALS_BUTTONS_COLORS.map(color => {
                                    return <option value={color.type}>{color.title}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <footer className={s.footer}>
                    <button
                        type='submit'
                        className='template-content_buttons-save btn btn-primary'
                        onClick={handleSubmit}
                    >
                            {(isCreateButtonLoading || isUpdateButtonLoading)? <LoaderIcon/> : t('save')}
                    </button >
                    <button
                        type='button'
                        className='template-content_buttons-close btn btn-primary me-3'
                        onClick={()=>navigate('/deals/messages')}
                    >
                        {t('back')}
                    </button >
                </footer>
            </div>
		</div >
    )
}