import React, {
	useEffect,
	useState
} from 'react'
import AlertModal from "../../components/AlertModal"
import {
	useSelector
} from "react-redux"
import {
	PAGES_NAME
} from "../../../utils/textsContent"
import SortingCategoriesTabl from "./SortingCategoriesTabl"
import { useGetCategoryMutation } from "../../store/apis/categoryApi"
import { useTranslation } from 'react-i18next'

const SortingCategoriesPage = () => {
	const [errorMessage, setErrorMessage] = useState('')
	const {category} = useSelector(state => state.categoryStore)
	const [getCategory] = useGetCategoryMutation()
	const {t} = useTranslation()


	useEffect(() => {
		const getCategoriesList = async () => {
			const {error} = await getCategory(`&list=null&orderBy=asc&sortingBy=position&isAll=true`)
			error && setErrorMessage(error?.data?.message)
		}
		getCategoriesList()
	}, [getCategory])

	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<h1 className='terms-title'>
					{t('s_sortcat')}
				</h1 >
				<SortingCategoriesTabl
					category={category}
				/>
		</div >
	)
}

export default SortingCategoriesPage
