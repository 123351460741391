import { Alert } from "react-bootstrap"
import AlertIcon from '../../utils/icons/alert-icon.svg'
import closeIcon from '../../utils/icons/close-classik-icon.svg'

const AlertModalTerms = ({close}) => {
	return (
		<Alert className='alertModal'>
			<img
				src={AlertIcon}
				alt="Alert"
			/>
			<div className="alertModal-block">
				<img
					src={closeIcon}
					alt="Close"
					className='alertModal-block_close'
					onClick={close}
				/>
				<h3 className='alertModal-block_h3'>Увага</h3>
				<p className='alertModal-block_text'>
					Даний термін використовується.
				</p>
			</div>
		</Alert>
	)
}

export default AlertModalTerms
