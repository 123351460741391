import React, { useEffect } from 'react'
import ChangeTemplateContentForTemplateModal from "./ChangeTemplateContentForTemplateModal"
import {
	useSelector,
} from "react-redux"
import { useTranslation } from 'react-i18next'

const ChangeTemplateContentForTemplateFilter = ({
	toggleShowTemplatesContentModal,
	formDateUpdateHandler,
	form,
	edit,
	num,
	handleBlur,
	errors,
	blockListView,
	firstSortViewBlocks,
	setFirstSortViewBlocks,
	blocksData,
	setMainBlockListView,
	setdetailInfoBlockListView,
	setadditionalBlockListView,
	setBlockListView,
	style
}) => {
	const {showModalChangeUnitTemplates} = useSelector(state => state.templateStore)
	
	const {t,i18n} = useTranslation()

	function changeGroupBlock(type, item){
		if(type === "main"){
			setMainBlockListView(prev => [...prev, item])
		}
		if(type === "detail"){
			setdetailInfoBlockListView(prev => [...prev, item])
		}
		if(type === "additional"){
			setadditionalBlockListView(prev => [...prev, item])
		}
		setBlockListView(prev => prev.filter(el => el.id !== item.id))
	}
	
	useEffect(() => {
		if (edit && !firstSortViewBlocks) {
			blockListView?.forEach(item => {
				blocksData.mainBlockView?.forEach(i => {
					if (i === item?.id) {
						setMainBlockListView(prev => [...prev, item])
						setBlockListView(prev => prev.filter(el => el.id !== i))
					}
				})
				blocksData.detailInfoView?.forEach(i => {
					if (i === item?.id) {
						setdetailInfoBlockListView(prev => [...prev, item])
						setBlockListView(prev => prev.filter(el => el.id !== i))
					}
				})
				blocksData.transportInfoView?.forEach(i => {
					if (i === item?.id) {
						setadditionalBlockListView(prev => [...prev, item])
						setBlockListView(prev => prev.filter(el => el.id !== i))
					}
				})
			})
			setFirstSortViewBlocks(true)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='template-content_category' style={style}>
			{
				showModalChangeUnitTemplates && <ChangeTemplateContentForTemplateModal changedItems={form.content}/>
			}
			<h2 className='template-content_category-title'>
      {t('c_satf')} View
    </h2 >
    <div className='template-content_category-body'>
      <ul className='template-content_valueBlock-list'>
				{
					blockListView && blockListView?.map((item, index) => (
						<li
							className='template-content_valueBlock-list_li'
							key={item?.id}
						>
							<>
								<span className='me-2'>{index + 1}. </span >
								<div className='template-content_valueBlock-list_li-text'>
									<div className='template-content_valueBlock-list_li-wrapper'>
										<span >{item?.name?.[i18n.language] || item?.title?.[i18n.language]}</span >
										<div className='template-content_valueBlock-list_li-buttons'>
											<p >{t('c_addTo')}</p >
											<button
												type='button'
												className='template-content_valueBlock-list_li-buttons_btn'
												onClick={() => changeGroupBlock('main', item)}
											>
												{t('c_mainBlock')}
											</button >
											<button
												type='button'
												className='template-content_valueBlock-list_li-buttons_btn'
												onClick={() => changeGroupBlock('detail', item)}
											>
												{t('c_detailed_information')}
											</button >
											
											{/* <button
												type='button'
												className='template-content_valueBlock-list_li-buttons_btn'
												onClick={() => changeGroupBlock('additional', item)}
											>
												Додаткового блоку
											</button > */}
											
										</div >
									</div >

								</div >
							</>
						</li >
					))
				}
      </ul >
    </div >
  </div >
	)
}

export default ChangeTemplateContentForTemplateFilter