import * as yup from "yup"

export const addSubscriptionSchema = () => {
	return yup.object().shape({
		subscriptionNameUa: yup
			.string()
			.required('Заголовок обов\'язковий!'),
		subscriptionNameEn: yup
			.string()
			.required('Title is required!'),
		subscriptionNameRu: yup
			.string()
			.required('Заголовок является обязательным!'),
		subscriptionNamePL: yup
			.string()
			.required('Tytuł jest wymagany!'),
	})
}

export const addSubscription1Schema = () => {
	return yup.object().shape({
		uaSubscriptionName: yup
			.string()
			.required('Заголовок обов\'язковий!'),
		enSubscriptionName: yup
			.string()
			.required('Title is required!'),
		ruSubscriptionName: yup
			.string()
			.required('Заголовок является обязательным!'),
		plSubscriptionName: yup
			.string()
			.required('Tytuł jest wymagany!'),
	})
}
