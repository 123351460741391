import { Table } from "react-bootstrap"
import ArrowSorting from '../../../utils/icons/arrow-sorting.svg'
import UsersTablItem from "./UsersTablItem"
import { ARE_COMMON } from "../../../utils/textsContent";
import { useDispatch } from "react-redux";
import { removeDateChangeUsersForAddressBookArr, setDateChangeUsersForAddressBookArr } from "../../store/slices/advertisingSlice";
import { useTranslation } from "react-i18next";

const UsersTabl = ( {
	sortedForColumn,
	countShowTerms,
	showSmallTermArr,
	counterAllItems,
	fromPage,
	isActive
} ) => {
	
	const dispatch = useDispatch()
	const { t } = useTranslation()
	
	function changeAll(event){
		if(event){
			showSmallTermArr.forEach(el => {
				dispatch( setDateChangeUsersForAddressBookArr( el ))
			})
		}
		else{
			showSmallTermArr.forEach(el => {
				dispatch( removeDateChangeUsersForAddressBookArr( el?.id ))
			})
		}
	}

	return (
		<div style={{opacity: isActive? 1 : 0.5}}>
			<div className='terms-wrapper_itemsInfo' >
					<span className='terms-wrapper_itemsInfo-content'>
						Showing { counterAllItems < countShowTerms? counterAllItems : countShowTerms } items out of { counterAllItems ? counterAllItems : '?' }
					</span >
			</div >
			<Table
				responsive
				className='users-wrapper_tabl'
				striped={ true }
			>
				<thead className='users-wrapper_tabl-head'>
				<tr className='users-wrapper_tabl-trow'>
					{
						fromPage === 'changeUserForAdvertising' || fromPage === 'users'
							?
							<th
								className='users-wrapper_tabl-trow_item col-1 id-size'
							>
								ID
							</th>
							:
							<th
								className='users-wrapper_tabl-trow_item col-1 id-size'
							>
						<input
							type={'checkbox'}
							name='check'
							defaultChecked={false}
							onKeyDown={e => {
								e.key === 'Enter' && e.preventDefault()
							}}
							disabled={!isActive}
							onChange={(e) => changeAll(e.target.checked)}
							className='users-wrapper_tabl-body_item-wrapper_input'
						/>
						<span >
							{t('select')}
						</span >
					</th >
					}
					<th
						className={`users-wrapper_tabl-trow_item col-1 login-size`}
						onClick={() => sortedForColumn('pib')}
					>
						<span >
							{t('user')}
						</span >
						<img
							src={ArrowSorting}
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item col-1 login-size'
						onClick={() => sortedForColumn('email')}
					>
						<span >
							{t('email')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1 login-size'
						onClick={ () => sortedForColumn( 'login' ) }
					>
						<span >
							{t('login')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item col-1 phone-size'
						onClick={ () => sortedForColumn( 'phone' ) }
					>
						<span >
							{t('phone')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1 id-size'
						onClick={ () => sortedForColumn( 'adverts' ) }
					>
						<span >
							{t('advertisements')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1 id-size'
						onClick={ () => sortedForColumn( 'deals' ) }
					>
						<span >
							{t('deals')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1 phone-size'
						onClick={ () => sortedForColumn( 'dateRegiter' ) }
					>
						<span >
							{t('registration')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1 id-size'
						onClick={ () => sortedForColumn( 'status' ) }
					>
						<span >
							{t('status')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1 id-size'
						onClick={() => sortedForColumn('verification')}
					>
						<span >
							{t('verification')}
						</span >
					<img
						src={ ArrowSorting }
						alt='Arrow Sorting'
					/>
					</th >
				</tr >
				</thead >
				<tbody
					className='users-wrapper_tabl-body'
				>
				{
					showSmallTermArr && showSmallTermArr?.map( item => <UsersTablItem
						key={ item.id }
						item={ item }
						fromPage={ fromPage }
						isActive={isActive}
					/> )
				}
				</tbody >
			</Table >
		</div>
	)
}

export default UsersTabl
