import React from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as WarningIcon } from "../../../../utils/icons/warning-icon.svg"
import s from './selectForm.module.scss'
import { useTranslation } from 'react-i18next'

const SelectForm = ({title, name, arr, formDateUpdateHandler, refLink, defaultValue, validation}) => {
	const navigate = useNavigate()
	const {t} = useTranslation()

	return (
		<Form.Group className='template-content_typeBlock-label w-100'>
			<p >
				{title}{!validation? validation === 0 ? <span className={s.validation} onClick={()=>navigate("/notifications/validation")}><WarningIcon/>{t('ns_not_valid')} <span className={s.validation_link}>Validation</span></span> : null : null }
			</p >
			<select
				aria-label='Default select example'
				className='template-content_typeBlock-select'
				name={name}
				ref={refLink}
				defaultValue={defaultValue}
				onChange={ ( e ) => formDateUpdateHandler( { [ e.target.name ]: e?.target.value } ) }
			>
				{
					arr && arr?.map( item => (
						<option
							key={item?.id}
							value={ item?.id }
						>
							{ item?.name }
						</option >
					) )
				}
			</select >
		</Form.Group >
	)
}

export default SelectForm