export const ARE_COMMON = {
	SHOWN: 'Показано',
	POINTS_FROM: 'пунктів з',
	VALUE: 'Значення',
	VERSION: 'Версія',
	ID: 'ID',
	NAME: 'Назва',
	SHOW_RECORDS: 'Показати записів:',
	ADD: 'Додати',
	SUBCATEGORY: 'Підкатегорія',
	STATUS: 'Статус',
	SYSTEM_GROUP: 'Системна група',
	MARK: 'Мітка',
	FIELD_TYPE: 'Тип поля',
	ALL: 'Всі',
	INT: 'Int',
	SELECT: 'Select',
	RADIO: 'Radio',
	BOOL: 'Bool',
	STRING: 'String',
	DIMENSIONS: 'Int*Int*Int',
	ADDRESS: 'Address',
	IMAGE: 'Media',
	TITLE: 'Title',
	PREVIEW: 'Перегляд',
	ON_MODERATION: 'На модерації',
	DOCS: 'Docs',
	DIYA: 'Diya',
	CANCEL: 'Скасувати',
	SAVE: 'Зберегти',
	CATEGORY: 'Категорія',
	CREATED: 'Створення',
	APPLY: 'Застосувати',
	CAST: 'Скинути',
	KEYS: 'Ключі',
	ABOLITION: 'Відміна',
	UNIT: 'Одиниця виміру',
	REMOVAL: 'Удаление',
	YOU_WANT_DELETE: 'Do you want to delete',
	SEARCH: 'Пошук',
	CREATE: 'Створення',
	EDITING: 'Редагування',
	BACK: 'Назад',
	ATTACHMENT: 'вкладення',
	SAVE_AND_BACK: 'Зберегти і вийти',
	ENTER: 'Вхід',
	INTERFACE: 'Інтерфейс',
	EMAIL: 'Електронна пошта',
	DEALS_NOTIFICATION: 'Сповіщення про пропозиції',
	SYSTEM: 'Система',
	ERROR: 'Помилка',
	NOTFILLED: 'Не заповненi',
	MIN_PRICE: 'Мінімальна ціна',
	MAX_PRICE: 'Максимальна ціна',
	STATEMENT: 'Виписки',
	METHODS: 'Методи оплат',
	MEMBER_ID: 'ID користувача',
	MEMBER_TYPE: 'Напрям платежу користувача'
}
export const INPUTS_TEXT = {
	NAME_EN: 'Name EN',
	NAME_UA: 'Назва UA',
	NAME_RU: 'Название RU',
	NAME_PL: 'Nazwa PL',

	SUBSCRIPTION_EN: 'Name subscription EN',
	SUBSCRIPTION_UA: 'Назва subscription UA',
	SUBSCRIPTION_RU: 'Название subscription RU',
	SUBSCRIPTION_PL: 'Nazwa subscription PL',

	TITLE_RU: 'Заголовок RU',
	TITLE_EN: 'Title EN',
	TITLE_UA: 'Заголовок UA',
	TITLE_PL: 'Tytuł PL',

	DESCRIPTION_RU: 'Description RU',
	DESCRIPTION_EN: 'Description EN',
	DESCRIPTION_UA: 'Description UA',
	DESCRIPTION_PL: 'Description PL',

	PLACEHOLDER_RU: 'Placeholder RU',
	PLACEHOLDER_EN: 'Placeholder EN',
	PLACEHOLDER_UA: 'Placeholder UA',
	PLACEHOLDER_PL: 'Placeholder PL',

	NAME: 'Назва',
	LINK: 'Посилання'
}

export const PAGES_NAME = {
	DASHBOARD: 'Дашборд',
	STATISTIC: 'Статистика',
	USERS: 'Користувачі',
	LIST: 'Список',
	DEALS: 'Угоди',
	DEALS_MESSAGES: 'Повiдомлення по угодам',
	DEALS_MESSAGES_TEMPLATES: 'Шаблони повiдомлень',
	ADVERTS: 'Оголошення',
	MARKETING: 'Маркетинг',
	ADVERTISING: 'Реклама',
	ADVERTISER: 'Рекламні компанії',
	NOTIFICATIONS: 'Усі розсилки',
	ADDRESS_BOOK: 'Адресна книга',
	VALIDATION: 'Валiдацiя',
	NOTIFICATIONS_SENDER_LIST: 'Вiдправники',
	MAILINGS: 'Розсилки',
	POLL: 'Опитування',
	SUBSCRIPTIONS: 'Підписки',
	FINANCES: 'Фінанси',
	TRANSACTIONS: 'Транзакції',
	MODERATION_PAYMENT: 'Модерація оплат',
	CONTENT: 'Контент',
	TEMPLATE: 'Шаблони',
	TEMPLATE_CONTENT: 'Поля шаблонів',
	TERMS: 'Терміни',
	CATEGORIES: 'Категорії',
	SUBCATEGORIES: 'Підкатегорії',
	SORTING_CATEGORIES: 'Сортування Категорії',
	DELIVERY: 'Доставка',
	MEDIA: 'Медiа-файли',
	PRICES: 'Ціни',
	UNITS_OF_MEASUREMENT: 'Одиниці виміру',
	GROUPS_UNITS_OF_MEASUREMENT: 'Групи одиниць виміру',
	EMAIL_TEMPLATES: 'Шаблони Email',
	AMOUNT: 'Amount',
	НTMLS: 'НTMLS',
	LANGUAGES: 'Мови',
	PAGES: 'PAGES',
	TECHNICAL_BLOCK: 'Техблок',
	VERSIONS: 'Версії',
	FAQ: 'FAQ',
	REFERRAL_LINKS: 'Реферальні силки',
	RBAC: 'RBAC'
}
