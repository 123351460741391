import { RoutesLink, RoutesLinksLogin } from "../routes"
import { AuthContext } from "../utils/context/auth.context"
import { useDispatch, useSelector } from "react-redux"
import { useLoginMutation } from "./store/apis/authApi"
import Layout from "./Layout/AppLayout"
import { useEffect } from "react"
import { setLang } from "./store/slices/userSlice"

function App() {
	const {
			user,
			token,
			// isLogin
	} = useSelector(state => state.userStore)
	const dispatch = useDispatch()
	const logout = () => dispatch(logout())
	const [login] = useLoginMutation()
	const isAuthenticated = Boolean(token)

	useEffect(()=>{
		dispatch(setLang(localStorage.getItem('i18nextLng')))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[localStorage.getItem('i18nextLng')]);

	return (
		<div className="App">
			<AuthContext.Provider value={
					{
						token,
						login,
						logout,
						user,
						isAuthenticated
					}
				}
			>
				{
					isAuthenticated && token
						?
						<Layout>
							<RoutesLink/>
						</Layout>
						:
						<RoutesLinksLogin />
				}
			</AuthContext.Provider>

		</div>
	)
}

export default App
