import React, { useState} from 'react'
import { useSelector } from "react-redux"
import ControlPanelsForUsers from "../../components/environmentTabl/ControlPanelsForUsers"
import AlertModal from "../../components/AlertModal"
import { PAGES_NAME } from "../../../utils/textsContent"
import { useTranslation } from 'react-i18next'

const UsersPage = () => {
	const { users } = useSelector( state => state.usersStore )

	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const { t } = useTranslation()

	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<h1 className='terms-title'>
				{t('users')}
			</h1 >
			<ControlPanelsForUsers
				bigArrElements={users}
				fromPage={ 'users' }
				toggleModalSearch={ toggleModalSearch }
				showModalSearch={ showModalSearch }
				showSearchBeforeDel={ showSearchBeforeDel }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				setErrorMessage={ setErrorMessage }
			/>
		</div >
	)
}


export default UsersPage


