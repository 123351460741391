import {
	Button,
	Form,
	Modal
} from "react-bootstrap"
import React, {
	useEffect,
	useState
} from "react"
import {
	useCreateTermsMutation,
	useEditTermsMutation
} from "../../store/apis/termsApi"
import Loader from "../Loader"
import { useDispatch } from "react-redux"
import {
	createNewTerm,
	editTermItem
} from "../../store/slices/termsSlice"
import AlertModal from "../AlertModal";
import {
	ARE_COMMON
} from "../../../utils/textsContent";
import BlockWithTranslationFieldsTerms from "../BlockWithTranslationFieldsTerms";
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const AddEditTermsItem = ({
	show,
	onHide,
	edit,
	editDateForModal
}) => {
	const [form, setForm] = useState({title: {}})
	const [errorMessage, setErrorMessage] = useState('')
	const [createTerms, {isLoading: isCreateTerms}] = useCreateTermsMutation()
	const [editTerms, {isLoading: isEditTerms}] = useEditTermsMutation()
	const dispatch = useDispatch()

	const isLoading = isCreateTerms || isEditTerms
	const langs = useSelector(store => store.langStore.langs)
	const {t} = useTranslation()

	useEffect(() => {
		const titles = {}
		if(edit){
			Object.entries(editDateForModal.title).forEach(el => {
				titles[`${el[0]}Title`] = el[1]
			})
			formDateUpdateHandler(titles)
		}
		edit && setForm({...editDateForModal, values: {...titles}})
		if(!edit){
			setForm({title: {}})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit])

	const formDateUpdateHandler = (opt) => setForm({...form, ...opt})

	useEffect(()=>{
		console.log("form", form);
	},[form]);

	const handleSubmit = async (values) => {
		const formDate = {
			title: {},
			psevdo: form.psevdo?.trim(),
			// editable: values.editable,
			systemGroup: form?.systemGroup
		}
		langs.forEach(ln => {
			formDate.title[ln.langIndex] = form.title?.[`${ln.langIndex}`]	
		})
		try {
			if (edit) {
				form.psevdo?.trim() === editDateForModal?.psevdo?.trim() && delete formDate?.psevdo
				const {error} = await editTerms({
					...formDate,
					id: editDateForModal.id
				})
				!error && dispatch(editTermItem({
					...formDate,
					id: editDateForModal.id
				}))
				!error && await updateDB()
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()
			} else {
				const {data, error} = await createTerms(formDate)
				error && setErrorMessage(error?.data?.message)
				!error && dispatch(createNewTerm({
					...formDate,
					id: data?.id
				}))
				!error && await updateDB()
				!error && clearFormHideModal()
			}
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}
	}

	const clearFormHideModal = () => {
		onHide()
		setForm({title: {}})
	}

	const updateDB = async () => {
		// const dataTerms = await getTerms()
		// await db.terms.put( {
		// 	id: 'terms',
		// 	terms: dataTerms.data.data
		// } )
	}
	

	return (
		<Modal
			show={show}
			onHide={clearFormHideModal}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className='add-edit-terms_modal'
			style={{ zIndex: '99999' }}
		>
			{
				errorMessage && <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}	
			<Modal.Header
				closeButton
			>
				<Modal.Title
					id="contained-modal-title-vcenter"
					className='add-edit-terms_title'
				>
					{edit ? t('ct_edit_title') : t('ct_add_title')}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					className="add-edit-terms"
					onSubmit={handleSubmit}
				>
					<div className="add-edit-terms_top">
						<BlockWithTranslationFieldsTerms
							values={form.title}
							setForm={setForm}
							name={'Title'}
							title={'title'}
							langs={langs}
							touched={false}
							errors={false}
							handleChange={()=>{}}
						/>
					</div>
					<Form.Group
						className="add-edit-terms_center"
					>
						Mark
						<Form.Control
							type="text"
							name="psevdo"
							className="add-edit-terms_center-inp"
							onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
							value={form.psevdo}
							onChange={(e) => {
								// handleChange(e)
								formDateUpdateHandler({
									[e.target.name]: e.target.value
								})
							}}
						/>
					</Form.Group>
					<Form.Group
						className='add-edit-terms_center'
					>
						{ t('ct_label_sysgroup') }
						<select
							className=' is-touch form-select mt-1'
							value={form?.systemGroup}
							name={'systemGroup'}
							onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
							onChange={(e) => {
								// handleChange(e)
								formDateUpdateHandler({
									[e.target.name]: e.target.value
								})
							}}
						>
							<option >
							</option >
							<option value='interface'>
								interface
							</option >
							<option value='select'>
								select
							</option >
							<option value='email'>
								email
							</option >
							<option value='deals_notification'>
								deals notification
							</option >
							<option value='system'>
								system
							</option >
							<option value='error'>
								error
							</option >
						</select >
					</Form.Group >
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button
					type='button'
					className='add-edit-terms_close'
					onClick={clearFormHideModal}
				>
					{t('cancel')}
				</Button>
				<Button
					type='submit'
					className='add-edit-terms_save d-inline-flex'
					style={{
						maxHeight: '33px',
						display: 'flex',
						alignItems: 'center'
					}}
					onClick={handleSubmit}
				>
					{
						isLoading ? <Loader/> : t('save')
					}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default AddEditTermsItem
