import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	USERS_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const usersApi = createApi( {
	reducerPath: "usersApi",
	tagTypes: ['User', 'Message'],
	baseQuery: fetchBaseQuery( {
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{ getState }
		) => {
			const { token } = getState().userStore;
			if ( token ) {
				headers.set( "Authorization", `Bearer ${ token }` )
			}
			return headers
		}
	} ),
	endpoints: ( builder ) => ({
		getUsers: builder.mutation( {
			query: ( { status, subscription, search, sort, order, page, limit } ) => (
				{
					url: `${ USERS_API.GET_USERS }?
					${ page }
					${ limit }
					${ status ? status : '' }
					${ subscription ? subscription : '' }
					${ search ? search : '' }
					${ sort ? sort : '' }
					${ order ? order : '' }
					`,
					method: "GET",
				}),
				providesTags: (result = false) =>
					result.data
					? [
						...result.data.map(({ id }) => ({ type: 'User', id })),
						{ type: 'User', id: 'LIST' },
						]
					: [{ type: 'User', id: 'LIST' }],
		} ),
		getUser: builder.query( {
			query: ( id ) => (
				{
					url: `${ USERS_API.GET_USER }?id=${id}`,
					method: "GET",
				}),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'User', id })),
					{ type: 'User', id: 'LIST' },
					]
				: [{ type: 'User', id: 'LIST' }],
		} ),
		blockUser: builder.mutation( {
			query: ( body ) => (
				{
					url: `${ USERS_API.BLOCK_USER }`,
					method: "POST",
					body
				}),
				invalidatesTags: [{ type: 'User', id: 'LIST' }]
		} ),
		unblockUser: builder.mutation( {
			query: ( body ) => (
				{
					url: `${ USERS_API.UNBLOCK_USER }`,
					method: "POST",
					body
				}),
				invalidatesTags: [{ type: 'User', id: 'LIST' }]
		} ),
		getDeals: builder.mutation( {
			query: ( { page, limit, status, category , userId, sort, order, dateRange  } ) => (
				{
					url: `${ USERS_API.DEALS_USER }?
					${ page }
					${ limit }
					${ status ? status : '' }
					${ category  ? category  : '' }
					${ userId  ? userId  : '' }
					${ sort ? sort : '' }
					${ order  ? order  : '' }
					${ dateRange  ? dateRange  : ''}
					`,
					method: "GET",
				})
		} ),
		postVerificationVerdict: builder.mutation( {
			query: ( body ) => (
				{
					url: `${ USERS_API.VERIFICATION_VERDICT }`,
					method: "POST",
					body
				}),
				invalidatesTags: [{ type: 'User', id: 'LIST' }]
		} ),
		getVerification: builder.mutation( {
			query: ( {id} ) => (
				{
					url: `${ USERS_API.GET_VERIFICATION }?${id}`,
					method: "GET",
				})
		} ),
		getPermissions: builder.mutation( {
			query: () => (
				{
					url: USERS_API.GET_PERMISSIONS,
					method: "GET",
				})
		} ),
		getAllPermissions: builder.query( {
			query: () => (
				{
					url: USERS_API.GET_PERMISSIONS_ALL,
					method: "GET",
				})
		} ),
		getOnlineUsers: builder.query( {
			query: () => (
				{
					url: USERS_API.GET_ONLINE_USERS,
					method: "GET",
				})
		} ),
		getVerificationMessages: builder.query( {
			query: () => (
				{
					url: USERS_API.GET_VERIFICATION_MESSAGES,
					method: "GET",
				}),
				providesTags: (result = false) =>
					result.data
					? [
						...result.data.map(({ id }) => ({ type: 'Message', id })),
						{ type: 'Message', id: 'LIST' },
						]
					: [{ type: 'Message', id: 'LIST' }],
		} ),
		createVerivicationMessage: builder.mutation( {
			query: (body) => (
				{
					url: USERS_API.CREATE_VERIFICATION_MESSAGES,
					method: "POST",
					body: body
				}),
				invalidatesTags: [{ type: 'Message', id: 'LIST' }]
		} ),
		updateVerivicationMessage: builder.mutation( {
			query: (body) => (
				{
					url: USERS_API.UPDATE_VERIFICATION_MESSAGES,
					method: "POST",
					body: body
				}),
				invalidatesTags: [{ type: 'Message', id: 'LIST' }]
		} ),
		deleteVerivicationMessage: builder.mutation( {
			query: (body) => (
				{
					url: USERS_API.DELETE_VERIFICATION_MESSAGES,
					method: "POST",
					body: body
				}),
				invalidatesTags: [{ type: 'Message', id: 'LIST' }]
		} ),
		verdictVerificationPassport: builder.mutation( {
			query: (body) => (
				{
					url: USERS_API.VERDICT_VERIFICATION_PASSPORT,
					method: "POST",
					body: body
				}),
				invalidatesTags: [{ type: 'User', id: 'LIST' }]
		} ),
		verdictVerificationDriverLicense: builder.mutation( {
			query: (body) => (
				{
					url: USERS_API.VERDICT_VERIFICATION_DRIVER_LICENSE,
					method: "POST",
					body: body
				}),
				invalidatesTags: [{ type: 'User', id: 'LIST' }]
		} ),

		getUserDeals: builder.query( {
			query: ( { page, limit, status, category , userId, sort, order, dateRange  } ) => (
				{
					url: `${ USERS_API.DEALS_USER }?
					${ page? "page="+page : '' }
					${ limit? "&limit="+limit : '' }
					${ status ? "&status="+status : '' }
					${ category  ? "&category="+category  : '' }
					${ userId  ? "&userId="+userId  : '' }
					${ sort ? "&sort="+sort : '' }
					${ order  ? "&order="+order  : '' }
					${ dateRange  ? "&dateRange="+dateRange  : ''}
					`,
					method: "GET",
				})
		} ),

		getWarnings: builder.mutation( {
			query: () => ({
				url: USERS_API.GET_WARNINGS,
				method: "GET",
			})
		} )
	})
} )

export const {
	useGetUsersMutation,
	useGetUserQuery,
	useBlockUserMutation,
	useUnblockUserMutation,
	useGetDealsMutation,
	usePostVerificationVerdictMutation,
	useGetVerificationMutation,
	useGetPermissionsMutation,
	useGetAllPermissionsQuery,
	useGetOnlineUsersQuery,
	useGetVerificationMessagesQuery,
	useCreateVerivicationMessageMutation,
	useUpdateVerivicationMessageMutation,
	useDeleteVerivicationMessageMutation,
	useVerdictVerificationPassportMutation,
	useVerdictVerificationDriverLicenseMutation,
	useGetWarningsMutation,
	useGetUserDealsQuery
} = usersApi
