import AlertInfo from '../../../utils/icons/info-moderation.svg'
import CopyIcon from '../../../utils/icons/copy-icon.svg'
import {
	useLocation,
	useNavigate
} from "react-router-dom"
import {useEffect, useState} from "react"
import ProofOfPaymentModal from "../../components/ProofOfPaymentModal"
import { ARE_COMMON } from "../../../utils/textsContent"
import {statusSwitchHelper} from "../../../utils/helpers/VerificationAndStatusSwitch";

const ModerationPaymentItemPage = () => {
	const [ showProofPaymentModal, setShowProofPaymentModal ] = useState( false )
	const navigation = useNavigate()
	const { state } = useLocation()
	const [ statusSwitch, setStatusSwitch ] = useState( null )
	const handleCloseProofPaymentModal = () => setShowProofPaymentModal( false )
	const handleShowProofPaymentModal = () => setShowProofPaymentModal( true )

	useEffect( () => {
		setStatusSwitch( statusSwitchHelper( state?.item?.status ) )
	}, [ state ] )

	return (
		<div className='terms'>
			<ProofOfPaymentModal
				show={ showProofPaymentModal }
				handleClose={ handleCloseProofPaymentModal }
			/>
			<h1 className='terms-title'>
				{ `Ордер ${ ARE_COMMON?.ID } ${ state?.item.order_id }` }
			</h1 >
			<div className='moderation-payment_block'>
				<div className='moderation-payment_block-content'>
					<div className='moderation-payment_block-content_wrapper'>
						<h2 className='moderation-payment_block-content_wrapper-title'>
							Деталі ордера
						</h2 >
						<ul className='moderation-payment_block-content_wrapper-ul'>
							<li className='moderation-payment_block-content_wrapper-ul_li'>
								<p className='moderation-payment_block-content_wrapper-ul_li-title'>
									{ `Банк ${ ARE_COMMON?.ID }` }
								</p >
								<p className='moderation-payment_block-content_wrapper-ul_li-value'>
									{state?.item?.id}
								</p >
							</li >
							<li className='moderation-payment_block-content_wrapper-ul_li'>
								<p className='moderation-payment_block-content_wrapper-ul_li-title'>
									Опис ордера
								</p >
								<p className='moderation-payment_block-content_wrapper-ul_li-value'>
									xxxxxxxxxxxxxxxxxxxxxxxx
								</p >
							</li >
							<li className='moderation-payment_block-content_wrapper-ul_li'>
								<p className='moderation-payment_block-content_wrapper-ul_li-title'>
									Сума транзакції
								</p >
								<p className='moderation-payment_block-content_wrapper-ul_li-value'>
									{state?.item?.amount}
								</p >
							</li >
							<li className='moderation-payment_block-content_wrapper-ul_li'>
								<p className='moderation-payment_block-content_wrapper-ul_li-title'>
									Надіслано
								</p >
								<p className='moderation-payment_block-content_wrapper-ul_li-value'>
									{state?.item?.amount}
								</p >
							</li >
							<li className='moderation-payment_block-content_wrapper-ul_li'>
								<p className='moderation-payment_block-content_wrapper-ul_li-title'>
									Отримано
								</p >
								<p className='moderation-payment_block-content_wrapper-ul_li-value'>
									{state?.item?.transferred_amount}
								</p >
							</li >
							<li className='moderation-payment_block-content_wrapper-ul_li'>
								<p className='moderation-payment_block-content_wrapper-ul_li-title'>
									Комісія
								</p >
								<p className='moderation-payment_block-content_wrapper-ul_li-value'>
									{state?.item?.commission}
								</p >
							</li >
							<li className='moderation-payment_block-content_wrapper-ul_li'>
								<p className='moderation-payment_block-content_wrapper-ul_li-title'>
									Тип транзакції
								</p >
								<p className='moderation-payment_block-content_wrapper-ul_li-value'>
									xxxxxxxxx
								</p >
							</li >
							<li className='moderation-payment_block-content_wrapper-ul_li'>
								<p className='moderation-payment_block-content_wrapper-ul_li-title'>
									{ARE_COMMON?.STATUS}
								</p >
								<p
									style={ {
										backgroundColor: `${ statusSwitch?.bg }`,
										color: `${ statusSwitch?.color }`,
										padding: "5px"
									} }
								>{ statusSwitch?.title }</p >
							</li >
						</ul >

						<div className='moderation-payment_block-content_wrapper-alert'>
							<div className='moderation-payment_block-content_wrapper-alert_img'>
								<img
									src={ AlertInfo }
									alt='alert icon'
									className='moderation-payment_block-content_wrapper-alert_icon'
								/>
							</div >
							<div className='moderation-payment_block-content_wrapper-alert_content'>
								<h3 className='moderation-payment_block-content_wrapper-alert_content-title'>
									{ statusSwitch?.title }
								</h3 >
								<p className='moderation-payment_block-content_wrapper-alert_content-text'>
									{statusSwitch?.description}
								</p >
							</div >
						</div >

						<ul className='moderation-payment_block-content_wrapper-ul'>
								<li className='moderation-payment_block-content_wrapper-ul_li'>
									<p className='moderation-payment_block-content_wrapper-ul_li-title'>
										{ARE_COMMON?.CREATED}
									</p >
									<p className='moderation-payment_block-content_wrapper-ul_li-value'>
										xxxxxxxxx
									</p >
								</li >
								<li className='moderation-payment_block-content_wrapper-ul_li'>
									<p className='moderation-payment_block-content_wrapper-ul_li-title'>
										Адреса для оплати
									</p >
									<p className='moderation-payment_block-content_wrapper-ul_li-value'>
										xxxxxxxxxxxxxxxxxxxxxxxx
										<img
											src={ CopyIcon }
											alt='Copy'
										/>
									</p >
								</li >
								<li className='moderation-payment_block-content_wrapper-ul_li'>
									<p className='moderation-payment_block-content_wrapper-ul_li-title'>
										Адреса виплати
									</p >
									<p className='moderation-payment_block-content_wrapper-ul_li-value'>
										xxxxxxxxxxxxxxxxxxxxxxxx
										<img
											src={ CopyIcon }
											alt='Copy'
										/>
									</p >
								</li >
								<li className='moderation-payment_block-content_wrapper-ul_li'>
									<p className='moderation-payment_block-content_wrapper-ul_li-title'>
										Payin hash
									</p >
									<p className='moderation-payment_block-content_wrapper-ul_li-value'>
										xxxxxxxxxxxxxxxxxxxxxxxx
										<img
											src={ CopyIcon }
											alt='Copy'
										/>
									</p >
								</li >
								<li className='moderation-payment_block-content_wrapper-ul_li'>
									<p className='moderation-payment_block-content_wrapper-ul_li-title'>
										Payout hash
									</p >
									<p className='moderation-payment_block-content_wrapper-ul_li-value'>
										xxxxxxxxxxxxxxxxxxxxxxxx
										<img
											src={ CopyIcon }
											alt='Copy'
										/>
									</p >
								</li >
								<li className='moderation-payment_block-content_wrapper-ul_li'>
									<p className='moderation-payment_block-content_wrapper-ul_li-title'>
										Фіксований тариф
									</p >
									<p className='moderation-payment_block-content_wrapper-ul_li-value'>
										{state?.item?.sandbox ? "Так" : "Ні" }
									</p >
								</li >
								<li className='moderation-payment_block-content_wrapper-ul_li'>
									<p className='moderation-payment_block-content_wrapper-ul_li-title'>
										Плата, сплачена користувачем
									</p >
									<p className='moderation-payment_block-content_wrapper-ul_li-value'>
										{state?.item?.payment_acquired ? "Так" : "Ні"}
									</p >
								</li >
								<li className='moderation-payment_block-content_wrapper-ul_li'>
									<p className='moderation-payment_block-content_wrapper-ul_li-title'>
										Плата за обслуговування
									</p >
									<p className='moderation-payment_block-content_wrapper-ul_li-value'>
										{state?.item?.commission}
									</p >
								</li >
						</ul >
					</div >
				</div >

				{!state.isTransactionsPage && <div className='moderation-payment_block-buttons'>
					<button
						className="moderation-payment_block-buttons_but" onClick={ handleShowProofPaymentModal }
						disabled={!statusSwitch?.available}
					>
						Підтвердити оплату <b >{state?.item?.transferred_amount} {state?.item?.currency} </b >
					</button >
					<button className="moderation-payment_block-buttons_but" disabled={!statusSwitch?.available}>
						Підтвердити повернення <b >{state?.item?.refund_amount} {state?.item?.currency} </b >
					</button >
					<button
						className='moderation-payment_block-buttons_but'
						onClick={ () => navigation( -1 ) }
					>
						{ARE_COMMON?.BACK}
					</button >
				</div >}
			</div >
		</div >
	)
}
export default ModerationPaymentItemPage