import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const DashbordDateComponents = ({arrList, formDateUpdateHandler, form, edit, anchorForChangeContentBlock}) => {
	const [checkedIdAllList, setCheckedIdAllList] = useState([])
	const {t} = useTranslation()

	useEffect(() => {
		if (edit) {
			setCheckedIdAllList(arrList)
		}
	}, [form?.specialFunctionality?.dashboardDate, edit, arrList])

	useEffect(() => {
		if (!edit) {
			setCheckedIdAllList(arrList)
		}
		if (anchorForChangeContentBlock === true) {
			setCheckedIdAllList(arrList)
		}
	}, [arrList, edit, anchorForChangeContentBlock])

	const saveCheckedId = (item) => {
		formDateUpdateHandler({
			...form,
			specialFunctionality: {
				...form?.specialFunctionality,
				dashboardDate: Number(item)
			}
		})
	}
	return (
		<div className='template-content_category'>
			<h2 className='template-content_category-title'>
				Date on Card
			</h2 >
				<select
					aria-label='Default select example'
					className='template-content_typeBlock-select poll-tools_center-select'
					name='typeNotification'
					onChange={(e) => saveCheckedId(e.target.value)}
				>
					<option></option>
					{checkedIdAllList?.length > 0 && checkedIdAllList?.map((item, index) => (
						<option
							value={item?.id}
							key={item?.id}
							selected={form?.specialFunctionality?.dashboardDate === item?.id}
						>
							{item?.name?.ua || item?.title?.ua}
						</option >
					))}
				</select >
  </div >
	)
}

export default DashbordDateComponents