import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import AlertModal from '../../components/AlertModal'
import DeleteModal from '../../components/DeleteModal'
import {
	useDispatch,
	useSelector
} from 'react-redux'
import ControlPanelsForAdvertising from '../../components/environmentTabl/ControlPanelsForAdvertising'
import AddEditAdvertisingItem from '../../components/modalsAddEdit/AddEditAdvertisingItem'
import { useGetAdvertisingMutation } from '../../store/apis/advertisingApi'
import { useNavigate } from 'react-router-dom'
import { LogoutUserHandler } from '../../../utils/helpers/LoqoutUserHelper'
import { PAGES_NAME } from "../../../utils/textsContent"

const AdvertisingPage = () => {
	const { advertising } = useSelector( ( state ) => state.advertisingStore )
	const [ getAdvertising, { isLoading: isGetAdvertisingLoading } ] = useGetAdvertisingMutation()
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ openEditModal, setOpenEditModal ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const getAdvertisingList = useCallback(async () => {
		const { error } = await getAdvertising()
		if ( error?.status === 403 && error?.data?.message === 'Incorrect token or expiried' ) LogoutUserHandler( dispatch, navigate )
	},[navigate, dispatch, getAdvertising])

	useEffect( () => {
		// advertising?.length === 0 && getAdvertisingList()
		getAdvertisingList()
	}, [addEditModalShow, getAdvertisingList] )

	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const showModalHandler = ( flag ) => {
		setAddEditModalShow( true )
		setOpenEditModal( false )
	}
	const hideModalHandler = async () => {
		setAddEditModalShow( false )
		setOpenEditModal( false )
		setEditDateForModal( {} )
	}
	const toggleDeleteModal = ( value ) => {
		setDeleteTerm( value )
		setDeleteModalShow( !deleteModalShow )
	}

	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const openEditModalHandler = ( value ) => {
		setEditDateForModal( value )
		setAddEditModalShow( true )
		setOpenEditModal( true )
	}
	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ deleteTerm }
				fromPage={ 'advertising' }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				returnErrorForDel={ returnErrorForDel }
			/>
			{
				addEditModalShow
					?
					<AddEditAdvertisingItem
						onHide={ hideModalHandler }
						edit={ openEditModal }
						editDateForModal={ editDateForModal }
					/>
					:
					<>
						<h1 className='terms-title'>
						{PAGES_NAME?.ADVERTISING}
						</h1 >
						<ControlPanelsForAdvertising
							bigArrElements={ advertising }
							showModalHandler={ showModalHandler }
							fromPage={ 'advertising' }
							isLoading={ isGetAdvertisingLoading }
							toggleDeleteModal={ toggleDeleteModal }
							showSearchBeforeDel={ showSearchBeforeDel }
							openEditModalHandler={ openEditModalHandler }
							searchTermValue={ searchTermValue }
							loaderSorting={ loaderSorting }
							setLoaderSorting={ setLoaderSorting }
							toggleModalSearch={ toggleModalSearch }
							showModalSearch={ showModalSearch }
							setShowSearchBeforeDel={ setShowSearchBeforeDel }
							valueSearchInputHandler={ valueSearchInputHandler }
						/>
					</>
			}
		</div >
	)
}

export default AdvertisingPage;
