export const APP_ROUTE = {
	DEFAULT: '/',
	ENTRY: "*",
	LOGIN: '/login',
}

export const APP_ROUTE_PAGES = {
	STATISTIC: '/statistic',
	USERS: '/users',
	USER: '/user',
	VERIFICATIONS: '/verifications',
	NOTICE: '/adverts',
	NOTICE_DETAIL: '/advert',
	CHATS: '/chats',
	DEALS: '/deals',
	DEALS_MESSAGES: 'deals/messages',
	DEALS_MESSAGES_CREATE: 'deals/messages/create',
	DEALS_MESSAGES_TEMPLATE: 'deals/messages/template',
	DEALS_MESSAGES_TEMPLATE_CREATE: 'deals/messages/template/create',
	ADVERTISING: '/advertising',
	NOTIFICATIONS: '/notifications',
	NOTIFICATION: '/notification',
	NOTIFICATIONS_ADD_EMAIL: 'notifications/add/email',
	NOTIFICATIONS_ADD_VIBER: 'notifications/add/viber',
	NOTIFICATIONS_SENDER_LIST: 'notifications/senderlist',
	NOTIFICATIONS_VALIDATION: 'notifications/validation',
	REFERRAL_LINKS: 'get/all/references',
	REFERRAL_LINK: 'get/reference/data',
	ADDRESS_BOOK: '/address-book',
	NEW_BOOK: '/address-book/new',
	ABOUT_BOOK: '/address-book/book',
	TEMPLATES: '/templates',
	TEMPLATES_CREATE: '/templates/create',
	TEMPLATES_CREATE_V2: '/templates/create/v2',
	TEMPLATES_EDIT: '/templates/edit',
	TEMPLATE_CONTENT: '/template-content',
	TERMS: '/terms',
	TAGS: '/tags',
	CATEGORIES: '/categories',
	SUBCATEGORIES: '/subcategories',
	SORTING_CATEGORIES: '/sorting-categories',
	DISCOUNTS: '/discounts',
	PRICES: '/prices',
	VALUE_OF_PRICES: '/value-prices',
	UNITS_OF_MEASUREMENT: '/units-measurement',
	UNITS_GROUPS: '/unitgroup',
	AMOUNT: '/amount',
	НTMLS: '/htmls',
	PAGES: '/pages',
	DELIVERY: '/delivery',
	DELIVERY_CREATE: '/delivery/create',
	VERSIONS: '/versions',
	RBAC: '/rbac',
	MEDIA_FILES: '/media-files',
	SESSIONS: '/sessions',
	LANGUAGES: '/languages',
	FAQ: '/faq',
	RECALCULATION: "/recalculation",
	RULES: "/rules",
	MODERATION_PAYMENT: "/moderation-payment",
	TRANSACTIONS: "/transactions",
	MARK: "/mark",
	MODEL: "/model",
	SUBSCRIPTIONS: '/subscriptions',
	TRANSPORT_CATEGORY: '/transportСategory',
	BODY_TYPE: '/bodyType',
	POLL: '/poll',
	EMAIL_TEMPLATES: '/email-templates',
	CREATE_EMAIL_TEMPLATES: '/email-templates/create',
	ADVERTISER: '/advertiser',
	ADVERTISER_VIEW: '/advertiser/view',
	ADVERTISER_COMPANY: '/advertiser/company',
	ADVERTISER_COMPANY_VIEW: '/advertiser/company/view'
}

export const REFERRAL_LINKS_API = {
	CREATE_REFERRAL_LINK: 'post/reference/create',
	CHANGE_REFERENCE_ARCHIVE: 'post/references/archive/change',
}

export const AUTH_API = {
	LOGIN: "post/admin/auth",
}
export const LANG_API = {
	GET_LANGS: "get/languages",
	CREATE_LANG: 'add/language',
	UPDATE_LANG: 'update/language',
	DELETE_LANG: 'delete/language'
}
export const TERMS_API = {
	GET_TERMS: "get/terms",
	CREATE_TERMS: "post/terms/create",
	EDIT_TERMS: "post/terms/update",
	DELETE_TERMS: "post/terms/delete",
	MIGRATION_TERMS: "/get/terms/migration"
}
export const UNITS_API = {
	GET_UNITS: "get/unitlist",
	CREATE_UNITS: "post/unitlist/create",
	EDIT_UNITS: "post/unitlist/update",
	DELETE_UNITS: "post/unitlist/delete",
}
export const UNITS_GROUPS_API = {
	GET_UNITS_GROUPS: "get/unitgroups",
	CREATE_UNITS_GROUPS: "post/create/unitgroups",
	EDIT_UNITS_GROUPS: "post/update/unitgroups",
	DELETE_UNITS_GROUPS: "post/delete/unitgroups",
}
export const PRICE_API = {
	GET_PRICE: "get/price",
	CREATE_PRICE: "post/price/create",
	EDIT_PRICE: "post/price/update",
	DELETE_PRICE: "post/price/delete",
}
export const AMOUNT_API = {
	GET_AMOUNT: "get/amount",
	CREATE_AMOUNT: "post/amount/create",
	EDIT_AMOUNT: "post/amount/update",
	DELETE_AMOUNT: "post/amount/delete",
}
export const DEALS_API = {
	GET_BUTTONS_CONDITIONS: "/get/deals/buttons/conditions",
	CREATE_DEAL_BUTTON: "/post/deals/buttons/create",
	CREATE_DEAL_BUTTON_TEMPLATE: "/post/deals/buttons/template/create",
	UPDATE_DEAL_BUTTON: "/post/deals/buttons/update",
	UPDATE_DEAL_BUTTON_TEMPLATE: "/post/deals/buttons/template/update",
	DELETE_DEAL_BUTTON: "/post/deals/buttons/delete",
	DELETE_DEAL_BUTTON_TEMPLATE: "/post/deals/buttons/template/delete",
	GET_BUTTONS: '/get/deals/buttons/get',
	GET_BUTTONS_TEMPLATES: '/get/deals/buttons/templates/get',
	MIGRATION_BUTTONS_TEMPLATES: '/post/deals/buttons/templates/migration'
}
export const ADDRESS_BOOK_API = {
	GET_GROUPS: "get/notification/group",
	CREATE_GROUP: 'post/notification/group/create',
	UPDATE_GROUP: 'post/notification/group/update',
	UPDATE_GROUP_USERS: 'post/notification/group/users/update/',
	GET_GROUP: 'get/notification/group/users',
	DELETE_GROUP: 'post/notification/group/delete',
	UPDATE_GROUP_USERS_FILTERS: '/post/notification/group/users/update/filter',
	DELETE_GROUP_USERS: 'post/notification/group/users/delete',
	EMAIL_VERIFICATION_GROUP: '/post/email/group/check',
	EMAIL_STOP_VERIFICATION_GROUP: '/post/email/group/stopcheck',
	EMAIL_VERIFICATION_DELETE_USERS: '/post/email/group/check/delete'
}
export const ADVERTISER_API = {
	GET_COMPANIES: '/get/advertiser/companies/',
	GET_COMPANY: '/get/advertiser/companies/my',
	GET_REFILL_HISTORY: '/get/advertiser/refill/history',
	GET_USERS: '/get/advertiser/users',
	CHANGE_ANNOUNCEMENT_STATUS: '/post/advertiser/company/changestatus',
	CHANGE_COMPANY_STATUS: '/post/advertiser/users/changestatus',
	CHANGE_MARKER_STATUS: '/post/advertiser/marker/changestatus',
	GET_PRICES: "/get/advertiser/prices",
}
export const ADVERTISING_API = {
	GET_ADVERTISING: "get/ads/list",
	CREATE_ADVERTISING: "post/ads/create",
	EDIT_ADVERTISING: "post/ads/update",
	DELETE_ADVERTISING: "post/ads/delete",
}
export const RBAC_API = {
	GET_RBAC: "get/rbac",
	CREATE_RBAC: "post/rbac/create",
	EDIT_RBAC: "post/rbac/update",
	DELETE_RBAC: "post/rbac/delete",
}
export const SUBSCRIPTION_API = {
	GET_SUBSCRIPTION: "get/subscriptions",
	GET_SUBSCRIPTION_PACKAGE: "get/package",
	CREATE_SUBSCRIPTION: "post/subscriptions/create",
	CREATE_SUBSCRIPTION_PACKAGE: "post/package/create",
	EDIT_SUBSCRIPTION: "post/subscriptions/update",
	EDIT_SUBSCRIPTION_PACKAGE: "post/package/update",
	DELETE_SUBSCRIPTION: "post/subscriptions/delete",
	DELETE_SUBSCRIPTION_PACKAGE: "post/package/delete",
	UPDATE_ADDITIONAL: "post/subscriptions/additional/update"
}
export const MARK_API = {
	GET_MARK: "get/marks",
	CREATE_MARK: "post/mark/create",
	EDIT_MARK: "post/mark/update",
	DELETE_MARK: "post/mark/delete",
}
export const TRANSPORT_CATEGORY_API = {
	GET_TRANSPORT_CATEGORY: "get/transportCategory",
	CREATE_TRANSPORT_CATEGORY: "post/transportCategory/create",
	EDIT_TRANSPORT_CATEGORY: "post/transportCategory/update",
	DELETE_TRANSPORT_CATEGORY: "post/transportCategory/delete",
}
export const BODY_TYPE_API = {
	GET_BODY_TYPE: "get/bodyType",
	CREATE_BODY_TYPE: "post/bodyType/create",
	EDIT_BODY_TYPE: "post/bodyType/update",
	DELETE_BODY_TYPE: "post/bodyType/delete",
}
export const MODEL_API = {
	GET_MODEL: "get/models",
	CREATE_MODEL: "post/model/create",
	EDIT_MODEL: "post/model/update",
	DELETE_MODEL: "post/model/delete",
}
export const HTMLS_API = {
	GET_HTMLS: "get/htmls",
	CREATE_HTMLS: "post/htmls/create",
	EDIT_HTMLS: "post/htmls/update",
	DELETE_HTMLS: "post/htmls/delete",
	MIGRATION_HTMLS: "post/htmls/migration",
}

export const PAGES_API = {
	GET_PAGES: "get/pages",
	CREATE_PAGES: "post/pages/create",
	EDIT_PAGES: "post/pages/update",
	DELETE_PAGES: "post/pages/delete",
}
export const TEMPLATE_CONTENT_API = {
	GET_TEMPLATE_CONTENT: "get/templatecontent",
	GET_TEMPLATE_CONTENT_V2: "/get/templatecontents/for/CRM",
	CREATE_TEMPLATE_CONTENT: "post/templatecontent/create",
	EDIT_TEMPLATE_CONTENT: "post/templatecontent/update",
	DELETE_TEMPLATE_CONTENT: "post/templatecontent/delete",
	GET_TEMPLATE_CONTENT_INFO: "get/templatecontent",
	FIX_TEMLATE_LANGUAGE: "/post/templatecontents/toenglish"
}
export const USERS_API = {
	GET_USERS: "get/users",
	GET_USER: "/get/user/info/for/CRM",
	BLOCK_USER: "post/user/ban",
	UNBLOCK_USER: "post/user/unban",
	DEALS_USER: "get/my-deals",
	VERIFICATION_VERDICT: "post/verdict",
	GET_VERIFICATION: "get/verification",
	REGISTRATION: "/post/admin/registration",
	ADMIN_EDIT: "/post/admin/edit",
	ADMIN_DELETE: "/post/admin/delete",
	GET_ROLES: "/get/admin/roles",
	ADD_ROLE: "/post/admin/roles/add",
	DELETE_ROLE: "/post/admin/roles/delete",
	EDIT_ROLE: "/post/admin/roles/edit",
	GET_ADMINS: "/get/admin/users",
	GET_PERMISSIONS: "/get/admin/permissions",
	GET_PERMISSIONS_ALL: "/get/admin/permissions/all",
	GET_ONLINE_USERS: "/get/online/users",
	GET_VERIFICATION_MESSAGES: "/get/verification/messages",
	CREATE_VERIFICATION_MESSAGES: "/post/verification/messages/create",
	UPDATE_VERIFICATION_MESSAGES: "/post/verification/messages/update",
	DELETE_VERIFICATION_MESSAGES: "/post/verification/messages/delete",
	VERDICT_VERIFICATION_PASSPORT: "/post/user/passport/status/moderation/change",
	VERDICT_VERIFICATION_DRIVER_LICENSE: "/post/user/driver-license/status/moderation/change",
	GET_WARNINGS: "/get/warnings"
}
export const NOTICE_API = {
	GET_NOTICE_V2: "v2/get/adverts",
	GET_NOTICE_ITEM: "v2/get/advert/info/forCRM",
	RESTORE_NOTICE: "post/adverts/raise",
	PROMOTE_NOTICE: "post/adverts/promote",
	SEARCH_NOTICE: "get/adverts/search",
	SEARCH_NOTICE_V2: "get/adverts/v2/search",
	SEARCH_NOTICE_FOR_USER: "get/adverts/data",
	TOPADV_NOTICE: "get/adverts/topadv",
	CHANGE_STATUS_NOTICE: "post/adverts/status/change",
	MODERATION_CHANGE_STATUS_NOTICE: "post/adverts/status/moderation/change",
	CREATE_ANSWER: "post/create/answer",
	GET_ANSWER: "get/answer",
	DELETE_ANSWER: "/post/delete/answer",
}
export const NOTIFICATION_API = {
	GET_NOTIFICATIONS: "get/notifications",
	GET_NOTIFICATION: "get/notification",
	GET_NOTIFICATION_STATISTICS: "get/notification/statistics",
	RESTORE_NOTIFICATION: "post/notification/raise",
	PROMOTE_NOTIFICATION: "post/notification/promote",
	SEARCH_NOTIFICATION: "get/notification/search",
	DELETE_NOTIFICATION: "post/notification/delete",
	CREATE_NOTIFICATION: 'post/notification/create',
	CREATE_EMAIL_NOTIFICATION_SENDER: 'post/notification/email/sender/create',
	UPDATE_EMAIL_NOTIFICATION_SENDER: 'post/notification/email/sender/update',
	DELETE_EMAIL_NOTIFICATION_SENDER: 'post/notification/email/sender/delete',
	GET_EMAIL_NOTIFICATION_TEMPLATE: 'get/notification/emailtemplate',
	GET_NOTIFICATIONS_MEDIA: 'get/notification/media',
	GET_EMAIL_NOTIFICATION_SENDERS: 'post/notification/email/sender/get',
	GET_EMAIL_TEMPLATES: '/get/emailtemplates',
	GET_EMAIL_TEMPLATE: '/get/emailtemplate',
	EDIT_EMAIL_TEMPLATES: '/post/emailtemplates/edit',
	CREATE_EMAIL_TEMPLATES: '/post/emailtemplates/create',
	MIGRATION_EMAIL_TEMPLATES: '/post/emailtemplates/migration'
}
export const DELIVERY_API = {
	GET_DELIVERY: "/get/delivery",
	GET_DELIVERIES: "/get/deliveries",
	CREATE_DELIVERY: "/post/delivery/create",
	DELETE_DELIVETY: "/post/delivery/delete",
	UPDATE_DELIVETY: "/post/delivery/update"
}
export const VERSIONS_API = {
	GET_VERSIONS: "get/versions",
}
export const CATEGORY_API = {
	GET_CATEGORY: "get/category",
	CREATE_CATEGORY: "post/category/create",
	EDIT_CATEGORY: "post/category/update",
	DELETE_CATEGORY: 'post/category/delete',
	ADD_IMAGE_CATEGORY: 'V2/post/mediafile/create',
	MIGRATION_TO_PROD_SUBCATEGORIES: '/post/category/syncprod',
	CHANGE_POSITION_CATEGORY_ELEMENT: 'post/category/change/position',
}
export const SUBCATEGORY_API = {
	GET_SUBCATEGORY: "get/all/subcategory",
	CREATE_SUBCATEGORY: "post/subcategory/create",
	EDIT_SUBCATEGORY: "post/subcategory/update",
	DELETE_SUBCATEGORY: 'post/subcategory/delete',
	MIGRATION_TO_PROD_SUBCATEGORIES: 'post/subcategory/syncprod',
	ADD_IMAGE_SUBCATEGORY: 'post/mediafile/create',
	CHANGE_POSITION_SUBCATEGORY_ELEMENT: 'post/subcategory/change/position',
}
export const STATISTICS_API = {
	GET_STATISTICS_GRAPH: "get/statistics/graph",
	GET_OTHER_STATISTICS: "get/statistics/other",
}
export const TEMPLATE_API = {
	GET_TEMPLATE: "get/template",
	GET_TEMPLATE_V2: "v2/get/templates",
	GET_TEMPLATE_V2_INFO: "v2/get/template/info/for/CRM",
	CREATE_TEMPLATE: "post/template/create",
	UPDATE_TEMPLATE: "post/template/update",
	DELETE_TEMPLATE: "post/template/delete",
	SYNC_TO_PROD: "/post/template/syncprod",
}
export const RECALCULATION_API = {
	GET_RECALCULATION: "get/recalculation",
}
export const RULES_API = {
	GET_RULES: "get/rules",
	CREATE_RULES: "post/rules/create",
}
export const MODERATION_PAYMENT_API = {
	GET_MODERATION_PAYMENT: "payment/select",
	GET_MODERATION_PAYMENT_STATUS: "payment/status",
}
export const MEDIA_API = {
	ADD_IMAGE: 'post/mediafile/create',
	DELETE_IMAGE: '/delete/notification/media'
}

export const PAGES_IDS = {
	USERS: {
		id: 2,
		name: 'Users',
		pages: [2000, 2033]
	},
	ADVERTS: {
		id: 3,
		name: 'Adverts',
		pages: [2001]
	},
	DEALS: {
		id: 8,
		name: 'Deals',
		pages: [2029, 2030]
	},
	MARKETING: {
		id: 4,
		name: 'Marketing',
		pages: [2032,2003,2004,2005,2006,2007,2008,2026]
	},
	FINANCES: {
		id: 5,
		name: 'Finance',
		pages: [2009,2010]
	},
	CONTENT: {
		id: 6,
		name: 'Content',
		pages: [2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022,2023,2027,2028,2031]
	},
	TECHNICAL: {
		id: 7,
		name: 'Tech-block',
		pages: [2025]
	}
}

export const ARR_KEYS_TEMPLATE_CONTENT = [ 'content', 'vendorCode', 'amount', 'minAmount', 'minPrice', 'mark', 'model' ]
// export const ARR_KEYS_TEMPLATE_CONTENT = [ 'content', 'vendorCode', 'produceYear', 'registrationNumber', 'engineVolume', 'owner', 'VIN', 'grossWeight', 'grossIn', 'grossVolume', 'amount', 'maxWeight', 'minAmount', 'minPrice', 'volume', 'transportSize', 'bodySize', 'mark', 'model', 'transportCategory', 'bodyType', 'color' ]
export const STATISTICS_IGNORE_DATERANGE = ['users_published_adverts', 'users_not_published_adverts', 'average_publication_users', 'users_published_deals', 'average_deals_users', 'users_not_published_deals']

export  const VALIDATION_TYPE = ['Regular']
export  const VALIDATION_VALUE = [
	{id: 1,name: 'Максимальна кількість символів (кількість)'},
	{id: 2, name: 'Мінімальна кількість символів (кількість)'},
	{id: 3,name: 'Недопустимі значення (рядок)'},
	{id: 4, name: 'З великої літери (початкова поз,кінцева поз)'},
	{id: 5,name: 'З маленької літери (початкова поз, кінцева поз)'},
	{id: 6, name: 'Тільки числа (початкова поз, кінцева поз)'},
	{id: 7, name: 'Первірка на посилання'},
	{id: 8, name: 'Первірка на номер тел'},
	{id: 9, name: 'Первірка на email'},
	{id: 10, name: 'Максимальное количество слов (кількість)'},
	{id: 11, name: 'Мінімальное количестов слов (кількість)'}
]

export const DELIVERY_TYPES = {
	VARIANT1: 'variant1',
	VARIANT2: 'variant2'
}


export const SOCIAL = {
	telegram_en: "https://t.me/TENTAI_EN",
    instagram_en: "https://www.instagram.com/en.tentai.pro/?igsh=MTNlMHR1Ymt6YWEzYg%3D%3D&utm_source=qr",
    facebook_en: "https://www.facebook.com/people/TentaiMarketplace-ENG/61554705346787/?mibextid=9R9pXO",
    youtube_en: "https://www.youtube.com/@TENTAI-tc1ip",
	line_en: "https://lin.ee/HfgIbIR",

    telegram_ua: "https://t.me/tentai_pro",
    instagram_ua: "https://www.instagram.com/ru.tentai.pro/?igsh=MXcxYTM1M29pcWRwbQ%3D%3D&utm_source=qr",
    facebook_ua: "https://www.facebook.com/ru.tentai.pro?mibextid=LQQJ4d",
    youtube_ua: "https://www.youtube.com/@tentai_ru",
	line_ua: "https://lin.ee/HfgIbIR",

    telegram_ru: "https://t.me/tentai_pro",
    instagram_ru: "https://www.instagram.com/ru.tentai.pro/?igsh=MXcxYTM1M29pcWRwbQ%3D%3D&utm_source=qr",
    facebook_ru: "https://www.facebook.com/ru.tentai.pro?mibextid=LQQJ4d",
    youtube_ru: "https://www.youtube.com/@tentai_ru",
	line_ru: "https://lin.ee/HfgIbIR",

    telegram_ch: "https://t.me/TENTAI_EN",
    instagram_ch: "https://www.instagram.com/en.tentai.pro/?igsh=MTNlMHR1Ymt6YWEzYg%3D%3D&utm_source=qr",
    facebook_ch: "https://www.facebook.com/people/TentaiMarketplace-ENG/61554705346787/?mibextid=9R9pXO",
    youtube_ch: "https://www.youtube.com/@TENTAI-tc1ip",
	line_ch: "https://lin.ee/HfgIbIR",

    telegram_th: "https://t.me/TENTAI_EN",
    instagram_th: "https://www.instagram.com/th.tentai.pro/?igsh=MTNxdXZueGpnbXFpYg%3D%3D&utm_source=qr",
    facebook_th: "https://www.facebook.com/th.tentai.pro?mibextid=YMEMSu",
    youtube_th: "https://www.youtube.com/@TENTAI-tc1ip",
	line_th: "https://lin.ee/HfgIbIR",

    telegram_fr: "https://t.me/TENTAI_EN",
    instagram_fr: "https://www.instagram.com/en.tentai.pro/?igsh=MTNlMHR1Ymt6YWEzYg%3D%3D&utm_source=qr",
    facebook_fr: "https://www.facebook.com/people/TentaiMarketplace-ENG/61554705346787/?mibextid=9R9pXO",
    youtube_fr: "https://www.youtube.com/@TENTAI-tc1ip",
	line_fr: "https://lin.ee/HfgIbIR"
}

export const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
export const PASSWORD_REGEXP = /^[a-zA-Z0-9!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]{6,}$/
// export const PHONE_REGEXP = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
// export const WEBSITE_REGEXP = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&\\/=]*)$/g
// export const FACEBOOK_REGEXP = /(?:https?|file|ftp):\/\/([^\/\s]+)[^\s]*/ig
// export const VIBER_REGEXP = /^[a-zA-Z0-9!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?]{6,}$/g
// export const TELEGRAM_REGEXP = /^(?:^|[^\w])([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/g
// export const INSTAGRAM_REGEXP = /^(?:^|[^\w])([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/g
// export const PRICE_REGEXP = /^\d{1,7}(\.\d{1,2})?$/
