import React from 'react';
import { useTranslation } from 'react-i18next';

const SelectShowRecords = ( { setCountShow, countShowTemplates } ) => {
	const { t } = useTranslation()
	return (
		<div className='terms-wrapper_search-showList'>
			<span className='terms-wrapper_search-showList_title'>
				{t('show_records')}
			</span >
			<select
				className='terms-wrapper_search-showList_select'
				onChange={ e => setCountShow( Number( e.target.value ) ) }
				defaultValue={countShowTemplates}
			>
				<option value='20'>20</option >
				<option value='30'>30</option >
				<option value='40'>40</option >
				<option value='50'>50</option >
				<option value='100'>100</option >
			</select >
		</div >
	)
}

export default SelectShowRecords