import { useEffect, useState } from 'react';
import { NewTabs } from '../../components/newTabs/NewTabs';
import s from './advertiser.module.scss';
import { AdvertiserProfile } from './AdvertiserProfile/AdvertiserProfile';
import { AdvertiserDocuments } from './AdvertiserDocuments/AdvertiserDocuments';
import { AdvertiserProfileCompanies } from './AdvertiserProfileCompanies/AdvertiserProfileCompanies';
import { AdvertiserWallet } from './AdvertiserWallet/AdvertiserWallet';
import { useAdvertiserGetUsersQuery } from '../../store/apis/advertiserApi';
import { useSearchParams } from 'react-router-dom';
import LoaderContent from '../../components/LoaderContent';
import { useTranslation } from 'react-i18next';
import { AdvertiserMarkers } from './AdvertiserMarkers/AdvertiserMarkers';

export const Advertiser = () => {

    const { t } = useTranslation()

    const [tabs, setTabs] = useState([
        { id: 0, title: t('profile') },
        { id: 1, title: t('documents') },
        { id: 2, title: t('a_ac') },
        { id: 3, title: t('wallet') },
        { id: 4, title: t('markers') },
    ])
    
    const userStatuses = [
        { id: 0, title: t('nver') },
        { id: 1, title: t('ver') },
        { id: 2, title: t('dnpm') },
        { id: 3, title: t('blocked') },
    ]

    // ### State
    const [currentTab, setTab] = useState(tabs[0].id)
    const [searchParams, setSearchParams] = useSearchParams()
    const userId = searchParams.get('id')

    const {data: userData, isLoading: isGetUserLoading} = useAdvertiserGetUsersQuery({id:userId})

    // ### Effects
    useEffect(()=>{
        setTabs(prev => {
            return prev.map(tab => {
                return {...tab, active: false}
            })
        })
        if(userData?.companies?.[0]?.status === 0){
            setTabs(prev => {
                return prev.map(tab => {
                    if(tab.id === 0 || tab.id === 1){
                        return {...tab, active: true}
                    } else return tab
                })
            })
        }
        if(userData?.companies?.[0]?.markers){
            let markerModerationFlag
            if(userData?.companies?.[0]?.markers?.moderationStatus === 0) markerModerationFlag = true
            userData?.companies?.[0]?.markers?.markers?.forEach(marker => {
                if(marker.moderationStatus === 0) markerModerationFlag = true
            })
            if(markerModerationFlag){
                setTabs(prev => {
                    return prev.map(tab => {
                        if(tab.id === 4){
                            return {...tab, active: true}
                        } else return tab
                    })
                })
            }
        }
        if(userData?.companies?.[0]?.warningAnnouncements){
            setTabs(prev => {
                return prev.map(tab => {
                    if(tab.id === 2){
                        return {...tab, active: true}
                    } else return tab
                })
            })
        }
    },[userData]);

    if(isGetUserLoading) return <LoaderContent />
    return(
        <section className="terms">
            <h1 className='terms-title'>{t('advertiser')}</h1 >
            <div className={s.page}>
                <div className={s.header}>
                    <div className={s.user_wrapper}>
                        <div className={s.logo}>
                            <img src="https://test.tentai.pro/image/notifications/10000/image-17067912204373282567041005122.png" alt="logo" />
                        </div>
                        <div className={s.user_info}>
                            <div className={`${s.status} 
                                ${
                                    userData?.companies?.[0]?.status === 0 ? s.moderation :
                                    userData?.companies?.[0]?.status === 1 ? s.active :
                                    userData?.companies?.[0]?.status === 2 ? s.rejected :
                                    userData?.companies?.[0]?.status === 3 ? s.banned : ''
                                }`}
                            >
                                {userStatuses.find(el => el.id === userData?.companies?.[0]?.status)?.title}
                            </div>
                            <span className={s.name}>{userData?.companies?.[0]?.creator?.name}</span>
                            <span className={s.email}>{userData?.companies?.[0]?.creator?.email}</span>
                        </div>
                    </div>
                    <div className={s.line}></div>
                </div>

                <div className={s.tabs_wrapper}>
                    <NewTabs items={tabs} tab={currentTab} setTab={setTab}/>
                </div>
                { currentTab === 0 && <AdvertiserProfile userData={userData.companies} userStatuses={userStatuses}/> }
                { currentTab === 1 && <AdvertiserDocuments userData={userData.companies?.[0]}/> }
                { currentTab === 2 && <AdvertiserProfileCompanies userData={userData.companies?.[0]}/> }
                { currentTab === 3 && <AdvertiserWallet userData={userData.companies?.[0]}/> }
                { currentTab === 4 && <AdvertiserMarkers markers={userData.companies?.[0]?.markers} companyId={userData.companies?.[0]?.id}/> }
            </div>

        </section>
    )
}