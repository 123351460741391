import { useState } from 'react';
import DeleteModal from '../../components/DeleteModal';
import s from './advertiserCompany.module.scss';
import { useAdvertiserGetCompaniesQuery } from '../../store/apis/advertiserApi';
import SearchItemsInput from '../../components/SearchItemsInput';
import PaginationCustom from '../../components/PaginationCustom';
import SelectShowRecords from '../../components/SelectShowRecords';
import { NewTabs } from '../../components/newTabs/NewTabs';
import LoaderContent from '../../components/LoaderContent';
import { useTranslation } from 'react-i18next';
import { AdvertiserCompanyItem } from './AdvertiserCompanyItem/AdvertiserCompanyItem';

export const AdvertiserCompany = () => {

    const {t} = useTranslation()
    const tabs = [
        { id: false, title: t('all') },
        { id: 0, title: t('u_om') },
        { id: 1, title: t('active') },
        { id: 2, title: t('rejected') },
        { id: 3, title: t('waiting_payment') },
        { id: 4, title:t('suspended') },
    ]

    // ### State
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [query, setQuery] = useState({search: '', page: 1, limit: 20, status: false})
    const [currentTab, setCurrentTab] = useState(false)
    const [search, setSearch] = useState('')

    const {data: companies, isFetching: isCompaniesLoading} = useAdvertiserGetCompaniesQuery(query)
    
    // ### Functions

    function setTab(value){
        if(value !== false) setCurrentTab(value + 1)
        else setCurrentTab(false)
        setQuery(prev => {return{...prev, status: value}})
    }

    // ### Views

    const companis_view = companies?.companies?.map((el, index) => <AdvertiserCompanyItem key={index} company={el}/>)

    return(
        <div className="terms">
            <h1 className='terms-title'>{t('ad_title')}</h1 >
            <DeleteModal
				show={ showDeleteModal }
				onHide={ setShowDeleteModal }
				value={ showDeleteModal }
				fromPage={ 'dealsbuttons' }
			/>

            <div className={s.tabs_wrapper}>
                <NewTabs items={tabs} tab={currentTab} setTab={setTab}/>
            </div>

            <div className='terms-wrapper_search'>
				<SearchItemsInput
					searchHandler={()=>{}}
					toggleModalSearch={()=>{ setQuery(prev => {return{...prev, search: search}})}}
					setSearch={()=>{}}
					valueSearchInputHandler={(value)=>{setSearch(value)}}
				/>
			</div >
            
            <div className='terms-wrapper_search'>
                <PaginationCustom
                    itemsCount={query.limit}
                    currentPage={query.page}
                    setCurrentPage={(page)=>{setQuery(prev => {return{...prev, page}})}}
                    counterAllItems={(query.search.length || query.status !== false)? companies?.allCount : companies?.allCount}
                />
                <SelectShowRecords setCountShow={(limit)=>{setQuery(prev => {return{...prev, limit}})}} />
            </div>
            {
                isCompaniesLoading ? <LoaderContent /> : 
                <table className={s.table}>
                    <thead>
                        <tr>
                            <td className={s.company_td} style={{padding: "12px 5px"}}>{t('ad_company')}</td>
                            <td>{t('date_created')}</td>
                            <td>{t('views')}</td>
                            <td>{t('transitions')}</td>
                            <td>{t('status')}</td>
                            <td>{t('price')}</td>
                            {/* <td>Меню</td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {companis_view}
                    </tbody>
                </table>
            }
        </div>
    )
}