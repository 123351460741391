import * as yup from "yup"

export const addAmountSchema = () => {
	return yup.object().shape({
		uaTitle: yup
			.string()
			.required('Заголовок ua обов\'язковий!'),
		ruTitle: yup
			.string()
			.required('Заголовок является обязательным!'),
		enTitle: yup
			.string()
			.required('Title is required!'),
		plTitle: yup
			.string()
			.required('Tytuł jest wymagany!'),
		unit: yup
			.number()
			.required('Обов\'язкове')
	})
}
