import React, {
	useEffect,
	useState
} from "react"
import {
	useSelector
} from "react-redux"
import { useGetMarkMutation } from "../../store/apis/markApi";
import AddEditMarkItem from "../../components/modalsAddEdit/AddEditMarkItem";
import { ShowFirstSelectedListItems } from "../../../utils/helpers/ShowFirstSelectedListItems";
import ControlPanelsModal from "../../components/environmentTabl/ControlPanelsModal";

const ChangeMarkForModel = ( ) => {
	const { mark, showModalChangeMark, dateModalChangeMark } = useSelector( state => state.markStore )
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ addSubcategoryForMarkShowModal, setAddSubcategoryForMarkShowModal ] = useState( false )

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )
	const [ getMark, { isLoading: isGetMarkLoading } ] = useGetMarkMutation()
	const [ markListArr, setMarkListArr ] = useState( [] || mark )
	const [ markerListItems, setMarkerListItems ] = useState( [] )

	useEffect( () => {
		const getMarkList = async () => {
			const { data } = await getMark()
			setMarkListArr( data?.data )
		}
		getMarkList()
	}, [ addEditModalShow, addSubcategoryForMarkShowModal, getMark ] )

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
	}

	const showModalAddSubcategoryForMarkHandler = () => {
		setAddSubcategoryForMarkShowModal( true )
	}
	const hideModalAddSubcategoryForMarkHandler = () => {
		setAddSubcategoryForMarkShowModal( !addSubcategoryForMarkShowModal )
	}

	useEffect( () => {
		const res = ShowFirstSelectedListItems( dateModalChangeMark, mark )
		setMarkerListItems( res )
	}, [ showModalChangeMark, mark, dateModalChangeMark ] )

	return (
		<div className='changeUnitForPrice'>
			<div className='changeUnitForPrice-wrapper changeUnitForTemplates'>
				<ControlPanelsModal
					bigArrElements={ markerListItems?.length > 0 ? markerListItems : markListArr }
					isLoading={ isGetMarkLoading }
					showModalHandler={ showModalHandler }
					fromPage={ 'changeMarKForModalCreate' }
					showModalAddUnitHandler={ showModalAddSubcategoryForMarkHandler }
					searchTermValue={ searchTermValue }
					loaderSorting={ loaderSorting }
					setLoaderSorting={ setLoaderSorting }
					toggleModalSearch={ toggleModalSearch }
					showModalSearch={ showModalSearch }
					showSearchBeforeDel={ showSearchBeforeDel }
					setShowSearchBeforeDel={ setShowSearchBeforeDel }
					valueSearchInputHandler={ valueSearchInputHandler }
				/>
				<AddEditMarkItem
					show={ addSubcategoryForMarkShowModal }
					onHide={ hideModalAddSubcategoryForMarkHandler }
				/>
			</div >
		</div >
	)
}

export default ChangeMarkForModel
