import * as yup from "yup"

export const addReferralLink = () => {
    return yup.object().shape({
        name: yup
            .string()
            .required('Name required!'),
        link: yup
            .string()
            .required('Link required!'),
    })
}