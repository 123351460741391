import EditPencil from "../../../utils/icons/edit-pencil.svg"
import DeleteIcon from "../../../utils/icons/delete-icon.svg"
import { useRef, useState } from "react"
import {ReactComponent as InfoIcon} from '../../../utils/icons/info-icon.svg'
import {
	useDispatch,
} from "react-redux"
import {
	setDateChangeUnitPrice,
	setDateChangeUnitPriceArr,
	deleteDateChangeUnitPriceArr
} from "../../store/slices/priceSlice"
import { setDateChangeUnitAmount } from "../../store/slices/amountSlice"
import {
	setDateChangeUnitTemplates,
	setDateChangeUnitTemplatesList,
} from "../../store/slices/temlateContentSlice"
import { setDateChangeTerms } from '../../store/slices/termsSlice'
import { setDateChangeHTMLS } from "../../store/slices/htmlsSlice"
import {
	setDateChangeUnitGroup,
	setDateChangeUnitGroups
} from "../../store/slices/unitGroupsSlice";
import {
	setDateChangeMark,
} from "../../store/slices/markSlice";
import { setDateChangeMarkForModel } from "../../store/slices/modelSlice";
import { setDateChangeBodyType } from "../../store/slices/bodyTypeSlice";
import { setDateChangeTransportCategory } from "../../store/slices/transportCategorySlice";
import { setDateChangeUnit } from "../../store/slices/unitsMeasurementSlice";
import { useTranslation } from "react-i18next"

const TermsTablItem = ({
	setOpenEditModal,
	item,
	toggleDeleteModal,
	fromPage,
	isChecked,
	setItem
}) => {
	const dispatch = useDispatch()
	const [checkedUnitForPrice, setCheckedUnitForPrice] = useState(false)
	const [checkedUnitForAmount, setCheckedUnitForAmount] = useState(false)
	const [checkedUnitForTemplate, setCheckedUnitForTemplate] = useState(false)
	const infoModal = useRef()
	const {i18n} = useTranslation()
	
	const toggleCheckedUnit = (value) => {
		if (fromPage === 'changeUnitForPrice') {
			setCheckedUnitForPrice(value)
		} else if (fromPage === 'changeUnitForPricesArrTemplate') {
			setCheckedUnitForPrice(value)
		} else if (fromPage === 'price') {
			setCheckedUnitForAmount(value)
		} else if (fromPage === 'changeUnitForAmount') {
			setCheckedUnitForAmount(value)
		} else if (fromPage === 'changeUnitForTemplates') {
			setCheckedUnitForTemplate(value)
		}
	}

	const saveChangeDateUnit = (checked, value) => {
		if (fromPage === 'changeUnitForPrice') {
			dispatch(setDateChangeUnitPrice(value))
		} else if (fromPage === 'price') {
			dispatch(setDateChangeUnitPrice(value))
		} else if (fromPage === 'changeUnitForAmount') {
			dispatch(setDateChangeUnitAmount(value))
		} else if (fromPage === 'amount') {
			dispatch(setDateChangeUnitAmount(value))
		} else if (fromPage === 'changeUnitForTemplates') {
			dispatch(setDateChangeUnitTemplates(value))
		} else if (fromPage === 'changeUnitForTemplatesSelect') {
			dispatch(setDateChangeUnitTemplatesList(Number(value)))
		} else if (fromPage === 'changeUnitForUnit') {
			dispatch(setDateChangeUnitGroup(value))
		} else if (fromPage === 'changeTermsForTemplate') {
			dispatch(setDateChangeTerms(value))
		} else if (fromPage === 'changeHTMLSForTemplate') {
			dispatch(setDateChangeHTMLS(value))
		} else if (fromPage === 'unitGroups') {
			dispatch(setDateChangeUnitGroups(value))
		} else if (fromPage === 'ChangeUnitGroups') {
			dispatch(setDateChangeUnitGroups(value))
		} else if (fromPage === 'changeUnitForPricesArr') {
			checked === true && dispatch(setDateChangeUnitPriceArr(Number(value)))
			checked=== false && dispatch(deleteDateChangeUnitPriceArr(Number(value)))
		} else if (fromPage === 'changeUnitForPricesArrTemplate') {
			checked === true && dispatch(setDateChangeUnitPriceArr(Number(value)))
			checked=== false && dispatch(deleteDateChangeUnitPriceArr(Number(value)))
		} else if (fromPage === 'changeMarkForModel') {
			dispatch(setDateChangeMark(Number(value)))
		} else if (fromPage === 'changeMarKForModalCreate') {
			dispatch(setDateChangeMarkForModel(Number(value)))
		} else if (fromPage === 'changeTransportCategoryForModel') {
			dispatch(setDateChangeTransportCategory(Number(value)))
		} else if (fromPage === 'changeBodyTypeForModel') {
			dispatch(setDateChangeBodyType(Number(value)))
		}else if (fromPage === 'changeUnitForTemplateDiscount') {
			dispatch(setDateChangeUnit(Number(value)))
		}
	}

	return (
		<tr style={{position: "relative"}}>
			{
				fromPage === 'changeUnitForPrice' ||
				fromPage === 'changeUnitForAmount' ||
				fromPage === 'changeUnitForTemplates' ||
				fromPage === 'changeUnitForTemplatesSelect' ||
				fromPage === 'changeUnitForUnit' ||
				fromPage === 'changeTermsForTemplate' ||
				fromPage === 'changeHTMLSForTemplate' ||
				fromPage === 'ChangeUnitGroups' ||
				fromPage === 'changeMarkForModel' ||
				fromPage === 'changeMarKForModalCreate' ||
				fromPage === 'changeTransportCategoryForModel' ||
				fromPage === 'changeBodyTypeForModel' ||
				fromPage === 'changeUnitForPricesArrTemplate' ||
				fromPage === 'changeUnitForTemplateDiscount' ||
				fromPage === 'changeUnitForPricesArr'
					?
					<>
						<td className='terms-wrapper_tabl-body_item col-1'>
							<div className='terms-wrapper_tabl-body_item-wrapper'>
								<input
									type={(
										fromPage === 'changeUnitForTemplatesSelect' ||
										fromPage === 'changeTransportCategoryForModel' ||
										fromPage === 'changeMarkForModel' ||
										fromPage === 'changeBodyTypeForModel' ||
										fromPage === 'ChangeUnitGroups' ||
										fromPage === 'changeUnitForPricesArrTemplate' ||
										fromPage === 'changeUnitForPricesArr'
									)
										? 'checkbox' : 'radio'}
									defaultChecked={
										checkedUnitForPrice ||
										checkedUnitForAmount ||
										checkedUnitForTemplate ||
										isChecked ||
										item?.markerChangeForList
									}
									disabled={
										checkedUnitForPrice ||
										checkedUnitForAmount ||
										checkedUnitForTemplate ||
										isChecked ||
										item?.markerChangeForList
									}
									// defaultChecked={
									// 	isChecked
									// }
									name='check'
									value={item?.id}
									id={item?.id}
									onKeyDown={e => {
										e.key === 'Enter' && e.preventDefault()
									}}
									className='terms-wrapper_tabl-body_item-wrapper_input'
									onChange={e => {
										if(setItem){
											setItem(e.target.value)
										}
										toggleCheckedUnit(e.target.checked)
										saveChangeDateUnit( e.target.checked, e.target.value )
									}}
								/>
								<label htmlFor={item?.id}>{item?.id}</label >
							</div >
						</td >
						<td className={`terms-wrapper_tabl-body_item ${fromPage === 'term' ? 'col-8' : 'col-10'}`}>
							<p >
								{
									item?.title?.[i18n.language] ||
									item?.term?.[i18n.language] ||
									item?.tag?.[i18n.language] ||
									item?.body?.[i18n.language] ||
									item?.name?.[i18n.language] ||
									item?.url ||
									item?.name ||
									item?.value?.[i18n.language] ||
									item?.value?.ua
								}
								{
									(fromPage === 'price' || fromPage === 'changeUnitForPricesArrTemplate') ? item?.altValue?.[i18n.language] ? ` (${item?.altValue?.[i18n.language]})` : '' : ``
								}
							</p >
						</td >
						{
							fromPage === 'changeUnitForPricesArr'
							&&
							<>
								<td className='terms-wrapper_tabl-body_item col-2 terms-wrapper_tabl-body_item-end'>
									<div className='terms-wrapper_tabl-body_item-wrapper'>
										<span >{item?.unitlistInfo?.term?.[i18n.language]}</span >
									</div >
								</td >
							</>
						}
						{
							(fromPage === 'changeUnitForPrice' || fromPage === 'changeUnitForPricesArrTemplate')
							&&
							<>
							<td className={`terms-wrapper_tabl-body_item col-1`}>
								<span
									style={{marginLeft: '15px'}}
								>
									{
										item?.unitlistInfo?.term?.[i18n.language]
									}
								</span >
							</td >
							
							<td className='terms-wrapper_tabl-body_item col-2 terms-wrapper_tabl-body_item-end' style={{position: "static", background: 'none'}}>
									<div className='terms-wrapper_tabl-body_item-wrapper'>
										<td className='terms-wrapper_tabl-body_item col-2' style={{position: "static", background: 'none', width: '100%'}}>
											<ul style={{overflow: "visible"}} className='terms-wrapper_tabl-body_item-wrapper_values'>
												{
													item.templates?.length?
													<div 
													style={{width: '100%'}}
													onMouseEnter={()=>{
														if(infoModal.current){
															let elementRect = infoModal.current.getBoundingClientRect();
															let windowHeight = window.innerHeight;
															let distanceToBottom = windowHeight - elementRect.bottom;
															if(distanceToBottom < 50){
																infoModal.current.style.cssText = `transform: translate(-50%, 0px); top: auto; bottom: 100%; opacity: 1; visibility: visible;`
															}else{
																infoModal.current.style.cssText = `opacity: 1; visibility: visible;`
															}
														}
													}}
													onMouseLeave={()=>{
														if(infoModal.current){
															infoModal.current.style.cssText = `opacity: 0; visibility: hidden;`
														}
													}}
													><InfoIcon/></div> : null
												}
												<div className="info_modal_table_template_content" ref={infoModal}>
													{
														item?.templates?.slice(0,50).map((el, index) => {
															if(!el){return null}
															return(
																<li key={index} style={{margin: '5px'}} className="info_modal_table_template_content_item">
																	<span >{el[i18n.language]}</span >
																</li >
															)
														})
													}
												</div>
											</ul >
										</td >
									</div >
								</td >
							
							</>
						}
						{
							(fromPage === 'changeUnitForTemplates' )
							&&
							<td className='terms-wrapper_tabl-body_item col-2 terms-wrapper_tabl-body_item-end' style={{minWidth: '80px'}}>
								{
									item?.unitlistInfo && item?.unitlistInfo?.map(r => (
										<div className='terms-wrapper_tabl-body_item-wrapper' key={r?.id}>
											<span >{r?.term?.[i18n.language]}</span >
										</div >
									))
								}
							</td >
						}
					</>
					:
					<>
						<td className='terms-wrapper_tabl-body_item col-1'>
							<div className='terms-wrapper_tabl-body_item-wrapper'>
								<span >{item?.id}</span >
							</div >
						</td >
						<td
							className={`terms-wrapper_tabl-body_item ${(
								fromPage === 'term' ||
								fromPage === 'htmls' ||
								fromPage === 'pages' ||
								fromPage === 'price' ||
								fromPage === 'mark'
							) ? 'col-7' : 'col-10'}`}
						>
							<p >
								{
									item?.title?.[i18n.language] ||
									item?.term?.[i18n.language] ||
									item?.tag?.[i18n.language] ||
									item?.name?.[i18n.language] ||
									item?.body?.[i18n.language] ||
									item?.url ||
									item?.name ||
									item?.value?.[i18n.language] ||
									item?.value?.ua
								}
								{
									(fromPage === 'price' || fromPage === 'changeUnitForPricesArrTemplate') ? item?.altValue?.[i18n.language] ? ` (${item?.altValue?.[i18n.language]})` : '' : ``
								}
							</p >
						</td >
						{
							fromPage === 'term'
								?
								<>
									<td className='terms-wrapper_tabl-body_item col-2'>
										<div className='terms-wrapper_tabl-body_item-wrapper'>
											<span >{item?.systemGroup ? item?.systemGroup : '-'}</span >
										</div >
									</td >
									<td className='terms-wrapper_tabl-body_item col-2'>
										<div className='terms-wrapper_tabl-body_item-wrapper'>
											<span >{item?.psevdo}</span >
										</div >
									</td >
								</>
								:
								<></>
						}
						{
							fromPage === 'htmls'
							&&
							<td className='terms-wrapper_tabl-body_item col-2 terms-wrapper_tabl-body_item-end'>
								<div className='terms-wrapper_tabl-body_item-wrapper'>
									<span >{item?.psevdo}</span >
								</div >
							</td >
						}
						{
							(fromPage === 'price' || fromPage === 'changeUnitForPricesArrTemplate')
							&&
							<>
								<td className='terms-wrapper_tabl-body_item col-2 terms-wrapper_tabl-body_item-end'>
									<div className='terms-wrapper_tabl-body_item-wrapper'>
										<span >{item?.unitlistInfo?.term?.[i18n.language]}</span >
									</div >
								</td >
								<td className='terms-wrapper_tabl-body_item col-1 terms-wrapper_tabl-body_item-end'>
									<div className='terms-wrapper_tabl-body_item-wrapper'>
										<td className='terms-wrapper_tabl-body_item col-2' style={{position: "static", background: 'none', width: '100%'}}>
											<ul style={{overflow: "visible"}} className='terms-wrapper_tabl-body_item-wrapper_values'>
												{
													item.templates?.length?
													<div 
													style={{width: '100%'}}
													onMouseEnter={()=>{
														if(infoModal.current){
															infoModal.current.style.cssText = `opacity: 1; visibility: visible;`
														}
													}}
													onMouseLeave={()=>{
														if(infoModal.current){
															infoModal.current.style.cssText = `opacity: 0; visibility: hidden;`
														}
													}}
													><InfoIcon/></div> : null
												}
												<div className="info_modal_table_template_content" ref={infoModal}>
													{
														item?.templates?.slice(0,50).map((el, index) => {
															if(!el){return null}
															return(
																<li key={index} style={{margin: '5px'}} className="info_modal_table_template_content_item">
																	<span >{el[i18n.language]}</span >
																</li >
															)
														})
													}
												</div>
											</ul >
										</td >
									</div >
								</td >
							</>
						}
						{
							fromPage === 'pages'
							&&
							<td className='terms-wrapper_tabl-body_item col-2 terms-wrapper_tabl-body_item-end'>
								<div className='terms-wrapper_tabl-body_item-wrapper'>
									<span >{item?.screen}</span >
								</div >
							</td >
						}
						{
							fromPage !== 'changeUnitForPricesArr' &&
							<td className='terms-wrapper_tabl-body_item col-2'>
							<div className='terms-wrapper_tabl-body_item-wrapper_icons'>
								<button
									type='button'
									onClick={() => {
										saveChangeDateUnit(item?.unit)
										setOpenEditModal(item)
									}}
								>
									<img
										src={EditPencil}
										alt='Edit Pencil'
									/>
								</button >
								<button
									type='button'
									onClick={() => {
										toggleDeleteModal(item)
									}}
								>
									<img
										src={DeleteIcon}
										alt='Delete'
									/>
								</button >
							</div >
						</td >
						}

					</>
			}
		</tr >
	)
}

export default TermsTablItem
