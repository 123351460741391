import React, {
} from 'react'
import Search from "../../utils/icons/search-inp.svg"
import DelForInp from "../../utils/icons/del-for-input.svg"
import { ARE_COMMON } from "../../utils/textsContent";
import { useTranslation } from 'react-i18next';

const SearchItemsInput = ({
	toggleModalSearch,
	searchTermValue,
	valueSearchInputHandler,
	searchHandler,
	clearSearchHandler
}) => {

	const {t} = useTranslation()

	return (
		<div className='terms-wrapper_search-block me-2'>
					<input
						type='text'
						placeholder={t('search')}
						className='terms-wrapper_search-inp'
						value={searchTermValue}
						onChange={e => {
							valueSearchInputHandler(e.target.value)
						}}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								e.preventDefault()
								searchHandler()
								toggleModalSearch(true)
							}
						}}
					/>
					<button
						type='button'
						className='terms-wrapper_search-button'
						disabled={searchTermValue === ''}
						onClick={() => {
							searchHandler()
							toggleModalSearch(true)
						}}
					>
						<img
							src={Search}
							alt='Search'
							className='terms-wrapper_search-img'
						/>
					</button >
			{
				searchTermValue?.length > 0
				&&
				<button
					type='button'
					className='terms-wrapper_search-button_clear'
					onClick={clearSearchHandler}
				>
							<img
								src={DelForInp}
								alt='Del'
								className='terms-wrapper_search-img'
							/>
						</button >
			}
				</div >
	)
}

export default SearchItemsInput