import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux"
import Loader from "../Loader"
import {useCreatePagesMutation, useEditPagesMutation} from "../../store/apis/pagesApi"
import {createNewPagesItem, editPagesItem} from "../../store/slices/pagesSlice"
import {Button, Form, Modal} from "react-bootstrap"
import {Formik} from "formik"
import {addPagesSchema} from "../../../utils/validation/YupPages"
import AlertModal from "../AlertModal";
import { ARE_COMMON } from "../../../utils/textsContent";

const AddEditPagesItem = ({show, onHide, edit, editDateForModal}) => {
	const [form, setForm] = useState({})
	const [createPages, {isLoading: isCreatePages}] = useCreatePagesMutation()
	const [editPages, {isLoading: isEditPages}] = useEditPagesMutation()
	const [errorMessage, setErrorMessage] = useState('')
	const dispatch = useDispatch()
	const isLoading = isCreatePages || isEditPages
	useEffect(() => {
		edit && setForm(editDateForModal)
	}, [edit])
	const formDateUpdateHandler = (opt) => {
		setForm({...form, ...opt})
	}

	const handleSubmit = async (values) => {
		const formDate = {
				url: values?.url || '',
				screen: values?.screen || ''
		}
		try {
			if (edit) {
				const {error} = await editPages({...formDate, id: editDateForModal.id})
				dispatch(editPagesItem({...formDate, id: editDateForModal.id}))
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()
			} else {
				const {data, error} = await createPages(formDate)
				dispatch(createNewPagesItem({...formDate, id: data?.id}))
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()
			}
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}
	}

	const clearFormHideModal = () => {
		onHide()
		setForm({})
	}
	return (
		<Modal
			show={show}
			onHide={clearFormHideModal}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className='add-edit-htmls_modal'
		>
			{
				errorMessage && <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<Formik
				validateOnChange
				initialValues={{
					url: form?.url || '',
					screen: form?.screen || '',
				}}
				validationSchema={addPagesSchema}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{
					({
						 values,
						 errors,
						 touched,
						 handleChange,
						 handleBlur,
						 isValid,
						 handleSubmit,
						 dirty
					 }) => (
						<>
							<Modal.Header
								closeButton
							>
								<Modal.Title
									id="contained-modal-title-vcenter"
									className='add-edit-htmls_title'
								>
									{edit ? `${ARE_COMMON?.EDITING} Pages` : `${ARE_COMMON?.CREATED} Pages`}
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Form
									className="add-edit-terms"
									onSubmit={handleSubmit}
								>
									<Form.Group
										className="add-edit-htmls_center"
									>
										URL
										<Form.Control
											type="text"
											name="url"
											onBlur={handleBlur}
											onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
											className={`add-edit-htmls_center-inp  ${touched?.url ? "is-touch " : ""} ${
												errors?.url && touched?.url ? " is-invalid" : ""
											} `}
											value={values.url}
											onChange={(e) => {
												handleChange(e)
												formDateUpdateHandler({
													[e.target.name]: e.target.value
												})
											}}
										/>
										{errors.url && touched.url && (
											<Form.Control.Feedback type="invalid">
												{errors.url}
											</Form.Control.Feedback>
										)}
									</Form.Group>
									<Form.Group
										className="add-edit-htmls_center"
									>
										Screen
										<Form.Control
											type="text"
											name="screen"
											onBlur={handleBlur}
											onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
											className={`add-edit-htmls_center-inp  ${touched?.screen ? "is-touch " : ""} ${
												errors?.screen && touched?.screen ? " is-invalid" : ""
											} `}
											value={values.screen}
											onChange={(e) => {
												handleChange(e)
												formDateUpdateHandler({
													[e.target.name]: e.target.value
												})
											}}
										/>
										{errors.screen && touched.screen && (
											<Form.Control.Feedback type="invalid">
												{errors.screen}
											</Form.Control.Feedback>
										)}
									</Form.Group>
								</Form>
							</Modal.Body>
							<Modal.Footer>
								<Button
									type='button'
									className='add-edit-htmls_close'
									onClick={clearFormHideModal}
								>
									{ARE_COMMON?.ABOLITION}
								</Button>
								<Button
									type='submit'
									className='add-edit-htmls_save d-inline-flex'
									style={{
										maxHeight: '33px',
										display: 'flex',
										alignItems: 'center'
									}}
									disabled={(!isValid && dirty)}
									onClick={handleSubmit}
								>
									{
										isLoading ? <Loader/> : (edit ? ARE_COMMON?.SAVE : 'Створити Pages')
									}
								</Button>
							</Modal.Footer>
						</>
					)
				}
			</Formik>
		</Modal>
	)
}
export default AddEditPagesItem
