import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	VERSIONS_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const versionsApi = createApi({
	reducerPath: "versionsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getVersions: builder.mutation({
			query: () => (
				{
					url: VERSIONS_API.GET_VERSIONS,
					method: "GET",
				})
		}),
	})
});

export const {
	useGetVersionsMutation,


} = versionsApi
