import { useEffect, useState } from 'react';
import s from './user.module.scss';
import { useTranslation } from 'react-i18next';
import { useBlockUserMutation, useGetUserQuery, useUnblockUserMutation } from '../../../store/apis/usersApi';
import { useSearchParams } from 'react-router-dom';

import { NewTabs } from '../../../components/newTabs/NewTabs';
import { UserProfile } from './UserProfile/UserProfile';
import AlertModal from '../../../components/AlertModal';
import { ConfirmModal } from '../../../components/ConfirmModal/ConfirmModal';
import Loader from '../../../components/LoaderContent';
import { UserDocuments } from './UserDocuments/UserDocuments';
import { UserDeals } from './UserDeals/UserDeals';
import balance_img from '../../../../utils/assets/images/advertiser-balance.webp'
import { VerificationMessages } from '../../../components/verificationMessagesModal/verificationMessagesModal';
import { base64ToBlob } from '../../../../utils/helpers/base64ToBlob';
import { AdvertsPage } from '../../AdvertsPage/AdvertsPage';


export const User = () => {

    // ### State
    const { t } = useTranslation()

    const tabs_items = [
        { id: 0, title: t('profile'), active: false },
        { id: 1, title: t('documents'), active: false },
        { id: 2, title: t('advertisements'), active: false },
        { id: 3, title: t('deals'), active: false },
    ]

    const verificationStatuses = [
        { id: 0, title: t('nver') },
        { id: 1, title: t('ver') },
        { id: 2, title: t('dnpm') },
        { id: 100, title: t('u_nv') }
    ]
    
    const userStatuses = [
        { id: 0, title: t('u_dn')},
        { id: 1, title: t('u_r')},
        { id: 2, title: t('u_d')},
        { id: 3, title: t('u_pd')},
        { id: 4, title: t('u_bu')},
    ]

    // ### State
    const [userVerification, setUserVerification] = useState(100)
    const [tabs, setTabs] = useState(tabs_items)
    const [currentTab, setTab] = useState(tabs[0].id)
    const [errorMessage, setErrorMessage] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [messagesModal, setMessagesModal] = useState(false)

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams()
    const userId = searchParams.get('id')
  
    const {data: user, isFetching: isGetUserLoading} = useGetUserQuery(userId)
    const [banned, {isLoading: isBannedLoading}] = useBlockUserMutation()
    const [unBanned, {isLoading: isUnBannedLoading}] = useUnblockUserMutation()
	const userData = user?.user
    
    // ### Effects

    useEffect(()=>{
        // Set Active Tabs
        if(user?.userPassport?.moderationStatus === 0 || user?.userDriverLicense?.moderationStatus === 0) setTabs(prev => { return [...prev.map(el => {
            if(el.id === 1) return {...el, active: true}
            else return el
        }) ] }) 
        else setTabs(prev => { return [...prev.map(el => {
            if(el.id === 1) return {...el, active: false}
            else return el
        }) ] }) 
        if(user?.userVerification?.status === 0) setTabs(prev => { return [...prev.map(el => {
            if(el.id === 0) return {...el, active: true}
            else return el
        }) ] })
        else setTabs(prev => { return [...prev.map(el => {
            if(el.id === 0) return {...el, active: false}
            else return el
        }) ] })

        if(user?.userVerification){
            switch (user.userVerification.status) {
                case 0:
                    setUserVerification(0)
                    break;
                case 1:
                    setUserVerification(1)
                    break;
                case 2:
                    setUserVerification(2)
                    break;
                default:
                    break;
            }
        } else{
            setUserVerification(100)
        }
    },[user]);
    
    // ### Functions
    async function handleUserStatus(status, message){
        let method
        if(status === 1) method = unBanned
        else if(status === 4) method = banned
       
        const response = await method({id: user?.user.id, message})
        setConfirmModal(false)
        if(response.error) setErrorMessage(response.error.data?.message)
            else setSuccessModal(true)
    }
    if(isGetUserLoading || isBannedLoading || isUnBannedLoading) return <Loader/>
    return(
        <>
        {
            errorMessage
            &&
            <AlertModal
                content={errorMessage}
                setErrorMessage={setErrorMessage}
            />
        }
        {
            successModal ? 
            <ConfirmModal 
                title={t('u_status')}
                text={t('s_stc')}
                close={()=>setSuccessModal(false)}
            /> : null
        }
        <section className="terms">
            <h1 className='terms-title'>{t('user')}</h1 >
            <div className={s.page}>
                <div className={s.header}>
                    <div className={s.content}>
                        <div className={s.left}>
                            <div className={s.user_wrapper}>
                                <div className={s.logo}>
                                    {user?.user?.avatar?.length && typeof user?.user?.avatar[0] === "object" ? 
                                        <img src={URL.createObjectURL(base64ToBlob(user.user?.avatar[0]?.file, user.user?.avatar[0]?.mimetype))} alt="logo" />
                                        :
                                        <img src="https://test.tentai.pro/image/notifications/10000/image-17067912204373282567041005122.png" alt="logo" />
                                    }
                                </div>
                                <div className={s.user_info}>
                                    <div className={`${s.status}
                                        ${
                                            user?.userVerification?.status === 0 ? s.rejected :
                                            user?.userVerification?.status === 1 ? s.active :
                                            user?.userVerification?.status === 2 ? s.rejected : ""
                                        }`}
                                    >
                                        {t(verificationStatuses.find(el => el.id === userVerification)?.title)}
                                    </div>
                                    <span className={s.name}>{userData?.pib}</span>
                                    <span className={s.email}>{userData?.email}</span>
                                </div>
                            </div>
                            <div className={s.balance_wrapper}>
                                <img src={balance_img} alt="balance" />
                                <div className={s.balance}>
                                    <span className={s.count}>{user?.user?.balance?.secret?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</span>
                                    <span className={s.valute}>THB</span>
                                </div>
                            </div>
                        </div>
                        <div className={s.right}>
                            <div className={`${s.status} 
                                ${
                                    user?.user?.status === 0 ? s.moderation :
                                    user?.user?.status === 1 ? s.active :
                                    user?.user?.status === 2 ? s.removed :
                                    user?.user?.status === 3 ? s.rejected :
                                    user?.user?.status === 4 ? s.banned : ""
                                }`}
                            >
                                {t(userStatuses.find(el => el.id === user?.user?.status)?.title)}
                            </div>
                            <div className={s.button_wrapper}>
                                <div className={`${s.button} ${user?.user?.status === 4 ? s.unbanned : s.banned}`} onClick={()=>user?.user?.status === 4? setConfirmModal(1) : setMessagesModal({status: 4, type: 1})}>
                                    {user?.user?.status === 4 ? t('unban') : t('ban')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.line}></div>
                </div>

                <div className={s.tabs_wrapper}>
                    <NewTabs items={tabs} tab={currentTab} setTab={setTab}/>
                </div>
                { currentTab === 0 && <UserProfile userData={user?.user} userVerification={user?.userVerification} verificationStatus={userVerification} verificationStatuses={verificationStatuses}/> }
                { currentTab === 1 && <UserDocuments passport={user?.userPassport} driverLicense={user?.userDriverLicense}/> }
                { currentTab === 2 && <AdvertsPage userId={user?.user.id} isModal={true}/> }
                { currentTab === 3 && <UserDeals userId={user?.user.id}/> }
                {/* { currentTab === 3 && <AdvertiserWallet userData={userData.companies?.[0]}/> }  */}
            </div>
            {confirmModal? <ConfirmModal title={t('u_status')} text={t('u_cstatus')} close={()=>setConfirmModal(false)} success={()=>handleUserStatus(confirmModal)} /> : null}
            {messagesModal ? <VerificationMessages type={messagesModal.type} close={()=>setMessagesModal(false)} confirm={(status, message)=>handleUserStatus(messagesModal.status, message.message)}/> : null}
        </section>
        </>
    )
}