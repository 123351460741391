import { createSlice } from "@reduxjs/toolkit"
import { statisticsApi } from "../apis/statisticsApi"

const initialState = {
	statistics: {
		data: {},
		procents: {}
	}
}

const statisticsSlice = createSlice({
	name: "statistics",
	initialState,
	reducers: {
		setStatistics: (state, action) => {
			state.statistics = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
		.addMatcher(statisticsApi.endpoints.getStatisticsGraph.matchFulfilled, (state, action) => {
			state.statistics = action.payload
		})
	}
})

const {
	actions,
	reducer
} = statisticsSlice
export const {
	setStatistics,
} = actions
export default reducer
