import React from 'react'
import { Table } from "react-bootstrap"
import RbacTablRoleItem from "./RbacTablRoleItem";
import { useGetUsersRolesQuery } from '../../../store/apis/rbacApi';
import DeleteModal from '../../../components/DeleteModal';
import { useState } from 'react';
import { useGetAllPermissionsQuery } from '../../../store/apis/usersApi';

const RbacTablRole = ({setRoleModal}) => {

	const [deleteModalShow, setDeleteModalShow] = useState(false)
	const [deleteTerm, setDeleteTerm] = useState({})

	const {data: roles} = useGetUsersRolesQuery()
	const {data: permissions} = useGetAllPermissionsQuery()

	const toggleDeleteModal = (value) => {
		setDeleteTerm(value)
		setDeleteModalShow(!deleteModalShow)
	}

	return (
		<>
			<DeleteModal
				show={deleteModalShow}
				onHide={toggleDeleteModal}
				value={deleteTerm}
				fromPage={'rbacRoles'}
			/>
			<Table
				responsive
				className='users-wrapper_tabl'
				striped={ true }
			>
				<thead className='users-wrapper_tabl-head'>
				<tr className='users-wrapper_tabl-trow'>
					<th
						className='users-wrapper_tabl-trow_item col-1'
						style={{width: 'fit-content'}}
					>
						<span >
							Post
						</span >
					</th >
					<th
						className={ `users-wrapper_tabl-trow_item col-3` }
					>
						<span >
							Access
						</span >
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1 text-center'
					>
						<span >
							Action
						</span >
					</th >
				</tr >
				</thead >
				<tbody
					className='users-wrapper_tabl-body'
				>
					{
						roles && roles.map((el, index) => {
							return <RbacTablRoleItem key={index} role={el} permissions={permissions} toggleDeleteModal={toggleDeleteModal} setRoleModal={setRoleModal}/>
						})
					}
				</tbody >
			</Table >
		</>
	);
};

export default RbacTablRole;