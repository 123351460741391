export function base64ToBlob(base64, contentType = '', sliceSize = 512){
    try {
        if(!base64) return false
        const byteCharacters = atob(base64.split(',')[1]);
        const byteArrays = [];
  
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
  
        return new Blob(byteArrays, { type: contentType });
    } catch (error) {
        console.log("base64ToBlob", error)
        return new Blob()
    }
}