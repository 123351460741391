import React, { useEffect, useState } from 'react';
import s from './createMessagesTemplate.module.scss';
import AlertModal from '../../../components/AlertModal';
import { TextFieldsBlock } from '../../../components/environmentTabl/TextFieldsBlock/TextFieldsBlock';
import { useCreateDealsButtonTemplateMutation, useGetButtonsConditionsQuery, useGetDealsButtonTemplateMutation, useGetDealsButtonsQuery, useUpdateDealsButtonTemplateMutation } from '../../../store/apis/dealsApi';
import { ReactComponent as AddPlus } from '../../../../utils/icons/addPlusColor.svg'
import { ReactComponent as DeleteIcon } from '../../../../utils/icons/del-for-input.svg'
import { ReactComponent as DeleteButton } from '../../../../utils/icons/delete-red-icon.svg'
import { ReactComponent as InfoIcon } from '../../../../utils/icons/info-icon.svg'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import LoaderIcon from '../../../components/Loader'
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DealsMessagesModal } from '../../DealsMessages/dealsMessagesModal/dealsMessagesModal';
import { ReactComponent as ArrowTop } from '../../../../utils/icons/arrow-right.svg'
import Loader from '../../../components/LoaderContent';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const initialState = {
    title: {},
    manual: [],
    conditions: [],
    buttons_payer: [],
    buttons_seller: []
}

export const CreateMessagesTemplate = ({isModal, close})=>{

    const [form, setForm] = useState(initialState)
    const [errorMessage, setErrorMessage] = useState(false)
    const [typeState, setTypeState] = useState()
    const [buttonsView, setButtonsView] = useState([])

    const [searchParams] = useSearchParams()
    const templateId = searchParams.get('id')
    const {t, i18n} = useTranslation()

    const langs = useSelector(store => store.langStore.langs)
    const navigate = useNavigate()

    const [createTemplate, {isLodaing: isCreateLoading}] = useCreateDealsButtonTemplateMutation()
    const [updateTemplate, {isLoading: isUpdateLoading}] = useUpdateDealsButtonTemplateMutation()
    const [getTemplatesQuery, {isLoading: isGetTemplateLoading}] = useGetDealsButtonTemplateMutation()
    const {data: conditionsData, isLoading: isConditionsLoading} = useGetButtonsConditionsQuery()
    const {data: buttons, isLoading} = useGetDealsButtonsQuery({})
    const conditions = conditionsData?.conditions

    // useEffect(()=>{
    //     console.log("################ FORM", form);
    // },[form]);

    useEffect(()=>{
        if(templateId && templateId.length && conditions && buttons){
            getDealsTemplate(templateId, conditions)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[templateId, conditions, buttons]);

    async function getDealsTemplate(id, conditions){
        const response = await getTemplatesQuery(id)
        if(response.error){
            setErrorMessage(response.error?.data?.message)
        }
        else{
            const data = response.data[0]
            
            const current_conditions = getCurrentConditions(conditions, data)

            const currentManual = data?.messages?.map(message => {
                
                const current_conditions = getCurrentConditions(conditions, message)
                
                return {
                    ...message, 
                    conditions: current_conditions,
                    title: {...message.title[0]?.title, id: message.title[0]?.id},
                    message: {...message.message[0]?.body, id: message.message[0]?.id}
                }
            })

            data?.buttons_payer.forEach(el => {
                onDragEnd({source: {droppableId: "droppable3", index: el.id}, destination: {droppableId: "droppable1"}, draggableId: el.id})
            })
            data?.buttons_seller.forEach(el => {
                onDragEnd({source: {droppableId: "droppable3", index: el.id}, destination: {droppableId: "droppable2"}, draggableId: el.id})
            })
            
            setForm(prev => {
                return{
                    ...prev,
                    title: {...data?.title?.title, id: data?.title?.id},
                    conditions: current_conditions,
                    manual: currentManual
                }
            })
        }
    }

    useEffect(()=>{
        if(conditions){
            setTypeState(conditions[0].id)
        }
    },[conditions]);

    useEffect(()=>{
        if(buttons){
            buttons.forEach((button, index) => {
                let currentStyle
                console.log(button);
                if(button.color === 1) currentStyle = s.accept
                if(button.color === 2) currentStyle = s.cancel
                if(button.color === 3) currentStyle = s.info
                const button_view = (
                    <Draggable key={button.id} draggableId={index.toString()} index={button.id}>
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={index} className={`${s.deal_button} ${currentStyle}`} id={button.id}>
                            <div className={s.button}>{button.title?.title?.[i18n.language]}</div>
                            <div className={s.info_wrapper}>
                                <InfoIcon/>
                                <div className={s.modal_wrapper}><DealsMessagesModal data={button} recipient={button.recipient}/></div>
                            </div>
                        </div>
                    )}
                    </Draggable>
                )
                setButtonsView(prev => {return[...prev, button_view]})
            })
        }
    },[buttons]);


    // ### FUNCTIONS

    function getCurrentConditions(conditions, data){
        return conditions.map(el => {
            return {
                id: el.id,
                title: el.title,
                value: el.value,
                variables: [
                    ...el.variables.map((variable, variableIndex) => {
                        const isset = data?.conditions?.find(element => element.id === el.id)?.variables?.find(element => element.value === variable.value)
                        if(isset) return {value: variable.value, title: variable.title, checked: true}
                        else return {value: variable.value, title: variable.title}
                    })
                ]
            }
        })
    }

    function changeTitle(value){
        console.log(value);
        setForm(prev => {
            return {
                ...prev,
                title: {...prev.title, ...value}
            }
        })
    }

    function addNewManual(){
        setForm(prev => {
            return {
                ...prev, 
                manual: [
                    ...prev.manual, 
                    {
                        typeState: conditions?.[0]?.id,
                        title: {},
                        message: {},
                        conditions: [],
                        recipient: 0
                    }
                ]
            }
        })
    }

    function setChangeManualCondition(id, value, index, manualindex){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            
            const variables = newState.manual[manualindex].conditions[index].variables?.map(el => {
                if(el.value === id) el.checked = value
                return el
            })
            newState.manual[manualindex].conditions[index].variables = variables
            return newState
        })
    }

    function addNewManualItem(index){
        const newType = conditions.find(el => el.id === form.manual[index].typeState)
        const isset = form.manual[index].conditions.find(el => el.id === newType.id)
        if(isset) return
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState.manual[index].conditions.push(newType)
            return newState
        })
    }

    function removeManual(currentIndex){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState.manual = newState.manual.filter((el, index) => index !== currentIndex)
            console.log(newState.manual[currentIndex]);
            return newState
        })
    }

    function setCurrentNewManualType(index, type){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState.manual[index].typeState = parseInt(type)
            return newState
        })
    }

    function removeManualItem(index, type){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            const updatedConditions = newState.manual[index]?.conditions?.filter(el => el.id !== type)
            newState.manual[index].conditions = updatedConditions
            return newState
        })
    }

    function changeManualTitle(index, value){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState.manual[index].title = {...newState.manual[index].title, ...value}
            return newState
        })
    }

    function changeMessage(index, value){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState.manual[index].message = {...newState.manual[index].message, ...value}
            return newState
        })
    }

    
    function changeManualRecipient(index, value){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState.manual[index].recipient = value.checked ? 1 : 0
            return newState
        })
    }

    function addNewCondition(){
        const newType = conditions.find(el => el.id === typeState)
        const isset = form.conditions.find(el => el.id === newType.id)
        if(isset) return
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState.conditions.push(newType)
            return newState
        })
    }

    function removeCondition(id){
        setForm(prev => {
            const newState = {
                ...prev,
                conditions: prev.conditions.filter(el => el.id !== id)
            }
            return newState
        })
    }

    function setChangeCondition(id, value, index){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            
            const variables = newState.conditions[index].variables?.map(el => {
                if(el.value === id) el.checked = value
                return el
            })
            newState.conditions[index].variables = variables
            return newState
        })
    }

    const onDragEnd = async (result) => {
		if (!result.destination) {
			return
		}
        const source = result.source.droppableId
        const destination = result.destination.droppableId
        const button_id = result.source.index

        if((source === 'droppable3' && destination === 'droppable1') || (source === 'droppable3' && destination === 'droppable2')){
            const current_button = buttons?.find(el => el.id === parseInt(button_id))
            let direction
            if(source === 'droppable3' && destination === 'droppable1') direction = "buttons_payer"
            if(source === 'droppable3' && destination === 'droppable2') direction = "buttons_seller"
            setForm(prev => {
                return{
                    ...prev,
                    [direction]: [
                        ...prev[direction],
                        current_button
                    ]
                }
            })
        }

        if((source === 'droppable1' && destination === 'droppable3') || (source === 'droppable2' && destination === 'droppable3')){
            let direction
            if(source === 'droppable1' && destination === 'droppable3') {
                direction = "buttons_payer"
            }
            if(source === 'droppable2' && destination === 'droppable3') {
                direction = "buttons_seller"
            }
            setForm(prev => {
                return {
                    ...prev,
                    [direction]: prev[direction]?.filter(el => parseInt(el.id) !== parseInt(button_id))
                }
            })
        }
	}

    async function handleSubmit(){
        let error = false
        if(!Object.keys(form.title) || Object.keys(form.title)?.length < langs.length) error = 'Будь ласка, заповнiть "Title"'
        Object.values(form.title)?.forEach(el => {
            if(el.length < 2) error = 'Будь ласка, заповнiть "Title"'
        })

        if(!form.conditions.length) error = 'Будь ласка, заповнiть "Умови показу"'

        if(error){
            setErrorMessage(error)
            return
        }

        const currentManualConditions = form?.manual?.map(manual => {
            const currentConditions = manual?.conditions?.map(el => {
                const currentVariables = el.variables.filter(el => el.checked)
                return {...el, variables: currentVariables?.map(el => {return{title: el.title, value: el.value}})}
            })
            return {title: manual.title, message: manual.message, recipient: manual.recipient, conditions: currentConditions.filter(el => el.variables.length)}
        })

        const currentConditions = form?.conditions?.map(el => {
            const currentVariables = el.variables.filter(el => el.checked)
            return {...el, variables: currentVariables?.map(el => {return{title: el.title, value: el.value}})}
        })
        const filteredConditions = currentConditions.filter(el => el.variables.length)

        const data = {
            ...form,
            messages: currentManualConditions,
            conditions: filteredConditions,
            id: templateId || undefined,
            buttons_payer: form.buttons_payer.map(el => el.id),
            buttons_seller: form.buttons_seller.map(el => el.id)
        }
        let response = false
        if(templateId && templateId.length){
            response = await updateTemplate(data)
        }else{
            response = await createTemplate(data)
        }
        
        if(response.data?.status === 200){
            if(isModal) close()
            else navigate('/deals/messages/template')
        }
        else{
            setErrorMessage(response.error?.data?.message)
        }
    }

    if(isGetTemplateLoading || isConditionsLoading || isLoading) return <Loader/>

    return(
        <div className={`terms ${isModal? s.modal_type : ''}`}>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			
			<h1 className='terms-title'>{t('deals_messages_title_templates')}</h1 >
			
            <div className={`${s.template_wrapper} terms-wrapper`}>
                <div className={s.row}>
                    <TextFieldsBlock title="Title" values={form.title} changeValue={changeTitle}/>
                </div>
                <div className={s.row}>
                    <div className={`${s.header_item} ${s.messages_title}`}>
                        <h3>{t('display_conditions')}</h3>
                    </div>
                    <div className={s.header_item}>
                        <div className={s.condition_wrapper}>
                            <div className={s.content}>
                                <div className={s.left}>
                                    <div className={s.header_item}>
                                        <div className={s.header_item_left}>
                                            <span className={s.label}>{t('add_type')}</span>
                                            <select onChange={(e)=>{setTypeState(parseInt(e.target.value))}}>
                                                {
                                                    conditions?.map((type, index) =>
                                                        <option
                                                            key={index}
                                                            value={type.id}
                                                        >
                                                            {t(`${type.value}`)}
                                                        </option>
                                                    )
                                                }
                                            </select>
                                            <span onClick={addNewCondition}>
                                                <AddPlus/>
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        form?.conditions?.map((variable, indexkey) => {
                                            return(
                                                <div key={indexkey} className={s.list_item}>
                                                    <div className={s.list_item_left}>
                                                        <span className={s.label}>{t(`${variable.value}`)}:</span>
                                                        <div className={s.list_item_values}>
                                                            {
                                                                variable.variables?.map((el, index) => {
                                                                    return(
                                                                        <div key={index} className={s.variable}>
                                                                            <input type='checkbox' checked={el.checked} onChange={(e) => setChangeCondition(el.value, e.target.checked, indexkey)}/>
                                                                            <span>{t(`${el.title}`)}</span>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={s.list_item_right}>
                                                        <div className={s.remove_type_wrapper} onClick={()=>{removeCondition(variable.id)}}>
                                                            <DeleteIcon/>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={`${s.header_item} ${s.messages_title}`}>
                        <h3>{t('messages')}</h3>
                    </div>
                    <Button
                        style={{marginBottom: 20}}
                        type='button'
                        className='add-edit-terms_save d-inline-flex'
                        onClick={addNewManual}
                    >
                        {t('add')}
                    </Button>
                {/* </div>
                <div className={s.row}> */}
                    {
                        form?.manual?.map((el, index) => {
                            return(
                                <div key={index} className={s.condition_wrapper}>
                                    <div className={s.content}>
                                        <div className={s.left}>
                                            <div className={s.header_item}>
                                                <div className={s.header_item_left}>
                                                    <span className={s.label}>{t('add_type')}</span>
                                                    <select onChange={(e)=>{setCurrentNewManualType(index, e.target.value)}}>
                                                        {
                                                            conditions?.map((type, index) =>
                                                                <option
                                                                    key={index}
                                                                    value={type.id}
                                                                >
                                                                    {t(`${type.value}`)}
                                                                </option>
                                                            )
                                                        }
                                                    </select>
                                                    <span onClick={()=>{addNewManualItem(index)}}>
                                                        <AddPlus/>
                                                    </span>
                                                </div>
                                                <div className={s.header_item_right}>
                                                    <span className={`${s.label} ${el.recipient? '' : s.label_active}`}>{t('buyer')}</span>
                                                
                                                        <Form.Check
                                                        type="switch"
                                                        checked={el.recipient}
                                                        onChange={(e)=>changeManualRecipient(index, e.target)}
                                                        />
                                                
                                                    <span className={`${s.label} ${el.recipient? s.label_active : ''}`}>{t('salesman')}</span>
                                                </div>

                                            </div>
                                            {
                                                el.conditions?.map((variable, indexkey) => {
                                                    return(
                                                        <div key={indexkey} className={s.list_item}>
                                                            <div className={s.list_item_left}>
                                                                <span className={s.label}>{t(`${variable.value}`)}:</span>
                                                                <div className={s.list_item_values}>
                                                                    {
                                                                        variable.variables?.map((el, variableIndex) => {
                                                                            return(
                                                                                <div key={variableIndex} className={s.variable}>
                                                                                    <input type='checkbox' checked={el.checked} onClick={(e)=>setChangeManualCondition(el.value, e.target.checked, indexkey, index)}/>
                                                                                    <span>{t(`${el.title}`)}</span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={s.list_item_right}>
                                                                <div className={s.remove_type_wrapper} onClick={() => removeManualItem(index, variable.id)}>
                                                                    <DeleteIcon/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className={s.remove_wrapper} onClick={() => removeManual(index)}>
                                                <DeleteButton/>
                                            </div>
                                        </div>
                                        <div className={s.right}>
                                            <div className={s.item}>
                                                <TextFieldsBlock title="Title" values={el.title} changeValue={(el) => {changeManualTitle(index, el)}}/>
                                            </div>
                                            <div className={s.item}>
                                                <TextFieldsBlock title="Message" values={el.message} changeValue={(el) => {changeMessage(index, el)}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                
                         
                <DragDropContext onDragEnd={onDragEnd}>
                <div className={s.buttons_wrapper}>
                    <div className={s.buttons_wrapper_content}>
                        <div className={s.buttons_column}>
                            <span className={s.buttons_column_title}>{t('buyer')}</span>  
                            <div className={s.arrow_wrapper}><ArrowTop/></div>
                            <Droppable droppableId={"droppable1"} direction="horizontal">
                                {(provided) => (
                                    <div>
                                        <div className={s.buttons_column_content} ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            form.buttons_payer?.map((button, index) => {
                                                let currentStyle
                                                if(button.color === 1) currentStyle = s.accept
                                                if(button.color === 2) currentStyle = s.cancel
                                                if(button.color === 3) currentStyle = s.info
                                                return (
                                                    <Draggable key={index} draggableId={`${button.id}${index}`} index={button.id}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={index} className={`${s.deal_button} ${currentStyle}`}>
                                                            <div className={s.button}>{button.title?.title?.[i18n.language]}</div>
                                                            <div className={s.info_wrapper}>
                                                                <InfoIcon/>
                                                                <div className={s.modal_wrapper}><DealsMessagesModal data={button}/></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    </Draggable>
                                                )
                                            })
                                        }
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div className={s.buttons_column}>
                            <span className={s.buttons_column_title}>{t('salesman')}</span>
                            <div className={s.arrow_wrapper}><ArrowTop/></div>
                            <Droppable droppableId={"droppable2"} direction="horizontal">
                                {(provided) => (
                                    <div>
                                        <div className={s.buttons_column_content} ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            form.buttons_seller?.map((button, index) => {
                                                let currentStyle
                                                if(button.color === 1) currentStyle = s.accept
                                                if(button.color === 2) currentStyle = s.cancel
                                                if(button.color === 3) currentStyle = s.info
                                                return (
                                                    <Draggable key={index} draggableId={`${index}${button.id}`} index={button.id}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={index} className={`${s.deal_button} ${currentStyle}`}>
                                                            <div className={s.button}>{button.title?.title?.[i18n.language]}</div>
                                                            <div className={s.info_wrapper}>
                                                                <InfoIcon/>
                                                                <div className={s.modal_wrapper}><DealsMessagesModal data={button}/></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    </Draggable>
                                                )
                                            })
                                        }
                                        </div>
                                        {provided.placeholder}
                                    </div> 
                                )}
                            </Droppable>
                        </div>
                    </div>
                </div>
                <div className={s.buttons_wrapper}>
                    <div className={s.buttons_wrapper_content}>
                        <div className={s.buttons_column_bottom}>
                            <Droppable droppableId={"droppable3"} direction="horizontal">
                                {(provided) => (
                                    <div>
                                        <div className={s.buttons_column_content_bottom} ref={provided.innerRef} {...provided.droppableProps}>
                                            {buttonsView}
                                        </div>   
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                </div>
                </DragDropContext>
            </div>
            <footer className={s.footer}>
                <button
                    type='submit'
                    className='template-content_buttons-save btn btn-primary'
                    onClick={handleSubmit}
                >
                        {(isCreateLoading || isUpdateLoading)? <LoaderIcon/> : t('save')}
                </button >
                <button
                    type='button'
                    className='template-content_buttons-close btn btn-primary me-3'
                    onClick={()=>isModal? close() : navigate('/deals/messages/template')}
                >
                    {t('back')}
                </button >
            </footer>
        </div>
    )
}