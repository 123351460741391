import React, { useMemo } from 'react'
import s from './notificationList.module.scss';
import emailIcon from '../../../../utils/icons/email2_icon.png'
import { ReactComponent as TickIcon } from '../../../../utils/icons/tick_icon.svg'
import menuIcon from '../../../../utils/icons/vertical-dots.svg'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { transformDate } from '../../../../utils/helpers/TransformDate'
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Loader } from '../../../../utils/icons/loading-icon.svg';
import { ReactComponent as WarningIcon } from '../../../../utils/icons/warning-icon.svg'
import { useTranslation } from 'react-i18next';

const NotificationList = ({item, toggleDeleteModal, setCurrentNotification, openAddEditEmailList, openAddEditViberList, openAddEditSMSList, setIsDoubleNotification}) => {

	const navigate = useNavigate()
	const modal = useRef()
	const {t} = useTranslation()
	
	const resProcent = useMemo(() => {
		let res = null
		const procent = (item?.statistics?.conversion / item?.statistics?.quantity) * 100
		
		return procent
	}, [item?.statistics])

	function toggleModal(){
		modal.current.classList.toggle(s.active)
	}

	function openAddEmail(el){
		if(el.id === "1") setIsDoubleNotification(true)
		setCurrentNotification(item)
		if(item.senderType === "email") openAddEditEmailList(true)
		if(item.senderType === "viber") openAddEditViberList(true)
		if(item.senderType === "sms") openAddEditSMSList(true)
	}

	return (
		<>
			{
				<div className={s.notification}>
					<div className={s.left}>
						<div className={s.img_wrapper}>
							<img
								src={emailIcon}
								alt='email_icon'
							/>
							{item.inProcess && item.validation? <Loader/> : null}
							{!item.inProcess && item.validation? item.start < new Date().getTime() && <TickIcon /> : null}
							{!item.validation && item.type === 0 ? <WarningIcon/> : null}
						</div >
					</div >
					<div className={s.right}>
						<div className={s.top}>
							<div>
								<span className={s.send_date}>{t('created')}: {item?.start && transformDate(item?.created)}</span >
								{
								!item.validation && item.type === 0 ?
								<span className={s.validation} onClick={()=>navigate("/notifications/validation")}><WarningIcon/> {t('ns_not_valid')} <span className={s.validation_link}>Validation</span></span>
								: null
								}
							</div>
							<div className={s.img_wrapper} onClick={toggleModal}>
								<img
									src={menuIcon}
									alt='menu_icon'
								/>
								<div className={ s.tools_modal } ref={modal}>
									<div className={s.item }>
											<span onClick={ () => {openAddEmail(item)}}>{t('edit')}</span >
									</div >
									<div className={s.item }>
											<span id="1" onClick={ (ev) => {openAddEmail(ev.target)}}>{t('duplicate')}</span >
									</div >
									<div className={ `${ s.item } ${ s.delete }` }>
										<span onClick={() => {toggleDeleteModal(item)}}>{t('delete')}</span >
									</div >
									<div className={ s.modal_wrapper } onClick={()=>{}}></div >
								</div >
							</div >
						</div >
						<div className={s.center}>
							<div className={s.left}>
								<div className={s.item}>
									<span className={`${s.value} ${s.value_name}`} onClick={()=>navigate(`/notification?id=${item.id}`)}>{item.type === 4 || item.type === 1? item?.title[0]?.body?.ua: item?.title[0]?.title?.ua}</span >
									<span className={s.desc}>{item?.group}</span >
								</div >
							</div>
							<div className={s.right}>
								<div className={s.item}>
									<span className={s.value}>{item?.statistics?.quantity}
										{/*<span className={ s.procent }>-1.5%</span >*/}
									</span >
									<span className={s.desc}>{t('count')}</span >
								</div >
								<div className={s.item}>
									<span className={s.value}>{item?.statistics?.successful}
										{/*<span className={ s.procent }>-1.5%</span >*/}
									</span >
									<span className={s.desc}>{t('sended')}</span >
								</div >
								<div className={s.item}>
									<span className={s.value}>{item?.statistics?.errors}
										{/*<span className={ s.procent }>-1.5%</span >*/}
									</span >
									<span className={s.desc}>{t('sended')}</span >
								</div >
								{
									item.senderType === "sms"? null :
									<div className={s.item}>
										<span className={s.value}>{item?.statistics?.conversion}</span >
										<span className={s.desc}>{t('transitions')}</span >
									</div >
								}
							</div>
						</div >
						{
							item.senderType === "sms"? null :
							<div className={s.bottom}>
								<div className={s.progress_bar}>
									<ProgressBar
										variant={resProcent < 100 ? resProcent < 30 ? 'danger' : 'warning' : 'success'}
										now={resProcent}
									/>
								</div >
								<div className={s.result}>
									{
										resProcent < 100
											?
											<span >{`${parseInt(resProcent)}%`}</span >
											:
											resProcent === Number('Infinity')
												?
												<>
													<span >{`${parseInt('0')}%`}</span >
												</>
												:
												<TickIcon />
									}
								</div >
							</div >
						}
					</div >
				</div >
			}
		</>
	)
}
export default NotificationList