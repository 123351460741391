import React from 'react';
import s from './pricesInfoModal.module.scss'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const PricesInfoModal = ({item})=>{
    const {i18n} = useTranslation()
    return(
        <div className={s.modal_wrapper}>
            {
                item?.templates?.slice(0,50).map((el, index) => {
                    if(!el){return null}
                    return(
                        <span >{el[i18next.lang]}</span > 
                    )
                })
            }
        </div>
    )
}