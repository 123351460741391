import { createSlice } from "@reduxjs/toolkit"
import { rulesApi } from "../apis/rulesApi"

const initialState = {
	rules: {},
}

const rulesSlice = createSlice({
	name: "rules",
	initialState,
	reducers: {
		createNewRulesItem: (state, action) => {
			state.rules = [action.payload, ...state.rules]
		},
		editRulesItem: (state, action) => {
			const index = state.rules.findIndex(
				(term) => {
					return term.id === action.payload.id
				}
			)
			state.rules[index] = action.payload
		},
		deleteRulesItem: (state, action) => {
			const termId = action.payload
			const termID = state.rules.filter(term => term.id !== termId)
			state.rules = termID
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(rulesApi.endpoints.getRules.matchFulfilled, (
					state,
					action
				) => {
					state.rules = action.payload
				}
			)
	}
})

const {
	actions,
	reducer
} = rulesSlice
export const {
	createNewRulesItem,
	deleteRulesItem,
	editRulesItem,
} = actions
export default reducer
