import {createSlice} from "@reduxjs/toolkit"
import {termsApi} from "../apis/termsApi"

const initialState = {
	terms: [],
	alert: null,
	showModalChangeTerms: false,
	dateModalChangeTerms: ''
}

const termsSlice = createSlice({
	name: "terms",
	initialState,
	reducers: {
		createNewTerm: (state, action) => {
			state.terms = [action.payload, ...state.terms]
		},
		getTermsList: ( state, action ) => {
			state.terms = action.payload
		},
		editTermItem: (state, action) => {
			const index = state.terms.findIndex(
				(term) => {
					return term.id === action.payload.id
				}
			)
			state.terms[index] = action.payload
		},
		deleteTermItem: (state, action) => {
			const termId = action.payload
			const termID = state.terms.filter(term => term.id !== termId)
			state.terms = termID
		},
		setAlert: (state, action) => {
			state.alert = action.payload
		},
		showChangeTermsModal: (state, action) => {
			state.showModalChangeTerms = action.payload
		},
		setDateChangeTerms: (state, action) => {
			state.dateModalChangeTerms = action.payload
		},
		resetDateChangeTerms: (state, action) => {
			state.dateModalChangeTerms = {}
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(termsApi.endpoints.getTerms.matchFulfilled, (
					state,
					action
				) => {
					state.terms = action.payload.data
				}
			)
	}
})

const {
	actions,
	reducer
} = termsSlice
export const {
	getTermsList,
	createNewTerm,
	deleteTermItem,
	editTermItem,
	setAlert,
	showChangeTermsModal,
	setDateChangeTerms,
	resetDateChangeTerms
} = actions
export default reducer
