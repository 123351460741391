import {
    Button,
    Form,
    Modal
} from "react-bootstrap"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import Loader from "../Loader"
import AlertModal from "../AlertModal"
import { ARE_COMMON, INPUTS_TEXT } from "../../../utils/textsContent";
import {useCreateReferralLinkMutation, useGetReferralLinksMutation} from "../../store/apis/referralLinksApi";
import {addReferralLink} from "../../../utils/validation/YupReferralLink";
import { useTranslation } from "react-i18next"

const initialState = {
    name: '',
    link: '',
}

const AddEditReferralLinks = ( { show, onHide, editDateForModal, getNewLinks } ) => {
    const [ form, setForm ] = useState( initialState )
    const {t} = useTranslation()

    const [ createReferralLink, { isLoading: isCreateBodyTypeLoader } ] = useCreateReferralLinkMutation()
    const [ getReferralLinksMutation ] = useGetReferralLinksMutation();

    const [ errorMessage, setErrorMessage ] = useState( '' )

    const isLoading = isCreateBodyTypeLoader

    const formDateUpdateHandler = ( opt ) => {
        setForm( { ...form, ...opt } )
    }

    const handleSubmit = async ( values ) => {
        const formDate = {
            name: form?.name || values?.name,
            link: form?.link || values?.link,
        }
        try {
            const {
                data,
                error
            } = await createReferralLink( formDate )
            !error && getNewLinks()
            error && setErrorMessage( error?.data?.mes )
            !error && await clearFormHideModal()
            !error && await getReferralLinksMutation(0)
        } catch ( e ) {
            console.log( e )
            setErrorMessage( e )
        }
    }

    const clearFormHideModal = async () => {
        onHide()
        setForm( initialState )
    }

    return (
        <Modal
            show={ show }
            onHide={ clearFormHideModal }
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            className='add-edit-terms_modal'
            style={ { zIndex: '99999' } }
        >
            {
                errorMessage
                &&
                <AlertModal
                    content={ errorMessage }
                    setErrorMessage={ setErrorMessage }
                />
            }
            <Formik
                validateOnChange
                initialValues={ {
                    name: form?.name || editDateForModal?.name,
                    link: form?.link || editDateForModal?.link
                } }
                validationSchema={addReferralLink}
                onSubmit={ handleSubmit }
                enableReinitialize
            >
                {
                    ( {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          isValid,
                          handleSubmit,
                          dirty
                      } ) => (
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title
                                    id='contained-modal-title-vcenter'
                                    className='add-edit-terms_title'
                                >
                                    {t('create')}
                                </Modal.Title >
                            </Modal.Header >
                            <Modal.Body >
                                <Form
                                    className='add-edit-terms'
                                    onSubmit={ handleSubmit }
                                >
                                    <div className='add-edit-terms_top'>
                                        <Form.Group
                                            className='add-edit-terms_label w-100'
                                        >
                                            {t('name')}
                                            <Form.Control
                                                type='text'
                                                name='name'
                                                onBlur={ handleBlur }
                                                defaultValue={ values.name }
                                                autoFocus
                                                onKeyDown={ e => {
                                                    e.key === 'Enter' && e.preventDefault()
                                                } }
                                                className={ `add-edit-terms_center-inp  ${ touched?.name ? "is-touch " : "" } ${
                                                    errors?.name && touched?.name ? " is-invalid" : ""
                                                } ` }
                                                onChange={ ( e ) => {
                                                    handleChange( e )
                                                    formDateUpdateHandler( {
                                                        [e.target.name]: e.target.value
                                                    } )
                                                } }
                                            />
                                            { errors.name && touched.name && (
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.name }
                                                </Form.Control.Feedback >
                                            ) }
                                        </Form.Group >
                                    </div >
                                    <div className='add-edit-terms_top mt-2 mb-2'>
                                        <Form.Group
                                            className='add-edit-terms_label w-100'
                                        >
                                            {t('link')}
                                            <Form.Control
                                                type='text'
                                                name='link'
                                                onBlur={ handleBlur }
                                                defaultValue={ values.link }
                                                onKeyDown={ e => {
                                                    e.key === 'Enter' && e.preventDefault()
                                                } }
                                                className={ `add-edit-terms_center-inp  ${ touched?.link ? "is-touch " : "" } ${
                                                    errors?.link && touched?.link ? " is-invalid" : ""
                                                } ` }
                                                onChange={ ( e ) => {
                                                    handleChange( e )
                                                    formDateUpdateHandler( {
                                                        [e.target.name]: e.target.value
                                                    } )
                                                } }
                                            />
                                            { errors.link && touched.link && (
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.link }
                                                </Form.Control.Feedback >
                                            ) }
                                        </Form.Group >
                                    </div >
                                </Form >
                            </Modal.Body >
                            <Modal.Footer >
                                <Button
                                    type='button'
                                    className='add-edit-terms_close'
                                    onClick={ clearFormHideModal }
                                >
                                    {t('cancel')}
                                </Button >
                                <Button
                                    type='submit'
                                    className='add-edit-terms_save d-inline-flex'
                                    style={ {
                                        maxHeight: '33px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    } }
                                    disabled={ ( !isValid && dirty && isLoading) }
                                    onClick={ handleSubmit }
                                >
                                    {
                                        isLoading ? <Loader /> : t('add')
                                    }
                                </Button >
                            </Modal.Footer >
                        </>
                    )
                }
            </Formik >
        </Modal >
    )
}

export default AddEditReferralLinks
