import React from 'react'
import {
	Button,
	Modal
} from "react-bootstrap"
import AlertIconInfo from "../../utils/icons/moderation-icon.svg"
import { ARE_COMMON } from "../../utils/textsContent";

const ProofOfPaymentModal = ({
	show,
	handleClose
}) => {
	return (
		<Modal
			show={show}
			onHide={handleClose}
			centered
			aria-labelledby='contained-modal-title-vcenter'
			className='add-edit-terms_modal'
		>
			<Modal.Header closeButton>
				<Modal.Title >
					Підтвердження оплати
				</Modal.Title >
			</Modal.Header >
			<Modal.Body >
				<div className='noticeItem-wrapper_status-alert statusInfo mt-0'>
					<div className='noticeItem-wrapper_status-alert_img  statusInfo_img'>
						<img
							src={AlertIconInfo}
							alt='Alert'
						/>
					</div >
					<div className='noticeItem-wrapper_status-alert_block'>
						<h3 className='noticeItem-wrapper_status-alert_block_h3 statusInfo_h3'>
							Увага
						</h3 >
						<p className='noticeItem-wrapper_status-alert_block_text'>
							Ви впевнені, що хочете підтвердити оплату
							<b > за Ордером 12345svsgsg на суму 900 грн?</b >
						</p >
					</div >
				</div >
			</Modal.Body >
			<div className='d-flex px-3'>
				<Button
					className='moderation-payment_block-buttons_but w-75'
					// onClick={handleClose}
				>
					Підтвердити оплату 900 грн.
				</Button >
				<Button
					className='moderation-payment_block-buttons_but ms-3 w-25'
					onClick={handleClose}
				>
					{ARE_COMMON?.ABOLITION}
				</Button >

			</div >
		</Modal >
	)
}

export default ProofOfPaymentModal