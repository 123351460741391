import AddEditTermsItem from "../../components/modalsAddEdit/AddEditTermsItem"
import React, {
	useEffect,
	useState
} from "react"
import DeleteModal from "../../components/DeleteModal"
import ControlPanelsTerms from "../../components/environmentTabl/ControlPanelsTerms"
import AlertModal from "../../components/AlertModal"
import { PAGES_NAME } from "../../../utils/textsContent"
import { useGetLangsQuery } from "../../store/apis/langApi"
import { useGetTermsListQuery } from "../../store/apis/termsApi"
import { useTranslation } from "react-i18next"

const TermsPage = () => {
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ openEditModal, setOpenEditModal ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )
	const [ termsListArr, setTermsListArr ] = useState( [] )
	const [ countShow, setCountShow ] = useState( 20 )
	const [page, setPage] = useState(1)
	const [sort, setSort] = useState(3)
	const [order, setOrder] = useState(-1)
	const [search, setSearch] = useState(undefined)
	const [group, setGroup] = useState(undefined)
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const {t} = useTranslation()

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )
	const [termsQuery, setTermsQuery] = useState(
		{
			limit: countShow, 
			page,
			sort,
			order,
			search,
			group
		}
	)

	const {data: langs} = useGetLangsQuery()
	const {data: termsList, isFetching} = useGetTermsListQuery(termsQuery)
	
	useEffect(()=>{
		if(termsList?.data){
			setTermsListArr(termsList.data)
		}
	},[termsList]);

	useEffect(()=>{
		if((!termsQuery.search || !termsQuery.search.length) && (search || search?.length)){
			setPage(1)
			setTermsQuery({...termsQuery, limit: countShow, page: 1, sort, order, search, group})
		}
		else{
			setTermsQuery({...termsQuery, limit: countShow, page, sort, order, search, group})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[countShow, page, sort, order, search, group]);

	useEffect(()=>{
		console.log("termsListArr", termsListArr);
	},[termsListArr]);

	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
		setOpenEditModal( false )
	}
	const hideModalHandler = () => {
		setAddEditModalShow( false )
		setOpenEditModal( false )
		setEditDateForModal( {} )
	}
	const toggleDeleteModal = ( value ) => {
		setDeleteTerm( value )
		setDeleteModalShow( !deleteModalShow )
	}

	const openEditModalHandler = ( value ) => {
		setEditDateForModal( value )
		setAddEditModalShow( true )
		setOpenEditModal( true )
	}
	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}

	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}

	return (
		<div className='terms'>
			{
				errorMessage && <AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<AddEditTermsItem
				show={ addEditModalShow }
				onHide={ hideModalHandler }
				edit={ openEditModal }
				editDateForModal={ editDateForModal }
				langs={langs}
			/>
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ deleteTerm }
				fromPage={ 'term' }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				returnErrorForDel={ returnErrorForDel }
			/>
			<h1 className='terms-title'>
				{t('s_terms')}
			</h1 >
			<ControlPanelsTerms
				bigArrElements={ termsListArr }
				showModalHandler={ showModalHandler }
				openEditModalHandler={ openEditModalHandler }
				isLoading={isFetching}
				toggleDeleteModal={ toggleDeleteModal }
				totalPages={termsList?.totalPages}
				totalCount={termsList?.totalCount}
				currentPage={page}
				setCurrentPage={setPage}
				countShow={countShow}
				setCountShow={setCountShow}
				sort={sort}
				setSort={setSort}
				order={order}
				setOrder={setOrder}
				setSearch={setSearch}
				setGroup={setGroup}
				fromPage={ 'term' }
				searchTermValue={ searchTermValue }
				loaderSorting={ loaderSorting }
				setLoaderSorting={ setLoaderSorting }
				toggleModalSearch={ toggleModalSearch }
				showModalSearch={ showModalSearch }
				showSearchBeforeDel={ showSearchBeforeDel }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				valueSearchInputHandler={ valueSearchInputHandler }
			/>
		</div >
	)
}

export default TermsPage
