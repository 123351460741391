import React from 'react';
import s from './rbacRoleEditModal.module.scss';
import { Button, Form, Modal } from 'react-bootstrap';
import {ReactComponent as LoadingIcon} from '../../../utils/icons/loading-icon.svg';
import { PAGES_IDS } from '../../../utils/constants';
import { useState } from 'react';
import { useEffect } from 'react';
import AlertModal from '../AlertModal';
import { useAddUsersRoleMutation, useEditUsersRoleMutation } from '../../store/apis/rbacApi';
import { useTranslation } from 'react-i18next';

export const RBACRoleEditModal = ({roleData, hide, allPermissions})=>{

    const [ errorMessage, setErrorMessage ] = useState( '' )
    const [permissionsState, setPermissionsState] = useState({})
    const [pages, setPages] = useState({})
    const [role, setRole] = useState('');
    const {t} = useTranslation()

    const [addRole, {isLoading: isAddRoleLoading}] = useAddUsersRoleMutation()
    const [editRole, {isLoading: isEditRoleLoading}] = useEditUsersRoleMutation()

    const changePages = (id, action) => {
        if(action) setPages({...pages, ...{[`${id}`]: id}})
        else{
            setPages(prev => {
                const newState = {...prev}
                delete newState[`${id}`]
                return newState
            })
        }
    }

    useEffect(()=>{
        if(roleData.title){
            setRole(roleData.title)
            const currentPages = {}
            roleData.permissionsIds.forEach(el => {
                 currentPages[`${el}`] = el
                })
            setPages(currentPages)
        }
    },[]);
    
    useEffect(()=>{
        if(allPermissions){
            for(let key in PAGES_IDS){
                setPermissionsState(prev => {return {...prev, [`${PAGES_IDS[key].name}`]: {name: PAGES_IDS[key].name, pages: []}}})
                PAGES_IDS[key].pages.forEach(pageId => {
                    allPermissions.forEach(per => {
                        if(per.id === pageId) setPermissionsState(prev => {
                            const current_pages = JSON.parse(JSON.stringify(prev[PAGES_IDS[key].name].pages))
                            const current_name = PAGES_IDS[key].name
                            current_pages.push(per)
                            return {...prev, [`${PAGES_IDS[key].name}`]: {name: current_name, pages: current_pages}}
                        })
                    })
                })
            }
        }
    },[allPermissions]);

    async function sendRole(){
        if(role.length < 2){
            setErrorMessage('Будь ласка, заповнiть назву посади')
            return
        }
        const response = roleData.title? 
        await editRole({pages: Object.entries(pages).map(el => el[1]), name: role, id: roleData.id})
        :
        await addRole({pages: Object.entries(pages).map(el => el[1]), role: role})
        if(response.data === "success"){
            hide(false)
        }
    }

    const permissions_view = Object.entries(permissionsState).map((el, index) => {
        return(
            <div key={index} className={s.page}>
                <p className={s.title}>{el[1].name}</p>
                <div className={s.row}>
                {
                    el[1].pages.map((el, index) => {
                        return(
                            <div key={index} className={s.item}>
                                <input type="checkbox"
                                    checked={pages[el.id]}
                                    onChange={(ev)=>{changePages(el.id, ev.target.checked)}}
                                />
                                <p>{el.title}</p>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        )
    })

    return(
        
        <Modal
            show={ true }
            onHide={ ()=> hide(false) }
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            className='add-edit-terms_modal'
            style={ { zIndex: '99999' } }
            >

            {
            errorMessage
            &&
            <AlertModal
                content={ errorMessage }
                setErrorMessage={ setErrorMessage }
            />
			}

            <Modal.Header
                closeButton
            >
                <Modal.Title
                    id='contained-modal-title-vcenter'
                    className='add-edit-terms_title'
                >
                    {roleData?.title? roleData.title : "New position"}
                </Modal.Title >
            </Modal.Header >
            <Modal.Body >
                <Form
                    className='add-edit-terms'
                    // onSubmit={ handleSubmit }
                >
                    <Form.Group className='add-edit-terms_label add-edit-rbac_label'>
                            Position
                            <Form.Control
                                type='text'
                                name='name'
                                // onBlur={ handleBlur }
                                onKeyDown={ e => {
                                    e.key === 'Enter' && e.preventDefault()
                                } }
                                value={role}
                                onChange={ev => setRole(ev.target.value)}
                                className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
                            />
                    </Form.Group >
                    <Form.Group className='add-edit-terms_label add-edit-rbac_label'>
                            <div className={s.permissions_wrapper}>
                               {permissions_view}
                            </div>
                    </Form.Group >
                </Form >
            </Modal.Body >
            <Modal.Footer >
                <Button
                    type='submit'
                    className='add-edit-terms_save add-edit-rbac_button d-inline-flex'
                    style={ {
                        maxHeight: '33px',
                        display: 'flex',
                        alignItems: 'center'
                    } }
                    onClick={sendRole}
                >
                        {isAddRoleLoading || isEditRoleLoading? <LoadingIcon/> : roleData.title? 'Edit' : 'Create post'}
                </Button >
            </Modal.Footer >
        </Modal >
        
    )
}