import React from 'react'
import { Form } from 'react-bootstrap'
import { Formik } from 'formik'
import CreatePollName from './CreatePollName'
import CreatePollQuestion from './CreatePollQuestion'
import { ARE_COMMON } from "../../../../utils/textsContent";

const CreatePollPage = ( { toggleShowCreatePollPageHandler } ) => {
	const form = {}

	const handleSubmit = async ( { values } ) => {
	}
	return (
		<div className='terms'>
			<h1 className='terms-title'>{ `${ ARE_COMMON?.CREATED } форми опитування` }</h1 >
			<Formik
				validateOnChange
				initialValues={ {
					type: form?.type,
				} }
				onSubmit={ handleSubmit }
				enableReinitialize
			>
				{ ( {
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					isValid,
					handleSubmit,
					dirty
				} ) => (
					<Form
						className='add-edit-terms'
						onSubmit={ handleSubmit }
					>
						<div className='terms-wrapper poll'>
							<div className='poll-create'>
								<CreatePollName
									handleBlur={handleBlur}
									form={form}
									touched={touched}
									errors={errors}
									values={values}
									handleChange={handleChange}
									// formDateUpdateHandler={formDateUpdateHandler}
								/>

								<CreatePollQuestion
									handleBlur={handleBlur}
									form={form}
									touched={touched}
									errors={errors}
									values={values}
									handleChange={handleChange}
									// formDateUpdateHandler={formDateUpdateHandler}
								/>
							</div >

							<div className='categories-create_block-buttons'>
								<button
									type='button'
									className='template-content_buttons-close btn btn-primary me-3'
									onClick={ toggleShowCreatePollPageHandler }
								>
									{ARE_COMMON?.CANCEL}
								</button >
								<button
									type='submit'
									className='template-content_buttons-save btn btn-primary'
									onClick={ handleSubmit }
								>
									{ARE_COMMON?.SAVE}
								</button >
							</div >

						</div >
				</Form >
				) }
			</Formik >
		</div >
	)
}

export default CreatePollPage