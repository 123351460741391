import React from 'react';
import s from './permissionError.module.scss';
import { ReactComponent as ErrLogo } from '../../../utils/assets/images/permissionError.svg'

export const PermissionError = ()=>{

    return(
        <div className={s.error}>
            <ErrLogo/>
            <h2>Вибачте, у Вас недостатньо доступу для перегляду цієї сторінки</h2>
        </div>
    )
}