import { Table } from "react-bootstrap"
import verticalDots from '../../../utils/icons/vertical-dots.svg'
import ArrowSorting from '../../../utils/icons/arrow-sorting.svg'
import TermsTablItem from "./TermsTablItem"
import {
	ARE_COMMON,
	PAGES_NAME
} from "../../../utils/textsContent";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const TermsTabl = ( {
	setOpenEditModal,
	toggleDeleteModal,
	sortedForColumn,
	countShowTerms,
	showSmallTermArr,
	countShowPage,
	fromPage,
	setItem,
	setOrder = ()=>{}
} ) => {

	const { dateModalChangeUnitTemplatesList} = useSelector(state => state.templateContentStore)
	const {t} = useTranslation()
	
	return (
		<>
			<div className='terms-wrapper_itemsInfo'>
					<span className='terms-wrapper_itemsInfo-content'>
						{t('showing')} { countShowTerms } {t('items_from')} { countShowPage }
					</span >
			</div >
			<Table
				responsive
				className='terms-wrapper_tabl'
				striped={ true }
			>
				<thead className='terms-wrapper_tabl-head'>
				<tr className='terms-wrapper_tabl-trow'>
					<th
						className='terms-wrapper_tabl-trow_item col-1'
						onClick={ () => {
							setOrder(prev => {
								if(prev  === 1) return -1
								else return 1
							})
						} }
					>
						<span >ID</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className={ `terms-wrapper_tabl-trow_item ${ (
							fromPage === 'term' ||
							fromPage === 'htmls' ||
							fromPage === 'pages' ||
							fromPage === 'price' ||
							fromPage === 'mark'
						) ? 'col-8' : 'col-10' }` }
						onClick={ () => {
							setOrder(prev => {
								if(prev  === 1) return -1
								else return 1
							})
						} }
					>
						<span >
							{
							(fromPage === 'term' && `Term`) ||
							((fromPage === 'unit' || fromPage === 'ChangeUnitGroups') && `UnitGroups`) ||
							(fromPage === 'changeUnitForTemplateDiscount' && `Unit`) ||
							(fromPage === 'price' && `Price`) ||
							(fromPage === 'amount' && `Amount`) ||
							(fromPage === 'htmls' && 'Htmls') ||
							(fromPage === 'pages' && 'Pages') ||
							(fromPage === 'changeTermsForTemplate' && `Terms`) ||
							(fromPage === 'changeUnitForPrice' && `Price`) ||
							(fromPage === 'changeUnitForPricesArrTemplate' && `Price`) ||
							(fromPage === 'changeUnitForPricesArr' && `Price`) ||
							(fromPage === 'changeHTMLSForTemplate' && 'Htmls') ||
							(fromPage === 'unitGroups' && 'UnitGroups') ||
							(fromPage === 'changeUnitForUnit' && 'UnitGroups') ||
							(fromPage === 'category' && `Name`) ||
							(fromPage === 'subcategory' && `Name`) ||
							(fromPage === 'subcategory1' && `Name`) ||
							(fromPage === 'subcategory2' && `Name`) ||
							(fromPage === 'mark' && `Name`) ||
							(fromPage === 'changeMarkForModel' && `Name`) ||
							(fromPage === 'changeMarKForModalCreate' && `Name`) ||
							(fromPage === 'model' && `Name`) ||
							(fromPage === 'bodyType' && `Name`) ||
							(fromPage === 'transportCategory' && `Name`) ||
							(fromPage === 'changeTransportCategoryForModel' && `Name`) ||
							(fromPage === 'changeBodyTypeForModel' && `Name`) ||
							(fromPage === 'changeUnitForTemplates' && `Name`) ||
							(fromPage === 'changeUnitForTemplatesSelect' && `Name`)
							}
						</span >
					</th >
					{
						fromPage === 'term'
							?
							<>
								<th
									className='terms-wrapper_tabl-trow_item col-2'
									onClick={ () => {
										setOrder(prev => {
											if(prev  === 1) return -1
											else return 1
										})
									} }
								>
									<span >
										System group
									</span >
									<img
										src={ ArrowSorting }
										alt='Arrow Sorting'
									/>
								</th >
								<th
									className='terms-wrapper_tabl-trow_item col-2'
									onClick={ () => {
										setOrder(prev => {
											if(prev  === 1) return -1
											else return 1
										})
									} }
								>
									<span >
										Mark
									</span >
									<img
										src={ ArrowSorting }
										alt='Arrow Sorting'
									/>
								</th >
							</>
							:
							<></>
					}
					{
						fromPage === 'htmls'
						&&
						<th
							className='terms-wrapper_tabl-trow_item col-2'
							onClick={ () => {
								setOrder(prev => {
									if(prev  === 1) return -1
									else return 1
								})
							} }
						>
							<span >Mark</span >
							<img
								src={ ArrowSorting }
								alt='Arrow Sorting'
							/>
						</th >
					}
					{
						(fromPage === 'price' || fromPage === 'changeUnitForPricesArrTemplate')
						&&
						<>
							<th
								className='terms-wrapper_tabl-trow_item col-1'
								onClick={ () => {
									setOrder(prev => {
										if(prev  === 1) return -1
										else return 1
									})
								} }
							>
								<span >Unitlist</span >
								<img
									src={ ArrowSorting }
									alt='Arrow Sorting'
								/>
							</th >
							<th>Value</th>
						</>
					}
					{
						fromPage === 'pages'
						&&
						<th
							className='terms-wrapper_tabl-trow_item col-2'
							onClick={ () => {
								setOrder(prev => {
									if(prev  === 1) return -1
									else return 1
								})
							} }
						>
							<span >Screen</span >
							<img
								src={ ArrowSorting }
								alt='Arrow Sorting'
							/>
						</th >
					}
					{
						fromPage === 'changeUnitForTemplates'
						&&
						<th className='terms-wrapper_tabl-trow_item col-2'>
							<span >Units</span >
							<img
								src={ ArrowSorting }
								alt='Arrow Sorting'
							/>
						</th >
					}
					{
						(
							fromPage !== 'changeUnitForPrice' &&
							fromPage !== 'changeUnitForPricesArr' &&
							fromPage !== 'changeUnitForPricesArrTemplate' &&
							fromPage !== 'changeUnitForAmount' &&
							fromPage !== 'changeUnitForTemplates' &&
							fromPage !== 'changeUnitForTemplatesSelect' &&
							fromPage !== 'changeUnitForUnit' &&
							fromPage !== 'changeTermsForTemplate' &&
							fromPage !== 'changeHTMLSForTemplate' &&
							fromPage !== 'ChangeUnitGroups' &&
							fromPage !== 'changeMarkForModel' &&
							fromPage !== 'changeMarKForModalCreate' &&
							fromPage !== 'changeBodyTypeForModel' &&
							fromPage !== 'changeUnitForTemplateDiscount' &&
							fromPage !== 'changeTransportCategoryForModel'
						)
						&&
						<th className='terms-wrapper_tabl-trow_item col-2'>
							<img
								src={ verticalDots }
								alt='Dots'
							/>
						</th >
					}
				</tr >
				</thead >
				<tbody
					className='terms-wrapper_tabl-body'
				>
				{
					showSmallTermArr && showSmallTermArr?.map( item => <TermsTablItem
						key={ item?.id }
						item={ item }
						setOpenEditModal={ setOpenEditModal }
						toggleDeleteModal={ toggleDeleteModal }
						fromPage={ fromPage }
						setItem={setItem}
						isChecked={(()=>{
							if(fromPage === 'changeUnitForTemplatesSelect'){
								if(dateModalChangeUnitTemplatesList.includes(item.id)){
									return true
								}
								else return false
							}
						})()}
					/> )
				}
				</tbody >
			</Table >
		</>
	)
}

export default TermsTabl
