import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	MODEL_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const modelApi = createApi({
	reducerPath: "modelApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),

	endpoints: (builder) => ({
		getModel: builder.mutation({
			query: () => (
				{
					url: MODEL_API.GET_MODEL,
					method: "GET",
				})
		}),
		createModel: builder.mutation({
			query: (body) => (
				{
					url: MODEL_API.CREATE_MODEL,
					method: "POST",
					body
				})
		}),
		editModel: builder.mutation({
			query: (body) => (
				{
					url: MODEL_API.EDIT_MODEL,
					method: "POST",
					body
				})
		}),
		deleteModel: builder.mutation({
			query: (body) => (
				{
					url: MODEL_API.DELETE_MODEL,
					method: "POST",
					body
				})
		}),
	})
})

export const {
	useGetModelMutation,
	useCreateModelMutation,
	useEditModelMutation,
	useDeleteModelMutation,


} = modelApi
