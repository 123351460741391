import * as yup from "yup"

export const addPriceSchema = () => {
	return yup.object().shape({
		uaTitle: yup
			.string()
			.required('Заголовок ua обов\'язковий!'),
		ruTitle: yup
			.string()
			.required('Заголовок является обязательным!'),
		enTitle: yup
			.string()
			.required('Title is required!'),
		plTitle: yup
			.string()
			.required('Tytuł jest wymagany!'),
		precision: yup
			.string()
			.max(6, 'max 6'),
		unit: yup
			.number()
			.required('Обов\'язкове'),
		isnum: yup
			.boolean(),
	})
}
