import React from 'react'
import {
	Button,
	Modal
} from "react-bootstrap"
import { useDeleteTermsMutation } from "../store/apis/termsApi"
import Loader from "./Loader"
import { useDispatch } from "react-redux"
import {
	deleteTermItem,
	setAlert
} from "../store/slices/termsSlice"
import {
	useDeleteUnitsMeasurementMutation,
	useGetUnitsMeasurementMutation
} from "../store/apis/unitsMeasurementApi"
import { deleteUnitsMeasurementItem } from "../store/slices/unitsMeasurementSlice"
import {
	useDeletePriceMutation,
	useGetPriceMutation
} from "../store/apis/priceApi"
import {
	deleteAmountItem,
	setDateChangeUnitAmount
} from "../store/slices/amountSlice"
import {
	useDeleteAmountMutation,
	useGetAmountMutation
} from "../store/apis/amountApi"
import {
	deleteHtmlsItem,
	setAlertHtmls
} from "../store/slices/htmlsSlice"
import { useDeleteHtmlsMutation } from "../store/apis/htmlsApi"
import { useDeletePagesMutation } from "../store/apis/pagesApi"
import { deletePagesItem } from "../store/slices/pagesSlice"
import { deleteTemplateContentItem } from "../store/slices/temlateContentSlice"
import { useDeleteTemplateContentMutation } from "../store/apis/temlateContentApi"
import { useDeleteTemplateMutation } from '../store/apis/templateApi'
import { deleteTemplateItem } from '../store/slices/templateSlice'
import {
	useDeleteUnitGroupsMutation,
	useGetUnitGroupsMutation
} from "../store/apis/unitGroupsApi";
import { deleteUnitGroupsItem } from "../store/slices/unitGroupsSlice";
import {
	useDeleteCategoryMutation,
	useGetCategoryMutation
} from "../store/apis/categoryApi";
import { deleteCategoryItem } from "../store/slices/categorySlice";
import {
	useDeleteSubcategoryMutation,
	useGetSubcategoryMutation
} from "../store/apis/subcategoryApi";
import { deleteSubcategoryItem } from "../store/slices/subcategorySlice";
import { deleteMarkItem } from "../store/slices/markSlice";
import { useDeleteMarkMutation } from "../store/apis/markApi";
import { useDeleteModelMutation } from "../store/apis/modelApi";
import { deleteModelItem } from "../store/slices/modelSlice";
import { useDeleteTransportCategoryMutation } from "../store/apis/transportCategoryApi";
import { deleteTransportCategoryItem } from "../store/slices/transportCategorySlice";
import { useDeleteBodyTypeMutation } from "../store/apis/bodyTypeApi";
import { deleteBodyTypeItem } from "../store/slices/bodyTypeSlice";
import {
	deleteSubscriptionItem,
	deleteSubscriptionPackageItem
} from "../store/slices/subscriptionSlice";
import {
	useDeleteSubscriptionMutation,
	useDeleteSubscriptionPackageMutation
} from "../store/apis/subscriptionApi";
import { useDeleteAdvertisingMutation } from '../store/apis/advertisingApi'
import { deleteAdvertisingItem } from '../store/slices/advertisingSlice'
import { useDeleteEmailNotificationSenderMutation, useDeleteNotificationImageMutation, useDeleteNotificationMutation } from '../store/apis/notificationApi'
import { deleteNotificationItem } from '../store/slices/notificationSlice'
import {
	useDeleteGroupMutation,
	useDeleteGroupUsersMutation,
	useGetGroupsMutation
} from '../store/apis/addressBookApi'
import { deleteAddressBook } from '../store/slices/addressBookSlice'
import { ARE_COMMON } from "../../utils/textsContent";
import { useDeleteAdminMutation, useDeleteUsersRoleMutation } from '../store/apis/rbacApi'
import { useDeleteLanguageMutation } from '../store/apis/langApi'
import { useDeleteDeliveryMutation } from '../store/apis/deliveryApi'
import { useDeleteDealsButtonMutation, useDeleteDealsButtonTemplateMutation } from '../store/apis/dealsApi'
import { useTranslation } from 'react-i18next'

const DeleteModal = ( {
	show,
	onHide,
	value,
	fromPage,
	returnErrorForDel,
	setShowSearchBeforeDel
} ) => {
	const [ deleteTerms, { isLoading: isDeleteTermsLoader } ] = useDeleteTermsMutation()
	const [ deleteUnitsMeasurement, { isLoading: isDeleteUnitsMeasurementLoader } ] = useDeleteUnitsMeasurementMutation()
	const [ deletePrice, { isLoading: isDeletePriceLoader } ] = useDeletePriceMutation()
	const [ deleteAmount, { isLoading: isDeleteAmountLoader } ] = useDeleteAmountMutation()
	const [ deleteHtmls, { isLoading: isDeleteHtmlsLoader } ] = useDeleteHtmlsMutation()
	const [ deletePages, { isLoading: isDeletePagesLoader } ] = useDeletePagesMutation()
	const [ deleteTemplateContent, { isLoading: isDeleteTemplateContentLoader } ] = useDeleteTemplateContentMutation()
	const [ deleteTemplate, { isLoading: isDeleteTemplateLoader } ] = useDeleteTemplateMutation()
	const [ deleteUnitGroups, { isLoading: isDeleteUnitGroupsLoader } ] = useDeleteUnitGroupsMutation()
	const [ deleteCategory, { isLoading: isDeleteCategoryLoader } ] = useDeleteCategoryMutation()
	const [ deleteSubcategory, { isLoading: isDeleteSubcategoryLoader } ] = useDeleteSubcategoryMutation()
	const [ deleteMark, { isLoading: isDeleteMarkLoader } ] = useDeleteMarkMutation()
	const [ deleteModel, { isLoading: isDeleteModelLoader } ] = useDeleteModelMutation()
	const [ deleteTransportCategory, { isLoading: isDeleteTransportCategoryLoader } ] = useDeleteTransportCategoryMutation()
	const [ deleteBodyType, { isLoading: isDeleteBodyTypeLoader } ] = useDeleteBodyTypeMutation()
	const [ deleteSubscription, { isLoading: isDeleteSubscriptionLoader } ] = useDeleteSubscriptionMutation()
	const [ deleteSubscriptionPackage, { isLoading: isDeleteSubscriptionPackageLoader } ] = useDeleteSubscriptionPackageMutation()
	const [ deleteAdvertising, { isLoading: isDeleteAdvertisingLoader } ] = useDeleteAdvertisingMutation()
	const [ deleteNotification, { isLoading: isDeleteNotificationLoader } ] = useDeleteNotificationMutation()
	const [ deleteGroup,{ isLoading: isDeleteGroupLoader } ] = useDeleteGroupMutation()

	const [ getAmount, { isLoading: isGetAmountLoading } ] = useGetAmountMutation()
	const [ getUnitsMeasurement, { isLoading: isUnitsMeasurementLoading } ] = useGetUnitsMeasurementMutation()
	const [ getUnitGroups, { isLoading: isUnitGroupsLoading } ] = useGetUnitGroupsMutation()
	const [ getPrice, { isLoading: isGetPriceLoading } ] = useGetPriceMutation()
	const [ getCategory, { isLoading: isGetCategoryLoading } ] = useGetCategoryMutation()
	const [ getSubcategory, { isLoading: isGetSubcategoryLoading } ] = useGetSubcategoryMutation()
	const [ getGroups, { isLoading: isGetGroupsLoading } ] = useGetGroupsMutation()
	const [ removeUsers, { isLoading: isRemoveUsersLoader } ] = useDeleteGroupUsersMutation()
	const [ deleteSender, {isLoading: isLoadingDelete} ] = useDeleteEmailNotificationSenderMutation()
	const [deleteImage, {isLoading: isDeleteImagesLoading}] = useDeleteNotificationImageMutation()
	const [deleteAdmin, {isLoading: isDeleteAdminLoading}] = useDeleteAdminMutation()
	const [deleteRole, {isLoading: isDeleteRoleLoading}] = useDeleteUsersRoleMutation()
	const [deleteLanguage, {isLoading: isDeleteLanguageLoading}] = useDeleteLanguageMutation()
	const [deleteDelivery, {isLoading: isDeleteDeliveryLoading}] = useDeleteDeliveryMutation()
	const [deleteDealsButton, {isLoading: isDeleteDealsButtonLoading}] = useDeleteDealsButtonMutation()
	const [deleteDealsButtonTemplate, {isLoading: isDeleteDealsButtonTemplateLoading}] = useDeleteDealsButtonTemplateMutation()

	const dispatch = useDispatch()
	const {t} = useTranslation()
	const isLoading =
		isDeleteTermsLoader ||
		isDeleteUnitsMeasurementLoader ||
		isDeletePriceLoader ||
		isDeleteAmountLoader ||
		isDeleteHtmlsLoader ||
		isDeletePagesLoader ||
		isDeleteTemplateContentLoader ||
		isDeleteTemplateLoader ||
		isDeleteUnitGroupsLoader ||
		isDeleteCategoryLoader ||
		isDeleteSubcategoryLoader ||
		isDeleteMarkLoader ||
		isDeleteModelLoader ||
		isDeleteTransportCategoryLoader ||
		isDeleteBodyTypeLoader ||
		isDeleteSubscriptionLoader ||
		isDeleteSubscriptionPackageLoader ||
		isDeleteAdvertisingLoader ||
		isDeleteNotificationLoader ||
		isDeleteGroupLoader ||
		isLoadingDelete ||
		isGetAmountLoading ||
		isUnitsMeasurementLoading ||
		isUnitGroupsLoading ||
		isGetPriceLoading ||
		isGetCategoryLoading ||
		isGetSubcategoryLoading ||
		isGetGroupsLoading ||
		isRemoveUsersLoader ||
		isDeleteImagesLoading||
		isDeleteAdminLoading ||
		isDeleteRoleLoading ||
		isDeleteLanguageLoading ||
		isDeleteDeliveryLoading ||
		isDeleteDealsButtonLoading ||
		isDeleteDealsButtonTemplateLoading

	const delAddressBook = async () => {
		const { error } = await deleteGroup( { id: value?.id } )
		!error && dispatch( deleteAddressBook( value?.id ) )
		!error && await getGroups( {} )
		returnErrorForDel( error?.data?.message )
		onHide()
	}

	const delNotificationItem = async () => {
		const { error } = await deleteNotification( { id: value?.id } )
		!error && dispatch( deleteNotificationItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		onHide()
	}

	const delAdvertising = async () => {
		const { error } = await deleteAdvertising( { id: value?.id } )
		!error && dispatch( deleteAdvertisingItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		onHide()
	}
	const delSubscription = async () => {
		const { error } = await deleteSubscription( { id: value?.id } )
		!error && dispatch( deleteSubscriptionItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		onHide()
	}
	const delSubscriptionPackage = async () => {
		const { error } = await deleteSubscriptionPackage( { id: value?.id } )
		!error && dispatch( deleteSubscriptionPackageItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		onHide()
	}
	const delBodyType = async () => {
		const { error } = await deleteBodyType( { bodyTypeId: value?.id } )
		!error && dispatch( deleteBodyTypeItem( value?.id ) )
		returnErrorForDel( error?.data?.mes )
		setShowSearchBeforeDel( true )
		onHide()
	}
	const delTransportCategory = async () => {
		const { error } = await deleteTransportCategory( { transportCategoryId: value?.id } )
		!error && dispatch( deleteTransportCategoryItem( value?.id ) )
		returnErrorForDel( error?.data?.mes )
		setShowSearchBeforeDel( true )
		onHide()
	}
	const delMark = async () => {
		const { error } = await deleteMark( { id: value?.id } )
		!error && dispatch( deleteMarkItem( value?.id ) )
		returnErrorForDel( error?.data?.mes )
		setShowSearchBeforeDel( true )
		onHide()
	}
	const delModel = async () => {
		const { error } = await deleteModel( { id: value?.id } )
		!error && dispatch( deleteModelItem( value?.id ) )
		returnErrorForDel( error?.data?.mes )
		setShowSearchBeforeDel( true )
		onHide()
	}
	const delUnitGroups = async () => {
		const { error } = await deleteUnitGroups( { id: value?.id } )
		!error && await getUnitGroups()
		!error && dispatch( deleteUnitGroupsItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		setShowSearchBeforeDel( true )
		onHide()
	}
	const delCategory = async () => {
		const { error } = await deleteCategory( { id: value?.id } )
		!error && await getCategory( `&list=null&orderBy=asc&sortingBy=position&isAll=true` )
		!error && dispatch( deleteCategoryItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		setShowSearchBeforeDel( true )
		onHide()
	}
	const delSubcategory = async () => {
		const { error } = await deleteSubcategory( { id: value?.id } )
		!error && await getSubcategory( {sortingBy: '&sortingBy=natural'} )
		!error && dispatch( deleteSubcategoryItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		setShowSearchBeforeDel( true )
		onHide()
	}
	const delTerms = async () => {
		const { error } = await deleteTerms( { id: value?.id } )
		!error && dispatch( deleteTermItem( value?.id ) )
		dispatch( setAlert( error?.data ) )
		setShowSearchBeforeDel( true )
		returnErrorForDel( error?.data?.message )
		onHide()
	}
	const delUnit = async () => {
		const { error } = await deleteUnitsMeasurement( { id: value?.id } )
		!error && await getUnitsMeasurement()
		!error && dispatch( deleteUnitsMeasurementItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		setShowSearchBeforeDel( true )
		onHide()
	}

	const delPrice = async () => {
		const { error } = await deletePrice( { id: value?.id } )
		!error && await getPrice()
		returnErrorForDel( error?.data?.message )
		setShowSearchBeforeDel( true )
		onHide()
	}

	const delAmount = async () => {
		const { error } = await deleteAmount( { id: value?.id } )
		!error && await getAmount()
		!error && dispatch( deleteAmountItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		setShowSearchBeforeDel( true )
		onHide()

		dispatch( setDateChangeUnitAmount( { id: '' } ) )
		sessionStorage.clear()
	}

	const delHTMLS = async () => {
		const { error } = await deleteHtmls( { id: value?.id } )
		!error && dispatch( deleteHtmlsItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		dispatch( setAlertHtmls( error?.data ) )
		setShowSearchBeforeDel( true )
		onHide()
	}

	const delPages = async () => {
		const { error } = await deletePages( { id: value?.id } )
		!error && dispatch( deletePagesItem( value?.id ) )
		returnErrorForDel( error?.data?.message )
		setShowSearchBeforeDel( true )
		onHide()
	}

	const delTemplateContent = async () => {
		const { error } = await deleteTemplateContent( { id: value?.id } )
		returnErrorForDel( error?.data?.message )
		console.log("error", error);
		!error && dispatch( deleteTemplateContentItem( value?.id ) )
		// setShowSearchBeforeDel( true )
		onHide()
	}

	const delTemplate = async () => {
		const { error } = await deleteTemplate( { id: value?.id } )
		returnErrorForDel( error?.data?.message )
		!error && dispatch( deleteTemplateItem( value?.id ) )
		setShowSearchBeforeDel( true )
		onHide()
	}

	const deleteUsersFromAddressBook = async () => {
		const body = {id: value.group_id, users: [], contacts: []}
		value.users.forEach(user => {
			if(user.id) {
				body.users.push(parseInt(user.id))
			} 
			else if(user){
				if(user.email) body.contacts.push({email: user.email})
				else body.contacts.push({email: user.getAttribute('email')})
			}
		})
		
		const result = await removeUsers(body);
		if(result.data?.status === 200) {
			await setShowSearchBeforeDel()
		}
	}

	const deleteEmailSender = async () => {
		await deleteSender({id: value.id})
		onHide()
	}

	const deleteMedia = async () => {
		await deleteImage({id: value})
		onHide()
	}

	const delAdmin = async () => {
		await deleteAdmin({id: value})
		onHide()
	}

	const delRole = async () => {
		await deleteRole({id: value})
		onHide()
	}

	const delLanguage = async () => {
		await deleteLanguage({id: value})
		onHide()
	}

	const delDelivery = async () => {
		await deleteDelivery({id: value})
		onHide()
	}

	const delButton = async () => {
		await deleteDealsButton({id: value})
		onHide()
	}

	const delDealsButtonsTemplate = async () => {
		await deleteDealsButtonTemplate({id: value})
		onHide()
	}

	const deleteHandler = async () => {
		try {
			switch ( fromPage ) {
				case 'term':
					await delTerms()
					break
				case 'unit':
					await delUnit()
					break

				case 'price':
					await delPrice()
					break

				case 'amount':
					await delAmount()
					break

				case 'htmls':
					await delHTMLS()
					break

				case 'pages':
					await delPages()
					break
				case 'templateContent':
					await delTemplateContent()
					break

				case 'template':
					await delTemplate()
					break
				case 'unitgroups':
					await delUnitGroups()
					break

				case 'category':
					await delCategory()
					break

				case 'subcategory':
					await delSubcategory()
					break

				case 'mark':
					await delMark()
					break

				case 'model':
					await delModel()
					break

				case 'transportCategory':
					await delTransportCategory()
					break

				case 'bodyType':
					await delBodyType()
					break

				case 'subscription':
					await delSubscription()
					break

				case 'package':
					await delSubscriptionPackage()
					break

				case 'advertising':
					await delAdvertising()
					break

				case 'notificationItem':
					await delNotificationItem()
					break
				case 'addressBook':
					await delAddressBook()
					break
				case 'aboutAddressBook':
					await deleteUsersFromAddressBook()
					break
				case 'notificationSender':
				await deleteEmailSender()
					break
				case 'notificationMedia':
				await deleteMedia()
					break
				case 'rbacUsers':
				await delAdmin()
					break
				case 'rbacRoles':
				await delRole()
					break
				case 'languages':
				await delLanguage()
					break
				case 'delivery':
				await delDelivery()
					break
				case 'dealsbuttons':
				await delButton()
					break
				case 'dealsbuttonsTemplate':
				await delDealsButtonsTemplate()
					break
				default:
					console.log( 'default' )
					break
			}
		} catch ( e ) {
			console.log( e )
		}
	}

	return (
		<Modal
			show={ show }
			onHide={ onHide }
			aria-labelledby='contained-modal-title-vcenter'
			centered
			backdrop='static'
		>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					{t('deletion')}
				</Modal.Title >
			</Modal.Header >
			<Modal.Body >
				{
					(value?.termInfo?.title?.ua ) ? <p >
					{t('deletion_confirm')} <i className='text-danger'>
					{ value?.termInfo?.title?.ua }
				</i > ?
				</p >
						:
						<p >
					{t('deletion_confirm')} <i className='text-danger'>
					{ 
					  value?.title?.ua ||
					  value?.term?.ua ||
					  value?.name?.ua ||
					  value?.name?.id ||
					  value?.body?.ua ||
					  value?.tag?.ua ||
					  value?.url ||
					  value?.name
					}
				</i > ?
				</p >
				}
			</Modal.Body >
			<Modal.Footer >
				<Button
					type='button'
					variant='outline-secondary'
					onClick={ onHide }
				>
					{t('cancel')}
				</Button >
				<Button
					type='button'
					variant='danger'
					className='d-inline-flex'
					style={ {
						maxHeight: '33px',
						display: 'flex',
						alignItems: 'center'
					} }
					onClick={ deleteHandler }
				>
					{ isLoading ? <Loader /> : t('delete') }
				</Button >
			</Modal.Footer >
		</Modal >
	)
}

export default DeleteModal
