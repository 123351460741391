import {createSlice} from "@reduxjs/toolkit"
import {htmlsApi} from "../apis/htmlsApi"

const initialState = {
	htmls: [],
	alert: null,
	showModalChangeHTMLS: false,
	dateModalChangeHTMLS: ''
}

const htmlsSlice = createSlice({
	name: "htmls",
	initialState,
	reducers: {
		createNewHtmlsItem: (state, action) => {
			state.htmls = [action.payload, ...state.htmls]
		},
		editHtmlsItem: (state, action) => {
			const index = state.htmls.findIndex(
				(htmls) => {
					return htmls.id === action.payload.id
				}
			)
			state.htmls[index] = action.payload
		},
		deleteHtmlsItem: (state, action) => {
			const htmlsId = action.payload
			const htmlsID = state.htmls.filter(htmls => htmls.id !== htmlsId)
			state.htmls = htmlsID
		},
		setAlertHtmls: (state, action) => {
			state.alert = action.payload
		},
		showChangeHTMLSModal: (state, action) => {
			state.showModalChangeHTMLS = action.payload
		},
		setDateChangeHTMLS: (state, action) => {
			state.dateModalChangeHTMLS = action.payload
		},
		resetDateChangeHTMLS: (state, action) => {
			state.dateModalChangeHTMLS = ''
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(htmlsApi.endpoints.getHtmls.matchFulfilled, (
					state,
					action
				) => {
					state.htmls = action.payload.data
				}
			)
	}
})

const {
	actions,
	reducer
} = htmlsSlice
export const {
	createNewHtmlsItem,
	deleteHtmlsItem,
	editHtmlsItem,
	setAlertHtmls,

	showChangeHTMLSModal,
	setDateChangeHTMLS,
	resetDateChangeHTMLS
} = actions
export default reducer
