export function configureDashboardDateAllTime(data, dataRange, setDataRange){
    
    const newData = {...data}
    //  average_publication_users
   
    if(Object.keys(data)?.includes('average_publication_users')){
        const length = data.average_publication_users.length
        const uniqueUsers = new Set();
        const users = [];
        for (const obj of data.average_publication_users) {
            if (!uniqueUsers.has(obj.creatorId)) {
                uniqueUsers.add(obj.creatorId);
                users.push(obj); 
            }
        }
        
        newData.average_publication_users = (length / users.length).toFixed(1)
    }

    //  average_deals_users

    if(Object.keys(data).includes('average_deals_users')){
        const length = (data.average_deals_users.length / 2)
        const uniqueUsers = new Set();
        const users = [];
        for (const obj of data.average_deals_users) {
            if (!uniqueUsers.has(obj.user)) {
                uniqueUsers.add(obj.user);
                users.push(obj); 
            }
        }
        newData.average_deals_users = (length / users.length).toFixed(1)
    }

    return newData

}
