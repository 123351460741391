import React, {
	useEffect,
	useState
} from 'react'
import { Form } from 'react-bootstrap'
import { Formik } from 'formik'
import AlertModal from '../../../components/AlertModal'
import DatePicker from 'react-datepicker'
import CloseIcon from '../../../../utils/icons/close-classik-icon-violet.svg'
import EditorTextarea from '../NotificationComponents/EditorTextarea'
import TextInput from '../NotificationComponents/TextInput'
import SelectForm from '../NotificationComponents/SelectForm'
import { useCreateHtmlsMutation } from '../../../store/apis/htmlsApi'
import { useCreateTermsMutation } from '../../../store/apis/termsApi'
import { createNewTerm } from '../../../store/slices/termsSlice'
import { useDispatch } from 'react-redux'
import { useGetGroupMutation } from '../../../store/apis/addressBookApi'
import { useCreateNotificationMutation, useGetEmailNotificationSenderQuery, useGetEmailTemplateQuery } from '../../../store/apis/notificationApi'
import {
	ARE_COMMON,
	PAGES_NAME
} from "../../../../utils/textsContent";
import { createNewNotification } from "../../../store/slices/notificationSlice";
import { useRef } from 'react'
import Loader from '../../../components/Loader'
import { PreviewEmail } from '../../../components/address-book/previewEmail/previewEmail'
import { MediaModal } from '../../../components/mediaModal/mediaModal'
import { useNavigate } from 'react-router-dom'
import { useGetLangsQuery } from '../../../store/apis/langApi'
import { useTranslation } from 'react-i18next'


const NotificationEmailPage = ({toggleAddEditEmailListPage, groupsList, dataNotification, isDoubleNotification, setIsDoubleNotification, setIsLoading}) => {

	const initialState = {
		type: 0,
		title: null,
		body: null,
		audienceCustom: dataNotification?.audienceCustom || [],
		audienceSystem: dataNotification?.audienceSystem || [],
		emails: [],
		repeat: dataNotification?.startRepeat? dataNotification.startRepeat : null,
		start: '',
		typeNotification: dataNotification?.typeNotification? dataNotification.typeNotification : 0,
		repeatCode: dataNotification?.repeatCode? dataNotification.repeatCode : 0,
		mark: [],
		sender_email: dataNotification
	}
	const default_titles = {}
	const {t} = useTranslation()

	if(dataNotification){
		Object.entries(dataNotification?.title[0]?.title).forEach((el, index) => {
			initialState[`themeTitle${el[0]}`] = el[1]
			default_titles[`themeTitle${el[0]}`] = el[1]
		})
	
		Object.entries(dataNotification?.body[0]?.body).forEach((el, index) => {
			initialState[`messageText${el[0]}`] = el[1]
		})
	}

	const [form, setForm] = useState(initialState)
	const [errorMessage, setErrorMessage] = useState('')
	const [showDatePicker, setShowDatePicker] = useState(dataNotification? true : false)
	const [checkDeliveryTime, setCheckDeliveryTime] = useState(dataNotification? true : false)
	const [date, setDate] = useState(dataNotification? new Date(dataNotification.firstSend): null)
	const [currentNames, setCurrentNames] = useState([])
	const [currentName, setCurrentName] = useState()
	const [currentEmail, setCurrentEmail] = useState()
	const [timeLifeMessage, setTimeLifeMessage] = useState(dataNotification?.startRepeat? dataNotification.startRepeat : 0)
	const [preview, setPreview] = useState(false)
	const [mediaModalState, setMediaModalState] = useState(false)
	const [validationGroup, setValidationGroup] = useState(true)
	const [defaultLang, setdefaultLang] = useState();
	
	const selectNameSender = useRef()
	const selectAddressBookRef = useRef()
	const selectSenderEmail = useRef()
	const typeNotification = useRef();
	const repeatCodeRef = useRef();
	const area_wrapper = useRef()
	const navigate = useNavigate()

	const [createHtmls, {isLoading: isCreateHtmls}] = useCreateHtmlsMutation()
	const [createTerms, {isLoading: isCreateTerms}] = useCreateTermsMutation()
	const [getGroup, {isLoading: isGetGroupLoading}] = useGetGroupMutation()
	const [createNotification, {isLoading: isCreateNotificationLoading}] = useCreateNotificationMutation()
	const {data: sender_list} = useGetEmailNotificationSenderQuery();
	const {data: emailTemplate} = useGetEmailTemplateQuery()
	const {data: langs} = useGetLangsQuery();

	const dispatch = useDispatch()

	const isLoading = isCreateHtmls || isCreateTerms || isCreateNotificationLoading || isGetGroupLoading
	const formDateUpdateHandler = (opt) => setForm({...form, ...opt})
	const showDatePickerHandler = (value) => setShowDatePicker(value)

	const plusTimeLifeMessage = () => setTimeLifeMessage(timeLifeMessage + 1)
	const minusTimeLifeMessage = () => setTimeLifeMessage(timeLifeMessage - 1)

	useEffect(()=>{
		console.log("defaultLang", defaultLang);
	},[defaultLang]);
	
	useEffect(()=>{
		if(langs){
			if(dataNotification) setdefaultLang(dataNotification.defaultLang)
			else setdefaultLang(langs[0].langIndex)
		}
		if(dataNotification){
			for(let value of selectAddressBookRef.current.options){
				if(parseInt(value.value) === dataNotification.audienceCustom[0]) value.selected = true
			}
			for(let value of selectSenderEmail.current.options){
				if(parseInt(value.value) === dataNotification.sender?.sender){
					value.selected = true
				}
			}

			for(let el of typeNotification.current){
				if(parseInt(el.value) === dataNotification.typeNotification) el.selected = true
			}
			if(repeatCodeRef.current){
				for(let el of repeatCodeRef.current){
					if(parseInt(el.value) === dataNotification.repeatCode) el.selected = true
				}
			}
		}
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[sender_list, langs, dataNotification]);

	useEffect(()=>{
		for(let value of selectNameSender.current.options){
			if(value.innerHTML === dataNotification?.sender?.name) value.selected = true
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[currentNames]);

	useEffect(()=>{
		if(sender_list){
			if(dataNotification){
				sender_list.forEach(sender => {
					if(sender.id === dataNotification.sender.sender){
						setCurrentNames(sender.value.names.map((el, index) => {return {id: index, value: el, name: el}}))
						setCurrentName(dataNotification.sender.name)
					}	
				})
			} else{
				setCurrentNames(sender_list[0]?.value.names.map((el, index) => {return {id: index, value: el, name: el}}))
			}

			setCurrentName(dataNotification?.sender?.name || sender_list[0]?.value.names[0])
			setCurrentEmail(dataNotification? 
				(sender_list?.find(el => el.id === dataNotification.sender.sender))?.value.email
				: 
				sender_list[0]?.value.email)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[sender_list]);

	useEffect(() => {
		const getUsersGroup = async () => {
			const {data} = await getGroup({id: Number(form?.audienceCustom)|| groupsList?.[0]?.id, limit: 999999999})
			const tehEmails = data?.data?.map(item => {
				return {email: item?.email, name: item.pib}
			})
			setValidationGroup(data?.validation)
			formDateUpdateHandler({emails: tehEmails})
		}
		getUsersGroup()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form?.audienceCustom])

	useEffect(() => {
		!checkDeliveryTime && setDate(null)
	}, [checkDeliveryTime])

	useEffect(() => {
		form?.typeNotification === 0 && formDateUpdateHandler({repeatCode: 0})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form?.typeNotification])

	function changeSenderName(value){
		const current_name = currentNames.find(el => el.id === parseInt(value.sender))
		setCurrentName(current_name.value)
	}

	function changeSender(value){
		if(sender_list){
			const current_sender = sender_list.filter(el => el.id === parseInt(value.sender))
			setCurrentNames(current_sender[0].value.names.map((el, index) => {return {id: index, value: el, name: el}}))
			setCurrentName(current_sender[0]?.value.names[0])
			setCurrentEmail(current_sender[0]?.value.email)
			selectNameSender.current.options[0].selected = true
		}
	}


	const handleSubmit = async (values, {resetForm}) => {
		let htmlID = null
		let termsID = null
		const audienceCustomGroupArr = [Number(form?.audienceCustom) || groupsList?.[0]?.id]
		
		const formDateHTMLS = {body: {}}
		langs.forEach(el => {
			formDateHTMLS.body[`${el.langIndex}`] = form[`messageText${el.langIndex}`]
		});

		const formDateTerms = {title: {}}
		langs.forEach(el => {
			formDateTerms.title[`${el.langIndex}`] = form[`themeTitle${el.langIndex}`]
		});
		
		try {
			const {data, error} = await createHtmls(formDateHTMLS)
			htmlID = data?.id
			setErrorMessage(error?.data?.message)
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}

		try {
			const {data, error} = await createTerms(formDateTerms)
			termsID = data?.id
			setErrorMessage(error?.data?.message)
			!error && dispatch(createNewTerm({
				...formDateTerms,
				id: data?.id
			}))
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}

		const formDate = {
			type: 0,
			title: termsID,
			body: htmlID,
			audienceCustom: audienceCustomGroupArr,
			audienceSystem: [],
			emails: form?.emails,
			repeat: timeLifeMessage,
			start: date !== null ? date.getTime() : '',
			typeNotification: form?.typeNotification,
			repeatCode: form?.repeatCode,
			sender_email: currentEmail,
			sender_name: currentName,
			mark: [],
			notification_id: dataNotification?.id || false,
			isDoubleNotification: isDoubleNotification || false,
			sender_type: 'email',
			defaultLang
		}
		try {
			const {data, error} = (htmlID && termsID) !== null && await createNotification(formDate)
			!error && dispatch(createNewNotification({
				...formDate,
				id: data?.id
			}))
			if(!error) navigate(`/notification?id=${data.id}`)
			else setErrorMessage(error?.data?.message)
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}
	}
	

	const clearFormHideModal = async () => {
		setIsDoubleNotification(false)
		setIsLoading(true)
		setForm({})
		toggleAddEditEmailListPage()
	}
	
	const sender_email_view = sender_list?.map(el => {return {id: el.id, name: el.value.email}})

	return (
		<div className='terms1'>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<h1 className='terms-title d-flex align-items-center justify-content-between'>
				{(dataNotification && !isDoubleNotification)? t("ns_edit_email") : null}
				{isDoubleNotification? t('ns_dublicate_email') : null}
				{(!dataNotification ?? !isDoubleNotification)? t('New email newsletter') : null}
				<button
					className='terms-wrapper_addButton rbac-wrapper_backButton mb-0'
					onClick={() => clearFormHideModal()}
				>
				<img
					src={CloseIcon}
					alt='close'
				/>
				</button >
			</h1 >
			<Formik
				validateOnChange
				initialValues={{
					type: form?.type,
					title: form?.title,
					body: form?.body,
					audienceCustom: form?.audienceCustom,
					audienceSystem: form?.audienceSystem,
					emails: form?.emails,
					repeat: form?.repeat,
					start: form?.start,
					typeNotification: form?.typeNotification,
					repeatCode: form?.repeatCode,
					mark: form?.mark,
				}}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					isValid,
					handleSubmit,
					dirty
				}) => (
					<Form
						className='add-edit-terms'
						onSubmit={handleSubmit}
					>
					<div className='terms-wrapper'>
						<div className='notification-email'>
							<div className='notification-email_content'>
								<h2 className='notification-email_content-title'>
									{t('ns_info_text')}
								</h2 >

								<SelectForm
									title={t('ns_address_book')}
									name={'audienceCustom'}
									arr={groupsList}
									formDateUpdateHandler={formDateUpdateHandler}
									refLink={selectAddressBookRef}
									validation={validationGroup}
								/>

								<div className='notification-email_content-inputs'>
										<div className='notification-email_content-inputs_block'>
											<SelectForm
												title={ t('address') }
												name={'sender'}
												arr={sender_email_view}
												formDateUpdateHandler={changeSender}
												refLink={selectSenderEmail}
											/>
										</div >
										<div className='notification-email_content-inputs_block'>
											<SelectForm
												title={ t('sender') }
												name={'sender'}
												arr={currentNames}
												refLink={selectNameSender}
												formDateUpdateHandler={changeSenderName}
											/>
										</div >

									<h2 className='notification-email_content-title mt-4'>
										{t('ns_theme')}
									</h2 >
									{
										langs?.map((el, index) => {
											return(
												<TextInput
													key={index}
													name={`themeTitle${el.langIndex}`}
													title={`${t('ns_theme')} ${el.langIndex}`}
													placeholder={t('ns_theme')}
													handleBlur={handleBlur}
													touched
													values = {default_titles}
													errors
													handleChange={handleChange}
													formDateUpdateHandler={formDateUpdateHandler}
												/>
											)
										})
									}

									<div className='notification-email_content-textarea' ref={area_wrapper}>
										<h2 className='notification-email_content-title'>
											{t('message')}
										</h2 >
										{
											langs?.map((el, index) => {
												return(
													<EditorTextarea
													 	key={index}
														title={`${t('message')} ${el.langIndex}`}
														name={el.langIndex}
														handleChange={handleChange}
														formDateUpdateHandler={formDateUpdateHandler}
														initialValue={dataNotification? dataNotification?.body[0].body[el.langIndex]:null}
														setMediaModalState={setMediaModalState}
													/>
												)
											})
										}
									</div >

									<div className='notification-email_content-deliveryTime'>
										<div style={{marginBottom: 20}}>
											<h2 className='notification-email_content-title'>
												{t('default_language')}
											</h2 >
											<select
												style={{textTransform: 'uppercase'}}
												aria-label='Default select example'
												className='template-content_typeBlock-select notification-viber_counter-wrapper_select m-0'
												name='repeatCode'
												value={defaultLang}
												ref={repeatCodeRef}
												onChange={(e) => {setdefaultLang(e.target.value)}}
											>
												{langs?.map((el, index) => (<option key={index} style={{textTransform: 'uppercase'}} value={el.langIndex}>{el.langIndex}</option >))}											
											</select >
										</div>
										<h2 className='notification-email_content-title'>
											{ t('ns_send_time')}
										</h2 >
										<Form.Group className='notification-email_content-deliveryTime_label'>
											<Form.Check
												type='radio'
												name='deliveryTime'
												id='deliveryTime'
												defaultChecked={dataNotification? false : true}
												className='notification-email_content-deliveryTime_radio'
												onKeyDown={e => {
													e.key === 'Enter' && e.preventDefault()
												}}
												onChange={() => {
													showDatePickerHandler(false)
													setCheckDeliveryTime(false)
												}}
											/>
											<label htmlFor='deliveryTime'>{t('ns_send_now')}</label >
										</Form.Group >
										<Form.Group className='notification-email_content-deliveryTime_label'>
											<Form.Check
												type='radio'
												name='deliveryTime'
												id='deliveryTime1'
												defaultChecked={dataNotification? true : false}
												className='notification-email_content-deliveryTime_radio'
												onKeyDown={e => {
													e.key === 'Enter' && e.preventDefault()
												}}
												onChange={() => {
													showDatePickerHandler(true)
													setCheckDeliveryTime(true)
												}}
											/>
											<label htmlFor='deliveryTime1'>{t('ns_send_delay')}</label >
										</Form.Group >
										{
											showDatePicker
											&&
											<div className='notification-email_content-deliveryTime_datepicekr'>
												<DatePicker
													selected={date}
													onChange={(date) => setDate(date)}
													showTimeSelect
													// showTimeSelectOnly
													timeFormat='p'
													timeIntervals='15'
													dateFormat='Pp'
													isClearable
												/>
											</div >
										}
										<div className='notification-email_content-deliveryTime mb-4'>
										<h2 className='notification-email_content-title'>
											{t('ns_send_type')}
										</h2 >
										<Form.Group className='template-content_typeBlock-label w-100 mb-4'>
											<select
												aria-label='Default select example'
												className='template-content_typeBlock-select'
												name='typeNotification'
												defaultValue={form?.typeNotification}
												ref={typeNotification}
												onChange={(e) => formDateUpdateHandler({[e.target.name]: Number(e?.target.value)})}
											>
												<option value={'0'}>{t('ns_onetime')}</option >
												<option value={'1'}>{t('ns_cyclic')}</option >
											</select >
										</Form.Group >
											<div className='notification-viber_counter-wrapper'>
												{
													form?.typeNotification === 1
													&&
													<Form.Group className='template-content_typeBlock-label w-100'>
														<p className='mt-4'>
															{t('ns_options')}
														</p >
														<div className='notification-viber_counter'>
															<div className='notification-viber_counter-wrapper'>
																<select
																	aria-label='Default select example'
																	className='template-content_typeBlock-select notification-viber_counter-wrapper_select m-0'
																	name='repeatCode'
																	defaultValue={'1'}
																	ref={repeatCodeRef}
																	onChange={(e) => formDateUpdateHandler(
																		{[e.target.name]: Number(e?.target.value)})}
																>
																		<option value={'0'}>{t('6hours')}</option >
																		<option value={'1'}>{t('12hours')}</option >
																		<option value={'2'}>{t('24hours')}</option >
																		<option value={'3'}>{t('48hours')}</option >
																		<option value={'4'}>{t('72hours')}</option >
																		<option value={'5'}>{t('5days')}</option >
																		<option value={'6'}>{t('6days')}</option >
																		<option value={'7'}>{t('7days')}</option >
																		<option value={'8'}>{t('2weeks')}</option >
																		<option value={'9'}>{t('1month')}</option >
																		<option value={'10'}>{t('3month')}</option >
																		<option value={'11'}>{t('half_year')}</option >
																		<option value={'12'}>{t('1year')}</option >
																		{/* <option value={'13'}>Хвилина</option > */}
																</select >
																<div className='notification-viber_counter-wrapper_block ms-2'>
																	<button
																		type='button'
																		className='notification-viber_counter-wrapper_minus'
																		onClick={minusTimeLifeMessage}
																	>
																		-
																	</button >
																	<Form.Control
																		type='number'
																		name='repeat '
																		onBlur={handleBlur}
																		value={timeLifeMessage}
																		onKeyDown={e => {
																			e.key === 'Enter' && e.preventDefault()
																		}}
																		className={`notification-viber_counter-wrapper_input`}
																	/>
																	<button
																		type='button'
																		className='notification-viber_counter-wrapper_plus'
																		onClick={plusTimeLifeMessage}
																	>
																		+
																	</button >
																</div >
															</div >
														</div >
													</Form.Group >
												}
											</div >
										</div >
									</div >
								</div >
							</div >

							<div className='categories-create_block-buttons'>
								<button
									type='button'
									className='template-content_buttons-close btn btn-primary me-3'
									onClick={clearFormHideModal}
								>
									{t('cancel')}
								</button >
								<button 
									style={{marginRight: 10}}
									type='button'
									className='template-content_buttons-save btn btn-primary'
									onClick={()=>setPreview(true)}
								>
									{t('preview')}
								</button >
								<button
									type='submit'
									className='template-content_buttons-save btn btn-primary'
									onClick={handleSubmit}
								>
										{isLoading? <Loader/> : t('save')}
								</button >
							</div >
						</div >
					</div >
					</Form >
				)}
			</Formik >
			{preview? <PreviewEmail hide={setPreview} data={form} template={emailTemplate} langs={langs}/> : null}
			{mediaModalState? <MediaModal close={setMediaModalState}/> : null}
		</div >
	)
}

export default NotificationEmailPage
