import React, {
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react'
import s from './notificationPage.module.scss'
import EmailIcon from "../../../utils/icons/email_icon_white.svg"
import ViberIcon from "../../../utils/icons/viber_icon.svg"
import FilterIcon from "../../../utils/icons/filter-icon.svg"
import SMSIcon from "../../../utils/icons/sms_icon.svg"
import DatePicker from "react-datepicker"
import NotificationList from "../../components/address-book/notificationList/notificationList"
import PaginationCustom from "../../components/PaginationCustom"
import NotificationEmailPage from './NotificationEmail/NotificationEmalePage'
import { useGetNotificationsMutation } from '../../store/apis/notificationApi'
import { useSelector } from 'react-redux'
import NotificationViberPage from './NotificationViber/NotificationViberPage'
import DeleteModal from '../../components/DeleteModal'
import AlertModal from '../../components/AlertModal'
import LoaderContent from '../../components/LoaderContent'
import {
	ARE_COMMON,
} from "../../../utils/textsContent"
import SelectShowRecords from "../../components/SelectShowRecords"
import { addEndTime } from '../../../utils/helpers/TransformDate'
import { useGetGroupsMutation } from '../../store/apis/addressBookApi'
import NotificationSMSPage from './NotificationSMS/NotificationSMSPage'
import { useTranslation } from 'react-i18next'

const NotificationPage = () => {
	const {notification} = useSelector(state => state.notificationStore)
	const {groups} = useSelector(state => state.addressBookStore)

	const [topMenu, setTopMenu] = useState(0)
	const [countShow, setCountShow] = useState(20)
	const [currentPage, setCurrentPage] = useState(1)
	const [sort, setSort] = useState(-1);
	const [totalCount, setTotalCount] = useState(0);
	const showPaginationBoard = true
	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()
	const [openAddEditEmailList, setOpenAddEditEmailList] = useState(false)
	const [openAddEditViberList, setOpenAddEditViberList] = useState(false)
	const [openAddEditSMSList, setOpenAddEditSMSList] = useState(false)
	const [deleteModalShow, setDeleteModalShow] = useState(false)
	const [deleteTerm, setDeleteTerm] = useState({})
	const [errorMessage, setErrorMessage] = useState('')
	const [currentNotification, setCurrentNotification] = useState()
	const [isDoubleNotification, setIsDoubleNotification] = useState(false)
	 
	const topMenuView = useRef()
	const filters_wrapper = useRef()
	const filters = useRef([])
	const email_button = useRef()
	const viber_button = useRef()
	const sms_button = useRef()

	const [getNotification] = useGetNotificationsMutation()
	const [getGroups] = useGetGroupsMutation()
	const [isLoading, setIsLoading] = useState(true)

	const {t} = useTranslation()
	
	const getNotificationList = useCallback(async () => {
		setIsLoading(true)
		await getGroups({
			limit: 100,
			page: 1,
		})
		const {data} = await getNotification({
			limit: `&limit=${countShow}`,
			page: `&page=${Number(currentPage)}` || `page=${Number(1)}`,
			title: `&title=${filters.current['title']?.value? filters.current['title'].value : ''}`,
			email: `&email=${filters.current['email']?.value? filters.current['email'].value : ''}`,
			date: `&date=${JSON.stringify({start: startDate? startDate.getTime() : undefined, end: endDate? addEndTime(endDate).getTime() : undefined})}`,
			sort: `&sort=${sort}`,
			senderType: `&senderType=${topMenu === 0? 'email' : topMenu === 1? 'viber': 'sms'}`
		})
		
		setTotalCount(data?.totalCount)
		setIsLoading(false)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openAddEditEmailList, openAddEditViberList, openAddEditSMSList, startDate, endDate, currentPage, countShow, filters.current, topMenu])

	useEffect(() => {
		getNotificationList()
	}, [openAddEditEmailList, openAddEditViberList, openAddEditSMSList, countShow, currentPage, countShow, topMenu, getNotificationList])

	const toggleAddEditEmailListPage = () => {
		setCurrentNotification()
		setOpenAddEditEmailList(!openAddEditEmailList)
	}
	const toggleAddEditViberListPage = () => {
		setCurrentNotification()
		setOpenAddEditViberList(!openAddEditViberList)
	}
	const toggleAddEditSMSListPage = () => {
		setCurrentNotification()
		setOpenAddEditSMSList(!openAddEditSMSList)
	}

	useEffect(() => {
		const items = topMenuView?.current?.children || []
		for (let el of items) {
			const value = el?.getAttribute('value')
			if (parseInt(value) === topMenu) el?.classList?.add(s.active)
			else el?.classList?.remove(s.active)
		}
		if(topMenu === 0){
			email_button.current?.classList.remove('template-content_buttons-close')
			viber_button.current?.classList.add('template-content_buttons-close')
			sms_button.current?.classList.add('template-content_buttons-close')
		}
		if(topMenu === 1){
			email_button.current?.classList.add('template-content_buttons-close')
			viber_button.current?.classList.remove('template-content_buttons-close')
			sms_button.current?.classList.add('template-content_buttons-close')
		}
		if(topMenu === 2){
			email_button.current?.classList.add('template-content_buttons-close')
			viber_button.current?.classList.add('template-content_buttons-close')
			sms_button.current?.classList.remove('template-content_buttons-close')
		}
	}, [topMenu, openAddEditEmailList, openAddEditViberList, openAddEditSMSList])


	function toggleFilters() {
		const current_height = filters_wrapper?.current?.offsetHeight;
		if (!current_height) filters_wrapper.current.style.height = `${filters_wrapper?.current?.scrollHeight}px`
		else filters_wrapper.current.style.height = '0px'
	}

	function clearFilters(){
		setStartDate()
		setEndDate()
		for(let key in filters.current){
			filters.current[key].value = ""
		}
	}

	const toggleDeleteModal = (value) => {
		setDeleteTerm(value)
		setDeleteModalShow(!deleteModalShow)
	}

	const returnErrorForDel = (mes) => {
		setErrorMessage(mes)
	}

	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<DeleteModal
				show={deleteModalShow}
				onHide={toggleDeleteModal}
				value={deleteTerm}
				fromPage={'notificationItem'}
				// setShowSearchBeforeDel={setShowSearchBeforeDel}
				returnErrorForDel={returnErrorForDel}
			/>
			
			{
				(openAddEditEmailList || openAddEditViberList || openAddEditSMSList)
					?
					(openAddEditEmailList
						&&
						<NotificationEmailPage
							toggleAddEditEmailListPage={toggleAddEditEmailListPage}
							groupsList={groups?.data}
							dataNotification={currentNotification}
							isDoubleNotification={isDoubleNotification}
							setIsDoubleNotification={setIsDoubleNotification}
							setIsLoading={setIsLoading}
						/>)
					||
					(openAddEditViberList
						&&
						<NotificationViberPage
							toggleAddEditViberListPage={toggleAddEditViberListPage}
							groupsList={groups?.data}
							dataNotification={currentNotification}
							isDoubleNotification={isDoubleNotification}
							setIsDoubleNotification={setIsDoubleNotification}
							setIsLoading={setIsLoading}
						/>)
					||
					(openAddEditSMSList
						&&
						<NotificationSMSPage
							toggleAddEditViberListPage={toggleAddEditSMSListPage}
							groupsList={groups?.data}
							dataNotification={currentNotification}
							isDoubleNotification={isDoubleNotification}
							setIsDoubleNotification={setIsDoubleNotification}
							setIsLoading={setIsLoading}
						/>)
					:
					<>
						<h1 className='terms-title'>{t('s_ml')}</h1 >
						<div className='terms-wrapper'>
							<header className={s.header}>
								<div
									className={s.top_menu}
									ref={topMenuView}
								>
									<div
										className={s.menu_item}
										value='0'
										onClick={(ev) => setTopMenu(0)}
									>
										<span >EMAIL</span >
									</div >
									{/* <div
										className={s.menu_item}
										value='1'
										onClick={(ev) => setTopMenu(1)}
									>
										<span >VIBER</span >
									</div > */}
									<div
										className={s.menu_item}
										value='2'
										onClick={(ev) => setTopMenu(2)}
									>
										<span >SMS</span >
									</div >
								</div >

								<div className={s.top_buttons}>
									<button
										ref={email_button}
										className={`terms-wrapper_addButton add_button_white w-100`}
										onClick={toggleAddEditEmailListPage}
									>
										<img
											src={EmailIcon}
											alt='AddIcon'
										/>
										{t('ns_create_email')}
									</button >
									{/* <button
										ref={viber_button}
										className={`terms-wrapper_addButton add_button_white template-content_buttons-close w-100`}
										onClick={toggleAddEditViberListPage}
									>
										<img
											src={ViberIcon}
											alt='AddIcon'
										/>
										Створити Viber розсилку
									</button > */}
									<button
										ref={sms_button}
										className={`terms-wrapper_addButton add_button_white template-content_buttons-close w-100`}
										onClick={toggleAddEditSMSListPage}
									>
										<img
											src={SMSIcon}
											alt='AddIcon'
										/>
										{t('ns_create_sms')}
									</button >
								</div >

								<div className={s.tools_wrapper}>
									<div className={s.sort_row}>
										<div className={s.left_block}>
											<div
												className={s.row}
												onClick={toggleFilters}
											>
												<span className={s.filter_text}>{t('filters')}</span >
												<img
													src={FilterIcon}
													alt='FilterIcon'
												/>
											</div >
										</div >
										<div className={s.right_block}>
											{/* <select
												name='sort'
												className={`${s.form_select} form-select`}
												onChange={(ev)=> setSort(ev.target.value)}
											>
												<option value={-1}>Дата останнього відпрапвлення (зменьшення)</option >
												<option value={1}>Дата останнього відпрапвлення (збільшення)</option >
											</select > */}
											<SelectShowRecords setCountShow={setCountShow} />
										</div >
									</div >
								</div >

								<div
									className={s.filters_wrapper}
									ref={filters_wrapper}
								>
									<div className={s.filters}>
										<div className={s.filter}>
											<span >from</span >
											<DatePicker
												selected={startDate}
												onChange={(date) => setStartDate(date)}
											/>
										</div >
										<div className={s.filter}>
											<span >to</span >
											<DatePicker
												selected={endDate}
												onChange={(date) => setEndDate(date)}
											/>
										</div >
									
										<div className={s.filter}>
											<span >search from name</span >
											<input
												type='text'
												name='title'
												ref={(el) => filters.current['title'] = el}
											/>
										</div >
										<div className={s.filter}>
											<span >search from email</span >
											<input
												type='email'
												name='id'
												ref={(el) => filters.current['email'] = el}
											/>
										</div >
										
									</div >
									<div className={s.buttons_wrapper}>
										<button
											className={`terms-wrapper_addButton ${s.add_button}`}
											onClick={getNotificationList}
										>
											{t('confirm')}
										</button >
										<button className='template-content_buttons-close btn btn-primary'
											onClick={clearFilters}
										>
											{t('reject')}
										</button >
									</div >
								</div >
							</header >
							<div className={s.content}>
								{
									isLoading?
									<LoaderContent />
									:
									notification.length?
									notification?.map((el, index) => {
										return(
											<NotificationList
											key={el?.id}
											item={el}
											toggleDeleteModal={toggleDeleteModal}
											setCurrentNotification={setCurrentNotification}
											openAddEditEmailList={setOpenAddEditEmailList}
											openAddEditSMSList={setOpenAddEditSMSList}
											openAddEditViberList={setOpenAddEditViberList}
											setIsDoubleNotification={setIsDoubleNotification}
										/>
										)
									}) : <h1 style={{ fontSize: 25, fontWeight: 400, textAlign: 'center'}}>{`Newsletter ${topMenu === 0? "Email" : topMenu === 1? "Viber" : "SMS"} not found`}</h1>
								}
							</div >
							<footer className={s.footer}>
								{
									totalCount > countShow
									&&
									<PaginationCustom
										currentPage={currentPage}
										setCurrentPage={setCurrentPage}
										itemsCount={countShow}
										counterAllItems={totalCount}
										alwaysShown={showPaginationBoard}
									/>
								}
							</footer >
						</div >
					</>
			}
		</div >
	)
}

export default NotificationPage
