export const countries = {
    en: [
        {
            id: 764,
            alpha2: "th",
            alpha3: "tha",
            name: "Thailand",
            code: "+66"
        },
        {
            id: 4,
            alpha2: "af",
            alpha3: "afg",
            name: "Afghanistan",
            code: "+93"
        },
        {
            id: 8,
            alpha2: "al",
            alpha3: "alb",
            name: "Albania",
            code: "+355"
        },
        {
            id: 12,
            alpha2: "dz",
            alpha3: "dza",
            name: "Algeria",
            code: "+213"
        },
        {
            id: 20,
            alpha2: "ad",
            alpha3: "and",
            name: "Andorra",
            code: "+376"
        },
        {
            id: 24,
            alpha2: "ao",
            alpha3: "ago",
            name: "Angola",
            code: "+244"
        },
        {
            id: 28,
            alpha2: "ag",
            alpha3: "atg",
            name: "Antigua and Barbuda",
            code: "+1268"
        },
        {
            id: 32,
            alpha2: "ar",
            alpha3: "arg",
            name: "Argentina",
            code: "+54"
        },
        {
            id: 51,
            alpha2: "am",
            alpha3: "arm",
            name: "Armenia",
            code: "+374"
        },
        {
            id: 36,
            alpha2: "au",
            alpha3: "aus",
            name: "Australia",
            code: "+61"
        },
        {
            id: 40,
            alpha2: "at",
            alpha3: "aut",
            name: "Austria",
            code: "+43"
        },
        {
            id: 31,
            alpha2: "az",
            alpha3: "aze",
            name: "Azerbaijan",
            code: "+994"
        },
        {
            id: 44,
            alpha2: "bs",
            alpha3: "bhs",
            name: "Bahamas",
            code: "+1242"
        },
        {
            id: 48,
            alpha2: "bh",
            alpha3: "bhr",
            name: "Bahrain",
            code: "+973"
        },
        {
            id: 50,
            alpha2: "bd",
            alpha3: "bgd",
            name: "Bangladesh",
            code: "+880"
        },
        {
            id: 52,
            alpha2: "bb",
            alpha3: "brb",
            name: "Barbados",
            code: "+1246"
        },
        {
            id: 112,
            alpha2: "by",
            alpha3: "blr",
            name: "Belarus",
            code: "+375"
        },
        {
            id: 56,
            alpha2: "be",
            alpha3: "bel",
            name: "Belgium",
            code: "+32"
        },
        {
            id: 84,
            alpha2: "bz",
            alpha3: "blz",
            name: "Belize",
            code: "+501"
        },
        {
            id: 204,
            alpha2: "bj",
            alpha3: "ben",
            name: "Benin",
            code: "+229"
        },
        {
            id: 64,
            alpha2: "bt",
            alpha3: "btn",
            name: "Bhutan",
            code: "+975"
        },
        {
            id: 68,
            alpha2: "bo",
            alpha3: "bol",
            name: "Bolivia (Plurinational State of)",
            code: "+591"
        },
        {
            id: 70,
            alpha2: "ba",
            alpha3: "bih",
            name: "Bosnia and Herzegovina",
            code: "+387"
        },
        {
            id: 72,
            alpha2: "bw",
            alpha3: "bwa",
            name: "Botswana",
            code: "+267"
        },
        {
            id: 76,
            alpha2: "br",
            alpha3: "bra",
            name: "Brazil",
            code: "+55"
        },
        {
            id: 96,
            alpha2: "bn",
            alpha3: "brn",
            name: "Brunei Darussalam",
            code: "+673"
        },
        {
            id: 100,
            alpha2: "bg",
            alpha3: "bgr",
            name: "Bulgaria",
            code: "+359"
        },
        {
            id: 854,
            alpha2: "bf",
            alpha3: "bfa",
            name: "Burkina Faso",
            code: "+226"
        },
        {
            id: 108,
            alpha2: "bi",
            alpha3: "bdi",
            name: "Burundi",
            code: "+257"
        },
        {
            id: 132,
            alpha2: "cv",
            alpha3: "cpv",
            name: "Cabo Verde",
            code: "+238"
        },
        {
            id: 116,
            alpha2: "kh",
            alpha3: "khm",
            name: "Cambodia",
            code: "+855"
        },
        {
            id: 120,
            alpha2: "cm",
            alpha3: "cmr",
            name: "Cameroon",
            code: "+237"
        },
        {
            id: 124,
            alpha2: "ca",
            alpha3: "can",
            name: "Canada",
            code: "+1"
        },
        {
            id: 140,
            alpha2: "cf",
            alpha3: "caf",
            name: "Central African Republic",
            code: "+236"
        },
        {
            id: 148,
            alpha2: "td",
            alpha3: "tcd",
            name: "Chad",
            code: "+235"
        },
        {
            id: 152,
            alpha2: "cl",
            alpha3: "chl",
            name: "Chile",
            code: "+56"
        },
        {
            id: 156,
            alpha2: "cn",
            alpha3: "chn",
            name: "China",
            code: "+86"
        },
        {
            id: 170,
            alpha2: "co",
            alpha3: "col",
            name: "Colombia",
            code: "+57"
        },
        {
            id: 174,
            alpha2: "km",
            alpha3: "com",
            name: "Comoros",
            code: "+269"
        },
        {
            id: 178,
            alpha2: "cg",
            alpha3: "cog",
            name: "Congo",
            code: "+242"
        },
        {
            id: 180,
            alpha2: "cd",
            alpha3: "cod",
            name: "Congo, Democratic Republic of the",
            code: "+243"
        },
        {
            id: 188,
            alpha2: "cr",
            alpha3: "cri",
            name: "Costa Rica",
            code: "+506"
        },
        {
            id: 384,
            alpha2: "ci",
            alpha3: "civ",
            name: "Côte d'Ivoire",
            code: "+225"
        },
        {
            id: 191,
            alpha2: "hr",
            alpha3: "hrv",
            name: "Croatia",
            code: "+385"
        },
        {
            id: 192,
            alpha2: "cu",
            alpha3: "cub",
            name: "Cuba",
            code: "+53"
        },
        {
            id: 196,
            alpha2: "cy",
            alpha3: "cyp",
            name: "Cyprus",
            code: "+357"
        },
        {
            id: 203,
            alpha2: "cz",
            alpha3: "cze",
            name: "Czechia",
            code: "+420"
        },
        {
            id: 208,
            alpha2: "dk",
            alpha3: "dnk",
            name: "Denmark",
            code: "+45"
        },
        {
            id: 262,
            alpha2: "dj",
            alpha3: "dji",
            name: "Djibouti",
            code: "+253"
        },
        {
            id: 212,
            alpha2: "dm",
            alpha3: "dma",
            name: "Dominica",
            code: "+1767"
        },
        {
            id: 214,
            alpha2: "do",
            alpha3: "dom",
            name: "Dominican Republic",
            code: "+1849"
        },
        {
            id: 218,
            alpha2: "ec",
            alpha3: "ecu",
            name: "Ecuador",
            code: "+593"
        },
        {
            id: 818,
            alpha2: "eg",
            alpha3: "egy",
            name: "Egypt",
            code: "+20"
        },
        {
            id: 222,
            alpha2: "sv",
            alpha3: "slv",
            name: "El Salvador",
            code: "+503"
        },
        {
            id: 226,
            alpha2: "gq",
            alpha3: "gnq",
            name: "Equatorial Guinea",
            code: "+240"
        },
        {
            id: 232,
            alpha2: "er",
            alpha3: "eri",
            name: "Eritrea",
            code: "+291"
        },
        {
            id: 233,
            alpha2: "ee",
            alpha3: "est",
            name: "Estonia",
            code: "+372"
        },
        {
            id: 748,
            alpha2: "sz",
            alpha3: "swz",
            name: "Eswatini",
            code: "+268"
        },
        {
            id: 231,
            alpha2: "et",
            alpha3: "eth",
            name: "Ethiopia",
            code: "+251"
        },
        {
            id: 242,
            alpha2: "fj",
            alpha3: "fji",
            name: "Fiji",
            code: "+679"
        },
        {
            id: 246,
            alpha2: "fi",
            alpha3: "fin",
            name: "Finland",
            code: "+358"
        },
        {
            id: 250,
            alpha2: "fr",
            alpha3: "fra",
            name: "France",
            code: "+33"
        },
        {
            id: 266,
            alpha2: "ga",
            alpha3: "gab",
            name: "Gabon",
            code: "+241"
        },
        {
            id: 270,
            alpha2: "gm",
            alpha3: "gmb",
            name: "Gambia",
            code: "+220"
        },
        {
            id: 268,
            alpha2: "ge",
            alpha3: "geo",
            name: "Georgia",
            code: "+995"
        },
        {
            id: 276,
            alpha2: "de",
            alpha3: "deu",
            name: "Germany",
            code: "+49"
        },
        {
            id: 288,
            alpha2: "gh",
            alpha3: "gha",
            name: "Ghana",
            code: "+233"
        },
        {
            id: 300,
            alpha2: "gr",
            alpha3: "grc",
            name: "Greece",
            code: "+30"
        },
        {
            id: 308,
            alpha2: "gd",
            alpha3: "grd",
            name: "Grenada",
            code: "+1473"
        },
        {
            id: 320,
            alpha2: "gt",
            alpha3: "gtm",
            name: "Guatemala",
            code: "+502"
        },
        {
            id: 324,
            alpha2: "gn",
            alpha3: "gin",
            name: "Guinea",
            code: "+224"
        },
        {
            id: 624,
            alpha2: "gw",
            alpha3: "gnb",
            name: "Guinea-Bissau",
            code: "+245"
        },
        {
            id: 328,
            alpha2: "gy",
            alpha3: "guy",
            name: "Guyana",
            code: "+595"
        },
        {
            id: 332,
            alpha2: "ht",
            alpha3: "hti",
            name: "Haiti",
            code: "+509"
        },
        {
            id: 340,
            alpha2: "hn",
            alpha3: "hnd",
            name: "Honduras",
            code: "+504"
        },
        {
            id: 348,
            alpha2: "hu",
            alpha3: "hun",
            name: "Hungary",
            code: "+36"
        },
        {
            id: 352,
            alpha2: "is",
            alpha3: "isl",
            name: "Iceland",
            code: "+354"
        },
        {
            id: 356,
            alpha2: "in",
            alpha3: "ind",
            name: "India",
            code: "+91"
        },
        {
            id: 360,
            alpha2: "id",
            alpha3: "idn",
            name: "Indonesia",
            code: "+62"
        },
        {
            id: 364,
            alpha2: "ir",
            alpha3: "irn",
            name: "Iran (Islamic Republic of)",
            code: "+98"
        },
        {
            id: 368,
            alpha2: "iq",
            alpha3: "irq",
            name: "Iraq",
            code: "+964"
        },
        {
            id: 372,
            alpha2: "ie",
            alpha3: "irl",
            name: "Ireland",
            code: "+353"
        },
        {
            id: 376,
            alpha2: "il",
            alpha3: "isr",
            name: "Israel",
            code: "+972"
        },
        {
            id: 380,
            alpha2: "it",
            alpha3: "ita",
            name: "Italy",
            code: "+39"
        },
        {
            id: 388,
            alpha2: "jm",
            alpha3: "jam",
            name: "Jamaica",
            code: "+1876"
        },
        {
            id: 392,
            alpha2: "jp",
            alpha3: "jpn",
            name: "Japan",
            code: "+81"
        },
        {
            id: 400,
            alpha2: "jo",
            alpha3: "jor",
            name: "Jordan",
            code: "+962"
        },
        {
            id: 398,
            alpha2: "kz",
            alpha3: "kaz",
            name: "Kazakhstan",
            code: "+77"
        },
        {
            id: 404,
            alpha2: "ke",
            alpha3: "ken",
            name: "Kenya",
            code: "+254"
        },
        {
            id: 296,
            alpha2: "ki",
            alpha3: "kir",
            name: "Kiribati",
            code: "+686"
        },
        {
            id: 408,
            alpha2: "kp",
            alpha3: "prk",
            name: "Korea (Democratic People's Republic of)",
            code: "+850"
        },
        {
            id: 410,
            alpha2: "kr",
            alpha3: "kor",
            name: "Korea, Republic of",
            code: "+82"
        },
        {
            id: 414,
            alpha2: "kw",
            alpha3: "kwt",
            name: "Kuwait",
            code: "+965"
        },
        {
            id: 417,
            alpha2: "kg",
            alpha3: "kgz",
            name: "Kyrgyzstan",
            code: "+996"
        },
        {
            id: 418,
            alpha2: "la",
            alpha3: "lao",
            name: "Lao People's Democratic Republic",
            code: "+856"
        },
        {
            id: 428,
            alpha2: "lv",
            alpha3: "lva",
            name: "Latvia",
            code: "+371"
        },
        {
            id: 422,
            alpha2: "lb",
            alpha3: "lbn",
            name: "Lebanon",
            code: "+961"
        },
        {
            id: 426,
            alpha2: "ls",
            alpha3: "lso",
            name: "Lesotho",
            code: "+266"
        },
        {
            id: 430,
            alpha2: "lr",
            alpha3: "lbr",
            name: "Liberia",
            code: "+231"
        },
        {
            id: 434,
            alpha2: "ly",
            alpha3: "lby",
            name: "Libya",
            code: "+218"
        },
        {
            id: 438,
            alpha2: "li",
            alpha3: "lie",
            name: "Liechtenstein",
            code: "+423"
        },
        {
            id: 440,
            alpha2: "lt",
            alpha3: "ltu",
            name: "Lithuania",
            code: "+370"
        },
        {
            id: 442,
            alpha2: "lu",
            alpha3: "lux",
            name: "Luxembourg",
            code: "+352"
        },
        {
            id: 450,
            alpha2: "mg",
            alpha3: "mdg",
            name: "Madagascar",
            code: "+261"
        },
        {
            id: 454,
            alpha2: "mw",
            alpha3: "mwi",
            name: "Malawi",
            code: "+265"
        },
        {
            id: 458,
            alpha2: "my",
            alpha3: "mys",
            name: "Malaysia",
            code: "+60"
        },
        {
            id: 462,
            alpha2: "mv",
            alpha3: "mdv",
            name: "Maldives",
            code: "+960"
        },
        {
            id: 466,
            alpha2: "ml",
            alpha3: "mli",
            name: "Mali",
            code: "+223"
        },
        {
            id: 470,
            alpha2: "mt",
            alpha3: "mlt",
            name: "Malta",
            code: "+356"
        },
        {
            id: 584,
            alpha2: "mh",
            alpha3: "mhl",
            name: "Marshall Islands",
            code: "+692"
        },
        {
            id: 478,
            alpha2: "mr",
            alpha3: "mrt",
            name: "Mauritania",
            code: "+222"
        },
        {
            id: 480,
            alpha2: "mu",
            alpha3: "mus",
            name: "Mauritius",
            code: "+230"
        },
        {
            id: 484,
            alpha2: "mx",
            alpha3: "mex",
            name: "Mexico",
            code: "+52"
        },
        {
            id: 583,
            alpha2: "fm",
            alpha3: "fsm",
            name: "Micronesia (Federated States of)",
            code: "+691"
        },
        {
            id: 498,
            alpha2: "md",
            alpha3: "mda",
            name: "Moldova, Republic of",
            code: "+373"
        },
        {
            id: 492,
            alpha2: "mc",
            alpha3: "mco",
            name: "Monaco",
            code: "+377"
        },
        {
            id: 496,
            alpha2: "mn",
            alpha3: "mng",
            name: "Mongolia",
            code: "+976"
        },
        {
            id: 499,
            alpha2: "me",
            alpha3: "mne",
            name: "Montenegro",
            code: "+382"
        },
        {
            id: 504,
            alpha2: "ma",
            alpha3: "mar",
            name: "Morocco",
            code: "+212"
        },
        {
            id: 508,
            alpha2: "mz",
            alpha3: "moz",
            name: "Mozambique",
            code: "+258"
        },
        {
            id: 104,
            alpha2: "mm",
            alpha3: "mmr",
            name: "Myanmar",
            code: "+95"
        },
        {
            id: 516,
            alpha2: "na",
            alpha3: "nam",
            name: "Namibia",
            code: "+264"
        },
        {
            id: 520,
            alpha2: "nr",
            alpha3: "nru",
            name: "Nauru",
            code: "+674"
        },
        {
            id: 524,
            alpha2: "np",
            alpha3: "npl",
            name: "Nepal",
            code: "+977"
        },
        {
            id: 528,
            alpha2: "nl",
            alpha3: "nld",
            name: "Netherlands",
            code: "+31"
        },
        {
            id: 554,
            alpha2: "nz",
            alpha3: "nzl",
            name: "New Zealand",
            code: "+64"
        },
        {
            id: 558,
            alpha2: "ni",
            alpha3: "nic",
            name: "Nicaragua",
            code: "+505"
        },
        {
            id: 562,
            alpha2: "ne",
            alpha3: "ner",
            name: "Niger",
            code: "+227"
        },
        {
            id: 566,
            alpha2: "ng",
            alpha3: "nga",
            name: "Nigeria",
            code: "+234"
        },
        {
            id: 807,
            alpha2: "mk",
            alpha3: "mkd",
            name: "North Macedonia",
            code: "+389"
        },
        {
            id: 578,
            alpha2: "no",
            alpha3: "nor",
            name: "Norway",
            code: "+47"
        },
        {
            id: 512,
            alpha2: "om",
            alpha3: "omn",
            name: "Oman",
            code: "+968"
        },
        {
            id: 586,
            alpha2: "pk",
            alpha3: "pak",
            name: "Pakistan",
            code: "+92"
        },
        {
            id: 585,
            alpha2: "pw",
            alpha3: "plw",
            name: "Palau",
            code: "+680"
        },
        {
            id: 591,
            alpha2: "pa",
            alpha3: "pan",
            name: "Panama",
            code: "+507"
        },
        {
            id: 598,
            alpha2: "pg",
            alpha3: "png",
            name: "Papua New Guinea",
            code: "+675"
        },
        {
            id: 600,
            alpha2: "py",
            alpha3: "pry",
            name: "Paraguay",
            code: "+595"
        },
        {
            id: 604,
            alpha2: "pe",
            alpha3: "per",
            name: "Peru",
            code: "+51"
        },
        {
            id: 608,
            alpha2: "ph",
            alpha3: "phl",
            name: "Philippines",
            code: "+63"
        },
        {
            id: 616,
            alpha2: "pl",
            alpha3: "pol",
            name: "Poland",
            code: "+48"
        },
        {
            id: 620,
            alpha2: "pt",
            alpha3: "prt",
            name: "Portugal",
            code: "+351"
        },
        {
            id: 634,
            alpha2: "qa",
            alpha3: "qat",
            name: "Qatar",
            code: "+974"
        },
        {
            id: 642,
            alpha2: "ro",
            alpha3: "rou",
            name: "Romania",
            code: "+40"
        },
        {
            id: 643,
            alpha2: "ru",
            alpha3: "rus",
            name: "Russian Federation",
            code: "+7"
        },
        {
            id: 646,
            alpha2: "rw",
            alpha3: "rwa",
            name: "Rwanda",
            code: "+250"
        },
        {
            id: 659,
            alpha2: "kn",
            alpha3: "kna",
            name: "Saint Kitts and Nevis",
            code: "+1869"
        },
        {
            id: 662,
            alpha2: "lc",
            alpha3: "lca",
            name: "Saint Lucia",
            code: "+1758"
        },
        {
            id: 670,
            alpha2: "vc",
            alpha3: "vct",
            name: "Saint Vincent and the Grenadines",
            code: "+1784"
        },
        {
            id: 882,
            alpha2: "ws",
            alpha3: "wsm",
            name: "Samoa",
            code: "+685"
        },
        {
            id: 674,
            alpha2: "sm",
            alpha3: "smr",
            name: "San Marino",
            code: "+378"
        },
        {
            id: 678,
            alpha2: "st",
            alpha3: "stp",
            name: "Sao Tome and Principe",
            code: "+239"
        },
        {
            id: 682,
            alpha2: "sa",
            alpha3: "sau",
            name: "Saudi Arabia",
            code: "+966"
        },
        {
            id: 686,
            alpha2: "sn",
            alpha3: "sen",
            name: "Senegal",
            code: "+221"
        },
        {
            id: 688,
            alpha2: "rs",
            alpha3: "srb",
            name: "Serbia",
            code: "+381"
        },
        {
            id: 690,
            alpha2: "sc",
            alpha3: "syc",
            name: "Seychelles",
            code: "+248"
        },
        {
            id: 694,
            alpha2: "sl",
            alpha3: "sle",
            name: "Sierra Leone",
            code: "+232"
        },
        {
            id: 702,
            alpha2: "sg",
            alpha3: "sgp",
            name: "Singapore",
            code: "+65"
        },
        {
            id: 703,
            alpha2: "sk",
            alpha3: "svk",
            name: "Slovakia",
            code: "+421"
        },
        {
            id: 705,
            alpha2: "si",
            alpha3: "svn",
            name: "Slovenia",
            code: "+386"
        },
        {
            id: 90,
            alpha2: "sb",
            alpha3: "slb",
            name: "Solomon Islands",
            code: "+677"
        },
        {
            id: 706,
            alpha2: "so",
            alpha3: "som",
            name: "Somalia",
            code: "+252"
        },
        {
            id: 710,
            alpha2: "za",
            alpha3: "zaf",
            name: "South Africa",
            code: "+27"
        },
        {
            id: 728,
            alpha2: "ss",
            alpha3: "ssd",
            name: "South Sudan",
            code: "+211"
        },
        {
            id: 724,
            alpha2: "es",
            alpha3: "esp",
            name: "Spain",
            code: "+34"
        },
        {
            id: 144,
            alpha2: "lk",
            alpha3: "lka",
            name: "Sri Lanka",
            code: "+94"
        },
        {
            id: 729,
            alpha2: "sd",
            alpha3: "sdn",
            name: "Sudan",
            code: "+249"
        },
        {
            id: 740,
            alpha2: "sr",
            alpha3: "sur",
            name: "Suriname",
            code: "+597"
        },
        {
            id: 752,
            alpha2: "se",
            alpha3: "swe",
            name: "Sweden",
            code: "+46"
        },
        {
            id: 756,
            alpha2: "ch",
            alpha3: "che",
            name: "Switzerland",
            code: "+41"
        },
        {
            id: 760,
            alpha2: "sy",
            alpha3: "syr",
            name: "Syrian Arab Republic",
            code: "+963"
        },
        {
            id: 762,
            alpha2: "tj",
            alpha3: "tjk",
            name: "Tajikistan",
            code: "+992"
        },
        {
            id: 834,
            alpha2: "tz",
            alpha3: "tza",
            name: "Tanzania, United Republic of",
            code: "+255"
        },
        {
            id: 626,
            alpha2: "tl",
            alpha3: "tls",
            name: "Timor-Leste",
            code: "+670"
        },
        {
            id: 768,
            alpha2: "tg",
            alpha3: "tgo",
            name: "Togo",
            code: "+228"
        },
        {
            id: 776,
            alpha2: "to",
            alpha3: "ton",
            name: "Tonga",
            code: "+676"
        },
        {
            id: 780,
            alpha2: "tt",
            alpha3: "tto",
            name: "Trinidad and Tobago",
            code: "+1868"
        },
        {
            id: 788,
            alpha2: "tn",
            alpha3: "tun",
            name: "Tunisia",
            code: "+216"
        },
        {
            id: 792,
            alpha2: "tr",
            alpha3: "tur",
            name: "Türkiye",
            code: "+90"
        },
        {
            id: 795,
            alpha2: "tm",
            alpha3: "tkm",
            name: "Turkmenistan",
            code: "+993"
        },
        {
            id: 798,
            alpha2: "tv",
            alpha3: "tuv",
            name: "Tuvalu",
            code: "+688"
        },
        {
            id: 800,
            alpha2: "ug",
            alpha3: "uga",
            name: "Uganda",
            code: "+256"
        },
        {
            id: 804,
            alpha2: "ua",
            alpha3: "ukr",
            name: "Ukraine",
            code: "+380"
        },
        {
            id: 784,
            alpha2: "ae",
            alpha3: "are",
            name: "United Arab Emirates",
            code: "+971"
        },
        {
            id: 826,
            alpha2: "gb",
            alpha3: "gbr",
            name: "United Kingdom of Great Britain and Northern Ireland",
            code: "+44"
        },
        {
            id: 840,
            alpha2: "us",
            alpha3: "usa",
            name: "United States of America",
            code: "+1"
        },
        {
            id: 858,
            alpha2: "uy",
            alpha3: "ury",
            name: "Uruguay",
            code: "+598"
        },
        {
            id: 860,
            alpha2: "uz",
            alpha3: "uzb",
            name: "Uzbekistan",
            code: "+998"
        },
        {
            id: 548,
            alpha2: "vu",
            alpha3: "vut",
            name: "Vanuatu",
            code: "+678"
        },
        {
            id: 862,
            alpha2: "ve",
            alpha3: "ven",
            name: "Venezuela (Bolivarian Republic of)",
            code: "+58"
        },
        {
            id: 704,
            alpha2: "vn",
            alpha3: "vnm",
            name: "Viet Nam",
            code: "+84"
        },
        {
            id: 887,
            alpha2: "ye",
            alpha3: "yem",
            name: "Yemen",
            code: "+967"
        },
        {
            id: 894,
            alpha2: "zm",
            alpha3: "zmb",
            name: "Zambia",
            code: "+260"
        },
        {
            id: 716,
            alpha2: "zw",
            alpha3: "zwe",
            name: "Zimbabwe",
            code: "+263"
        }
    ],
    ua: [
        {
            alpha2: "au",
            alpha3: "aus",
            code: "+61",
            id: 36,
            name: "Австралія"
        },
        {
            alpha2: "at",
            alpha3: "aut",
            code: "+43",
            id: 40,
            name: "Австрія"
        },
        {
            alpha2: "az",
            alpha3: "aze",
            code: "+994",
            id: 31,
            name: "Азербайджан"
        },
        {
            alpha2: "al",
            alpha3: "alb",
            code: "+355",
            id: 8,
            name: "Албанія"
        },
        {
            alpha2: "dz",
            alpha3: "dza",
            code: "+213",
            id: 12,
            name: "Алжир"
        },
        {
            alpha2: "ao",
            alpha3: "ago",
            code: "+244",
            id: 24,
            name: "Ангола"
        },
        {
            alpha2: "ad",
            alpha3: "and",
            code: "+376",
            id: 20,
            name: "Андорра"
        },
        {
            alpha2: "ag",
            alpha3: "atg",
            code: "+1268",
            id: 28,
            name: "Антигуа і Барбуда"
        },
        {
            alpha2: "ar",
            alpha3: "arg",
            code: "+54",
            id: 32,
            name: "Аргентина"
        },
        {
            alpha2: "af",
            alpha3: "afg",
            code: "+93",
            id: 4,
            name: "Афганістан"
        },
        {
            alpha2: "bs",
            alpha3: "bhs",
            code: "+1242",
            id: 44,
            name: "Багамські Острови"
        },
        {
            alpha2: "bd",
            alpha3: "bgd",
            code: "+880",
            id: 50,
            name: "Бангладеш"
        },
        {
            alpha2: "bb",
            alpha3: "brb",
            code: "+1246",
            id: 52,
            name: "Барбадос"
        },
        {
            alpha2: "bh",
            alpha3: "bhr",
            code: "+973",
            id: 48,
            name: "Бахрейн"
        },
        {
            alpha2: "bz",
            alpha3: "blz",
            code: "+501",
            id: 84,
            name: "Беліз"
        },
        {
            alpha2: "be",
            alpha3: "bel",
            code: "+32",
            id: 56,
            name: "Бельгія"
        },
        {
            alpha2: "bj",
            alpha3: "ben",
            code: "+229",
            id: 204,
            name: "Бенін"
        },
        {
            alpha2: "by",
            alpha3: "blr",
            code: "+375",
            id: 112,
            name: "Білорусь"
        },
        {
            alpha2: "bg",
            alpha3: "bgr",
            code: "+359",
            id: 100,
            name: "Болгарія"
        },
        {
            alpha2: "bo",
            alpha3: "bol",
            code: "+591",
            id: 68,
            name: "Болівія"
        },
        {
            alpha2: "ba",
            alpha3: "bih",
            code: "+387",
            id: 70,
            name: "Боснія і Герцеговина"
        },
        {
            alpha2: "bw",
            alpha3: "bwa",
            code: "+267",
            id: 72,
            name: "Ботсвана"
        },
        {
            alpha2: "br",
            alpha3: "bra",
            code: "+55",
            id: 76,
            name: "Бразилія"
        },
        {
            alpha2: "bn",
            alpha3: "brn",
            code: "+673",
            id: 96,
            name: "Бруней"
        },
        {
            alpha2: "bf",
            alpha3: "bfa",
            code: "+226",
            id: 854,
            name: "Буркіна-Фасо"
        },
        {
            alpha2: "bi",
            alpha3: "bdi",
            code: "+257",
            id: 108,
            name: "Бурунді"
        },
        {
            alpha2: "bt",
            alpha3: "btn",
            code: "+975",
            id: 64,
            name: "Бутан"
        },
        {
            alpha2: "vu",
            alpha3: "vut",
            code: "+678",
            id: 548,
            name: "Вануату"
        },
        {
            alpha2: "gb",
            alpha3: "gbr",
            code: "+44",
            id: 826,
            name: "Велика Британія"
        },
        {
            alpha2: "ve",
            alpha3: "ven",
            code: "+58",
            id: 862,
            name: "Венесуела"
        },
        {
            alpha2: "vn",
            alpha3: "vnm",
            code: "+84",
            id: 704,
            name: "В'єтнам"
        },
        {
            alpha2: "am",
            alpha3: "arm",
            code: "+374",
            id: 51,
            name: "Вірменія"
        },
        {
            alpha2: "ga",
            alpha3: "gab",
            code: "+241",
            id: 266,
            name: "Габон"
        },
        {
            alpha2: "ht",
            alpha3: "hti",
            code: "+509",
            id: 332,
            name: "Гаїті"
        },
        {
            alpha2: "gy",
            alpha3: "guy",
            code: "+595",
            id: 328,
            name: "Гаяна"
        },
        {
            alpha2: "gm",
            alpha3: "gmb",
            code: "+220",
            id: 270,
            name: "Гамбія"
        },
        {
            alpha2: "gh",
            alpha3: "gha",
            code: "+233",
            id: 288,
            name: "Гана"
        },
        {
            alpha2: "gt",
            alpha3: "gtm",
            code: "+502",
            id: 320,
            name: "Гватемала"
        },
        {
            alpha2: "gn",
            alpha3: "gin",
            code: "+224",
            id: 324,
            name: "Гвінея"
        },
        {
            alpha2: "gw",
            alpha3: "gnb",
            code: "+245",
            id: 624,
            name: "Гвінея-Бісау"
        },
        {
            alpha2: "hn",
            alpha3: "hnd",
            code: "+504",
            id: 340,
            name: "Гондурас"
        },
        {
            alpha2: "gd",
            alpha3: "grd",
            code: "+1473",
            id: 308,
            name: "Гренада"
        },
        {
            alpha2: "gr",
            alpha3: "grc",
            code: "+30",
            id: 300,
            name: "Греція"
        },
        {
            alpha2: "ge",
            alpha3: "geo",
            code: "+995",
            id: 268,
            name: "Грузія"
        },
        {
            alpha2: "dk",
            alpha3: "dnk",
            code: "+45",
            id: 208,
            name: "Данія"
        },
        {
            alpha2: "cd",
            alpha3: "cod",
            code: "+243",
            id: 180,
            name: "ДР Конго"
        },
        {
            alpha2: "dj",
            alpha3: "dji",
            code: "+253",
            id: 262,
            name: "Джибуті"
        },
        {
            alpha2: "dm",
            alpha3: "dma",
            code: "+1767",
            id: 212,
            name: "Домініка"
        },
        {
            alpha2: "do",
            alpha3: "dom",
            code: "+1849",
            id: 214,
            name: "Домініканська Республіка"
        },
        {
            alpha2: "ec",
            alpha3: "ecu",
            code: "+593",
            id: 218,
            name: "Еквадор"
        },
        {
            alpha2: "gq",
            alpha3: "gnq",
            code: "+240",
            id: 226,
            name: "Екваторіальна Гвінея"
        },
        {
            alpha2: "er",
            alpha3: "eri",
            code: "+291",
            id: 232,
            name: "Еритрея"
        },
        {
            alpha2: "ee",
            alpha3: "est",
            code: "+372",
            id: 233,
            name: "Естонія"
        },
        {
            alpha2: "et",
            alpha3: "eth",
            code: "+251",
            id: 231,
            name: "Ефіопія"
        },
        {
            alpha2: "eg",
            alpha3: "egy",
            code: "+20",
            id: 818,
            name: "Єгипет"
        },
        {
            alpha2: "ye",
            alpha3: "yem",
            code: "+967",
            id: 887,
            name: "Ємен"
        },
        {
            alpha2: "zm",
            alpha3: "zmb",
            code: "+260",
            id: 894,
            name: "Замбія"
        },
        {
            alpha2: "zw",
            alpha3: "zwe",
            code: "+263",
            id: 716,
            name: "Зімбабве"
        },
        {
            alpha2: "il",
            alpha3: "isr",
            code: "+972",
            id: 376,
            name: "Ізраїль"
        },
        {
            alpha2: "in",
            alpha3: "ind",
            code: "+91",
            id: 356,
            name: "Індія"
        },
        {
            alpha2: "id",
            alpha3: "idn",
            code: "+62",
            id: 360,
            name: "Індонезія"
        },
        {
            alpha2: "iq",
            alpha3: "irq",
            code: "+964",
            id: 368,
            name: "Ірак"
        },
        {
            alpha2: "ir",
            alpha3: "irn",
            code: "+98",
            id: 364,
            name: "Іран"
        },
        {
            alpha2: "ie",
            alpha3: "irl",
            code: "+353",
            id: 372,
            name: "Ірландія"
        },
        {
            alpha2: "is",
            alpha3: "isl",
            code: "+354",
            id: 352,
            name: "Ісландія"
        },
        {
            alpha2: "es",
            alpha3: "esp",
            code: "+34",
            id: 724,
            name: "Іспанія"
        },
        {
            alpha2: "it",
            alpha3: "ita",
            code: "+39",
            id: 380,
            name: "Італія"
        },
        {
            alpha2: "jo",
            alpha3: "jor",
            code: "+962",
            id: 400,
            name: "Йорданія"
        },
        {
            alpha2: "cv",
            alpha3: "cpv",
            code: "+238",
            id: 132,
            name: "Кабо-Верде"
        },
        {
            alpha2: "kz",
            alpha3: "kaz",
            code: "+77",
            id: 398,
            name: "Казахстан"
        },
        {
            alpha2: "kh",
            alpha3: "khm",
            code: "+855",
            id: 116,
            name: "Камбоджа"
        },
        {
            alpha2: "cm",
            alpha3: "cmr",
            code: "+237",
            id: 120,
            name: "Камерун"
        },
        {
            alpha2: "ca",
            alpha3: "can",
            code: "+1",
            id: 124,
            name: "Канада"
        },
        {
            alpha2: "qa",
            alpha3: "qat",
            code: "+974",
            id: 634,
            name: "Катар"
        },
        {
            alpha2: "ke",
            alpha3: "ken",
            code: "+254",
            id: 404,
            name: "Кенія"
        },
        {
            alpha2: "kg",
            alpha3: "kgz",
            code: "+996",
            id: 417,
            name: "Киргизстан"
        },
        {
            alpha2: "cn",
            alpha3: "chn",
            code: "+86",
            id: 156,
            name: "Китайська Народна Республіка"
        },
        {
            alpha2: "cy",
            alpha3: "cyp",
            code: "+357",
            id: 196,
            name: "Кіпр"
        },
        {
            alpha2: "ki",
            alpha3: "kir",
            code: "+686",
            id: 296,
            name: "Кірибаті"
        },
        {
            alpha2: "co",
            alpha3: "col",
            code: "+57",
            id: 170,
            name: "Колумбія"
        },
        {
            alpha2: "km",
            alpha3: "com",
            code: "+269",
            id: 174,
            name: "Коморські Острови"
        },
        {
            alpha2: "cg",
            alpha3: "cog",
            code: "+242",
            id: 178,
            name: "Республіка Конго"
        },
        {
            alpha2: "cr",
            alpha3: "cri",
            code: "+506",
            id: 188,
            name: "Коста-Рика"
        },
        {
            alpha2: "ci",
            alpha3: "civ",
            code: "+225",
            id: 384,
            name: "Кот-д'Івуар"
        },
        {
            alpha2: "cu",
            alpha3: "cub",
            code: "+53",
            id: 192,
            name: "Куба"
        },
        {
            alpha2: "kw",
            alpha3: "kwt",
            code: "+965",
            id: 414,
            name: "Кувейт"
        },
        {
            alpha2: "la",
            alpha3: "lao",
            code: "+856",
            id: 418,
            name: "Лаос"
        },
        {
            alpha2: "lv",
            alpha3: "lva",
            code: "+371",
            id: 428,
            name: "Латвія"
        },
        {
            alpha2: "ls",
            alpha3: "lso",
            code: "+266",
            id: 426,
            name: "Лесото"
        },
        {
            alpha2: "lt",
            alpha3: "ltu",
            code: "+370",
            id: 440,
            name: "Литва"
        },
        {
            alpha2: "lr",
            alpha3: "lbr",
            code: "+231",
            id: 430,
            name: "Ліберія"
        },
        {
            alpha2: "lb",
            alpha3: "lbn",
            code: "+961",
            id: 422,
            name: "Ліван"
        },
        {
            alpha2: "ly",
            alpha3: "lby",
            code: "+218",
            id: 434,
            name: "Лівія"
        },
        {
            alpha2: "li",
            alpha3: "lie",
            code: "+423",
            id: 438,
            name: "Ліхтенштейн"
        },
        {
            alpha2: "lu",
            alpha3: "lux",
            code: "+352",
            id: 442,
            name: "Люксембург"
        },
        {
            alpha2: "mu",
            alpha3: "mus",
            code: "+230",
            id: 480,
            name: "Маврикій"
        },
        {
            alpha2: "mr",
            alpha3: "mrt",
            code: "+222",
            id: 478,
            name: "Мавританія"
        },
        {
            alpha2: "mg",
            alpha3: "mdg",
            code: "+261",
            id: 450,
            name: "Мадагаскар"
        },
        {
            alpha2: "mw",
            alpha3: "mwi",
            code: "+265",
            id: 454,
            name: "Малаві"
        },
        {
            alpha2: "my",
            alpha3: "mys",
            code: "+60",
            id: 458,
            name: "Малайзія"
        },
        {
            alpha2: "ml",
            alpha3: "mli",
            code: "+223",
            id: 466,
            name: "Малі"
        },
        {
            alpha2: "mv",
            alpha3: "mdv",
            code: "+960",
            id: 462,
            name: "Мальдіви"
        },
        {
            alpha2: "mt",
            alpha3: "mlt",
            code: "+356",
            id: 470,
            name: "Мальта"
        },
        {
            alpha2: "ma",
            alpha3: "mar",
            code: "+212",
            id: 504,
            name: "Марокко"
        },
        {
            alpha2: "mh",
            alpha3: "mhl",
            code: "+692",
            id: 584,
            name: "Маршаллові Острови"
        },
        {
            alpha2: "mx",
            alpha3: "mex",
            code: "+52",
            id: 484,
            name: "Мексика"
        },
        {
            alpha2: "mz",
            alpha3: "moz",
            code: "+258",
            id: 508,
            name: "Мозамбік"
        },
        {
            alpha2: "md",
            alpha3: "mda",
            code: "+373",
            id: 498,
            name: "Молдова"
        },
        {
            alpha2: "mc",
            alpha3: "mco",
            code: "+377",
            id: 492,
            name: "Монако"
        },
        {
            alpha2: "mn",
            alpha3: "mng",
            code: "+976",
            id: 496,
            name: "Монголія"
        },
        {
            alpha2: "mm",
            alpha3: "mmr",
            code: "+95",
            id: 104,
            name: "М'янма"
        },
        {
            alpha2: "na",
            alpha3: "nam",
            code: "+264",
            id: 516,
            name: "Намібія"
        },
        {
            alpha2: "nr",
            alpha3: "nru",
            code: "+674",
            id: 520,
            name: "Науру"
        },
        {
            alpha2: "np",
            alpha3: "npl",
            code: "+977",
            id: 524,
            name: "Непал"
        },
        {
            alpha2: "ne",
            alpha3: "ner",
            code: "+227",
            id: 562,
            name: "Нігер"
        },
        {
            alpha2: "ng",
            alpha3: "nga",
            code: "+234",
            id: 566,
            name: "Нігерія"
        },
        {
            alpha2: "nl",
            alpha3: "nld",
            code: "+31",
            id: 528,
            name: "Нідерланди"
        },
        {
            alpha2: "ni",
            alpha3: "nic",
            code: "+505",
            id: 558,
            name: "Нікарагуа"
        },
        {
            alpha2: "de",
            alpha3: "deu",
            code: "+49",
            id: 276,
            name: "Німеччина"
        },
        {
            alpha2: "nz",
            alpha3: "nzl",
            code: "+64",
            id: 554,
            name: "Нова Зеландія"
        },
        {
            alpha2: "no",
            alpha3: "nor",
            code: "+47",
            id: 578,
            name: "Норвегія"
        },
        {
            alpha2: "ae",
            alpha3: "are",
            code: "+971",
            id: 784,
            name: "ОАЕ"
        },
        {
            alpha2: "om",
            alpha3: "omn",
            code: "+968",
            id: 512,
            name: "Оман"
        },
        {
            alpha2: "pk",
            alpha3: "pak",
            code: "+92",
            id: 586,
            name: "Пакистан"
        },
        {
            alpha2: "pw",
            alpha3: "plw",
            code: "+680",
            id: 585,
            name: "Палау"
        },
        {
            alpha2: "pa",
            alpha3: "pan",
            code: "+507",
            id: 591,
            name: "Панама"
        },
        {
            alpha2: "pg",
            alpha3: "png",
            code: "+675",
            id: 598,
            name: "Папуа Нова Гвінея"
        },
        {
            alpha2: "py",
            alpha3: "pry",
            code: "+595",
            id: 600,
            name: "Парагвай"
        },
        {
            alpha2: "pe",
            alpha3: "per",
            code: "+51",
            id: 604,
            name: "Перу"
        },
        { alpha2: "za", alpha3: "zaf", code: "+27", id: 710, name: "ПАР" },
        {
            alpha2: "kr",
            alpha3: "kor",
            code: "+82",
            id: 410,
            name: "Південна Корея"
        },
        {
            alpha2: "ss",
            alpha3: "ssd",
            code: "+211",
            id: 728,
            name: "Південний Судан"
        },
        {
            alpha2: "kp",
            alpha3: "prk",
            code: "+850",
            id: 408,
            name: "Північна Корея"
        },
        {
            alpha2: "mk",
            alpha3: "mkd",
            code: "+389",
            id: 807,
            name: "Північна Македонія"
        },
        {
            alpha2: "pl",
            alpha3: "pol",
            code: "+48",
            id: 616,
            name: "Польща"
        },
        {
            alpha2: "pt",
            alpha3: "prt",
            code: "+351",
            id: 620,
            name: "Португалія"
        },
        {
            alpha2: "ru",
            alpha3: "rus",
            code: "+7",
            id: 643,
            name: "Росія"
        },
        {
            alpha2: "rw",
            alpha3: "rwa",
            code: "+250",
            id: 646,
            name: "Руанда"
        },
        {
            alpha2: "ro",
            alpha3: "rou",
            code: "+40",
            id: 642,
            name: "Румунія"
        },
        {
            alpha2: "sv",
            alpha3: "slv",
            code: "+503",
            id: 222,
            name: "Сальвадор"
        },
        {
            alpha2: "ws",
            alpha3: "wsm",
            code: "+685",
            id: 882,
            name: "Самоа"
        },
        {
            alpha2: "sm",
            alpha3: "smr",
            code: "+378",
            id: 674,
            name: "Сан-Марино"
        },
        {
            alpha2: "st",
            alpha3: "stp",
            code: "+239",
            id: 678,
            name: "Сан-Томе і Принсіпі"
        },
        {
            alpha2: "sa",
            alpha3: "sau",
            code: "+966",
            id: 682,
            name: "Саудівська Аравія"
        },
        {
            alpha2: "sz",
            alpha3: "swz",
            code: "+268",
            id: 748,
            name: "Есватіні"
        },
        {
            alpha2: "sc",
            alpha3: "syc",
            code: "+248",
            id: 690,
            name: "Сейшельські Острови"
        },
        {
            alpha2: "sn",
            alpha3: "sen",
            code: "+221",
            id: 686,
            name: "Сенегал"
        },
        {
            alpha2: "vc",
            alpha3: "vct",
            code: "+1784",
            id: 670,
            name: "Сент-Вінсент і Гренадини"
        },
        {
            alpha2: "kn",
            alpha3: "kna",
            code: "+1869",
            id: 659,
            name: "Сент-Кіттс і Невіс"
        },
        {
            alpha2: "lc",
            alpha3: "lca",
            code: "+1758",
            id: 662,
            name: "Сент-Люсія"
        },
        {
            alpha2: "rs",
            alpha3: "srb",
            code: "+381",
            id: 688,
            name: "Сербія"
        },
        {
            alpha2: "sy",
            alpha3: "syr",
            code: "+963",
            id: 760,
            name: "Сирія"
        },
        {
            alpha2: "sg",
            alpha3: "sgp",
            code: "+65",
            id: 702,
            name: "Сінгапур"
        },
        {
            alpha2: "sk",
            alpha3: "svk",
            code: "+421",
            id: 703,
            name: "Словаччина"
        },
        {
            alpha2: "si",
            alpha3: "svn",
            code: "+386",
            id: 705,
            name: "Словенія"
        },
        {
            alpha2: "sb",
            alpha3: "slb",
            code: "+677",
            id: 90,
            name: "Соломонові Острови"
        },
        {
            alpha2: "so",
            alpha3: "som",
            code: "+252",
            id: 706,
            name: "Сомалі"
        },
        {
            alpha2: "us",
            alpha3: "usa",
            code: "+1",
            id: 840,
            name: "США"
        },
        {
            alpha2: "sd",
            alpha3: "sdn",
            code: "+249",
            id: 729,
            name: "Судан"
        },
        {
            alpha2: "sr",
            alpha3: "sur",
            code: "+597",
            id: 740,
            name: "Суринам"
        },
        {
            alpha2: "tl",
            alpha3: "tls",
            code: "+670",
            id: 626,
            name: "Східний Тимор"
        },
        {
            alpha2: "sl",
            alpha3: "sle",
            code: "+232",
            id: 694,
            name: "Сьєрра-Леоне"
        },
        {
            alpha2: "tj",
            alpha3: "tjk",
            code: "+992",
            id: 762,
            name: "Таджикистан"
        },
        {
            alpha2: "th",
            alpha3: "tha",
            code: "+66",
            id: 764,
            name: "Таїланд"
        },
        {
            alpha2: "tz",
            alpha3: "tza",
            code: "+255",
            id: 834,
            name: "Танзанія"
        },
        {
            alpha2: "tg",
            alpha3: "tgo",
            code: "+228",
            id: 768,
            name: "Того"
        },
        {
            alpha2: "to",
            alpha3: "ton",
            code: "+676",
            id: 776,
            name: "Тонга"
        },
        {
            alpha2: "tt",
            alpha3: "tto",
            code: "+1868",
            id: 780,
            name: "Тринідад і Тобаго"
        },
        {
            alpha2: "tv",
            alpha3: "tuv",
            code: "+688",
            id: 798,
            name: "Тувалу"
        },
        {
            alpha2: "tn",
            alpha3: "tun",
            code: "+216",
            id: 788,
            name: "Туніс"
        },
        {
            alpha2: "tr",
            alpha3: "tur",
            code: "+90",
            id: 792,
            name: "Туреччина"
        },
        {
            alpha2: "tm",
            alpha3: "tkm",
            code: "+993",
            id: 795,
            name: "Туркменістан"
        },
        {
            alpha2: "ug",
            alpha3: "uga",
            code: "+256",
            id: 800,
            name: "Уганда"
        },
        {
            alpha2: "hu",
            alpha3: "hun",
            code: "+36",
            id: 348,
            name: "Угорщина"
        },
        {
            alpha2: "uz",
            alpha3: "uzb",
            code: "+998",
            id: 860,
            name: "Узбекистан"
        },
        {
            alpha2: "ua",
            alpha3: "ukr",
            code: "+380",
            id: 804,
            name: "Україна"
        },
        {
            alpha2: "uy",
            alpha3: "ury",
            code: "+598",
            id: 858,
            name: "Уругвай"
        },
        {
            alpha2: "fm",
            alpha3: "fsm",
            code: "+691",
            id: 583,
            name: "Федеративні Штати Мікронезії"
        },
        {
            alpha2: "fj",
            alpha3: "fji",
            code: "+679",
            id: 242,
            name: "Фіджі"
        },
        {
            alpha2: "ph",
            alpha3: "phl",
            code: "+63",
            id: 608,
            name: "Філіппіни"
        },
        {
            alpha2: "fi",
            alpha3: "fin",
            code: "+358",
            id: 246,
            name: "Фінляндія"
        },
        {
            alpha2: "fr",
            alpha3: "fra",
            code: "+33",
            id: 250,
            name: "Франція"
        },
        {
            alpha2: "hr",
            alpha3: "hrv",
            code: "+385",
            id: 191,
            name: "Хорватія"
        },
        {
            alpha2: "cf",
            alpha3: "caf",
            code: "+236",
            id: 140,
            name: "Центральноафриканська Республіка"
        },
        {
            alpha2: "td",
            alpha3: "tcd",
            code: "+235",
            id: 148,
            name: "Чад"
        },
        {
            alpha2: "cz",
            alpha3: "cze",
            code: "+420",
            id: 203,
            name: "Чехія"
        },
        {
            alpha2: "cl",
            alpha3: "chl",
            code: "+56",
            id: 152,
            name: "Чилі"
        },
        {
            alpha2: "me",
            alpha3: "mne",
            code: "+382",
            id: 499,
            name: "Чорногорія"
        },
        {
            alpha2: "ch",
            alpha3: "che",
            code: "+41",
            id: 756,
            name: "Швейцарія"
        },
        {
            alpha2: "se",
            alpha3: "swe",
            code: "+46",
            id: 752,
            name: "Швеція"
        },
        {
            alpha2: "lk",
            alpha3: "lka",
            code: "+94",
            id: 144,
            name: "Шрі-Ланка"
        },
        {
            alpha2: "jm",
            alpha3: "jam",
            code: "+1876",
            id: 388,
            name: "Ямайка"
        },
        { alpha2: "jp", alpha3: "jpn", code: "+81", id: 392, name: "Японія" }
    ],
    ru: [
        {
            alpha2: "au",
            alpha3: "aus",
            code: "+61",
            id: 36,
            name: "Австралия"
        },
        {
            alpha2: "at",
            alpha3: "aut",
            code: "+43",
            id: 40,
            name: "Австрия"
        },
        {
            alpha2: "az",
            alpha3: "aze",
            code: "+994",
            id: 31,
            name: "Азербайджан"
        },
        {
            alpha2: "al",
            alpha3: "alb",
            code: "+355",
            id: 8,
            name: "Албания"
        },
        {
            alpha2: "dz",
            alpha3: "dza",
            code: "+213",
            id: 12,
            name: "Алжир"
        },
        {
            alpha2: "ao",
            alpha3: "ago",
            code: "+244",
            id: 24,
            name: "Ангола"
        },
        {
            alpha2: "ad",
            alpha3: "and",
            code: "+376",
            id: 20,
            name: "Андорра"
        },
        {
            alpha2: "ag",
            alpha3: "atg",
            code: "+1268",
            id: 28,
            name: "Антигуа и Барбуда"
        },
        {
            alpha2: "ar",
            alpha3: "arg",
            code: "+54",
            id: 32,
            name: "Аргентина"
        },
        {
            alpha2: "am",
            alpha3: "arm",
            code: "+374",
            id: 51,
            name: "Армения"
        },
        {
            alpha2: "af",
            alpha3: "afg",
            code: "+93",
            id: 4,
            name: "Афганистан"
        },
        {
            alpha2: "bs",
            alpha3: "bhs",
            code: "+1242",
            id: 44,
            name: "Багамские Острова"
        },
        {
            alpha2: "bd",
            alpha3: "bgd",
            code: "+880",
            id: 50,
            name: "Бангладеш"
        },
        {
            alpha2: "bb",
            alpha3: "brb",
            code: "+1246",
            id: 52,
            name: "Барбадос"
        },
        {
            alpha2: "bh",
            alpha3: "bhr",
            code: "+973",
            id: 48,
            name: "Бахрейн"
        },
        {
            alpha2: "bz",
            alpha3: "blz",
            code: "+501",
            id: 84,
            name: "Белиз"
        },
        {
            alpha2: "by",
            alpha3: "blr",
            code: "+375",
            id: 112,
            name: "Беларусь"
        },
        {
            alpha2: "be",
            alpha3: "bel",
            code: "+32",
            id: 56,
            name: "Бельгия"
        },
        {
            alpha2: "bj",
            alpha3: "ben",
            code: "+229",
            id: 204,
            name: "Бенин"
        },
        {
            alpha2: "bg",
            alpha3: "bgr",
            code: "+359",
            id: 100,
            name: "Болгария"
        },
        {
            alpha2: "bo",
            alpha3: "bol",
            code: "+591",
            id: 68,
            name: "Боливия"
        },
        {
            alpha2: "ba",
            alpha3: "bih",
            code: "+387",
            id: 70,
            name: "Босния и Герцеговина"
        },
        {
            alpha2: "bw",
            alpha3: "bwa",
            code: "+267",
            id: 72,
            name: "Ботсвана"
        },
        {
            alpha2: "br",
            alpha3: "bra",
            code: "+55",
            id: 76,
            name: "Бразилия"
        },
        {
            alpha2: "bn",
            alpha3: "brn",
            code: "+673",
            id: 96,
            name: "Бруней"
        },
        {
            alpha2: "bf",
            alpha3: "bfa",
            code: "+226",
            id: 854,
            name: "Буркина-Фасо"
        },
        {
            alpha2: "bi",
            alpha3: "bdi",
            code: "+257",
            id: 108,
            name: "Бурунди"
        },
        {
            alpha2: "bt",
            alpha3: "btn",
            code: "+975",
            id: 64,
            name: "Бутан"
        },
        {
            alpha2: "vu",
            alpha3: "vut",
            code: "+678",
            id: 548,
            name: "Вануату"
        },
        {
            alpha2: "gb",
            alpha3: "gbr",
            code: "+44",
            id: 826,
            name: "Великобритания"
        },
        {
            alpha2: "hu",
            alpha3: "hun",
            code: "+36",
            id: 348,
            name: "Венгрия"
        },
        {
            alpha2: "ve",
            alpha3: "ven",
            code: "+58",
            id: 862,
            name: "Венесуэла"
        },
        {
            alpha2: "tl",
            alpha3: "tls",
            code: "+670",
            id: 626,
            name: "Восточный Тимор"
        },
        {
            alpha2: "vn",
            alpha3: "vnm",
            code: "+84",
            id: 704,
            name: "Вьетнам"
        },
        {
            alpha2: "ga",
            alpha3: "gab",
            code: "+241",
            id: 266,
            name: "Габон"
        },
        {
            alpha2: "ht",
            alpha3: "hti",
            code: "+509",
            id: 332,
            name: "Гаити"
        },
        {
            alpha2: "gy",
            alpha3: "guy",
            code: "+595",
            id: 328,
            name: "Гайана"
        },
        {
            alpha2: "gm",
            alpha3: "gmb",
            code: "+220",
            id: 270,
            name: "Гамбия"
        },
        {
            alpha2: "gh",
            alpha3: "gha",
            code: "+233",
            id: 288,
            name: "Гана"
        },
        {
            alpha2: "gt",
            alpha3: "gtm",
            code: "+502",
            id: 320,
            name: "Гватемала"
        },
        {
            alpha2: "gn",
            alpha3: "gin",
            code: "+224",
            id: 324,
            name: "Гвинея"
        },
        {
            alpha2: "gw",
            alpha3: "gnb",
            code: "+245",
            id: 624,
            name: "Гвинея-Бисау"
        },
        {
            alpha2: "de",
            alpha3: "deu",
            code: "+49",
            id: 276,
            name: "Германия"
        },
        {
            alpha2: "hn",
            alpha3: "hnd",
            code: "+504",
            id: 340,
            name: "Гондурас"
        },
        {
            alpha2: "gd",
            alpha3: "grd",
            code: "+1473",
            id: 308,
            name: "Гренада"
        },
        {
            alpha2: "gr",
            alpha3: "grc",
            code: "+30",
            id: 300,
            name: "Греция"
        },
        {
            alpha2: "ge",
            alpha3: "geo",
            code: "+995",
            id: 268,
            name: "Грузия"
        },
        {
            alpha2: "dk",
            alpha3: "dnk",
            code: "+45",
            id: 208,
            name: "Дания"
        },
        {
            alpha2: "dj",
            alpha3: "dji",
            code: "+253",
            id: 262,
            name: "Джибути"
        },
        {
            alpha2: "dm",
            alpha3: "dma",
            code: "+1767",
            id: 212,
            name: "Доминика"
        },
        {
            alpha2: "do",
            alpha3: "dom",
            code: "+1849",
            id: 214,
            name: "Доминиканская Республика"
        },
        {
            alpha2: "cd",
            alpha3: "cod",
            code: "+243",
            id: 180,
            name: "ДР Конго"
        },
        {
            alpha2: "eg",
            alpha3: "egy",
            code: "+20",
            id: 818,
            name: "Египет"
        },
        {
            alpha2: "zm",
            alpha3: "zmb",
            code: "+260",
            id: 894,
            name: "Замбия"
        },
        {
            alpha2: "zw",
            alpha3: "zwe",
            code: "+263",
            id: 716,
            name: "Зимбабве"
        },
        {
            alpha2: "il",
            alpha3: "isr",
            code: "+972",
            id: 376,
            name: "Израиль"
        },
        {
            alpha2: "in",
            alpha3: "ind",
            code: "+91",
            id: 356,
            name: "Индия"
        },
        {
            alpha2: "id",
            alpha3: "idn",
            code: "+62",
            id: 360,
            name: "Индонезия"
        },
        {
            alpha2: "jo",
            alpha3: "jor",
            code: "+962",
            id: 400,
            name: "Иордания"
        },
        {
            alpha2: "iq",
            alpha3: "irq",
            code: "+964",
            id: 368,
            name: "Ирак"
        },
        {
            alpha2: "ir",
            alpha3: "irn",
            code: "+98",
            id: 364,
            name: "Иран"
        },
        {
            alpha2: "ie",
            alpha3: "irl",
            code: "+353",
            id: 372,
            name: "Ирландия"
        },
        {
            alpha2: "is",
            alpha3: "isl",
            code: "+354",
            id: 352,
            name: "Исландия"
        },
        {
            alpha2: "es",
            alpha3: "esp",
            code: "+34",
            id: 724,
            name: "Испания"
        },
        {
            alpha2: "it",
            alpha3: "ita",
            code: "+39",
            id: 380,
            name: "Италия"
        },
        {
            alpha2: "ye",
            alpha3: "yem",
            code: "+967",
            id: 887,
            name: "Йемен"
        },
        {
            alpha2: "cv",
            alpha3: "cpv",
            code: "+238",
            id: 132,
            name: "Кабо-Верде"
        },
        {
            alpha2: "kz",
            alpha3: "kaz",
            code: "+77",
            id: 398,
            name: "Казахстан"
        },
        {
            alpha2: "kh",
            alpha3: "khm",
            code: "+855",
            id: 116,
            name: "Камбоджа"
        },
        {
            alpha2: "cm",
            alpha3: "cmr",
            code: "+237",
            id: 120,
            name: "Камерун"
        },
        {
            alpha2: "ca",
            alpha3: "can",
            code: "+1",
            id: 124,
            name: "Канада"
        },
        {
            alpha2: "qa",
            alpha3: "qat",
            code: "+974",
            id: 634,
            name: "Катар"
        },
        {
            alpha2: "ke",
            alpha3: "ken",
            code: "+254",
            id: 404,
            name: "Кения"
        },
        {
            alpha2: "cy",
            alpha3: "cyp",
            code: "+357",
            id: 196,
            name: "Кипр"
        },
        {
            alpha2: "kg",
            alpha3: "kgz",
            code: "+996",
            id: 417,
            name: "Киргизия"
        },
        {
            alpha2: "ki",
            alpha3: "kir",
            code: "+686",
            id: 296,
            name: "Кирибати"
        },
        {
            alpha2: "kp",
            alpha3: "prk",
            code: "+850",
            id: 408,
            name: "КНДР (Корейская Народно-Демократическая Республика)"
        },
        {
            alpha2: "cn",
            alpha3: "chn",
            code: "+86",
            id: 156,
            name: "Китай (Китайская Народная Республика)"
        },
        {
            alpha2: "co",
            alpha3: "col",
            code: "+57",
            id: 170,
            name: "Колумбия"
        },
        {
            alpha2: "km",
            alpha3: "com",
            code: "+269",
            id: 174,
            name: "Коморы"
        },
        {
            alpha2: "cr",
            alpha3: "cri",
            code: "+506",
            id: 188,
            name: "Коста-Рика"
        },
        {
            alpha2: "ci",
            alpha3: "civ",
            code: "+225",
            id: 384,
            name: "Кот-д’Ивуар"
        },
        {
            alpha2: "cu",
            alpha3: "cub",
            code: "+53",
            id: 192,
            name: "Куба"
        },
        {
            alpha2: "kw",
            alpha3: "kwt",
            code: "+965",
            id: 414,
            name: "Кувейт"
        },
        {
            alpha2: "la",
            alpha3: "lao",
            code: "+856",
            id: 418,
            name: "Лаос"
        },
        {
            alpha2: "lv",
            alpha3: "lva",
            code: "+371",
            id: 428,
            name: "Латвия"
        },
        {
            alpha2: "ls",
            alpha3: "lso",
            code: "+266",
            id: 426,
            name: "Лесото"
        },
        {
            alpha2: "lr",
            alpha3: "lbr",
            code: "+231",
            id: 430,
            name: "Либерия"
        },
        {
            alpha2: "lb",
            alpha3: "lbn",
            code: "+961",
            id: 422,
            name: "Ливан"
        },
        {
            alpha2: "ly",
            alpha3: "lby",
            code: "+218",
            id: 434,
            name: "Ливия"
        },
        {
            alpha2: "lt",
            alpha3: "ltu",
            code: "+370",
            id: 440,
            name: "Литва"
        },
        {
            alpha2: "li",
            alpha3: "lie",
            code: "+423",
            id: 438,
            name: "Лихтенштейн"
        },
        {
            alpha2: "lu",
            alpha3: "lux",
            code: "+352",
            id: 442,
            name: "Люксембург"
        },
        {
            alpha2: "mu",
            alpha3: "mus",
            code: "+230",
            id: 480,
            name: "Маврикий"
        },
        {
            alpha2: "mr",
            alpha3: "mrt",
            code: "+222",
            id: 478,
            name: "Мавритания"
        },
        {
            alpha2: "mg",
            alpha3: "mdg",
            code: "+261",
            id: 450,
            name: "Мадагаскар"
        },
        {
            alpha2: "mk",
            alpha3: "mkd",
            code: "+389",
            id: 807,
            name: "Северная Македония"
        },
        {
            alpha2: "mw",
            alpha3: "mwi",
            code: "+265",
            id: 454,
            name: "Малави"
        },
        {
            alpha2: "my",
            alpha3: "mys",
            code: "+60",
            id: 458,
            name: "Малайзия"
        },
        {
            alpha2: "ml",
            alpha3: "mli",
            code: "+223",
            id: 466,
            name: "Мали"
        },
        {
            alpha2: "mv",
            alpha3: "mdv",
            code: "+960",
            id: 462,
            name: "Мальдивы"
        },
        {
            alpha2: "mt",
            alpha3: "mlt",
            code: "+356",
            id: 470,
            name: "Мальта"
        },
        {
            alpha2: "ma",
            alpha3: "mar",
            code: "+212",
            id: 504,
            name: "Марокко"
        },
        {
            alpha2: "mh",
            alpha3: "mhl",
            code: "+692",
            id: 584,
            name: "Маршалловы Острова"
        },
        {
            alpha2: "mx",
            alpha3: "mex",
            code: "+52",
            id: 484,
            name: "Мексика"
        },
        {
            alpha2: "fm",
            alpha3: "fsm",
            code: "+691",
            id: 583,
            name: "Микронезия"
        },
        {
            alpha2: "mz",
            alpha3: "moz",
            code: "+258",
            id: 508,
            name: "Мозамбик"
        },
        {
            alpha2: "md",
            alpha3: "mda",
            code: "+373",
            id: 498,
            name: "Молдавия"
        },
        {
            alpha2: "mc",
            alpha3: "mco",
            code: "+377",
            id: 492,
            name: "Монако"
        },
        {
            alpha2: "mn",
            alpha3: "mng",
            code: "+976",
            id: 496,
            name: "Монголия"
        },
        {
            alpha2: "mm",
            alpha3: "mmr",
            code: "+95",
            id: 104,
            name: "Мьянма"
        },
        {
            alpha2: "na",
            alpha3: "nam",
            code: "+264",
            id: 516,
            name: "Намибия"
        },
        {
            alpha2: "nr",
            alpha3: "nru",
            code: "+674",
            id: 520,
            name: "Науру"
        },
        {
            alpha2: "np",
            alpha3: "npl",
            code: "+977",
            id: 524,
            name: "Непал"
        },
        {
            alpha2: "ne",
            alpha3: "ner",
            code: "+227",
            id: 562,
            name: "Нигер"
        },
        {
            alpha2: "ng",
            alpha3: "nga",
            code: "+234",
            id: 566,
            name: "Нигерия"
        },
        {
            alpha2: "nl",
            alpha3: "nld",
            code: "+31",
            id: 528,
            name: "Нидерланды"
        },
        {
            alpha2: "ni",
            alpha3: "nic",
            code: "+505",
            id: 558,
            name: "Никарагуа"
        },
        {
            alpha2: "nz",
            alpha3: "nzl",
            code: "+64",
            id: 554,
            name: "Новая Зеландия"
        },
        {
            alpha2: "no",
            alpha3: "nor",
            code: "+47",
            id: 578,
            name: "Норвегия"
        },
        {
            alpha2: "ae",
            alpha3: "are",
            code: "+971",
            id: 784,
            name: "ОАЭ"
        },
        {
            alpha2: "om",
            alpha3: "omn",
            code: "+968",
            id: 512,
            name: "Оман"
        },
        {
            alpha2: "pk",
            alpha3: "pak",
            code: "+92",
            id: 586,
            name: "Пакистан"
        },
        {
            alpha2: "pw",
            alpha3: "plw",
            code: "+680",
            id: 585,
            name: "Палау"
        },
        {
            alpha2: "pa",
            alpha3: "pan",
            code: "+507",
            id: 591,
            name: "Панама"
        },
        {
            alpha2: "pg",
            alpha3: "png",
            code: "+675",
            id: 598,
            name: "Папуа — Новая Гвинея"
        },
        {
            alpha2: "py",
            alpha3: "pry",
            code: "+595",
            id: 600,
            name: "Парагвай"
        },
        {
            alpha2: "pe",
            alpha3: "per",
            code: "+51",
            id: 604,
            name: "Перу"
        },
        {
            alpha2: "pl",
            alpha3: "pol",
            code: "+48",
            id: 616,
            name: "Польша"
        },
        {
            alpha2: "pt",
            alpha3: "prt",
            code: "+351",
            id: 620,
            name: "Португалия"
        },
        {
            alpha2: "cg",
            alpha3: "cog",
            code: "+242",
            id: 178,
            name: "Республика Конго"
        },
        {
            alpha2: "kr",
            alpha3: "kor",
            code: "+82",
            id: 410,
            name: "Республика Корея"
        },
        {
            alpha2: "ru",
            alpha3: "rus",
            code: "+7",
            id: 643,
            name: "Россия"
        },
        {
            alpha2: "rw",
            alpha3: "rwa",
            code: "+250",
            id: 646,
            name: "Руанда"
        },
        {
            alpha2: "ro",
            alpha3: "rou",
            code: "+40",
            id: 642,
            name: "Румыния"
        },
        {
            alpha2: "sv",
            alpha3: "slv",
            code: "+503",
            id: 222,
            name: "Сальвадор"
        },
        {
            alpha2: "ws",
            alpha3: "wsm",
            code: "+685",
            id: 882,
            name: "Самоа"
        },
        {
            alpha2: "sm",
            alpha3: "smr",
            code: "+378",
            id: 674,
            name: "Сан-Марино"
        },
        {
            alpha2: "st",
            alpha3: "stp",
            code: "+239",
            id: 678,
            name: "Сан-Томе и Принсипи"
        },
        {
            alpha2: "sa",
            alpha3: "sau",
            code: "+966",
            id: 682,
            name: "Саудовская Аравия"
        },
        {
            alpha2: "sz",
            alpha3: "swz",
            code: "+268",
            id: 748,
            name: "Эсватини"
        },
        {
            alpha2: "sc",
            alpha3: "syc",
            code: "+248",
            id: 690,
            name: "Сейшельские Острова"
        },
        {
            alpha2: "sn",
            alpha3: "sen",
            code: "+221",
            id: 686,
            name: "Сенегал"
        },
        {
            alpha2: "vc",
            alpha3: "vct",
            code: "+1784",
            id: 670,
            name: "Сент-Винсент и Гренадины"
        },
        {
            alpha2: "kn",
            alpha3: "kna",
            code: "+1869",
            id: 659,
            name: "Сент-Китс и Невис"
        },
        {
            alpha2: "lc",
            alpha3: "lca",
            code: "+1758",
            id: 662,
            name: "Сент-Люсия"
        },
        {
            alpha2: "rs",
            alpha3: "srb",
            code: "+381",
            id: 688,
            name: "Сербия"
        },
        {
            alpha2: "sg",
            alpha3: "sgp",
            code: "+65",
            id: 702,
            name: "Сингапур"
        },
        {
            alpha2: "sy",
            alpha3: "syr",
            code: "+963",
            id: 760,
            name: "Сирия"
        },
        {
            alpha2: "sk",
            alpha3: "svk",
            code: "+421",
            id: 703,
            name: "Словакия"
        },
        {
            alpha2: "si",
            alpha3: "svn",
            code: "+386",
            id: 705,
            name: "Словения"
        },
        {
            alpha2: "sb",
            alpha3: "slb",
            code: "+677",
            id: 90,
            name: "Соломоновы Острова"
        },
        {
            alpha2: "so",
            alpha3: "som",
            code: "+252",
            id: 706,
            name: "Сомали"
        },
        {
            alpha2: "sd",
            alpha3: "sdn",
            code: "+249",
            id: 729,
            name: "Судан"
        },
        {
            alpha2: "sr",
            alpha3: "sur",
            code: "+597",
            id: 740,
            name: "Суринам"
        },
        {
            alpha2: "us",
            alpha3: "usa",
            code: "+1",
            id: 840,
            name: "США"
        },
        {
            alpha2: "sl",
            alpha3: "sle",
            code: "+232",
            id: 694,
            name: "Сьерра-Леоне"
        },
        {
            alpha2: "tj",
            alpha3: "tjk",
            code: "+992",
            id: 762,
            name: "Таджикистан"
        },
        {
            alpha2: "th",
            alpha3: "tha",
            code: "+66",
            id: 764,
            name: "Таиланд"
        },
        {
            alpha2: "tz",
            alpha3: "tza",
            code: "+255",
            id: 834,
            name: "Танзания"
        },
        {
            alpha2: "tg",
            alpha3: "tgo",
            code: "+228",
            id: 768,
            name: "Того"
        },
        {
            alpha2: "to",
            alpha3: "ton",
            code: "+676",
            id: 776,
            name: "Тонга"
        },
        {
            alpha2: "tt",
            alpha3: "tto",
            code: "+1868",
            id: 780,
            name: "Тринидад и Тобаго"
        },
        {
            alpha2: "tv",
            alpha3: "tuv",
            code: "+688",
            id: 798,
            name: "Тувалу"
        },
        {
            alpha2: "tn",
            alpha3: "tun",
            code: "+216",
            id: 788,
            name: "Тунис"
        },
        {
            alpha2: "tm",
            alpha3: "tkm",
            code: "+993",
            id: 795,
            name: "Туркменистан"
        },
        {
            alpha2: "tr",
            alpha3: "tur",
            code: "+90",
            id: 792,
            name: "Турция"
        },
        {
            alpha2: "ug",
            alpha3: "uga",
            code: "+256",
            id: 800,
            name: "Уганда"
        },
        {
            alpha2: "uz",
            alpha3: "uzb",
            code: "+998",
            id: 860,
            name: "Узбекистан"
        },
        {
            alpha2: "ua",
            alpha3: "ukr",
            code: "+380",
            id: 804,
            name: "Украина"
        },
        {
            alpha2: "uy",
            alpha3: "ury",
            code: "+598",
            id: 858,
            name: "Уругвай"
        },
        {
            alpha2: "fj",
            alpha3: "fji",
            code: "+679",
            id: 242,
            name: "Фиджи"
        },
        {
            alpha2: "ph",
            alpha3: "phl",
            code: "+63",
            id: 608,
            name: "Филиппины"
        },
        {
            alpha2: "fi",
            alpha3: "fin",
            code: "+358",
            id: 246,
            name: "Финляндия"
        },
        {
            alpha2: "fr",
            alpha3: "fra",
            code: "+33",
            id: 250,
            name: "Франция"
        },
        {
            alpha2: "hr",
            alpha3: "hrv",
            code: "+385",
            id: 191,
            name: "Хорватия"
        },
        {
            alpha2: "cf",
            alpha3: "caf",
            code: "+236",
            id: 140,
            name: "ЦАР"
        },
        {
            alpha2: "td",
            alpha3: "tcd",
            code: "+235",
            id: 148,
            name: "Чад"
        },
        {
            alpha2: "me",
            alpha3: "mne",
            code: "+382",
            id: 499,
            name: "Черногория"
        },
        {
            alpha2: "cz",
            alpha3: "cze",
            code: "+420",
            id: 203,
            name: "Чехия"
        },
        {
            alpha2: "cl",
            alpha3: "chl",
            code: "+56",
            id: 152,
            name: "Чили"
        },
        {
            alpha2: "ch",
            alpha3: "che",
            code: "+41",
            id: 756,
            name: "Швейцария"
        },
        {
            alpha2: "se",
            alpha3: "swe",
            code: "+46",
            id: 752,
            name: "Швеция"
        },
        {
            alpha2: "lk",
            alpha3: "lka",
            code: "+94",
            id: 144,
            name: "Шри-Ланка"
        },
        {
            alpha2: "ec",
            alpha3: "ecu",
            code: "+593",
            id: 218,
            name: "Эквадор"
        },
        {
            alpha2: "gq",
            alpha3: "gnq",
            code: "+240",
            id: 226,
            name: "Экваториальная Гвинея"
        },
        {
            alpha2: "er",
            alpha3: "eri",
            code: "+291",
            id: 232,
            name: "Эритрея"
        },
        {
            alpha2: "ee",
            alpha3: "est",
            code: "+372",
            id: 233,
            name: "Эстония"
        },
        {
            alpha2: "et",
            alpha3: "eth",
            code: "+251",
            id: 231,
            name: "Эфиопия"
        },
        { alpha2: "za", alpha3: "zaf", code: "+27", id: 710, name: "ЮАР" },
        {
            alpha2: "ss",
            alpha3: "ssd",
            code: "+211",
            id: 728,
            name: "Южный Судан"
        },
        {
            alpha2: "jm",
            alpha3: "jam",
            code: "+1876",
            id: 388,
            name: "Ямайка"
        },
        { alpha2: "jp", alpha3: "jpn", code: "+81", id: 392, name: "Япония" }
    ],
    fr: [
        {
            alpha2: "af",
            alpha3: "afg",
            code: "+93",
            id: 4,
            name: "Afghanistan"
        },
        {
            alpha2: "za",
            alpha3: "zaf",
            code: "+27",
            id: 710,
            name: "Afrique du Sud"
        },
        {
            alpha2: "al",
            alpha3: "alb",
            code: "+355",
            id: 8,
            name: "Albanie"
        },
        {
            alpha2: "dz",
            alpha3: "dza",
            code: "+213",
            id: 12,
            name: "Algérie"
        },
        {
            alpha2: "de",
            alpha3: "deu",
            code: "+49",
            id: 276,
            name: "Allemagne"
        },
        {
            alpha2: "ad",
            alpha3: "and",
            code: "+376",
            id: 20,
            name: "Andorre"
        },
        {
            alpha2: "ao",
            alpha3: "ago",
            code: "+244",
            id: 24,
            name: "Angola"
        },
        {
            alpha2: "ag",
            alpha3: "atg",
            code: "+1268",
            id: 28,
            name: "Antigua-et-Barbuda"
        },
        {
            alpha2: "sa",
            alpha3: "sau",
            code: "+966",
            id: 682,
            name: "Arabie saoudite"
        },
        {
            alpha2: "ar",
            alpha3: "arg",
            code: "+54",
            id: 32,
            name: "Argentine"
        },
        {
            alpha2: "am",
            alpha3: "arm",
            code: "+374",
            id: 51,
            name: "Arménie"
        },
        {
            alpha2: "au",
            alpha3: "aus",
            code: "+61",
            id: 36,
            name: "Australie"
        },
        {
            alpha2: "at",
            alpha3: "aut",
            code: "+43",
            id: 40,
            name: "Autriche"
        },
        {
            alpha2: "az",
            alpha3: "aze",
            code: "+994",
            id: 31,
            name: "Azerbaïdjan"
        },
        {
            alpha2: "bs",
            alpha3: "bhs",
            code: "+1242",
            id: 44,
            name: "Bahamas"
        },
        {
            alpha2: "bh",
            alpha3: "bhr",
            code: "+973",
            id: 48,
            name: "Bahreïn"
        },
        {
            alpha2: "bd",
            alpha3: "bgd",
            code: "+880",
            id: 50,
            name: "Bangladesh"
        },
        {
            alpha2: "bb",
            alpha3: "brb",
            code: "+1246",
            id: 52,
            name: "Barbade"
        },
        {
            alpha2: "by",
            alpha3: "blr",
            code: "+375",
            id: 112,
            name: "Biélorussie"
        },
        {
            alpha2: "be",
            alpha3: "bel",
            code: "+32",
            id: 56,
            name: "Belgique"
        },
        {
            alpha2: "bz",
            alpha3: "blz",
            code: "+501",
            id: 84,
            name: "Belize"
        },
        {
            alpha2: "bj",
            alpha3: "ben",
            code: "+229",
            id: 204,
            name: "Bénin"
        },
        {
            alpha2: "bt",
            alpha3: "btn",
            code: "+975",
            id: 64,
            name: "Bhoutan"
        },
        {
            alpha2: "bo",
            alpha3: "bol",
            code: "+591",
            id: 68,
            name: "Bolivie"
        },
        {
            alpha2: "ba",
            alpha3: "bih",
            code: "+387",
            id: 70,
            name: "Bosnie-Herzégovine"
        },
        {
            alpha2: "bw",
            alpha3: "bwa",
            code: "+267",
            id: 72,
            name: "Botswana"
        },
        {
            alpha2: "br",
            alpha3: "bra",
            code: "+55",
            id: 76,
            name: "Brésil"
        },
        {
            alpha2: "bn",
            alpha3: "brn",
            code: "+673",
            id: 96,
            name: "Brunei"
        },
        {
            alpha2: "bg",
            alpha3: "bgr",
            code: "+359",
            id: 100,
            name: "Bulgarie"
        },
        {
            alpha2: "bf",
            alpha3: "bfa",
            code: "+226",
            id: 854,
            name: "Burkina Faso"
        },
        {
            alpha2: "bi",
            alpha3: "bdi",
            code: "+257",
            id: 108,
            name: "Burundi"
        },
        {
            alpha2: "kh",
            alpha3: "khm",
            code: "+855",
            id: 116,
            name: "Cambodge"
        },
        {
            alpha2: "cm",
            alpha3: "cmr",
            code: "+237",
            id: 120,
            name: "Cameroun"
        },
        {
            alpha2: "ca",
            alpha3: "can",
            code: "+1",
            id: 124,
            name: "Canada"
        },
        {
            alpha2: "cv",
            alpha3: "cpv",
            code: "+238",
            id: 132,
            name: "Cap-Vert"
        },
        {
            alpha2: "cf",
            alpha3: "caf",
            code: "+236",
            id: 140,
            name: "République centrafricaine"
        },
        {
            alpha2: "cl",
            alpha3: "chl",
            code: "+56",
            id: 152,
            name: "Chili"
        },
        {
            alpha2: "cn",
            alpha3: "chn",
            code: "+86",
            id: 156,
            name: "Chine"
        },
        {
            alpha2: "cy",
            alpha3: "cyp",
            code: "+357",
            id: 196,
            name: "Chypre"
        },
        {
            alpha2: "co",
            alpha3: "col",
            code: "+57",
            id: 170,
            name: "Colombie"
        },
        {
            alpha2: "km",
            alpha3: "com",
            code: "+269",
            id: 174,
            name: "Comores"
        },
        {
            alpha2: "cg",
            alpha3: "cog",
            code: "+242",
            id: 178,
            name: "République du Congo"
        },
        {
            alpha2: "cd",
            alpha3: "cod",
            code: "+243",
            id: 180,
            name: "République démocratique du Congo"
        },
        {
            alpha2: "kr",
            alpha3: "kor",
            code: "+82",
            id: 410,
            name: "Corée du Sud"
        },
        {
            alpha2: "kp",
            alpha3: "prk",
            code: "+850",
            id: 408,
            name: "Corée du Nord"
        },
        {
            alpha2: "cr",
            alpha3: "cri",
            code: "+506",
            id: 188,
            name: "Costa Rica"
        },
        {
            alpha2: "ci",
            alpha3: "civ",
            code: "+225",
            id: 384,
            name: "Côte d'Ivoire"
        },
        {
            alpha2: "hr",
            alpha3: "hrv",
            code: "+385",
            id: 191,
            name: "Croatie"
        },
        {
            alpha2: "cu",
            alpha3: "cub",
            code: "+53",
            id: 192,
            name: "Cuba"
        },
        {
            alpha2: "dk",
            alpha3: "dnk",
            code: "+45",
            id: 208,
            name: "Danemark"
        },
        {
            alpha2: "dj",
            alpha3: "dji",
            code: "+253",
            id: 262,
            name: "Djibouti"
        },
        {
            alpha2: "do",
            alpha3: "dom",
            code: "+1849",
            id: 214,
            name: "République dominicaine"
        },
        {
            alpha2: "dm",
            alpha3: "dma",
            code: "+1767",
            id: 212,
            name: "Dominique"
        },
        {
            alpha2: "eg",
            alpha3: "egy",
            code: "+20",
            id: 818,
            name: "Égypte"
        },
        {
            alpha2: "ae",
            alpha3: "are",
            code: "+971",
            id: 784,
            name: "Émirats arabes unis"
        },
        {
            alpha2: "ec",
            alpha3: "ecu",
            code: "+593",
            id: 218,
            name: "Équateur"
        },
        {
            alpha2: "er",
            alpha3: "eri",
            code: "+291",
            id: 232,
            name: "Érythrée"
        },
        {
            alpha2: "es",
            alpha3: "esp",
            code: "+34",
            id: 724,
            name: "Espagne"
        },
        {
            alpha2: "ee",
            alpha3: "est",
            code: "+372",
            id: 233,
            name: "Estonie"
        },
        {
            alpha2: "us",
            alpha3: "usa",
            code: "+1",
            id: 840,
            name: "États-Unis"
        },
        {
            alpha2: "et",
            alpha3: "eth",
            code: "+251",
            id: 231,
            name: "Éthiopie"
        },
        {
            alpha2: "fj",
            alpha3: "fji",
            code: "+679",
            id: 242,
            name: "Fidji"
        },
        {
            alpha2: "fi",
            alpha3: "fin",
            code: "+358",
            id: 246,
            name: "Finlande"
        },
        {
            alpha2: "fr",
            alpha3: "fra",
            code: "+33",
            id: 250,
            name: "France"
        },
        {
            alpha2: "ga",
            alpha3: "gab",
            code: "+241",
            id: 266,
            name: "Gabon"
        },
        {
            alpha2: "gm",
            alpha3: "gmb",
            code: "+220",
            id: 270,
            name: "Gambie"
        },
        {
            alpha2: "ge",
            alpha3: "geo",
            code: "+995",
            id: 268,
            name: "Géorgie"
        },
        {
            alpha2: "gh",
            alpha3: "gha",
            code: "+233",
            id: 288,
            name: "Ghana"
        },
        {
            alpha2: "gr",
            alpha3: "grc",
            code: "+30",
            id: 300,
            name: "Grèce"
        },
        {
            alpha2: "gd",
            alpha3: "grd",
            code: "+1473",
            id: 308,
            name: "Grenade"
        },
        {
            alpha2: "gt",
            alpha3: "gtm",
            code: "+502",
            id: 320,
            name: "Guatemala"
        },
        {
            alpha2: "gn",
            alpha3: "gin",
            code: "+224",
            id: 324,
            name: "Guinée"
        },
        {
            alpha2: "gw",
            alpha3: "gnb",
            code: "+245",
            id: 624,
            name: "Guinée-Bissau"
        },
        {
            alpha2: "gq",
            alpha3: "gnq",
            code: "+240",
            id: 226,
            name: "Guinée équatoriale"
        },
        {
            alpha2: "gy",
            alpha3: "guy",
            code: "+595",
            id: 328,
            name: "Guyana"
        },
        {
            alpha2: "ht",
            alpha3: "hti",
            code: "+509",
            id: 332,
            name: "Haïti"
        },
        {
            alpha2: "hn",
            alpha3: "hnd",
            code: "+504",
            id: 340,
            name: "Honduras"
        },
        {
            alpha2: "hu",
            alpha3: "hun",
            code: "+36",
            id: 348,
            name: "Hongrie"
        },
        {
            alpha2: "in",
            alpha3: "ind",
            code: "+91",
            id: 356,
            name: "Inde"
        },
        {
            alpha2: "id",
            alpha3: "idn",
            code: "+62",
            id: 360,
            name: "Indonésie"
        },
        {
            alpha2: "ir",
            alpha3: "irn",
            code: "+98",
            id: 364,
            name: "Iran"
        },
        {
            alpha2: "iq",
            alpha3: "irq",
            code: "+964",
            id: 368,
            name: "Irak"
        },
        {
            alpha2: "ie",
            alpha3: "irl",
            code: "+353",
            id: 372,
            name: "Irlande"
        },
        {
            alpha2: "is",
            alpha3: "isl",
            code: "+354",
            id: 352,
            name: "Islande"
        },
        {
            alpha2: "il",
            alpha3: "isr",
            code: "+972",
            id: 376,
            name: "Israël"
        },
        {
            alpha2: "it",
            alpha3: "ita",
            code: "+39",
            id: 380,
            name: "Italie"
        },
        {
            alpha2: "jm",
            alpha3: "jam",
            code: "+1876",
            id: 388,
            name: "Jamaïque"
        },
        {
            alpha2: "jp",
            alpha3: "jpn",
            code: "+81",
            id: 392,
            name: "Japon"
        },
        {
            alpha2: "jo",
            alpha3: "jor",
            code: "+962",
            id: 400,
            name: "Jordanie"
        },
        {
            alpha2: "kz",
            alpha3: "kaz",
            code: "+77",
            id: 398,
            name: "Kazakhstan"
        },
        {
            alpha2: "ke",
            alpha3: "ken",
            code: "+254",
            id: 404,
            name: "Kenya"
        },
        {
            alpha2: "kg",
            alpha3: "kgz",
            code: "+996",
            id: 417,
            name: "Kirghizistan"
        },
        {
            alpha2: "ki",
            alpha3: "kir",
            code: "+686",
            id: 296,
            name: "Kiribati"
        },
        {
            alpha2: "kw",
            alpha3: "kwt",
            code: "+965",
            id: 414,
            name: "Koweït"
        },
        {
            alpha2: "la",
            alpha3: "lao",
            code: "+856",
            id: 418,
            name: "Laos"
        },
        {
            alpha2: "ls",
            alpha3: "lso",
            code: "+266",
            id: 426,
            name: "Lesotho"
        },
        {
            alpha2: "lv",
            alpha3: "lva",
            code: "+371",
            id: 428,
            name: "Lettonie"
        },
        {
            alpha2: "lb",
            alpha3: "lbn",
            code: "+961",
            id: 422,
            name: "Liban"
        },
        {
            alpha2: "lr",
            alpha3: "lbr",
            code: "+231",
            id: 430,
            name: "Liberia"
        },
        {
            alpha2: "ly",
            alpha3: "lby",
            code: "+218",
            id: 434,
            name: "Libye"
        },
        {
            alpha2: "li",
            alpha3: "lie",
            code: "+423",
            id: 438,
            name: "Liechtenstein"
        },
        {
            alpha2: "lt",
            alpha3: "ltu",
            code: "+370",
            id: 440,
            name: "Lituanie"
        },
        {
            alpha2: "lu",
            alpha3: "lux",
            code: "+352",
            id: 442,
            name: "Luxembourg"
        },
        {
            alpha2: "mk",
            alpha3: "mkd",
            code: "+389",
            id: 807,
            name: "Macédoine du Nord"
        },
        {
            alpha2: "mg",
            alpha3: "mdg",
            code: "+261",
            id: 450,
            name: "Madagascar"
        },
        {
            alpha2: "my",
            alpha3: "mys",
            code: "+60",
            id: 458,
            name: "Malaisie"
        },
        {
            alpha2: "mw",
            alpha3: "mwi",
            code: "+265",
            id: 454,
            name: "Malawi"
        },
        {
            alpha2: "mv",
            alpha3: "mdv",
            code: "+960",
            id: 462,
            name: "Maldives"
        },
        {
            alpha2: "ml",
            alpha3: "mli",
            code: "+223",
            id: 466,
            name: "Mali"
        },
        {
            alpha2: "mt",
            alpha3: "mlt",
            code: "+356",
            id: 470,
            name: "Malte"
        },
        {
            alpha2: "ma",
            alpha3: "mar",
            code: "+212",
            id: 504,
            name: "Maroc"
        },
        {
            alpha2: "mh",
            alpha3: "mhl",
            code: "+692",
            id: 584,
            name: "Îles Marshall"
        },
        {
            alpha2: "mu",
            alpha3: "mus",
            code: "+230",
            id: 480,
            name: "Maurice"
        },
        {
            alpha2: "mr",
            alpha3: "mrt",
            code: "+222",
            id: 478,
            name: "Mauritanie"
        },
        {
            alpha2: "mx",
            alpha3: "mex",
            code: "+52",
            id: 484,
            name: "Mexique"
        },
        {
            alpha2: "fm",
            alpha3: "fsm",
            code: "+691",
            id: 583,
            name: "États fédérés de Micronésie"
        },
        {
            alpha2: "md",
            alpha3: "mda",
            code: "+373",
            id: 498,
            name: "Moldavie"
        },
        {
            alpha2: "mc",
            alpha3: "mco",
            code: "+377",
            id: 492,
            name: "Monaco"
        },
        {
            alpha2: "mn",
            alpha3: "mng",
            code: "+976",
            id: 496,
            name: "Mongolie"
        },
        {
            alpha2: "me",
            alpha3: "mne",
            code: "+382",
            id: 499,
            name: "Monténégro"
        },
        {
            alpha2: "mz",
            alpha3: "moz",
            code: "+258",
            id: 508,
            name: "Mozambique"
        },
        {
            alpha2: "mm",
            alpha3: "mmr",
            code: "+95",
            id: 104,
            name: "Birmanie"
        },
        {
            alpha2: "na",
            alpha3: "nam",
            code: "+264",
            id: 516,
            name: "Namibie"
        },
        {
            alpha2: "nr",
            alpha3: "nru",
            code: "+674",
            id: 520,
            name: "Nauru"
        },
        {
            alpha2: "np",
            alpha3: "npl",
            code: "+977",
            id: 524,
            name: "Népal"
        },
        {
            alpha2: "ni",
            alpha3: "nic",
            code: "+505",
            id: 558,
            name: "Nicaragua"
        },
        {
            alpha2: "ne",
            alpha3: "ner",
            code: "+227",
            id: 562,
            name: "Niger"
        },
        {
            alpha2: "ng",
            alpha3: "nga",
            code: "+234",
            id: 566,
            name: "Nigeria"
        },
        {
            alpha2: "no",
            alpha3: "nor",
            code: "+47",
            id: 578,
            name: "Norvège"
        },
        {
            alpha2: "nz",
            alpha3: "nzl",
            code: "+64",
            id: 554,
            name: "Nouvelle-Zélande"
        },
        {
            alpha2: "om",
            alpha3: "omn",
            code: "+968",
            id: 512,
            name: "Oman"
        },
        {
            alpha2: "ug",
            alpha3: "uga",
            code: "+256",
            id: 800,
            name: "Ouganda"
        },
        {
            alpha2: "uz",
            alpha3: "uzb",
            code: "+998",
            id: 860,
            name: "Ouzbékistan"
        },
        {
            alpha2: "pk",
            alpha3: "pak",
            code: "+92",
            id: 586,
            name: "Pakistan"
        },
        {
            alpha2: "pw",
            alpha3: "plw",
            code: "+680",
            id: 585,
            name: "Palaos"
        },
        {
            alpha2: "pa",
            alpha3: "pan",
            code: "+507",
            id: 591,
            name: "Panama"
        },
        {
            alpha2: "pg",
            alpha3: "png",
            code: "+675",
            id: 598,
            name: "Papouasie-Nouvelle-Guinée"
        },
        {
            alpha2: "py",
            alpha3: "pry",
            code: "+595",
            id: 600,
            name: "Paraguay"
        },
        {
            alpha2: "nl",
            alpha3: "nld",
            code: "+31",
            id: 528,
            name: "Pays-Bas"
        },
        {
            alpha2: "pe",
            alpha3: "per",
            code: "+51",
            id: 604,
            name: "Pérou"
        },
        {
            alpha2: "ph",
            alpha3: "phl",
            code: "+63",
            id: 608,
            name: "Philippines"
        },
        {
            alpha2: "pl",
            alpha3: "pol",
            code: "+48",
            id: 616,
            name: "Pologne"
        },
        {
            alpha2: "pt",
            alpha3: "prt",
            code: "+351",
            id: 620,
            name: "Portugal"
        },
        {
            alpha2: "qa",
            alpha3: "qat",
            code: "+974",
            id: 634,
            name: "Qatar"
        },
        {
            alpha2: "ro",
            alpha3: "rou",
            code: "+40",
            id: 642,
            name: "Roumanie"
        },
        {
            alpha2: "gb",
            alpha3: "gbr",
            code: "+44",
            id: 826,
            name: "Royaume-Uni"
        },
        {
            alpha2: "ru",
            alpha3: "rus",
            code: "+7",
            id: 643,
            name: "Russie"
        },
        {
            alpha2: "rw",
            alpha3: "rwa",
            code: "+250",
            id: 646,
            name: "Rwanda"
        },
        {
            alpha2: "kn",
            alpha3: "kna",
            code: "+1869",
            id: 659,
            name: "Saint-Christophe-et-Niévès"
        },
        {
            alpha2: "sm",
            alpha3: "smr",
            code: "+378",
            id: 674,
            name: "Saint-Marin"
        },
        {
            alpha2: "vc",
            alpha3: "vct",
            code: "+1784",
            id: 670,
            name: "Saint-Vincent-et-les-Grenadines"
        },
        {
            alpha2: "lc",
            alpha3: "lca",
            code: "+1758",
            id: 662,
            name: "Sainte-Lucie"
        },
        {
            alpha2: "sb",
            alpha3: "slb",
            code: "+677",
            id: 90,
            name: "Îles Salomon"
        },
        {
            alpha2: "sv",
            alpha3: "slv",
            code: "+503",
            id: 222,
            name: "Salvador"
        },
        {
            alpha2: "ws",
            alpha3: "wsm",
            code: "+685",
            id: 882,
            name: "Samoa"
        },
        {
            alpha2: "st",
            alpha3: "stp",
            code: "+239",
            id: 678,
            name: "Sao Tomé-et-Principe"
        },
        {
            alpha2: "sn",
            alpha3: "sen",
            code: "+221",
            id: 686,
            name: "Sénégal"
        },
        {
            alpha2: "rs",
            alpha3: "srb",
            code: "+381",
            id: 688,
            name: "Serbie"
        },
        {
            alpha2: "sc",
            alpha3: "syc",
            code: "+248",
            id: 690,
            name: "Seychelles"
        },
        {
            alpha2: "sl",
            alpha3: "sle",
            code: "+232",
            id: 694,
            name: "Sierra Leone"
        },
        {
            alpha2: "sg",
            alpha3: "sgp",
            code: "+65",
            id: 702,
            name: "Singapour"
        },
        {
            alpha2: "sk",
            alpha3: "svk",
            code: "+421",
            id: 703,
            name: "Slovaquie"
        },
        {
            alpha2: "si",
            alpha3: "svn",
            code: "+386",
            id: 705,
            name: "Slovénie"
        },
        {
            alpha2: "so",
            alpha3: "som",
            code: "+252",
            id: 706,
            name: "Somalie"
        },
        {
            alpha2: "sd",
            alpha3: "sdn",
            code: "+249",
            id: 729,
            name: "Soudan"
        },
        {
            alpha2: "ss",
            alpha3: "ssd",
            code: "+211",
            id: 728,
            name: "Soudan du Sud"
        },
        {
            alpha2: "lk",
            alpha3: "lka",
            code: "+94",
            id: 144,
            name: "Sri Lanka"
        },
        {
            alpha2: "se",
            alpha3: "swe",
            code: "+46",
            id: 752,
            name: "Suède"
        },
        {
            alpha2: "ch",
            alpha3: "che",
            code: "+41",
            id: 756,
            name: "Suisse"
        },
        {
            alpha2: "sr",
            alpha3: "sur",
            code: "+597",
            id: 740,
            name: "Suriname"
        },
        {
            alpha2: "sz",
            alpha3: "swz",
            code: "+268",
            id: 748,
            name: "Eswatini"
        },
        {
            alpha2: "sy",
            alpha3: "syr",
            code: "+963",
            id: 760,
            name: "Syrie"
        },
        {
            alpha2: "tj",
            alpha3: "tjk",
            code: "+992",
            id: 762,
            name: "Tadjikistan"
        },
        {
            alpha2: "tz",
            alpha3: "tza",
            code: "+255",
            id: 834,
            name: "Tanzanie"
        },
        {
            alpha2: "td",
            alpha3: "tcd",
            code: "+235",
            id: 148,
            name: "Tchad"
        },
        {
            alpha2: "cz",
            alpha3: "cze",
            code: "+420",
            id: 203,
            name: "Tchéquie"
        },
        {
            alpha2: "th",
            alpha3: "tha",
            code: "+66",
            id: 764,
            name: "Thaïlande"
        },
        {
            alpha2: "tl",
            alpha3: "tls",
            code: "+670",
            id: 626,
            name: "Timor oriental"
        },
        {
            alpha2: "tg",
            alpha3: "tgo",
            code: "+228",
            id: 768,
            name: "Togo"
        },
        {
            alpha2: "to",
            alpha3: "ton",
            code: "+676",
            id: 776,
            name: "Tonga"
        },
        {
            alpha2: "tt",
            alpha3: "tto",
            code: "+1868",
            id: 780,
            name: "Trinité-et-Tobago"
        },
        {
            alpha2: "tn",
            alpha3: "tun",
            code: "+216",
            id: 788,
            name: "Tunisie"
        },
        {
            alpha2: "tm",
            alpha3: "tkm",
            code: "+993",
            id: 795,
            name: "Turkménistan"
        },
        {
            alpha2: "tr",
            alpha3: "tur",
            code: "+90",
            id: 792,
            name: "Turquie"
        },
        {
            alpha2: "tv",
            alpha3: "tuv",
            code: "+688",
            id: 798,
            name: "Tuvalu"
        },
        {
            alpha2: "ua",
            alpha3: "ukr",
            code: "+380",
            id: 804,
            name: "Ukraine"
        },
        {
            alpha2: "uy",
            alpha3: "ury",
            code: "+598",
            id: 858,
            name: "Uruguay"
        },
        {
            alpha2: "vu",
            alpha3: "vut",
            code: "+678",
            id: 548,
            name: "Vanuatu"
        },
        {
            alpha2: "ve",
            alpha3: "ven",
            code: "+58",
            id: 862,
            name: "Venezuela"
        },
        {
            alpha2: "vn",
            alpha3: "vnm",
            code: "+84",
            id: 704,
            name: "Viêt Nam"
        },
        {
            alpha2: "ye",
            alpha3: "yem",
            code: "+967",
            id: 887,
            name: "Yémen"
        },
        {
            alpha2: "zm",
            alpha3: "zmb",
            code: "+260",
            id: 894,
            name: "Zambie"
        },
        { alpha2: "zw", alpha3: "zwe", code: "+263", id: 716, name: "Zimbabwe" }
    ],
    th: [
        {
            alpha2: "af",
            alpha3: "afg",
            code: "+93",
            id: 4,
            name: "อัฟกานิสถาน"
        },
        {
            alpha2: "al",
            alpha3: "alb",
            code: "+355",
            id: 8,
            name: "แอลเบเนีย"
        },
        {
            alpha2: "dz",
            alpha3: "dza",
            code: "+213",
            id: 12,
            name: "แอลจีเรีย"
        },
        {
            alpha2: "ad",
            alpha3: "and",
            code: "+376",
            id: 20,
            name: "อันดอร์รา"
        },
        {
            alpha2: "ao",
            alpha3: "ago",
            code: "+244",
            id: 24,
            name: "แองโกลา"
        },
        {
            alpha2: "ag",
            alpha3: "atg",
            code: "+1268",
            id: 28,
            name: "แอนทีกาและบาร์บิวดา"
        },
        {
            alpha2: "ar",
            alpha3: "arg",
            code: "+54",
            id: 32,
            name: "อาร์เจนตินา"
        },
        {
            alpha2: "am",
            alpha3: "arm",
            code: "+374",
            id: 51,
            name: "อาร์มีเนีย"
        },
        {
            alpha2: "au",
            alpha3: "aus",
            code: "+61",
            id: 36,
            name: "ออสเตรเลีย"
        },
        {
            alpha2: "at",
            alpha3: "aut",
            code: "+43",
            id: 40,
            name: "ออสเตรีย"
        },
        {
            alpha2: "az",
            alpha3: "aze",
            code: "+994",
            id: 31,
            name: "อาเซอร์ไบจาน"
        },
        {
            alpha2: "bs",
            alpha3: "bhs",
            code: "+1242",
            id: 44,
            name: "บาฮามาส"
        },
        {
            alpha2: "bh",
            alpha3: "bhr",
            code: "+973",
            id: 48,
            name: "บาห์เรน"
        },
        {
            alpha2: "bd",
            alpha3: "bgd",
            code: "+880",
            id: 50,
            name: "บังกลาเทศ"
        },
        {
            alpha2: "bb",
            alpha3: "brb",
            code: "+1246",
            id: 52,
            name: "บาร์เบโดส"
        },
        {
            alpha2: "by",
            alpha3: "blr",
            code: "+375",
            id: 112,
            name: "เบลารุส"
        },
        {
            alpha2: "be",
            alpha3: "bel",
            code: "+32",
            id: 56,
            name: "เบลเยียม"
        },
        {
            alpha2: "bz",
            alpha3: "blz",
            code: "+501",
            id: 84,
            name: "เบลีซ"
        },
        {
            alpha2: "bj",
            alpha3: "ben",
            code: "+229",
            id: 204,
            name: "เบนิน"
        },
        {
            alpha2: "bt",
            alpha3: "btn",
            code: "+975",
            id: 64,
            name: "ภูฏาน"
        },
        {
            alpha2: "bo",
            alpha3: "bol",
            code: "+591",
            id: 68,
            name: "โบลิเวีย"
        },
        {
            alpha2: "ba",
            alpha3: "bih",
            code: "+387",
            id: 70,
            name: "บอสเนียและเฮอร์เซโกวีนา"
        },
        {
            alpha2: "bw",
            alpha3: "bwa",
            code: "+267",
            id: 72,
            name: "บอตสวานา"
        },
        {
            alpha2: "br",
            alpha3: "bra",
            code: "+55",
            id: 76,
            name: "บราซิล"
        },
        {
            alpha2: "bn",
            alpha3: "brn",
            code: "+673",
            id: 96,
            name: "บรูไน"
        },
        {
            alpha2: "bg",
            alpha3: "bgr",
            code: "+359",
            id: 100,
            name: "บัลแกเรีย"
        },
        {
            alpha2: "bf",
            alpha3: "bfa",
            code: "+226",
            id: 854,
            name: "บูร์กินาฟาโซ"
        },
        {
            alpha2: "bi",
            alpha3: "bdi",
            code: "+257",
            id: 108,
            name: "บุรุนดี"
        },
        {
            alpha2: "cv",
            alpha3: "cpv",
            code: "+238",
            id: 132,
            name: "กาบูเวร์ดี"
        },
        {
            alpha2: "kh",
            alpha3: "khm",
            code: "+855",
            id: 116,
            name: "กัมพูชา"
        },
        {
            alpha2: "cm",
            alpha3: "cmr",
            code: "+237",
            id: 120,
            name: "แคเมอรูน"
        },
        {
            alpha2: "ca",
            alpha3: "can",
            code: "+1",
            id: 124,
            name: "แคนาดา"
        },
        {
            alpha2: "cf",
            alpha3: "caf",
            code: "+236",
            id: 140,
            name: "สาธารณรัฐแอฟริกากลาง"
        },
        {
            alpha2: "td",
            alpha3: "tcd",
            code: "+235",
            id: 148,
            name: "ชาด"
        },
        {
            alpha2: "cl",
            alpha3: "chl",
            code: "+56",
            id: 152,
            name: "ชิลี"
        },
        { alpha2: "cn", alpha3: "chn", code: "+86", id: 156, name: "จีน" },
        {
            alpha2: "co",
            alpha3: "col",
            code: "+57",
            id: 170,
            name: "โคลอมเบีย"
        },
        {
            alpha2: "km",
            alpha3: "com",
            code: "+269",
            id: 174,
            name: "คอโมโรส"
        },
        {
            alpha2: "cg",
            alpha3: "cog",
            code: "+242",
            id: 178,
            name: "สาธารณรัฐคองโก"
        },
        {
            alpha2: "cd",
            alpha3: "cod",
            code: "+243",
            id: 180,
            name: "สาธารณรัฐประชาธิปไตยคองโก"
        },
        {
            alpha2: "cr",
            alpha3: "cri",
            code: "+506",
            id: 188,
            name: "คอสตาริกา"
        },
        {
            alpha2: "ci",
            alpha3: "civ",
            code: "+225",
            id: 384,
            name: "โกตดิวัวร์"
        },
        {
            alpha2: "hr",
            alpha3: "hrv",
            code: "+385",
            id: 191,
            name: "โครเอเชีย"
        },
        {
            alpha2: "cu",
            alpha3: "cub",
            code: "+53",
            id: 192,
            name: "คิวบา"
        },
        {
            alpha2: "cy",
            alpha3: "cyp",
            code: "+357",
            id: 196,
            name: "ไซปรัส"
        },
        {
            alpha2: "cz",
            alpha3: "cze",
            code: "+420",
            id: 203,
            name: "เช็กเกีย"
        },
        {
            alpha2: "dk",
            alpha3: "dnk",
            code: "+45",
            id: 208,
            name: "เดนมาร์ก"
        },
        {
            alpha2: "dj",
            alpha3: "dji",
            code: "+253",
            id: 262,
            name: "จิบูตี"
        },
        {
            alpha2: "dm",
            alpha3: "dma",
            code: "+1767",
            id: 212,
            name: "ดอมินีกา"
        },
        {
            alpha2: "do",
            alpha3: "dom",
            code: "+1849",
            id: 214,
            name: "สาธารณรัฐโดมินิกัน"
        },
        {
            alpha2: "ec",
            alpha3: "ecu",
            code: "+593",
            id: 218,
            name: "เอกวาดอร์"
        },
        {
            alpha2: "eg",
            alpha3: "egy",
            code: "+20",
            id: 818,
            name: "อียิปต์"
        },
        {
            alpha2: "sv",
            alpha3: "slv",
            code: "+503",
            id: 222,
            name: "เอลซัลวาดอร์"
        },
        {
            alpha2: "gq",
            alpha3: "gnq",
            code: "+240",
            id: 226,
            name: "อิเควทอเรียลกินี"
        },
        {
            alpha2: "er",
            alpha3: "eri",
            code: "+291",
            id: 232,
            name: "เอริเทรีย"
        },
        {
            alpha2: "ee",
            alpha3: "est",
            code: "+372",
            id: 233,
            name: "เอสโตเนีย"
        },
        {
            alpha2: "sz",
            alpha3: "swz",
            code: "+268",
            id: 748,
            name: "เอสวาตีนี"
        },
        {
            alpha2: "et",
            alpha3: "eth",
            code: "+251",
            id: 231,
            name: "เอธิโอเปีย"
        },
        {
            alpha2: "fj",
            alpha3: "fji",
            code: "+679",
            id: 242,
            name: "ฟีจี"
        },
        {
            alpha2: "fi",
            alpha3: "fin",
            code: "+358",
            id: 246,
            name: "ฟินแลนด์"
        },
        {
            alpha2: "fr",
            alpha3: "fra",
            code: "+33",
            id: 250,
            name: "ฝรั่งเศส"
        },
        {
            alpha2: "ga",
            alpha3: "gab",
            code: "+241",
            id: 266,
            name: "กาบอง"
        },
        {
            alpha2: "gm",
            alpha3: "gmb",
            code: "+220",
            id: 270,
            name: "แกมเบีย"
        },
        {
            alpha2: "ge",
            alpha3: "geo",
            code: "+995",
            id: 268,
            name: "จอร์เจีย"
        },
        {
            alpha2: "de",
            alpha3: "deu",
            code: "+49",
            id: 276,
            name: "เยอรมนี"
        },
        {
            alpha2: "gh",
            alpha3: "gha",
            code: "+233",
            id: 288,
            name: "กานา"
        },
        {
            alpha2: "gr",
            alpha3: "grc",
            code: "+30",
            id: 300,
            name: "กรีซ"
        },
        {
            alpha2: "gd",
            alpha3: "grd",
            code: "+1473",
            id: 308,
            name: "กรีเนดา"
        },
        {
            alpha2: "gt",
            alpha3: "gtm",
            code: "+502",
            id: 320,
            name: "กัวเตมาลา"
        },
        {
            alpha2: "gn",
            alpha3: "gin",
            code: "+224",
            id: 324,
            name: "กินี"
        },
        {
            alpha2: "gw",
            alpha3: "gnb",
            code: "+245",
            id: 624,
            name: "กินี-บิสเซา"
        },
        {
            alpha2: "gy",
            alpha3: "guy",
            code: "+595",
            id: 328,
            name: "กายอานา"
        },
        {
            alpha2: "ht",
            alpha3: "hti",
            code: "+509",
            id: 332,
            name: "เฮติ"
        },
        {
            alpha2: "hn",
            alpha3: "hnd",
            code: "+504",
            id: 340,
            name: "ฮอนดูรัส"
        },
        {
            alpha2: "hu",
            alpha3: "hun",
            code: "+36",
            id: 348,
            name: "ฮังการี"
        },
        {
            alpha2: "is",
            alpha3: "isl",
            code: "+354",
            id: 352,
            name: "ไอซ์แลนด์"
        },
        {
            alpha2: "in",
            alpha3: "ind",
            code: "+91",
            id: 356,
            name: "อินเดีย"
        },
        {
            alpha2: "id",
            alpha3: "idn",
            code: "+62",
            id: 360,
            name: "อินโดนีเซีย"
        },
        {
            alpha2: "ir",
            alpha3: "irn",
            code: "+98",
            id: 364,
            name: "อิหร่าน"
        },
        {
            alpha2: "iq",
            alpha3: "irq",
            code: "+964",
            id: 368,
            name: "อิรัก"
        },
        {
            alpha2: "ie",
            alpha3: "irl",
            code: "+353",
            id: 372,
            name: "ไอร์แลนด์"
        },
        {
            alpha2: "il",
            alpha3: "isr",
            code: "+972",
            id: 376,
            name: "อิสราเอล"
        },
        {
            alpha2: "it",
            alpha3: "ita",
            code: "+39",
            id: 380,
            name: "อิตาลี"
        },
        {
            alpha2: "jm",
            alpha3: "jam",
            code: "+1876",
            id: 388,
            name: "จาเมกา"
        },
        {
            alpha2: "jp",
            alpha3: "jpn",
            code: "+81",
            id: 392,
            name: "ญี่ปุ่น"
        },
        {
            alpha2: "jo",
            alpha3: "jor",
            code: "+962",
            id: 400,
            name: "จอร์แดน"
        },
        {
            alpha2: "kz",
            alpha3: "kaz",
            code: "+77",
            id: 398,
            name: "คาซัคสถาน"
        },
        {
            alpha2: "ke",
            alpha3: "ken",
            code: "+254",
            id: 404,
            name: "เคนยา"
        },
        {
            alpha2: "ki",
            alpha3: "kir",
            code: "+686",
            id: 296,
            name: "คิริบาส"
        },
        {
            alpha2: "kp",
            alpha3: "prk",
            code: "+850",
            id: 408,
            name: "เกาหลีเหนือ"
        },
        {
            alpha2: "kr",
            alpha3: "kor",
            code: "+82",
            id: 410,
            name: "เกาหลีใต้"
        },
        {
            alpha2: "kw",
            alpha3: "kwt",
            code: "+965",
            id: 414,
            name: "คูเวต"
        },
        {
            alpha2: "kg",
            alpha3: "kgz",
            code: "+996",
            id: 417,
            name: "คีร์กีซสถาน"
        },
        {
            alpha2: "la",
            alpha3: "lao",
            code: "+856",
            id: 418,
            name: "ลาว"
        },
        {
            alpha2: "lv",
            alpha3: "lva",
            code: "+371",
            id: 428,
            name: "ลัตเวีย"
        },
        {
            alpha2: "lb",
            alpha3: "lbn",
            code: "+961",
            id: 422,
            name: "เลบานอน"
        },
        {
            alpha2: "ls",
            alpha3: "lso",
            code: "+266",
            id: 426,
            name: "เลโซโท"
        },
        {
            alpha2: "lr",
            alpha3: "lbr",
            code: "+231",
            id: 430,
            name: "ไลบีเรีย"
        },
        {
            alpha2: "ly",
            alpha3: "lby",
            code: "+218",
            id: 434,
            name: "ลิเบีย"
        },
        {
            alpha2: "li",
            alpha3: "lie",
            code: "+423",
            id: 438,
            name: "ลีชเทินชไตน์"
        },
        {
            alpha2: "lt",
            alpha3: "ltu",
            code: "+370",
            id: 440,
            name: "ลิทัวเนีย"
        },
        {
            alpha2: "lu",
            alpha3: "lux",
            code: "+352",
            id: 442,
            name: "ลักเซมเบิร์ก"
        },
        {
            alpha2: "mg",
            alpha3: "mdg",
            code: "+261",
            id: 450,
            name: "มาดากัสการ์"
        },
        {
            alpha2: "mw",
            alpha3: "mwi",
            code: "+265",
            id: 454,
            name: "มาลาวี"
        },
        {
            alpha2: "my",
            alpha3: "mys",
            code: "+60",
            id: 458,
            name: "มาเลเซีย"
        },
        {
            alpha2: "mv",
            alpha3: "mdv",
            code: "+960",
            id: 462,
            name: "มัลดีฟส์"
        },
        {
            alpha2: "ml",
            alpha3: "mli",
            code: "+223",
            id: 466,
            name: "มาลี"
        },
        {
            alpha2: "mt",
            alpha3: "mlt",
            code: "+356",
            id: 470,
            name: "มอลตา"
        },
        {
            alpha2: "mh",
            alpha3: "mhl",
            code: "+692",
            id: 584,
            name: "หมู่เกาะมาร์แชลล์"
        },
        {
            alpha2: "mr",
            alpha3: "mrt",
            code: "+222",
            id: 478,
            name: "มอริเตเนีย"
        },
        {
            alpha2: "mu",
            alpha3: "mus",
            code: "+230",
            id: 480,
            name: "มอริเชียส"
        },
        {
            alpha2: "mx",
            alpha3: "mex",
            code: "+52",
            id: 484,
            name: "เม็กซิโก"
        },
        {
            alpha2: "fm",
            alpha3: "fsm",
            code: "+691",
            id: 583,
            name: "ไมโครนีเชีย"
        },
        {
            alpha2: "md",
            alpha3: "mda",
            code: "+373",
            id: 498,
            name: "มอลโดวา"
        },
        {
            alpha2: "mc",
            alpha3: "mco",
            code: "+377",
            id: 492,
            name: "โมนาโก"
        },
        {
            alpha2: "mn",
            alpha3: "mng",
            code: "+976",
            id: 496,
            name: "มองโกเลีย"
        },
        {
            alpha2: "me",
            alpha3: "mne",
            code: "+382",
            id: 499,
            name: "มอนเตเนโกร"
        },
        {
            alpha2: "ma",
            alpha3: "mar",
            code: "+212",
            id: 504,
            name: "โมร็อกโก"
        },
        {
            alpha2: "mz",
            alpha3: "moz",
            code: "+258",
            id: 508,
            name: "โมซัมบิก"
        },
        {
            alpha2: "mm",
            alpha3: "mmr",
            code: "+95",
            id: 104,
            name: "พม่า"
        },
        {
            alpha2: "na",
            alpha3: "nam",
            code: "+264",
            id: 516,
            name: "นามิเบีย"
        },
        {
            alpha2: "nr",
            alpha3: "nru",
            code: "+674",
            id: 520,
            name: "นาอูรู"
        },
        {
            alpha2: "np",
            alpha3: "npl",
            code: "+977",
            id: 524,
            name: "เนปาล"
        },
        {
            alpha2: "nl",
            alpha3: "nld",
            code: "+31",
            id: 528,
            name: "เนเธอร์แลนด์"
        },
        {
            alpha2: "nz",
            alpha3: "nzl",
            code: "+64",
            id: 554,
            name: "นิวซีแลนด์"
        },
        {
            alpha2: "ni",
            alpha3: "nic",
            code: "+505",
            id: 558,
            name: "นิการากัว"
        },
        {
            alpha2: "ne",
            alpha3: "ner",
            code: "+227",
            id: 562,
            name: "ไนเจอร์"
        },
        {
            alpha2: "ng",
            alpha3: "nga",
            code: "+234",
            id: 566,
            name: "ไนจีเรีย"
        },
        {
            alpha2: "mk",
            alpha3: "mkd",
            code: "+389",
            id: 807,
            name: "มาซิโดเนียเหนือ"
        },
        {
            alpha2: "no",
            alpha3: "nor",
            code: "+47",
            id: 578,
            name: "นอร์เวย์"
        },
        {
            alpha2: "om",
            alpha3: "omn",
            code: "+968",
            id: 512,
            name: "โอมาน"
        },
        {
            alpha2: "pk",
            alpha3: "pak",
            code: "+92",
            id: 586,
            name: "ปากีสถาน"
        },
        {
            alpha2: "pw",
            alpha3: "plw",
            code: "+680",
            id: 585,
            name: "ปาเลา"
        },
        {
            alpha2: "pa",
            alpha3: "pan",
            code: "+507",
            id: 591,
            name: "ปานามา"
        },
        {
            alpha2: "pg",
            alpha3: "png",
            code: "+675",
            id: 598,
            name: "ปาปัวนิวกินี"
        },
        {
            alpha2: "py",
            alpha3: "pry",
            code: "+595",
            id: 600,
            name: "ปารากวัย"
        },
        {
            alpha2: "pe",
            alpha3: "per",
            code: "+51",
            id: 604,
            name: "เปรู"
        },
        {
            alpha2: "ph",
            alpha3: "phl",
            code: "+63",
            id: 608,
            name: "ฟิลิปปินส์"
        },
        {
            alpha2: "pl",
            alpha3: "pol",
            code: "+48",
            id: 616,
            name: "โปแลนด์"
        },
        {
            alpha2: "pt",
            alpha3: "prt",
            code: "+351",
            id: 620,
            name: "โปรตุเกส"
        },
        {
            alpha2: "qa",
            alpha3: "qat",
            code: "+974",
            id: 634,
            name: "กาตาร์"
        },
        {
            alpha2: "ro",
            alpha3: "rou",
            code: "+40",
            id: 642,
            name: "โรมาเนีย"
        },
        {
            alpha2: "ru",
            alpha3: "rus",
            code: "+7",
            id: 643,
            name: "รัสเซีย"
        },
        {
            alpha2: "rw",
            alpha3: "rwa",
            code: "+250",
            id: 646,
            name: "รวันดา"
        },
        {
            alpha2: "kn",
            alpha3: "kna",
            code: "+1869",
            id: 659,
            name: "เซนต์คิตส์และเนวิส"
        },
        {
            alpha2: "lc",
            alpha3: "lca",
            code: "+1758",
            id: 662,
            name: "เซนต์ลูเชีย"
        },
        {
            alpha2: "vc",
            alpha3: "vct",
            code: "+1784",
            id: 670,
            name: "เซนต์วินเซนต์และเกรนาดีนส์"
        },
        {
            alpha2: "ws",
            alpha3: "wsm",
            code: "+685",
            id: 882,
            name: "ซามัว"
        },
        {
            alpha2: "sm",
            alpha3: "smr",
            code: "+378",
            id: 674,
            name: "ซานมารีโน"
        },
        {
            alpha2: "st",
            alpha3: "stp",
            code: "+239",
            id: 678,
            name: "เซาตูแมอีปริงซีป"
        },
        {
            alpha2: "sa",
            alpha3: "sau",
            code: "+966",
            id: 682,
            name: "ซาอุดีอาระเบีย"
        },
        {
            alpha2: "sn",
            alpha3: "sen",
            code: "+221",
            id: 686,
            name: "เซเนกัล"
        },
        {
            alpha2: "rs",
            alpha3: "srb",
            code: "+381",
            id: 688,
            name: "เซอร์เบีย"
        },
        {
            alpha2: "sc",
            alpha3: "syc",
            code: "+248",
            id: 690,
            name: "เซเชลส์"
        },
        {
            alpha2: "sl",
            alpha3: "sle",
            code: "+232",
            id: 694,
            name: "เซียร์ราลีโอน"
        },
        {
            alpha2: "sg",
            alpha3: "sgp",
            code: "+65",
            id: 702,
            name: "สิงคโปร์"
        },
        {
            alpha2: "sk",
            alpha3: "svk",
            code: "+421",
            id: 703,
            name: "สโลวาเกีย"
        },
        {
            alpha2: "si",
            alpha3: "svn",
            code: "+386",
            id: 705,
            name: "สโลวีเนีย"
        },
        {
            alpha2: "sb",
            alpha3: "slb",
            code: "+677",
            id: 90,
            name: "หมู่เกาะโซโลมอน"
        },
        {
            alpha2: "so",
            alpha3: "som",
            code: "+252",
            id: 706,
            name: "โซมาเลีย"
        },
        {
            alpha2: "za",
            alpha3: "zaf",
            code: "+27",
            id: 710,
            name: "แอฟริกาใต้"
        },
        {
            alpha2: "ss",
            alpha3: "ssd",
            code: "+211",
            id: 728,
            name: "ซูดานใต้"
        },
        {
            alpha2: "es",
            alpha3: "esp",
            code: "+34",
            id: 724,
            name: "สเปน"
        },
        {
            alpha2: "lk",
            alpha3: "lka",
            code: "+94",
            id: 144,
            name: "ศรีลังกา"
        },
        {
            alpha2: "sd",
            alpha3: "sdn",
            code: "+249",
            id: 729,
            name: "ซูดาน"
        },
        {
            alpha2: "sr",
            alpha3: "sur",
            code: "+597",
            id: 740,
            name: "ซูรินาม"
        },
        {
            alpha2: "se",
            alpha3: "swe",
            code: "+46",
            id: 752,
            name: "สวีเดน"
        },
        {
            alpha2: "ch",
            alpha3: "che",
            code: "+41",
            id: 756,
            name: "สวิตเซอร์แลนด์"
        },
        {
            alpha2: "sy",
            alpha3: "syr",
            code: "+963",
            id: 760,
            name: "ซีเรีย"
        },
        {
            alpha2: "tj",
            alpha3: "tjk",
            code: "+992",
            id: 762,
            name: "ทาจิกิสถาน"
        },
        {
            alpha2: "tz",
            alpha3: "tza",
            code: "+255",
            id: 834,
            name: "แทนซาเนีย"
        },
        { alpha2: "th", alpha3: "tha", code: "+66", id: 764, name: "ไทย" },
        {
            alpha2: "tl",
            alpha3: "tls",
            code: "+670",
            id: 626,
            name: "ติมอร์-เลสเต"
        },
        {
            alpha2: "tg",
            alpha3: "tgo",
            code: "+228",
            id: 768,
            name: "โตโก"
        },
        {
            alpha2: "to",
            alpha3: "ton",
            code: "+676",
            id: 776,
            name: "ตองงา"
        },
        {
            alpha2: "tt",
            alpha3: "tto",
            code: "+1868",
            id: 780,
            name: "ตรินิแดดและโตเบโก"
        },
        {
            alpha2: "tn",
            alpha3: "tun",
            code: "+216",
            id: 788,
            name: "ตูนิเซีย"
        },
        {
            alpha2: "tr",
            alpha3: "tur",
            code: "+90",
            id: 792,
            name: "ตุรกี"
        },
        {
            alpha2: "tm",
            alpha3: "tkm",
            code: "+993",
            id: 795,
            name: "เติร์กเมนิสถาน"
        },
        {
            alpha2: "tv",
            alpha3: "tuv",
            code: "+688",
            id: 798,
            name: "ตูวาลู"
        },
        {
            alpha2: "ug",
            alpha3: "uga",
            code: "+256",
            id: 800,
            name: "ยูกันดา"
        },
        {
            alpha2: "ua",
            alpha3: "ukr",
            code: "+380",
            id: 804,
            name: "ยูเครน"
        },
        {
            alpha2: "ae",
            alpha3: "are",
            code: "+971",
            id: 784,
            name: "สหรัฐอาหรับเอมิเรตส์"
        },
        {
            alpha2: "gb",
            alpha3: "gbr",
            code: "+44",
            id: 826,
            name: "สหราชอาณาจักร"
        },
        {
            alpha2: "us",
            alpha3: "usa",
            code: "+1",
            id: 840,
            name: "สหรัฐ"
        },
        {
            alpha2: "uy",
            alpha3: "ury",
            code: "+598",
            id: 858,
            name: "อุรุกวัย"
        },
        {
            alpha2: "uz",
            alpha3: "uzb",
            code: "+998",
            id: 860,
            name: "อุซเบกิสถาน"
        },
        {
            alpha2: "vu",
            alpha3: "vut",
            code: "+678",
            id: 548,
            name: "วานูวาตู"
        },
        {
            alpha2: "ve",
            alpha3: "ven",
            code: "+58",
            id: 862,
            name: "เวเนซุเอลา"
        },
        {
            alpha2: "vn",
            alpha3: "vnm",
            code: "+84",
            id: 704,
            name: "เวียดนาม"
        },
        {
            alpha2: "ye",
            alpha3: "yem",
            code: "+967",
            id: 887,
            name: "เยเมน"
        },
        {
            alpha2: "zm",
            alpha3: "zmb",
            code: "+260",
            id: 894,
            name: "แซมเบีย"
        },
        { alpha2: "zw", alpha3: "zwe", code: "+263", id: 716, name: "ซิมบับเว" }
    ],
    cn: [
        { id: 4, alpha2: "af", alpha3: "afg", name: "阿富汗", code: "+93" },
        {
            id: 8,
            alpha2: "al",
            alpha3: "alb",
            name: "阿爾巴尼亞",
            code: "+355"
        },
        {
            id: 12,
            alpha2: "dz",
            alpha3: "dza",
            name: "阿爾及利亞",
            code: "+213"
        },
        { id: 20, alpha2: "ad", alpha3: "and", name: "安道爾", code: "+376" },
        {
            id: 24,
            alpha2: "ao",
            alpha3: "ago",
            name: "安哥拉",
            code: "+244"
        },
        {
            id: 28,
            alpha2: "ag",
            alpha3: "atg",
            name: "安地卡及巴布達",
            code: "+1268"
        },
        { id: 32, alpha2: "ar", alpha3: "arg", name: "阿根廷", code: "+54" },
        {
            id: 51,
            alpha2: "am",
            alpha3: "arm",
            name: "亞美尼亞",
            code: "+374"
        },
        { id: 36, alpha2: "au", alpha3: "aus", name: "澳洲", code: "+61" },
        {
            id: 40,
            alpha2: "at",
            alpha3: "aut",
            name: "奧地利",
            code: "+43"
        },
        { id: 31, alpha2: "az", alpha3: "aze", name: "亞塞拜然", code: "+994" },
        {
            id: 44,
            alpha2: "bs",
            alpha3: "bhs",
            name: "巴哈馬",
            code: "+1242"
        },
        { id: 48, alpha2: "bh", alpha3: "bhr", name: "巴林", code: "+973" },
        {
            id: 50,
            alpha2: "bd",
            alpha3: "bgd",
            name: "孟加拉",
            code: "+880"
        },
        { id: 52, alpha2: "bb", alpha3: "brb", name: "巴貝多", code: "+1246" },
        {
            id: 112,
            alpha2: "by",
            alpha3: "blr",
            name: "白俄羅斯",
            code: "+375"
        },
        { id: 56, alpha2: "be", alpha3: "bel", name: "比利時", code: "+32" },
        {
            id: 84,
            alpha2: "bz",
            alpha3: "blz",
            name: "貝里斯",
            code: "+501"
        },
        { id: 204, alpha2: "bj", alpha3: "ben", name: "貝南", code: "+229" },
        {
            id: 64,
            alpha2: "bt",
            alpha3: "btn",
            name: "不丹",
            code: "+975"
        },
        { id: 68, alpha2: "bo", alpha3: "bol", name: "玻利維亞", code: "+591" },
        {
            id: 70,
            alpha2: "ba",
            alpha3: "bih",
            name: "波士尼亞與赫塞哥維納",
            code: "+387"
        },
        { id: 72, alpha2: "bw", alpha3: "bwa", name: "波札那", code: "+267" },
        {
            id: 76,
            alpha2: "br",
            alpha3: "bra",
            name: "巴西",
            code: "+55"
        },
        { id: 96, alpha2: "bn", alpha3: "brn", name: "汶萊", code: "+673" },
        {
            id: 100,
            alpha2: "bg",
            alpha3: "bgr",
            name: "保加利亞",
            code: "+359"
        },
        {
            id: 854,
            alpha2: "bf",
            alpha3: "bfa",
            name: "布吉納法索",
            code: "+226"
        },
        { id: 108, alpha2: "bi", alpha3: "bdi", name: "蒲隆地", code: "+257" },
        {
            id: 132,
            alpha2: "cv",
            alpha3: "cpv",
            name: "維德角",
            code: "+238"
        },
        { id: 116, alpha2: "kh", alpha3: "khm", name: "柬埔寨", code: "+855" },
        {
            id: 120,
            alpha2: "cm",
            alpha3: "cmr",
            name: "喀麥隆",
            code: "+237"
        },
        { id: 124, alpha2: "ca", alpha3: "can", name: "加拿大", code: "+1" },
        {
            id: 140,
            alpha2: "cf",
            alpha3: "caf",
            name: "中非",
            code: "+236"
        },
        { id: 148, alpha2: "td", alpha3: "tcd", name: "查德", code: "+235" },
        {
            id: 152,
            alpha2: "cl",
            alpha3: "chl",
            name: "智利",
            code: "+56"
        },
        { id: 156, alpha2: "cn", alpha3: "chn", name: "中國", code: "+86" },
        {
            id: 170,
            alpha2: "co",
            alpha3: "col",
            name: "哥倫比亞",
            code: "+57"
        },
        { id: 174, alpha2: "km", alpha3: "com", name: "葛摩", code: "+269" },
        {
            id: 178,
            alpha2: "cg",
            alpha3: "cog",
            name: "剛果共和國",
            code: "+242"
        },
        { id: 180, alpha2: "cd", alpha3: "cod", name: "剛果民主共和國", code: "+243" },
        {
            id: 188,
            alpha2: "cr",
            alpha3: "cri",
            name: "哥斯大黎加",
            code: "+506"
        },
        { id: 384, alpha2: "ci", alpha3: "civ", name: "象牙海岸", code: "+225" },
        {
            id: 191,
            alpha2: "hr",
            alpha3: "hrv",
            name: "克羅埃西亞",
            code: "+385"
        },
        { id: 192, alpha2: "cu", alpha3: "cub", name: "古巴", code: "+53" },
        {
            id: 196,
            alpha2: "cy",
            alpha3: "cyp",
            name: "賽普勒斯",
            code: "+357"
        },
        { id: 203, alpha2: "cz", alpha3: "cze", name: "捷克", code: "+420" },
        {
            id: 208,
            alpha2: "dk",
            alpha3: "dnk",
            name: "丹麥",
            code: "+45"
        },
        { id: 262, alpha2: "dj", alpha3: "dji", name: "吉布地", code: "+253" },
        {
            id: 212,
            alpha2: "dm",
            alpha3: "dma",
            name: "多米尼克",
            code: "+1767"
        },
        {
            id: 214,
            alpha2: "do",
            alpha3: "dom",
            name: "多明尼加",
            code: "+1849"
        },
        { id: 218, alpha2: "ec", alpha3: "ecu", name: "厄瓜多", code: "+593" },
        {
            id: 818,
            alpha2: "eg",
            alpha3: "egy",
            name: "埃及",
            code: "+20"
        },
        {
            id: 222,
            alpha2: "sv",
            alpha3: "slv",
            name: "薩爾瓦多",
            code: "+503"
        },
        {
            id: 226,
            alpha2: "gq",
            alpha3: "gnq",
            name: "赤道幾內亞",
            code: "+240"
        },
        {
            id: 232,
            alpha2: "er",
            alpha3: "eri",
            name: "厄利垂亞",
            code: "+291"
        },
        {
            id: 233,
            alpha2: "ee",
            alpha3: "est",
            name: "愛沙尼亞",
            code: "+372"
        },
        { id: 748, alpha2: "sz", alpha3: "swz", name: "史瓦帝尼", code: "+268" },
        {
            id: 231,
            alpha2: "et",
            alpha3: "eth",
            name: "衣索比亞",
            code: "+251"
        },
        { id: 242, alpha2: "fj", alpha3: "fji", name: "斐濟", code: "+679" },
        {
            id: 246,
            alpha2: "fi",
            alpha3: "fin",
            name: "芬蘭",
            code: "+358"
        },
        { id: 250, alpha2: "fr", alpha3: "fra", name: "法國", code: "+33" },
        {
            id: 266,
            alpha2: "ga",
            alpha3: "gab",
            name: "加彭",
            code: "+241"
        },
        { id: 270, alpha2: "gm", alpha3: "gmb", name: "甘比亞", code: "+220" },
        {
            id: 268,
            alpha2: "ge",
            alpha3: "geo",
            name: "喬治亞",
            code: "+995"
        },
        { id: 276, alpha2: "de", alpha3: "deu", name: "德國", code: "+49" },
        {
            id: 288,
            alpha2: "gh",
            alpha3: "gha",
            name: "迦納",
            code: "+233"
        },
        { id: 300, alpha2: "gr", alpha3: "grc", name: "希臘", code: "+30" },
        {
            id: 308,
            alpha2: "gd",
            alpha3: "grd",
            name: "格瑞那達",
            code: "+1473"
        },
        {
            id: 320,
            alpha2: "gt",
            alpha3: "gtm",
            name: "瓜地馬拉",
            code: "+502"
        },
        { id: 324, alpha2: "gn", alpha3: "gin", name: "幾內亞", code: "+224" },
        {
            id: 624,
            alpha2: "gw",
            alpha3: "gnb",
            name: "幾內亞比索",
            code: "+245"
        },
        { id: 328, alpha2: "gy", alpha3: "guy", name: "蓋亞那", code: "+595" },
        {
            id: 332,
            alpha2: "ht",
            alpha3: "hti",
            name: "海地",
            code: "+509"
        },
        {
            id: 340,
            alpha2: "hn",
            alpha3: "hnd",
            name: "宏都拉斯",
            code: "+504"
        },
        { id: 348, alpha2: "hu", alpha3: "hun", name: "匈牙利", code: "+36" },
        {
            id: 352,
            alpha2: "is",
            alpha3: "isl",
            name: "冰島",
            code: "+354"
        },
        { id: 356, alpha2: "in", alpha3: "ind", name: "印度", code: "+91" },
        {
            id: 360,
            alpha2: "id",
            alpha3: "idn",
            name: "印度尼西亞",
            code: "+62"
        },
        { id: 364, alpha2: "ir", alpha3: "irn", name: "伊朗", code: "+98" },
        {
            id: 368,
            alpha2: "iq",
            alpha3: "irq",
            name: "伊拉克",
            code: "+964"
        },
        { id: 372, alpha2: "ie", alpha3: "irl", name: "愛爾蘭", code: "+353" },
        {
            id: 376,
            alpha2: "il",
            alpha3: "isr",
            name: "以色列",
            code: "+972"
        },
        { id: 380, alpha2: "it", alpha3: "ita", name: "義大利", code: "+39" },
        {
            id: 388,
            alpha2: "jm",
            alpha3: "jam",
            name: "牙買加",
            code: "+1876"
        },
        { id: 392, alpha2: "jp", alpha3: "jpn", name: "日本", code: "+81" },
        {
            id: 400,
            alpha2: "jo",
            alpha3: "jor",
            name: "約旦",
            code: "+962"
        },
        { id: 398, alpha2: "kz", alpha3: "kaz", name: "哈薩克", code: "+77" },
        {
            id: 404,
            alpha2: "ke",
            alpha3: "ken",
            name: "肯亞",
            code: "+254"
        },
        {
            id: 296,
            alpha2: "ki",
            alpha3: "kir",
            name: "吉里巴斯",
            code: "+686"
        },
        { id: 408, alpha2: "kp", alpha3: "prk", name: "北韓", code: "+850" },
        {
            id: 410,
            alpha2: "kr",
            alpha3: "kor",
            name: "南韓",
            code: "+82"
        },
        { id: 414, alpha2: "kw", alpha3: "kwt", name: "科威特", code: "+965" },
        {
            id: 417,
            alpha2: "kg",
            alpha3: "kgz",
            name: "吉爾吉斯",
            code: "+996"
        },
        { id: 418, alpha2: "la", alpha3: "lao", name: "寮國", code: "+856" },
        {
            id: 428,
            alpha2: "lv",
            alpha3: "lva",
            name: "拉脫維亞",
            code: "+371"
        },
        { id: 422, alpha2: "lb", alpha3: "lbn", name: "黎巴嫩", code: "+961" },
        {
            id: 426,
            alpha2: "ls",
            alpha3: "lso",
            name: "賴索托",
            code: "+266"
        },
        {
            id: 430,
            alpha2: "lr",
            alpha3: "lbr",
            name: "賴比瑞亞",
            code: "+231"
        },
        { id: 434, alpha2: "ly", alpha3: "lby", name: "利比亞", code: "+218" },
        {
            id: 438,
            alpha2: "li",
            alpha3: "lie",
            name: "列支敦斯登",
            code: "+423"
        },
        { id: 440, alpha2: "lt", alpha3: "ltu", name: "立陶宛", code: "+370" },
        {
            id: 442,
            alpha2: "lu",
            alpha3: "lux",
            name: "盧森堡",
            code: "+352"
        },
        {
            id: 450,
            alpha2: "mg",
            alpha3: "mdg",
            name: "馬達加斯加",
            code: "+261"
        },
        { id: 454, alpha2: "mw", alpha3: "mwi", name: "馬拉威", code: "+265" },
        {
            id: 458,
            alpha2: "my",
            alpha3: "mys",
            name: "馬來西亞",
            code: "+60"
        },
        {
            id: 462,
            alpha2: "mv",
            alpha3: "mdv",
            name: "馬爾地夫",
            code: "+960"
        },
        { id: 466, alpha2: "ml", alpha3: "mli", name: "馬利", code: "+223" },
        {
            id: 470,
            alpha2: "mt",
            alpha3: "mlt",
            name: "馬爾他",
            code: "+356"
        },
        {
            id: 584,
            alpha2: "mh",
            alpha3: "mhl",
            name: "馬紹爾群島",
            code: "+692"
        },
        {
            id: 478,
            alpha2: "mr",
            alpha3: "mrt",
            name: "茅利塔尼亞",
            code: "+222"
        },
        {
            id: 480,
            alpha2: "mu",
            alpha3: "mus",
            name: "模里西斯",
            code: "+230"
        },
        { id: 484, alpha2: "mx", alpha3: "mex", name: "墨西哥", code: "+52" },
        {
            id: 583,
            alpha2: "fm",
            alpha3: "fsm",
            name: "密克羅尼西亞聯邦",
            code: "+691"
        },
        { id: 498, alpha2: "md", alpha3: "mda", name: "摩爾多瓦", code: "+373" },
        {
            id: 492,
            alpha2: "mc",
            alpha3: "mco",
            name: "摩納哥",
            code: "+377"
        },
        { id: 496, alpha2: "mn", alpha3: "mng", name: "蒙古", code: "+976" },
        {
            id: 499,
            alpha2: "me",
            alpha3: "mne",
            name: "蒙特內哥羅",
            code: "+382"
        },
        { id: 504, alpha2: "ma", alpha3: "mar", name: "摩洛哥", code: "+212" },
        {
            id: 508,
            alpha2: "mz",
            alpha3: "moz",
            name: "莫三比克",
            code: "+258"
        },
        { id: 104, alpha2: "mm", alpha3: "mmr", name: "緬甸", code: "+95" },
        {
            id: 516,
            alpha2: "na",
            alpha3: "nam",
            name: "納米比亞",
            code: "+264"
        },
        { id: 520, alpha2: "nr", alpha3: "nru", name: "諾魯", code: "+674" },
        {
            id: 524,
            alpha2: "np",
            alpha3: "npl",
            name: "尼泊爾",
            code: "+977"
        },
        { id: 528, alpha2: "nl", alpha3: "nld", name: "荷蘭", code: "+31" },
        {
            id: 554,
            alpha2: "nz",
            alpha3: "nzl",
            name: "紐西蘭",
            code: "+64"
        },
        {
            id: 558,
            alpha2: "ni",
            alpha3: "nic",
            name: "尼加拉瓜",
            code: "+505"
        },
        { id: 562, alpha2: "ne", alpha3: "ner", name: "尼日", code: "+227" },
        {
            id: 566,
            alpha2: "ng",
            alpha3: "nga",
            name: "奈及利亞",
            code: "+234"
        },
        { id: 807, alpha2: "mk", alpha3: "mkd", name: "北馬其頓", code: "+389" },
        {
            id: 578,
            alpha2: "no",
            alpha3: "nor",
            name: "挪威",
            code: "+47"
        },
        { id: 512, alpha2: "om", alpha3: "omn", name: "阿曼", code: "+968" },
        {
            id: 586,
            alpha2: "pk",
            alpha3: "pak",
            name: "巴基斯坦",
            code: "+92"
        },
        { id: 585, alpha2: "pw", alpha3: "plw", name: "帛琉", code: "+680" },
        {
            id: 591,
            alpha2: "pa",
            alpha3: "pan",
            name: "巴拿馬",
            code: "+507"
        },
        {
            id: 598,
            alpha2: "pg",
            alpha3: "png",
            name: "巴布亞紐幾內亞",
            code: "+675"
        },
        { id: 600, alpha2: "py", alpha3: "pry", name: "巴拉圭", code: "+595" },
        {
            id: 604,
            alpha2: "pe",
            alpha3: "per",
            name: "秘魯",
            code: "+51"
        },
        { id: 608, alpha2: "ph", alpha3: "phl", name: "菲律賓", code: "+63" },
        {
            id: 616,
            alpha2: "pl",
            alpha3: "pol",
            name: "波蘭",
            code: "+48"
        },
        { id: 620, alpha2: "pt", alpha3: "prt", name: "葡萄牙", code: "+351" },
        {
            id: 634,
            alpha2: "qa",
            alpha3: "qat",
            name: "卡達",
            code: "+974"
        },
        { id: 642, alpha2: "ro", alpha3: "rou", name: "羅馬尼亞", code: "+40" },
        {
            id: 643,
            alpha2: "ru",
            alpha3: "rus",
            name: "俄羅斯",
            code: "+7"
        },
        { id: 646, alpha2: "rw", alpha3: "rwa", name: "盧安達", code: "+250" },
        {
            id: 659,
            alpha2: "kn",
            alpha3: "kna",
            name: "聖克里斯多福及尼維斯",
            code: "+1869"
        },
        {
            id: 662,
            alpha2: "lc",
            alpha3: "lca",
            name: "聖露西亞",
            code: "+1758"
        },
        {
            id: 670,
            alpha2: "vc",
            alpha3: "vct",
            name: "聖文森及格瑞那丁",
            code: "+1784"
        },
        { id: 882, alpha2: "ws", alpha3: "wsm", name: "薩摩亞", code: "+685" },
        {
            id: 674,
            alpha2: "sm",
            alpha3: "smr",
            name: "聖馬利諾",
            code: "+378"
        },
        {
            id: 678,
            alpha2: "st",
            alpha3: "stp",
            name: "聖多美普林西比",
            code: "+239"
        },
        {
            id: 682,
            alpha2: "sa",
            alpha3: "sau",
            name: "沙烏地阿拉伯",
            code: "+966"
        },
        {
            id: 686,
            alpha2: "sn",
            alpha3: "sen",
            name: "塞內加爾",
            code: "+221"
        },
        {
            id: 688,
            alpha2: "rs",
            alpha3: "srb",
            name: "塞爾維亞",
            code: "+381"
        },
        { id: 690, alpha2: "sc", alpha3: "syc", name: "塞席爾", code: "+248" },
        {
            id: 694,
            alpha2: "sl",
            alpha3: "sle",
            name: "獅子山",
            code: "+232"
        },
        { id: 702, alpha2: "sg", alpha3: "sgp", name: "新加坡", code: "+65" },
        {
            id: 703,
            alpha2: "sk",
            alpha3: "svk",
            name: "斯洛伐克",
            code: "+421"
        },
        {
            id: 705,
            alpha2: "si",
            alpha3: "svn",
            name: "斯洛維尼亞",
            code: "+386"
        },
        {
            id: 90,
            alpha2: "sb",
            alpha3: "slb",
            name: "索羅門群島",
            code: "+677"
        },
        {
            id: 706,
            alpha2: "so",
            alpha3: "som",
            name: "索馬利亞",
            code: "+252"
        },
        { id: 710, alpha2: "za", alpha3: "zaf", name: "南非", code: "+27" },
        {
            id: 728,
            alpha2: "ss",
            alpha3: "ssd",
            name: "南蘇丹",
            code: "+211"
        },
        { id: 724, alpha2: "es", alpha3: "esp", name: "西班牙", code: "+34" },
        {
            id: 144,
            alpha2: "lk",
            alpha3: "lka",
            name: "斯里蘭卡",
            code: "+94"
        },
        { id: 729, alpha2: "sd", alpha3: "sdn", name: "蘇丹", code: "+249" },
        {
            id: 740,
            alpha2: "sr",
            alpha3: "sur",
            name: "蘇利南",
            code: "+597"
        },
        { id: 752, alpha2: "se", alpha3: "swe", name: "瑞典", code: "+46" },
        {
            id: 756,
            alpha2: "ch",
            alpha3: "che",
            name: "瑞士",
            code: "+41"
        },
        { id: 760, alpha2: "sy", alpha3: "syr", name: "敘利亞", code: "+963" },
        {
            id: 762,
            alpha2: "tj",
            alpha3: "tjk",
            name: "塔吉克",
            code: "+992"
        },
        { id: 834, alpha2: "tz", alpha3: "tza", name: "坦尚尼亞", code: "+255" },
        {
            id: 764,
            alpha2: "th",
            alpha3: "tha",
            name: "泰國",
            code: "+66"
        },
        { id: 626, alpha2: "tl", alpha3: "tls", name: "東帝汶", code: "+670" },
        {
            id: 768,
            alpha2: "tg",
            alpha3: "tgo",
            name: "多哥",
            code: "+228"
        },
        { id: 776, alpha2: "to", alpha3: "ton", name: "東加", code: "+676" },
        {
            id: 780,
            alpha2: "tt",
            alpha3: "tto",
            name: "千里達及托巴哥",
            code: "+1868"
        },
        {
            id: 788,
            alpha2: "tn",
            alpha3: "tun",
            name: "突尼西亞",
            code: "+216"
        },
        { id: 792, alpha2: "tr", alpha3: "tur", name: "土耳其", code: "+90" },
        {
            id: 795,
            alpha2: "tm",
            alpha3: "tkm",
            name: "土庫曼",
            code: "+993"
        },
        { id: 798, alpha2: "tv", alpha3: "tuv", name: "吐瓦魯", code: "+688" },
        {
            id: 800,
            alpha2: "ug",
            alpha3: "uga",
            name: "烏干達",
            code: "+256"
        },
        { id: 804, alpha2: "ua", alpha3: "ukr", name: "烏克蘭", code: "+380" },
        {
            id: 784,
            alpha2: "ae",
            alpha3: "are",
            name: "阿聯",
            code: "+971"
        },
        { id: 826, alpha2: "gb", alpha3: "gbr", name: "英國", code: "+44" },
        {
            id: 840,
            alpha2: "us",
            alpha3: "usa",
            name: "美國",
            code: "+1"
        },
        { id: 858, alpha2: "uy", alpha3: "ury", name: "烏拉圭", code: "+598" },
        {
            id: 860,
            alpha2: "uz",
            alpha3: "uzb",
            name: "烏茲別克",
            code: "+998"
        },
        { id: 548, alpha2: "vu", alpha3: "vut", name: "萬那杜", code: "+678" },
        {
            id: 862,
            alpha2: "ve",
            alpha3: "ven",
            name: "委內瑞拉",
            code: "+58"
        },
        { id: 704, alpha2: "vn", alpha3: "vnm", name: "越南", code: "+84" },
        {
            id: 887,
            alpha2: "ye",
            alpha3: "yem",
            name: "葉門",
            code: "+967"
        },
        { id: 894, alpha2: "zm", alpha3: "zmb", name: "尚比亞", code: "+260" },
        {
            id: 716,
            alpha2: "zw",
            alpha3: "zwe",
            name: "辛巴威",
            code: "+263"
        }
    ]
};
