import React, { useRef, useState } from "react";
import AnalyticsAllPeriodGraph from "./StatisticPageComponents/AnalyticsAllPeriodGraph";
import { getCurrentDate } from "../../../utils/helpers/TransformDate";
import { useEffect } from "react";
import { MainStatistics } from "./components/mainStatistics/mainStatistics";
import { OtherStatistics } from "./components/otherStatistics/otherStatistics";
import { useGetStatisticsGraphMutation } from "../../store/apis/statisticsApi";
import { useGetOnlineUsersQuery } from "../../store/apis/usersApi";
import logo from "../../../utils/icons/Logo.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLang } from "../../store/slices/userSlice";
import i18next from "i18next";

const StatisticPage = () => {
  
  const { t, i18n } = useTranslation()

  const DASHBOARD_STATISTICS = {
    GRAPHIC_STATISTICS:{
        REGISTRATION_STATISTICS: {
            title: t("users"),
            items: [
                {name: "registration_all", title: t('users'), color: "#FF6600"},
                {name: "registration_successful", title: t('d_cr'), color: "#21D929"},
                {name: "registration_unsuccessful", title: t('d_ir'), color: "#FF8C00"},
                {name: "registration_verification", title: t('d_sv'), color: "#FF0000"},
                {name: "registration_not_verification", title: t('d_fv'), color: "#4682B4"},
            ]
        },
        ADVERTS_STATISTICS: {
            title: t('announcement'),
            items: [
                {name: "adverts_all", title: t('announcement'), color: "#FF6600"},
                {name: "adverts_active", title: t('d_aa'), color: "#21D929"},
                {name: "adverts_in_archive", title: t('d_aia'), color: "#FF8C00"},
                {name: "adverts_for_moderation", title: t('d_am'), color: "#FF0000"},
                {name: "users_published_adverts", title: t('d_ua'), color: "#4682B4"},
                {name: "users_not_published_adverts", title: t('d_una'), color: "#DA70D6"},
                {name: "average_publication_users", title: t('d_nau'), color: "#696969"},
            ]
        },
        DEALS_STATISTICS: {
            title: t('deals'),
            items: [
                {name: "deals_all", title: t('deals'),color: "#FF6600"},
                {name: "deals_in_progress", title: t('d_dp'), color: "#21D929"},
                {name: "deals_finished", title: t('d_sd'), color: "#FF8C00"},
                {name: "deals_removed", title: t('d_rd'), color: "#FF0000"},
                {name: "users_published_deals", title: t('d_ud'), color: "#4682B4"},
                {name: "users_not_published_deals", title: t('d_und'), color: "#DA70D6"},
                {name: "average_deals_users", title: t('d_ndu'), color: "#696969"},
            ]
        }
    }
  }

  const [activeStatistics, setActiveStatistics] = useState(
    Object.entries(DASHBOARD_STATISTICS)[0][1].REGISTRATION_STATISTICS.items
  );
  const [activeLines, setActiveLines] = useState([activeStatistics[0]]);
  const [dateRange, setDateRange] = useState({
    startDate: 0,
    endDate: new Date().getTime(),
  });
  const [dateRangeType, setDateRangeType] = useState("month");

  const [statistics, { isLoading: isStatisticsLoading }] =
    useGetStatisticsGraphMutation();
  const { data: usersOnline } = useGetOnlineUsersQuery();
  const dispatch = useDispatch()
  const langs = useSelector(store => store.langStore.langs)
  const lang = useSelector(store => store.userStore.lang)
  const wrapper = useRef()
  const menu = useRef()

  useEffect(()=>{
      const newLines = []

      activeLines?.map(line => {
          const registration = DASHBOARD_STATISTICS.GRAPHIC_STATISTICS.REGISTRATION_STATISTICS.items.find(el => el.name === line.name)
          if(registration) newLines.push(registration)

          const adverts = DASHBOARD_STATISTICS.GRAPHIC_STATISTICS.ADVERTS_STATISTICS.items.find(el => el.name === line.name)
          if(adverts) newLines.push(adverts)

          const deals = DASHBOARD_STATISTICS.GRAPHIC_STATISTICS.DEALS_STATISTICS.items.find(el => el.name === line.name)
          if(deals) newLines.push(deals)
      })

      setActiveLines(newLines)

  },[i18next.language]);

  useEffect(() => {
    statistics({
      types: activeStatistics.map((el) => el.name),
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      date_type: dateRangeType,
    });
  }, [activeStatistics, dateRange, dateRangeType, statistics]);

  function handleLanguageMenu(){
      if(menu.current.offsetHeight){
        wrapper.current.style.cssText = `opacity: 0; visibility: hidden;`
        menu.current.style.cssText = `height: 0px;`
      }
      else{
        wrapper.current.style.cssText = `opacity: 1; visibility: visible;`
        menu.current.style.cssText = `height: ${menu.current.scrollHeight}px;`
      }
  }

  function changeLanguage(value){
      dispatch(setLang(value))
      localStorage.setItem("i18nextLng", value)
      i18n.changeLanguage(value)
      handleLanguageMenu()
  }

  return (
    <div className="terms statistic">
      <header className="statistic-header">
        <h1 className="terms-title">{t("d_title")}</h1>
        <div className="statistic-header_right">
          <div className="statistic-header_right_languages_wrapper">
            <div className="statistic-header_right_languages_wrapper_close_wrapper" ref={wrapper} onClick={handleLanguageMenu}></div>
            <span className="statistic-header_right_languages_wrapper_current_lang" onClick={handleLanguageMenu}>{lang}</span>
            <div className="statistic-header_right_languages_wrapper_dropdown_wrapper" ref={menu} >
              {
                langs?.map((el, index) => {
                  if(el.langIndex === "en" || el.langIndex === "th" || el.langIndex === "ru" || el.langIndex === "ua"){
                    return <span onClick={() => changeLanguage(el.langIndex)} key={index} className="statistic-header_right_languages_wrapper_dropdown_wrapper_item">{el.langIndex}</span>
                  } else return ""
                })
              }
            </div>
          </div>
          <div className="statistic-header_right_date">
            <p className="statistic-header_right_date-calendar">{getCurrentDate()}</p>
            <p className="statistic-header_right_date-users" style={{paddingTop: 10}}>
              {t('userOnline')}:{" "}
              <span style={{ color: "green" }}>{usersOnline?.usersOnline}</span>
            </p>
          </div>
        </div>
      </header>

      <MainStatistics
        setActiveStatistics={setActiveStatistics}
        activeLines={activeLines}
        setActiveLines={setActiveLines}
        setDateRange={setDateRange}
        setDateRangeType={setDateRangeType}
        DASHBOARD_STATISTICS={DASHBOARD_STATISTICS}
      />
      <AnalyticsAllPeriodGraph
        dateRange={dateRange}
        setDateRange={setDateRange}
        activeLines={activeLines}
        isLoading={isStatisticsLoading}
      />
      <OtherStatistics />

      <footer className="statistic-footer">
        <img src={logo} alt="logo" />
        <p className="statistic-footer_text">v 1.01 from 25.01.2024</p>
      </footer>
    </div>
  );
};

export default StatisticPage;
