import React from 'react'
import EyeOpen from "../../../../utils/icons/eye-open.svg"
import DeleteIcon from "../../../../utils/icons/delete-icon.svg"
import { RBACPermissionsGroupList } from '../../../components/rbacPermissionsGroupList/rbacPermissionsGroupList'

const RbacTablRoleItem = ({role, permissions, toggleDeleteModal, setRoleModal}) => {
	return (
		<tr >
			<td className='users-wrapper_tabl-body_item col-1' style={{width: '30%'}}>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{role.title}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-3'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<RBACPermissionsGroupList ids={role.permissionsIds} permissions={permissions}/>
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1' style={ { width: '100px' } }>
				<div
					className='users-wrapper_tabl-body_item-wrapper_icons'
				>
					<button
						type='button'
						style={ { marginLeft: '15px' } }
						onClick={() => setRoleModal(role)}
					>
						<img
							src={ EyeOpen }
							alt='Eye'
						/>
					</button >
					<button
						type='button'
						onClick={() => toggleDeleteModal(role.id)}
					>
						<img
							src={ DeleteIcon }
							alt='Delete'
						/>
					</button >
				</div >
			</td >
		</tr >
	)
}

export default RbacTablRoleItem