import { Table } from "react-bootstrap"
import verticalDots from '../../../utils/icons/vertical-dots.svg'
import ArrowSorting from '../../../utils/icons/arrow-sorting.svg'
import TemplateContentTablItem from "./TemplateContentTablItem"
import { ARE_COMMON } from "../../../utils/textsContent"
import { useTranslation } from "react-i18next"

const TemplateContentTabl = ({
	setOpenEditModal,
	toggleDeleteModal,
	sortedForColumn,
	countShowTemplates,
	showSmallTermArr,
	countShowPage,
	fromPage,
	// changeAllSelected
}) => {

	const {t} = useTranslation()

	return (
		<>
			<div className='terms-wrapper_itemsInfo'>
					<span className='terms-wrapper_itemsInfo-content'>
						{t('showing')} {countShowTemplates} {t('items_from')} {countShowPage}
					</span >
			</div >
			<Table
				responsive
				className='terms-wrapper_tabl'
				striped={true}
			>
				<thead className='terms-wrapper_tabl-head'>
				<tr className='terms-wrapper_tabl-trow'>
					<th className='terms-wrapper_tabl-trow_item col-1'>
						{/*<input*/}
						{/*	type='checkbox'*/}
						{/*	onKeyDown={ e => {*/}
						{/*		e.key === 'Enter' && e.preventDefault()*/}
						{/*	} }*/}
						{/*	name='checkAll'*/}
						{/*	className='terms-wrapper_tabl-body_item-wrapper_inputAll'*/}
						{/*	onChange={ e => {*/}
						{/*		changeAllSelected( e.target.checked )*/}
						{/*	} }*/}
						{/*/>*/}
						<span onClick={() => sortedForColumn('id')}>ID</span >
						<img
							src={ArrowSorting}
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className={`terms-wrapper_tabl-trow_item col-4'`}
						onClick={() => sortedForColumn('name')}
					>
						<span >
							{t('name')}
						</span >
					</th >
					<th
						className='terms-wrapper_tabl-trow_item col-2'
						onClick={() => sortedForColumn('type')}
					>
						<span >
							Type
						</span >
						<img
							src={ArrowSorting}
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='terms-wrapper_tabl-trow_item col-2'
						onClick={() => sortedForColumn('psevdo')}
					>
						<span >
							Mark
						</span >
						<img
							src={ArrowSorting}
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='terms-wrapper_tabl-trow_item pointer-event col-2 text-center'
						// onClick={() => sortedForColumn('value')}
					>
						<span >Key</span >
						{/*<img*/}
						{/*	src={ArrowSorting}*/}
						{/*	alt='Arrow Sorting'*/}
						{/*/>*/}
					</th >
					{
						// fromPage === 'changeItemsTemplateContentForTemplate' &&
						true &&
						<th
							className='terms-wrapper_tabl-trow_item pointer-event col-2'
							// onClick={() => sortedForColumn('value')}
						>
						<span >Info</span >
						{/*<img*/}
						{/*	src={ArrowSorting}*/}
						{/*	alt='Arrow Sorting'*/}
						{/*/>*/}
					</th >
					}
						<th className='terms-wrapper_tabl-trow_item col-1'>
						<img
							src={verticalDots}
							alt='Dots'
						/>
					</th >

				</tr >
				</thead >
				<tbody
					className='terms-wrapper_tabl-body'
				>
				{
					showSmallTermArr
					&&
					showSmallTermArr?.map((item, index) => <TemplateContentTablItem
						key={item?.id / 0.2 + index}
						item={item}
						setOpenEditModal={setOpenEditModal}
						toggleDeleteModal={toggleDeleteModal}
						fromPage={fromPage}
					/>)
				}
				</tbody >
			</Table >
		</>
	)
}

export default TemplateContentTabl
