import React, {
	useCallback,
	useEffect, useRef,
	useState,
} from 'react'
import { useDispatch, useSelector } from "react-redux"
import DeleteModal from "../../components/DeleteModal"
import AddEditBuilderTemplateContent from "./CreateTemplateContent/AddEditBuilderTemplateContent"
import AlertModal from "../../components/AlertModal"
import {
	useGetTemplateContentInfoMutation,
	useGetTemplateContentV2Mutation
} from "../../store/apis/temlateContentApi"
import { PAGES_NAME } from "../../../utils/textsContent"
import ControlPanelsForTemplateContentV2 from "../../components/environmentTabl/ControlPanelsForTemplateContentV2"
import { setTabName } from "../../store/slices/temlateContentSlice"
import { useTranslation } from 'react-i18next'

const TemplateContentPage = () => {
	const {templateContent, changeTabName} = useSelector(state => state.templateContentStore)
	const [getTemplateContentV2, {isLoading: isTemplateContentV2Loading}] = useGetTemplateContentV2Mutation()
	const [getTemplateContentInfo, {isLoading: isGetTemplateContentInfoLoading}] = useGetTemplateContentInfoMutation()
	const parameters = JSON.parse(localStorage.getItem('templateContentParametersPage'))
	const resV = parameters?.filters?.split('=').pop()

	const [addEditModalShow, setAddEditModalShow] = useState(false)
	const [deleteModalShow, setDeleteModalShow] = useState(false)
	const [openEditModal, setOpenEditModal] = useState(false)
	const [editDateForModal, setEditDateForModal] = useState({})
	const [deleteTerm, setDeleteTerm] = useState({})
	const [errorMessage, setErrorMessage] = useState('')
	const [searchTemplateContentValue, setSearchTemplateContentValue] = useState(parameters?.query?.split('=').pop() || '')
	const [sortedColumnValue, setSortedColumnValue] = useState(parameters?.sortBy?.split('=').pop() || '')
	const [currentPage, setCurrentPage] = useState(Number(parameters?.page?.split('=').pop()) || Number(1))
	const [countShowTemplates, setCountShowTemplates] = useState(Number(parameters?.limit?.split('=').pop()) || 20)
	const [changeOrderSort, setChangeOrderSort] = useState(parameters?.order?.split('=').pop() || 1)
	const [countShowPage, setCountShowPage] = useState(1)
	const [counterAllItems, setCounterAllItems] = useState(1)
	const [changeKeyValue, setChangeKeyValue] = useState(resV?.key ? resV?.key : '')
	const [changeTabValue, setChangeTabValue] = useState(resV?.type ? resV?.type : 0)
	const pastNumberCount = useRef(countShowTemplates)
	const pastValueSearchCount = useRef(searchTemplateContentValue)
	const dispatch = useDispatch()
	const allButtonsType = document.querySelectorAll('.terms-wrapper_search-types_button')
	const isLoading = isTemplateContentV2Loading || isGetTemplateContentInfoLoading
	const {t} = useTranslation()

	const getAllTemplateContent = useCallback(async (search, start) => {
		const keyValue = changeKeyValue ? {"key": changeKeyValue} : ''
		const tabValue = changeTabValue ? {"type": changeTabValue} : ''
		const limit = `&limit=${countShowTemplates}`
		const page = start === true ? `&page=${Number(1)}` : `&page=${Number(currentPage)}`
		const querySearch = search ? '' : searchTemplateContentValue ? {query: `&query=${searchTemplateContentValue.trim()}`} : ''
		const sortByItem = sortedColumnValue ? {sortBy: `&sortBy=${sortedColumnValue}`} : ''

		const filtersByItem = {filters: `&filters=${JSON.stringify({...tabValue, ...keyValue})}`}

		const tehFormData = {
			limit: limit,
			page: page,
			order: `&order=${changeOrderSort === 1 ? 1 : -1}`
		}
		try {
			if (parameters === null) {
				const {
					data,
					error
				} = await getTemplateContentV2({...tehFormData, ...querySearch, ...sortByItem, ...filtersByItem})
				!error && setCountShowPage(Number(data?.totalPages))
				!error && setCounterAllItems(Number(data?.totalTemplates))
				!error && localStorage.setItem('templateContentParametersPage', JSON?.stringify({...tehFormData, ...querySearch, ...sortByItem, ...filtersByItem}))
				error && setErrorMessage(error?.data?.message)
			} else {
				if (
					searchTemplateContentValue !== '' ||
					sortedColumnValue !== '' ||
					changeOrderSort ||
					countShowTemplates !== 20 ||
					currentPage !== 1
				) {
					const {
						data,
						error
					} = await getTemplateContentV2({...tehFormData, ...querySearch, ...sortByItem, ...filtersByItem})
					!error && setCountShowPage(Number(data?.totalPages))
					!error && setCounterAllItems(Number(data?.totalTemplates))
					!error && setCurrentPage(Number(data?.page))
					!error && localStorage.setItem('templateContentParametersPage', JSON?.stringify({...tehFormData, ...querySearch, ...sortByItem, ...filtersByItem}))
					!error && (pastNumberCount.current = countShowTemplates)
					!error && (pastValueSearchCount.current = searchTemplateContentValue)
					error && setErrorMessage(error?.data?.message)
				}
			}
		} catch (e) {
			setErrorMessage(e)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTemplateContentValue, sortedColumnValue, changeOrderSort, countShowTemplates, currentPage, changeKeyValue, changeTabValue])

	useEffect(()=>{
		if(!addEditModalShow) getAllTemplateContent(false)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[addEditModalShow]);

	useEffect(() => {
		const search = false
		getAllTemplateContent(search)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortedColumnValue, changeOrderSort, countShowTemplates, currentPage, changeKeyValue, changeTabValue])

	const toggleButtonsType = (name, num) => {
		for (let i = 0; i < allButtonsType?.length; i++) {
			if (allButtonsType[i].innerText === (name || changeTabName)) {
				allButtonsType[i].classList.add('term-types_active')
				changeTabName !== name && dispatch(setTabName(name))
			} else {
				allButtonsType[i].classList.remove('term-types_active')
			}
		}
		setChangeTabValue(num)
	}

	const searchHandler = async () => {
		const search = false
		const start = true
		await getAllTemplateContent(search, start)
	}

	const toggleModalSearch = () => {
	}

	const sortedForColumnHandler = (sort) => {
		if (sort === sortedColumnValue) {
			if (changeOrderSort === 1) setChangeOrderSort(-1)
			else setChangeOrderSort(1)
		} else {
			setChangeOrderSort(1)
		}
		setSortedColumnValue(sort)
	}

	const showModalHandler = () => {
		setAddEditModalShow(true)
		setOpenEditModal(false)
	}


	const hideModalHandler = () => {
		setAddEditModalShow(false)
		setOpenEditModal(false)
		setEditDateForModal({})
	}

	const toggleDeleteModal = (value) => {
		setDeleteTerm(value)
		setDeleteModalShow(!deleteModalShow)
	}

	const openEditModalHandler = async (value) => {
		const idTemplateContent = value?.id ? {param: `&id=${value?.id}`} : ''
		const {data, error} = await getTemplateContentInfo(idTemplateContent)
		error && setErrorMessage(error?.data?.message)
		error && setTimeout(() => hideModalHandler(), 3000)
		// console.log('data?.data?.[0]', data?.data?.[0])
		setEditDateForModal(data?.data?.[0])
		setAddEditModalShow(true)
		setOpenEditModal(true)
	}

	const valueSearchInputHandler = (value) => {
		setSearchTemplateContentValue(value)
	}

	const returnErrorForDel = (mes) => {
		setErrorMessage(mes)
	}


	const clearSearchHandler = async () => {
		setSearchTemplateContentValue('')
		const search = true
		await getAllTemplateContent(search)
	}

	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<DeleteModal
				show={deleteModalShow}
				onHide={toggleDeleteModal}
				value={deleteTerm}
				fromPage={'templateContent'}
				returnErrorForDel={returnErrorForDel}
			/>
			{
				addEditModalShow
					?
					<AddEditBuilderTemplateContent
						onHide={hideModalHandler}
						edit={openEditModal}
						editDateForModal={editDateForModal}
					/>
					:
					<>
						<h1 className='terms-title'>
							{t('s_temf')}
						</h1 >
							<ControlPanelsForTemplateContentV2
								bigArrElements={templateContent}
								isLoading={isLoading}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								countShowPage={countShowPage}
								counterAllItems={counterAllItems}
								setCountShowTemplates={setCountShowTemplates}
								searchTemplateContentValue={searchTemplateContentValue}
								countShowTemplates={countShowTemplates}
								openEditModalHandler={openEditModalHandler}
								sortedForColumn={sortedForColumnHandler}
								toggleDeleteModal={toggleDeleteModal}
								valueSearchInputHandler={valueSearchInputHandler}
								clearSearchHandler={clearSearchHandler}
								changeKeyValue={changeKeyValue}
								setChangeKeyValue={setChangeKeyValue}
								toggleButtonsType={toggleButtonsType}
								showModalHandler={showModalHandler}
								searchHandler={searchHandler}
								toggleModalSearch={toggleModalSearch}
							/>
					</>
			}
		</div >
	)
}

export default TemplateContentPage
