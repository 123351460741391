import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	NOTICE_API,
} from "../../../utils/constants"

const API = process.env.REACT_APP_API_URL;

export const noticeApi = createApi({
	reducerPath: "noticeApi",
	tagTypes: ['Advert'],
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => (
		{
			getNotice: builder.mutation({
				query: ({limit, page, sort, order, moderation_status, status, template, subcategory, category}) => (
					{
						url: `
					${NOTICE_API.GET_NOTICE}?
					${limit ? limit : ''}
					${page ? page : ''}
					${order ? order : ''}
					${sort ? sort : ''}
					${moderation_status ? moderation_status : ''}
					${status ? status : ''}
					${template ? template : ''}
					${subcategory ? subcategory : ''}
					${category ? category : ''}
					`,
						method: "GET",
					}
				),
				invalidatesTags: [{ type: 'Advert', id: 'LIST' }],
			}),
			getV2Notice: builder.mutation({
				query: ({limit, page, sort, order, moderation_status, status, template, subcategory, category, user}) => (
					{
						url: `
					${NOTICE_API.GET_NOTICE_V2}?
					${limit ? limit : ''}
					${page ? page : ''}
					${order ? order : ''}
					${sort ? sort : ''}
					${moderation_status ? moderation_status : ''}
					${status ? status : ''}
					${template ? template : ''}
					${subcategory ? subcategory : ''}
					${category ? category : ''}
					${user ? user : ''}
					`,
						method: "GET",
					}
				),
				invalidatesTags: [{ type: 'Advert', id: 'LIST' }],
			}),
			getNoticeItem: builder.mutation({
				query: ({id, sort, order, limit, page, template, subcategory, category, moderation_status, status}) => (
					{
						url: `
					${NOTICE_API.GET_NOTICE_ITEM}?
					${id}
					${sort ? sort : ''}
					${order ? order : ''}
					${limit ? limit : ''}
					${page ? page : ''}
					${template ? template : ''}
					${subcategory ? subcategory : ''}
					${category ? category : ''}
					${moderation_status ? moderation_status : ''}
					${status ? status : ''}
					`,
						method: "GET",
					}
				),
				invalidatesTags: [{ type: 'Advert', id: 'LIST' }],
			}),
			restoreNotice: builder.mutation({
				query: (body) => (
					{
						url: `${NOTICE_API.RESTORE_NOTICE}`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Advert', id: 'LIST' }],
			}),
			promoteNotice: builder.mutation({
				query: (body) => (
					{
						url: `${NOTICE_API.PROMOTE_NOTICE}`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Advert', id: 'LIST' }],
			}),
			searchNotice: builder.mutation({
				query: (search) => (
					{
						url: `${NOTICE_API.SEARCH_NOTICE}?search=${search}`,
						method: "GET",
					}
				)
			}),
			searchNoticeV2: builder.mutation({
				query: (search) => (
					{
						url: `${NOTICE_API.SEARCH_NOTICE_V2}?search=${search}`,
						method: "GET",
					}
				)
			}),
			searchNoticeForAuthor: builder.mutation({
				query: (search) => (
					{
						url: `${NOTICE_API.SEARCH_NOTICE_FOR_USER}?${search}`,
						method: "GET",
					}
				)
			}),
			searchNoticeForAuthorV2: builder.mutation({
				query: (search) => (
					{
						url: `${NOTICE_API.GET_NOTICE_V2}?${search}`,
						method: "GET",
					}
				)
			}),

			topadvNotice: builder.mutation({
				query: () => (
					{
						url: `${NOTICE_API.TOPADV_NOTICE}`,
						method: "GET"
					}
				)
			}),
			changeStatusNotice: builder.mutation({
				query: (body) => (
					{
						url: `${NOTICE_API.CHANGE_STATUS_NOTICE}`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Advert', id: 'LIST' }],
			}),

			moderationChangeStatusNotice: builder.mutation({
				query: (body) => (
					{
						url: `${NOTICE_API.MODERATION_CHANGE_STATUS_NOTICE}`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Advert', id: 'LIST' }],
			}),

			getAdverts: builder.query({
				query: ({limit, page, sort, order, moderation_status, status, template, subcategory, category, user, searchQuery, lang}) => (
					{
						url: `
					${NOTICE_API.GET_NOTICE_V2}?
					${limit ? "limit="+limit : ''}
					${page ? "&page="+page : ''}
					${order ? "&order="+order : ''}
					${sort ? "&sort="+sort : ''}
					${searchQuery ? "&searchQuery="+searchQuery : ''}
					${typeof moderation_status === "number" ? "&moderation_status="+moderation_status : ''}
					${typeof status === "number" ? "&status="+status : ''}
					${template ? "&template="+status : ''}
					${subcategory ? "&subcategory="+subcategory : ''}
					${category ? "&category="+category : ''}
					${user ? "&user="+user : ''}
					${lang ? "&lang="+lang : ''}
					`,
						method: "GET",
					}
				),
				providesTags: (result = false) =>
					result.data
					? [
						...result.data.map(({ id }) => ({ type: 'Advert', id })),
						{ type: 'Advert', id: 'LIST' },
						]
					: [{ type: 'Advert', id: 'LIST' }],
			}),

			getAdvert: builder.query({
				query: (params) => ({
					url: NOTICE_API.GET_NOTICE_ITEM, 
					method: "GET",
					params
				}),
				providesTags: (result = false) =>
					result.data
					? [
						...result.data.map(({ id }) => ({ type: 'Advert', id })),
						{ type: 'Advert', id: 'LIST' },
						]
					: [{ type: 'Advert', id: 'LIST' }],
			}),
		}
	)
})
// &sort=${sort}
export const {
	useGetV2NoticeMutation,
	useGetNoticeMutation,
	useGetNoticeItemMutation,
	useRestoreNoticeMutation,
	usePromoteNoticeMutation,
	useSearchNoticeMutation,
	useSearchNoticeV2Mutation,
	useTopadvNoticeMutation,
	useSearchNoticeForAuthorMutation,
	useSearchNoticeForAuthorV2Mutation,
	useChangeStatusNoticeMutation,
	useModerationChangeStatusNoticeMutation,
	useGetAdvertsQuery,
	useGetAdvertQuery
} = noticeApi
