import { createSlice } from "@reduxjs/toolkit"
import { unitGroupsApi } from "../apis/unitGroupsApi"
import { UniqueArrItems } from "../../../utils/helpers/UniqueArrItems";

const initialState = {
	unitGroups: [],
	showModalChangeUnitGroups: false,
	dateModalChangeUnitGroups: [],
	dateModalChangeUnitGroup: '',
	showModalChangeUnitGroup: false,
	dateModalChangeUnitGroupAmount: '',
	dateModalChangeUnitGroupVolume: '',
}

const unitGroupsSlice = createSlice( {
	name: "unitGroups",
	initialState,
	reducers: {
		setUnitGroupsList: (state, action) => {
			state.unitGroups = action.payload
		},
		getUnitGroupsList: ( state, action ) => {
			state.unitGroups = action.payload
		},
		createNewUnitGroupsItem: ( state, action ) => {
			state.unitGroups = [ action.payload, ...state.unitGroups ]
		},
		editUnitGroupsItem: ( state, action ) => {
			const index = state.unitGroups.findIndex(
				( term ) => {
					return term.id === action.payload.id
				}
			)
			state.unitGroups[index] = action.payload
		},
		deleteUnitGroupsItem: ( state, action ) => {
			const termId = action.payload
			const termID = state.unitGroups.filter( term => term.id !== termId )
			state.unitGroups = termID
		},
		showChangeUnitGroupsModal: ( state, action ) => {
			state.showModalChangeUnitGroups = action.payload
		},
		setDateChangeUnitGroups: ( state, action ) => {
			const uniq = UniqueArrItems([ ...state.dateModalChangeUnitGroups, action.payload ])
			state.dateModalChangeUnitGroups = uniq
		},
		resetDateChangeUnitGroups: ( state, action ) => {
			state.dateModalChangeUnitGroups = []
		},
		setDateChangeUnitGroup: ( state, action ) => {
			state.dateModalChangeUnitGroup = action.payload
		},
		setDateChangeUnitGroupAmount: ( state, action ) => {
			state.dateModalChangeUnitGroupAmount = action.payload
		},
		setDateChangeUnitGroupVolume: ( state, action ) => {
			state.dateModalChangeUnitGroupVolume = action.payload
		},
		showChangeUnitGroupModalHandler: (state, action) => {
			state.showModalChangeUnitGroup = action.payload
		},
	},
	extraReducers: ( builder ) => {
		builder
			.addMatcher( unitGroupsApi.endpoints.getUnitGroups.matchFulfilled, (
					state,
					action
				) => {
					state.unitGroups = action.payload.data
				}
			)
	}
} )

const {
	actions,
	reducer
} = unitGroupsSlice
export const {
	setUnitGroupsList,
	getUnitGroupsList,
	createNewUnitGroupsItem,
	deleteUnitGroupsItem,
	editUnitGroupsItem,
	showChangeUnitGroupsModal,
	setDateChangeUnitGroups,
	resetDateChangeUnitGroups,
	setDateChangeUnitGroup,
	showChangeUnitGroupModalHandler,
	setDateChangeUnitGroupAmount,
	setDateChangeUnitGroupVolume
} = actions
export default reducer
