const RejectButton = ({handler, title, isLoading}) => {
	return (
		<button
			className="terms-wrapper_addButton"
			type='button'
			onClick={isLoading? null : handler}
		>
			{isLoading? 'Зачекайте...' : title}
		</button>
	);
};

export default RejectButton
