import { createSlice } from "@reduxjs/toolkit"
import { notificationApi } from "../apis/notificationApi"

const initialState = {
	notification: []
}

const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		setNotification: (state, action) => {
			state.notification = action.payload
		},
		createNewNotification: (state, action) => {
			state.notification = [action.payload, ...state.notification]
		},
		deleteNotificationItem: (state, action) => {
			const Id = action.payload
			const ID = state.notification.filter(answer => answer.id !== Id)
			state.notification = ID
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(notificationApi.endpoints.getNotifications.matchFulfilled, (
					state,
					action
				) => {
					state.notification = action.payload.data
				}
			)
	}
})

const {
	actions,
	reducer
} = notificationSlice
export const {
	setNotification,
	deleteNotificationItem,
	createNewNotification
} = actions
export default reducer
