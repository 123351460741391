import React from 'react'
import MenuIcon from '../../../utils/icons/vertical-dots.svg'
import { PAGES_NAME } from "../../../utils/textsContent";
import { useTranslation } from 'react-i18next';

const PollPageItemList = () => {

	const {t} = useTranslation()

	return (
		<li className='poll-content_li'>
			<div className='poll-content_li-left'>
				<p className='poll-content_li-left_date'>
					22 червня 2023 11:23
				</p >
				<h3 className='poll-content_li-left_title'>
					Нова форма 1
				</h3 >
				<p className='poll-content_li-left_text'>
					Анкета
				</p >
			</div >
			<div className='poll-content_li-center'>
				<p className='poll-content_li-center_date'>
				</p >
				<h3 className='poll-content_li-center_title'>
					Новий список 1
				</h3 >
				<p className='poll-content_li-center_text'>
					{PAGES_NAME?.ADDRESS_BOOK}
				</p >
			</div >
			<div className='poll-content_li-right'>
				<div className='poll-content_li-right_date'>
					<img
						src={ MenuIcon }
						alt='dots'
					/>
				</div >
				<h3 className='poll-content_li-right_title'>
					10/<span>200</span>
				</h3 >
				<p className='poll-content_li-right_text'>
					Пройдено
				</p >
			</div >
		</li >
	)
}

export default PollPageItemList