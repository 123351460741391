import { Table } from "react-bootstrap"
import ArrowSorting from '../../../utils/icons/arrow-sorting.svg'
import {
	useDispatch,
	useSelector,
} from "react-redux"
import {
	useEffect,
	useState,
} from "react"
import AlertModalTerms from "../../components/AlertModalTerms"
import { setAlert } from "../../store/slices/termsSlice"
import AdvertisingTablItem from './AdvertisingTablItem'
import { ARE_COMMON } from "../../../utils/textsContent"

const AdvertisingTabl = ( {
	setOpenEditModal,
	toggleDeleteModal,
	sortedForColumn,
	countShowTerms,
	showSmallTermArr,
	countShowPage,
	fromPage
} ) => {
	const { alert } = useSelector( state => state.termsStore )
	const [ showAlertModal, setShowAlertModal ] = useState( false )
	const dispatch = useDispatch()
	const closeAlertModalHandler = () => {
		setShowAlertModal( false )
		dispatch( setAlert( null ) )
	}

	useEffect( () => {
		alert && setShowAlertModal( true )
	}, [ alert ] )

	return (
		<>
			<div className='terms-wrapper_itemsInfo'>
					<span className='terms-wrapper_itemsInfo-content'>
						{ARE_COMMON?.SHOWN} { countShowTerms } {ARE_COMMON?.POINTS_FROM} { countShowPage }
					</span >
			</div >
			{
				showAlertModal && <AlertModalTerms close={ closeAlertModalHandler } />
			}
			<Table
				responsive
				className='terms-wrapper_tabl'
				striped={ true }
			>
				<thead className='terms-wrapper_tabl-head'>
				<tr className='terms-wrapper_tabl-trow'>
					<th
						className='terms-wrapper_tabl-trow_item col-1'
						onClick={ () => sortedForColumn( 'id' ) }
					>
						<span >{ARE_COMMON?.ID}</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className={ `terms-wrapper_tabl-trow_item col-10` }
						onClick={ () => sortedForColumn( 'advertising' ) }
					>
						<span >
							{ARE_COMMON?.NAME}
						</span >
					</th >
				</tr >
				</thead >
				<tbody
					className='terms-wrapper_tabl-body'
				>
				{
					showSmallTermArr && showSmallTermArr?.map( ( item, index ) => (
						<AdvertisingTablItem
							key={ item?.id || index + 'te' + 3 }
							item={ item }
							setOpenEditModal={ setOpenEditModal }
							toggleDeleteModal={ toggleDeleteModal }
							fromPage={ fromPage }
						/>
					) )
				}
				</tbody >
			</Table >
		</>
	)
}

export default AdvertisingTabl
