import {
	Button,
	Form,
	Modal
} from "react-bootstrap"
import { Formik } from "formik"
import React, {
	useEffect,
	useState
} from "react"
import Loader from "../Loader"
import {
	useDispatch,
	useSelector
} from "react-redux"
import arrowDownIcon from '../../../utils/icons/arrow-full-down.svg'
import ChangeUnitsForPrice from "../../pages/Amount/ChangeUnitsForAmount"
import {
	createNewAmountItem,
	editAmountItem,
	setDateChangeUnitAmount,
	showChangeUnitAmountModal
} from "../../store/slices/amountSlice"
import {
	useCreateAmountMutation,
	useEditAmountMutation,
	useGetAmountMutation
} from "../../store/apis/amountApi"
import { addAmountSchema } from "../../../utils/validation/YupAmount"
import AlertModal from "../AlertModal";
import { LogoutUserHandler } from '../../../utils/helpers/LoqoutUserHelper'
import { useNavigate } from 'react-router-dom'
import {
	ARE_COMMON,
	INPUTS_TEXT
} from "../../../utils/textsContent";
import BlockWithTranslationFields from "../BlockWithTranslationFields";

const AddEditAmountItem = ({
	show,
	onHide,
	edit,
	editDateForModal,
	showModalChangeUnitAmount
}) => {
	const [form, setForm] = useState({})
	const {dateModalChangeUnitAmount} = useSelector((state) => state.amountStore)
	const {unitsMeasurement} = useSelector((state) => state.unitsMeasurementStore)
	const [createAmount, {isLoading: isCreateAmountLoader}] = useCreateAmountMutation()
	const [editAmount, {isLoading: iseEditAmountLoader}] = useEditAmountMutation()
	const [getAmount, {isLoading: isGetAmountLoading}] = useGetAmountMutation()
	const [valueChangeUnitForPrice, setValueChangeUnitForPrice] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const isLoading = isCreateAmountLoader || iseEditAmountLoader || isGetAmountLoading

	useEffect(() => {
			const data = unitsMeasurement.find(item => item.id === Number(dateModalChangeUnitAmount))
			setValueChangeUnitForPrice(data)
		},
		[dateModalChangeUnitAmount, unitsMeasurement.length > 0])
	const openModalChangeUnitForPrice = () => dispatch(showChangeUnitAmountModal(true))

	useEffect(() => {
		if (edit) {
			dispatch(setDateChangeUnitAmount(editDateForModal?.unit))
		}
	}, [edit])

	useEffect(() => {
		edit && setForm(editDateForModal)
		const sesForm = sessionStorage.getItem('amountItem')
		if (sesForm?.length) {
			const pars = JSON?.parse(sesForm)
			setForm(pars)
		}
	}, [dateModalChangeUnitAmount])

	const formDateUpdateHandler = (opt) => {
		setForm({...form, ...opt})
		sessionStorage.setItem('amountItem', JSON?.stringify({...form, ...opt}))
	}

	const handleSubmit = async (values) => {
		const formDate = {
			name: {
				'ua': values.uaTitle,
				'ru': values.ruTitle,
				'en': values.enTitle,
				'pl': values.plTitle,
			},
			unit: Number(dateModalChangeUnitAmount),
		}
		try {
			if (edit) {
				const {error} = await editAmount({...formDate, id: editDateForModal.id})
				await updateDB()
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()
				if (error?.status === 403 && error?.data?.message === 'Incorrect token or expiried') LogoutUserHandler(
					dispatch, navigate)
				sessionStorage.clear()
			} else {
				const {error} = await createAmount(formDate)
				await updateDB()
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()
				sessionStorage.clear()
				if (error?.status === 403 && error?.data?.message === 'Incorrect token or expiried') LogoutUserHandler(
					dispatch, navigate)
			}
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}
	}

	const clearFormHideModal = async () => {
		onHide()
		setForm({})
		setValueChangeUnitForPrice(null)
		dispatch(setDateChangeUnitAmount({id: ''}))
		sessionStorage.clear()
	}

	const updateDB = async () => {
		// const dataAmount = await getAmount()
		// await db.amount.put({
		// 	id: 'amount',
		// 	amount: dataAmount.data.data
		// })
	}

	return (
		<Modal
			show={show}
			onHide={clearFormHideModal}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			className='add-edit-terms_modal'
			style={{zIndex: '99999'}}
		>
			{
				showModalChangeUnitAmount
				&&
				<ChangeUnitsForPrice />
			}
			{
				errorMessage && <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<Formik
				validateOnChange
				initialValues={{
					uaTitle: form.uaTitle || form?.name?.ua || '',
					ruTitle: form.ruTitle || form?.name?.ru || '',
					enTitle: form.enTitle || form?.name?.en || '',
					plTitle: form.plTitle || form?.name?.pl || '',
					unit: form?.unit || null || dateModalChangeUnitAmount
				}}
				validationSchema={addAmountSchema}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{
					({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty
					}) => (
						<>
							<Modal.Header
								closeButton
							>
								<Modal.Title
									id='contained-modal-title-vcenter'
									className='add-edit-terms_title'
								>
									{edit ? `${ARE_COMMON?.EDITING} amount` : `${ARE_COMMON?.CREATED} amount`}
								</Modal.Title >
							</Modal.Header >
							<Modal.Body >
								<Form
									className='add-edit-terms'
									onSubmit={handleSubmit}
								>
									<BlockWithTranslationFields
										handleChange={handleChange}
										values={values}
										errors={errors}
										touched={touched}
										handleBlur={handleBlur}
										formDateUpdateHandler={formDateUpdateHandler}
										name={'Title'}
										title={'title'}
									/>
									<div className='add-edit-terms_block'>
										<Form.Group className='add-edit-terms_center'>
											{ARE_COMMON?.UNIT}
											<button
												type='button'
												className='add-edit-terms_block-dropdown'
												onClick={openModalChangeUnitForPrice}
											>
												{
													valueChangeUnitForPrice ? valueChangeUnitForPrice?.term?.ua : 'Оберіть одиницю виміру'
												}
												<img
													src={arrowDownIcon}
													alt='arrow down'
												/>
											</button >
										</Form.Group >
									</div >
								</Form >
							</Modal.Body >
							<Modal.Footer >
								<Button
									type='button'
									className='add-edit-terms_close'
									onClick={clearFormHideModal}
								>
									{ARE_COMMON?.ABOLITION}
								</Button >
								<Button
									type='submit'
									className='add-edit-terms_save d-inline-flex'
									style={{
										maxHeight: '33px',
										display: 'flex',
										alignItems: 'center'
									}}
									disabled={(!isValid && dirty)}
									onClick={handleSubmit}
								>
									{
										isLoading ? <Loader /> : (edit ? ARE_COMMON?.SAVE : `${ARE_COMMON?.CREATED} amount`)
									}
								</Button >
							</Modal.Footer >
						</>
					)
				}
			</Formik >
		</Modal >
	)
}

export default AddEditAmountItem
