import React, { useEffect, useRef, useState } from 'react'
import AlertModal from "../AlertModal"
import { Formik } from "formik"
import { addPriceSchema } from "../../../utils/validation/YupPrice"
import {
	Button,
	Form,
	Modal
} from "react-bootstrap"
import { generatePassword } from '../../../utils/helpers/generatePassword'
import { EMAIL_REGEXP } from '../../../utils/constants'
import { ReactComponent as LoadingIcon } from '../../../utils/icons/loading-icon.svg'
import { useEditAdminMutation, useGetUsersRolesQuery, useRegistrationUserMutation } from '../../store/apis/rbacApi'
import Loader from '../LoaderContent'
import { useTranslation } from 'react-i18next'

const AddEditRbacItemModal = ( {show, onHide, setCurrentUser, userData, edit} ) => {

	const [ form, setForm ] = useState( {name: '', lastname: '', login: '', password: '', email: '', role: ''} )
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const password_input = useRef()

	const {data: roles, isLoading: isRolesLoading} = useGetUsersRolesQuery()
	const [regUser, {isLoading: isRegLoading}] = useRegistrationUserMutation()
	const [editUser, {isLoading: isEditLoading}] = useEditAdminMutation()
	const {t} = useTranslation()
	
	const formDateUpdateHandler = ( opt ) => setForm( { ...form, ...opt } )

	
	useEffect(()=>{
		if(userData){
			const pib_arr = userData.pib.split(' ');
			setForm({name: pib_arr[1], lastname: pib_arr[0], surname: pib_arr[2], email: userData.email, login: userData.login, role: userData.role.id, id: userData.id})
		}
	},[]);

	useEffect(()=>{
		if(roles && !userData)formDateUpdateHandler({role: roles[0].id})
	},[roles]);

	async function handleSubmit(){
		if(isRolesLoading) return
		let error = false;
		if(!EMAIL_REGEXP.test(form.email)) error ="Please enter a valid Email address"
		for(let value in form){
			if(!form[value] || form[value].length < 3) error ="Please fill all fields"
		}
	
		if(error){
			setErrorMessage(error)
			return
		}
		let result
		if(userData) result = await editUser(form)
		else result = await regUser(form)
		if(result.data.status === 200) clearFormHideModal()
	}

	const clearFormHideModal = () => {
		if(userData) setCurrentUser(false)
		else{
			onHide()
			setForm( {} )
		}
	}
	return (
		<Modal
			show={ show }
			onHide={ clearFormHideModal }
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			className='add-edit-terms_modal'
			style={ { zIndex: '99999' } }
		>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<Formik
				validateOnChange
				initialValues={ {
					name: form.name,
					lastName: form.lastname,
					surname: form.surname,
					login: form.login,
					password: form.password,
					role: form.role,
					email: form?.email
				} }
				validationSchema={ addPriceSchema }
				// onSubmit={ handleSubmit }
				enableReinitialize
			>
				{
					( {
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						// handleSubmit,
						dirty
					} ) => (
						<>
							<Modal.Header
								closeButton
							>
								<Modal.Title
									id='contained-modal-title-vcenter'
									className='add-edit-terms_title'
								>
									{userData? userData.pib : "New employee"}
								</Modal.Title >
							</Modal.Header >
							<Modal.Body >
								<Form
									className='add-edit-terms'
									onSubmit={ handleSubmit }
								>
									<Form.Group
										className='add-edit-terms_label add-edit-rbac_label'
									>
											Name
											<Form.Control
												type='text'
												name='name'
												onBlur={ handleBlur }
												onKeyDown={ e => {
													e.key === 'Enter' && e.preventDefault()
												} }
												value={form?.name}
												onChange={ev => formDateUpdateHandler({name: ev.target.value})}
												className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
											/>
									</Form.Group >
									<Form.Group
										className='add-edit-terms_label add-edit-rbac_label'
									>
											Surname
											<Form.Control
												type='text'
												name='lastname'
												value={form?.lastname}
												onBlur={ handleBlur }
												onKeyDown={ e => {
													e.key === 'Enter' && e.preventDefault()
												} }
												onChange={ev => formDateUpdateHandler({lastname: ev.target.value})}
												className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
											/>
									</Form.Group >
									{/* <Form.Group
										className='add-edit-terms_label add-edit-rbac_label'
									>
										По батькові
											<Form.Control
												type='text'
												name='surname'
												value={form?.surname}
												onBlur={ handleBlur }
												onKeyDown={ e => {
													e.key === 'Enter' && e.preventDefault()
												} }
												onChange={ev => formDateUpdateHandler({surname: ev.target.value})}
												className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
											/>
									</Form.Group > */}

									<Form.Group
										className='add-edit-terms_label add-edit-rbac_label'
									>
										Position
										{
											isRolesLoading? <Loader/> :
											<select
												className='add-edit-rbac_inp mt-1'
												name="role"
												onChange={ev => formDateUpdateHandler({role: ev.target.value})}
											>
												{
													roles?.map((el, index) => {
														return (<option key={index} selected={form?.role === el.id} value={el.id}>{el.title}</option >)
													})
												}
											</select > 
										}
									</Form.Group >

									<div className='add-edit-rbac_container'>
										<Form.Group
											className='add-edit-terms_label add-edit-rbac_label'
										>
											Login
											<Form.Control
												type='text'
												name='login'
												onBlur={ handleBlur }
												value={form?.login}
												onKeyDown={ e => {
													e.key === 'Enter' && e.preventDefault()
												} }
												onChange={ev => formDateUpdateHandler({login: ev.target.value})}
												className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
											/>
									</Form.Group >
										<div className='add-edit-rbac_container-block'>
											<Form.Group
												className='add-edit-terms_label add-edit-rbac_label'
											>
											Password
											<Form.Control
												type='text'
												name='password'
												onBlur={ handleBlur }
												onKeyDown={ e => {
													e.key === 'Enter' && e.preventDefault()
												} }
												ref={password_input}
												onChange={ev => {formDateUpdateHandler({password: ev.target.value})}}
												className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
											/>
									</Form.Group >
											<Button className='add-edit-terms_save d-inline-flex'
												onClick={() => {
													const pass = generatePassword(20)
													formDateUpdateHandler({password: pass})
													password_input.current.value = pass
												}}
											>
												Generate
											</Button >
										</div >

										<Form.Group
											className='add-edit-terms_label add-edit-rbac_label'
										>
											Еmail
											<Form.Control
												type='text'
												name='email'
												onBlur={ handleBlur }
												value={form?.email}
												onKeyDown={ e => {
													e.key === 'Enter' && e.preventDefault()
												} }
												onChange={ev => formDateUpdateHandler({email: ev.target.value})}
												className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
											/>
									</Form.Group >
										{/* <div className='add-edit-rbac_container-button'>
											<button className='add-edit-terms_close'>
											Відправити на почту
										</button >
										</div > */}
									</div >

								</Form >
							</Modal.Body >
							<Modal.Footer >
								<Button
									type='submit'
									className='add-edit-terms_save add-edit-rbac_button d-inline-flex'
									style={ {
										maxHeight: '33px',
										display: 'flex',
										alignItems: 'center'
									} }
									onClick={handleSubmit}
								>
										{isRegLoading || isEditLoading? <LoadingIcon/> : t('save')}
								</Button >
							</Modal.Footer >
						</>
					)
				}
			</Formik >
		</Modal >
	)
}

export default AddEditRbacItemModal;