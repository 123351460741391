import { createSlice } from "@reduxjs/toolkit"
import { advertisingApi } from "../apis/advertisingApi"
import { UniqueArrItems } from '../../../utils/helpers/UniqueArrItems'

const initialState = {
	advertising: [],
	showModalChangeUserAdvertising: false,
	dateModalChangeUserAdvertising: {},
	dateModalChangeUsersForAddressBook: []
}

const advertisingSlice = createSlice( {
	name: "advertising",
	initialState,
	reducers: {
		setAdvertisingList: ( state, action ) => {
			state.advertising = action.payload
		},
		createNewAdvertisingItem: ( state, action ) => {
			state.advertising = [ action.payload, ...state.advertising ]
		},
		editAdvertisingItem: ( state, action ) => {
			const index = state.advertising.findIndex(
				( term ) => {
					return term.id === action.payload.id
				}
			)
			state.advertising[ index ] = action.payload
		},
		deleteAdvertisingItem: ( state, action ) => {
			const termId = action.payload
			const termID = state.advertising.filter( term => term.id !== termId )
			state.advertising = termID
		},
		showChangeUserAdvertisingModal: ( state, action ) => {
			state.showModalChangeUserAdvertising = action.payload
		},
		setDateChangeUserAdvertising: ( state, action ) => {
			state.dateModalChangeUserAdvertising = action.payload
		},
		setDateChangeUsersForAddressBookArr: ( state, action ) => {
			const res = UniqueArrItems([...state.dateModalChangeUsersForAddressBook, action.payload])
			state.dateModalChangeUsersForAddressBook = res
		},
		removeDateChangeUsersForAddressBookArr: ( state, action ) => {
			const res = UniqueArrItems(state.dateModalChangeUsersForAddressBook.filter(el => el.id !== action.payload))
			state.dateModalChangeUsersForAddressBook = res
		},
		resetDateChangeUsersForAddressBookArr: ( state, action ) => {
			state.dateModalChangeUsersForAddressBook = []
		},
		deleteDateChangeUsersForAddressBookArr: (state, action) => {
			const Id = Number(action.payload)
			const ID = state.dateModalChangeUsersForAddressBook.filter(item => Number(item?.id) !== Id)
			state.dateModalChangeUsersForAddressBook = ID
		},
		resetDateChangeUserAdvertising: ( state, action ) => {
			state.dateModalChangeUserAdvertising = {}
		},
	},
	extraReducers: ( builder ) => {
		builder
			.addMatcher( advertisingApi.endpoints.getAdvertising.matchFulfilled, (
					state,
					action
				) => {
					state.advertising = []
					const tehArr = action.payload.data
					tehArr?.forEach( item => state.advertising = ([ ...state.advertising, item?.ads ]) )
					state.advertising = state.advertising?.filter( item => item?.id )
				}
			)
	}
} )

const {
	actions,
	reducer
} = advertisingSlice
export const {
	setAdvertisingList,
	createNewAdvertisingItem,
	deleteAdvertisingItem,
	editAdvertisingItem,
	showChangeUserAdvertisingModal,
	setDateChangeUserAdvertising,
	resetDateChangeUserAdvertising,
	setDateChangeUsersForAddressBookArr,
	deleteDateChangeUsersForAddressBookArr,
	resetDateChangeUsersForAddressBookArr,
	removeDateChangeUsersForAddressBookArr
} = actions
export default reducer
