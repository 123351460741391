import { createSlice } from "@reduxjs/toolkit"
import { versionsApi } from "../apis/versionsApi"

const initialState = {
	versions: {},
}

const versionsSlice = createSlice({
	name: "versions",
	initialState,
	reducers: {
		createNewVersionsItem: (state, action) => {
			state.versions = [action.payload, ...state.versions]
		},
		editVersionsItem: (state, action) => {
			const index = state.versions.findIndex(
				(term) => {
					return term.id === action.payload.id
				}
			)
			state.versions[index] = action.payload
		},
		deleteVersionsItem: (state, action) => {
			const termId = action.payload
			const termID = state.versions.filter(term => term.id !== termId)
			state.versions = termID
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(versionsApi.endpoints.getVersions.matchFulfilled, (
					state,
					action
				) => {
					state.versions = action.payload
				}
			)
	}
})

const {
	actions,
	reducer
} = versionsSlice
export const {
	createNewVersionsItem,
	deleteVersionsItem,
	editVersionsItem,
} = actions
export default reducer
