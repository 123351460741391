import React, {
	useRef,
	useState
} from 'react'
import { useTranslation } from 'react-i18next';
import { useCSVReader } from 'react-papaparse'

const styles = {
	csvWrapper: {
		width: 'fit-content',
	},
	csvReader: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 10,
	},
	browseFile: {
		color: '#FF6600',
		padding: '4px 8px',
		border: '1px #FF6600 solid',
		borderRadius: '4px',
		background: 'none',
		lineHeight: '20px',
		height: 'fit-content',
		whiteSpace: 'nowrap'
	},
	acceptedFile: {
		lineHeight: 2.5,
		paddingLeft: 10,
		width: '80%',
	},
	errorText: {
		fontSize: '14px',
		color: 'red'
	},
	progressBarBackgroundColor: {
		backgroundColor: 'green',
	},
};

export default function CSVReader({setData}) {
	const { CSVReader } = useCSVReader();
	const [ message, setMessage ] = useState( ' ' );
	const message_view = useRef()
	const {t} = useTranslation()

	return (
		<CSVReader
			onUploadAccepted={ ( results ) => {
				setMessage( '' )
				const errors = results.data.filter( el => {
					if(el.length !== 3) return el
				} );
				if ( !results.errors.length && !errors.length ) {
					message_view.current.style.color = 'green'
					setMessage( `${t('found')} ${ results.data.length } ${t('_m_ready_file')}` )

					const data = results.data.map( el => {
						return { pib: el[0], phone: el[2], email: el[1] }
					} )
					setData( data );
				} else {
					message_view.current.style.color = 'red'
					setData( false );
					setMessage( t('_m_error_occurred') )
				}
			} }
			config={ {
				delimiter: ',',
				skipEmptyLines: true,
				header: false
			} }
		>
			{ ( {
				getRootProps,
				acceptedFile,
				// ProgressBar,
			} ) => (
				<div style={ styles.csvWrapper }>
					<div style={ styles.csvReader }>
						<button
							type='button' { ...getRootProps() }
							style={ styles.browseFile }
						>
							{t('_m_selectFile')}
						</button >
						<div style={ styles.acceptedFile }>
							{ acceptedFile && acceptedFile.name }
						</div >
					</div >
					<span ref={ message_view }>{ message }</span >
				</div >
			) }
		</CSVReader >
	)
}