import React from 'react';
import s from './otherStatistics.module.scss';
import { useGetOtherStatisticsQuery } from '../../../../store/apis/statisticsApi';
import { PieChartComponent } from './pieChartComponent/pieChartComponent';
import { FeedbackStatistics } from './feedbackStatistics/feedbackStatistics';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const OtherStatistics = ()=>{
    
    const { t } = useTranslation()
    const lang = useSelector(store => store.userStore.lang)

    const DASHBOARD_STATISTICS = {
        CATEGORY_STATISTICS: {
            title: t('d_pc'),
            items: [
                {name: "category_views", title: "Категорії"},
                {name: "google_play", title: "Отзывы Google Play Market"},
                {name: "app_store", title: "Отзывы Apple Store"},
            ]
        }
    }
    const {data, isFetching} = useGetOtherStatisticsQuery({types: JSON.stringify(Object.entries(DASHBOARD_STATISTICS)[0][1].items.map(el => el.name)), lang})
    return(
        <div className={s.other_statistics}>
            <div className={s.statistic_row}>
                <p className={s.title}>{t('adp')}</p>
                <PieChartComponent data={data?.category_views} isLoading={isFetching}/>
            </div>
            <div className={s.statistic_row}>
                <p className={s.title}>{t('d_rf')}</p>
                <FeedbackStatistics data_google={data?.google_play} data_apple={data?.app_store} isLoading={isFetching}/>
            </div> 
        </div>
    )
}