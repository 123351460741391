import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import { useSelector } from "react-redux"
import { useGetPagesMutation } from "../../store/apis/pagesApi"
import AddEditPagesItem from "../../components/modalsAddEdit/AddEditPagesItem"
import DeleteModal from "../../components/DeleteModal"
import ControlPanels from "../../components/environmentTabl/ControlPanels"
import AlertModal from "../../components/AlertModal"

const PagesPage = () => {
	const { pages } = useSelector( ( state ) => state.pagesStore )
	const [ getPages, { isLoading: isPagesLoading } ] = useGetPagesMutation()

	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ openEditModal, setOpenEditModal ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )
	const [ errorMessage, setErrorMessage ] = useState( '' )

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}


	const getAllPages = useCallback( async () => {
		try {
			await getPages()
		} catch ( e ) {
			console.log( e )
		}
	}, [getPages] )

	useEffect( () => {
		getAllPages()
	}, [getAllPages] )
	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
		setOpenEditModal( false )
	}
	const hideModalHandler = () => {
		setAddEditModalShow( false )
		setOpenEditModal( false )
		setEditDateForModal( {} )
	}
	const toggleDeleteModal = ( value ) => {
		setDeleteTerm( value )
		setDeleteModalShow( !deleteModalShow )
	}

	const openEditModalHandler = ( value ) => {
		setEditDateForModal( value )
		setAddEditModalShow( true )
		setOpenEditModal( true )
	}
	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}
	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
				content={ errorMessage }
				setErrorMessage={ setErrorMessage }
			/>
		}
			<AddEditPagesItem
				show={ addEditModalShow }
				onHide={ hideModalHandler }
				edit={ openEditModal }
				editDateForModal={ editDateForModal }
			/>
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ deleteTerm }
				fromPage={ 'pages' }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				returnErrorForDel={ returnErrorForDel }
			/>
			<h1 className='terms-title'>
				Pages
			</h1 >
			<ControlPanels
				bigArrElements={ pages }
				showModalHandler={ showModalHandler }
				isLoading={ isPagesLoading }
				openEditModalHandler={ openEditModalHandler }
				toggleDeleteModal={ toggleDeleteModal }
				fromPage={ 'pages' }
				searchTermValue={ searchTermValue }
				loaderSorting={ loaderSorting }
				setLoaderSorting={ setLoaderSorting }
				toggleModalSearch={ toggleModalSearch }
				showModalSearch={ showModalSearch }
				showSearchBeforeDel={ showSearchBeforeDel }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				valueSearchInputHandler={ valueSearchInputHandler }
			/>
		</div >
	)
}
export default PagesPage
