import React from 'react'
import { Form } from "react-bootstrap";

const RbacAdminInfoItemRole = () => {
	return (
		<div className='rbac-wrapper_info'>
			<div className='rbac-wrapper_info-title'>
				Роль і посада
			</div >
			<Form.Group
				className='add-edit-terms_label add-edit-rbac_label'
			>
				Посада
				<select
					className='add-edit-rbac_inp mt-1'
					defaultValue=''
					// onClick={e => setCountShowTerms(Number(e.target.value))}
				>
					<option value='1'>
						Адміністратор
					</option >
					<option value='2'>
						Адміністратор 1
					</option >
					<option value='3'>
						Адміністратор 2
					</option >
					<option value='4'>
						Адміністратор 3
					</option >
				</select >
			</Form.Group >
			<Form.Group
				className='add-edit-terms_label add-edit-rbac_label'
			>
				Роль
				<select
					className='add-edit-rbac_inp mt-1'
					defaultValue=''
					// onClick={e => setCountShowTerms(Number(e.target.value))}
				>
					<option value='1'>
						Super_admin
					</option >
					<option value='2'>
						Super_admin 1
					</option >
					<option value='3'>
						Super_admin 2
					</option >
					<option value='4'>
						Super_admin 3
					</option >
				</select >
			</Form.Group >
		</div >
	)
}

export default RbacAdminInfoItemRole