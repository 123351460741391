export const transformTime = ( time ) => {
	const t = Number( `${ time }.3193` )
	const date = new Date( t )
	const year = date.getFullYear()
	const month = ( "0" + ( date.getMonth() + 1 ) ).slice( -2 )
	const day = ( "0" + date.getDate() ).slice( -2 )
	const hours = date.getHours();
	const minutes = date.getMinutes().toString().padStart(2, '0');

	return `${ day }.${ month }.${ year } ${hours}:${minutes}`
}

export const transformTimeRevers = ( time ) => {
	const t = Number( `${ time }.3193` )

	const date = new Date( t )
	const dataToday = new Date()

	const year1 = date.getFullYear()
	const year2 = dataToday.getFullYear()

	const month1 = ( "0" + ( date.getMonth() + 1 ) ).slice( -2 )
	const month2 = ( "0" + ( dataToday.getMonth() + 1 ) ).slice( -2 )

	const finYear = Math.trunc( ( year2 - year1 ) )
	const finMonth = Math.trunc( Number( month2 ) - Number( month1 ) )

	if ( finYear === 0 ) {
		return `${ finMonth } міс.`
	} else if ( finYear !== 0 ) {
		return `${ finYear } р. і ${ finMonth } міс.`
	}
}


export const transformDate = ( time ) => {
	const t = Number( `${ time }.3193` )
	const date = new Date( t )
	const year = date.getFullYear()
	const month = ( "0" + ( date.getMonth() + 1 ) ).slice( -2 )
	const day = ( "0" + date.getDate() ).slice( -2 )

	return `${ day }.${ month }.${ year }`
}