import React, {
	useEffect,
	useRef,
	useState
} from 'react'
import s from './AddContactsAll.module.scss'
import AlertModal from "../../AlertModal"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const AddContactsAll = ( { name, sendGroup } ) => {

	const text = useRef()
	const button = useRef()

	const [ inputData, setInputData ] = useState()
	const [ error, setError ] = useState( true )
	const navigate = useNavigate()
	const {t} = useTranslation()

	useEffect( () => {
		if ( error ) {
			text.current.classList.add( s.error )
			button.current.style.opacity = 0.5
		} else {
			button.current.style.opacity = 1
			text.current.classList.remove( s.error )
		}
	}, [ error ] )

	function changeText( el ) {
		const lines = el.value.split( '\n' )
		const result = []
		for ( const line of lines ) {
			const values = line.split( ',' ) // Разделяем строку на значения по запятой

			// Создаем объект с полями и добавляем его в результат
			const obj = {}
			for ( let i = 0; i < values.length; i++ ) {
				let field
				if ( i === 0 ) field = 'pib'
				if ( i === 1 ) field = 'email'
				if ( i === 2 ) field = 'phone'
				obj[ `${ field }` ] = values[ i ].trim() // Добавляем значения в поля объекта, удаляем лишние пробелы вокруг
			}
			result.push( obj )
		}
		const error = result.filter( el => {
			if ( !el.pib
				|| el.pib.length < 3
				|| !el.email
				|| el.email.length < 3
				|| !el.phone
				|| el.phone.length < 3
				|| Object.keys( el ).length > 3
			) setError( true )
			else setError( false )
		} )
		setInputData( result )
	}

	async function makeData() {
		if ( error ) return
		const body = {
			name: name,
			users: [],
			contacts: inputData
		}
		sendGroup( body )
	}

	return (
		<div className={ s.content }>
			<AlertModal
				content={t('_m_canEnter1000Contacts')}
				setErrorMessage={ () => {
				} }
				position
			/>
			<h2 >{t('_m_contactsList')}</h2 >
			<span className={ s.notice }>{t('_m_enterContactsList')}</span >
			<textarea
				placeholder='Alex,alex@gmail.com,+380961112233 Enter...'
				ref={ text }
				onChange={ ( ev ) => changeText( ev.target ) }
			>
			</textarea >
			<button
				className='terms-wrapper_addButton'
				ref={ button }
				onClick={ makeData }
			>
					{t('download')}
			</button >
    </div >
	)
}