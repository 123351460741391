import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	PAGES_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const pagesApi = createApi({
	reducerPath: "pagesApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getPages: builder.mutation({
			query: () => (
				{
					url: PAGES_API.GET_PAGES,
					method: "GET",
				})
		}),
		createPages: builder.mutation({
			query: (body) => (
				{
					url: PAGES_API.CREATE_PAGES,
					method: "POST",
					body
				})
		}),
		editPages: builder.mutation({
			query: (body) => (
				{
					url: PAGES_API.EDIT_PAGES,
					method: "POST",
					body
				})
		}),
		deletePages: builder.mutation({
			query: (body) => (
				{
					url: PAGES_API.DELETE_PAGES,
					method: "POST",
					body
				})
		}),
	})
});

export const {
	useGetPagesMutation,
	useCreatePagesMutation,
	useEditPagesMutation,
	useDeletePagesMutation,


} = pagesApi
