import { useEffect } from "react";
import Loader from "../components/Loader";
import { useGetCategoryMutation } from "../store/apis/categoryApi";
import Sidebar from "./Sidebar";
import { useGetSubcategoryMutation } from "../store/apis/subcategoryApi";
import { useGetPriceMutation } from "../store/apis/priceApi";
import { useGetRulesMutation } from "../store/apis/rulesApi";
import { useGetUnitsMeasurementMutation } from "../store/apis/unitsMeasurementApi";
import { useGetUnitGroupsMutation } from "../store/apis/unitGroupsApi";
import { useGetLangsQuery } from "../store/apis/langApi";
import { useGetPermissionsMutation, useGetWarningsMutation } from "../store/apis/usersApi";
import "../../utils/styles/swiper.scss"

const Layout = ({ children }) => {
 
  const {isLoading: isGetLangsLoading} = useGetLangsQuery()
  const [getPermissions, {isLoading: isGetPermissionsLoading}] = useGetPermissionsMutation();
  const [getCategories, { isLoading: isCategoriesLoading }] = useGetCategoryMutation();
  const [getSubCategories, { isLoading: isSubCategoriesLoading }] = useGetSubcategoryMutation();
  const [getPrices, { isLoading: isGetPriceLoading }] = useGetPriceMutation();
  const [getRules, { isLoading: isGetRulesLoading }] = useGetRulesMutation();
  const [getUnitsMeasurement, { isLoading: isGetUnitsLoading }] = useGetUnitsMeasurementMutation();
  const [getUnitGroups, { isLoading: isGetUnitsGroupsLoading }] = useGetUnitGroupsMutation();
  const [getWarning, {isLoading: isGetMenuWarnings}] = useGetWarningsMutation()

  const isLoading =
    isCategoriesLoading ||
    isSubCategoriesLoading ||
    isGetPriceLoading ||
    isGetRulesLoading ||
    isGetUnitsLoading ||
    isGetUnitsGroupsLoading ||
    isGetPermissionsLoading ||
    isGetLangsLoading ||
    isGetMenuWarnings

  useEffect(() => {
    getPermissions();
    getCategories(`&list=null&orderBy=asc&sortingBy=position&isAll=true`);
    getSubCategories({orderBy: '&orderBy=desc', sortingBy: '?sortingBy=position'});
    getPrices();
    getRules();
    getUnitsMeasurement();
    getUnitGroups();
    getWarning();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="appWrapper">
      {isLoading? 
          <div className="app_loader"><Loader/></div>
          :
          <div className="contentBox">
            <div className="contentBox__content">
              <Sidebar />
              {children}
            </div>
          </div>
      }
    </div>
  );
};

export default Layout;
