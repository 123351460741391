export function costСalculation({data, prices}){

    if(!data || !prices) return false

    let mainPrice = prices.default_price_main
    let internalPrice = prices.default_price_internal

    // Category price calculate
    if(data.categories?.length){
        mainPrice = mainPrice + prices.categories_main
        internalPrice = internalPrice + prices.categories_internal
    }

    // Gender price calculate
    if(data.gender && (data.gender?.male || data.gender?.female)){
        mainPrice = mainPrice + prices.gender_main
        internalPrice = internalPrice + prices.gender_internal
    }

    // Age price calculate
    if(data.age?.length){
        mainPrice = mainPrice + prices.age_main
        internalPrice = internalPrice + prices.age_internal
    }

    // Language price calculate
    if(data.langs?.length){
        mainPrice = mainPrice + prices.language_main
        internalPrice = internalPrice + prices.language_internal
    }

    // Location price calculate
    if(data.regions?.length){
        mainPrice = mainPrice + prices.region_main
        internalPrice = internalPrice + prices.region_internal
    }

    return {mainPrice, internalPrice}
}