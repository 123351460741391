import { Form } from "react-bootstrap"
import React from "react"
import { useSelector } from "react-redux"
import { TextTranslator } from "./TranslationText/TextTranslator"

const BlockWithTranslationFieldsTemplates = ({
	values,
	setForm,
	touched,
	handleChange,
	formDateUpdateHandler,
	name
}) => {

	const langs = useSelector(store => store.langStore.langs)
	
	return (
		<div className='add-edit-terms_top'>
			{
				langs?.map((ln, index) => {
					return(
						<Form.Group
							key={index}
							className='add-edit-terms_label'
						>
							{/* <h3 style={{textTransform: 'uppercase'}} className='subscription-create_wrapper-date_block-title'>{`title ${ln.langIndex}`}</h3> */}
							<h3 className='subscription-create_wrapper-date_block-title'>
								{name}<span style={{textTransform: 'uppercase'}}>{` ${ln.langIndex}`}</span>
								{index? null : <TextTranslator values={values} setForm={setForm} fieldName={"name"}/>}
							</h3>
							<Form.Control
								type='text'
								name={`${ln.langIndex}${name}`}
								// onBlur={handleBlur}
								value={values?.[`${ln.langIndex}`]}
								onKeyDown={e => {
									e.key === 'Enter' && e.preventDefault()
								}}
								className={`add-edit-terms_inp  ${touched[`${ln.langIndex}${name}`] ? "is-touch " : ""} `}
								onChange={(e) => {
									setForm(prev => {
										return {...prev, name: {...prev.name, [`${ln.langIndex}`]: e.target.value}}
									})
								}}
							/>
						</Form.Group >
					)
				})
			}
		</div >
	)
}

export default BlockWithTranslationFieldsTemplates