import { useEffect, useRef, useState } from 'react';
import s from './markerItem.module.scss';
import { useTranslation } from 'react-i18next';
import { getMarkerStatus } from '../../../utils/helpers/getMarkerStatus';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { base64ToBlob } from '../../../utils/helpers/base64ToBlob';
import { ReactComponent as AdvertiserLocation } from '../../../utils/icons/AdvertiserLocation.svg'
import { ReactComponent as AdvertiserGlobe } from '../../../utils/icons/AdvertiserGlobe.svg'
import { ReactComponent as AdvertiserPhone } from '../../../utils/icons/AdvertiserPhone.svg'
import { ReactComponent as AdvertiserClock } from '../../../utils/icons/AdvertiserClock.svg'
import { ReactComponent as SvgArrow } from '../../../utils/icons/AdvertiserArrow.svg'
import Loader from '../LoaderContent';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { VerificationMessages } from '../verificationMessagesModal/verificationMessagesModal';
import { useAdvertiserChangeMarkerStatusStatusMutation } from '../../store/apis/advertiserApi';
import AlertModal from '../AlertModal';
import { useGetWarningsMutation } from '../../store/apis/usersApi';


const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const MarkerItem = ({ marker, companyName, companyId }) => {
   
    // ### State
    const [confirmModal, setConfirmModal] = useState(false)
    const [messagesModal, setMessagesModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [changeMarkerStatausQuery, {isLoading: isChangeStatusLoading}] = useAdvertiserChangeMarkerStatusStatusMutation()
    const [getWarning, {isLoading: isGetMenuWarnings}] = useGetWarningsMutation()

    const modalRef = useRef()
    const scheduleRef = useRef()
    const scheduleArrowRef = useRef()

    const {t} = useTranslation()

    const now = new Date();
    const todayIndex = now.getDay();
    const currentDay = daysOfWeek[todayIndex];
    const currentStatus = marker?.schedule?.values? getMarkerStatus(marker?.schedule?.values) : undefined

    // ### Effects

    useEffect(()=>{
        if(modalRef.current){
            setTimeout(()=>{
                modalRef.current.classList.add(s.open)
            },0)
        }
    },[]);

    // ### Functions

    async function handleMarkerSubmit(status, message){
        const response = await changeMarkerStatausQuery({companyId, markerId: marker.id, markerStatus: status, moderationMessage: message.message, markerName: marker.name})
        if(response.error) setErrorMessage(response.error?.data?.message)
        else if(response.data === 'success') setSuccessModal(marker.name)
        getWarning()
        // dispatch(usersApi.util.resetApiState())
    }

    function toggleScheduleBlock(){
        if(scheduleRef.current || scheduleArrowRef.current){
            if(scheduleRef.current.offsetHeight){
                scheduleRef.current.style.cssText = `height: 0px`
                scheduleArrowRef.current.classList.remove(s.active)
            }
            else{
                scheduleRef.current.style.cssText = `height: ${scheduleRef.current.scrollHeight}px`
                scheduleArrowRef.current.classList.add(s.active)
            }
        }
    }

    // ### Views

    const markerImagesView = marker?.images?.map(image => {
        return <SwiperSlide><img src={URL.createObjectURL(base64ToBlob(image.file, image.mimetype))}/></SwiperSlide>
    })

    const scheduleRowsView = Object.entries(marker?.schedule?.values)?.map((el, index) => {
        const currentName = daysOfWeek.find(day => day.toLowerCase() === el[0])
        const currentStart = el[1]?.open?.from?.time
        const currentEnd = el[1]?.open?.to?.time
        return(
            <tr className={s.schedule_row} key={index}>
                <td className={s.schedule_title}>{t(currentName?.toLocaleLowerCase())}:</td>
                {
                    el[1] === true ?
                    <td className={s.schedule_value}>{t('around')}</td>
                    :
                    <td className={s.schedule_value}>{`${currentStart ? currentStart : '-'} - ${currentEnd ? currentEnd : '-'}`}</td>
                }
            </tr>
        )
    })

    return(
        <div className={`${s.modal}  ${marker.moderationStatus === 1? s.active : marker.moderationStatus === 2? s.unactive : s.moderation}`} ref={modalRef}>
            { errorMessage && <AlertModal content={errorMessage} setErrorMessage={setErrorMessage} />}
            <div className={s.image_wrapper}>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={true}
                    modules={[Pagination]}
                    style={{
                        height: 209,
                        "--swiper-pagination-color": "#FF6600",
                        "--swiper-pagination-bullet-inactive-color": "#CECECE",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "6px",
                        "--swiper-pagination-bullet-inactive": "16px",
                        "--swiper-pagination-bullet-horizontal-gap": "6px"
                    }}
                    >
                    {markerImagesView}
                </Swiper>
                <div className={`${s.status_label} ${currentStatus?.isOpen ? s.label_open : s.label_close}`}>{currentStatus?.isOpen ? "Open" : "Close"}</div>
                <div className={s.moderation_wrapper}>
                    { marker.moderationStatus !== 1 ? <div className={s.success_button} onClick={()=>setConfirmModal(marker.name)}>{t('verification_success_mini')}</div> : null}
                    { marker.moderationStatus !== 2 ? <div className={s.reject_button} onClick={()=>setMessagesModal(2)}>{t('reject')}</div> : null}
                </div>
            </div>
            <div className={s.scrolled_content}>
                <span className={s.title}>{marker.name}</span>
                <span className={s.company_name}>{companyName}</span>
                <div className={s.row}>
                    <div className={s.row_icon}><AdvertiserLocation/></div>
                    <span className={s.row_title}>{marker.name}</span>
                </div>
                <div className={s.row}>
                    <div className={s.row_icon}><AdvertiserGlobe/></div>
                    <span className={s.row_title}>{marker.link}</span>
                </div>
                <div className={s.row}>
                    <div className={s.row_icon}><AdvertiserPhone /></div>
                    <span className={s.row_title}>{marker.phone?.fullPhone}</span>
                </div>
                {
                    marker.schedule?.status ?
                    <>
                        <div className={s.hours_row} onClick={toggleScheduleBlock}>
                            <span className={`${s.title} ${currentStatus?.isOpen ? s.open_title : s.close_title}`}><AdvertiserClock />{currentStatus?.isOpen ? t('opened') : t('closed')}</span>
                            {
                                currentStatus?.isOpen === "around" ?
                                <span className={`${s.value} ${s.schedule}`} onClick={toggleScheduleBlock}>{t('around')}<div ref={scheduleArrowRef}><SvgArrow/></div></span>
                                :
                                <span className={`${s.value} ${s.schedule}`} onClick={toggleScheduleBlock}>{`${currentStatus?.isOpen? t('will_close'):t('will_open')} ${currentDay === currentStatus?.nextEvent?.day ? currentStatus?.nextEvent?.time : `${t(currentStatus?.nextEvent?.day)} ${currentStatus?.nextEvent?.time}`}`}<div ref={scheduleArrowRef}><SvgArrow/></div></span>
                            }
                        </div>
                        <div className={s.chedule_block} ref={scheduleRef}>
                            {scheduleRowsView}
                        </div>
                    </>
                    : null
                }
                <div className={s.description_wrapper}>{marker.description}</div>
            </div>
            {confirmModal? <ConfirmModal title={t('verification_title')} text={`${t('verification_confirm')} ${confirmModal}?`} close={()=>setConfirmModal(false)} success={()=>handleMarkerSubmit(1, false)} /> : null}
            {successModal? <ConfirmModal title={t('_a_message')} text={`${successModal} ${t('successfully_updated')}`} close={()=>setSuccessModal(false)} /> : null}
            {messagesModal ? <VerificationMessages type={messagesModal} confirm={handleMarkerSubmit} close={()=>setMessagesModal(false)}/> : null}
            { isChangeStatusLoading || isGetMenuWarnings? <Loader/> : null }
        </div>
    )
}