import React, {
	useRef,
	useState
} from 'react'
import s from './addContactsList.module.scss'
import {
	useNavigate,
	useSearchParams
} from "react-router-dom"
import { AddContactsFiles } from "../../../components/address-book/addContactsFiles/AddContactsFiles"
import { AddContactsAll } from "../../../components/address-book/addContactsAll/AddContactsAll"
import { AddContactsOne } from "../../../components/address-book/AddContactsOne/AddContactsOne"
import {
	addressBookApi,
	useCreateGroupMutation,
	useUpdateGroupUsersMutation
} from "../../../store/apis/addressBookApi"
import { useDispatch } from "react-redux"
import CloseIcon from '../../../../utils/icons/close-classik-icon-violet.svg'
import { AddContactsChangeAll } from '../../../components/address-book/addContactsChangeAll/AddContactsChangeAll'
import { useTranslation } from 'react-i18next'


export const AddContactsList = () => {

	const [ searchParams ] = useSearchParams()
	let book_name = searchParams.get( "name" )
	let book_id = searchParams.get( "id" )
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [ createGroup ] = useCreateGroupMutation()
	const [ updateGroupUsers ] = useUpdateGroupUsersMutation()

	const menu = useRef()
	const {t} = useTranslation()

	const [ menuState, setMenuState ] = useState( 1 )

	function selectMenu( el ) {
		const value = el.getAttribute( 'value' )
		for ( let i = 0; i < menu.current.children.length; i++ ) {
			menu.current.children[ i ].classList.remove( s.active );
		}
		el.classList.add( s.active )
		setMenuState( parseInt( value ) )
	}

	async function sendGroup( body ) {
		if ( book_id ) {
			const update_body = { id: book_id, users: body.users, contacts: body.contacts }
			const response = await updateGroupUsers( update_body )

			if ( response.data.status === 200 ) {
				dispatch( addressBookApi.util.resetApiState() )
				navigate( `/address-book/book?id=${ book_id }` )
			}
		} else {
			const response = await createGroup( body )
			if ( response?.data?.data?.id ) navigate( `/address-book/book?id=${response?.data?.data?.id}` )
		}
	}

	return (
		<div className='terms'>
			<div className='d-flex align-items-center justify-content-between'>
				<h1 className={ `terms-title ${ s.book_name }` }>
				{ `${t('address_book')}: ` }
				<span >
					{ book_name }
				</span >
			</h1 >
			<button
				className='terms-wrapper_addButton rbac-wrapper_backButton mb-0'
				onClick={ () => navigate( -1 ) }
			>
				<img
					src={ CloseIcon }
					alt='close'
				/>
			</button >
			</div>
			<div className={ `terms-wrapper ${ s.terms_wrapper }` }>
				<header >
					<div
						className={ s.menu }
						ref={ menu }
					>
						<span
							className={ `${ s.item } ${ s.active }` }
							value='1'
							onClick={ ( ev ) => selectMenu( ev.target ) }
						>
							{t('_m_download')}
						</span >
						<span
							className={ `${ s.item }` }
							value='2'
							onClick={ ( ev ) => selectMenu( ev.target ) }
						>
							{t('_m_enterManual')}
						</span >
						<span
							className={ s.item }
							value='3'
							onClick={ ( ev ) => selectMenu( ev.target ) }
						>
							{t('_m_oneAtTime')}
						</span >
						<span
							className={ s.item }
							value='4'
							onClick={ (ev) => selectMenu(ev.target) }
						>
							{t('_m_selectUsers')}
						</span >
					</div >
				</header >
				<div className={ s.content }>
					{
						menuState === 1
							?
							<AddContactsFiles
								name={ book_name }
								sendGroup={ sendGroup }
							/>
							:
							null
					}
					{
						menuState === 2
							?
							<AddContactsAll
								name={ book_name }
								sendGroup={ sendGroup }
							/>
							:
							null
					}
					{
						menuState === 3
							?
							<AddContactsOne
								name={ book_name }
								sendGroup={ sendGroup }
							/>
							:
							null
					}
					{
						menuState === 4
							?
							<AddContactsChangeAll
								name={ book_name }
								sendGroup={ sendGroup }
								book_id = {book_id}
							/>
							:
							null
					}
				</div >
			</div >
		</div >
	)
}