import React, {
	useEffect,
	useRef,
	useState
} from 'react'
import s from './AddressBookPage.module.scss'
import FilterIcon from '../../../utils/icons/filter-icon.svg'
import AddIcon from '../../../utils/icons/addPlus.svg'
import { ContactList } from "../../components/address-book/contactList/contactList"
import PaginationCustom from "../../components/PaginationCustom"
import { ModalNewBook } from "../../components/address-book/modalNewBook/modalNewBook"
import {
	useDispatch,
	useSelector
} from "react-redux"
import { useGetGroupsMutation, } from "../../store/apis/addressBookApi"
import LoaderContent from "../../components/LoaderContent"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
	addEndTime,
	transformTimeZone
} from "../../../utils/helpers/TransformDate"
import DeleteModal from '../../components/DeleteModal'
import AlertModal from '../../components/AlertModal'
import { LogoutUserHandler } from '../../../utils/helpers/LoqoutUserHelper'
import { useNavigate } from 'react-router-dom'
import Remove from "../../../utils/icons/delete-red-icon.svg";
import SelectShowRecords from "../../components/SelectShowRecords";
import { useTranslation } from 'react-i18next'


const AddressBookPage = () => {
	const { groups } = useSelector( state => state.addressBookStore )
	const [ currentPage, setCurrentPage ] = useState( 1 )
	const [ showNumber, setShowNumber ] = useState( 20 )
	const [ newBookModal, setNewBookModal ] = useState( false )
	const [ startDate, setStartDate ] = useState( '' )
	const startDateRef = useRef('')
	const [ endDate, setEndDate ] = useState( '' )
	const endDateRef = useRef('')
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const filters_wrapper = useRef()
	const filters = useRef( [] )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const [ getGroups, { isLoading } ] = useGetGroupsMutation()
	function toggleFilters() {
		const current_height = filters_wrapper.current.offsetHeight
		if ( !current_height ) filters_wrapper.current.style.height = `${ filters_wrapper.current.scrollHeight }px`; else filters_wrapper.current.style.height = '0px'
	}

	async function refetch( page ) {
		setCurrentPage( page )
		let end_date = false
		if ( endDateRef.current ) end_date = new Date( endDateRef.current.toISOString() )
		let date;
		if ( startDateRef.current || end_date ) date = {
			start: startDateRef.current ? transformTimeZone( startDateRef.current ) : '',
			end: end_date ? transformTimeZone( addEndTime( end_date ) ) : ''
		}

		const {error} = await getGroups( {
			user: filters.current[ 'id' ].value,
			email: filters.current[ 'email' ].value,
			phone: filters.current[ 'phone' ].value,
			name: filters.current[ 'name' ].value,
			limit: showNumber,
			page: page || 1,
			date: date ? date : ''
		} )

		if ( error?.data?.code === 1 && error?.data?.message === 'Incorrect token' ) LogoutUserHandler( dispatch, navigate )
	}

	useEffect( () => {
		refetch(currentPage)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showNumber] )

	function resetFilters() {
		filters.current[ 'id' ].value = ''
		filters.current[ 'email' ].value = ''
		filters.current[ 'name' ].value = ''
		filters.current[ 'phone' ].value = ''
		setStartDate( '' )
		setEndDate( '' )
		startDateRef.current = ''
		endDateRef.current = ''
		toggleFilters()
		setTimeout(()=>{
			refetch( currentPage )
		},500)
	}

	const toggleDeleteModal = ( value ) => {
		setDeleteTerm( value )
		setDeleteModalShow(!deleteModalShow)
	}
	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}

	return (
		<div className='terms'>
			{ newBookModal ? <ModalNewBook close={ setNewBookModal } /> : null }
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ deleteTerm }
				fromPage={ 'addressBook' }
				returnErrorForDel={ returnErrorForDel }
			/>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<h1 className='terms-title'>
				{t('address_book')}
			</h1 >
			<div className='terms-wrapper'>
				<header className={ s.header }>
					<div className={ s.button_wrapper }>
						<button
							className={ `terms-wrapper_addButton ${ s.add_button }` }
							onClick={ () => setNewBookModal( true ) }
						>
							<img
								src={ AddIcon }
								alt='AddIcon'
							/>
							{t('ab_create')}
						</button >
					</div >
					<div className={ s.row }>
						<div className={ s.left_block }>
							<h3 >{t('_m_listOfAddressBooks')}</h3 >
							<div className={ s.row } onClick={ toggleFilters }>
								<span className={ s.filter_text }>{t('_m_filters')}</span >
								<img
									src={ FilterIcon }
									alt='FilterIcon'
								/>
							</div >
							</div >
							<div className={ s.right_block }>

								<SelectShowRecords setCountShow={setShowNumber}/>

							</div >
					</div >
					<div
						className={ s.filters_wrapper }
						ref={ filters_wrapper }
					>
						<div className={ s.titles }>
							<p >{t('showing')} <span className={ s.number }>{ groups.data?.length }</span > {t('items_from')} <span className={ s.number_all }>{ groups.totalGroups }</span ></p >
							<p className={ s.clear_filters }>{t('clear_filters')}</p >
						</div >
						<div className={ s.filters }>
							<div className={ s.filter }>
								<span >{t('_m_filtersFrom')}</span >
								<DatePicker
									selected={ startDate }
									onChange={ ( date ) => {
										setStartDate( date )
										startDateRef.current = date
									} }
								/>
							</div >
							<div className={ s.filter }>
								<span >{t('_m_filtersTo')}</span >
								<DatePicker
									selected={ endDate }
									onChange={ ( date ) => {
										setEndDate( date )
										endDateRef.current = date
									} }
								/>
							</div >
							<div className={ s.filter }>
								<span >{t('name')}</span >
								<input
									type='text'
									name='name'
									ref={ ( el ) => filters.current[ 'name' ] = el }
								/>
							</div >
							<div className={ s.filter }>
								<span >{t('email')}</span >
								<input
									type='text'
									name='email'
									ref={ ( el ) => filters.current[ 'email' ] = el }
								/>
							</div >
							<div className={ s.filter }>
								<span >ID</span >
								<input
									type='text'
									name='id'
									ref={ ( el ) => filters.current[ 'id' ] = el }
								/>
							</div >
							<div className={ s.filter }>
								<span >{t('phone')}</span >
								<input
									type='text'
									name='phone'
									ref={ ( el ) => filters.current[ 'phone' ] = el }
								/>
							</div >
						</div >
						<div className={ s.buttons_wrapper }>
							<button
								className={ `terms-wrapper_addButton ${ s.add_button }` }
								onClick={ () => refetch( currentPage ) }
							>
								{t('apply')}
							</button >
							<button
								className='template-content_buttons-close btn btn-primary'
								onClick={ resetFilters }
							>
								<img
									src={ Remove }
									alt='remove filter'
								/>
							</button >
						</div >
					</div >
				</header >
				{ isLoading
					?
					<LoaderContent />
					:
					<section className={ s.content_wrapper }>
						{
							groups.data?.length !== 0
							?
							groups.data
							&&
							groups.data?.map( el => (
								<ContactList
									key={ el.id }
									id={ el.id }
									name={ el.name }
									emails={ el.emails }
									contacts={ el.contacts }
									phones={ el.phones }
									date={ el.date }
									item={ el }
									toggleDeleteModal={ toggleDeleteModal }
									validationEmailDate={el.validationEmailDate}
								/>
								)
							)
								:
								<h2>
									{t('_m_not_addressbook')}
								</h2>
						}
					</section >
				}
				<footer
					className={ s.footer }
					style={ { opacity: groups.totalGroups > showNumber ? 1 : 0 } }
				>
					<PaginationCustom
						currentPage={ currentPage }
						setCurrentPage={ refetch }
						itemsCount={ showNumber }
						counterAllItems={ groups.totalGroups }
					/>
				</footer >
			</div >
	</div >
	)
}
export default AddressBookPage;