import React, { useEffect, useRef, useState } from 'react';
import s from './htmlFieldsBlock.module.scss';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { TextTranslator } from '../../TranslationText/TextTranslator';

export const HTMLFieldsBlock = ({title, values, changeValue, setLang}) => {

    const langs = useSelector(store => store.langStore.langs)

    const [activeLang, setActiveLang] = useState(langs?.[0].langIndex)
    const input = useRef()

    // ### EFFECTS
    useEffect(()=>{
        input.current.focus()
    },[activeLang]);

    const langs_view = langs?.map((el, index) => {
        const completed = values?.[el.langIndex]?.length
        return(
            <div 
                key={index}
                className={`${s.lang_item} ${activeLang === el.langIndex? s.active_lang_item : ''} ${completed? s.completed_lang_item : ''}`}
                onClick={()=>{
                    setActiveLang(el.langIndex)
                    if(setLang) setLang(el.langIndex)
                }}
            >
                {el.langIndex}
                <span>{completed? <span>&#10004;</span> : <span>&#10008;</span>}</span>
            </div>
        )
    })

    function setTranslateText(values){
        Object.entries(values).forEach(value => {
            changeValue({[value[0]]: value[1]})
        })
    }

    return(
        <div className={s.wrapper}>
            <header>
                <h3>{title}</h3>
                <div className={s.langs_wrapper}>
                    {langs_view}
                </div>
                <div className={s.translator_wrapper}>
                    <TextTranslator values={values} setForm={setTranslateText}/>
                </div>
            </header>
            <div className={s.input_wrapper}>
                <span>{activeLang}:</span>
                <textarea
                    ref={input}
                    value={values?.[activeLang]?.length? values[activeLang] : ''}
                    className={s.input}
                    rows={10}
                    onChange={(e) => {
                        changeValue({[activeLang]: e.target.value})
                    }}
                ></textarea>
                {/* <Form.Control
                    type='textarea'
                    ref={input}
                    name="textarea"
                    value={values?.[activeLang]?.length? values[activeLang] : ''}
                    onKeyDown={e => {e.key === 'Enter' && e.preventDefault()}}
                    className={s.input}
                    onChange={(e) => {
                        changeValue({[activeLang]: e.target.value})
                    }}
                
                /> */}
            </div>
        </div>
    )
}