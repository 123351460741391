/* eslint-disable no-loop-func */
import React, {
	useEffect,
	useState
} from 'react'
import {
	useDispatch,
	useSelector
} from "react-redux"
import {
	Button,
	Form,
	Modal
} from "react-bootstrap"
import AlertModal from "../AlertModal"
import { Formik } from "formik"
import Loader from "../Loader"
import {
	resetDateChangeUnitGroups,
	setDateChangeUnitGroups,
	showChangeUnitGroupsModal
} from "../../store/slices/unitGroupsSlice"
import ChangeUnitForUnitGroups from "../../pages/UnitGroup/ChangeUnitForUnitGroups"
import { UniqueArrItems } from "../../../utils/helpers/UniqueArrItems"
import {
	useCreateUnitGroupsMutation,
	useEditUnitGroupsMutation,
	useGetUnitGroupsMutation
} from "../../store/apis/unitGroupsApi"
import { ARE_COMMON } from "../../../utils/textsContent";
import { useTranslation } from 'react-i18next'

const initialState = {
	name: '',
	unitlist: [],
}
const AddEditUnitGroupsItem = ({
	show,
	onHide,
	edit,
	editDateForModal
}) => {
	const [form, setForm] = useState(edit ? editDateForModal : initialState)
	const [changeUnitForUnitGroups, setChangeUnitForUnitGroups] = useState([])
	const [valueAmount, setValueAmount] = useState({})
	const [unitArrWithDBFull, setUnitArrWithDBFull] = useState([])
	const {showModalChangeUnitGroups, dateModalChangeUnitGroups} = useSelector(state => state.unitGroupsStore)
	const [createUnitGroups, {isLoading: isCreateUnitGroupsMeasurementLoading}] = useCreateUnitGroupsMutation()
	const [editUnitGroups, {isLoading: isEditUnitGroupsLoading}] = useEditUnitGroupsMutation()
	const [getUnitGroups, {isLoading: isUnitGroupsLoading}] = useGetUnitGroupsMutation()
	const [errorMessage, setErrorMessage] = useState('')
	const dispatch = useDispatch()
	const isLoading = isCreateUnitGroupsMeasurementLoading || isEditUnitGroupsLoading || isUnitGroupsLoading

	const unitArrWithDB = useSelector(store => store.unitsMeasurementStore.unitsMeasurement)
	const {t, i18n} = useTranslation()
	
	const filterItemHelper = (arr, id) => arr?.filter(item => item.id === Number(id))
	useEffect(()=>{
	},[showModalChangeUnitGroups]);
	useEffect(() => {
		const getDateUnitList = async () => {
			setUnitArrWithDBFull(unitArrWithDB)

			let teh = []
			for (let i = 0; i < dateModalChangeUnitGroups?.length; i++) {
				teh = [...teh, ...filterItemHelper(unitArrWithDB, dateModalChangeUnitGroups[i])]
			}
			!edit && setChangeUnitForUnitGroups(UniqueArrItems(teh))
		}
		getDateUnitList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateModalChangeUnitGroups, showModalChangeUnitGroups])

	useEffect(() => {
		if (edit) {
			const emptyOrder = Object.keys(valueAmount).length === 0
			emptyOrder && setForm(editDateForModal)

			let tehArrUnitIDGroupsEdit = []

			form?.unitlist?.forEach(item => {
				if (item !== undefined) {
					dispatch(setDateChangeUnitGroups(String(item?.id)))
					tehArrUnitIDGroupsEdit = [...tehArrUnitIDGroupsEdit, String(item?.id)]
				}
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit, form?.unitlist, showModalChangeUnitGroups])

	useEffect(() => {
		let tehArrUnitGroupsEdit = []
		let teh = []

		for (let i = 0; i < dateModalChangeUnitGroups?.length; i++) {
			teh = [...teh, ...filterItemHelper(unitArrWithDBFull, dateModalChangeUnitGroups[i])]
		}
		const uniqArr = UniqueArrItems(teh)

		for (let i = 0; i < form?.unitlist?.length; i++) {
			uniqArr?.forEach(item => {
				if (Number(item?.id) === Number(form?.unitlist[i]?.id)) {
					tehArrUnitGroupsEdit = [...tehArrUnitGroupsEdit, {
						...item,
						minValueAmount: form?.unitlist[i]?.minValueAmount
					}]
				} else if (uniqArr?.length !== form?.unitlist?.length) {
					const objLast = uniqArr?.at(-1)
					tehArrUnitGroupsEdit = [...tehArrUnitGroupsEdit, {
						...objLast,
						minValueAmount: ''
					}]
				}
			})
		}

		const result = unique(tehArrUnitGroupsEdit, n => n.id)
		setChangeUnitForUnitGroups(result)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit, dateModalChangeUnitGroups])

	const unique = (arr, key) => Object.values(Object.fromEntries(arr.map(n => [key(n), n])))
	const formDateUpdateHandler = (opt) => setForm({...form, ...opt})

	useEffect(() => {
		const w = form?.unitlist &&
			[...form?.unitlist, valueAmount].filter((v, i, a) => a.findLastIndex(v2 => (v2.id === v.id)) ===
				i)?.filter(r => r?.id)
		setForm({
			...form,
			unitlist: w
		})

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueAmount])

	const handleSubmit = async (values) => {
		const formDate = {
			name: values?.name || form?.name,
			unitlist: [...form?.unitlist]
		}
		try {
			if (edit) {

				if (values?.name === editDateForModal?.name) delete formDate?.name

				const {error} = await editUnitGroups({
					...formDate,
					id: editDateForModal.id
				})
				!error && getUnitGroups()
				// !error && dispatch( editUnitGroupsItem( {
				// 	...formDate,
				// 	id: editDateForModal.id
				// } ) )
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()
			} else {
				const {
					error
				} = await createUnitGroups(formDate)
				!error && getUnitGroups()
				
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()
			}
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}
	}

	const clearFormHideModal = () => {
		onHide()
		setForm(initialState)
		setValueAmount({})
		dispatch(resetDateChangeUnitGroups())
	}

	// const deleteUnitItem = (id) => {
	// 	const w = form?.unitlist && form?.unitlist?.filter(it => it?.id !== id)
	// 	setForm({
	// 		...form,
	// 		unitlist: w
	// 	})
	// }
	return (
		<Modal
			show={show}
			onHide={clearFormHideModal}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			className='add-edit-terms_modal'
			style={{zIndex: '99999'}}
		>
			{showModalChangeUnitGroups && <ChangeUnitForUnitGroups />}
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<Formik
				validateOnChange
				initialValues={{
					name: form.name || editDateForModal?.name,
					unitlist: [form?.unitlist]
				}}
				onSubmit={handleSubmit}
				enableReinitialize
			>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				isValid,
				handleSubmit,
				dirty
			}) => (<>
				<Modal.Header closeButton>
					<Modal.Title
						id='contained-modal-title-vcenter'
						className='add-edit-terms_title'
					>
						{edit ? t('_c_editOfGroupOfMeasurementUnits') : t('_c_creationOfGroupOfMeasurementUnits')}
					</Modal.Title >
				</Modal.Header >
				<Modal.Body >
					<Form
						className='add-edit-terms'
						onSubmit={handleSubmit}
					>
						<div className='add-edit-terms_top'>
							<Form.Group
								className='add-edit-terms_label w-100'
							>
								{t('title')}
								<Form.Control
									type='text'
									name='name'
									defaultValue={values.name}
									onKeyDown={e => {
										e.key === 'Enter' && e.preventDefault()
									}}
									className={`add-edit-terms_center-inp is-touch`}
									onChange={(e) => {
										handleChange(e)
										formDateUpdateHandler({
											[e.target.name]: e.target.value
										})
									}}
								/>
							</Form.Group >
						</div >
						<Button
							type='button'
							className='add-edit-terms_save d-inline-flex mt-3'
							style={{
								maxHeight: '33px',
								display: 'flex',
								alignItems: 'center'
							}}
							onClick={e => {
								e.stopPropagation()
								dispatch(showChangeUnitGroupsModal(true))
							}}
						>
							{t('add')}
						</Button >
							
						<ul className='w-100'>
							{
								changeUnitForUnitGroups
								&& changeUnitForUnitGroups?.map((unit, index) => (
									<li
										key={unit?.id + index + 'q'}
										className='d-flex align-items-center w-100 justify-content-start'
									>
										<p className='pe-2'>{`${index + 1}.  `} </p >
										<p className='mr-2 w-50'> {unit?.term?.[i18n.language]}</p >
										<Form.Group
											className='add-edit-terms_label w-50 d-flex align-items-center'
										>
											<Form.Control
												type='number'
												name={unit?.id}
												defaultValue={values?.minValueAmount || unit?.minValueAmount}
												onKeyDown={e => {
													e.key === 'Enter' && e.preventDefault()
												}}
												className={`add-edit-terms_center-inp is-touch`}
												onChange={(e) => {
													handleChange(e)
													setValueAmount({
														id: unit?.id,
														minValueAmount: (e.target.value)
													})
												}}
											/>
									</Form.Group >
								</li >))}
						</ul >
					</Form >
				</Modal.Body >
				<Modal.Footer >
					<Button
						type='button'
						className='add-edit-terms_close'
						onClick={clearFormHideModal}
					>
						{t('cancel')}
					</Button >
					<Button
						type='submit'
						className='add-edit-terms_save d-inline-flex'
						style={{
							maxHeight: '33px',
							display: 'flex',
							alignItems: 'center'
						}}
						disabled={form?.name === "" || form?.unitlist?.length === 0}
						onClick={handleSubmit}
					>
						{isLoading ? <Loader /> : t('save')}
					</Button >
				</Modal.Footer >
			</>)}
		</Formik >
	</Modal >
	)
}
export default AddEditUnitGroupsItem