import React, { useState } from 'react';
import ControlPanelsV2 from '../../components/environmentTabl/ControlPanelsV2';
import { useNavigate } from 'react-router-dom';
import { CreateDealsMessages } from './CreateDealsMessages/CreateDealsMessages';
import { useGetDealsButtonsQuery } from '../../store/apis/dealsApi';
import { ReactComponent as EditIcon } from '../../../utils/icons/edit-pencil.svg'
import { ReactComponent as DeleteIcon } from '../../../utils/icons/delete-icon.svg'
import DeleteModal from '../../components/DeleteModal';
import { useTranslation } from 'react-i18next';

export const DealsMessages = ({ isModal, modalType, changedItems, setItems, close }) => {

	const {t, i18n} = useTranslation()

	const buttons_types = [
		{title: t('all'), value: 2},
		{title: t('salesman'), value: 0},
		{title: t('buyer'), value: 1}
	]

	const DEALS_BUTTONS_TYPES = [
        {type: 1, title: t('confirm')},
        {type: 2, title: t('reject')},
        {type: 3, title: t('pay')},
        {type: 4, title: t('track_p')},
        {type: 5, title: t('track_t')},
        {type: 6, title: t('add_ttn')},
        {type: 7, title: t('complete_deal')},
        {type: 8, title: t('write_review')},
        {type: 9, title: t('arrival_time')},
        {type: 10, title: t('add_your_price')},
    ]
    
    const DEALS_BUTTONS_COLORS = [
        {type: 1, title: 'Accept'},
        {type: 2, title: 'Cancel'},
        {type: 3, title: 'Info'}
    ]

    const [showAddModal, setShowAddModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [query, setQuery] = useState({search: undefined, type: undefined})
	const [activeButton, setActiveButton] = useState(buttons_types[0].value)
    const navigate = useNavigate()

    const {data: buttons, isLoading} = useGetDealsButtonsQuery(query)

    const table_columns = [isModal? '' : "ID", t("title"), t('type'), t('color'), ""]

    const table_rows = buttons?.map(el => {
		return [
			{type: isModal? modalType === "checkbox"? 'checkbox' : 'radio' : "default", value: el.id},
			{type: "default", value: el.title?.title?.[i18n.language]},
			{type: "default", value: DEALS_BUTTONS_TYPES.find(type => type.type === el.type)?.title},
			
			{type: "default", value: DEALS_BUTTONS_COLORS.find(color => color.type === el.color)?.title},
			
			{type: "icons", value: [<EditIcon onClick={()=>navigate(`/deals/messages/create?id=${el.id}`)}/>, <DeleteIcon onClick={()=>setShowDeleteModal(el.id)}/>]}
		]
	})


	function setButton(value){
		setQuery(prev => {
			return {...prev, type: value === 2? undefined : value.toString()}
		})
		setActiveButton(value)
	}

    // if(isLoading) return <Loader/>
    
    return(
        <div className='terms'>
			<>
				<h1 className='terms-title'>{t('deals_messages_title')}</h1 >
			</>
            <DeleteModal
				show={ showDeleteModal }
				onHide={ setShowDeleteModal }
				value={ showDeleteModal }
				fromPage={ 'dealsbuttons' }
			/>
            <ControlPanelsV2 
					rows={table_rows} 
					columns={table_columns}
					buttons={buttons_types}
					setButton={setButton}
					activeButton={activeButton}
					totalCount={buttons?.length} 
					setShowAddModal={()=> isModal? setShowAddModal(true) : navigate('/deals/messages/create')}
					showAddModal={showAddModal}
					addElementComponent = {<CreateDealsMessages isModal={true} close={setShowAddModal}/>}
					isLoading={isLoading}
					changeItem={()=>{}}
					isModal={isModal}
					query={query}
					setQuery={setQuery}
					selectedItems={[]}
					setItemsFromModal={()=>{}}
				/>
		</div >
    )
}