import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import { useGetTermsMutation } from '../../store/apis/termsApi'
import { useDispatch } from 'react-redux'
import {
	setUnitsMeasurementList
} from '../../store/slices/unitsMeasurementSlice'
import {
	getUnitGroupsList
} from '../../store/slices/unitGroupsSlice'
import {
	getTermsList
} from '../../store/slices/termsSlice'
import { showChangeUnitPriceModal } from '../../store/slices/priceSlice'
import { arrayBreakdownHelper } from '../../../utils/helpers/ArrayBreakdownHelper'
import { searchItemsHelper } from '../../../utils/helpers/SearchItemsHelper'
import AddButton from './AddButton'
import SearchItemsInput from '../SearchItemsInput'
import { Form } from 'react-bootstrap'
import PaginationCustom from '../PaginationCustom'
import LoaderContent from '../LoaderContent'
import AdvertisingTabl from '../../pages/AdvertisingPage/AdvertisingTabl'
import { ARE_COMMON } from "../../../utils/textsContent";
import SelectShowRecords from "../SelectShowRecords";

const ControlPanelsForAdvertising = ( {
	bigArrElements,
	showModalHandler,
	isLoading,
	openEditModalHandler,
	toggleDeleteModal,
	showModalAddUnitHandler,
	fromPage,
	addEditModalShow,
	searchTermValue,
	loaderSorting,
	setLoaderSorting,
	toggleModalSearch,
	showModalSearch,
	showSearchBeforeDel,
	setShowSearchBeforeDel,
	valueSearchInputHandler,
	setShowModalPrice,
	setShowModalAmount
} ) => {
	const [ smallAllArrTerms, setSmallAllArrTerms ] = useState( [] )
	const [ showSmallTermArr, setShowSmallTermArr ] = useState( [] )
	const [ countShowTerms, setCountShowTerms ] = useState( 20 )
	const [ currentPage, setCurrentPage ] = useState( 1 )
	const [ countShowPage, setCountShowPage ] = useState( 0 )
	const [ counterAllItems, setCounterAllItems ] = useState( 0 )
	const [ showPaginationBoard, setShowPaginationBoard ] = useState( true )

	const dispatch = useDispatch()

	const isLoadingReq = false

	const getAllUnitsMeasurement = useCallback( async () => {
			try {

			} catch ( e ) {
				console.log( e )
			}
		},
		[ addEditModalShow ]
	)

	// const getLists = async () => {
	// 	const { unitlist } = await db.unitlist.get( 'unitlist' )
	// 	dispatch( setUnitsMeasurementList( unitlist ) )

	// 	const { unitgroups } = await db.unitgroups.get( 'unitgroups' )
	// 	dispatch( getUnitGroupsList( unitgroups ) )

	// 	const { terms } = await db.terms.get( 'terms' )
	// 	dispatch( getTermsList( terms ) )
	// }

	useEffect( () => {
		getAllUnitsMeasurement()
	}, [ addEditModalShow, fromPage === 'price' ] )


	const closeModalChangeUnitForPrice = () => {
		dispatch( showChangeUnitPriceModal( false ) )
	}

	useEffect( () => {
		const teh = arrayBreakdownHelper( bigArrElements, countShowTerms )
		setSmallAllArrTerms( [ [], ...teh ] )
		setCountShowPage( teh?.length )
		setShowSmallTermArr( teh[ 0 ] )
	}, [ bigArrElements, countShowTerms, fromPage ] )

	useEffect( () => {
	}, [ fromPage ] )

	useEffect( () => {
		showNextPagePagination()
	}, [ currentPage ] )

	const showNextPagePagination = () => {
		setShowSmallTermArr( smallAllArrTerms[ currentPage ] )
	}

	const searchHandler = ( e ) => {
		setLoaderSorting( true )
		if ( fromPage === 'advertising' ) {
			const itemsDate = searchItemsHelper( bigArrElements, 'advertising', searchTermValue )
			setShowSmallTermArr( itemsDate )
			setCounterAllItems( itemsDate?.length )
		}
		timerLoaderHandler()
		setShowPaginationBoard( false )
	}

	const clearSearchHandler = () => {
		toggleModalSearch( false )
		setLoaderSorting( true )
		valueSearchInputHandler( '' )
		const teh = arrayBreakdownHelper( bigArrElements,
			countShowTerms
		)
		setSmallAllArrTerms( [ [], ...teh ] )
		setShowSmallTermArr( teh[ 0 ] )
		setCounterAllItems( bigArrElements?.length )
		setCurrentPage( 1 )
		timerLoaderHandler()
		setShowPaginationBoard( true )
	}

	const sortedForColumn = ( column ) => {
		setLoaderSorting( true )
		if ( column === 'id' ) {
			const te = [ ...bigArrElements ].sort( ( a, b ) => a.id > b.id ? 1 : -1 )
			const teh = arrayBreakdownHelper( te, countShowTerms )
			setSmallAllArrTerms( [ [], ...teh ] )
			setShowSmallTermArr( teh[ 0 ] )
			setCurrentPage( 1 )
		}else if ( column === 'advertising' ) {
			const te = [ ...bigArrElements ].sort( ( a, b ) => a.title > b.title ? 1 : -1 )
			const teh = arrayBreakdownHelper( te, countShowTerms )
			setSmallAllArrTerms( [ [], ...teh ] )
			setShowSmallTermArr( teh[0] )
			setCurrentPage( 1 )
		}

		timerLoaderHandler()
	}
	const timerLoaderHandler = () => {
		setTimeout( () => setLoaderSorting( false ), 1000 )
	}
	useEffect( () => {
		setCounterAllItems( bigArrElements?.length )
	}, [ bigArrElements ] )

	useEffect( () => {
		if ( searchTermValue !== '' && showModalSearch ) {
			showModalSearch && searchHandler()
			setShowSearchBeforeDel( false )
		}
		if ( searchTermValue === '' && !showModalSearch ) {
			clearSearchHandler()
		}
	}, [ showModalSearch, showSearchBeforeDel ] )

	return (
		<div className='terms-wrapper'>
			{
				(
					fromPage === 'advertising'
				) && <AddButton
					title={ `${ARE_COMMON?.ADD}` }
					handler={ showModalHandler }
				/>
			}
			<div className='d-flex align-items-center'>
				{/*{*/ }
				{/*	(*/ }
				{/*		fromPage === 'advertising'*/ }
				{/*	)*/ }
				{/*	&&*/ }
				{/*	<AddButton*/ }
				{/*		title={ 'Створити ' }*/ }
				{/*		handler={ showModalAddUnitHandler }*/ }
				{/*	/>*/ }
				{/*}*/ }
				{/*{*/ }
				{/*	(*/ }
				{/*		fromPage === 'advertising'*/ }
				{/*	) && <AddButton*/ }
				{/*		title={ `${ARE_COMMON?.ADD}` }*/ }
				{/*		handler={ closeModalChangeUnitForPrice }*/ }
				{/*	/>*/ }
				{/*}*/ }
			</div >
			<div className='terms-wrapper_search'>

				<SearchItemsInput
					toggleModalSearch={ toggleModalSearch }
					searchTermValue={ searchTermValue }
					valueSearchInputHandler={ valueSearchInputHandler }
					searchHandler={ searchHandler }
					clearSearchHandler={ clearSearchHandler }
				/>

				<SelectShowRecords setCountShow={setCountShowTerms} />
			</div >
			{
				countShowPage > 1
				&&
				showPaginationBoard && <PaginationCustom
					itemsCount={ countShowPage }
					currentPage={ currentPage }
					setCurrentPage={ setCurrentPage }
					alwaysShown={ showPaginationBoard }
				/>
			}
			{
				isLoading || isLoadingReq
					?
					<LoaderContent />
					:
					<AdvertisingTabl
						countShowPage={ counterAllItems }
						setOpenEditModal={ openEditModalHandler }
						toggleDeleteModal={ toggleDeleteModal }
						sortedForColumn={ sortedForColumn }
						countShowTerms={ countShowTerms }
						showSmallTermArr={ showSmallTermArr }
						fromPage={ fromPage }
					/>
			}
		</div >
	)
}

export default ControlPanelsForAdvertising