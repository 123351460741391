import { ARE_COMMON } from "../textsContent";

export const FilteredFieldTypeForChangeKey = (arrFieldsType, changeValue) => {
	let update = []
	arrFieldsType?.forEach(item => {
		if (changeValue === 'content') {
			update = [
				// {value: 1, name: `${ARE_COMMON?.INT}`},
				// {value: 2, name: `${ARE_COMMON?.SELECT}`},
				// {value: 3, name: `${ARE_COMMON?.RADIO}`},
				// {value: 4, name: `${ARE_COMMON?.STRING}`},
				// {value: 5, name: `${ARE_COMMON?.BOOL}`},
				// {value: 10, name: 'Date(timestamp)'},
				// {value: 11, name: 'Date range from - to'},
				// {value: 12, name: 'Range from - to'}

				{
					value: 1,
					name: `${ARE_COMMON?.INT}`
				},
				{
					value: 2,
					name: `${ARE_COMMON?.SELECT}`
				},
				{
					value: 3,
					name: `${ARE_COMMON?.RADIO}`
				},
				{
					value: 4,
					name: `${ARE_COMMON?.STRING}`
				},
				{
					value: 5,
					name: `${ARE_COMMON?.BOOL}`
				},
				{
					value: 6,
					name: `${ARE_COMMON?.DIMENSIONS}`
				},
				{
					value: 7,
					name: `${ARE_COMMON?.ADDRESS}`
				},
				{
					value: 8,
					name: `${ARE_COMMON?.IMAGE}`
				},
				{
					value: 9,
					name: `${ARE_COMMON?.TITLE}`
				},
				{
					value: 11,
					name: 'Date(timestamp)'
				},
				{
					value: 12,
					name: 'Date range from - to'
				},
				{
					value: 101,
					name: 'Time range from - to'
				},
				{
					value: 13,
					name: 'Range from - to'
				},
			]
		} else if (changeValue ===
			'vendorCode' ||
			changeValue ===
			'produceYear' ||
			changeValue ===
			'registrationNumber' ||
			changeValue ===
			'engineVolume' ||
			changeValue ===
			'owner' ||
			changeValue ===
			'VIN') {
			update = [{value: 4, name: `${ARE_COMMON?.STRING}`}]
		} else if (changeValue ===
			'grossWeight' ||
			changeValue ===
			'volume' ||
			changeValue ===
			'minPrice' ||
			changeValue ===
			'minAmount' ||
			changeValue ===
			'maxWeight' ||
			changeValue ===
			'amount' ||
			changeValue ===
			'grossIn') {
			update = [{value: 1, name: `${ARE_COMMON?.INT}`}]
		} else if (changeValue === 'transportSize' ||
			changeValue === 'bodySize' ||
			changeValue === 'grossVolume') {
			update = [{value: 6, name: `${ARE_COMMON?.DIMENSIONS}`}]
		} else if (changeValue ===
			'mark' ||
			changeValue ===
			'model' ||
			changeValue ===
			'transportCategory' ||
			changeValue ===
			'bodyType' ||
			changeValue ===
			'color') {
			update = [{value: 2, name: `${ARE_COMMON?.SELECT}`}]
		}
	})

	return update
}