import React, { useEffect, useState } from 'react';
import s from './createDelivery.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TextFieldsBlock } from '../../../components/environmentTabl/TextFieldsBlock/TextFieldsBlock';
import { useCreateDeliveryMutation, useGetDeliveryMutation, useUpdateDeliveryMutation } from '../../../store/apis/deliveryApi';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import AlertModal from '../../../components/AlertModal';
import { DELIVERY_TYPES } from '../../../../utils/constants';
import Loader from '../../../components/Loader.js'
import LoaderContent from '../../../components/LoaderContent.js'
import { useTranslation } from 'react-i18next';

const initialState = {
    name: {},
    title: {},
    subtitle: {},
    description: {},
    type: DELIVERY_TYPES.VARIANT1
}

export const CreateDelivery = ({isModal, close})=>{

    const [form, setForm] = useState(initialState)
    const [errorMessage, setErrorMessage] = useState('')

    const [searchParams] = useSearchParams()
    const deliveryId = searchParams.get('id')
    const langs = useSelector(store => store.langStore.langs)
    const {t} = useTranslation()

    const navigate = useNavigate()

    const [createDeliveryQuery, {isLoading: isCreateDeliveryLoading}] = useCreateDeliveryMutation()
    const [updateDeliveryQuery, {isLoading: isUpdateDeliveryQuery}] = useUpdateDeliveryMutation()
    const [getDeliveryQuery, {isLoading: isGetDeliveryQuery}] = useGetDeliveryMutation()

    useEffect(()=>{
        if(deliveryId && deliveryId.length){
            getDelivery(deliveryId)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deliveryId]);

    async function getDelivery(id){
        const response = await getDeliveryQuery(id)
        if(response.error){
            setErrorMessage(response.error?.data?.message)
        }
        else{
            setForm(response.data)
        }
    }

    function changeDeliveryType(value){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState.type = value
            return newState
        })
    }

    async function createDelivery(){
        let error = false

        if(!Object.entries(form.name) || Object.entries(form.name)?.length < langs.length) error = 'Будь ласка, заповнiть "Назва"'
        Object.entries(form.name)?.forEach(el => {
            if(!el[1] || !el[1].length) error = 'Будь ласка, заповнiть "Назва"'
        })

        if(!Object.entries(form.title) || Object.entries(form.title)?.length < langs.length) error = 'Будь ласка, заповнiть "Заголовок"'
        Object.entries(form.title)?.forEach(el => {
            if(!el[1] || !el[1].length) error = 'Будь ласка, заповнiть "Заголовок"'
        })

        if(!Object.entries(form.subtitle) || Object.entries(form.subtitle)?.length < langs.length) error = 'Будь ласка, заповнiть "Пiдзаголовок"'
        Object.entries(form.subtitle)?.forEach(el => {
            if(!el[1] || !el[1].length) error = 'Будь ласка, заповнiть "Пiдзаголовок"'
        })

        if(!Object.entries(form.description) || Object.entries(form.description)?.length < langs.length) error = 'Будь ласка, заповнiть "Опис"'
        Object.entries(form.description)?.forEach(el => {
            if(!el[1] || !el[1].length) error = 'Будь ласка, заповнiть "Опис"'
        })

        if(!form.type || !form.type.length) error = 'Будь ласка, заповнiть "Тип доставки"'

        if(error){
            setErrorMessage(error)
            return
        }

        let response
        if(deliveryId && deliveryId.length){
            response = await updateDeliveryQuery(form)
        }
        else response = await createDeliveryQuery(form)

        if(response.error?.data?.message) setErrorMessage(response.error?.data?.message)
        else if(response.data?.message === 'Success'){
            if(isModal) close()
            else navigate('/delivery')
        }
    }

    return(
        <div className={`terms ${isModal? s.modal_type : ''}`}>
			<h1 className='terms-title'>{deliveryId? t('cd_edit_delivery') : t('cd_create_delivery')}</h1 >
            <div className='terms-wrapper'>

                <div className={s.page_item}>
                    <TextFieldsBlock 
                        title={t('cd_add_title')}
                        values={form.name}
                        changeValue={(value)=>{
                            setForm(prev => {
                                return {...prev, name: {...prev.name, ...value}}
                            })
                        }}
                    />
                </div>

                <div className={s.page_item}>
                    <TextFieldsBlock 
                        title={t('cd_add_header')}
                        values={form.title}
                        changeValue={(value)=>{
                            setForm(prev => {
                                return {...prev, title: {...prev.title, ...value}}
                            })
                        }}
                    />
                </div>

                <div className={s.page_item}>
                    <TextFieldsBlock 
                        title={t('cd_add_subheader')}
                        values={form.subtitle}
                        changeValue={(value)=>{
                            setForm(prev => {
                                return {...prev, subtitle: {...prev.subtitle, ...value}}
                            })
                        }}
                    />
                </div>   

                <div className={s.page_item}>
                    <TextFieldsBlock 
                        title={t('description')}
                        values={form.description}
                        changeValue={(value)=>{
                            setForm(prev => {
                                return {...prev, description: {...prev.description, ...value}}
                            })
                        }}
                    />
                </div>   

                <div className={`${s.page_item} ${s.last_page_item}`}>
                    <h3>{t('cd_add_deliveryType')}</h3>
                    <Form.Select
                        onChange={(e) => {changeDeliveryType(e.target.value)}}
                        value={form?.type}
                    >
                        <option value="variant1">variant1</option>
                        <option value="variant2">variant2</option>
                    </Form.Select>
                </div>
                
                <footer className={s.footer}>
                    <button
                        type='submit'
                        className='template-content_buttons-save btn btn-primary'
                        disabled={isCreateDeliveryLoading || isUpdateDeliveryQuery}
                        onClick={createDelivery}
                    >
                        {isCreateDeliveryLoading ? <Loader /> : t('create')}
                    </button >
                    <button onClick={()=>isModal? close() : navigate('/delivery')} className='template-content_buttons-close btn btn-primary'>
                        {t('back')}
                    </button >
                </footer>
            </div>
            {
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
            {isGetDeliveryQuery? <LoaderContent/> : null}
		</div >
    )
}