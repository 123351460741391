import { createSlice } from "@reduxjs/toolkit"
import { priceApi } from "../apis/priceApi"
import { UniqueArrItems } from "../../../utils/helpers/UniqueArrItems";

const initialState = {
	price: [],
	showModalChangeUnitPrice: false,
	dateModalChangeUnitPrice: '',
	dateModalChangeUnitPriceArr: [],
	showModalSubChangeUnitPrice: false,
}

const priceSlice = createSlice({
	name: "price",
	initialState,
	reducers: {
		getPriceList: (state, action) => {
			state.price = action.payload
		},
		createNewPriceItem: (state, action) => {
			state.price = [action.payload, ...state.price]
		},
		editPriceItem: (state, action) => {
			const index = state.price.findIndex(
				(term) => {
					return term.id === action.payload.id
				}
			)
			state.price[index] = action.payload
		},
		deletePriceItem: (state, action) => {
			const termId = action.payload
			const termID = state.price.filter(term => term.id !== termId)
			state.price = termID
		},
		showChangeUnitPriceModal: (state, action) => {
			state.showModalChangeUnitPrice = action.payload
		},
		showModalSubChangeUnitPrice: (state, action) => {
			state.showModalSubChangeUnitPrice = action.payload
		},
		setDateChangeUnitPrice: (state, action) => {
			state.dateModalChangeUnitPrice = action.payload
		},
		resetDateChangeUnitPrice: (state, action) => {
			state.dateModalChangeUnitPrice = {}
		},
		setDateChangeUnitPriceArr: (state, action) => {
			const uniq = UniqueArrItems([...state.dateModalChangeUnitPriceArr,  action.payload])
			state.dateModalChangeUnitPriceArr = uniq
		},
		resetDateChangeUnitPriceArr: (state, action) => {
			state.dateModalChangeUnitPriceArr = []
		},
		deleteDateChangeUnitPriceArr: (state, action) => {
			const Id = Number(action.payload)
			const ID = state.dateModalChangeUnitPriceArr.filter(item => Number(item) !== Id)
			state.dateModalChangeUnitPriceArr = ID
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(priceApi.endpoints.getPrice.matchFulfilled, (
					state,
					action
				) => {
					state.price = action.payload.data
				}
			)
	}
})

const {
	actions,
	reducer
} = priceSlice
export const {
	getPriceList,
	createNewPriceItem,
	deletePriceItem,
	editPriceItem,
	showChangeUnitPriceModal,
	setDateChangeUnitPrice,
	resetDateChangeUnitPrice,
	setDateChangeUnitPriceArr,
	deleteDateChangeUnitPriceArr,
	resetDateChangeUnitPriceArr,
	showModalSubChangeUnitPrice
} = actions
export default reducer
