import { persistReducer } from "redux-persist"
import { configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE
} from "redux-persist/es/constants"
import userSlice from "./slices/userSlice"
import termsSlice from "./slices/termsSlice"
import unitsMeasurementSlice from "./slices/unitsMeasurementSlice"
import { authApi } from "./apis/authApi"
import { termsApi } from "./apis/termsApi"
import { unitsMeasurementApi } from './apis/unitsMeasurementApi'
import priceSlice from "./slices/priceSlice"
import { priceApi } from "./apis/priceApi"
import amountSlice from "./slices/amountSlice"
import { amountApi } from "./apis/amountApi"
import htmlsSlice from "./slices/htmlsSlice"
import { htmlsApi } from "./apis/htmlsApi"
import pagesSlice from "./slices/pagesSlice"
import { pagesApi } from "./apis/pagesApi"
import templateContentSlice from './slices/temlateContentSlice'
import { templateContentApi } from "./apis/temlateContentApi"
import usersSlice from "./slices/usersSlice"
import { usersApi } from "./apis/usersApi"
import noticeSlice from "./slices/noticeSlice"
import { noticeApi } from "./apis/noticeApi"
import versionsSlice from "./slices/versionsSlice"
import { versionsApi } from "./apis/versionsApi"
import categorySlice from "./slices/categorySlice"
import { categoryApi } from "./apis/categoryApi"
import subcategorySlice from "./slices/subcategorySlice"
import { subcategoryApi } from "./apis/subcategoryApi"
import templateSlice from "./slices/templateSlice"
import { templateApi } from "./apis/templateApi"
import recalculationSlice from "./slices/recalculationSlice"
import { recalculationApi } from "./apis/recalculationApi"
import rulesSlice from "./slices/rulesSlice"
import { rulesApi } from "./apis/rulesApi"
import unitGroupsSlice from "./slices/unitGroupsSlice"
import { unitGroupsApi } from "./apis/unitGroupsApi"
import { markApi } from "./apis/markApi"
import markSlice from "./slices/markSlice"
import { modelApi } from "./apis/modelApi"
import modelSlice from "./slices/modelSlice"
import { transportCategoryApi } from "./apis/transportCategoryApi"
import transportCategorySlice from "./slices/transportCategorySlice"
import { bodyTypeApi } from "./apis/bodyTypeApi"
import bodyTypeSlice from "./slices/bodyTypeSlice"
import subscriptionSlice from "./slices/subscriptionSlice"
import { subscriptionApi } from "./apis/subscriptionApi"
import { rbacApi } from './apis/rbacApi'
import advertisingSlice from './slices/advertisingSlice'
import { advertisingApi } from './apis/advertisingApi'
import { addressBookApi } from "./apis/addressBookApi"
import addressBookSlice from './slices/addressBookSlice'
import moderationPaymentSlice from './slices/moderationPaymentSlice'
import { moderationPaymentApi } from './apis/moderationPaymentApi'
import notificationSlice from './slices/notificationSlice'
import statisticsSlice from './slices/statisticsSlice'
import { notificationApi } from './apis/notificationApi'
import { referralLinksApi } from './apis/referralLinksApi'
import { statisticsApi } from "./apis/statisticsApi"
import referralLinksSlice from "./slices/referralLinksSlice"
import { langApi } from "./apis/langApi"
import langSlice from './slices/langSlice'
import { deliveryApi } from "./apis/deliveryApi"
import deliveryStore from './slices/deliverySlice'
import { dealsApi } from "./apis/dealsApi"
import { advertiserApi } from "./apis/advertiserApi"

export const store = configureStore( {
	reducer: {
		userStore: persistReducer( { key: "auth", storage }, userSlice ),
		termsStore: termsSlice,
		unitsMeasurementStore: unitsMeasurementSlice,
		unitGroupsStore: unitGroupsSlice,
		priceStore: priceSlice,
		amountStore: amountSlice,
		htmlsStore: htmlsSlice,
		pagesStore: pagesSlice,
		templateContentStore: templateContentSlice,
		usersStore: usersSlice,
		noticeStore: noticeSlice,
		versionsStore: versionsSlice,
		categoryStore: categorySlice,
		subcategoryStore: subcategorySlice,
		templateStore: templateSlice,
		recalculationStore: recalculationSlice,
		rulesStore: rulesSlice,
		markStore: markSlice,
		modelStore: modelSlice,
		transportCategoryStore: transportCategorySlice,
		bodyTypeStore: bodyTypeSlice,
		subscriptionStore: subscriptionSlice,
		advertisingStore: advertisingSlice,
		moderationPaymentStore: moderationPaymentSlice,
		addressBookStore: addressBookSlice,
		notificationStore: notificationSlice,
		referralLinksStore: referralLinksSlice,
		statisticsStore: statisticsSlice,
		langStore: langSlice,
		deliveryStore: deliveryStore,

		[ authApi.reducerPath ]: authApi.reducer,
		[ termsApi.reducerPath ]: termsApi.reducer,
		[ unitsMeasurementApi.reducerPath ]: unitsMeasurementApi.reducer,
		[ unitGroupsApi.reducerPath ]: unitGroupsApi.reducer,
		[ priceApi.reducerPath ]: priceApi.reducer,
		[ amountApi.reducerPath ]: amountApi.reducer,
		[ htmlsApi.reducerPath ]: htmlsApi.reducer,
		[ pagesApi.reducerPath ]: pagesApi.reducer,
		[ templateContentApi.reducerPath ]: templateContentApi.reducer,
		[ usersApi.reducerPath ]: usersApi.reducer,
		[ noticeApi.reducerPath ]: noticeApi.reducer,
		[ versionsApi.reducerPath ]: versionsApi.reducer,
		[ categoryApi.reducerPath ]: categoryApi.reducer,
		[ subcategoryApi.reducerPath ]: subcategoryApi.reducer,
		[ templateApi.reducerPath ]: templateApi.reducer,
		[ recalculationApi.reducerPath ]: recalculationApi.reducer,
		[ rulesApi.reducerPath ]: rulesApi.reducer,
		[ addressBookApi.reducerPath ]: addressBookApi.reducer,
		[ markApi.reducerPath ]: markApi.reducer,
		[ modelApi.reducerPath ]: modelApi.reducer,
		[ transportCategoryApi.reducerPath ]: transportCategoryApi.reducer,
		[ bodyTypeApi.reducerPath ]: bodyTypeApi.reducer,
		[ subscriptionApi.reducerPath ]: subscriptionApi.reducer,
		[ rbacApi.reducerPath ]: rbacApi.reducer,
		[ advertisingApi.reducerPath ]: advertisingApi.reducer,
		[ moderationPaymentApi.reducerPath ]: moderationPaymentApi.reducer,
		[ notificationApi.reducerPath ]: notificationApi.reducer,
		[ referralLinksApi.reducerPath ]: referralLinksApi.reducer,
		[ statisticsApi.reducerPath ]: statisticsApi.reducer,
		[ langApi.reducerPath ]: langApi.reducer,
		[ deliveryApi.reducerPath ]: deliveryApi.reducer,
		[ dealsApi.reducerPath ]: dealsApi.reducer,
		[ advertiserApi.reducerPath ]: advertiserApi.reducer
	},
	middleware: ( getDefaultMiddleware ) => [
		...getDefaultMiddleware( {
			serializableCheck: {
				ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ]
			}
		} )
			.concat( [
				authApi.middleware,
				termsApi.middleware,
				unitsMeasurementApi.middleware,
				unitGroupsApi.middleware,
				priceApi.middleware,
				amountApi.middleware,
				htmlsApi.middleware,
				pagesApi.middleware,
				templateContentApi.middleware,
				usersApi.middleware,
				noticeApi.middleware,
				versionsApi.middleware,
				categoryApi.middleware,
				subcategoryApi.middleware,
				templateApi.middleware,
				recalculationApi.middleware,
				rulesApi.middleware,
				markApi.middleware,
				modelApi.middleware,
				transportCategoryApi.middleware,
				bodyTypeApi.middleware,
				subscriptionApi.middleware,
				rbacApi.middleware,
				advertisingApi.middleware,
				addressBookApi.middleware,
				moderationPaymentApi.middleware,
				notificationApi.middleware,
				referralLinksApi.middleware,
				statisticsApi.middleware,
				langApi.middleware,
				deliveryApi.middleware,
				dealsApi.middleware,
				advertiserApi.middleware
			] )
	]
} )
