import React from 'react';
import s from './previewEmail.module.scss';
import mobile from '../../../../utils/assets/images/mobile.webp';
import desktop from '../../../../utils/assets/images/desktop.webp';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

export const PreviewEmail = ({data, hide, template, langs})=>{
   
    const [mode, setMode] = useState(false);
    const [lang, setLang] = useState(langs?.[0]?.langIndex)
    const [currentBodybody, setBody] = useState(data.messageTextUa)
    const [title, setTitle] = useState(data.themeTitleUa)
    const iFrame = useRef()

    useEffect(()=>{
            setBody(data[`messageText${lang}`])
            setTitle(data[`themeTitle${lang}`])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[lang]);

    useEffect(()=>{
        if(iFrame.current){
            iFrame.current.width  = iFrame.current.contentWindow.document.body.scrollWidth;
            iFrame.current.height = iFrame.current.contentWindow.document.body.scrollHeight+100;
        }
    },[iFrame.current?.contentWindow?.document?.body?.scrollHeight]);

    let html = template?.replace('$body', currentBodybody)
    html = html?.replace('$title', title)
    html = html?.replace('$footer_text1', footer_text.footer1[lang])
    html = html?.replace('$footer_text2', footer_text.footer2[lang])
    html = html?.replace('$copyrates', footer_text.copyrates[lang])
    html = html?.replace('$unsubscribe', footer_text.unsubscribe[lang])

    return(
        <div className={s.preview_wrapper}>
            <div className={s.lang_bar}>
                {
                    langs?.map((el, index) => (
                        <button
                            key={index}
                            style={{textTransform: 'uppercase'}}
                            type='button'
                            className='template-content_buttons-save btn btn-primary'
                            onClick={()=> setLang(el.langIndex)}
                        >{el.langIndex}</button >
                    ))
                }
            </div>
            <div className={s.modal}>
                {
                    mode?
                    <div className={s.mobile_wrapper}>
                        <img src={mobile} alt="mobile" />
                        <div className={s.email_wrapper}>
                            <iframe srcDoc={html} title="message" name="message" width="100%" frameBorder="0" ref={iFrame}></iframe>
                        </div>
                        <div className={s.title_wrapper}>{title}</div>
                    </div>
                    :
                    <div className={s.desktop_wrapper}>
                        <img src={desktop} alt="desktop" />
                        <div className={s.email_wrapper}>
                            <iframe srcDoc={html} title="message" name="message" width="100%" frameBorder="0" ref={iFrame}></iframe>
                        </div>
                        <div className={s.title_wrapper}>{title}</div>
                    </div>
                }
            </div>
            <div className={s.footer}>
                <button
                    type='button'
                    className='template-content_buttons-save btn btn-primary'
                    onClick={()=> setMode(false)}
                >
                    Desktop
                </button >
                <button
                    type='button'
                    className='template-content_buttons-save btn btn-primary'
                    onClick={()=> setMode(true)}
                >
                    Mobile
                </button >
                <button
                    type='button'
                    className='template-content_buttons-save btn btn-primary'
                    onClick={()=> hide(false)}
                >
                    Закрити
                </button >
            </div>
        </div>
    )
}

const footer_text = {
    footer1: {
        en: "Best regards,",
        ua: "З повагою,",
        ru: "С уважением,",
        fr: "Cordialement,",
        cn: "致敬,",
        th: "ด้วยความเคารพ"
    },
    footer2: {
        en: "TENTAI Marketplace Team",
        ua: "Команда TENTAI Marketplace",
        ru: "Команда TENTAI Marketplace",
        fr: "Équipe de TENTAI Marketplace",
        cn: "TENTAI Marketplace团队",
        th: "ทีม TENTAI Marketplace"
    },
    copyrates: {
        en: "© 2024 Tentai - Book, rent, buy, sell with just one click!",
        ua: "© 2024 Tentai - Бронюйте, орендуйте, купуйте, продавайте одним кліком!",
        ru: "© 2024 Tentai - Бронируйте, арендуйте, покупайте, продавайте одним кликом!",
        fr: "© 2024 Tentai - Réservez, louez, achetez, vendez en un clic !",
        cn: "© 2024 Tentai - 一键预订、租赁、购买、销售！",
        th: "© 2024 Tentai - จอง, เช่า, ซื้อ, ขาย ด้วยคลิกเดียว!"
    },
    unsubscribe: {
        en: "Unsubscribe",
        ua: "Відписатись",
        ru: "Отписаться",
        fr: "Se désabonner",
        cn: "退订",
        th: "ยกเลิกการสมัคร"
    }
}