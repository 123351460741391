import React, {
	useEffect,
	useState
} from 'react'
import Search from "../../../utils/icons/search-inp.svg"
import DelForInp from "../../../utils/icons/del-for-input.svg"
import {Form} from "react-bootstrap"
import LoaderContent from "../LoaderContent"
import PaginationCustom from "../PaginationCustom"
import {arrayBreakdownHelper} from "../../../utils/helpers/ArrayBreakdownHelper"
import {searchItemsHelper} from "../../../utils/helpers/SearchItemsHelper"
import VersionsTabl from "../../pages/Versions/VersionsTabl";
import { ARE_COMMON } from "../../../utils/textsContent";
import SelectShowRecords from "../SelectShowRecords";

const ControlPanelsForVersions = ({
	bigArrElements,
	fromPage
}) => {
	const [searchTermValue, setSearchTermValue] = useState('')
	const [loaderSorting, setLoaderSorting] = useState(false)
	const [smallAllArrTerms, setSmallAllArrTerms] = useState([])
	const [showSmallTermArr, setShowSmallTermArr] = useState([])
	const [countShowTerms, setCountShowTerms] = useState(20)
	const [currentPage, setCurrentPage] = useState(1)
	const [countShowPage, setCountShowPage] = useState(0)
	const [showPaginationBoard, setShowPaginationBoard] = useState(true)


	useEffect(() => {
			const teh = arrayBreakdownHelper(bigArrElements,
				countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setCountShowPage(teh.length)
			setShowSmallTermArr(teh[0])
		},
		[bigArrElements, countShowTerms, fromPage])

	useEffect(() => {
		},
		[fromPage])

	useEffect(() => {
			showNextPagePagination()
		},
		[currentPage])

	const showNextPagePagination = () => {
		setShowSmallTermArr(smallAllArrTerms[currentPage])
	}

	const searchHandler = (e) => {
		setLoaderSorting(true)

		if (fromPage === 'term') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'title',
				searchTermValue))
		} else if (fromPage === 'unit') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'term',
				searchTermValue))
		} else if (fromPage === 'price') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'tag',
				searchTermValue))
		} else if (fromPage === 'amount') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'tag',
				searchTermValue))
		} else if (fromPage === 'changeUnitForPrice') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'term',
				searchTermValue))
		} else if (fromPage === 'changeUnitForAmount') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'term',
				searchTermValue))
		} else if (fromPage === 'changeUnitForTemplates') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'term',
				searchTermValue))
		} else if (fromPage === 'changeUnitForTemplatesSelect') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'term',
				searchTermValue))
		} else if (fromPage === 'amount') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'name',
				searchTermValue))
		} else if (fromPage === 'htmls') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'body',
				searchTermValue))
		} else if (fromPage === 'pages') {
			setShowSmallTermArr(searchItemsHelper(bigArrElements,
				'url',
				searchTermValue))
		}
		timerLoaderHandler()
		setShowPaginationBoard(false)
	}

	const clearSearchHandler = () => {
		setLoaderSorting(true)
		valueSearchInputHandler('')
		const teh = arrayBreakdownHelper(bigArrElements,
			countShowTerms)
		setSmallAllArrTerms([[], ...teh])
		setShowSmallTermArr(teh[0])
		setCurrentPage(1)
		timerLoaderHandler()
		setShowPaginationBoard(true)
	}

	const valueSearchInputHandler = (value) => {
		setSearchTermValue(value)
	}
	const sortedForColumn = (column) => {
		setLoaderSorting(true)
		if (column === 'id') {
			const te = [...bigArrElements].sort((a,
				b) => a.id > b.id ? 1 : -1)
			const teh = arrayBreakdownHelper(te,
				countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'editor') {
			const te = [...bigArrElements].sort((a,
				b) => a.editor > b.editor ? 1 : -1)
			const teh = arrayBreakdownHelper(te,
				countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'psevdo') {
			const te = [...bigArrElements].sort((a,
				b) => a.psevdo > b.psevdo ? 1 : -1)
			const teh = arrayBreakdownHelper(te,
				countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'termin') {
			const teh = arrayBreakdownHelper(bigArrElements,
				countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		}
		timerLoaderHandler()
	}
	const timerLoaderHandler = () => {
		setTimeout(() => setLoaderSorting(false),
			1000)
	}

	return (
		<div className="terms-wrapper">
			<div className="terms-wrapper_search">
				<div className="terms-wrapper_search-block">
					<input
						type="text"
						placeholder={ARE_COMMON?.SEARCH}
						className='terms-wrapper_search-inp'
						value={searchTermValue}
						onChange={e => {
							valueSearchInputHandler(e.target.value)
						}}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								searchHandler()
							}
						}}
					/>
					<button
						type='submit'
						className='terms-wrapper_search-button'
						disabled={searchTermValue === ''}
						onClick={searchHandler}
					>
						<img
							src={Search}
							alt="Search"
							className='terms-wrapper_search-img'
						/>
					</button >
					{
						searchTermValue.length > 0
						&&
						<button
							type='button'
							className='terms-wrapper_search-button_clear'
							onClick={clearSearchHandler}
						>
							<img
								src={DelForInp}
								alt="Del"
								className='terms-wrapper_search-img'
							/>
						</button >
					}
				</div >

				<SelectShowRecords setCountShow={setCountShowTerms}/>

			</div >
			{
				countShowPage > 1
				&&
				showPaginationBoard && <PaginationCustom
					itemsCount={countShowPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					alwaysShown={showPaginationBoard}
				/>
			}
			{
				loaderSorting
					?
					<LoaderContent />
					:
					<VersionsTabl
						bigArrElements={bigArrElements}
						sortedForColumn={sortedForColumn}
						countShowTerms={countShowTerms}
						showSmallTermArr={showSmallTermArr}
						fromPage={fromPage}
					/>
			}
		</div >
	)
}

export default ControlPanelsForVersions
