import React, {
	useEffect,
	useState
} from 'react'
import AlertModal from "../AlertModal"
import { Formik } from "formik"
import {
	Button,
	Form
} from "react-bootstrap"
import {
	useCreateSubscriptionPackageMutation,
	useEditSubscriptionPackageMutation
} from "../../store/apis/subscriptionApi"
import LoaderContent from "../LoaderContent";
import { useDispatch } from "react-redux";
import {
	createNewSubscriptionPackageItem,
	editSubscriptionItem
} from "../../store/slices/subscriptionSlice";
import { addSubscriptionSchema } from "../../../utils/validation/YupSubscription";
import { ARE_COMMON } from "../../../utils/textsContent";

const initialState = {
	subscriptionNameUa: '',
	subscriptionNameEn: '',
	subscriptionNameRu: '',
	preference: {
		free_adverts: '',
		top_adverts_per_month: '',
		adverts_remake: ''
	},
	tariff: '',
	date: ''
}

const AddEditSubscription = ( { onHide, edit, editDateForModal } ) => {
	const [ form, setForm ] = useState( edit ? editDateForModal : initialState )
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const [ preferenceForm, setPreferenceForm ] = useState( {} )
	const dispatch = useDispatch()

	const [ createSubscriptionPackage, { isLoading: isCreateSubscriptionLoader } ] = useCreateSubscriptionPackageMutation()
	const [ editSubscriptionPackage, { isLoading: isEditSubscriptionLoader } ] = useEditSubscriptionPackageMutation()
	const isLoading = isCreateSubscriptionLoader || isEditSubscriptionLoader

	const formDateUpdateHandler = ( opt ) => {
		setForm( { ...form, ...opt } )
	}
	const formDatePreferenceUpdateHandler = ( opt ) => {
		setPreferenceForm( { ...preferenceForm, ...opt } )
	}

	// console.log( preferenceForm )
	const handleSubmit = async ( values, { resetForm } ) => {
		const formDate = {
			name: {
				ua: form?.subscriptionNameUa || values?.subscriptionNameUa,
				en: form?.subscriptionNameEn || values?.subscriptionNameEn,
				ru: form?.subscriptionNameRu || values?.subscriptionNameRu,
				pl: form?.subscriptionNamePl || values?.subscriptionNamePl,
			},
			preference: preferenceForm,
			tariff: Number( values?.tariff ),
			date: Date.now()
		}
		try {
			// console.log( "formDate", formDate )
			if ( edit ) {
				const {
					error
				} = await editSubscriptionPackage( { ...formDate, id: editDateForModal?.id } )
				!error && dispatch( editSubscriptionItem( { ...formDate, id: editDateForModal?.id } ) )
				!error && clearFormHideModal()
				error && setErrorMessage( error?.data?.message )
				resetForm( {} )
			} else {
				const {
					data,
					error
				} = await createSubscriptionPackage( formDate )
				!error && dispatch( createNewSubscriptionPackageItem( { ...formDate, id: data?.id } ) )
				!error && clearFormHideModal()
				error && setErrorMessage( error?.data?.message )
				resetForm( {} )
			}
		} catch ( e ) {
			console.log( e )
		}
	}

	const clearFormHideModal = () => {
		onHide()
		setForm( {} )
	}

	useEffect( () => {
		edit &&
		setForm( {
			...editDateForModal,
			tariff: editDateForModal?.tariff,
			preference: editDateForModal?.preference
		} )
		edit &&
		setPreferenceForm( { ...preferenceForm, ...editDateForModal?.preference } )
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ edit ] )

	// console.log( 'FORM-->', form )

	return (
		<div className='template-content'>
			<h1 className='terms-title'>
				{ edit ? `${ARE_COMMON?.EDITING} пакету` : `${ARE_COMMON?.CREATED} нового пакету` }
			</h1 >
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<Formik
				validateOnChange
				initialValues={ {
					subscriptionNameUa: !edit ? form?.subscriptionNameUa : editDateForModal?.name?.ua,
					subscriptionNameEn: !edit ? form?.subscriptionNameEn : editDateForModal?.name?.en,
					subscriptionNameRu: !edit ? form?.subscriptionNameRu : editDateForModal?.name?.ru,
					subscriptionNamePl: !edit ? form?.subscriptionNamePl : editDateForModal?.name?.pl,
					adverts_remake: !edit ? form?.adverts_remake : editDateForModal?.preference?.adverts_remake,
					free_adverts: !edit ? form?.free_adverts : editDateForModal?.preference?.free_adverts,
					top_adverts_per_month: !edit ? form?.top_adverts_per_month : editDateForModal?.preference?.top_adverts_per_month,
					tariff: form?.tariff,
				} }
				validationSchema={ addSubscriptionSchema }
				onSubmit={ handleSubmit }
				enableReinitialize
			>
				{
					( {
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty,
					} ) => (
						<Form
							className='add-edit-terms'
							onSubmit={ handleSubmit }
						>
						<div className='subscription-create_wrapper'>
							<Form.Group
								className='subscription-create_label'
							>
								<h3 >
									{ `${ ARE_COMMON?.NAME } підписки UA` }
								</h3 >
								<Form.Control
									type='text'
									name='subscriptionNameUa'
									onBlur={ handleBlur }
									autoFocus
									defaultValue={ values?.subscriptionNameUa }
									onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
									placeholder='Введіть назву'
									className={ `subscription-create_inp  ${ touched?.subscriptionNameUa ? "is-touch " : "" } ${
										errors?.subscriptionNameUa && touched?.subscriptionNameUa ? " is-invalid" : ""
									} ` }
									onChange={ ( e ) => {
										e.preventDefault()
										handleChange( e )
										formDateUpdateHandler( {
											[e.target.name]: e.target.value,
										} )
									} }
								/>
								{ errors.subscriptionNameUa && touched.subscriptionNameUa && (
									<Form.Control.Feedback type='invalid'>
										{ errors.subscriptionNameUa }
									</Form.Control.Feedback >
								) }
							</Form.Group >
							<Form.Group
								className='subscription-create_label'
							>
								<h3 >
									{ `${ ARE_COMMON?.NAME } підписки En` }
								</h3 >
								<Form.Control
									type='text'
									name='subscriptionNameEn'
									onBlur={ handleBlur }
									defaultValue={ values?.subscriptionNameEn }
									onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
									placeholder='Введіть назву'
									className={ `subscription-create_inp  ${ touched?.subscriptionNameEn ? "is-touch " : "" } ${
										errors?.subscriptionNameEn && touched?.subscriptionNameEn ? " is-invalid" : ""
									} ` }
									onChange={ ( e ) => {
										e.preventDefault()
										handleChange( e )
										formDateUpdateHandler( {
											[e.target.name]: e.target.value,
										} )
									} }
								/>
								{ errors.subscriptionNameEn && touched.subscriptionNameEn && (
									<Form.Control.Feedback type='invalid'>
										{ errors.subscriptionNameEn }
									</Form.Control.Feedback >
								) }
							</Form.Group >
							<Form.Group
								className='subscription-create_label'
							>
								<h3 >
									{ `${ ARE_COMMON?.NAME } підписки Ru` }
								</h3 >
								<Form.Control
									type='text'
									name='subscriptionNameRu'
									onBlur={ handleBlur }
									defaultValue={ values?.subscriptionNameRu }
									onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
									placeholder='Введіть назву'
									className={ `subscription-create_inp  ${ touched?.subscriptionNameRu ? "is-touch " : "" } ${
										errors?.subscriptionNameRu && touched?.subscriptionNameRu ? " is-invalid" : ""
									} ` }
									onChange={ ( e ) => {
										e.preventDefault()
										handleChange( e )
										formDateUpdateHandler( {
											[e.target.name]: e.target.value,
										} )
									} }
								/>
								{ errors.subscriptionNameRu && touched.subscriptionNameRu && (
									<Form.Control.Feedback type='invalid'>
										{ errors.subscriptionNameRu }
									</Form.Control.Feedback >
								) }
							</Form.Group >
							<Form.Group
								className='subscription-create_label'
							>
								<h3 >
									{ `${ ARE_COMMON?.NAME } підписки Pl` }
								</h3 >
								<Form.Control
									type='text'
									name='subscriptionNamePL'
									onBlur={ handleBlur }
									defaultValue={ values?.subscriptionNamePL }
									onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
									placeholder='Введіть назву'
									className={ `subscription-create_inp  ${ touched?.subscriptionNamePL ? "is-touch " : "" } ${
										errors?.subscriptionNamePL && touched?.subscriptionNamePL ? " is-invalid" : ""
									} ` }
									onChange={ ( e ) => {
										e.preventDefault()
										handleChange( e )
										formDateUpdateHandler( {
											[e.target.name]: e.target.value,
										} )
									} }
								/>
								{ errors.subscriptionNamePL && touched.subscriptionNamePL && (
									<Form.Control.Feedback type='invalid'>
										{ errors.subscriptionNamePL }
									</Form.Control.Feedback >
								) }
							</Form.Group >

							<div className='subscription-create_wrapper-date'>
								<div className='subscription-create_wrapper-date_block'>
									<h3 className='subscription-create_wrapper-date_block-title'>
										Price
									</h3 >
									<Form.Control
										type='number'
										name='tariff'
										onBlur={ handleBlur }
										defaultValue={ values?.tariff }
										onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
										placeholder='Введіть ціну'
										className={ `subscription-create_inp  ${ touched?.tariff ? "is-touch " : "" } ${
											errors?.tariff && touched?.tariff ? " is-invalid" : ""
										} ` }
										onChange={ ( e ) => {
											e.preventDefault()
											handleChange( e )
											formDateUpdateHandler( {
												[e.target.name]: e.target.value
											} )
										} }
									/>
								</div >
							</div >

							<div className='subscription-create_wrapper-preference'>
								<h3 className='subscription-create_wrapper-date_block-title'>
									Переваги
								</h3 >
								<ul className='subscription-create_wrapper-preference_wrapper'>
									<li className='subscription-create_wrapper-preference_wrapper-li'>
											<h3 className='subscription-create_wrapper-preference_wrapper-li_title'>
												adverts remake
											</h3 >
											<Form.Control
												type='number'
												name={ 'adverts_remake' }
												onBlur={ handleBlur }
												defaultValue={ values?.adverts_remake }
												onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
												placeholder='Введіть число'
												className='subscription-create_wrapper-preference_wrapper-li_input'
												onChange={ ( e ) => {
													e.preventDefault()
													handleChange( e )
													formDatePreferenceUpdateHandler( {
														[e.target.name]: Number( e.target.value )
													} )
												} }
											/>
									</li >
									<li className='subscription-create_wrapper-preference_wrapper-li'>
										<h3 className='subscription-create_wrapper-preference_wrapper-li_title'>
											free adverts
											</h3 >
											<Form.Control
												type='number'
												name={ 'free_adverts' }
												onBlur={ handleBlur }
												defaultValue={ values?.free_adverts }
												onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
												placeholder='Введіть число'
												className='subscription-create_wrapper-preference_wrapper-li_input'
												onChange={ ( e ) => {
													e.preventDefault()
													handleChange( e )
													formDatePreferenceUpdateHandler( {
														[e.target.name]: Number( e.target.value )
													} )
												} }
											/>
									</li >
									<li className='subscription-create_wrapper-preference_wrapper-li'>
										<h3 className='subscription-create_wrapper-preference_wrapper-li_title'>
											top adverts per month
											</h3 >
											<Form.Control
												type='number'
												name={ 'top_adverts_per_month' }
												onBlur={ handleBlur }
												defaultValue={ values?.top_adverts_per_month }
												onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
												placeholder='Введіть число'
												className='subscription-create_wrapper-preference_wrapper-li_input'
												onChange={ ( e ) => {
													e.preventDefault()
													handleChange( e )
													formDatePreferenceUpdateHandler( {
														[e.target.name]: Number( e.target.value )
													} )
												} }
											/>
									</li >
								</ul >
							</div >


							<div className='d-flex align-items-center mt-3'>
								<Button
									type='submit'
									className='add-edit-htmls_save d-flex align-items-center subscription-create_button'
									style={ {
										maxHeight: '33px',
										display: 'flex',
										alignItems: 'center'
									} }
									// disabled={!isValid && dirty}
									onClick={ handleSubmit }
								>
									{ ARE_COMMON?.SAVE } { isLoading && <LoaderContent /> }
									</Button >
									<Button
										type='button'
										className='add-edit-htmls_close subscription-create_button'
										style={ {
											maxHeight: '33px',
											display: 'flex',
											alignItems: 'center'
										} }
										onClick={ () => {
											onHide()
											clearFormHideModal()
										} }
									>
									{ARE_COMMON?.ABOLITION}
								</Button >
							</div >
						</div >
					</Form >
					) }
			</Formik >
    </div >
	)
}

export default AddEditSubscription