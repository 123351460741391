import EditPencil from "../../../utils/icons/edit-pencil.svg"
import DeleteIcon from "../../../utils/icons/delete-icon.svg"
import {
	useEffect,
	useRef,
	useState,
} from "react"
import { useDispatch } from "react-redux"
import { templateContentType } from "../../../utils/helpers/TemplateContentType"
import {
	deleteDateChangeUnitTemplatesList,
	setDateChangeUnitTemplatesListArr,
	showModalEditDataTemplateContentTemplates,
	showModalEditTemplateContentTemplates
} from "../../store/slices/templateSlice"
import { ARE_COMMON } from "../../../utils/textsContent"
import {ReactComponent as InfoIcon} from '../../../utils/icons/info-icon.svg'
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const TemplateContentTablItem = ({
	setOpenEditModal,
	item,
	toggleDeleteModal,
	fromPage,
	changeSelected,
	selectedInputs,
}) => {
	const [itemType, setItemType] = useState(`${ARE_COMMON?.INT}`)
	const units = useSelector(store => store.unitGroupsStore.unitGroups)
	const unitName = units.find(unit => unit.id === item.unit)?.name
	const {i18n} = useTranslation()
	
	const dispatch = useDispatch()
	const infoModal = useRef()

	useEffect(() => {
		templateContentType(item?.type, setItemType)
	},[item])

	const saveChangeDateUnit = (checked) => {
		if (checked === true && fromPage !== 'templateContent' )  dispatch(setDateChangeUnitTemplatesListArr(item))
		if (checked === false && fromPage !== 'templateContent' ) dispatch(deleteDateChangeUnitTemplatesList(item))
	}

	return (
		<tr style={{position: "relative"}}>
			<td className='terms-wrapper_tabl-body_item col-1'>
				<div className='terms-wrapper_tabl-body_item-wrapper'>
					{
						fromPage === 'changeItemsTemplateContentForTemplate'
						&&
						<input
							type='checkbox'
							onKeyDown={e => {
								e.key === 'Enter' && e.preventDefault()
							}}
							name='check'
							defaultChecked={item?.markerChangeForList ? item?.markerChangeForList : false}
							disabled={item?.markerChangeForList ? item?.markerChangeForList : false}
							id={item?.id}
							value={item?.id}
							className='terms-wrapper_tabl-body_item-wrapper_input'
							onChange={e => {
								saveChangeDateUnit(e.target.checked)
							}}

						/>
					}
					<label htmlFor={item?.id}>{item?.id}</label >
				</div >
			</td >
			<td className={`terms-wrapper_tabl-body_item col-4`}>
				<p >
					{
						item?.name?.[i18n.language]
					}
				</p >
			</td >
			<td className='terms-wrapper_tabl-body_item col-2'>
				<div className='terms-wrapper_tabl-body_item-wrapper'>
					<span >{itemType}</span >
				</div >
			</td >
			<td className='terms-wrapper_tabl-body_item col-2'>
				<div className='terms-wrapper_tabl-body_item-wrapper'>
					<span >{item?.psevdo}</span >
				</div >
			</td >
			<td className='terms-wrapper_tabl-body_item col-2'>
				<div className='terms-wrapper_tabl-body_item-wrapper justify-content-center'>
					<span >{item?.key}</span >
				</div >
			</td >

			{
				// fromPage === 'changeItemsTemplateContentForTemplate'
				true
				&&
				<td className='terms-wrapper_tabl-body_item col-2' style={{position: "static", background: 'none'}}>
					{
						(item.type === 1 || item.type === 6)?
						<span>{unitName}</span>
						:
						<ul style={{overflow: "visible"}} className='terms-wrapper_tabl-body_item-wrapper_values'>
							{
								item.values?.length?
								<div 
								onMouseEnter={()=>{
									if(infoModal.current){
										let elementRect = infoModal.current.getBoundingClientRect();
										let windowHeight = window.innerHeight;
										let distanceToBottom = windowHeight - elementRect.bottom;
										if(distanceToBottom < 50){
											infoModal.current.style.cssText = `transform: translate(-50%, 0px); top: auto; bottom: 100%; opacity: 1; visibility: visible;`
										}else{
											infoModal.current.style.cssText = `opacity: 1; visibility: visible;`
										}
									}
								}}
								onMouseLeave={()=>{
									if(infoModal.current){
										infoModal.current.style.cssText = `opacity: 0; visibility: hidden;`
									}
								}}
								><InfoIcon/></div> : null
							}
							<div className="info_modal_table_template_content" ref={infoModal}>
								{
									item?.values?.slice(0,50).map((el, index) => {
										if(!el){return null}
										return(
											<li key={index} style={{margin: '5px'}} className="info_modal_table_template_content_item">
												<span >{el?.[i18n.language]}</span >
											</li >
										)
									})
								}
							</div>
						</ul >
					}
				</td >
			}

			<td className='terms-wrapper_tabl-body_item col-1'>
				<div className='terms-wrapper_tabl-body_item-wrapper_icons'>
						<button
							type='button'
							onClick={() => {
								saveChangeDateUnit(item?.unit)
								fromPage !== 'changeItemsTemplateContentForTemplate' && setOpenEditModal(item)
								fromPage === 'changeItemsTemplateContentForTemplate' && dispatch(
									showModalEditTemplateContentTemplates(true))
								fromPage === 'changeItemsTemplateContentForTemplate' && dispatch(
									showModalEditDataTemplateContentTemplates(item))
							}}
						>
						<img
							src={EditPencil}
							alt='Edit Pencil'
						/>
					</button >
					{
						fromPage !== 'changeItemsTemplateContentForTemplate'
						&&
						<button
							type='button'
							onClick={() => {
								toggleDeleteModal(item)
							}}
						>
						<img
							src={DeleteIcon}
							alt='Delete'
						/>
					</button >
					}
				</div >
			</td >
		</tr >
	)
}

export default TemplateContentTablItem
