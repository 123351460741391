import React, {
	useEffect,
	useState
} from 'react'
import ControlPanels from "../../components/environmentTabl/ControlPanels"
import { useSelector } from "react-redux"
import DeleteModal from "../../components/DeleteModal"
import AddEditUnitsMeasurementItem from "../../components/modalsAddEdit/AddEditUnitsMeasurementItem"
import AlertModal from "../../components/AlertModal"
import { PAGES_NAME } from "../../../utils/textsContent"
import { useTranslation } from 'react-i18next'

const UnitsMeasurementPage = () => {
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ openEditModal, setOpenEditModal ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )
	const [ unitsMeasurementListArr, setUnitsMeasurementListArr ] = useState( [] )
	const [ errorMessage, setErrorMessage ] = useState( '' )

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )
	const {t} = useTranslation()

	const data = useSelector(store => store.unitsMeasurementStore.unitsMeasurement)

	useEffect( () => {
		const getUnitList = async () => {
			setUnitsMeasurementListArr( data )
		}
		getUnitList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ addEditModalShow, deleteModalShow ] )

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
		setOpenEditModal( false )
	}
	const hideModalHandler = () => {
		setAddEditModalShow( false )
		setOpenEditModal( false )
		setEditDateForModal( {} )
	}
	const toggleDeleteModal = ( value ) => {
		setDeleteTerm( value )
		setDeleteModalShow( !deleteModalShow )
	}

	const openEditModalHandler = ( value ) => {
		setEditDateForModal( value )
		setAddEditModalShow( true )
		setOpenEditModal( true )
	}

	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}

	function setOrder(){
		setUnitsMeasurementListArr(prev => {
			const newState = JSON.parse(JSON.stringify(prev))
			return newState.reverse()
		})
	}

	return (
		<div className='terms'>
			{
				errorMessage && <AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<AddEditUnitsMeasurementItem
				show={ addEditModalShow }
				onHide={ hideModalHandler }
				edit={ openEditModal }
				editDateForModal={ editDateForModal }
			/>
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ deleteTerm }
				fromPage={ 'unit' }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				returnErrorForDel={ returnErrorForDel }
			/>
			<h1 className='terms-title'>
				{t('c_uom_colunm_units')}
			</h1 >
			<ControlPanels
				bigArrElements={ unitsMeasurementListArr }
				showModalHandler={ showModalHandler }
				openEditModalHandler={ openEditModalHandler }
				toggleDeleteModal={ toggleDeleteModal }
				fromPage={ 'unit' }
				searchTermValue={ searchTermValue }
				loaderSorting={ loaderSorting }
				setLoaderSorting={ setLoaderSorting }
				toggleModalSearch={ toggleModalSearch }
				showModalSearch={ showModalSearch }
				showSearchBeforeDel={ showSearchBeforeDel }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				valueSearchInputHandler={ valueSearchInputHandler }
				setOrder={setOrder}
			/>
		</div >
	)
}

export default UnitsMeasurementPage
