import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	TRANSPORT_CATEGORY_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const transportCategoryApi = createApi({
	reducerPath: "transportCategoryApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getTransportCategory: builder.mutation({
			query: () => (
				{
					url: TRANSPORT_CATEGORY_API.GET_TRANSPORT_CATEGORY,
					method: "GET",
				})
		}),
		createTransportCategory: builder.mutation({
			query: (body) => (
				{
					url: TRANSPORT_CATEGORY_API.CREATE_TRANSPORT_CATEGORY,
					method: "POST",
					body
				})
		}),
		editTransportCategory: builder.mutation({
			query: (body) => (
				{
					url: TRANSPORT_CATEGORY_API.EDIT_TRANSPORT_CATEGORY,
					method: "POST",
					body
				})
		}),
		deleteTransportCategory: builder.mutation({
			query: (body) => (
				{
					url: TRANSPORT_CATEGORY_API.DELETE_TRANSPORT_CATEGORY,
					method: "POST",
					body
				})
		}),
	})
});

export const {
	useGetTransportCategoryMutation,
	useCreateTransportCategoryMutation,
	useEditTransportCategoryMutation,
	useDeleteTransportCategoryMutation,


} = transportCategoryApi
