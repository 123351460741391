import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import { TERMS_API } from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const termsApi = createApi({
	reducerPath: "termsApi",
	tagTypes: ['Terms'],
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getTermsList: builder.query( {
			query: (query) => (
				{
					url: `${TERMS_API.GET_TERMS}?
					${query.limit? `limit=${query.limit}` : ''}
					${query.page? `&page=${query.page}` : ''}
					${query.sort? `&sort=${query.sort}` : ''}
					${query.order? `&order=${query.order}` : ''}
					${query.search? `&text=${encodeURIComponent(query.search)}` : ''}
					${query.group? `&systemGroup=${query.group}` : ''}
					${query.list? `&list=${JSON.stringify(query.list)}` : ''}
					`,
					method: "GET"
				}
			),
			providesTags: (result = false) =>
			result.data
			? [
				...result.data.map(({ id }) => ({ type: 'Terms', id })),
				{ type: 'Terms', id: 'LIST' },
				]
			: [{ type: 'Terms', id: 'LIST' }],
		} ),

		migrationTerms: builder.mutation( {
			query: () => (
				{
					url: `${TERMS_API.MIGRATION_TERMS}?`,
					method: "GET"
				}
			)
		} ),

		getTerms: builder.mutation({
			query: (query) => (
				{
					url: `${TERMS_API.GET_TERMS}?
					${query.list? `list=${JSON.stringify(query.list)}` : ''}
					`,
					method: "GET",
				})
		}),
		createTerms: builder.mutation({
			query: (body) => (
				{
					url: TERMS_API.CREATE_TERMS,
					method: "POST",
					body
				}),
				invalidatesTags: [{ type: 'Terms', id: 'LIST' }],
		}),
		editTerms: builder.mutation({
			query: (body) => (
				{
					url: TERMS_API.EDIT_TERMS,
					method: "POST",
					body
				}),
				invalidatesTags: [{ type: 'Terms', id: 'LIST' }],
		}),
		deleteTerms: builder.mutation({
			query: (body) => (
				{
					url: TERMS_API.DELETE_TERMS,
					method: "POST",
					body
				}),
				invalidatesTags: [{ type: 'Terms', id: 'LIST' }],
		}),
	})
});

export const {
	useGetTermsMutation,
	useCreateTermsMutation,
	useEditTermsMutation,
	useDeleteTermsMutation,
	useGetTermsListQuery,
	useMigrationTermsMutation
} = termsApi
