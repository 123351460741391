import * as yup from "yup"

export const addTransportCategorySchema = () => {
	return yup.object().shape({
		nameUa: yup
			.string()
			.required('Заголовок ua обов\'язковий!'),
		nameEn: yup
			.string()
			.required('Заголовок en обов\'язковий!!'),
		nameRu: yup
			.string()
			.required('Заголовок ru обов\'язковий!'),
	})
}
