import * as yup from "yup"

export const addPagesSchema = () => {
	return yup.object().shape({
		url: yup
			.string()
			.required('Поле є обов\'язковим!!!'),
		screen: yup
			.string()
			.required('Поле є обов\'язковим!!!'),
	})
}
