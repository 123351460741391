import { ARE_COMMON } from "../textsContent";

export const verificationSwitchHelper = (item) => {
	let res = ''
	if (item?.diya === true || item?.docs === true) {
		res = {title: 'Верифіковано', bg: '#2DF31C', color: '#141414'}
	} else if (item?.docs === false) {
		res = {title: `${ARE_COMMON?.ON_MODERATION}`, bg: '#21468F', color: '#fff'}
	} else {
		res = {title: 'Не верифіковано', bg: '#6B778C', color: '#fff'}
	}
	return res
}

export const statusSwitchHelper = (item) => {
	let res = ''

	switch (item) {
		case "success":
			res = {title: 'Закрито', bg: '#01A944', color: '#fff', available: false, description: "Транзакція закрита."}
			break
		case "sandbox":
			res = {
				title: 'HOLD', bg: '#4148F8', color: '#fff', available: false, description: "Угода завершена." +
					"Кошти надійшли і перебувають в очікуванні протягом 3х днів."
			}
			break
		case "reversed":
			res = {
				title: 'Потребує підтвердження', bg: '#F71010', color: '#fff', available: true, description: "Угода" +
					"завершена. Очікування підтвердження оплати чи повернення коштів."
			}
			break
		case "invoice_wait":
			res = {
				title: 'Очікування оплати',
				bg: '#E7BE2F',
				color: '#fff',
				available: false,
				description: "Оплата не надійшла."
			}
			break
		case "incomplete":
			res = {
				title: 'Незавершено', bg: '#A3A3A3', color: '#fff', available: false, description: "Оплата надійшла," +
					"але угода незавершена."
			}
			break
		default:
			res = {title: 'error', bg: 'yellow', color: 'black', available: false, description: "Помилка"}
			break
	}
	return res
}


export const statusUsersSwitchHelper = (item) => {
	let res = ''

	switch (item) {
		case 1:
			res = {title: 'Активний', bg: '#00A046', color: '#fff'}
			break
		case 2:
			res = {title: 'На реєстрації', bg: '#21468F', color: '#fff'}
			break
		case 3:
			res = {title: 'Заблокований', bg: '#F84146', color: '#fff'}
			break
		case 4:
			res = {title: 'На видаленні', bg: '#444444', color: '#fff'}
			break
		case 5:
			res = {title: 'Видалений', bg: '#231F20', color: '#fff'}
			break
		default:
			res = {title: 'Помилка', bg: 'yellow', color: 'black'}
			break
	}
	return res
}

export const statusNoticesSwitchHelper = (item) => {
	let res = ''
	switch (item) {
		case 0:
			res = {title: 'Архів', bg: '#21468F', color: '#fff'}
			break
		case 1:
			res = {title: 'Активно', bg: '#00A046', color: '#fff'}
			break
		case 2:
			res = {title: 'Заблоковане оголошення', bg: '#F84146', color: '#fff'}
			break
		case 3:
			res = {title: 'Видалене оголошення', bg: '#F84146', color: '#fff'}
			break
		default:
			res = {title: 'Помилка', bg: 'yellow', color: '#000'}
			break
	}
	return res
}

export const moderationStatusNoticesSwitchHelper = (item) => {
	let res = ''
	switch (item) {
		case 0:
			res = {title: 'Оголошення в модерації', bg: '#21468F', color: '#fff'}
			break
		case 1:
			res = {title: 'Пройшов модерацію', bg: '#00A046', color: '#fff'}
			break
		case 2:
			res = {title: 'Не прошел модерацию', bg: '#F84146', color: '#fff'}
			break
		default:
			res = {title: 'Помилка', bg: 'yellow', color: '#000'}
			break
	}
	return res
}

export const statusTemplatesSwitchHelper = (item, t) => {
	let res = ''

	switch (item) {
		case true:
			res = {title: t('active'), bg: '#00A046', color: '#fff'}
			break
		case false:
			res = {title: t('inactive'), bg: '#F84146', color: '#fff'}
			break
		default:
			res = {title: t('error'), bg: 'yellow', color: '#000'}
			break
	}
	return res
}