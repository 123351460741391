import React, { useEffect, useState } from 'react';
import s from './contentCreator.module.scss'
import { Button } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from '../../../utils/icons/del-for-input.svg'
import moowIcon from '../../../utils/icons/move_icon.png'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TextFieldsBlock } from '../environmentTabl/TextFieldsBlock/TextFieldsBlock';

const initialTypes = ['text', 'select', 'radio', 'checkbox', 'date', 'location']

export const ContentCreator = ({ form, setForm }) => {

    const [types, setTypes] = useState([])

    useEffect(()=>{
        if(!form.values){
            setForm(prev => {return{...prev, values: {}}})
        }else{
            setTypes(Object.entries(form.values)?.map(el => el[1].type))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[form]);

    function reorder(list, startIndex, endIndex){
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		return result
	}

    function getOpenedTypes(){
        return initialTypes.filter(el => !types.includes(el))
    }

    function onDragEnd(result){
        if (!result.destination) {
			return
		}
        const sortedList = reorder(
			Object.entries(form.values).map(el => el[1]),
			result.source.index,
			result.destination.index
		)
        const sortedObject = {}
        sortedList.forEach((el, index) => sortedObject[`${index + 1}`] = {...el, position: index + 1})

        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState.values = sortedObject
            return newState
        })
    }

    function addNewValue(){
        const openedTypes = getOpenedTypes()
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            let values = Object.entries(newState?.values).map(el => el[1])
            values = values.sort((a, b) => a.id - b.id)
            const lastIndex = (values[values.length - 1]?.id + 1) || 1
            newState.values[`${lastIndex}`] = {
                id: lastIndex,
                position: lastIndex,
                type: openedTypes[0],
                placeholder: {},
                title: {},
                options: []
            }
            return newState
        })
    }

    function changeValueType(el, type){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            const newValues = {}
            Object.entries(newState.values)?.forEach((item) => {
                if(item[1].id === el.id) {
                    newValues[item[0]] = {...item[1], type: type, options: []}
                }
                else newValues[item[0]] = {...item[1]}
            })
            newState.values = newValues
            return newState
        })
    }

    function setTitle(el, value){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            const newValues = {}
            Object.entries(newState.values)?.forEach((item) => {
                if(item[1].id === el.id) {
                    newValues[item[0]] = {...item[1], title: {...item[1].title, ...value}}
                }
                else newValues[item[0]] = {...item[1]}
            })
            newState.values = newValues
            return newState
        })
    }

    function setPlaceholder(el, value){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            const newValues = {}
            Object.entries(newState.values)?.forEach((item) => {
                if(item[1].id === el.id) {
                    newValues[item[0]] = {...item[1], placeholder: {...item[1].placeholder, ...value}}
                }
                else newValues[item[0]] = {...item[1]}
            })
            newState.values = newValues
            return newState
        })
    }

    function addOptionsValue(el){
        console.log(el);
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            const newValues = {}
            Object.entries(newState.values)?.forEach((item) => {
                if(item[1].id === el.id) {
                    console.log();
                    item[1].options?.push({})
                }
                newValues[item[0]] = {...item[1]}
            })
            newState.values = newValues
            return newState
        })
        
    }

    function changeOptionValue(el, index, value){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            const newValues = {}
            Object.entries(newState.values)?.forEach((item) => {
                if(item[1].id === el.id) {
                    item[1].options[index] = {...item[1].options[index], ...value}
                }
                newValues[item[0]] = {...item[1]}
            })
            newState.values = newValues
            return newState
        })
    }

    function deleteItem(el){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            const newValues = {}
            Object.entries(newState.values)?.forEach((item, index) => {
                if(item[1].id !== el.id) newValues[index + 1] = item[1]
            })
            newState.values = newValues
            return newState
        })
    }

    function deleteOptionsValue(el, index){
        console.log(index);
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            const newValues = {}
            Object.entries(newState.values)?.forEach((item) => {
                if(item[1].id === el.id) {
                    item[1].options.splice(index, 1)
                }
                newValues[item[0]] = {...item[1]}
            })
            newState.values = newValues
            return newState
        })
    }

    return(
        <div className={s.wrapper}>
            <div className='categories-create_block' style={{width: '100%'}}>
                <h3>Values</h3>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={"droppable"}>
                        {(provided) => (
                            <div className={s.items_wrapper} ref={provided.innerRef} {...provided.droppableProps}>
                            {form?.values &&
                                Object.entries(form?.values)?.map((el, index) => {
                                    return(
                                        <Draggable
                                            key={el[1].id}
                                            draggableId={el[1].id.toString()}
                                            index={index}
                                        >
                                        {(provided) => (
                                            <div key={index} className={s.item_wrapper}
                                                ref={provided.innerRef} 
                                                {...provided.draggableProps}
                                            >
                                                <div className={s.icons_wrapper}>
                                                    <img {...provided.dragHandleProps} src={moowIcon} alt="moow" />
                                                    <DeleteIcon onClick={()=>deleteItem(el[1])}/>
                                                </div>
                                                <div className={s.header}>
                                                    <select  value={el[1].type} name="type" id="type" onChange={(e)=>{changeValueType(el[1], e.target.value)}}>
                                                        <option disabled={types.includes('text')} value="text">Текст</option>
                                                        <option disabled={types.includes('select')} value="select">Список</option>
                                                        <option disabled={types.includes('radio')} value="radio">Радiо</option>
                                                        <option disabled={types.includes('checkbox')} value="checkbox">Чекбокс</option>
                                                        <option disabled={types.includes('date')} value="date">Дата</option>
                                                        <option disabled={types.includes('location')} value="location">Локацiя</option>
                                                    </select>

                                                    <div className={s.center}>
                                                        <TextFieldsBlock title="Title" values={el[1].title} changeValue={(value)=>setTitle(el[1], value)}/>
                                                        <TextFieldsBlock title="Placeholder" values={el[1].placeholder} changeValue={(value)=>setPlaceholder(el[1], value)}/>
                                                    </div>
                                                </div>
                                                {
                                                    (el[1].type === "select" || el[1].type === "radio") &&
                                                    <div className={s.select_options}>
                                                    <span>Варiанти</span>

                                                    {el[1].options?.length ?
                                                    <div className={s.options_wrapper}>
                                                        {
                                                            el[1].options?.map((value, index) => {
                                                                return(
                                                                    <div key={index} className={s.option_wrapper}>
                                                                        <div className={s.delete_icon_wrapper} onClick={()=>deleteOptionsValue(el[1], index)}>
                                                                            <DeleteIcon />
                                                                        </div>
                                                                        <TextFieldsBlock title="option" values={value} changeValue={(value)=>changeOptionValue(el[1], index, value)}/>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div> : null}

                                                    <div className={s.footer}>
                                                        <Button onClick={()=>addOptionsValue(el[1])}>Додати варiант</Button>
                                                    </div>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                        </Draggable>
                                    )
                                })
                            }
                            </div>
                        )}
                    </Droppable >
                </DragDropContext >
                <Button disabled={!getOpenedTypes().length} onClick={addNewValue}>Додати елемент</Button>
            </div >
        </div>
    )
}