import { createSlice } from "@reduxjs/toolkit"
import { moderationPaymentApi } from "../apis/moderationPaymentApi"

const initialState = {
	moderationPayment: [],
	moderationPaymentStatus: [],
	amount: 0
}

const moderationPaymentSlice = createSlice({
	name: "moderationPayment",
	initialState,
	reducers: {
		/*createNewModerationPaymentItem: (state, action) => {
			state.moderationPayment = [action.payload, ...state.moderationPayment]
		},*/
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(moderationPaymentApi.endpoints.getModerationPayments.matchFulfilled, (
					state,
					action
				) => {

					state.moderationPayment = action.payload.data
					state.amount = action.payload.amount

				}
			)
			.addMatcher(moderationPaymentApi.endpoints.getModerationPaymentsStatus.matchFulfilled, (
					state,
					action
				) => {
					state.moderationPaymentStatus = action.payload.data
				}
			)
	}
})

const {
	actions,
	reducer
} = moderationPaymentSlice
export const {
	createNewModerationPaymentItem,
} = actions
export default reducer
