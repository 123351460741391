import { STATISTICS_IGNORE_DATERANGE } from "../constants";
import {
	getCurrentDate,
	getDatesBetween,
	getHourlyDatesForLast24Hours,
	parseDate,
	sortStatisticDates
} from "./TransformDate";

export function configureDashboardDate(data, dataRange, setDataRange) {
	if(!data) return
	const other_statistics = []
	let startDate = dataRange.startDate
	let endDate = dataRange.endDate
	if (!Object.keys(data).length) return

	if (startDate === 0) startDate = findStartDate(data)
	const difference = endDate - startDate;

	let result = []
	
	if (difference <= 86400000) {
		result = getHourlyDatesForLast24Hours();
	} else result = getDatesBetween(startDate, endDate);
	try {
		for (let statistic in data) {
			if (!STATISTICS_IGNORE_DATERANGE.includes(statistic)) {
				result.forEach(el => el[statistic] = [])
				data[statistic].forEach(el => {
					let timestamp = el;
					let date = new Date(parseInt(timestamp));
					
					// разбиваем по дням или по часам
					let interval
					if (difference <= 86400000) {
						interval = parseInt(date.getHours()) < 10 ? `0${date.getHours()}:00` : `${date.getHours()}:00`
					} else {
						date.setHours(0, 0, 0, 0);
						interval = getCurrentDate(date)
					}
					
					const element = result.find(el => el.date === interval)
					if (element) {
						if (element[statistic]) element[statistic].push(timestamp)
						else element[statistic] = [timestamp]
					}

				})
			} else other_statistics.push(statistic)
		}
	} catch (error) {
		console.log(error);
	}

	// считаем кол-во записей
	const graphic_data = result.map(el => {
		const newObj = {}
		for (let key in el) {
			if (key === "date") newObj[key] = el[key]
			else newObj[key] = el[key].length
		}
		return newObj
	})
	// сортируем по полю date
	const sorted_data = sortStatisticDates(graphic_data)

	if (!other_statistics.length) return sorted_data
	else {
		const result = sorted_data.map(item => {
			if (other_statistics.includes('average_publication_users')) {
				const adverts_count = data['average_publication_users'].filter(el => {
					let item_date
					if (difference <= 86400000) {
						const tempDate = new Date()
						tempDate.setHours(item.date.split(":")[0])
						item_date = tempDate
					} else item_date = parseDate(item.date)
					if (new Date(parseInt(el.extension)) <= item_date) {
						return true
					} else return false
				})
				const uniqueUsers = new Set();
				const users = [];
				for (const obj of adverts_count) {
					if (!uniqueUsers.has(obj.creatorId)) {
						uniqueUsers.add(obj.creatorId);
						users.push(obj);
					}
				}
				item['average_publication_users'] = (adverts_count.length / users.length).toFixed(1)
			}
			if (other_statistics.includes('users_published_adverts')) {
				const adverts_count = data['average_publication_users'].filter(el => {
					let item_date
					if (difference <= 86400000) {
						const tempDate = new Date()
						tempDate.setHours(item.date.split(":")[0])
						item_date = tempDate
					} else item_date = parseDate(item.date)
					if (new Date(parseInt(el.extension)) <= item_date) {
						return true
					} else return false
				})
				const uniqueUsers = new Set();
				const users = [];
				for (const obj of adverts_count) {
					if (!uniqueUsers.has(obj.creatorId)) {
						uniqueUsers.add(obj.creatorId);
						users.push(obj);
					}
				}
				item['users_published_adverts'] = users.length
			}
			if (other_statistics.includes('users_not_published_adverts')) {
				const users_count = data['users_not_published_adverts'].filter(el => {
					let item_date
					if (difference <= 86400000) {
						const tempDate = new Date()
						tempDate.setHours(item.date.split(":")[0])
						item_date = tempDate
					} else item_date = parseDate(item.date)
					if (new Date(parseInt(el.dateRegister)) < item_date) {
						return true
					} else return false
				})
				item['users_not_published_adverts'] = users_count.length
			}

			if (other_statistics.includes('average_deals_users')) {
				const deals_count = data['average_deals_users'].filter(el => {
					let item_date
					if (difference <= 86400000) {
						const tempDate = new Date()
						tempDate.setHours(item.date.split(":")[0])
						item_date = tempDate
					} else item_date = parseDate(item.date)
					if (new Date(parseInt(el.date)) <= item_date) {
						return true
					} else return false
				})
				const uniqueUsers = new Set();
				const users = [];
				for (const obj of deals_count) {
					if (!uniqueUsers.has(obj.user)) {
						uniqueUsers.add(obj.user);
						users.push(obj);
					}
				}
				item['average_deals_users'] = ((deals_count.length / 2) / users.length).toFixed(1)
			}
			if (other_statistics.includes('users_published_deals')) {
				const users_count = data['average_deals_users'].filter(el => {
					let item_date
					if (difference <= 86400000) {
						const tempDate = new Date()
						tempDate.setHours(item.date.split(":")[0])
						item_date = tempDate
					} else item_date = parseDate(item.date)
					if (new Date(parseInt(el.date)) <= item_date) {
						return true
					} else return false
				})
				const uniqueUsers = new Set();
				const users = [];
				for (const obj of users_count) {
					if (!uniqueUsers.has(obj.user)) {
						uniqueUsers.add(obj.user);
						users.push(obj);
					}
				}
				item['users_published_deals'] = users.length
			}
			if (other_statistics.includes('users_not_published_deals')) {
				const users_count = data['users_not_published_deals'].filter(el => {
					let item_date
					if (difference <= 86400000) {
						const tempDate = new Date()
						tempDate.setHours(item.date.split(":")[0])
						item_date = tempDate
					} else item_date = parseDate(item.date)
					if (new Date(parseInt(el.dateRegister)) <= item_date) {
						return true
					} else return false
				})
				item['users_not_published_deals'] = users_count.length
			}
			return item
		})
		return result
	}
}

function findStartDate(data) {
	const dates = [];
	for (let key in data) {
		if (!STATISTICS_IGNORE_DATERANGE.includes(key)) {
			data[key].forEach(el => dates.push(el))
		}
	}
	// return 1645558952286
	return Math.min(...dates);
}