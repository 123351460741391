import { Form } from "react-bootstrap"
import { INPUTS_TEXT } from "../../utils/textsContent"
import React, { useMemo } from "react"

const BlockWithTranslationFields = ({
	handleBlur,
	values,
	errors,
	touched,
	handleChange,
	formDateUpdateHandler,
	name,
	title
}) => {

	const titleName = useMemo(() => {
		let res = {}
		if (title === 'title') {
			res = {
				ua: `${INPUTS_TEXT?.TITLE_UA}`,
				ru: `${INPUTS_TEXT?.TITLE_RU}`,
				en: `${INPUTS_TEXT?.TITLE_EN}`,
				pl: `${INPUTS_TEXT?.TITLE_PL}`
			}
		} else if (title === 'name') {
			res = {
				ua: `${INPUTS_TEXT?.NAME_UA}`,
				ru: `${INPUTS_TEXT?.NAME_RU}`,
				en: `${INPUTS_TEXT?.NAME_EN}`,
				pl: `${INPUTS_TEXT?.NAME_PL}`
			}
		}else if (title === 'description') {
			res = {
				ua: `${INPUTS_TEXT?.DESCRIPTION_UA}`,
				ru: `${INPUTS_TEXT?.DESCRIPTION_RU}`,
				en: `${INPUTS_TEXT?.DESCRIPTION_EN}`,
				pl: `${INPUTS_TEXT?.DESCRIPTION_PL}`
			}
		}else if (title === 'placeholder') {
			res = {
				ua: `${INPUTS_TEXT?.PLACEHOLDER_UA}`,
				ru: `${INPUTS_TEXT?.PLACEHOLDER_RU}`,
				en: `${INPUTS_TEXT?.PLACEHOLDER_EN}`,
				pl: `${INPUTS_TEXT?.PLACEHOLDER_PL}`
			}
		}else if (title === 'subscription') {
			res = {
				ua: `${INPUTS_TEXT?.SUBSCRIPTION_UA}`,
				ru: `${INPUTS_TEXT?.SUBSCRIPTION_RU}`,
				en: `${INPUTS_TEXT?.SUBSCRIPTION_EN}`,
				pl: `${INPUTS_TEXT?.SUBSCRIPTION_PL}`
			}
		}
		return res
	}, [title])

	return (
		<div className='add-edit-terms_top'>
			<Form.Group
				className='add-edit-terms_label'
			>
				<h3 className='subscription-create_wrapper-date_block-title'>{titleName?.ua}</h3>
				<Form.Control
					type='text'
					name={`ua${name}`}
					onBlur={handleBlur}
					value={values?.[`ua${name}`]}
					onKeyDown={e => {
						e.key === 'Enter' && e.preventDefault()
					}}
					className={`add-edit-terms_inp  ${touched[`ua${name}`] ? "is-touch " : ""} ${
						errors[`ua${name}`] && touched[`ua${name}`] ? " is-invalid" : ""
					} `}
					onChange={(e) => {
						handleChange(e)
						formDateUpdateHandler({
							[e.target.name]: e.target.value
						})
					}}
				/>
				{errors[`ua${name}`] && touched[`ua${name}`] && (
					<Form.Control.Feedback type='invalid'>
						{errors[`ua${name}`]}
					</Form.Control.Feedback >
				)}
			</Form.Group >
			<Form.Group
				className='add-edit-terms_label'
			>
				<h3 className='subscription-create_wrapper-date_block-title'>{titleName?.ru}</h3>
				<Form.Control
					type='text'
					name={`ru${name}`}
					onKeyDown={e => {
						e.key === 'Enter' && e.preventDefault()
					}}
					onBlur={handleBlur}
					className={`add-edit-terms_inp  ${touched[`ru${name}`] ? "is-touch " : ""} ${
						errors[`ru${name}`] && touched[`ru${name}`] ? " is-invalid" : ""
					} `}
					value={values?.[`ru${name}`]}
					onChange={(e) => {
						handleChange(e)
						formDateUpdateHandler({
							[e.target.name]: e.target.value
						})
					}}
				/>
				{errors[`ru${name}`] && touched[`ru${name}`] && (
					<Form.Control.Feedback type='invalid'>
						{errors[`ru${name}`]}
					</Form.Control.Feedback >
				)}
			</Form.Group >
			<Form.Group
				className='add-edit-terms_label'
			>
				<h3 className='subscription-create_wrapper-date_block-title'>{titleName?.en}</h3>
				<Form.Control
					type='text'
					name={`en${name}`}
					onKeyDown={e => {
						e.key === 'Enter' && e.preventDefault()
					}}
					onBlur={handleBlur}
					className={`add-edit-terms_inp ${touched[`en${name}`] ? "is-touch " : ""} ${
						errors[`en${name}`] && touched[`en${name}`] ? " is-invalid" : ""
					}`}
					value={values?.[`en${name}`]}
					onChange={(e) => {
						handleChange(e)
						formDateUpdateHandler({
							[e.target.name]: e.target.value
						})
					}}
				/>
				{errors[`en${name}`] && touched[`en${name}`] && (
					<Form.Control.Feedback type='invalid'>
						{errors[`en${name}`]}
					</Form.Control.Feedback >
				)}
			</Form.Group >
			<Form.Group
				className='add-edit-terms_label'
			>
				<h3 className='subscription-create_wrapper-date_block-title'>{titleName?.pl}</h3>
				<Form.Control
					type='text'
					name={`pl${name}`}
					onKeyDown={e => {
						e.key === 'Enter' && e.preventDefault()
					}}
					onBlur={handleBlur}
					className={`add-edit-terms_inp ${touched[`pl${name}`] ? "is-touch " : ""} ${
						errors[`pl${name}`] && touched[`pl${name}`] ? " is-invalid" : ""
					}`}
					value={values?.[`pl${name}`]}
					onChange={(e) => {
						handleChange(e)
						formDateUpdateHandler({
							[e.target.name]: e.target.value
						})
					}}
				/>
				{errors[`pl${name}`] && touched[`pl${name}`] && (
					<Form.Control.Feedback type='invalid'>
						{errors[`pl${name}`]}
					</Form.Control.Feedback >
				)}
			</Form.Group >
		</div >
	)
}

export default BlockWithTranslationFields