import React, {
	useEffect,
	useState
} from "react"
import { useSelector } from "react-redux"
import AddEditUnitsMeasurementItem from "../../components/modalsAddEdit/AddEditUnitsMeasurementItem"
import ControlPanels from "../../components/environmentTabl/ControlPanels"

const ChangeUnitsForAmount = () => {
	const { unitsMeasurement } = useSelector( ( state ) => state.unitsMeasurementStore )
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ addUnitFromAmountShowModal, setAddUnitFromAmountShowModal ] = useState( false )

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
	}

	const openEditModalHandler = ( value ) => {
		setAddEditModalShow( true )
	}

	const showModalAddUnitForAmountHandler = () => {
		setAddUnitFromAmountShowModal( true )
	}
	const hideModalAddUnitForAmountHandler = () => {
		setAddUnitFromAmountShowModal( !addUnitFromAmountShowModal )
	}

	return (
		<div className='changeUnitForPrice'>
			<div className='changeUnitForPrice-wrapper changeUnitForTemplates'>
				<ControlPanels
					bigArrElements={ unitsMeasurement }
					showModalHandler={ showModalHandler }
					openEditModalHandler={ openEditModalHandler }
					fromPage={ 'changeUnitForAmount' }
					showModalAddUnitHandler={ showModalAddUnitForAmountHandler }
					searchTermValue={ searchTermValue }
					loaderSorting={ loaderSorting }
					setLoaderSorting={ setLoaderSorting }
					toggleModalSearch={ toggleModalSearch }
					showModalSearch={ showModalSearch }
					showSearchBeforeDel={ showSearchBeforeDel }
					setShowSearchBeforeDel={ setShowSearchBeforeDel }
					valueSearchInputHandler={ valueSearchInputHandler }
				/>
				{
					addUnitFromAmountShowModal && <AddEditUnitsMeasurementItem
						show={ addUnitFromAmountShowModal }
						onHide={ hideModalAddUnitForAmountHandler }
					/>
				}
			</div >
		</div >
	)
}

export default ChangeUnitsForAmount
