import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import { ADVERTISER_API } from "../../../utils/constants";
const API = process.env.REACT_APP_API_URL;

export const advertiserApi = createApi( {
	reducerPath: "advertiserApi",
	tagTypes: [ 'Company', 'User' ],
	baseQuery: fetchBaseQuery( {
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{ getState }
		) => {
			const { token } = getState().userStore;
			if ( token ) {
				headers.set( "Authorization", `Bearer ${ token }` )
			}
			return headers
		}
	} ),

	endpoints: ( builder ) => ({
		advertiserGetCompanies: builder.query({
            query: (query) => ({
                url: ADVERTISER_API.GET_COMPANIES,
                method: "GET",
                params: query
            }),
            providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Company', id })),
					{ type: 'Company', id: 'LIST' },
					]
				: [{ type: 'Company', id: 'LIST' }],
        }),

        advertiserGetCompany: builder.query({
            query: (query) => ({
                url: ADVERTISER_API.GET_COMPANY,
                method: "GET",
                params: query
            }),
            providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Company', id })),
					{ type: 'Company', id: 'LIST' },
					]
				: [{ type: 'Company', id: 'LIST' }],
        }),

        advertiserChangeAnnouncementStatus: builder.mutation( {
			query: ( body ) => (
				{
					url: ADVERTISER_API.CHANGE_ANNOUNCEMENT_STATUS,
					method: "POST",
					body: body
				}
			),
			invalidatesTags: [ { type: 'Company', id: 'LIST' } ]
		} ),

		advertiserChangeCompanyStatus: builder.mutation( {
			query: ( body ) => (
				{
					url: ADVERTISER_API.CHANGE_COMPANY_STATUS,
					method: "POST",
					body: body
				}
			),
			invalidatesTags: [ { type: 'User', id: 'LIST' } ]
		} ),

		advertiserGetUsers: builder.query({
            query: (query) => ({
                url: ADVERTISER_API.GET_USERS,
                method: "GET",
                params: query
            }),
            providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'User', id })),
					{ type: 'User', id: 'LIST' },
					]
				: [{ type: 'User', id: 'LIST' }],
        }),

		advertiserGetRefillHistory: builder.query({
            query: (data) => ({
                url: ADVERTISER_API.GET_REFILL_HISTORY,
                method: 'GET',
                params: data
            })
        }),

		advertiserGetPrices: builder.query({
            query: (data) => ({
                url: ADVERTISER_API.GET_PRICES,
                method: 'GET',
                params: data
            })
        }),

		advertiserChangeMarkerStatusStatus: builder.mutation( {
			query: ( body ) => (
				{
					url: ADVERTISER_API.CHANGE_MARKER_STATUS,
					method: "POST",
					body: body
				}
			),
			invalidatesTags: [ { type: 'User', id: 'LIST' } ]
		} ),
	})
} );

export const {
	useAdvertiserGetCompaniesQuery,
    useAdvertiserGetCompanyQuery,
    useAdvertiserChangeAnnouncementStatusMutation,
	useAdvertiserGetUsersQuery,
	useAdvertiserChangeCompanyStatusMutation,
	useAdvertiserGetRefillHistoryQuery,
	useAdvertiserGetPricesQuery,
	useAdvertiserChangeMarkerStatusStatusMutation
} = advertiserApi
