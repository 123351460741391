import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	ADVERTISING_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const advertisingApi = createApi({
	reducerPath: "advertisingApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getAdvertising: builder.mutation({
			query: () => (
				{
					url: ADVERTISING_API.GET_ADVERTISING,
					method: "GET",
				})
		}),
		createAdvertising: builder.mutation({
			query: (body) => (
				{
					url: ADVERTISING_API.CREATE_ADVERTISING,
					method: "POST",
					body
				})
		}),
		editAdvertising: builder.mutation({
			query: (body) => (
				{
					url: ADVERTISING_API.EDIT_ADVERTISING,
					method: "POST",
					body
				})
		}),
		deleteAdvertising: builder.mutation({
			query: (body) => (
				{
					url: ADVERTISING_API.DELETE_ADVERTISING,
					method: "POST",
					body
				})
		}),
	})
});

export const {
	useGetAdvertisingMutation,
	useCreateAdvertisingMutation,
	useEditAdvertisingMutation,
	useDeleteAdvertisingMutation,


} = advertisingApi
