import React, { useState } from 'react';
import ControlPanelsV2 from '../../components/environmentTabl/ControlPanelsV2';
import { useNavigate } from 'react-router-dom';
import { useGetDealsButtonsQuery, useGetDealsButtonsTemplatesQuery } from '../../store/apis/dealsApi';
import { ReactComponent as EditIcon } from '../../../utils/icons/edit-pencil.svg'
import { ReactComponent as DeleteIcon } from '../../../utils/icons/delete-icon.svg'
import DeleteModal from '../../components/DeleteModal';
import { CreateMessagesTemplate } from './CreateDealsMessagesTemplate/CreateMessagesTemplate';
import Loader from '../../components/LoaderContent';
import s from './dealsMessagesTemplates.module.scss';
import { DealsMessagesTemplateModal } from './DealsMessagesTemplateModal/DealsMessagesTemplateModal';
import { useTranslation } from 'react-i18next';

export const DealsMessagesTemplates = ({ isModal, modalType, changedItems, setItems, close }) => {

    const [showAddModal, setShowAddModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedItems, setSelectedItems] = useState(changedItems || [])
    const [query, setQuery] = useState({search: undefined})
    const navigate = useNavigate()
	const {t, i18n} = useTranslation()

    const {data: templates, isLoading} = useGetDealsButtonsTemplatesQuery(query)
	const {data: buttons, isLoadingButtons} = useGetDealsButtonsQuery(query)

    const table_columns = [isModal? '' : "ID", t('title'), t('info'), ""]

    const table_rows = templates?.map(el => {
		return [
			{type: isModal? modalType === "checkbox"? 'checkbox' : 'radio' : "default", value: el.id},
			{type: "default", value: el.title?.title?.[i18n.language]},
			{type: "modal", value: <DealsMessagesTemplateModal data={el} buttons={buttons}/>},
			{type: "icons", value: [<EditIcon onClick={()=>navigate(`/deals/messages/template/create?id=${el.id}`)}/>, <DeleteIcon onClick={()=>setShowDeleteModal(el.id)}/>]}
		]
	})

    function setItemsFromModal(){
		setItems(selectedItems)
		close()
	}

	function changeItem(id, action){
		const item = templates.find(el => el.id === id)
		if(modalType === "checkbox"){
			if(action) setSelectedItems(prev => {return [...prev, item]})
			else{
				setSelectedItems(prev => {
					let newState = JSON.parse(JSON.stringify(prev))
					newState = newState.filter(el => el.id !== id)
					return newState
				})
			}
		}
		if(modalType === "radio"){
			const item = templates.find(el => el.id === id)
			setSelectedItems([item])
		}
	}

    if((isLoading || isLoadingButtons)) return <Loader/>
    
    return(
        <div className={`${isModal ? s.modal_type : ''} terms`}>
			<>
			{isModal? null : <h1 className='terms-title'>{t('deals_messages_title_templates')}</h1 >}
			</>
            <DeleteModal
				show={ showDeleteModal }
				onHide={ setShowDeleteModal }
				value={ showDeleteModal }
				fromPage={ 'dealsbuttonsTemplate' }
			/>
            <ControlPanelsV2 
					rows={table_rows}
					columns={table_columns} 
					totalCount={templates?.length} 
					setShowAddModal={()=> isModal? setShowAddModal(true) : navigate('/deals/messages/template/create')}
					showAddModal={showAddModal}
					addElementComponent = {<CreateMessagesTemplate isModal={true} close={setShowAddModal}/>}
					isLoading={(isLoading || isLoadingButtons)}
					changeItem={changeItem}
					isModal={isModal}
					query={query}
					setQuery={setQuery}
					selectedItems={selectedItems.map(el => {return el.id? el.id : el})}
					setItemsFromModal={setItemsFromModal}
					fromPage="dealsMessagess"
				/>
		</div >
    )
}