import React from 'react'
import { Form } from "react-bootstrap"
import {
	ARE_COMMON
} from "../../../../utils/textsContent"
import BlockWithTranslationFieldsTemplatesContent from "../../../components/BlockWithTranslationFieldsTemplatesContent";
import { useTranslation } from 'react-i18next';

const NameBlockTemplateContent = ( {
	handleBlur,
	values,
	psevdoValue,
	touched,
	errors,
	handleChange,
	formDateUpdateHandler,
	setForm
} ) => {

	const {t} = useTranslation()

	return (
		<div className='template-content_nameBlock'>
			<h2 className='template-content_nameBlock-title'>
				1. {t('tf_enter_templateName')}
			</h2 >
			<BlockWithTranslationFieldsTemplatesContent
				handleChange={handleChange}
				values={values}
				errors={errors}
				touched={touched}
				handleBlur={handleBlur}
				formDateUpdateHandler={formDateUpdateHandler}
				name={'Title'}
				title={'name'}
				setForm={setForm}
			/>
			<Form.Group className='template-content_nameBlock-label_psevdo mt-3'>
				<p >
					Mark
				</p >
				<Form.Control
					type='text'
					name='psevdo'
					onBlur={ handleBlur }
					value={ psevdoValue }
					onKeyDown={ e => {
						e.key === 'Enter' && e.preventDefault()
					} }
					className={ `template-content_nameBlock-inp_psevdo mt-1  ${ touched?.psevdo ? "is-touch " : "" } ${
						errors?.psevdo && touched?.psevdo ? " is-invalid" : ""
					} ` }
					onChange={ ( e ) => {
						handleChange( e )
						formDateUpdateHandler( {
							[e.target.name]: e.target.value
						} )
					} }
				/>
				{ errors.psevdo && touched.psevdo && (
					<Form.Control.Feedback type='invalid'>
						{ errors.psevdo }
					</Form.Control.Feedback >
				) }
			</Form.Group >
		</div >
	)
}

export default NameBlockTemplateContent