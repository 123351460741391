import { useEffect, useState } from 'react';
import { useGetAdvertsQuery } from '../../store/apis/noticeApi';
import s from './advertsPage.module.scss';
import Loader from '../../components/LoaderContent'
import PaginationCustom from '../../components/PaginationCustom';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchItemsInput from '../../components/SearchItemsInput';
import { NewTabs } from '../../components/newTabs/NewTabs';
import SelectShowRecords from '../../components/SelectShowRecords';

export const AdvertsPage = ({isModal}) => {

    const {t, i18n} = useTranslation()

	const statuses = [
		{id: 0, title: t('u_om')},
		{id: 1, title: t('active'), class: "active"},
		{id: 2, title: t('dnpm'), class: "rejected"},
		{id: 3, title: t('blocked'), class: "banned"},
		{id: 4, title: t('deleted'), class: "deleted"}
	]
	
	const tabs_items = [
		{ id: 0, title: t('u_om'), active: false },
		{ id: 1, title: t('all'), active: false },
		{ id: 2, title: t('active'), active: false },
		{ id: 3, title: t('dnpm'), active: false },
		{ id: 4, title: t('blocked'), active: false },
		{ id: 5, title: t('deleted'), active: false }
	]

	// ### State
	const [query, setQuery] = useState({
		limit: 20,
		page: 1,
		order: "desc",
		sort: "date",
		moderation_status: 0,
		status: undefined,
		template: undefined,
		subcategory: undefined,
		category: undefined,
		searchQuery: undefined,
		lang: i18n.language
	})

	const [params, setParams] = useSearchParams()
	const query_page = params.get('page')
    const query_limit = params.get('limit')
	const query_type = params.get('type')
	const user_type = params.get('id')

	const [tab, setTab] = useState(query_type || tabs_items[0].id)
	const [searchValue, setSearchValue] = useState(undefined)

	const navigate = useNavigate()

	// ### Effects
	const {data: adverts, isFetching: isAdvertsLoading} = useGetAdvertsQuery(query)

    useEffect(()=>{
        if(query_page) setQuery(prev => {return {...prev, page: query_page}})
        if(query_limit) setQuery(prev => {return {...prev, limit: query_limit}})
        if(user_type) setQuery(prev => {return {...prev, user: user_type, moderation_status: undefined}})
        if(query_type) changeStatus(Number(query_type))
    },[query_page, query_limit, query_type, user_type]);
	
	// ### Functions
	function changeSort(sort){
		setQuery(prev => { return {...prev, sort: sort, order: prev.order === 'desc' ? 'asc' : 'desc'}})
	}

	function changeStatus(status){
		setTab(status)
		switch (status) {
			case 0:
				setQuery(prev => { return {...prev, status: 0, moderation_status: 0}})
				break;
			case 1:
				setQuery(prev => { return {...prev, status: undefined, moderation_status: undefined}})
				break;
			case 2:
				setQuery(prev => { return {...prev, status: 1, moderation_status: 1}})
				break;
			case 3:
				setQuery(prev => { return {...prev, status: 0, moderation_status: 2}})
				break;
			case 4:
				setQuery(prev => { return {...prev, status: 2, moderation_status: 2}})
				break;
			case 5:
				setQuery(prev => { return {...prev, status: 3, moderation_status: 2}})
				break;

			default:
				break;
		}
	}

	function getCurrentStatus(status, moderationStatus){
		if(status === 0 && moderationStatus === 0) return statuses[0]
		if(status === 1 && moderationStatus === 1) return statuses[1]
		if(status === 0 && moderationStatus === 2) return statuses[2]
		if(status === 2 && moderationStatus === 2) return statuses[3]
		if(status === 3 && moderationStatus === 2) return statuses[4]
	}
	
	// ### Views
	const adverts_view = adverts?.adverts?.map((el, index) => (
		<tr key={index} onClick={()=>navigate(`/advert?id=${el.id}`)}>
			<td className={s.id}>{el.id}</td>
			<td style={{width: '30%'}}>{el.title}</td>
			<td>{el.category?.[i18n.language]}</td>
			<td>{el.subcategory?.[i18n.language]}</td>
			<td>{new Date(parseInt(el.date)).toLocaleString()}</td>
			<td style={{width: '5%'}}>
				<span className={`${s.status} ${s[getCurrentStatus(el.status, el.moderation_status)?.class]}`}>{getCurrentStatus(el.status, el.moderation_status)?.title}</span>
			</td>
		</tr>
	))

    return(
        <div className='terms' style={{marginLeft: isModal ? 0 : 230, padding: isModal? 0 : 30}}>
			{ !isModal ? <h1 className='terms-title'>{t('s_ad')}</h1 > : null }
			<div className='terms-wrapper'>
                <div className={s.wrapper}>
					{ !isModal ?  <NewTabs items={tabs_items} tab={tab} setTab={(status)=>{setParams({limit: params.get('limit') || query.limit, page: 1, type: status}); changeStatus(status)}}/> : null }

                    { !isModal ?
					<header className={s.header}>
                        <div className={s.header_row}>
                            <SearchItemsInput
                                toggleModalSearch={()=>{}}
                                searchTermValue={searchValue}
                                valueSearchInputHandler={(value) => setSearchValue(value)}
                                searchHandler={()=>{setQuery(prev => { return { ...prev, searchQuery: searchValue } })}}
                                clearSearchHandler={()=>{
                                    setQuery(prev => { return {...prev, searchQuery: undefined} })
                                    setSearchValue('')
                                }}
                            />
                            <PaginationCustom
                                itemsCount={query.limit}
                                currentPage={query.page}
                                setCurrentPage={(page)=>{setParams({limit: params.get('limit') || query.limit, type: params.get('type') || tab, page})}}
                                counterAllItems={adverts?.totalItems}
                            />
                        </div>
                        <SelectShowRecords countShowTemplates={params.get('limit')} setCountShow={(limit) => setParams({limit, page: params.get('page') || query.page, type: params.get('type') || tab})} />
                    </header> :null
					}
                    
                    <table className={s.table}>
                        <thead>
                            <tr>
                                <td className={`${s.id} ${s.cursor}`} onClick={()=>changeSort("id")}>ID</td>
                                <td className={`${s.cursor}`} style={{width: '20%'}} onClick={()=>changeSort("title")}>{t('name')}</td>
                                <td className={`${s.cursor}`} onClick={()=>changeSort(`category.${i18n.language}`)}>{t('category')}</td>
                                <td className={`${s.cursor}`} onClick={()=>changeSort(`subcategory.${i18n.language}`)}>{t('subcategory')}</td>
                                <td className={`${s.cursor}`} onClick={()=>changeSort("date")}>{t('date_created')}</td>
                                <td style={{width: '5%'}}>{t('status')}</td>
                            </tr>
                        </thead>
                        <tbody>
                        { isAdvertsLoading ? <Loader /> : adverts_view }
                        </tbody>
                    </table>
                    { (!adverts_view?.length && !isAdvertsLoading) ? <h1 className={s.no_data}>Not found</h1> : null}
                </div>
            </div>
        </div>
    )
}