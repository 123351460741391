import s from './viewImageModal.module.scss';
import { ReactComponent as CloseIcon } from '../../../utils/icons/close-classik-icon.svg'

export const ViewImageModal = ({link, close}) => {

    return(
        <div className={s.modal_wrapper}>
            <div className={s.back_wrapper}></div>
            <div className={s.image_wrapper}>
                <div className={s.close_wrapper} onClick={()=>close(false)}><CloseIcon/></div>
                <img src={link} alt="image" />
            </div>
        </div>
    )
}