import { UniqueArrItems } from "./UniqueArrItems"

export const ShowFirstSelectedListItems = (storeArr, arrAllItems) => {
	let tehArrNotChange = []
	let tehArrChange = []
	const uniqItemsInArr = UniqueArrItems( storeArr )
	if ( uniqItemsInArr?.length > 0 ) {
		for ( let i = 0; i < arrAllItems?.length; i++ ) {
			for ( const key in uniqItemsInArr ) {
				if ( Number( arrAllItems[i]?.id ) === Number( uniqItemsInArr[key] ) ) {
					tehArrChange = [ ...tehArrChange, { ...arrAllItems[i], markerChangeForList: true } ]
				}
			}
			tehArrNotChange = [ ...tehArrNotChange, { ...arrAllItems[i], markerChangeForList: false } ]
		}
	}

	const arrType = uniqItemsInArr?.map( item => Number( item ) )

	const arr = tehArrNotChange.filter( ( { id } ) => !arrType.includes( id ) )

	return [ ...tehArrChange, ...arr ]
}

export const ShowFirstSelectedListItemsTemplate = (storeArr, arrAllItems, changedItems) => {
	let tehArrNotChange = []
	let tehArrChange = []
	const uniqItemsInArr = UniqueArrItems( storeArr )
	if ( uniqItemsInArr?.length > 0 ) {
		for ( let i = 0; i < arrAllItems?.length; i++ ) {
			for ( const key in uniqItemsInArr ) {
				if ( Number( arrAllItems[i]?.id ) === Number( uniqItemsInArr[key] ) ) {
					tehArrChange = [ ...tehArrChange, { ...arrAllItems[i], markerChangeForList: true } ]
				}
				if ( Number( arrAllItems[i]?.id ) === Number( uniqItemsInArr[key].id ) ) {
					tehArrChange = [ ...tehArrChange, { ...arrAllItems[i], markerChangeForList: true } ]
				}
			}
			tehArrNotChange = [ ...tehArrNotChange, { ...arrAllItems[i], markerChangeForList: false } ]
		}
	}

	// const arrType = uniqItemsInArr?.map( item => Number( item ) )

	// const arr = tehArrNotChange.filter( ( { id } ) => !arrType.includes( id ) )
	const changedIds = []
	const changedArr = changedItems.map(el => {
		const template = {
			...JSON.parse(JSON.stringify(el.content)),
			markerChangeForList: true
		}
		changedIds.push(template.id)
		return template
	})
    
	return [...changedArr, ...arrAllItems.filter(el => !changedIds.includes(el.id))]
}