import React from 'react'
import EyeOpen from "../../../../utils/icons/eye-open.svg"
import Delete from "../../../../utils/icons/delete-icon.svg"

const RbacTablWorkerItem = ( {user, setCurrentUser, toggleDeleteModal} ) => {
	return (
		<tr >
			<td className='users-wrapper_tabl-body_item col-4'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
				<span >{user.pib}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-2'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{user.role?.title}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-3'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{user.email}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-2'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{user.login}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-2'>
				<div
					className='users-wrapper_tabl-body_item-wrapper_icons'
					style={ { right: '45px' } }
				>
					<button
						type='button'
						onClick={ () => setCurrentUser(user) }
					>
						<img
							src={ EyeOpen }
							alt='Eye'
						/>
					</button >
					<button
						type='button'
						style={ { marginLeft: '15px' } }
						onClick={() => toggleDeleteModal(user.id)}
					>
						<img
							src={ Delete }
							alt='email'
						/>
					</button >
				</div >
			</td >
		</tr >
	)
}

export default RbacTablWorkerItem