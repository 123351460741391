import { createSlice } from "@reduxjs/toolkit"
import { templateContentApi } from "../apis/temlateContentApi"
import { UniqueArrItems } from "../../../utils/helpers/UniqueArrItems";

const initialState = {
	templateContent: [],
	showModalChangeUnitTemplates: false,
	dateModalChangeUnitTemplates: null,
	dateModalChangeUnitTemplatesList: [],
	changeTabName: ''
}

const templateContentSlice = createSlice({
	name: "templateContent",
	initialState,
	reducers: {
		createNewTemplateContentItem: (state, action) => {
			state.templateContent = [action.payload, ...state.templateContent]
		},
		editTemplateContentItem: (state, action) => {
			const index = state.templateContent.findIndex(
				(templateContent) => {
					return templateContent.id === action.payload.id
				}
			)
			state.templateContent[index] = action.payload
		},
		deleteTemplateContentItem: (state, action) => {
			const templateContentId = action.payload
			const templateContentID = state.templateContent.filter(templateContent => templateContent.id !== templateContentId)
			state.templateContent = templateContentID
		},
		showChangeUnitModalTemplates: (state, action) => {
			state.showModalChangeUnitTemplates = action.payload
		},
		setDateChangeUnitTemplates: (state, action) => {
			state.dateModalChangeUnitTemplates = action.payload
		},
		setTabName: (state, action) => {
			state.changeTabName = action.payload
		},
		resetDateChangeUnitTemplates: (state, action) => {
			state.dateModalChangeUnitTemplates = {}
		},
		setDateChangeUnitTemplatesList: (state, action) => {
			if(action.payload){
				const uniq = UniqueArrItems([...state.dateModalChangeUnitTemplatesList, action.payload])
				state.dateModalChangeUnitTemplatesList = uniq
			}
		},
		resetDateChangeUnitTemplatesList: (state, action) => {
			state.dateModalChangeUnitTemplatesList = []
		},
		deleteDateChangeUnitTemplatesList: (state, action) => {
			const dateModalChangeUnitTemplatesListId = Number(action.payload)
			const dateModalChangeUnitTemplatesListID = state.dateModalChangeUnitTemplatesList.filter(item => Number(item) !== dateModalChangeUnitTemplatesListId)
			state.dateModalChangeUnitTemplatesList = dateModalChangeUnitTemplatesListID
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(templateContentApi.endpoints.getTemplateContent.matchFulfilled, (
					state,
					action
				) => {
					state.templateContent = action.payload.data
				}
			)
			.addMatcher(templateContentApi.endpoints.getTemplateContentV2.matchFulfilled, (
					state,
					action
				) => {
					state.templateContent = action.payload.templateContents
				}
			)
	}
})

const {
	actions,
	reducer
} = templateContentSlice
export const {
	createNewTemplateContentItem,
	deleteTemplateContentItem,
	editTemplateContentItem,
	showChangeUnitTemplateContentModal,
	setDateChangeUnitTemplateContent,
	showChangeUnitModalTemplates,
	setDateChangeUnitTemplates,
	setDateChangeUnitTemplatesList,
	deleteDateChangeUnitTemplatesList,
	resetDateChangeUnitTemplatesList,
	resetDateChangeUnitTemplates,
	setTabName
} = actions
export default reducer
