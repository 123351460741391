import { SOCIAL } from "../constants"

const footer_text = {
    footer1: {
        en: "Best regards,",
        ua: "З повагою,",
        ru: "С уважением,",
        fr: "Cordialement,",
        cn: "致敬,",
        th: "ด้วยความเคารพ"
    },
    footer2: {
        en: "TENTAI Marketplace Team",
        ua: "Команда TENTAI Marketplace",
        ru: "Команда TENTAI Marketplace",
        fr: "Équipe de TENTAI Marketplace",
        cn: "TENTAI Marketplace团队",
        th: "ทีม TENTAI Marketplace"
    },
    copyrates: {
        en: "© 2024 Tentai - Book, rent, buy, sell with just one click!",
        ua: "© 2024 Tentai - Бронюйте, орендуйте, купуйте, продавайте одним кліком!",
        ru: "© 2024 Tentai - Бронируйте, арендуйте, покупайте, продавайте одним кликом!",
        fr: "© 2024 Tentai - Réservez, louez, achetez, vendez en un clic !",
        cn: "© 2024 Tentai - 一键预订、租赁、购买、销售！",
        th: "© 2024 Tentai - จอง, เช่า, ซื้อ, ขาย ด้วยคลิกเดียว!"
    },
    unsubscribe: {
        en: "Unsubscribe",
        ua: "Відписатись",
        ru: "Отписаться",
        fr: "Se désabonner",
        cn: "退订",
        th: "ยกเลิกการสมัคร"
    }
}
const links = {
    support: "https://tentai.pro",
    unsubscribe: "https://tentai.pro/profile/settings/notifications"
}

export function ConfigureEmail({template, data, lang}){

    const social_links = getSocialLinks(lang)
    let newTitle = setColorTitle(data?.title, "TENTAI")
    newTitle = setColorTitle(newTitle, "Tentai")
    newTitle = setColorTitle(newTitle, "tentai")
    newTitle = setColorTitle(newTitle, "MARKETPLACE!")
    newTitle = setColorTitle(newTitle, "Marketplace!")
    newTitle = setColorTitle(newTitle, "marketplace!")
    newTitle = setColorTitle(newTitle, "MARKETPLACE")
    newTitle = setColorTitle(newTitle, "Marketplace")
    newTitle = setColorTitle(newTitle, "marketplace")
    
    template = template?.replace('$body', data?.body)
    template = template?.replace('$title', newTitle)

    // replace footer
    template = template?.replace('$footer_text1', footer_text?.footer1[lang])
    template = template?.replace('$footer_text2', footer_text?.footer2[lang])
    template = template?.replace('$copyrates', footer_text?.copyrates[lang])
    template = template?.replace('$unsubscribe', footer_text?.unsubscribe[lang])
    // replace link
    template = template?.replace('$support_link', links.support)
    template = template?.replace('$unsubscrib_link', links?.unsubscribe)
    // replace social link
    template = template?.replace('$instagram_link', social_links.instagram)
    template = template?.replace('$telegram_link', social_links.telegram)
    template = template?.replace('$facebook_link', social_links.facebook)
    template = template?.replace('$youtube_link', social_links.youtube)
    template = template?.replace('$line_link', social_links.line)

    return template
}

function getSocialLinks(lang){
    const current_telegram = SOCIAL[`telegram_${lang}`] ? SOCIAL[`telegram_${lang}`] : SOCIAL['telegram_en']
    const current_instagram = SOCIAL[`instagram_${lang}`] ? SOCIAL[`instagram_${lang}`] : SOCIAL[`instagram_en`]
    const current_facebook = SOCIAL[`facebook_${lang}`] ? SOCIAL[`facebook_${lang}`] : SOCIAL[`facebook_en`]
    const current_youtube = SOCIAL[`youtube_${lang}`] ? SOCIAL[`youtube_${lang}`] : SOCIAL[`youtube_en`]
    const current_line = SOCIAL[`line_${lang}`] ? SOCIAL[`line_${lang}`] : SOCIAL[`line_en`]
    return {
        telegram: current_telegram,
        instagram: current_instagram,
        facebook: current_facebook,
        youtube: current_youtube,
        line: current_line
    }
}

function setColorTitle(title, word){
    let index = title?.indexOf(word);

    if (index !== -1) {
        let before = title?.substring(0, index);
        let after = title?.substring(index + word.length);
        let highlightedStr = before + "<span class='color_text'>"+word+"</span>" + after;
        return highlightedStr
    } else {
        return title
    }
}