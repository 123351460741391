import {
	Button,
	Form,
	Modal
} from "react-bootstrap"
import { Formik } from "formik"
import React, {
	useEffect,
	useState
} from "react"
import Loader from "../Loader"
import {
	useDispatch,
} from "react-redux"
import AlertModal from "../AlertModal"
import {
	createNewMarkItem,
	editMarkItem,
} from "../../store/slices/markSlice"
import {
	useCreateMarkMutation,
	useEditMarkMutation,
} from "../../store/apis/markApi"
import { ARE_COMMON } from "../../../utils/textsContent";

const initialState = {
	name: ''
}

const AddEditMarkItem = ( {
	show,
	onHide,
	edit,
	editDateForModal
} ) => {
	const [ form, setForm ] = useState( edit ? editDateForModal : initialState )
	const [ createMark, { isLoading: isCreateMarkLoader } ] = useCreateMarkMutation()
	const [ editMark, { isLoading: iseEditMarkLoader } ] = useEditMarkMutation()
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const dispatch = useDispatch()

	const isLoading = isCreateMarkLoader || iseEditMarkLoader

	const formDateUpdateHandler = ( opt ) => {
		setForm( { ...form, ...opt } )
	}

	useEffect( () => {
		setForm(editDateForModal)
	}, [edit, editDateForModal] )

	const handleSubmit = async ( values ) => {
		const formDate = {
			name: form?.name || values?.name
		}
		try {
			if ( edit ) {
				const {error} = await editMark({
					...formDate,
					id: editDateForModal.id
				})
				!error && dispatch(editMarkItem({
					...formDate,
					id: editDateForModal.id
				}))
				error && setErrorMessage(error?.data?.mes)
				!error && await clearFormHideModal()
			} else {
				const {
					data,
					error
				} = await createMark( formDate )
				!error && dispatch( createNewMarkItem( {
					...formDate,
					id: data?.id
				} ) )
				error && setErrorMessage( error?.data?.mes )
				!error && await clearFormHideModal()
			}
		} catch ( e ) {
			console.log( e )
			setErrorMessage( e )
		}
	}

	const clearFormHideModal = async () => {
		onHide()
		setForm( {} )
	}

	return (
		<Modal
			show={ show }
			onHide={ clearFormHideModal }
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			className='add-edit-terms_modal'
			style={ { zIndex: '9999999' } }
		>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<Formik
				validateOnChange
				initialValues={ {
					name: form?.name || editDateForModal?.name
				} }
				onSubmit={ handleSubmit }
				enableReinitialize
			>
				{
					( {
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty
					} ) => (
						<>
							<Modal.Header closeButton>
								<Modal.Title
									id='contained-modal-title-vcenter'
									className='add-edit-terms_title'
								>
									{ edit ? `${ARE_COMMON?.EDITING} Mark` : `${ARE_COMMON?.CREATED} Mark` }
								</Modal.Title >
							</Modal.Header >
							<Modal.Body >
								<Form
									className='add-edit-terms'
									onSubmit={ handleSubmit }
								>
									<div className='add-edit-terms_top'>
										<Form.Group
											className='add-edit-terms_label w-100'
										>
											{ARE_COMMON?.NAME}
											<Form.Control
												type='text'
												name='name'
												onBlur={ handleBlur }
												defaultValue={ values.name }
												autoFocus
												onKeyDown={ e => {
													e.key === 'Enter' && e.preventDefault()
												} }
												className={ `add-edit-terms_center-inp  ${ touched?.name ? "is-touch " : "" } ${
													errors?.name && touched?.name ? " is-invalid" : ""
												} ` }
												onChange={ ( e ) => {
													handleChange( e )
													formDateUpdateHandler( {
														[e.target.name]: e.target.value
													} )
												} }
											/>
											{ errors.name && touched.name && (
												<Form.Control.Feedback type='invalid'>
													{ errors.name }
												</Form.Control.Feedback >
											) }
										</Form.Group >
									</div >
								</Form >
							</Modal.Body >
							<Modal.Footer >
								<Button
									type='button'
									className='add-edit-terms_close'
									onClick={ clearFormHideModal }
								>
									{ARE_COMMON?.ABOLITION}
								</Button >
								<Button
									type='submit'
									className='add-edit-terms_save d-inline-flex'
									style={ {
										maxHeight: '33px',
										display: 'flex',
										alignItems: 'center'
									} }
									disabled={ (
										!isValid &&
										dirty ||
										form?.name === ( '' || undefined )
									) }
									onClick={ handleSubmit }
								>
									{
										isLoading ? <Loader /> : (
											edit ? ARE_COMMON?.SAVE : `${ARE_COMMON?.CREATED} Mark`
										)
									}
								</Button >
							</Modal.Footer >
						</>
					)
				}
			</Formik >
		</Modal >
	)
}

export default AddEditMarkItem
