import React, {
	useCallback,
	useEffect,
	useState
} from "react"
import {
	useDispatch,
	useSelector
} from "react-redux"
import AlertModal from "../AlertModal"
import {
	Form,
	Spinner
} from 'react-bootstrap'
import { Formik } from 'formik'
import Loader from '../Loader'
import addPlus from '../../../utils/icons/addPlus.svg'
import Remove from '../../../utils/icons/delete-red-icon.svg'
import DefaultImage from '../../../utils/assets/images/defaultImage.png'
import PaperClipIcon from '../../../utils/icons/paper-clip-icon.svg'
import Resizer from 'react-image-file-resizer'
import { squeezeFiles } from '../../../utils/helpers/CompressorFiles'
import {
	createNewAdvertisingItem,
	editAdvertisingItem,
	resetDateChangeUserAdvertising,
	setDateChangeUserAdvertising,
	showChangeUserAdvertisingModal
} from '../../store/slices/advertisingSlice'
import ChangeUserForAdvertising from '../../pages/AdvertisingPage/ChangeUserForAdvertising'
import { useAddImageCategoryMutation } from '../../store/apis/categoryApi'
import { UploadingImageHelper } from '../../../utils/helpers/UploadingImageHelper'
import {
	useCreateAdvertisingMutation,
	useEditAdvertisingMutation
} from '../../store/apis/advertisingApi'
import { ARE_COMMON } from "../../../utils/textsContent"
import { useGetUsersMutation } from "../../store/apis/usersApi"
import LoaderContent from "../LoaderContent"
const IMAGE_API = process.env.REACT_APP_IMAGES_API_URL

const AddEditAdvertisingItem = ({
	onHide,
	edit,
	editDateForModal
}) => {
	const {
		showModalChangeUserAdvertising,
		dateModalChangeUserAdvertising
	} = useSelector((state) => state.advertisingStore)
	const [form, setForm] = useState(edit ? editDateForModal : {})
	const [errorMessage, setErrorMessage] = useState('')
	const [changeUserObj, setChangeUserObj] = useState({})
	const [createAdvertising, {isLoading: isCreateAdvertisingLoading}] = useCreateAdvertisingMutation()
	const [editAdvertising, {isLoading: isUpdateAdvertisingLoading}] = useEditAdvertisingMutation()
	const [getUsers, {isLoading: isGetUsersLoading}] = useGetUsersMutation()
	const [addImageCategory, {isLoading: isAddImageCategoryLoading}] = useAddImageCategoryMutation()
	const [compressorImageLoading, setCompressorImageLoading] = useState(false)
	const [compressorImage1Loading, setCompressorImage1Loading] = useState(false)
	const [compressorImage2Loading, setCompressorImage2Loading] = useState(false)
	const [compressorImage3Loading, setCompressorImage3Loading] = useState(false)
	const [compressorImage4Loading, setCompressorImage4Loading] = useState(false)
	const [image, setImage] = useState(null)
	const [imageFile, setImageFile] = useState(null)
	const [image1, setImage1] = useState(null)
	const [imageFile1, setImageFile1] = useState(null)
	const [image2, setImage2] = useState(null)
	const [imageFile2, setImageFile2] = useState(null)
	const [image3, setImage3] = useState(null)
	const [image4, setImage4] = useState(null)
	const [imageFile3, setImageFile3] = useState(null)
	const [imageFile4, setImageFile4] = useState(null)
	const dir = 'investors'

	const dispatch = useDispatch()
	const isLoading =
		isAddImageCategoryLoading ||
		isCreateAdvertisingLoading ||
		isUpdateAdvertisingLoading

	useEffect(() => {
		if (dateModalChangeUserAdvertising) {
			setChangeUserObj(dateModalChangeUserAdvertising)
		}
	}, [dateModalChangeUserAdvertising])

	const getUserInfo = useCallback(async () => {

		const { data } = await getUsers({
			limit: '&limit=20',
			page: '&page=1',
			search: `&search=${ editDateForModal?.user }`
		})
		const dataTeh = data?.data?.users?.find(user => user?.id === editDateForModal?.user)
		dispatch(setDateChangeUserAdvertising(dataTeh))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		getUserInfo()
		edit
		&&
		(form?.adsBlock?.[0]?.value?.path !== undefined && form?.adsBlock?.[0]?.position === 2
			?
			setImage(`${form?.adsBlock?.[0]?.value?.path?.indexOf('https://') === 0
				? form?.adsBlock?.[0]?.value?.path
				:
				`${IMAGE_API}${form?.adsBlock?.[0]?.value?.path}`}`)
			:
			setImage(null))

		edit
		&&
		(form?.adsBlock?.[1]?.value?.path !== undefined && form?.adsBlock?.[1]?.position === 1
			?
			setImage1(`${form?.adsBlock?.[1]?.value?.path?.indexOf('https://') === 0
				?
				form?.adsBlock?.[1]?.value?.path
				:
				`${IMAGE_API}${form?.adsBlock?.[1]?.value?.path}`}`)
			:
			setImage1(null))

		edit
		&&
		(form?.adsBlock?.[2]?.value?.path !== undefined && form?.adsBlock?.[2]?.position === 3
			?
			setImage2(`${form?.adsBlock?.[2]?.value?.path?.indexOf('https://') === 0
				?
				form?.adsBlock?.[2]?.value?.path
				:
				`${IMAGE_API}${form?.adsBlock?.[2]?.value?.path}`}`)
			:
			setImage2(null))

		edit
		&&
		(form?.adsBlock?.[3]?.value?.path !== undefined && form?.adsBlock?.[3]?.position === 4
			?
			setImage3(`${form?.adsBlock?.[3]?.value?.path?.indexOf('https://') === 0
				?
				form?.adsBlock?.[3]?.value?.path
				:
				`${IMAGE_API}${form?.adsBlock?.[3]?.value?.path}`}`)
			:
			setImage3(null))
			edit
		&&
		(form?.adsBlock?.[4]?.value?.path !== undefined && form?.adsBlock?.[4]?.position === 5
			?
			setImage4(`${form?.adsBlock?.[4]?.value?.path?.indexOf('https://') === 0
				?
				form?.adsBlock?.[4]?.value?.path
				:
				`${IMAGE_API}${form?.adsBlock?.[4]?.value?.path}`}`)
			:
			setImage4(null))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit, editDateForModal?.user])

	const formDateUpdateHandler = (opt) => setForm({...form, ...opt})

	const addNewAdministratorHandler = () => dispatch(showChangeUserAdvertisingModal(true))

	const handleSubmit = async (values) => {
		let idImage = null
		let idImage1 = null
		let idImage2 = null
		let idImage3 = null
		let idImage4 = null

		try {
			if (imageFile !== null) {
				const data = await UploadingImageHelper(dir, imageFile, addImageCategory)
				data?.error && setErrorMessage(data?.error?.data?.status)
				// console.log( data )
				idImage = data?.data?.data[0]?.id
			}
			if (imageFile1 !== null) {
				const data = await UploadingImageHelper(dir, imageFile1, addImageCategory)
				data?.error && setErrorMessage(data?.error?.data?.status)
				idImage1 = data?.data?.data[0]?.id
			}
			if (imageFile2 !== null) {
				const data = await UploadingImageHelper(dir, imageFile2, addImageCategory)
				data?.error && setErrorMessage(data?.error?.data?.status)
				idImage2 = data?.data?.data[0]?.id
			}
			if (imageFile3 !== null) {
				const data = await UploadingImageHelper(dir, imageFile3, addImageCategory)
				data?.error && setErrorMessage(data?.error?.data?.status)
				idImage3 = data?.data?.data[0]?.id
			}
			if (imageFile4 !== null) {
				const data = await UploadingImageHelper(dir, imageFile4, addImageCategory)
				data?.error && setErrorMessage(data?.error?.data?.status)
				idImage4 = data?.data?.data[0]?.id
			}

		} catch (e) {
			console.log(e)
		}

		const arrAdsBlock = [
			{
				position: 2,
				value: idImage ? idImage : image !== null ? editDateForModal?.adsBlock?.[0]?.value?.id : null,
				type: image !== null ? 1 : null
			},
			{
				position: 1,
				value: idImage1 ? idImage1 : image1 !== null ? editDateForModal?.adsBlock?.[1]?.value?.id : null,
				type: image1 !== null ? 1 : null
			},
			{
				position: 3,
				value: idImage2 ? idImage2 : image2 !== null ? editDateForModal?.adsBlock?.[2]?.value?.id : null,
				type: image2 !== null ? 1 : null
			},
			{
				position: 4,
				value: idImage3 ? idImage3 : image3 !== null ? editDateForModal?.adsBlock?.[3]?.value?.id : null,
				type: image3 !== null ? 1 : null
			},
			{
				position: 5,
				value: idImage4 ? idImage4 : image4 !== null ? editDateForModal?.adsBlock?.[4]?.value?.id : null,
				type: image4 !== null ? 1 : null
			},
		]

		const formDate = {
			title: values?.title,
			adsBlock: arrAdsBlock,
			user: dateModalChangeUserAdvertising?.id,
			url: values?.url || '',
			impressions: Number(values?.impressions) || 0
		}

		try {
			if (edit) {
				const {error} = errorMessage === '' && await editAdvertising(
					{...formDate, id: editDateForModal?.id})
				!error && dispatch(editAdvertisingItem((
					image?.indexOf('https://') === 0 ||
					image1?.indexOf('https://') === 0 ||
					image2?.indexOf('https://') === 0 ||
					image3?.indexOf('https://') === 0 ||
					image4?.indexOf('https://') === 0
				) ? {...formDate, id: editDateForModal?.id, adsBlock: form?.adsBlock} : {
					...formDate,
					id: editDateForModal?.id
				}))
				error && setErrorMessage(error?.data?.message)
				!error && clearForm()
			} else {
				const {error} = errorMessage === '' && await createAdvertising(formDate)
				!error && dispatch(createNewAdvertisingItem(formDate))
				error && setErrorMessage(error?.data?.message)
				!error && clearForm()
			}
		} catch (e) {
			console.log(e)
		}
	}

	const clearForm = () => {
		onHide()
		setForm({})
		dispatch(resetDateChangeUserAdvertising({}))
		setImage(null)
		setImageFile(null)
	}

	const resizeFile = async (file, type) => {
		Resizer.imageFileResizer(
			file,
			250,
			180,
			"JPEG",
			100,
			0,
			(uri) => {
				type === 'image' && setImage(uri)
				type === 'image1' && setImage1(uri)
				type === 'image2' && setImage2(uri)
				type === 'image3' && setImage3(uri)
				type === 'image4' && setImage4(uri)
			},
			"base64",
			50,
			50
		)
	}

	const compressorFileImage = async (file, type) => {
		if (type === 'image') {
			setCompressorImageLoading(true)
			const tehFile = await squeezeFiles(file)
			setImageFile(tehFile)
			setCompressorImageLoading(false)
		} else if (type === 'image1') {
			setCompressorImage1Loading(true)
			const tehFile1 = await squeezeFiles(file)
			setImageFile1(tehFile1)
			setCompressorImage1Loading(false)
		} else if (type === 'image2') {
			setCompressorImage2Loading(true)
			const tehFile2 = await squeezeFiles(file)
			setImageFile2(tehFile2)
			setCompressorImage2Loading(false)
		} else if (type === 'image3') {
			setCompressorImage3Loading(true)
			const tehFile3 = await squeezeFiles(file)
			setImageFile3(tehFile3)
			setCompressorImage3Loading(false)
		} else if (type === 'image4') {
			setCompressorImage4Loading(true)
			const tehFile4 = await squeezeFiles(file)
			setImageFile4(tehFile4)
			setCompressorImage4Loading(false)
		}
	}

	const removeImage = (type) => {
		if (type === 'image') {
			setImage(null)
			setImageFile(null)
			setForm({...form, image: null})
		} else if (type === 'image1') {
			setImage1(null)
			setImageFile1(null)
			setForm({...form, image1: null})
		} else if (type === 'image2') {
			setImage2(null)
			setImageFile2(null)
			setForm({...form, image2: null})
		} else if (type === 'image3') {
			setImage3(null)
			setImageFile3(null)
			setForm({...form, image3: null})
		} else if (type === 'image4') {
			setImage4(null)
			setImageFile4(null)
			setForm({...form, image4: null})
		}
	}

	return (
		<div className='terms' style={{margin: 0, padding: 0}}>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			{
				showModalChangeUserAdvertising
				&&
				<ChangeUserForAdvertising fromPage={'changeUserForAdvertising'} />
			}
			<h1 className='terms-title'>
				{edit ? `${ARE_COMMON?.EDITING} рекламної компанії` : `${ARE_COMMON?.CREATED} рекламної компанії`}
			</h1 >
			<div className='terms-wrapper'>
				<Formik
					validateOnChange
					initialValues={{
						title: form?.title || form?.title || '',
						user: form?.user || '',
						url: form?.url || '',
						impressions: form?.impressions || 0
					}}
					// validationSchema={ addCategorySchema }
					onSubmit={handleSubmit}
					enableReinitialize
				>
					{
						({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							isValid,
							handleSubmit,
							dirty
						}) => (
							<Form
								className='add-edit-terms advertising'
								onSubmit={handleSubmit}
							>
								<div className='categories-create_block'>
									<Form.Group
										className='template-content_nameBlock-label'
									>
										<p className='categories-create_block-title'>{`${ARE_COMMON?.NAME} компанії`}</p >
										<Form.Control
											type='text'
											name='title'
											onBlur={handleBlur}
											autoFocus
											defaultValue={values?.title}
											onKeyDown={e => {
												e.key === 'Enter' && e.preventDefault()
											}}
											className={`template-content_nameBlock-inp_psevdo  ${touched?.title ? "is-touch " : ""} ${
												errors?.title && touched?.title ? " is-invalid" : ""
											} `}
											onChange={(e) => {
												handleChange(e)
												formDateUpdateHandler({
													[e.target.name]: e.target.value
												})
											}}
										/>
										{errors.title && touched.title && (
											<Form.Control.Feedback type='invalid'>
													{errors.title}
												</Form.Control.Feedback >
										)}
									</Form.Group >
								</div >

								<div className='advertising-block'>
									<div className='advertising-block_administrator'>
										<p className='categories-create_block-title'>
										{`${ARE_COMMON?.ADD} адміністратора`}
										</p >
										{
											isGetUsersLoading
											?
												<LoaderContent/>
												:
											changeUserObj?.pib
											&&
											<div className='advertising-block_administrator-list'>

														<div className='advertising-block_administrator-list_li'>
															<p className='advertising-block_administrator-list_li-p'>{changeUserObj?.pib}</p >
															<img
																src={Remove}
																alt='delete'
																title='delete'
																onClick={() => {
																	setChangeUserObj({})
																	dispatch(resetDateChangeUserAdvertising({}))
																}}
															/>
														</div >

											</div >
										}
										<div className='advertising-block_administrator-inp'>
												<button
													onClick={addNewAdministratorHandler}
													type='button'
													className='template-content_buttons-save btn btn-primary'
												>
													<img
														src={addPlus}
														alt='Add Plus'
													/>
													{`${ARE_COMMON?.ADD}`}
												</button >
										</div >
									</div >
								</div >

								<div className='advertising_media'>
									<h2 className='advertising_media-title categories-create_block-title'>
										Рекламні блоки для відображення на усіх сторінках платформи
									</h2 >
									<p className='advertising_media-subtitle'>
										Оберіть 1 із 3 запропонованих варіантів додавання медія,  або додайте файл або код HTML
									</p >
									<div className='advertising_media-wrapper'>
										{/*TODO img 1*/}
										<div className='advertising_media-wrapper_image advertising_media-wrapper_image-addImage'>
											{
												compressorImageLoading
													?
													<Spinner
														animation='grow'
														variant='secondary'
													/>
													:
													image
														?
														<div className='advertising_media-wrapper_image-addImage_photo'>
														{/*<img*/}
															{/*		src={ image }*/}
															{/*		alt='default image'*/}
															{/*		className='advertising_media-wrapper_image-addImage_photo-img'*/}
															{/*	/>*/}
															{/*src={ state?.avatar?.[ 0 ]?.path?.indexOf( 'https://' ) === 0 ? state?.avatar?.[ 0 ]?.path : `https://test.moow.pro/${ state?.avatar?.[ 0 ]?.path }` }*/}

															<div
																style={{
																	// background: `url(${ edit ? form?.image === null || form?.image === undefined ? image : form?.image?.path : image } )`,
																	background: `url(${image} )`,
																	backgroundSize: 'cover',
																	backgroundRepeat: 'no-repeat',
																	backgroundPosition: 'center'
																}}
																className='advertising_media-wrapper_image-addImage_photo-img'
															/>
															<button
																type='button'
																className='advertising_media-wrapper_image-addImage_photo-del'
																onClick={() => removeImage('image')}
															>
															<img
																src={Remove}
																alt='delete_шmage'
																title='delete image'
															/>
														</button >
													</div >
														:
														<>
													<Form.Control
														type='file'
														name='icon-category'
														accept='image/png, image/jpeg, image/webp, image/jpg, image/svg, image/pdf, image/xls, image/xls'
														className='advertising_media-wrapper_image-addImage_file'
														onChange={e => {
															resizeFile(e.target.files[0], 'image')
															compressorFileImage(e.target.files[0], 'image')
														}}
													/>
														<img
															src={DefaultImage}
															alt='default_image'
															className='advertising_media-wrapper_image-addImage_img'
														/>
														<div className='advertising_media-wrapper_image-addImage_button terms-wrapper_addButton'>
															<img
																src={PaperClipIcon}
																alt='paper clip'
															/>
															Виберіть файл
														</div >
														<p className='categories-create_block-addImage_subtext'>
															Підтримує формат: Webp
														</p >
															<p className='categories-create_block-addImage_subtext'>
															300x600
														</p >
													</>
											}
										</div >
										{/*TODO img 2*/}
										<div className='advertising_media-wrapper_image advertising_media-wrapper_image-addImage'>
											{
												compressorImage1Loading
													?
													<Spinner
														animation='grow'
														variant='secondary'
													/>
													:
													image1
														?
														<div className='advertising_media-wrapper_image-addImage_photo'>
														<div
															style={{
																background: `url(${edit ? form?.image1 === null || form?.image1 === undefined ? image1 : `${form?.image1?.path}` : image1} )`,
																backgroundSize: 'cover',
																backgroundRepeat: 'no-repeat',
																backgroundPosition: 'center'
															}}
															className='advertising_media-wrapper_image-addImage_photo-img'
														/>
															{/*<img*/}
															{/*	src={ edit ? form?.image1 === null ? image1 : `${ form?.image1?.path }` : image1 }*/}
															{/*	alt='default image'*/}
															{/*	className='advertising_media-wrapper_image-addImage_photo-img'*/}
															{/*/>*/}
															<button
																type='button'
																className='advertising_media-wrapper_image-addImage_photo-del'
																onClick={() => removeImage('image1')}
															>
															<img
																src={Remove}
																alt='delete_шmage'
																title='delete image'
															/>
														</button >
													</div >
														:
														<>
															<Form.Control
																type='file'
																name='icon-category'
																accept='image/png, image/jpeg, image/webp, image/jpg, image/svg, image/pdf, image/xls, image/xls'
																className='advertising_media-wrapper_image-addImage_file'
																onChange={e => {
																	resizeFile(e.target.files[0], 'image1')
																	compressorFileImage(e.target.files[0], 'image1')
																}}
															/>
															<img
																src={DefaultImage}
																alt='default_image'
																className='advertising_media-wrapper_image-addImage_img'
															/>
															<div className='advertising_media-wrapper_image-addImage_button terms-wrapper_addButton'>
																<img
																	src={PaperClipIcon}
																	alt='paper clip'
																/>
																Виберіть файл
															</div >
															<p className='categories-create_block-addImage_subtext'>
																Підтримує формат: Webp
															</p >
															<p className='categories-create_block-addImage_subtext'>
															1020x100
														</p >
														</>
											}
										</div >
										{/*TODO img 3*/}
										<div className='advertising_media-wrapper_image advertising_media-wrapper_image-addImage'>
										{
											compressorImage2Loading
												?
												<Spinner
													animation='grow'
													variant='secondary'
												/>
												:
												image2
													?
													<div className='advertising_media-wrapper_image-addImage_photo'>
														<div
															style={{
																background: `url(${edit ? form?.image2 === null || form?.image2 === undefined ? image2 : `${form?.image2?.path}` : image2} )`,
																backgroundSize: 'cover',
																backgroundRepeat: 'no-repeat',
																backgroundPosition: 'center'
															}}
															className='advertising_media-wrapper_image-addImage_photo-img'
														/>
														{/*<img*/}
														{/*	src={ edit ? form?.image2 === null ? image2 : `${ form?.image2?.path }` : image2 }*/}
														{/*	alt='default image'*/}
														{/*	className='advertising_media-wrapper_image-addImage_photo-img'*/}
														{/*/>*/}
														<button
															type='button'
															className='advertising_media-wrapper_image-addImage_photo-del'
															onClick={() => removeImage('image2')}
														>
															<img
																src={Remove}
																alt='delete_шmage'
																title='delete image'
															/>
														</button >
													</div >
													:
													<>
														<Form.Control
															type='file'
															name='icon-category'
															accept='image/png, image/jpeg, image/webp, image/jpg, image/svg, image/pdf, image/xls, image/xls'
															className='advertising_media-wrapper_image-addImage_file'
															onChange={e => {
																resizeFile(e.target.files[0], 'image2')
																compressorFileImage(e.target.files[0], 'image2')
															}}
														/>
														<img
															src={DefaultImage}
															alt='default_image'
															className='advertising_media-wrapper_image-addImage_img'
														/>
														<div className='advertising_media-wrapper_image-addImage_button terms-wrapper_addButton'>
															<img
																src={PaperClipIcon}
																alt='paper clip'
															/>
															Виберіть файл
														</div >
														<p className='categories-create_block-addImage_subtext'>
															Підтримує формат: Webp
														</p >
														<p className='categories-create_block-addImage_subtext'>
															270x240
														</p >
													</>
										}
										</div >
									</div >
									<div className='advertising_media-link'></div >

									<div className='advertising_media-bigImg'>
										<h2 className='advertising_media-title categories-create_block-title'>
											Рекламний блок для відображення у слайдери на головній сторінці (Web версiя)
										</h2 >
										<div className='advertising_media-bigImg_image advertising_media-bigImg_image-addImage'>
										{
											compressorImage3Loading
												?
												<Spinner
													animation='grow'
													variant='secondary'
												/>
												:
												image3
													?
													<div className='advertising_media-wrapper_image-addImage_photo'>
														<div
															style={{
																background: `url(${edit ? form?.image3 === null || form?.image3 === undefined ? image3 : `${form?.image3?.path}` : image3} )`,
																backgroundSize: 'cover',
																backgroundRepeat: 'no-repeat',
																backgroundPosition: 'center',
																minHeight: '300px'
															}}
															className='advertising_media-wrapper_image-addImage_photo-img'
														/>
														{/*<img*/}
														{/*	src={ edit ? form?.image3 === null ? image3 : `${ form?.image3?.path }` : image3 }*/}
														{/*	alt='default image'*/}
														{/*	className='advertising_media-wrapper_image-addImage_photo-img'*/}
														{/*/>*/}
														<button
															type='button'
															className='advertising_media-wrapper_image-addImage_photo-del'
															onClick={() => removeImage('image3')}
														>
															<img
																src={Remove}
																alt='delete_шmage'
																title='delete image'
															/>
														</button >
													</div >
													:
													<>
														<Form.Control
															type='file'
															name='icon-category'
															accept='image/png, image/jpeg, image/webp, image/jpg, image/svg, image/pdf, image/xls, image/xls'
															className='advertising_media-bigImg_image-addImage_file'
															onChange={e => {
																resizeFile(e.target.files[0], 'image3')
																compressorFileImage(e.target.files[0], 'image3')
															}}
														/>
														<img
															src={DefaultImage}
															alt='default_image'
															className='advertising_media-bigImg_image-addImage_img'
														/>
														<div className='advertising_media-bigImg_image-addImage_button terms-wrapper_addButton'>
															<img
																src={PaperClipIcon}
																alt='paper clip'
															/>
															Виберіть файл
														</div >
														<p className='categories-create_block-addImage_subtext'>
															Підтримує формат: Webp
														</p >
														<p className='categories-create_block-addImage_subtext'>
															1280×340
														</p >
													</>
										}
										</div >
									</div >

									<div className='advertising_media-bigImg' style={{marginTop: 30}}>
										<h2 className='advertising_media-title categories-create_block-title'>
											Рекламний блок для відображення у слайдери на головній сторінці (Mobile версiя)
										</h2 >
										<div className='advertising_media-bigImg_image advertising_media-bigImg_image-addImage'>
										{
											compressorImage4Loading
												?
												<Spinner
													animation='grow'
													variant='secondary'
												/>
												:
												image4
													?
													<div className='advertising_media-wrapper_image-addImage_photo'>
														<div
															style={{
																background: `url(${edit ? form?.image4 === null || form?.image4 === undefined ? image4 : `${form?.image4?.path}` : image4} )`,
																backgroundSize: 'cover',
																backgroundRepeat: 'no-repeat',
																backgroundPosition: 'center',
																minHeight: '300px'
															}}
															className='advertising_media-wrapper_image-addImage_photo-img'
														/>
														<button
															type='button'
															className='advertising_media-wrapper_image-addImage_photo-del'
															onClick={() => removeImage('image4')}
														>
															<img
																src={Remove}
																alt='delete_шmage'
																title='delete image'
															/>
														</button >
													</div >
													:
													<>
														<Form.Control
															type='file'
															name='icon-category'
															accept='image/png, image/jpeg, image/webp, image/jpg, image/svg, image/pdf, image/xls, image/xls'
															className='advertising_media-bigImg_image-addImage_file'
															onChange={e => {
																resizeFile(e.target.files[0], 'image4')
																compressorFileImage(e.target.files[0], 'image4')
															}}
														/>
														<img
															src={DefaultImage}
															alt='default_image'
															className='advertising_media-bigImg_image-addImage_img'
														/>
														<div className='advertising_media-bigImg_image-addImage_button terms-wrapper_addButton'>
															<img
																src={PaperClipIcon}
																alt='paper clip'
															/>
															Виберіть файл
														</div >
														<p className='categories-create_block-addImage_subtext'>
															Підтримує формат: Webp
														</p >
														<p className='categories-create_block-addImage_subtext'>
															288х134
														</p >
													</>
										}
										</div >
									</div >

									<div className='advertising_media-link'>
										<p className='advertising_media-title categories-create_block-title'>
											Додайте посилання
										</p >
										<Form.Control
											type='text'
											name='url'
											placeholder='https://'
											onBlur={handleBlur}
											defaultValue={form?.url}
											// value={ imageOneValue }
											onKeyDown={e => {
												e.key === 'Enter' && e.preventDefault()
											}}
											className={`template-content_nameBlock-inp_psevdo`}
											onChange={(e) => formDateUpdateHandler({[e.target.name]: e.target.value})}
										/>
									</div >

									<div className='advertising_media-footer'>
										<p className='advertising_media-title categories-create_block-title'>
											Оберіть кількість показів
										</p >
										<div className='advertising_media-footer_content'>
											<Form.Control
												type='text'
												name='impressions'
												placeholder='100'
												onBlur={handleBlur}
												defaultValue={form?.impressions}
												// value={ imageOneValue }
												onKeyDown={e => {
													e.key === 'Enter' && e.preventDefault()
												}}
												className={`template-content_nameBlock-inp_psevdo`}
												onChange={(e) => formDateUpdateHandler({[e.target.name]: e.target.value})}
											/>
											<p className='advertising_media-footer_text'>
												1000$ або 1$ / за показ
											</p >
										</div >
									</div >
								</div >

							<div className='categories-create_block-buttons'>
								<button
									type='submit'
									className='template-content_buttons-save btn btn-primary'
									disabled={isLoading || !isValid || dirty}
								>
									{isLoading ? <Loader /> : ARE_COMMON?.SAVE}
								</button >
								<button
									type='button'
									className='template-content_buttons-close btn btn-primary'
									onClick={clearForm}
								>
									{ARE_COMMON?.CANCEL}
								</button >
							</div >
						</Form >
						)}
				</Formik >
			</div >
		</div >
	)
}

export default AddEditAdvertisingItem
