import React, {
	useEffect,
	useState
} from 'react'
import s from './AddContactsChangeAll.module.scss'
import AlertModal from "../../AlertModal"
import {
	deleteDateChangeUsersForAddressBookArr,
	resetDateChangeUsersForAddressBookArr,
	showChangeUserAdvertisingModal
} from '../../../store/slices/advertisingSlice'
import {
	useDispatch,
	useSelector
} from 'react-redux'
import addPlus from '../../../../utils/icons/addPlus.svg'
import ChangeUserForAdvertising from '../../../pages/AdvertisingPage/ChangeUserForAdvertising'
import Remove from '../../../../utils/icons/delete-red-icon.svg'
import { useUpdateUsersWithFiltersMutation } from '../../../store/apis/addressBookApi'
import { useTranslation } from 'react-i18next'

export const AddContactsChangeAll = ( { name, sendGroup, book_id } ) => {

	const [groupUsers, setGroupUsers] = useState([])
	let {
		showModalChangeUserAdvertising,
		dateModalChangeUsersForAddressBook
	} = useSelector( ( state ) => state.advertisingStore )
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const [updateUsersFithFilters, {isLoading: isULoadingpdateUsersFithFilters}] = useUpdateUsersWithFiltersMutation()
	
	const addNewAdministratorHandler = () => dispatch( showChangeUserAdvertisingModal( true ) )

	async function makeData() {

		await Promise.all(groupUsers.map(async el => {
			await updateUsersFithFilters( {...el.filters.status, ...el.filters.search, id: `&id=${book_id}`} )
		}))

		const body = {
			name: name,
			users: dateModalChangeUsersForAddressBook?.map(item => item?.id),
			contacts: []
		}
		await sendGroup( body )

		dispatch(resetDateChangeUsersForAddressBookArr())
	}

	const removeChangeItemArr = ( id ) => {
		dispatch( deleteDateChangeUsersForAddressBookArr( id ) )
	}

	const unique_users = []
	dateModalChangeUsersForAddressBook.forEach(el => {
		const result = unique_users.find(user => user.id === el.id)
		if(!result) unique_users.push(el)
	})

	return (
		<div className={ s.content }>
			{
				showModalChangeUserAdvertising
				&&
				<ChangeUserForAdvertising fromPage={ 'changeUsersListForAddressBook' } setGroupUsers={setGroupUsers} groupUsers={groupUsers} />
			}
			<AlertModal
				content={t('_m_canEnter1000Contacts')}
				setErrorMessage={ () => {
				} }
				position
			/>
			<h2 >{t('_m_contactsList')}</h2 >
			<div className='advertising-block_administrator-inp'>
				<button
					onClick={ addNewAdministratorHandler }
					type='button'
					className='template-content_buttons-save btn btn-primary'
				>
					<img
						src={ addPlus }
						alt='Add Plus'
					/>
						{t('add')}
					</button >
			</div >
			<p >{ unique_users?.length || groupUsers.reduce((acc, el) => acc + el.length, 0) ?  `${t('selected')} - ${ unique_users?.length + groupUsers.reduce((acc, el) => acc + el.length, 0) } ${t('users')}` : t('_m_noSelectedUser') }</p >
			<ul className='template-content_valueBlock-list mt-0'>
				{
					unique_users && unique_users?.map( item => (
						<li
							className='template-content_valueBlock-list_li'
							key={ item?.id }
						>
							<div className='template-content_valueBlock-list_li-text'>
								<p style={{width: '33%'}}>{ item?.pib }</p>
								<p style={{width: '33%'}}>{ item?.email }</p>
								<p style={{width: '33%'}}>{ item?.phone }</p>
							</div>
							<img
								src={ Remove }
								alt='remove item'
								className='template-content_valueBlock-list_li-remove'
								onClick={ () => removeChangeItemArr( item?.id ) }
							/>
						</li >
					) )
				}
				{
					groupUsers.length ? groupUsers.map((el, index) => {
						return(
							<li
							className='template-content_valueBlock-list_li'
							key={ index }
						>
							<p className='template-content_valueBlock-list_li-text' style={{background: "#F2F2F2"}}>
								Група "{el.title}", {el.length} користувачiв
							</p >
							<img
								src={ Remove }
								alt='remove item'
								className='template-content_valueBlock-list_li-remove'
								onClick={()=>setGroupUsers(groupUsers.filter(group => group.group !== el.group))}
							/>
						</li >
						)
					}) : null
				}
			</ul >

			<button
				className='terms-wrapper_addButton'
				onClick={ makeData }
				disabled={unique_users?.length === 0 && groupUsers?.length === 0}
			>
				{t('download')}
			</button >
    </div >
	)
}