import React, {
	useMemo, useState,
} from 'react'
import { Form } from "react-bootstrap"
import LoaderContent from "../../../components/LoaderContent"
import Remove from "../../../../utils/icons/delete-icon.svg";

const SubscriptionsTab = ( {
	subscription,
	isGetSubscriptionLoading,
	deleteSubscriptionItemHandler,
	openEditHandler,
	edit
} ) => {

	const updateSubscriptions = useMemo( () => {
		const teh = subscription?.map( item => {
			let arrPref = []
			let resArr = []
			let obj = {}
			for ( let [ key, value ] of Object.entries( item?.preference ) ) {
				arrPref = [ ...arrPref, `${ key }:${ value }` ]
			}
			arrPref?.forEach( pr => {
				const y = pr?.split( ':' )
				obj = edit ? { title: y[ 0 ]?.replaceAll( /_/gi, ' ' ), value: y[ 1 ] } : { title: y[ 0 ], value: y[ 1 ] }
				resArr = [ ...resArr, obj ]
				return resArr
			} )
			return { ...item, prefInfoAff: resArr }
		} )

		return teh
	}, [ subscription, edit ] )

	const [checkedPeriod, setCheckedPeriod] = useState(0);

	const handleChange = (selectedID) => {
			setCheckedPeriod(selectedID);
	};

	return (
		<div className='subscription-wrapper'>
			{
				isGetSubscriptionLoading
					?
					<LoaderContent />
					:
					updateSubscriptions
					&&
					updateSubscriptions?.map( item => (
						<div
							className='subscription-wrapper_block'
							key={ item?.id }
						>
							<div className='subscription-wrapper_block-top'>
								<div className='subscription-wrapper_block-header'>
									<h3 className='subscription-wrapper_block-header_h3'>
										{ item?.name?.ua.toUpperCase() }
									</h3 >
									<div className='subscription-wrapper_block-header_line'></div >
								</div >
								<p className='subscription-wrapper_block-coast'>
									{ item?.tariff[ checkedPeriod ]?.price } грн
								</p >
								<div className='subscription-wrapper_block-period'>
									<h4 className='subscription-wrapper_block-period_title'>
										Період підписки
									</h4 >
									{
										item?.tariff?.map((tariffItem, id) => (
											<Form.Check
												key={"1" + tariffItem?.period}
												label={`1 ${tariffItem?.period}`}
												className='subscription-wrapper_block-period_checkbox'
												onChange={() => handleChange(id)}
												checked={checkedPeriod === id}
											/>
										))
									}
								</div >
								<h4 className='subscription-wrapper_block-advantages_li-title'>
									Переваги
								</h4 >
								<ul className='subscription-wrapper_block-advantages'>
									{
										item?.preference
										&&
										item?.preference?.map( (
											pref,
											index
										) => (
											(
												pref?.value === "true" || pref?.value === "false"
											)
												?
												<li
													key={ pref?.title + pref?.value + index }
													className='subscription-wrapper_block-advantages_li'
												>
													<p className='subscription-wrapper_block-advantages_li-text'>
														{ pref?.title.ua }
													</p >

													{
														pref?.value === "true"
															?
															<Form.Check
																className='subscription-wrapper_block-period_checkbox'
																defaultChecked
																disabled
															/>
															:
															<Form.Check
																className='subscription-wrapper_block-period_checkbox-notchacked'
																defaultChecked={ false }
																disabled
															/>
													}
												</li >
												:
												<li
													key={ pref?.title + pref?.value + index }
													className='subscription-wrapper_block-advantages_li'
												>
													<p className='subscription-wrapper_block-advantages_li-text'>
														{ pref?.title.ua }
													</p >
													<p className='subscription-wrapper_block-advantages_li-number'>
														{ pref?.value ? pref?.value : '-' }
													</p >
												</li >
										) )
									}
								</ul >
							</div >
							<button
								className='subscription-wrapper_block-button'
								onClick={ () => openEditHandler( item ) }
							>
								Редагувати
							</button >
							<button
								className='subscription-wrapper_block-button'
								onClick={ () => deleteSubscriptionItemHandler( item ) }
							>
								Видалити
								<img
									src={ Remove }
									alt='remove item'
									className='template-content_valueBlock-list_li-remove'
								/>
							</button >
						</div >
					) )
			}
					</div >
	)
}

export default SubscriptionsTab