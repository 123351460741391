import { createSlice } from "@reduxjs/toolkit"
import {referralLinksApi} from "../apis/referralLinksApi";

const initialState = {
    referralLinks: [],
}

const referralLinksSlice = createSlice({
    name: "referralLinks",
    initialState,
    reducers: {
        createNewLink: (state, action) => {
            state.referralLinks = [action.payload, ...state.referralLinks]
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(referralLinksApi.endpoints.getReferralLinks.matchFulfilled, (
                    state,
                    action
                ) => {
                    state.referralLinks = action.payload.data
                }
            )
    }
})

const {
    actions,
    reducer
} = referralLinksSlice

export const {
    createNewLink
} = actions
export default reducer
