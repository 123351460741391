import React, { useEffect, useState } from 'react'
import { Table } from "react-bootstrap"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useChangePositionElementMutation } from "../../store/apis/categoryApi"
import AlertModal from "../../components/AlertModal"
import ShowFirstLevelSubcategory from "./ShowLevelSubcategory/ShowFirstLevelSubcategory"
import AlertSuccessfullyModal from "../../components/AlertSuccessfullyModal"
import { ReactComponent as ArrowRight } from '../../../utils/icons/arrow-from-category-dnd.svg'
import { useTranslation } from 'react-i18next'

const SortingCategoriesTabl = ({category}) => {
	const [changeSortCategory, setChangeSortCategory] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	const [successffullyMessage, setSuccessffullyMessage] = useState('')
	const [showSubcategoryFirstLevel, setShowSubcategoryFirstLevel] = useState(false)
	const [idChangeElement, setIdChangeElement] = useState(null)
	const [changePositionElement] = useChangePositionElementMutation()
	const {i18n} = useTranslation()

	useEffect(() => {
		setChangeSortCategory(category)
	}, [category])
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		return result
	}

	const onDragEnd = async (result) => {
		if (!result.destination) {
			return
		}
		const modifiersGroupsSortedList = reorder(
			changeSortCategory,
			result.source.index,
			result.destination.index
		)

		setChangeSortCategory(modifiersGroupsSortedList)
		const {data, error} = await changePositionElement({
			id: Number(result?.draggableId),
			position: (result?.destination?.index + 1)
		})
		error && setErrorMessage(error?.data?.message)
		data?.status === 200 && setSuccessffullyMessage(data?.message)
	}

	const toggleShowSubcategoryFirstLevel = (value) => {
		idChangeElement !== value ? setShowSubcategoryFirstLevel(true) : setShowSubcategoryFirstLevel(false)
		setIdChangeElement(value)
	}

	return (
		<div className='terms-wrapper'>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			{
				successffullyMessage
				&&
				<AlertSuccessfullyModal
					content={successffullyMessage}
					setErrorMessage={setSuccessffullyMessage}
					position={false}
				/>
			}
			<div className='terms-wrapper_block'>
			<Table
				responsive
				className='terms-wrapper_tabl'
				striped={true}
			>
				<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable'>
					{(provided) => (
						<ul
							className='template-content_valueBlock-list list-items'
							style={{maxHeight: 'max-content'}}
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{
								changeSortCategory && changeSortCategory?.map((item, index) => {
									if(!item.status) return ""
									else return(
									<Draggable
										key={item?.id}
										draggableId={item?.id.toString()}
										index={index}
									>
										{(provided) => (
											<li
												className='template-content_valueBlock-list_li'
												key={item?.id}
												id={item?.id}
												ref={provided.innerRef} {...provided.draggableProps}
												{...provided.dragHandleProps}
											>
											<>
												<span className='me-2'>{index + 1}. </span >
												<div
													className='template-content_valueBlock-list_li-text'
													style={{backgroundColor: `${idChangeElement === item?.id ? 'rgba(102, 92, 209, 1)' : '#fff'}`}}
												>
													<div className='template-content_valueBlock-list_li-wrapper'>
														<span style={{color: `${idChangeElement === item?.id ? '#fff' : '#111'}`}}>
															{item?.name?.[i18n.language]}
														</span >
														<div className='template-content_valueBlock-list_li-buttons'>
															<button
																type='button'
																style={{backgroundColor: 'transparent', width: '40px', height: '30px'}}
																onClick={() => toggleShowSubcategoryFirstLevel(item?.id)}
															>
																<ArrowRight/>
															</button >
															</div >
														</div >

													</div >
												</>
											</li >
										)}
									</Draggable >
								)})
							}
							{provided.placeholder}
						</ul >
					)}
				</Droppable >
			</DragDropContext >
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable'>
					{(provided) => (
						<ul
							className='template-content_valueBlock-list list-items'
							style={{maxHeight: 'max-content'}}
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{
								changeSortCategory && changeSortCategory?.map((item, index) => {
									if(item.status) return ""
									else return(
									<Draggable
										key={item?.id}
										draggableId={item?.id.toString()}
										index={index}
										isDragDisabled={true}
									>
										{(provided) => (
											<li
												className='template-content_valueBlock-list_li'
												key={item?.id}
												id={item?.id}
												ref={provided.innerRef} {...provided.draggableProps}
												{...provided.dragHandleProps}
											>
											<>
												<span className='me-2'></span >
												<div
													className='template-content_valueBlock-list_li-text'
													style={{backgroundColor: `${idChangeElement === item?.id ? 'rgba(102, 92, 209, 1)' : '#fff'}`}}
												>
													<div className='template-content_valueBlock-list_li-wrapper' style={{opacity: 0.3, cursor: 'not-allowed', userSelect: 'none'}}>
														<span style={{color: `${idChangeElement === item?.id ? '#fff' : '#111'}`}}>
															{item?.name?.[i18n.language]}
														</span >
														<div className='template-content_valueBlock-list_li-buttons'>
															<button
																type='button'
																style={{backgroundColor: 'transparent', width: '40px', height: '30px'}}
																onClick={() => toggleShowSubcategoryFirstLevel(item?.id)}
															>
																<ArrowRight/>
															</button >
															</div >
														</div >

													</div >
												</>
											</li >
										)}
									</Draggable >
								)})
							}
							{provided.placeholder}
						</ul >
					)}
				</Droppable >
			</DragDropContext >
			</Table >
				{
					showSubcategoryFirstLevel
					&&
					<ShowFirstLevelSubcategory
						id={idChangeElement}
						setShowSubcategoryFirstLevel={setShowSubcategoryFirstLevel}
					/>
				}
			</div >
		</div >
	)
}

export default SortingCategoriesTabl