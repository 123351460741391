import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	BODY_TYPE_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const bodyTypeApi = createApi({
	reducerPath: "bodyTypeApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getBodyType: builder.mutation({
			query: () => (
				{
					url: BODY_TYPE_API.GET_BODY_TYPE,
					method: "GET",
				})
		}),
		createBodyType: builder.mutation({
			query: (body) => (
				{
					url: BODY_TYPE_API.CREATE_BODY_TYPE,
					method: "POST",
					body
				})
		}),
		editBodyType: builder.mutation({
			query: (body) => (
				{
					url: BODY_TYPE_API.EDIT_BODY_TYPE,
					method: "POST",
					body
				})
		}),
		deleteBodyType: builder.mutation({
			query: (body) => (
				{
					url: BODY_TYPE_API.DELETE_BODY_TYPE,
					method: "POST",
					body
				})
		}),
	})
});

export const {
	useGetBodyTypeMutation,
	useCreateBodyTypeMutation,
	useEditBodyTypeMutation,
	useDeleteBodyTypeMutation,
} = bodyTypeApi
