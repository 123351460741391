import React from 'react'
import { Form } from 'react-bootstrap'

const TextInput = ( {
	name,
	placeholder,
	title,
	handleBlur,
	touched,
	values,
	errors,
	handleChange,
	formDateUpdateHandler,
	refLink
} ) => {
	return (
		<Form.Group
			className='add-edit-htmls_center'
		>
			{ title }
			<Form.Control
				type='text'
				name={ name }
				onBlur={ handleBlur }
				placeholder={ placeholder }
				ref={refLink}
				onKeyDown={ e => {
					e.key === 'Enter' && e.preventDefault()
				} }
				className={ `add-edit-htmls_center-inp  ${ touched?.[ name ] ? "is-touch " : "" } ${ errors?.[ name ] && touched?.[ name ] ? " is-invalid" : "" } ` }
				defaultValue={ values?.[ name ] }
				onChange={ ( e ) => {
					handleChange( e )
					formDateUpdateHandler( { [ e.target.name ]: e.target.value } )
				} }
			/>
			{ errors?.[ name ] && touched?.[ name ] && (
				<Form.Control.Feedback type='invalid'>
					{ errors?.[ name ] }
				</Form.Control.Feedback >
			) }
		</Form.Group >
	)
}

export default TextInput