import { Table } from "react-bootstrap"
import verticalDots from '../../../utils/icons/vertical-dots.svg'
import ArrowSorting from '../../../utils/icons/arrow-sorting.svg'
import VersionsTablItem from "./VersionsTablItem"
import { ARE_COMMON } from "../../../utils/textsContent";

const VersionsTabl = ( {
	setOpenEditModal,
	toggleDeleteModal,
	sortedForColumn,
	countShowTerms,
	showSmallTermArr,
	bigArrElements,
	fromPage
} ) => {

	return (
		<>
			<div className='terms-wrapper_itemsInfo'>
					<span className='terms-wrapper_itemsInfo-content'>
						{` ${ ARE_COMMON?.SHOWN } ${ countShowTerms } ${ ARE_COMMON?.POINTS_FROM } ${ bigArrElements?.length ? bigArrElements?.length : '?' } `}
					</span >
			</div >
			<Table
				responsive
				className='terms-wrapper_tabl'
				striped={ true }
			>
				<thead className='terms-wrapper_tabl-head'>
				<tr className='terms-wrapper_tabl-trow'>
					<th
						className='terms-wrapper_tabl-trow_item col-6'
						onClick={ () => sortedForColumn( 'id' ) }
					>
						<input
							type='checkbox'
							name='check'
							className='terms-wrapper_tabl-body_item-wrapper_input'
							onKeyDown={ e => {
								e.key === 'Enter' && e.preventDefault()
							} }
							onChange={ e => {
							} }
						/>
						<span >
							{ARE_COMMON?.VALUE}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className={ `terms-wrapper_tabl-trow_item col-2` }
						onClick={ () => sortedForColumn( 'termin' ) }
					>
						<span >
							{ `${ ARE_COMMON?.VERSION } СРМ` }
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='terms-wrapper_tabl-trow_item col-2'
						onClick={ () => sortedForColumn( 'editor' ) }
					>
									<span >
										{ `${ ARE_COMMON?.VERSION } DB` }
									</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >

					<th className='terms-wrapper_tabl-trow_item col-2'>
						<img
							src={ verticalDots }
							alt='Dots'
						/>
					</th >
				</tr >
				</thead >
				<tbody
					className='terms-wrapper_tabl-body'
				>
				{
					showSmallTermArr && showSmallTermArr?.map( item => <VersionsTablItem
						key={ item.id }
						item={ item }
						setOpenEditModal={ setOpenEditModal }
						toggleDeleteModal={ toggleDeleteModal }
						fromPage={ fromPage }
					/> )
				}
				</tbody >
			</Table >
		</>
	)
}

export default VersionsTabl
