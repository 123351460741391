import { createSlice } from "@reduxjs/toolkit"
import { addressBookApi } from "../apis/addressBookApi";

const initialState = {
	groups: [],
}

const addressBookSlice = createSlice( {
	name: "addressBook",
	initialState,
	reducers: {
		testGroups: ( state, action ) => {
			state.groups = action.payload
		},
		deleteAddressBook: (state, action) => {
			const Id = action.payload
			const ID = state.groups?.data.filter(item => item.id !== Id)
			state.groups = ID
		},
	},
	extraReducers: ( builder ) => {
		builder
			.addMatcher( addressBookApi.endpoints.getGroups.matchFulfilled, (
					state,
					action
				) => {
					state.groups = action.payload
				}
			)
	}
} )

const {
	actions,
	reducer
} = addressBookSlice
export const {
	testGroups,
	deleteAddressBook
} = actions
export default reducer
