import React, { useState } from 'react';
import { ReactComponent as LoadingIcon } from '../../../utils/icons/loading-icon.svg'
import translate_icon from '../../../utils/icons/google_translate_icon.png'
import CopyIcon  from '../../../utils/icons/copy_icon.png';
import { useSelector } from 'react-redux';
import { TranslateText } from '../../../utils/helpers/translateText';

export const TextTranslator = ({values, setForm, fieldName}) => {

    const [translateLoading, setTranslateLoading] = useState(false)
    const langs = useSelector(store => store.langStore.langs)

    function getCurrentValue(){
        let value
        for(let i=0; i<langs.length; i++){
            if(values[langs[i].langIndex]?.length){
                value = values[langs[i].langIndex]
                break
            }
        }
        return value
    }

    async function getTranslateText(){
        const currentValue = getCurrentValue()
		if(currentValue){
			setTranslateLoading(true)
			const result = await TranslateText(currentValue, langs.map(el => el.langIndex))
            if(fieldName){
                setForm(prev => {
                    return {...prev, [fieldName]: {...prev[fieldName], ...result}}
                })
            }
            else{
                setForm(result)
            }
			setTranslateLoading(false)
		}else{
			alert("Please enter any values")
		}
	}

    function copyText(){
        const currentValue = getCurrentValue()
		if(currentValue){
            const newValue = {}
            langs.forEach(el => newValue[el.langIndex] = currentValue)
            if(fieldName){
                setForm(prev => {
                    return {...prev, [fieldName]: {...prev[fieldName], ...newValue}}
                })
            }
            else setForm(newValue)
        }
    }

    return(
        <div className='subscription-create_wrapper-date_block-title-translation' style={{display: "flex", gap: 10}}>
            {translateLoading ? <LoadingIcon/> : <img  onClick={()=>copyText()} src={CopyIcon} alt="translation"/>}
            {translateLoading ? <LoadingIcon/> : <img  onClick={()=>getTranslateText()} src={translate_icon} alt="translation"/>}
        </div>
    )
}