import { ARE_COMMON } from "../textsContent"

export const templateContentType = (typeNumber, setItemType) => {
	switch (typeNumber) {
		case 1:
			setItemType(`${ARE_COMMON?.INT}`)
			break
		case 2:
			setItemType(`${ARE_COMMON?.SELECT}`)
			break
		case 3:
			setItemType(`${ARE_COMMON?.RADIO}`)
			break
		case 4:
			setItemType(`${ARE_COMMON?.STRING}`)
			break
		case 5:
			setItemType(`${ARE_COMMON?.BOOL}`)
			break
		case 6:
			setItemType(`${ARE_COMMON?.DIMENSIONS}`)
			break
		case 7:
			setItemType(`${ARE_COMMON?.ADDRESS}`)
			break
		case 8:
			setItemType(`${ARE_COMMON?.IMAGE}`)
			break
		case 9:
			setItemType(`${ARE_COMMON?.TITLE}`)
			break
		default:
			break
	}
}