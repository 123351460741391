import React from 'react'
import { VALIDATION_TYPE, VALIDATION_VALUE } from "../../../../../utils/constants"
import { Button, Form } from "react-bootstrap"
import Remove from "../../../../../utils/icons/delete-red-icon.svg"
import CloseIcon from "../../../../../utils/icons/close-classik-icon-violet.svg"
import { useSelector } from 'react-redux'

const ValidationRulesRegular = ({
	edit,
	handleBlur,
	handleChange,
	formDateUpdateHandler,
	validationForm,
	addValidationForm,
	disabled,
	toggleValidationBlockHandler,
	number,
	removeBlockHandler,
	hideBlockHandler
}) => {
	const langs = useSelector(store => store.langStore.langs)
	
	return (
		<div className='mb-5'>
			<h2>
			{number ? `Правило №${ number }.` : 'Створити нове правило'}
			</h2 >
			<Form.Group className='add-edit-terms_center'>
				<p >
					Type:
				</p >
				<select
					aria-label='Default select example'
					className=' is-touch form-select mt-1'
					name='type'
					defaultValue={validationForm?.type}
					disabled={disabled}
					onChange={(e) => {
						handleChange(e)
						formDateUpdateHandler({
							[e.target.name]: e.target.value
						})
						// setChangeKeyValue(e.target.value)
					}}
				>
					{
						VALIDATION_TYPE?.map((item, index) => (
								<option
									key={index}
									value={item}
								>
								{item}
							</option >
							)
						)
					}
				</select >
			</Form.Group >
			<Form.Group className='add-edit-terms_center'>
				<p >
					Валидация:
				</p >
				<select
					aria-label='Default select example'
					className=' is-touch form-select mt-1'
					name='validationValue'
					disabled={disabled}
					defaultValue={validationForm?.validationValue}
					onChange={(e) => {
						handleChange(e)
						formDateUpdateHandler({
							[e.target.name]: Number(e.target.value)
						})
					}}
				>
					{
						VALIDATION_VALUE?.map((item, index) => (
								<option
									key={item + index}
									value={item?.id}
								>
								{item?.name}
							</option >
							)
						)
					}
				</select >
			</Form.Group >
			{
				(validationForm?.validationValue === 1 || validationForm?.validationValue === 2 || validationForm?.validationValue === 10 || validationForm?.validationValue === 11)
				&&
				<Form.Group
					className='add-edit-terms_label'
				>
				<p >Кількість:</p >
					<Form.Control
						type='number'
						name={`quantity`}
						onBlur={handleBlur}
						disabled={disabled}
						value={validationForm?.quantity !== undefined ? validationForm?.quantity : ''}
						onKeyDown={e => {
							e.key === 'Enter' && e.preventDefault()
						}}
						className={`add-edit-terms_inp`}
						onChange={(e) => {
							handleChange(e)
							formDateUpdateHandler({
								[e.target.name]: e.target.value
							})
						}}
					/>
					</Form.Group >
			}
			{
				(validationForm?.validationValue === 3)
				&&
				<Form.Group
					className='add-edit-terms_label'
				>
				<p >Рядок:</p >
					<Form.Control
						type='text'
						name={`string`}
						onBlur={handleBlur}
						disabled={disabled}
						value={validationForm?.string}
						onKeyDown={e => {
							e.key === 'Enter' && e.preventDefault()
						}}
						className={`add-edit-terms_inp`}
						onChange={(e) => {
							handleChange(e)
							formDateUpdateHandler({
								[e.target.name]: e.target.value
							})
						}}
					/>
					</Form.Group >
			}
			{
				(validationForm?.validationValue === 4 || validationForm?.validationValue === 5 || validationForm?.validationValue === 6)
				&&
				<>
					<Form.Group className='add-edit-terms_label'>
					<p >Початкова поз:</p >
						<Form.Control
							type={validationForm?.validationValue === 6 ? 'number' : 'text'}
							name={`quantityStart`}
							onBlur={handleBlur}
							disabled={disabled}
							value={validationForm?.quantityStart}
							onKeyDown={e => {
								e.key === 'Enter' && e.preventDefault()
							}}
							className={`add-edit-terms_inp`}
							onChange={(e) => {
								handleChange(e)
								formDateUpdateHandler({
									[e.target.name]: e.target.value
								})
							}}
						/>
						</Form.Group >
					<Form.Group className='add-edit-terms_label'>
					<p >Кінцева поз:</p >
						<Form.Control
							type={validationForm?.validationValue === 6 ? 'number' : 'text'}
							name={`quantityEnd`}
							onBlur={handleBlur}
							disabled={disabled}
							value={validationForm?.quantityEnd}
							onKeyDown={e => {
								e.key === 'Enter' && e.preventDefault()
							}}
							className={`add-edit-terms_inp`}
							onChange={(e) => {
								handleChange(e)
								formDateUpdateHandler({
									[e.target.name]: e.target.value
								})
							}}
						/>
					</Form.Group >
				</>
			}
			<div className='mt-5'>
				<p >Опис помилки:</p >
				<div className='template-content_nameBlock-wrapper mb-3 mt-0'>
					<div className='add-edit-terms_top'>
						{
							langs?.map((el, index) => {
								return(
									<Form.Group
									key={index}
										className='add-edit-terms_label'
									>
										<h3 className='subscription-create_wrapper-date_block-title'>Description {el.langIndex}</h3 >
										<Form.Control
											type='text'
											name={`${el.langIndex}DescriptionValidation`}
											onBlur={handleBlur}
											disabled={disabled}
											value={disabled ? validationForm?.description?.[el.langIndex] : validationForm?.[`${el.langIndex}DescriptionValidation`]}
											onKeyDown={e => {
												e.key === 'Enter' && e.preventDefault()
											}}
											className={`add-edit-terms_inp`}
											onChange={(e) => {
												handleChange(e)
												formDateUpdateHandler({
													[e.target.name]: e.target.value
												})
											}}
										/>
									</Form.Group >
								)
							})
						}
					</div >
				</div >
			</div >
			{
				disabled
				&&
				<div className='w-100 d-flex align-items-center justify-content-between'>
					<Button
						type='button'
						className='btn btn-light'
						title={'Видалити цю знижку'}
						onClick={() => removeBlockHandler(validationForm?.tehId)}
					>
						<img
							src={Remove}
							alt='remove item'
						/>
					</Button >
				</div >

			}
			{
				!disabled
				&&
				<div className='d-flex align-items-center justify-content-between'>
					<button
						className='template-content_buttons-save btn btn-primary m-0'
						onClick={addValidationForm}
					>
						Зберегти
					</button >
					<Button
					variant='light'
					className='ms-2 bg-transparent'
					type='button'
					disabled={number !== undefined}
					onClick={() => hideBlockHandler(false)}
					>
						<img
							src={CloseIcon}
							alt='close'
						/>
					</Button >
				</div>
			}

		</div>
	)
}

export default ValidationRulesRegular