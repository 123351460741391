import React, {
	useEffect,
	useState,
} from 'react'
import { Form } from "react-bootstrap"
import { useSelector } from 'react-redux'
import { CategoryDropdownList } from './CategoryDropdownList'
import { useTranslation } from 'react-i18next'

const CategoryCheckedTemplate = ({
	formDateUpdateHandler,
	categories,
	form,
	templateType,
	value,
	edit
}) => {
	
	
	const [currentCategory, setCurrentCategory] = useState(false)
	const [currentCategoryName, setCurrentCategoryName] = useState(false)
	const {t} = useTranslation()

	const subcategories = useSelector(store => store.subcategoryStore.subcategory)

	useEffect(()=>{
		if(value){
			const name = categories.find(el => el.id === value)?.name?.ua
			if(!name){
				const name = subcategories.find(el => el.id === value)?.title?.ua
				setCurrentCategoryName(name)
			}else{
				setCurrentCategoryName(name)
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	useEffect(()=>{
		if(currentCategory){
			formDateUpdateHandler( { subcategory: currentCategory } )
			const name = categories.find(el => el.id === currentCategory)?.name?.ua
			if(!name){
				const name = subcategories.find(el => el.id === currentCategory)?.title?.ua
				setCurrentCategoryName(name)
			}else{
				setCurrentCategoryName(name)
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[currentCategory]);

	return (
		<div className='template-content_category'>
			<h2 className='template-content_category-title'>
				{t('c_add_chooseCategory')}
			</h2 >
			<div className='template-content_category-body'>
				<Form.Group className='template-content_category-body_breadcrumbs'>
					<p className='template-content_category-body_breadcrumbs-label'>
						{t('category')}
					</p >
					<p
						className={`template-content_category-body_breadcrumbs-text`}
						style={{backgroundColor: false ? !form?.dublicate && '#e9ecef' : ''}}
					>
						{currentCategoryName || t('c_add_chooseCategory')}
					</p >
				</Form.Group >
				{/* {
					(!edit || form?.dublicate)
					&& */}
					<div className='template-content_category-body_list'>
					{
						categories && categories?.map(category => (
							<CategoryDropdownList
								category={category}
								subcategories={subcategories}
								currentCategory={currentCategory}
								setCurrentCategory={setCurrentCategory}
								templateType={templateType}
								form={form}
								edit={edit}
								categories={categories}
							/>
						))
					}
					</div >
				{/* } */}
			</div >
		</div >
	)
}

export default CategoryCheckedTemplate