import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	TEMPLATE_CONTENT_API
} from "../../../utils/constants"

const API = process.env.REACT_APP_API_URL;

export const templateContentApi = createApi({
	reducerPath: "templateContentApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getTemplateContent: builder.mutation({
			query: () => (
				{
					url: TEMPLATE_CONTENT_API.GET_TEMPLATE_CONTENT,
					method: "GET",
				})
		}),
		getTemplateContentInfo: builder.mutation({
			query: ({param}) => (
				{
					url: `${TEMPLATE_CONTENT_API.GET_TEMPLATE_CONTENT_INFO}?
					${param ? param : ''}
					`,
					method: "GET",
				})
		}),
		getTemplateContentV2: builder.mutation({
			query: ({filters, query, sortBy, order, page, limit}) => (
				{
					url: `${TEMPLATE_CONTENT_API.GET_TEMPLATE_CONTENT_V2}?
					${filters ? filters : ''}
					${query ? query : ''}
					${sortBy ? sortBy : ''}
					${order ? order : ''}
					${page ? page : ''}
					${limit ? limit : ''}
					`,
					method: "GET",
				})
		}),
		createTemplateContent: builder.mutation({
			query: (body) => (
				{
					url: TEMPLATE_CONTENT_API.CREATE_TEMPLATE_CONTENT,
					method: "POST",
					body
				})
		}),
		editTemplateContent: builder.mutation({
			query: (body) => (
				{
					url: TEMPLATE_CONTENT_API.EDIT_TEMPLATE_CONTENT,
					method: "POST",
					body
				})
		}),
		deleteTemplateContent: builder.mutation({
			query: (body) => (
				{
					url: TEMPLATE_CONTENT_API.DELETE_TEMPLATE_CONTENT,
					method: "POST",
					body
				})
		}),
		fixTemplateLanguageToEnglish: builder.mutation({
			query: (body) => (
				{
					url: TEMPLATE_CONTENT_API.FIX_TEMLATE_LANGUAGE,
					method: "POST",
					body
				})
		}),
	})
});

export const {
	useGetTemplateContentMutation,
	useGetTemplateContentInfoMutation,
	useGetTemplateContentV2Mutation,
	useCreateTemplateContentMutation,
	useEditTemplateContentMutation,
	useDeleteTemplateContentMutation,
	useFixTemplateLanguageToEnglishMutation
} = templateContentApi


// 1 - int
// 2 - select
// 3 - radio
// 4 - string
// 5 - bool
// 6 - int*int*int
// 7 - address
// 8 - media
// 9 - title(string)
// 11 - date(timestamp)
// 12 - date range from - to
// 13 - range from - to