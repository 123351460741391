import React, {
	useEffect,
	useState
} from 'react'
import { useDispatch } from "react-redux"
import {
	Button,
	Form,
	Modal
} from "react-bootstrap"
import { Formik } from "formik"
import Loader from "../Loader"
import {
	useCreateHtmlsMutation,
	useEditHtmlsMutation
} from "../../store/apis/htmlsApi"
import {
	createNewHtmlsItem,
	editHtmlsItem
} from "../../store/slices/htmlsSlice"
import AlertModal from "../AlertModal";
import {
	ARE_COMMON,
	PAGES_NAME
} from "../../../utils/textsContent"
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const AddEditHtmlsItem = ( { show, onHide, edit, editDateForModal } ) => {
	const [ form, setForm ] = useState( {} )
	const [ createHtmls, { isLoading: isCreateHtmls } ] = useCreateHtmlsMutation()
	const [ editHtmls, { isLoading: isEditHtmls } ] = useEditHtmlsMutation()
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const dispatch = useDispatch()
	const isLoading = isCreateHtmls || isEditHtmls
	const langs = useSelector(store => store.langStore.langs)
	const {t} = useTranslation()

	useEffect( () => {
		console.log("editDateForModal", editDateForModal);
		edit && setForm( editDateForModal )
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ edit ] )

	const formDateUpdateHandler = ( opt ) => {
		setForm( { ...form, ...opt } )
	}

	const handleSubmit = async ( values ) => {
		const bodyes = {}
		langs?.forEach(el => {
			bodyes[el.langIndex] = values?.[`${el.langIndex}Title`]
		})

		const formDate = {
			body: bodyes,
			css: values?.css || '',
			psevdo: values.psevdo.trim(),
			// editable: values.editable
		}
		try {
			if ( edit ) {
				const { error } = await editHtmls( { ...formDate, id: editDateForModal.id } )
				!error && dispatch( editHtmlsItem( { ...formDate, id: editDateForModal.id } ) )
				error && setErrorMessage( error?.data?.message )
				!error && clearFormHideModal()
			} else {
				const { data, error } = await createHtmls( formDate )
				!error && dispatch( createNewHtmlsItem( { ...formDate, id: data?.id } ) )
				error && setErrorMessage( error?.data?.message )
				!error && clearFormHideModal()
			}
		} catch ( e ) {
			console.log( e )
			setErrorMessage( e )
		}
	}

	const clearFormHideModal = () => {
		onHide()
		setForm( {} )
	}

	function setInitialValues(){
		const values = {}
		langs.forEach(el => {values[`${el.langIndex}Title`] = editDateForModal?.[`${el.langIndex}Title`] || editDateForModal?.body?.[`${el.langIndex}`] || ''})
		return values
	}

	return (
		<Modal
			show={ show }
			onHide={ clearFormHideModal }
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			className='add-edit-htmls_modal'
			style={ { zIndex: '99999' } }
		>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<Formik
				initialValues={ {
					...setInitialValues(),
					psevdo: editDateForModal?.psevdo || '',
					css: editDateForModal?.css || '',
					// editable: (form?.editable || form?.editable) || false
				} }
				onSubmit={ handleSubmit }
			>
				{
					( {
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty
					} ) => (
						<>
							<Modal.Header
								closeButton
							>
								<Modal.Title
									id='contained-modal-title-vcenter'
									className='add-edit-htmls_title'
								>
									{ edit ? t('_c_editHTMLS') : t('_c_creatingHTMLS') }
								</Modal.Title >
							</Modal.Header >
							<Modal.Body >
								<Form
									className='add-edit-terms'
									onSubmit={ handleSubmit }
								>
									<div className='add-edit-htmls_top'>

										{
											langs?.map((el, index) => {
												return(
													<Form.Group
														key={index}
														className='add-edit-htmls_label'
													>
														{`Body ${el.langIndex}`}
														<Form.Control
															as='textarea'
															rows='3'
															name={`${el.langIndex}Title`}
															onKeyDown={ e => {
																e.key === 'Enter' && e.preventDefault()
															} }
															onBlur={ handleBlur }
															className={ `add-edit-htmls_inp  ${ touched?.[`${el.langIndex}Title`] ? "is-touch " : "" } ${
																errors?.[`${el.langIndex}Title`] && touched?.[`${el.langIndex}Title`] ? " is-invalid" : ""
															} ` }
															value={ values?.[`${el.langIndex}Title`] }
															onChange={ ( e ) => {
																handleChange( e )
																formDateUpdateHandler( {
																	[e.target.name]: e.target.value
																} )
															} }
														/>
														{ errors[`${el.langIndex}Title`] && touched[`${el.langIndex}Title`] && (
															<Form.Control.Feedback type='invalid'>
																{ errors[`${el.langIndex}Title`] }
															</Form.Control.Feedback >
														) }
														<p className='add-edit-htmls_length'>
															{ form?.[`${el.langIndex}Title`]?.length || form?.body?.[el.langIndex]?.length || '0' }
														</p >
													</Form.Group >
												)
											})
										}
									</div >
									<Form.Group
										className='add-edit-htmls_center'
									>
										Mark
										<Form.Control
											type='text'
											name='psevdo'
											className='add-edit-htmls_center-inp'
											value={ values.psevdo }
											onKeyDown={ e => {
												e.key === 'Enter' && e.preventDefault()
											} }
											onChange={ ( e ) => {
												handleChange( e )
												formDateUpdateHandler( {
													[e.target.name]: e.target.value
												} )
											} }
										/>
									</Form.Group >
									<Form.Group
										className='add-edit-htmls_label'
									>
										CSS name
										<Form.Control
											as='textarea'
											rows='3'
											name='css'
											onBlur={ handleBlur }
											onKeyDown={ e => {
												e.key === 'Enter' && e.preventDefault()
											} }
											className={ `add-edit-htmls_inp ${ touched?.css ? "is-touch " : "" } ${
												errors?.css && touched?.css ? " is-invalid" : ""
											}` }
											value={ values.css }
											onChange={ ( e ) => {
												handleChange( e )
												formDateUpdateHandler( {
													[e.target.name]: e.target.value
												} )
											} }
										/>
										{ errors.css && touched.css && (
											<Form.Control.Feedback type='invalid'>
												{ errors.css }
											</Form.Control.Feedback >
										) }
										<p className='add-edit-htmls_length'>
											{ form?.css?.length || '0' }
										</p >
									</Form.Group >
									{/*<div className='add-edit-htmls_block'>*/ }
									{/*	<Form.Group*/ }
									{/*		htmlFor="checkboxEdit"*/ }
									{/*		className="add-edit-htmls_checkboxEdit"*/ }
									{/*	>*/ }
									{/*		<input*/ }
									{/*			type="checkbox"*/ }
									{/*			name='editable'*/ }
									{/*			id='checkboxEdit'*/ }
									{/*			className="add-edit-htmls_checkboxInp"*/ }
									{/*			defaultChecked={values.editable || form?.editable}*/ }
									{/*			onChange={(e) => {*/ }
									{/*				handleChange(e)*/ }
									{/*				formDateUpdateHandler({*/ }
									{/*					[e.target.name]: e.target.checked*/ }
									{/*				})*/ }
									{/*			}}*/ }
									{/*		/>*/ }
									{/*		Редагувати*/ }
									{/*	</Form.Group>*/ }
									{/*	{*/ }
									{/*		edit && <div className='add-edit-htmls_block-info'>*/ }
									{/*			<p>*/ }
									{/*				Термін використовуеться*/ }
									{/*			</p>*/ }
									{/*			<img*/ }
									{/*				src={infoIcon}*/ }
									{/*				alt="info"*/ }
									{/*			/>*/ }
									{/*		</div>*/ }
									{/*	}*/ }
									{/*</div>*/ }
								</Form >
							</Modal.Body >
							<Modal.Footer >
								<Button
									type='button'
									className='add-edit-htmls_close'
									onClick={ clearFormHideModal }
								>
									{t('cancel')}
								</Button >
								<Button
									type='submit'
									className='add-edit-htmls_save d-inline-flex'
									style={ {
										maxHeight: '33px',
										display: 'flex',
										alignItems: 'center'
									} }
									disabled={ (
										!isValid && dirty
									) }
									onClick={ handleSubmit }
								>
									{
										isLoading ? <Loader /> : t('save')
									}
								</Button >
							</Modal.Footer >
						</>
					)
				}
			</Formik >
		</Modal >
	)
}
export default AddEditHtmlsItem
