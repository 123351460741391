import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	USERS_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const rbacApi = createApi({
	reducerPath: "rbacApi",
	tagTypes: ['Admins', 'Roles'],
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		registrationUser: builder.mutation( {
			query: ( body ) => (
				{
					url: USERS_API.REGISTRATION,
					method: "POST",
					body
				}),
			invalidatesTags: [{ type: 'Admins', id: 'LIST' }],
		}),
		editAdmin: builder.mutation( {
			query: ( body ) => (
				{
					url: USERS_API.ADMIN_EDIT,
					method: "POST",
					body
				}),
			invalidatesTags: [{ type: 'Admins', id: 'LIST' }],
		}),
		deleteAdmin: builder.mutation( {
			query: ( body ) => (
				{
					url: USERS_API.ADMIN_DELETE,
					method: "POST",
					body
				}),
			invalidatesTags: [{ type: 'Admins', id: 'LIST' }],
		}),
		getUsersRoles: builder.query( {
			query: () => (
				{
					url: USERS_API.GET_ROLES,
					method: "GET",
				}),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Roles', id })),
					{ type: 'Roles', id: 'LIST' },
					]
				: [{ type: 'Roles', id: 'LIST' }],
		} ),
		addUsersRole: builder.mutation( {
			query: (body) => (
				{
					url: USERS_API.ADD_ROLE,
					method: "POST",
					body
				}),
				invalidatesTags: [{ type: 'Roles', id: 'LIST' }],
		} ),
		deleteUsersRole: builder.mutation( {
			query: (body) => (
				{
					url: USERS_API.DELETE_ROLE,
					method: "POST",
					body
				}),
				invalidatesTags: [{ type: 'Roles', id: 'LIST' }],
		} ),
		editUsersRole: builder.mutation( {
			query: (body) => (
				{
					url: USERS_API.EDIT_ROLE,
					method: "POST",
					body
				}),
				invalidatesTags: [{ type: 'Roles', id: 'LIST' }],
		} ),
		getAdmins: builder.query( {
			query: ({search}) => (
				{
					url: `${USERS_API.GET_ADMINS}?search=${search}`,
					method: "GET",
				}),
			providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Admins', id })),
					{ type: 'Admins', id: 'LIST' },
					]
				: [{ type: 'Admins', id: 'LIST' }],
		} ),
	})
});

export const {
	useRegistrationUserMutation,
	useEditAdminMutation,
	useDeleteAdminMutation,
	useGetUsersRolesQuery,
	useGetAdminsQuery,
	useAddUsersRoleMutation,
	useDeleteUsersRoleMutation,
	useEditUsersRoleMutation
} = rbacApi
