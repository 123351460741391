import React, {
	useEffect,
	useRef,
	useState
} from 'react'
import s from './AddContactsFiles.module.scss';
import AlertModal from "../../AlertModal";
import CSVReader from "../fileLoader/fileLoader";
import { ReactComponent as InfoIcon } from "../../../../utils/icons/info-icon.svg";
import file_template from '../../../../utils/assets/images/file_template.webp'
import { useTranslation } from 'react-i18next';

export const AddContactsFiles = ( { name, sendGroup } ) => {

	const button = useRef()
	const {t} = useTranslation()

	const [ fileData, setFileData ] = useState( false )

	useEffect( () => {
		if ( fileData.length ) button.current.style.opacity = 1;
		else button.current.style.opacity = 0.5;
	}, [ fileData ] )

	async function makeData() {
		if ( !fileData.length ) return;
		const body = {
			name: name,
			users: [],
			contacts: fileData
		}
		sendGroup( body )
	}

	return (
		<div className={ s.content }>
			<AlertModal
				content={`${t('_m_allowedFileFormats')}: CSV, TXT`}
				setErrorMessage={ () => {
				} }
				position
			/>
			<div className={ s.row }>
				<h2 >{t('_m_fileToDownload')}</h2 >
				<InfoIcon />
				<img
					src={ file_template }
					alt='FileTemplate'
				/>
			</div >
			<CSVReader setData={ setFileData } />
			<button
				className={ `terms-wrapper_addButton ${ s.add_button }` }
				ref={ button }
				onClick={ makeData }
			>
				{t('download')}
			</button >
		</div >
	)
}