import React from "react"
import {
	Form,
	Pagination
} from "react-bootstrap"

const PaginationCustom = ({
	itemsCount,
	currentPage,
	setCurrentPage,
	alwaysShown = true,
	counterAllItems,
	pageName
}) => {
	const pagesCount = counterAllItems ? Math.ceil( counterAllItems / itemsCount) : itemsCount
	const isPaginationShown = alwaysShown ? true : pagesCount > 0
	const isCurrentPageFirst = currentPage === 1
	const isCurrentPageLast = currentPage === pagesCount

	const changePage = number => {
		if (currentPage === number) return
		setCurrentPage(number)
	}

	const onPageNumberClick = pageNumber => {
		changePage(pageNumber)
		localStorage.setItem(pageName, JSON?.stringify(pageNumber))
	}

	const onPreviousPageClick = () => {
		changePage(currentPage => currentPage - 1)
		localStorage.setItem(pageName, JSON?.stringify(currentPage - 1))
	}

	const onNextPageClick = () => {
		changePage(currentPage => currentPage + 1)
		localStorage.setItem(pageName, JSON?.stringify(currentPage + 1))

	}

	// const setLastPageAsCurrent = () => {
	// 	if (currentPage > pagesCount) {
	// 		setCurrentPage(pagesCount)
	// 		localStorage.setItem(pageName, JSON?.stringify(pagesCount))
	// 	} else {
	// 		setCurrentPage(1)
	// 	}
	// }

	let isPageNumberOutOfRange

	const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
		const pageNumber = index + 1
		const isPageNumberFirst = pageNumber === 1
		const isPageNumberLast = pageNumber === pagesCount
		const isCurrentPageWithinTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 1
		if (
			isPageNumberFirst ||
			isPageNumberLast ||
			isCurrentPageWithinTwoPageNumbers
		) {
			isPageNumberOutOfRange = false
			return (
				<Pagination.Item
					key={pageNumber}
					onClick={() => onPageNumberClick(pageNumber)}
					active={pageNumber === Number(currentPage)}
				>
					{pageNumber}
				</Pagination.Item>
			)
		}

		if (!isPageNumberOutOfRange) {
			isPageNumberOutOfRange = true
			return <Pagination.Ellipsis
				key={pageNumber}
				className="muted"
			/>
		}

		return null
	})

	// useEffect(setLastPageAsCurrent, [pagesCount])

	return (
		<>
			{
				isPaginationShown
				&&
				(
				<div className='pagination-wrapper'>
					<Pagination className="d-flex align-items-center justify-content-start">
						<Pagination.Prev
							onClick={onPreviousPageClick}
							disabled={isCurrentPageFirst}
						/>
						{pageNumbers}
						<Pagination.Next
							onClick={onNextPageClick}
							disabled={isCurrentPageLast}
						/>
					</Pagination>
					<Form.Group className='pagination-input-block'>
						<Form.Control
							type='number'
							className='pagination-input-wrapper_inp'
							name='numberPage'
							value={currentPage}
							onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
							onChange={e => {
								setCurrentPage(e.target.value)
								localStorage.setItem(pageName, JSON?.stringify(Number(e.target.value)))
							}}
							placeholder='1'
							min={1}
							max={pagesCount}
						/>
						<span>of {pagesCount}</span>
					</Form.Group>
				</div>
				)
			}
		</>
	)
}

export default PaginationCustom
