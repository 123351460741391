import EditPencil from "../../../utils/icons/edit-pencil.svg"
import DeleteIcon from "../../../utils/icons/delete-icon.svg"

const AdvertisingTablItem = ( {
	setOpenEditModal,
	item,
	toggleDeleteModal
} ) => {
	return (
		<tr key={ item?.id }>
			{
				<>
					<td className='terms-wrapper_tabl-body_item col-1'>
						<div className='terms-wrapper_tabl-body_item-wrapper'>
							<span >{ item?.id }</span >
						</div >
					</td >
					<td className={ `terms-wrapper_tabl-body_item col-10` }>
						<p >
							{ item?.title }
						</p >
					</td >
					<td className='terms-wrapper_tabl-body_item col-2'>
						<div className='terms-wrapper_tabl-body_item-wrapper_icons'>
							<button
								type='button'
								onClick={ () => {
									setOpenEditModal( item )
								} }
							>
								<img
									src={ EditPencil }
									alt='Edit Pencil'
								/>
							</button >
							<button
								type='button'
								onClick={ () => {
									toggleDeleteModal( item )
								} }
							>
								<img
									src={ DeleteIcon }
									alt='Delete'
								/>
							</button >
						</div >
					</td >
				</>
			}
		</tr >
	)
}

export default AdvertisingTablItem
