import React, {
	useEffect,
	useState
} from 'react'
import CreateCategoryPage from "./CreateCategory/CreateCategoryPage"
import AlertModal from "../../components/AlertModal"
import DeleteModal from "../../components/DeleteModal"
import ControlPanels from "../../components/environmentTabl/ControlPanels"
import {
	useSelector
} from "react-redux"
import {
	ARE_COMMON,
	PAGES_NAME
} from "../../../utils/textsContent"
import { useGetCategoryMutation } from "../../store/apis/categoryApi"
import { useTranslation } from 'react-i18next'

const CategoriesPage = () => {
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ openEditModal, setOpenEditModal ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )
	const { category } = useSelector( state => state.categoryStore )
	const [getCategory, {isLoading: isGetCategoryLoading}] = useGetCategoryMutation()

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )
	const {t} = useTranslation()

	useEffect( () => {
		const getCategoriesList = async () => {
			await getCategory(`&list=null&orderBy=desc&sortingBy=position&isAll=true`)
		}
		getCategoriesList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [] )

	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const showModalHandler = ( flag ) => {
		setAddEditModalShow( true )
		setOpenEditModal( false )
	}
	const hideModalHandler = () => {
		setAddEditModalShow( false )
		setOpenEditModal( false )
		setEditDateForModal( {} )
	}
	const toggleDeleteModal = ( value ) => {
		// console.log(value)
		setDeleteTerm( value )
		setDeleteModalShow( !deleteModalShow )
	}

	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const openEditModalHandler = ( value ) => {
		setEditDateForModal( value )
		setAddEditModalShow( true )
		setOpenEditModal( true )
	}

	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ deleteTerm }
				fromPage={ 'category' }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				returnErrorForDel={ returnErrorForDel }
			/>
			{
				addEditModalShow
					?
					<>
						<h1 className='terms-title'>{ openEditModal ? t('cc_edit_title') : t('cc_create_title') }</h1 >
						<CreateCategoryPage
							onHide={ hideModalHandler }
							edit={ openEditModal }
							editDateForModal={ editDateForModal }
						/>
					</>
					:
					<>
						<h1 className='terms-title'>
							{t('s_cat')}
						</h1 >
						<ControlPanels
							bigArrElements={ category }
							showModalHandler={ showModalHandler }
							fromPage={ 'category' }
							isLoading={ isGetCategoryLoading }
							toggleDeleteModal={ toggleDeleteModal }
							showSearchBeforeDel={ showSearchBeforeDel }
							openEditModalHandler={ openEditModalHandler }
							searchTermValue={ searchTermValue }
							loaderSorting={ loaderSorting }
							setLoaderSorting={ setLoaderSorting }
							toggleModalSearch={ toggleModalSearch }
							showModalSearch={ showModalSearch }
							setShowSearchBeforeDel={ setShowSearchBeforeDel }
							valueSearchInputHandler={ valueSearchInputHandler }
						/>
					</>
			}
		</div >
	)
}

export default CategoriesPage
