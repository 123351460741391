import React from 'react'
import { Form } from 'react-bootstrap'
import CreatePollTools from './CreatePollTools'
import { ARE_COMMON } from "../../../../utils/textsContent";

const CreatePollName = ( { handleBlur, form, touched, errors, values, handleChange, formDateUpdateHandler } ) => {
	return (
		<div className='poll-create_content'>
			<div className='poll-create_block'>
				<h2 className='notification-email_content-title'>
					{ARE_COMMON?.NAME}
				</h2 >
				<Form.Group
					className='add-edit-htmls_center'
				>
					Заголовок
					<Form.Control
						type='text'
						name='name'
						onBlur={ handleBlur }
						placeholder='Заголовок'
						onKeyDown={ e => {
							e.key === 'Enter' && e.preventDefault()
						} }
						className={ `add-edit-htmls_center-inp  ${ touched?.name ? "is-touch " : "" } ${ errors?.name && touched?.name ? " is-invalid" : "" } ` }
						value={ values.name }
						onChange={ ( e ) => {
							handleChange( e )
							// formDateUpdateHandler( { [ e.target.name ]: e.target.value } )
						} }
					/>
					{ errors.name && touched.name && (
						<Form.Control.Feedback type='invalid'>
								{ errors.name }
							</Form.Control.Feedback >
					) }
					</Form.Group >
					<Form.Group
						className='add-edit-htmls_center'
					>
						Підзаголовок
						<Form.Control
							type='text'
							name='subname'
							onBlur={ handleBlur }
							placeholder='Підзаголовок'
							onKeyDown={ e => {
								e.key === 'Enter' && e.preventDefault()
							} }
							className={ `add-edit-htmls_center-inp  ${ touched?.subname ? "is-touch " : "" } ${ errors?.subname && touched?.subname ? " is-invalid" : "" } ` }
							value={ values.subname }
							onChange={ ( e ) => {
								handleChange( e )
								// formDateUpdateHandler( { [ e.target.name ]: e.target.value } )
							} }
						/>
						{ errors.subname && touched.subname && (
							<Form.Control.Feedback type='invalid'>
								{ errors.subname }
							</Form.Control.Feedback >
						) }
					</Form.Group >
				</div >
				<CreatePollTools />
			</div >
	)
}

export default CreatePollName