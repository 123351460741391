import React, { useState } from 'react';
import ControlPanelsV2 from '../../components/environmentTabl/ControlPanelsV2';
import DeleteModal from '../../components/DeleteModal';
import { ReactComponent as EditIcon } from '../../../utils/icons/edit-pencil.svg'
import { useGetEmailTemplatesQuery } from '../../store/apis/notificationApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const EmailsTemplatesPage = ({isModal}) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [query, setQuery] = useState({page: undefined, limit: undefined})
    const navigate = useNavigate()
    const {t} = useTranslation()

    const {data: templates, isLoading: isTemplatesLoading} = useGetEmailTemplatesQuery({})
    const table_columns = ["ID", "Description", ""]

    const table_rows = templates?.templates? JSON.parse(JSON.stringify(templates?.templates))?.sort((a, b) => a.id > b.id)?.map(el => {
		return [
			{type: "default", value: el.id},
			{type: "default", value: el.description},			
			{type: "icons", value: [<EditIcon onClick={()=>navigate(`/email-templates/create?id=${el.id}`)}/>]}
		]
	}) : []

    return(
        <div className="terms">
            <h1 className='terms-title'>{t('s_emailt')}</h1 >
            <DeleteModal
				show={ showDeleteModal }
				onHide={ setShowDeleteModal }
				value={ showDeleteModal }
				fromPage={ 'dealsbuttons' }
			/>
            <ControlPanelsV2 
                rows={table_rows} 
                columns={table_columns}
                totalCount={templates?.templates?.length} 
                isLoading={isTemplatesLoading}
                changeItem={()=>{}}
                isModal={isModal}
                query={query}
                setQuery={setQuery}
                selectedItems={[]}
                setItemsFromModal={()=>{}}
                setShowAddModal={()=>navigate("/email-templates/create")}
                fromPage="emailTemplates"
            />
        </div>
    )
}