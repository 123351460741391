import { createSlice } from "@reduxjs/toolkit"
import { usersApi } from "../apis/usersApi"
import { noticeApi } from "../apis/noticeApi"

const initialState = {
	users: [],
	showPageUserInfoAgreement: false,
	dateForPageUserInfoAgreement: {},
	userAdvertsList: [],
	warning: false
}

const usersSlice = createSlice({
	name: "users",
	initialState,
	reducers: {
		// createNewUsersItem: (state, action) => {
		// 	state.users = [action.payload, ...state.users]
		// },
		setDateForPageUserInfoAgreement: (state, action) => {
			state.dateForPageUserInfoAgreement = action.payload
		},
		toggleShowPageUserInfoAgreement: (state, action) => {
			state.showPageUserInfoAgreement = action.payload
		},

	},
	extraReducers: (builder) => {
		builder
			.addMatcher(usersApi.endpoints.getUsers.matchFulfilled, (
					state,
					action
				) => {
					state.users = action.payload.data.users
				}
			)
			.addMatcher(noticeApi.endpoints.searchNoticeForAuthorV2.matchFulfilled, (
					state,
					action
				) => {
					state.userAdvertsList = action.payload?.adverts
				}
			)
			.addMatcher(usersApi.endpoints.getWarnings.matchFulfilled, (
				state,
				action
			) => {
				state.warning = action.payload
			}
		)
	}
})

const {
	actions,
	reducer
} = usersSlice
export const {
	createNewUsersItem,
	setDateForPageUserInfoAgreement,
	toggleShowPageUserInfoAgreement

} = actions
export default reducer
