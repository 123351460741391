import React from 'react';
import s from './dealsMessagesTemplateModal.module.scss';

export const DealsMessagesTemplateModal = ({data, buttons})=>{
    return(
        <div className={s.modal_wrapper}>
            <div className={s.message_wrapper}>
                 
                <div className={s.conditions_wrapper}>
                {
                    data.conditions.map(condition => {
                        return(
                        <div className={s.condition_item}>
                            <span className={s.condition_title}>{condition.title}:</span>
                            <div className={s.condition_variables}>
                            {
                                condition.variables?.map(variable => {
                                    return <span className={s.condition_variable}><span className={s.galka}>✔</span>{variable.title}</span>
                                })
                            }
                            </div>
                        </div>
                        )
                    })
                }
                </div>
                <div className={s.buttons_wrapper}>
                    <div className={s.left}>
                        <span className={s.title}>Замовник</span>
                        <div className={s.buttons}>
                            {
                                data?.buttons_payer?.map((el, index) => {
                                    const current_button = buttons?.find(button => button.id === el.id)
                                    let currentStyle
                                    if(el.color === 1) currentStyle = s.accept
                                    if(el.color === 2) currentStyle = s.cancel
                                    if(el.color === 3) currentStyle = s.info
                                    return(
                                        <div key={index} className={`${s.deal_button} ${currentStyle}`}>
                                            <div className={s.button}>{current_button?.title?.title?.ua}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={s.right}>
                        <span className={s.title}>Продавець</span>
                        <div className={s.buttons}>
                        {
                                data?.buttons_seller?.map((el, index) => {
                                    const current_button = buttons?.find(button => button.id === el.id)
                                    let currentStyle
                                    if(el.color === 1) currentStyle = s.accept
                                    if(el.color === 2) currentStyle = s.cancel
                                    if(el.color === 3) currentStyle = s.info
                                    return(
                                        <div key={index} className={`${s.deal_button} ${currentStyle}`}>
                                            <div className={s.button}>{current_button?.title?.title?.ua}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}