import { compress } from "squoosh-compress"

export const squeezeFiles = async ( filesDiff ) => {
	let files = filesDiff;
	let flagSize = false;
	let flagType = false;

	if ( Array.isArray( files ) ) {
		for ( let i = 0; i < files.length; i++ ) {
			const { file, flagSize: flagS, flagType: flagT } = await typesFile( { file: files[i], flagSize, flagType } );

			flagSize = flagS;
			flagType = flagT;

			if ( flagSize || flagType ) {
				return [];
			} else {
				files[i] = file;
			}
		}

		if ( !flagSize && !flagType ) {
			return files;
		}
	} else {
		const { file, flagSize: flagS, flagType: flagT } = await typesFile( { file: files, flagSize, flagType } );
		
		flagSize = flagS;
		flagType = flagT;

		if ( flagSize || flagType ) {
			return undefined;
		} else {
			files = file;
		}

		if ( !flagSize && !flagType ) {
			return files;
		}
	}
};

const typesFile = async ( { file, flagSize, flagType } ) => {
	const types = [ "jpeg", "jpg", "png", "webp", "xml", "pdf", "msword" ];
	const typesImage = [ "jpeg", "jpg", "png", "webp" ];
	
	let fileCompress = file;

	const type = file?.type?.split( "/" )[1];
	
	if ( typesImage.some( ( typ ) => typ === type ) ) {
		fileCompress = await compress(
			file,
			{
				type: `browser-${type}`,
				options: {
					quality: 0.75
				}
			},
			file.name
		);
	}

	if ( fileCompress?.size >= 100000000 ) {
		flagSize = true;
		//toaster
		console.log( "Файл повинен бути не бiльше 10 Мб" );
		return { flagSize, flagType };
	}
	if ( !types.some( ( typ ) => typ === type ) ) {
		flagType = true;
		//toaster
		console.log( "Файл повинен бути у форматi jpeg/jpg/png/xml/pdf/doc" );
		return { flagSize, flagType };
	}

	return { file: fileCompress, flagSize, flagType };
};
