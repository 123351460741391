import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	UNITS_GROUPS_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const unitGroupsApi = createApi({
	reducerPath: "unitGroups",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getUnitGroups: builder.mutation({
			query: () => (
				{
					url: UNITS_GROUPS_API.GET_UNITS_GROUPS,
					method: "GET",
				})
		}),
		createUnitGroups: builder.mutation({
			query: (body) => (
				{
					url: UNITS_GROUPS_API.CREATE_UNITS_GROUPS,
					method: "POST",
					body
				})
		}),
		editUnitGroups: builder.mutation({
			query: (body) => (
				{
					url: UNITS_GROUPS_API.EDIT_UNITS_GROUPS,
					method: "POST",
					body
				})
		}),
		deleteUnitGroups: builder.mutation({
			query: (body) => (
				{
					url: UNITS_GROUPS_API.DELETE_UNITS_GROUPS,
					method: "POST",
					body
				})
		}),
	})
})

export const {
	useGetUnitGroupsMutation,
	useCreateUnitGroupsMutation,
	useEditUnitGroupsMutation,
	useDeleteUnitGroupsMutation,


} = unitGroupsApi
