import React from 'react'
import AddButton from "./AddButton"
import LoaderContent from "../LoaderContent"
import PaginationCustom from "../PaginationCustom"
import TemplateContentTabl from "../../pages/TemlateContent/TemplateContentTabl"
import { showChangeTemplateContentTemplates } from "../../store/slices/templateSlice"
import { useDispatch } from "react-redux"
import SearchItemsInput from "../SearchItemsInput"
import { ARE_COMMON } from "../../../utils/textsContent"
import SelectShowRecords from "../SelectShowRecords"
import { ARR_KEYS_TEMPLATE_CONTENT } from "../../../utils/constants"
import { useTranslation } from 'react-i18next'

const ControlPanelsForTemplateContentV2 = ({
	bigArrElements,
	isLoading,
	countShowPage,
	currentPage,
	setCurrentPage,
	counterAllItems,
	setCountShowTemplates,
	countShowTemplates,
	openEditModalHandler,
	sortedForColumn,
	toggleDeleteModal,
	valueSearchInputHandler,
	searchTemplateContentValue,
	clearSearchHandler,
	changeKeyValue,
	setChangeKeyValue,
	toggleButtonsType,
	showModalHandler,
	fromPage,
	searchHandler,
	toggleModalSearch
}) => {
	const dispatch = useDispatch()
	const {t} = useTranslation()
	return (
		<div className='terms-wrapper'>
			<div className='d-flex align-items-center'>
			<AddButton
				title={t('create')}
				handler={showModalHandler}
			/>
				{
					fromPage === 'changeItemsTemplateContentForTemplate'
					&&
					<AddButton
						title={t('add')}
						handler={() => dispatch(showChangeTemplateContentTemplates(false))}
					/>
				}
			</div >
			<div className='terms-wrapper_search-types'>
				<button
					type='button'
					className='terms-wrapper_search-types_button term-types_active'
					onClick={() => {
						toggleButtonsType(`${ARE_COMMON?.ALL}`, 0)
					}}
				>
					All
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						toggleButtonsType(`${ARE_COMMON?.INT}`, 1)
					}}
				>
					{ARE_COMMON?.INT}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						toggleButtonsType(`${ARE_COMMON?.SELECT}`, 2)
					}}
				>
					{ARE_COMMON?.SELECT}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(3)
						toggleButtonsType(`${ARE_COMMON?.RADIO}`,3)
					}}
				>
					{ARE_COMMON?.RADIO}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(5)
						toggleButtonsType(`${ARE_COMMON?.BOOL}`,5)
					}}
				>
					{ARE_COMMON?.BOOL}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(4)
						toggleButtonsType(`${ARE_COMMON?.STRING}`,4)
					}}
				>
					{ARE_COMMON?.STRING}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(6)
						toggleButtonsType(`${ARE_COMMON?.DIMENSIONS}`,6)
					}}
				>
					{ARE_COMMON?.DIMENSIONS}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(7)
						toggleButtonsType(`${ARE_COMMON?.ADDRESS}`,7)
					}}
				>
					{ARE_COMMON?.ADDRESS}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(8)
						toggleButtonsType(`${ARE_COMMON?.IMAGE}`,8)
					}}
				>
					{ARE_COMMON?.IMAGE}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(9)
						toggleButtonsType(`${ARE_COMMON?.TITLE}`,9)
					}}
				>
					{ARE_COMMON?.TITLE}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(11)
						toggleButtonsType(`Date(timestamp)`,11)
					}}
				>
					Date(timestamp)
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(12)
						toggleButtonsType(`Date range from - to`,12)
					}}
				>
					Date range from - to
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(12)
						toggleButtonsType(`Time range from - to`,101)
					}}
				>
					Time range from - to
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						// filteredTypeHandler(13)
						toggleButtonsType(`Range from - to`,13)
					}}
				>
					Range from - to
				</button >
			</div >
			<div className='terms-wrapper_search'>
				<div className='d-flex align-items-center justify-content-start'>
					<SearchItemsInput
						toggleModalSearch={toggleModalSearch}
						searchTermValue={searchTemplateContentValue}
						valueSearchInputHandler={valueSearchInputHandler}
						clearSearchHandler={clearSearchHandler}
						searchHandler={searchHandler}
					/>
					<div className='template-content_typeBlock-label'>
						<select
							aria-label='Default select example'
							className='template-content_typeBlock-select'
							name='key'
							defaultValue={changeKeyValue}
							onChange={(e) => {
								setChangeKeyValue(e.target.value)
							}}
						>
							<option ></option >
							{
								ARR_KEYS_TEMPLATE_CONTENT?.map((item, index) => (
										<option
											key={item + index}
											value={item}
										>
											{item}
										</option >
									)
								)
							}
						</select >
					</div >
				</div >
				<SelectShowRecords setCountShow={setCountShowTemplates} countShowTemplates={countShowTemplates}/>

			</div >
			{
				countShowPage > 1
				&&
				<PaginationCustom
					itemsCount={countShowPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>
			}
			{
				isLoading
					?
					<LoaderContent />
					:
					<TemplateContentTabl
						countShowPage={counterAllItems}
						setOpenEditModal={openEditModalHandler}
						toggleDeleteModal={toggleDeleteModal}
						sortedForColumn={sortedForColumn}
						countShowTemplates={countShowTemplates}
						showSmallTermArr={bigArrElements}
						fromPage={fromPage}
						// changeAllSelected={changeAllSelected}
					/>
			}
		</div >
	)
}

export default ControlPanelsForTemplateContentV2
