import React, {
	useCallback,
	useEffect, useRef,
	useState,
} from 'react'
import {
	useDispatch,
	useSelector
} from "react-redux"
import { useGetTemplateContentV2Mutation } from "../../../store/apis/temlateContentApi"
import AddEditBuilderTemplateContent from "../../TemlateContent/CreateTemplateContent/AddEditBuilderTemplateContent"
import { showModalEditTemplateContentTemplates } from "../../../store/slices/templateSlice"
import { setTabName } from "../../../store/slices/temlateContentSlice"
import AlertModal from "../../../components/AlertModal"
import ControlPanelsForTemplateContentV2 from "../../../components/environmentTabl/ControlPanelsForTemplateContentV2"
import { ShowFirstSelectedListItemsTemplate } from "../../../../utils/helpers/ShowFirstSelectedListItems"

const ChangeTemplateContentForTemplateModal = ({changedItems}) => {
	const {templateContent, changeTabName} = useSelector(state => state.templateContentStore)
	const [getTemplateContentV2, {isLoading: isTemplateContentV2Loading}] = useGetTemplateContentV2Mutation()
	const parameters = JSON.parse(localStorage.getItem('templateContentParametersPage'))
	const resV = parameters?.filters?.split('=').pop()
	const [addCheckedItemsMarkerInList, setAddCheckedItemsMarkerInList] = useState([])
	const {
		showModalEditTemplateContent,
		showModalEditDataTemplateContent,
		dateModalChangeUnitTemplatesArr,
		showModalChangeUnitTemplates,
		allElementsChangeInContentBlocks
	} = useSelector(state => state.templateStore)

	const [errorMessage, setErrorMessage] = useState('')

	const [searchTemplateContentValue, setSearchTemplateContentValue] = useState(parameters?.query?.split('=').pop() || '')
	const [sortedColumnValue, setSortedColumnValue] = useState(parameters?.sortBy?.split('=').pop() || '')
	const [currentPage, setCurrentPage] = useState(Number(parameters?.page?.split('=').pop()) || Number(1))
	const [countShowTemplates, setCountShowTemplates] = useState(Number(parameters?.limit?.split('=').pop()) || 20)
	const [changeOrderSort, setChangeOrderSort] = useState(parameters?.order?.split('=').pop() || 1)
	const [countShowPage, setCountShowPage] = useState(1)
	const [counterAllItems, setCounterAllItems] = useState(1)
	const [changeKeyValue, setChangeKeyValue] = useState(resV?.key ? resV?.key : '')
	const [changeTabValue, setChangeTabValue] = useState(resV?.type ? resV?.type : 0)
	const pastNumberCount = useRef(countShowTemplates)
	const pastValueSearchCount = useRef(searchTemplateContentValue)
	const dispatch = useDispatch()
	const allButtonsType = document.querySelectorAll('.terms-wrapper_search-types_button')
	const isLoading = isTemplateContentV2Loading

	const getAllTemplateContent = useCallback(async (search) => {
		const keyValue = changeKeyValue ? {"key": changeKeyValue} : ''
		const tabValue = changeTabValue ? {"type": changeTabValue} : ''
		const limit = `&limit=${countShowTemplates}`
		// const page = (searchTemplateContentValue !== pastValueSearchCount.current) || (countShowTemplates !== pastNumberCount.current) ? `&page=${Number(1)}` : `&page=${Number(currentPage)}`
		const page = `&page=${Number(currentPage)}`
		const querySearch =  search ? '' : searchTemplateContentValue ? {query: `&query=${searchTemplateContentValue.trim()}`} : ''
		const sortByItem = sortedColumnValue ? {sortBy: `&sortBy=${sortedColumnValue}`} : ''

		const filtersByItem = {filters: `&filters=${JSON.stringify({...tabValue, ...keyValue})}`}

	const tehFormData = {
		limit: limit,
		page: page,
		order: `&order=${changeOrderSort === 1 ? 1 : -1}`
	}

	try {
		if (parameters === null) {
			const {
				data,
				error
			} = await getTemplateContentV2({...tehFormData, ...querySearch, ...sortByItem, ...filtersByItem})
			!error && setCountShowPage(Number(data?.totalPages))
			!error && setCounterAllItems(Number(data?.totalTemplates))
			!error && localStorage.setItem('templateContentParametersPage', JSON?.stringify({...tehFormData, ...querySearch, ...sortByItem, ...filtersByItem}))
			error && setErrorMessage(error?.data?.message)
		} else {
			if (
				searchTemplateContentValue !== '' ||
				sortedColumnValue !== '' ||
				changeOrderSort ||
				countShowTemplates !== 20 ||
				currentPage !== 1
			) {
				const {
					data,
					error
				} = await getTemplateContentV2({...tehFormData, ...querySearch, ...sortByItem, ...filtersByItem})
				!error && setCountShowPage(Number(data?.totalPages))
				!error && setCounterAllItems(Number(data?.totalTemplates))
				!error && setCurrentPage(Number(data?.page))
				!error && localStorage.setItem('templateContentParametersPage', JSON?.stringify({...tehFormData, ...querySearch, ...sortByItem, ...filtersByItem}))
				!error && (pastNumberCount.current = countShowTemplates)
				!error && (pastValueSearchCount.current = searchTemplateContentValue)
				error && setErrorMessage(error?.data?.message)
			}
		}
	} catch (e) {
		setErrorMessage(e)
	}
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [searchTemplateContentValue, sortedColumnValue, changeOrderSort, countShowTemplates, currentPage, changeKeyValue, changeTabValue])


useEffect(() => {
	getAllTemplateContent()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [ sortedColumnValue, changeOrderSort, countShowTemplates, currentPage, changeKeyValue, changeTabValue])

	const toggleButtonsType = (name, num) => {
		for (let i = 0; i < allButtonsType?.length; i++) {
			if (allButtonsType[i].innerText === (name || changeTabName)) {
				allButtonsType[i].classList.add('term-types_active')
				changeTabName !== name && dispatch(setTabName(name))
			} else {
				allButtonsType[i].classList.remove('term-types_active')
			}
		}
		setChangeTabValue(num)
	}

	const valueSearchInputHandler = (value) => {
		setSearchTemplateContentValue(value)
	}
	const toggleModalSearch = (t) => {}

	const hideModalHandler = () => {
		dispatch(showModalEditTemplateContentTemplates(false))
	}


	const showModalHandler = () => {
		dispatch(showModalEditTemplateContentTemplates(true))
	}


	useEffect(() => {
		if (dateModalChangeUnitTemplatesArr?.length > 0) {
			const res = ShowFirstSelectedListItemsTemplate([...dateModalChangeUnitTemplatesArr, ...allElementsChangeInContentBlocks], templateContent, changedItems)
			setAddCheckedItemsMarkerInList(res)
		}else{
			const res = ShowFirstSelectedListItemsTemplate(allElementsChangeInContentBlocks, templateContent, changedItems)
			setAddCheckedItemsMarkerInList(res)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModalChangeUnitTemplates, templateContent])


	const sortedForColumnHandler = (sort) => {
		if (sort === sortedColumnValue) {
			if (changeOrderSort === 1) setChangeOrderSort(-1)
			else setChangeOrderSort(1)
		} else {
			setChangeOrderSort(1)
		}
		setSortedColumnValue(sort)
	}


	const clearSearchHandler = async () => {
		setSearchTemplateContentValue('')
		const search = true
		await getAllTemplateContent(search)
	}
	const searchHandler = async () => {
		const search = false
		await getAllTemplateContent(search)
	}
	// console.log('templateContent', templateContent)
		return (
		<div className='changeUnitForPrice'>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<div className='changeUnitForPrice-wrapper changeUnitForTemplates'>
				<ControlPanelsForTemplateContentV2
					bigArrElements={addCheckedItemsMarkerInList?.length > 0 ? addCheckedItemsMarkerInList : templateContent}
					fromPage={'changeItemsTemplateContentForTemplate'}
					isLoading={isLoading}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					countShowPage={countShowPage}
					counterAllItems={counterAllItems}
					setCountShowTemplates={setCountShowTemplates}
					searchTemplateContentValue={searchTemplateContentValue}
					countShowTemplates={countShowTemplates}
					sortedForColumn={sortedForColumnHandler}
					valueSearchInputHandler={valueSearchInputHandler}
					clearSearchHandler={clearSearchHandler}
					changeKeyValue={changeKeyValue}
					setChangeKeyValue={setChangeKeyValue}
					toggleButtonsType={toggleButtonsType}
					showModalHandler={showModalHandler}
					searchHandler={searchHandler}
					toggleModalSearch={toggleModalSearch}
				/>
				{
					showModalEditTemplateContent
					&&
					<div className={'changeUnitForTemplates-modal'}>
						<div className={'changeUnitForTemplates-modal_wrapper'}>
							<AddEditBuilderTemplateContent
								onHide={hideModalHandler}
								edit={showModalEditDataTemplateContent?.id ? true : false}
								editDateForModal={showModalEditDataTemplateContent}
							/>
						</div >
					</div >
				}
			</div >
		</div >
	)
}
export default ChangeTemplateContentForTemplateModal