import { useEffect, useRef } from 'react';
import s from './newTabs.module.scss';

export const NewTabs = ({items, tab = 0, setTab}) => {

    // ### STATE

    const tabs = useRef([])
    const line = useRef(null)

    // ### FUNCTIONS

    useEffect(()=>{
        let left_distance = 0;
        let current_width = 0;
        if(tabs.current){
            if(tab === false) tab = 0
            current_width = tabs.current[parseInt(tab)]?.offsetWidth
            tabs.current.slice(0, parseInt(tab))?.forEach(el => {
                left_distance = left_distance + el.offsetWidth
            })
        }
        if(line.current){
            line.current.style.cssText = `width: ${current_width}px; left: ${left_distance}px`
        }
        
    },[tab, tabs]);

    // ### VIEWS

    const items_view = items?.map((item, index) => {
        return (
            <span 
                key={index}
                className={`${s.item} ${tab === index.toString()? s.item_active : ''}`}
                onClick={() => setTab(item.id)}
                ref={(e) => {
                    if(e) tabs.current[index] = e
                }}
            >
                {item.title}
                {item.active ? <div className={s.dot}></div> : null}
            </span>
        )
    })

    return(
        <div className={s.tabs_wrapper}>
            {items_view}
            <span className={s.line} ref={line}></span>
        </div>
    )
}