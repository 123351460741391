import React, { useRef, useState } from 'react';
import s from './mainStatistics.module.scss';
import Info from "../../../../../utils/icons/statistic-page-icons/info-icon.svg"
import {ReactComponent as GreenArrow} from '../../../../../utils/icons/statistic-page-icons/green-arrow-icon.svg'
import { useSelector } from 'react-redux';
import { getTimestamp24HoursAgo, getTimestampOneMonthAgo, getTimestampWeekAgo } from '../../../../../utils/helpers/TransformDate';
import { configureDashboardDateAllTime } from '../../../../../utils/helpers/configureDashboardDateAllTime';
import {ReactComponent as LoadingIcon} from'../../../../../utils/icons/loading-icon.svg'
import { useTranslation } from 'react-i18next';

export const MainStatistics = ({ activeLines, setActiveLines, setDateRange, setActiveStatistics, setDateRangeType, DASHBOARD_STATISTICS })=>{

    const [groupsState, setGroupsState] = useState(0)
    const [timeRangeState, setTimeRangeState] = useState(0)
    const data = useSelector(store => store.statisticsStore.statistics)
    const configure_data = configureDashboardDateAllTime(data?.data)
    const statistics_row = useRef()
    const { t } = useTranslation()

    function changeStatistic(statistic){
		setActiveLines(prev =>{
			let newState = JSON.parse(JSON.stringify(prev))
			const isset = newState.find(el => el.name === statistic.name)
			if(isset){
				newState = newState.filter(el => el.name !== statistic.name)
				return newState
			} 
			else{
				newState.push(statistic)
				return newState
			}
		})
	}

    function changeGroupState(group){
        setGroupsState(group)
        Object.entries(DASHBOARD_STATISTICS.GRAPHIC_STATISTICS).forEach((el, index)=>{
            if(index === group){
                setActiveStatistics(el[1].items)
                setActiveLines([el[1].items[0]])
            }
        })
    }

    function changeTimeRange(variant){
        setTimeRangeState(variant)
        if(variant === 0){
            setDateRange({startDate: 0, endDate: new Date().getTime()})
            setDateRangeType('all')
        }
        if(variant === 1){
            setDateRange({startDate: getTimestamp24HoursAgo(), endDate: new Date().getTime()})
            setDateRangeType('24hours')
        }
        if(variant === 2){
            setDateRange({startDate: getTimestampWeekAgo(), endDate: new Date().getTime()})
            setDateRangeType('week')
        }
        if(variant === 3){
            setDateRange({startDate: getTimestampOneMonthAgo(), endDate: new Date().getTime()})
            setDateRangeType('month')
        }
    }

    const statistics = []

    Object.entries(DASHBOARD_STATISTICS.GRAPHIC_STATISTICS).forEach((el, index)=>{
        if(index === groupsState){
      
            const row = el[1].items.map((el, index) => {
                let value
                if(configure_data[el.name]){
                    if(el.name === 'average_publication_users' || el.name === 'average_deals_users') value = configure_data[el.name]
                    else value = configure_data[el.name].length
                }
                return(
                    <div className={`${s.block} ${activeLines?.find(item => item.name === el.name)? s.active_block : ''}`} 
                        key={index}
                        onClick={()=>changeStatistic(el)}
                    >
                        <div className={s.left}>
                            <p className={s.title}>
                                {el.title}
                            </p >
                            {configure_data[el.name]?
                                <div className={s.number}>
                                    <GreenArrow style={{stroke: data.procents[el.name] > -0.001? '#009B33':'#FF6347', transform: data.procents[el.name] < 0? 'rotate(90deg)':''}}/>
                                    <p className={s.big}>
                                        {value}
                                    </p >
                                    <p className={s.procent} style={{color: data.procents[el.name] > -0.001? '#009B33' : '#FF6347'}}>
                                        {`${data.procents[el.name] > 0?'+':''}${data.procents[el.name]?.toFixed(1)}%`}
                                    </p >
                                </div > :  <span className={s.loading_text}><LoadingIcon/></span>
                            }
                        </div >
                    </div >
                )
            })
            statistics.push(row)
        }
    });
   
    const groups_view = Object.entries(DASHBOARD_STATISTICS.GRAPHIC_STATISTICS).map((el, index) => {
        return(
            <span key={index} 
            className={`${s.statistic_group} ${groupsState === index? s.active_statistic_group : ''}`}
            onClick={()=>changeGroupState(index)}>
                {el[1].title}
            </span>
        )
    })

    return(
        <div className={s.container}>
            <div className={s.header_statistic}>
                <div className={s.left}>
                    <div className={s.statistics_groups_wrapper}>
                        <div className={s.statistics_groups}>
                            {groups_view}
                        </div>
                        <img
                            src={ Info }
                            alt='info icon'
                        />
                    </div>
                </div>
                <div className={s.right}>
                    <div className={s.timerange_wrapper}>
                        <div className={`${s.timerange} ${timeRangeState === 0? s.timerange_active:''}`}
                            onClick={()=>changeTimeRange(0)}>
                                {t("AllTime")}
                        </div>
                        <div className={`${s.timerange} ${timeRangeState === 1? s.timerange_active:''}`}
                            onClick={()=>changeTimeRange(1)}>
                                {t("24h")}
                        </div>
                        <div className={`${s.timerange} ${timeRangeState === 2? s.timerange_active:''}`}
                            onClick={()=>changeTimeRange(2)}>
                                {t("week")}
                        </div>
                        <div className={`${s.timerange} ${timeRangeState === 3? s.timerange_active:''}`}
                            onClick={()=>changeTimeRange(3)}>
                                {t("month")}
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.statistic_row} ref={statistics_row}>
                {statistics}
            </div>
        </div>
    )
}