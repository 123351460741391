import React from 'react'
import PlusIcon from '../../../../utils/icons/addPlusColor.svg'
import AddSectionIcon from '../../../../utils/icons/add-section-icon.svg'
import AddNameDescriptionIcon from '../../../../utils/icons/add-name-description-icon.svg'
import AddImageIcon from '../../../../utils/icons/add-image-icon.svg'
import AddVideoIcon from '../../../../utils/icons/add-video.svg'
import { ARE_COMMON } from "../../../../utils/textsContent"

const CreatePollTools = () => {
	return (
		<div className='poll-create_tools'>
			<ul className='poll-create_tools-wrapper'>
				<li className='poll-create_tools-wrapper_li'>
					<button className='poll-create_tools-wrapper_li-button' title={`${ARE_COMMON?.ADD} питання` }>
						<img
							src={ PlusIcon }
							alt='plus'
							className='poll-create_tools-wrapper_li-button_icon'
						/>
					</button >
					</li >
					<li className='poll-create_tools-wrapper_li'>
						<button className='poll-create_tools-wrapper_li-button' title={`${ARE_COMMON?.ADD} розділ` }>
							<img
								src={ AddSectionIcon }
								alt='section'
								className='poll-create_tools-wrapper_li-button_icon'
							/>
						</button >
					</li >
					<li className='poll-create_tools-wrapper_li'>
						<button className='poll-create_tools-wrapper_li-button' title={`${ARE_COMMON?.ADD} назву і опис` }>
							<img
								src={ AddNameDescriptionIcon }
								alt='name and description'
								className='poll-create_tools-wrapper_li-button_icon'
							/>
						</button >
					</li >
					<li className='poll-create_tools-wrapper_li'>
						<button className='poll-create_tools-wrapper_li-button' title={`${ARE_COMMON?.ADD} картинку` }>
							<img
								src={ AddImageIcon }
								alt='imageAdd'
								className='poll-create_tools-wrapper_li-button_icon'
							/>
						</button >
					</li >
					<li className='poll-create_tools-wrapper_li'>
						<button className='poll-create_tools-wrapper_li-button' title={`${ARE_COMMON?.ADD} відео` }>
							<img
								src={ AddVideoIcon }
								alt='video'
								className='poll-create_tools-wrapper_li-button_icon'
							/>
						</button >
					</li >
			</ul >
		</div >
	)
}

export default CreatePollTools