import React, { useState } from 'react'
import AddButton from "./AddButton"
import LoaderContent from "../LoaderContent"
import PaginationCustom from "../PaginationCustom"
import TemplateTabl from "../../pages/Templates/TemplateTabl"
import { ARE_COMMON } from "../../../utils/textsContent"
import SelectShowRecords from "../SelectShowRecords"
import SearchItemsInput from "../SearchItemsInput"
import { Form, Button } from 'react-bootstrap'
import { useSyncTemplatesToProdMutation } from '../../store/apis/templateApi'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import AlertModal from '../AlertModal'
import { useTranslation } from 'react-i18next'

const ControlPanelsForTemplate = ({
	bigArrElements,
	showModalHandler,
	isLoading,
	openEditModalHandler,
	toggleDeleteModal,
	showModalAddUnitHandler,
	fromPage,
	searchTemplateValue,
	valueSearchInputHandler,
	clearSearchHandler,
	sortedForColumn,
	currentPage,
	setCurrentPage,
	setCountShowTemplates,
	countShowPage,
	counterAllItems,
	countShowTemplates,
	searchHandler,
	toggleModalSearch
}) => {

	const [typeTemplates, setTypeTemplates] = useState('all')
	const [syncToProdQuery, {isLoading: isSyncLoading}] = useSyncTemplatesToProdMutation()
	const [confirmModal, setConfirmModal] = useState(false)
	const [successModal, setSuccessModal] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const {t} = useTranslation()

	async function SyncToProd(){
		const response = await syncToProdQuery({})
		if(response.error){
			setErrorMessage(response.error.error)
		}
		else if(response.data.status === 200){
			setSuccessModal(response.data.result)
		}
	}
	
	return (
		<div className='terms-wrapper'>
			{
				confirmModal ? 
				<ConfirmModal 
					title={t('migrate_templates')}
					text={t('migrate_warning')}
					close={()=>setConfirmModal(false)}
					success={SyncToProd}
					isLoading={isSyncLoading}
				/> : null
			}
			{
				(successModal || typeof successModal === 'number') ? 
				<ConfirmModal 
					title="Success"
					text={`${successModal.length} templates have been transferred to prod.`}
					close={()=>setSuccessModal(false)}
				/> : null
			}
			{
				errorMessage
				&& <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<div className='sync_button_wrapper'>
				<Button onClick={()=>setConfirmModal(true)}>Sync to prod</Button>
			</div>
			{
				(
					fromPage !== 'changeUnitForTemplateDiscount'
				) && <AddButton
					title={t('create')}
					handler={showModalHandler}
				/>
			}
			<div className='d-flex align-items-center'>
				{
					(
						fromPage === 'changeUnitForTemplateDiscount'
					)
					&&
					<AddButton
						title={t('create')}
						handler={showModalAddUnitHandler}
					/>
				}
				{
					(
						fromPage === 'changeUnitForTemplateDiscount'
					) && <AddButton
						title={`${ARE_COMMON?.ADD}`}
						// handler={closeModalChangeUnitForPrice}
					/>
				}
			</div >
			<div className='terms-wrapper_search'>
				<div style={{
					display: "flex",
					gap: "15px"
				}}>
					<SearchItemsInput
						toggleModalSearch={toggleModalSearch}
						searchTermValue={searchTemplateValue}
						valueSearchInputHandler={valueSearchInputHandler}
						clearSearchHandler={clearSearchHandler}
						searchHandler={searchHandler}
					/>
					<Form.Select 
						onChange={(el)=>{
							setTypeTemplates(el.target.value)
						}}
						style={{width: 'fit-content'}}
					>
						<option value="all">Всi</option>
						<option value="main">Main</option>
						<option value="middle">Middle</option>
					</Form.Select>
				</div>

				<SelectShowRecords setCountShow={setCountShowTemplates} countShowTemplates={countShowTemplates}/>

			</div >
			{
				countShowPage > 1
				&&
				<PaginationCustom
					itemsCount={countShowPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>
			}
			{
				isLoading
					?
					<LoaderContent />
					:
					<TemplateTabl
						countShowPage={counterAllItems}
						setOpenEditModal={openEditModalHandler}
						toggleDeleteModal={toggleDeleteModal}
						sortedForColumn={sortedForColumn}
						countShowTemplates={countShowTemplates}
						showSmallTermArr={typeTemplates === 'main'? bigArrElements.filter(el => !el.isMiddle) : typeTemplates === 'middle'? bigArrElements.filter(el => el.isMiddle) : bigArrElements}
						fromPage={fromPage}
					/>
			}
		</div >
	)
}

export default ControlPanelsForTemplate
