import { createSlice } from "@reduxjs/toolkit"
import { bodyTypeApi } from "../apis/bodyTypeApi"
import { UniqueArrItems } from "../../../utils/helpers/UniqueArrItems";

const initialState = {
	bodyType: [],
	showModalChangeBodyType: false,
	dateModalChangeBodyType: []
}

const bodyTypeSlice = createSlice( {
	name: "bodyType",
	initialState,
	reducers: {
		setBodyTypeList: ( state, action ) => {
			state.bodyType = action.payload
		},
		createNewBodyTypeItem: ( state, action ) => {
			state.bodyType = [ action.payload, ...state.bodyType ]
		},
		editBodyTypeItem: ( state, action ) => {
			const index = state.bodyType.findIndex(
				( term ) => {
					return term.id === action.payload.id
				}
			)
			state.bodyType[index] = action.payload
		},
		deleteBodyTypeItem: ( state, action ) => {
			const termId = action.payload
			const termID = state.bodyType.filter( term => term.id !== termId )
			state.bodyType = termID
		},
		showChangeBodyType: ( state, action ) => {
			state.showModalChangeBodyType = action.payload
		},
		setDateChangeBodyType: ( state, action ) => {
			const uniq = UniqueArrItems( [ ...state.dateModalChangeBodyType, action.payload ] )
			state.dateModalChangeBodyType = uniq
		},
		resetDateChangeBodyType: ( state, action ) => {
			state.dateModalChangeBodyType = []
		},
		deleteDateChangeBodyTypeArr: ( state, action ) => {
			const Id = Number( action.payload )
			const ID = state.dateModalChangeBodyType.filter( item => Number( item ) !== Id )
			state.dateModalChangeBodyType = ID
		},
	},
	extraReducers: ( builder ) => {
		builder
			.addMatcher( bodyTypeApi.endpoints.getBodyType.matchFulfilled, (
					state,
					action
				) => {
					state.bodyType = action.payload.data
				}
			)
	}
} )

const {
	actions,
	reducer
} = bodyTypeSlice
export const {
	setBodyTypeList,
	createNewBodyTypeItem,
	deleteBodyTypeItem,
	editBodyTypeItem,
	resetDateChangeBodyType,
	setDateChangeBodyType,
	showChangeBodyType,
	deleteDateChangeBodyTypeArr
} = actions
export default reducer
