import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	AMOUNT_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const amountApi = createApi({
	reducerPath: "amountApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getAmount: builder.mutation({
			query: () => (
				{
					url: AMOUNT_API.GET_AMOUNT,
					method: "GET",
				})
		}),
		createAmount: builder.mutation({
			query: (body) => (
				{
					url: AMOUNT_API.CREATE_AMOUNT,
					method: "POST",
					body
				})
		}),
		editAmount: builder.mutation({
			query: (body) => (
				{
					url: AMOUNT_API.EDIT_AMOUNT,
					method: "POST",
					body
				})
		}),
		deleteAmount: builder.mutation({
			query: (body) => (
				{
					url: AMOUNT_API.DELETE_AMOUNT,
					method: "POST",
					body
				})
		}),
	})
});

export const {
	useGetAmountMutation,
	useCreateAmountMutation,
	useEditAmountMutation,
	useDeleteAmountMutation,


} = amountApi
