import React, {
	useState
} from 'react'
import AddButton from "./AddButton"
import LoaderContent from "../LoaderContent"
import TermsTabl from "../../pages/Terms/TermsTabl"
import PaginationCustom from "../PaginationCustom"
import {
	useDispatch
} from "react-redux"
import { showChangeUnitPriceModal, showModalSubChangeUnitPrice } from "../../store/slices/priceSlice"
import { showChangeUnitAmountModal } from "../../store/slices/amountSlice"
import { showChangeUnitModalTemplates } from "../../store/slices/temlateContentSlice"
import SearchItemsInputTerms from "../SearchItemsInputTerms"
import { showChangeUnitModal } from "../../store/slices/unitsMeasurementSlice"
import TemplateTabl from "../../pages/Templates/TemplateTabl"
import { showChangeHTMLSModal } from "../../store/slices/htmlsSlice"
import {
	showChangeUnitGroupModalHandler,
	showChangeUnitGroupsModal
} from "../../store/slices/unitGroupsSlice"
import { showChangeMark } from "../../store/slices/markSlice"
import { showChangeMarkForModelModel } from "../../store/slices/modelSlice"
import { showChangeBodyType } from "../../store/slices/bodyTypeSlice"
import { showChangeTransportCategory } from "../../store/slices/transportCategorySlice"
import { ARE_COMMON } from "../../../utils/textsContent"
import SelectShowRecords from "../SelectShowRecords"
import AlertModal from "../AlertModal"
import { Button } from 'react-bootstrap'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { useMigrationTermsMutation } from '../../store/apis/termsApi'
import { useTranslation } from 'react-i18next'

const ControlPanelsTerms = ({
	bigArrElements,
	showModalHandler,
	isLoading,
	openEditModalHandler,
	toggleDeleteModal,
	showModalAddUnitHandler,
	fromPage,
	totalPages,
	totalCount,
	countShow,
	setCountShow,
	currentPage,
	setCurrentPage,
	setSearch,
	setOrder,
	setGroup,
	searchTermValue,
	toggleModalSearch,
	valueSearchInputHandler,
	setShowModalPrice,
	setShowModalAmount
}) => {
	const [errorMessage, setErrorMessage] = useState('')
	const [confirmModal, setConfirmModal] = useState(false)
	const [successModal, setSuccessModal] = useState(false)
	const allButtonsType = document.querySelectorAll('.terms-wrapper_search-types_button')
	const [migration, {isLoading: isMigrationLoading}] = useMigrationTermsMutation()
	const dispatch = useDispatch()
	const {t} = useTranslation()

	async function migrationToProd(){
		const response = await migration()
		if(response.error){
			setErrorMessage(response.error.error)
		}
		else if(response.data.status === 200){
			setSuccessModal(response.data.length)
		}
	}

	const closeModalChangeUnitForPrice = () => {
		fromPage !== 'changeUnitForPricesArrTemplate' && dispatch(showChangeUnitPriceModal(false))
		dispatch(showChangeUnitAmountModal(false))
		fromPage !== 'ChangeUnitGroups' && dispatch(showChangeUnitModalTemplates(false))
		dispatch(showChangeUnitModal(false))
		dispatch(showChangeHTMLSModal(false))
		dispatch(showChangeUnitGroupsModal(false))
		fromPage === 'changeUnitForUnit' && dispatch(showChangeUnitGroupModalHandler(false))
		fromPage === 'changeUnitForTemplates' && dispatch(showChangeUnitGroupModalHandler(false))
		fromPage === 'changeMarkForModel' && dispatch(showChangeMark(false))
		fromPage === 'changeMarKForModalCreate' && dispatch(showChangeMarkForModelModel(false))
		fromPage === 'changeTransportCategoryForModel' && dispatch(showChangeTransportCategory(false))
		fromPage === 'changeBodyTypeForModel' && dispatch(showChangeBodyType(false))
		fromPage === 'changeUnitForPricesArrTemplate' && dispatch(showModalSubChangeUnitPrice(false))
		setShowModalAmount !== undefined && setShowModalAmount(false)
		setShowModalPrice !== undefined && setShowModalPrice()
	}


	const toggleButtonsType = (name) => {
		for (let i = 0; i < allButtonsType.length; i++) {
			if (allButtonsType[i].innerText === name) {
				allButtonsType[i].classList.add('term-types_active')
			} else {
				allButtonsType[i].classList.remove('term-types_active')
			}
		}
	}

	return (
		<div className='terms-wrapper'>
			{
				fromPage === 'term' ?
				(
					<div className='absolute_button'>
						<Button onClick={()=>setConfirmModal(true)}>Sync to prod</Button>
						{
							confirmModal ? 
							<ConfirmModal 
								title="Copy all terms to prod?"
								text="If you are unsure of your actions, do not proceed"
								close={()=>setConfirmModal(false)}
								success={migrationToProd}
								isLoading={isMigrationLoading}
							/> : null
						}
						{
							(successModal || typeof successModal === 'number') ? 
							<ConfirmModal 
								title="Success!"
								text={`${successModal} terms have been transferred to prod.`}
								close={()=>setSuccessModal(false)}
							/> : null
						}
					</div>
				) : null
			}
			{
				errorMessage
				&& <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			{
				(
					fromPage !== 'changeUnitForPrice' &&
					fromPage !== 'changeUnitForPricesArr' &&
					fromPage !== 'changeUnitForAmount' &&
					fromPage !== 'changeUnitForTemplates' &&
					fromPage !== 'changeUnitForTemplatesSelect' &&
					fromPage !== 'changeUnitForUnit' &&
					fromPage !== 'changeTermsForTemplate' &&
					fromPage !== 'changeHTMLSForTemplate' &&
					fromPage !== 'ChangeUnitGroups' &&
					fromPage !== 'changeMarkForModel' &&
					fromPage !== 'changeMarKForModalCreate' &&
					fromPage !== 'changeBodyTypeForModel' &&
					fromPage !== 'changeUnitForPricesArrTemplate' &&
					fromPage !== 'changeTransportCategoryForModel' &&
					fromPage !== 'changeUnitForTemplateDiscount'
				) && <AddButton
					title={t('add')}
					handler={showModalHandler}
				/>
			}
			<div className='d-flex align-items-center'>
				{
					(
						fromPage === 'changeUnitForPrice' ||
						fromPage === 'changeUnitForPricesArr' ||
						fromPage === 'changeUnitForAmount' ||
						fromPage === 'changeUnitForTemplates' ||
						fromPage === 'changeUnitForTemplatesSelect' ||
						fromPage === 'changeUnitForUnit' ||
						fromPage === 'changeTermsForTemplate' ||
						fromPage === 'changeHTMLSForTemplate' ||
						fromPage === 'ChangeUnitGroups' ||
						fromPage === 'changeMarkForModel' ||
						fromPage === 'changeMarKForModalCreate' ||
						fromPage === 'changeBodyTypeForModel' ||
						fromPage === 'changeUnitForPricesArrTemplate' ||
						fromPage === 'changeTransportCategoryForModel' ||
						fromPage === 'changeUnitForTemplateDiscount'
					)
					&&
					<AddButton
						title={t('create')}
						handler={showModalAddUnitHandler}
					/>
				}
				{
					(
						fromPage === 'changeUnitForPrice' ||
						fromPage === 'changeUnitForAmount' ||
						fromPage === 'changeUnitForPricesArr' ||
						fromPage === 'changeUnitForTemplates' ||
						fromPage === 'changeUnitForTemplatesSelect' ||
						fromPage === 'changeUnitForUnit' ||
						fromPage === 'changeTermsForTemplate' ||
						fromPage === 'changeHTMLSForTemplate' ||
						fromPage === 'ChangeUnitGroups' ||
						fromPage === 'changeMarkForModel' ||
						fromPage === 'changeMarKForModalCreate' ||
						fromPage === 'changeBodyTypeForModel' ||
						fromPage === 'changeUnitForPricesArrTemplate' ||
						fromPage === 'changeTransportCategoryForModel' ||
						fromPage === 'changeUnitForTemplateDiscount'
					) && <AddButton
						title={t('add')}
						handler={closeModalChangeUnitForPrice}
					/>
				}
			</div >
			{
				fromPage === 'term'
				&&
				<div className='terms-wrapper_search-types'>
				<button
					type='button'
					className='terms-wrapper_search-types_button term-types_active'
					onClick={() => {
						setCurrentPage(1)
						setGroup(undefined)
						toggleButtonsType(`All`)
					}}
				>
					All
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						setCurrentPage(1)
						setGroup('interface')
						toggleButtonsType(`Interface`)
					}}
				>
					Interface
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						setCurrentPage(1)
						setGroup('select')
						toggleButtonsType(`Select`)
					}}
				>
					Select
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						setCurrentPage(1)
						setGroup('email')
						toggleButtonsType(`Email`)
					}}
				>
					Email
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						setCurrentPage(1)
						setGroup('deals_notification')
						toggleButtonsType(`Deals notification`)
					}}
				>
					Deals notification
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						setCurrentPage(1)
						setGroup('system')
						toggleButtonsType(`System`)
					}}
				>
					System
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						setCurrentPage(1)
						setGroup('error')
						toggleButtonsType(`Error`)
					}}
				>
					Error
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						setCurrentPage(1)
						setGroup('notfilled')
						toggleButtonsType(`Not filled`)
					}}
				>
					Not filled
				</button >
			</div >
			}
			<div className='terms-wrapper_search'>

				<SearchItemsInputTerms
					toggleModalSearch={toggleModalSearch}
					searchTermValue={searchTermValue}
					valueSearchInputHandler={valueSearchInputHandler}
					setSearch={setSearch}
				/>

				<SelectShowRecords setCountShow={setCountShow} />

			</div >
			{
				totalPages > 1
				&&
				<PaginationCustom
					itemsCount={countShow}
					counterAllItems={totalCount}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>
			}
			{
				isLoading
					?
					<LoaderContent />
					:
					<>
						{
							(
								fromPage === 'template'
							)
								?
								<TemplateTabl
									countShowPage={totalCount}
									setOpenEditModal={openEditModalHandler}
									toggleDeleteModal={toggleDeleteModal}
									showSmallTermArr={bigArrElements}
									fromPage={fromPage}
								/>
								:
								// counterAllItems
								// 	?
									<TermsTabl
										countShowPage={totalCount}
										setOpenEditModal={openEditModalHandler}
										toggleDeleteModal={toggleDeleteModal}
										countShowTerms={countShow}
										showSmallTermArr={bigArrElements}
										fromPage={fromPage}
										setOrder={setOrder}
									/>
									// :
									// <p className='userInfo-wrapper_block-pib_name mt-5'>За запросом нічого немає</p >
						}
					</>
			}
		</div >
	)
}

export default ControlPanelsTerms
