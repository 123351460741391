import { Table } from "react-bootstrap"
import verticalDots from '../../../utils/icons/vertical-dots.svg'
import ArrowSorting from '../../../utils/icons/arrow-sorting.svg'
import TemplateTablItem from "./TemplateTablItem"
import { ARE_COMMON } from "../../../utils/textsContent";
import { useTranslation } from "react-i18next";

const TemplateTabl = ( {
	setOpenEditModal,
	toggleDeleteModal,
	sortedForColumn,
	countShowTemplates,
	showSmallTermArr,
	countShowPage,
	fromPage,
} ) => {

	const {t} = useTranslation()

	return (
		<>
			<div className='terms-wrapper_itemsInfo'>
					<span className='terms-wrapper_itemsInfo-content'>
						{ARE_COMMON?.SHOWN} { countShowTemplates } {ARE_COMMON?.POINTS_FROM} { countShowPage }
					</span >
			</div >
			<Table
				responsive
				className='terms-wrapper_tabl'
				striped={ true }
			>
				<thead className='terms-wrapper_tabl-head'>
				<tr className='terms-wrapper_tabl-trow'>
					<th
						className='users-wrapper_tabl-trow_item col-1'
						onClick={ () => sortedForColumn( 'id' ) }
					>
						<span >ID</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className={ `users-wrapper_tabl-trow_item col-4'` }
						onClick={ () => sortedForColumn( 'name' ) }
					>
						<span >
							{t('name')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item col-4'
						onClick={ () => sortedForColumn( 'subcategory' ) }
					>
						<span >
							{t('subcategory')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item col-4'
						onClick={ () => sortedForColumn( 'date' ) }
					>
						<span >
							{t('date')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-2'
						// onClick={ () => sortedForColumn( 'value' ) }
					>
						<span >{t('status')}</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th className='users-wrapper_tabl-trow_item col-1 text-end'>
						<img
							src={ verticalDots }
							alt='Dots'
						/>
					</th >
				</tr >
				</thead >
				<tbody
					className='terms-wrapper_tabl-body'
				>
				{
					showSmallTermArr && showSmallTermArr?.map( (item, index) => <TemplateTablItem
						key={ item.id }
						item={ item }
						indexNumber={index}
						setOpenEditModal={ setOpenEditModal }
						toggleDeleteModal={ toggleDeleteModal }
						fromPage={ fromPage }
					/> )
				}
				</tbody >
			</Table >
		</>
	)
}

export default TemplateTabl
