import {
	Button,
	Form
} from "react-bootstrap"
import { Formik } from "formik"
import React, {
	Suspense,
	useEffect,
	useMemo,
	useState,
} from "react"
import Loader from "../../../components/Loader"
import NameBlockTemplate from "./NameBlockTemplate"
import {
	showChangeTemplateContentTemplates,
	resetDateChangeUnitTemplatesListArr,
	deleteMainBlockList,
	deleteDetailInfoBlockList,
	deleteAdditionalBlockList,
	resetMainBlockList,
	resetDetailInfoBlockList,
	resetAdditionalBlockList, setDateChangeUnitTemplatesListArr, setContentBlockList
} from "../../../store/slices/templateSlice"
import { useDispatch, useSelector } from "react-redux"
import {
	useCreateTemplateMutation,
	useUpdateTemplateMutation,
} from '../../../store/apis/templateApi'
import {
	resetDateChangeTerms,
	showChangeTermsModal,
} from '../../../store/slices/termsSlice'
import {
	resetDateChangeUnitPrice,
	resetDateChangeUnitPriceArr,
	showChangeUnitPriceModal, showModalSubChangeUnitPrice,
} from '../../../store/slices/priceSlice'
import {
	resetDateChangeUnitAmount,
	showChangeUnitAmountModal,
} from '../../../store/slices/amountSlice'
import {
	resetDateChangeUnitTemplates,
	resetDateChangeUnitTemplatesList,
	showChangeUnitModalTemplates,
} from '../../../store/slices/temlateContentSlice'
import AlertModal from "../../../components/AlertModal"
import {
	resetDateChangeMark,
	showChangeMark
} from "../../../store/slices/markSlice"
import {
	resetDateChangeTransportCategory,
	showChangeTransportCategory
} from "../../../store/slices/transportCategorySlice"
import {
	resetDateChangeBodyType,
	showChangeBodyType
} from "../../../store/slices/bodyTypeSlice"
import { ARE_COMMON } from "../../../../utils/textsContent"
import TypesParameters from "./TypesParameters"
import {setDateChangeUnitGroup,
	setDateChangeUnitGroupAmount,
	setDateChangeUnitGroupVolume
} from "../../../store/slices/unitGroupsSlice"
import { useGetTermsMutation } from "../../../store/apis/termsApi"
import { useGetUnitGroupsMutation } from "../../../store/apis/unitGroupsApi"
import { useLocation, useNavigate } from "react-router-dom"
import { APP_ROUTE_PAGES } from "../../../../utils/constants"
import DashbordDateComponents from "./DashbordDateComponents"
import ChangeTemplateContentForTemplateView from "./ChangeTemplateContentForTemplateView"
import ChangeTemplateContentForTemplateFilter from "./ChangeTemplateContentForTemplateFilter"
import CategoryCheckedTemplate from "./CategoryCheckedTemplate"
import { ConfirmModal } from "../../../components/ConfirmModal/ConfirmModal"
import { useTranslation } from "react-i18next"

const OptionsParameters = React.lazy(() => import('./OptionsParameters'))
const ChangePrices = React.lazy(() => import('./ChangePrices'))
const CommissionBuilder = React.lazy(() => import('./CommissionBuilder/CommissionBuilder'))
const DiscountsBuilder = React.lazy(() => import('./DiscountsBuilder/DiscountsBuilder'))
const PriceShiftBuilder = React.lazy(() => import('./PriceShift/PriceShiftBuilder'))
const BlockWithTemplateContentItems = React.lazy(() => import('./BlocksWithTemplateContent/BlockWithTemplateContentItems'))
const ChangeTemplateContentForTemplate = React.lazy(() => import('./ChangeTemplateContentForTemplate'))
const TitleComponents = React.lazy(() => import('./TitleComponents'))
const MarksComponents = React.lazy(() => import('./MarksComponents'))
const FacialParameters = React.lazy(() => import('./FacialParameters'))
const PROD_URL = process.env.REACT_APP_PROD_URL;

const initialState = {
	name: {},
	subcategory: '',
	content: [],
	prices: [],
	status: false,
	specialFunctionality: {
		address: false,
		loyalty: false,
		calendar: false,
		calendarData: {
			title: false,
			enterTime: false,
			escapeTime: false,
			minAmount: false,
			maxAmount: false,
		},
		phoneNumber: false,
		delivery: null,
		messages: null,
		typeOrderParameter: {},
		transportDimensions: {},
		description: {},
		date: false,
		commission: false,
		order: false,
		writing: false,
		image: false,
		imageRequired: false,
		typeOfCard: 0,
		map: false,
		point: false,
		dateObject: {},
		buttons: {
			inTop: false,
			publish: false,
			remake: false,
			archive: false,
			statistic: false,
			delete: false,
			edit: false,
			open: false,
			close: false,
			finish: false,
			tracking: false,
			point: false,
			isTranslatable: false,
			isRent: false
		},
		isPriceRequired: false,
		facialParameters: [],
		titleComponents: []
	},
	payment: [],
	discounts: [],
	type: 1,
	commission: []
}

const AddEditBuilderTemplate = ({templateType}) => {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const editDateForModal = location?.state
	const edit = location?.state === null ? false : true

	const [form, setForm] = useState(initialState)
	const [titleComponentsArr, setTitleComponentsArr] = useState([])
	const [marksComponentsArr, setMarksComponentsArr] = useState([])
	const [dashbordDateComponentsArr, setDashbordDateComponentsArr] = useState({})
	const [anchorForChangeContentBlock, setAnchorForChangeContentBlock] = useState(false)
	const [blockListView, setBlockListView] = useState([])
	const [blockListFilter, setBlockListFilter] = useState([])
	const [mainBlockListView, setMainBlockListView] = useState([])
	const [detailInfoBlockListView, setdetailInfoBlockListView] = useState([])
	const [additionalBlockListView, setadditionalBlockListView] = useState([])
	const [firstSortViewBlocks, setFirstSortViewBlocks] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [confirmModal, setConfirmModal] = useState(location?.state?.migrate)

	const [createTemplate, {isLoading: isCreateTemplateLoading}] = useCreateTemplateMutation()
	const [updateTemplate, {isLoading: isUpdateTemplateLoading}] = useUpdateTemplateMutation()
	const [getTerms, {isLoading: isGetTermsLoading}] = useGetTermsMutation()
	const [getUnitGroups, {isLoading: isUnitGroupsLoading}] = useGetUnitGroupsMutation()
	const {t} = useTranslation()

	const {terms} = useSelector(state => state.termsStore)
	const {mainBlockList, detailInfoBlockList, additionalBlockList} = useSelector(state => state.templateStore)

	const categories = useSelector(store => store.categoryStore.category)
	const subCategories = useSelector(store => store.subcategoryStore.subcategory)
	const langs = useSelector(store => store.langStore.langs)
	const rulesFunction = useSelector(store => store.rulesStore.rules)

	const isLoading =
		isCreateTemplateLoading ||
		isUpdateTemplateLoading ||
		isGetTermsLoading ||
		isUnitGroupsLoading 
	if(edit){
		langs.forEach(el => {
			initialState.name[`${el.langIndex}Title`] = editDateForModal.name[el.langIndex]
		})
	}else{
		initialState.name = {}
	}

	useEffect(()=>{
		return ()=>{
			clearFormHideModal()
		}
	},[]);
	
	useEffect(() => {
		if (edit === true) {
			const re = {
				specialFunctionality: {
					...editDateForModal?.specialFunctionality,
					typeOfCard: editDateForModal?.specialFunctionality?.typeOfCard
				}
			}

			const tehResCommission = editDateForModal?.commission?.map((item, index) => {
				return {...item, tehId: (Date.now() + index)}
			})
			const com = {commission: tehResCommission}

			const tehResDiscounts = editDateForModal?.discounts?.map((item, index) => {
				const termTeh = terms?.find(i => i?.id === item?.title)
				return {
					...item,
					tehId: (Date.now() + index),
					title: termTeh?.title !== undefined ? termTeh?.title : item?.title
				}
			})
			const dis = {discounts: tehResDiscounts}			

			setForm({...editDateForModal, ...re, ...com, ...dis, prices: editDateForModal.prices.map(el => {return {id: el.id, type: el.type}}) })
		}
	}, [edit, editDateForModal, editDateForModal?.commission, editDateForModal?.discounts, terms])

	const toggleShowTemplatesContentModal = (e) => dispatch(showChangeTemplateContentTemplates(true))

	const toggleShowPricesModal = (e) => dispatch(showModalSubChangeUnitPrice(true))

	const subCategorySearch = (id) => {
		const resultSub = subCategories.filter(sub => sub.parent === id)
		return resultSub
	}

	useEffect(() => {
		let arrAll = []
		let subArrAll = []
		let res = []
		let subRes = []
		for (let i = 0; i < categories?.length; i++) {
			res = subCategorySearch(categories[i]?.id)
			arrAll = [...arrAll, res]

			for (let i = 0; i < res.length; i++) {
				subRes = subCategorySearch(res[i].id)
				subArrAll = [...subArrAll, subRes]
			}
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subCategories, categories])

	const formDateUpdateHandler = (opt) => {
		opt && setForm(prev => { return {...prev, ...opt} })
	}

	useEffect(() => {
		const view_blocks = [...mainBlockListView, ...detailInfoBlockListView, ...additionalBlockListView]
		mainBlockList.forEach(el => {
			if(!view_blocks.find(t => t.id === el.id)){
				setBlockListView(prev => {
					if(prev.find(t => t.id === el.id)) return prev
					else return [...prev, el]
				})
			}
			if(!view_blocks.find(t => t.id === el.id)){
				setBlockListFilter(prev => {
					if((!!prev.find(t => t.el.id === el.id) || el.key !== "content")) return prev
					else return [...prev, {el, checked: editDateForModal?.blocksFilter?.includes(el.id)? true : false}]
				})
			}
		})
		detailInfoBlockList.forEach(el => {
			if(!view_blocks.find(t => t.id === el.id)){
				setBlockListView(prev => {
					if(prev.find(t => t.id === el.id)) return prev
					else return [...prev, el]
				})
			}
			if(!view_blocks.find(t => t.id === el.id)){
				setBlockListFilter(prev => {
					if((!!prev.find(t => t.el.id === el.id) || el.key !== "content")) return prev
					else return [...prev, {el, checked: editDateForModal?.blocksFilter?.includes(el.id)? true : false}]
				})
			}
		})
		additionalBlockList.forEach(el => {
			if(!view_blocks.find(t => t.id === el.id)){
				setBlockListView(prev => {
					if(prev.find(t => t.id === el.id)) return prev
					else return [...prev, el]
				})
			}
			if(!view_blocks.find(t => t.id === el.id)){
				setBlockListFilter(prev => {
					if((!!prev.find(t => t.el.id === el.id) || el.key !== "content")) return prev
					else return [...prev, {el, checked: editDateForModal?.blocksFilter?.includes(el.id)? true : false}]
				})
			}
		})

		const mainDataBlock = mainBlockList?.map(item => item?.id)
		const detailInfoDataBlock = detailInfoBlockList?.map(item => item?.id)
		const transportInfoDataBlock = additionalBlockList?.map(item => item?.id)
		dispatch(setContentBlockList([...mainDataBlock, ...detailInfoDataBlock, ...transportInfoDataBlock]))

		const arr = [...mainBlockList, ...detailInfoBlockList, ...additionalBlockList]
		const filterForTypeTitleComponents = arr?.filter(item => item?.type === 1 || item?.type === 2 || item?.type === 4)
		setTitleComponentsArr(filterForTypeTitleComponents)

		const filterForTypeMarksComponents = arr?.filter(item => item?.type === 7)
		setMarksComponentsArr(filterForTypeMarksComponents)

		const filterDashbordDateComponents = arr?.filter(item => item?.type === 12)
		setDashbordDateComponentsArr(filterDashbordDateComponents)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainBlockList, detailInfoBlockList, additionalBlockList])

	const handleSubmit = async () => {
		let error = false

		const mainDataBlock = mainBlockList?.map(item => item?.id)
		const detailInfoDataBlock = detailInfoBlockList?.map(item => item?.id)
		const transportInfoDataBlock = additionalBlockList?.map(item => item?.id)

		const mainBlockView = mainBlockListView?.map(item => item?.id)
		const detailInfoBlockView = detailInfoBlockListView?.map(item => item?.id)
		const transportInfoDataBlockView = additionalBlockListView?.map(item => item?.id)

		let resDiscounts = []
		form?.discounts?.forEach(item => {
			delete item?.tehId
			resDiscounts = [...resDiscounts, item]
		})

		let resCommissions = []
		form?.commission?.forEach(item => {
			delete item?.tehId
			resCommissions = [...resCommissions, item]
		})

		const titles = {}
		langs.forEach(el => {
			titles[el.langIndex] = form.name[`${el.langIndex}`]
		})

		// ===========> VALIDATION
		if(!form.prices?.length) error = t('c_pfip')
		form.prices.forEach(el => {
			if(!el.id || !el.type) error = t('c_pfip')
		})

		Object.entries(titles).forEach(el => {
			if(!el[1] || !el[1].length) error = t('c_pfitmn')
		})
		
		
		if(!form.specialFunctionality.typeOfCard) error = t('c_pstoc')

		if(form.specialFunctionality.delivery && !form.specialFunctionality.delivery.length){
			error = t('c_psd')
		}

		if(form.specialFunctionality.delivery && !form.specialFunctionality.delivery.length){
			error = t('c_psm')
		}

		if(form.specialFunctionality.calendar){
			Object.values(form.specialFunctionality.calendarData.title).forEach(el => {
				if(!el || !toString(el.length)) error = t('c_pitct')
			})
		}
		
		if(error){
			setErrorMessage(error)
			return
		}
		// ===========> VALIDATION
		const formDate = {
			name: titles,
			subcategory: form?.subcategory || [],
			content: [...mainDataBlock, ...detailInfoDataBlock, ...transportInfoDataBlock],
			contentView: [...mainBlockView, ...detailInfoBlockView, ...transportInfoDataBlockView],
			prices: form?.prices || [],
			status: true,
			specialFunctionality: {
				...form?.specialFunctionality,
				delivery: form?.specialFunctionality.delivery? form?.specialFunctionality.delivery.map(el => el.id) : null,
				messages: form?.specialFunctionality.messages? form?.specialFunctionality.messages.map(el => el.id) : null,
				calendar: form?.specialFunctionality.calendarData? true : false
			},
			payment: form?.payment,
			discounts: resDiscounts,
			type: form?.type,
			commission: resCommissions,
			blocks: {
				mainBlock: mainDataBlock,
				detailInfo: detailInfoDataBlock,
				transportInfo: transportInfoDataBlock,
			},
			blocksView: {
				mainBlockView: mainBlockView,
				detailInfoView: detailInfoBlockView,
				// transportInfoView: transportInfoDataBlockView,
			},
			blocksFilter: blockListFilter?.filter(el => el.checked).map(el => el.el.id)
		}
		try {
			if (edit && !form?.dublicate) {
				const sub = form?.subcategory?.length? form?.subcategory?.slice(-1)[0]?.id : form?.subcategory
				const priceArr = form?.prices?.map(item => {
					return {id: item?.id, type: item?.type}
				})

				const optionsA = typeof form?.specialFunctionality?.typeOrderParameter?.unit === "object" ? form?.specialFunctionality?.typeOrderParameter?.unit?.id : form?.specialFunctionality?.typeOrderParameter?.unit
				const optionsV = typeof form?.specialFunctionality?.transportDimensions?.unit === "object" ? form?.specialFunctionality?.transportDimensions?.unit?.id : form?.specialFunctionality?.transportDimensions?.unit

				const prShift = form?.specialFunctionality?.priceShift?.map((pr, index) => {
					return {...pr, id: index + 1}
				})

				const resSpeshFunction = {
					...form?.specialFunctionality,
					typeOrderParameter: {...form?.specialFunctionality?.typeOrderParameter, unit: optionsA},
					transportDimensions: {...form?.specialFunctionality?.transportDimensions, unit: optionsV},
					priceShift: prShift,
				}

				const discountUpdate = form?.discounts?.map(dis => {
					delete dis?.id
					const res = {...dis, unit: dis?.unit?.id || dis?.unit}
					return res
				})

				delete formDate?.dublicate
				delete (formDate?.subcategory)
				delete (formDate?.prices)
				delete (formDate?.discounts)

				const {error} = await updateTemplate({
					id: editDateForModal?.id, ...formDate,
					subcategory: sub,
					prices: priceArr,
					specialFunctionality: {
						...resSpeshFunction,
						delivery: form?.specialFunctionality.delivery? form?.specialFunctionality.delivery.map(el => el.id) : null,
						messages: form?.specialFunctionality.messages? form?.specialFunctionality.messages.map(el => el.id) : null
					},
					discounts: discountUpdate,
					migration: form.migrate
				})
				error && setErrorMessage(error?.data?.data?.message || error?.data?.message)
				if(form.migrate && !error) window.open(PROD_URL+"/templates")
				!error && clearFormHideModal()
			} else {
				if (form?.dublicate) {
					const sub = (form?.subcategory?.length >= 1 && form?.subcategory?.slice(-1)[0]?.id) || form?.subcategory
					const priceArr = form?.prices?.map(item => {
						return {id: item?.id, type: item?.type}
					})
					const optionsA = typeof form?.specialFunctionality?.typeOrderParameter?.unit === "object" ? form?.specialFunctionality?.typeOrderParameter?.unit?.id : form?.specialFunctionality?.typeOrderParameter?.unit
					const optionsV = typeof form?.specialFunctionality?.transportDimensions?.unit === "object" ? form?.specialFunctionality?.transportDimensions?.unit?.id : form?.specialFunctionality?.transportDimensions?.unit
					const prShift = form?.specialFunctionality?.priceShift?.map((pr, index) => {
						return {...pr, id: index + 1}
					})

					const resSpeshFunction = {
						...form?.specialFunctionality,
						typeOrderParameter: {...form?.specialFunctionality?.typeOrderParameter, unit: optionsA},
						transportDimensions: {...form?.specialFunctionality?.transportDimensions, unit: optionsV},
						priceShift: prShift,
					}

					const discountUpdate = form?.discounts?.map(dis => {
						delete dis?.id
						const res = {...dis, unit: dis?.unit?.id || dis?.unit}
						return res
					})

					delete formDate?.dublicate
					delete (formDate?.subcategory)
					delete (formDate?.prices)
					delete (formDate?.discounts)

					const {error} = await createTemplate({
						id: editDateForModal?.id, ...formDate,
						subcategory: sub,
						prices: priceArr,
						specialFunctionality: {
							...resSpeshFunction,
							delivery: form?.specialFunctionality.delivery? form?.specialFunctionality.delivery.map(el => el.id) : null,
							messages: form?.specialFunctionality.messages? form?.specialFunctionality.messages.map(el => el.id) : null
						},
						discounts: discountUpdate
					})
					error && setErrorMessage(error?.data?.data?.message || error?.data?.message)
					!error && clearFormHideModal()
				} else {
					const {error} = await createTemplate({...formDate, subcategory: form?.subcategory})
					error && setErrorMessage(error?.data?.data?.message || error?.data?.message)
					!error && clearFormHideModal()
				}
			}
		} catch (e) {
			setErrorMessage(e)
		}
	}
	const clearFormHideModal = () => {
		navigate(`${APP_ROUTE_PAGES.TEMPLATES}`)
		setForm(initialState)
		dispatch(showChangeTermsModal(false))
		dispatch(resetDateChangeTerms())
		dispatch(showChangeUnitPriceModal(false))
		dispatch(resetDateChangeUnitPrice())
		dispatch(showChangeUnitAmountModal(false))
		dispatch(resetDateChangeUnitAmount())
		dispatch(showChangeUnitModalTemplates(false))
		dispatch(resetDateChangeUnitTemplates())
		dispatch(resetDateChangeUnitTemplatesList())
		dispatch(showChangeTemplateContentTemplates(false))
		dispatch(resetDateChangeUnitTemplatesListArr())
		dispatch(showChangeMark(false))
		dispatch(resetDateChangeMark())
		dispatch(showChangeTransportCategory(false))
		dispatch(resetDateChangeTransportCategory())
		dispatch(showChangeBodyType(false))
		dispatch(resetDateChangeBodyType())

		dispatch(resetMainBlockList())
		dispatch(resetDetailInfoBlockList())
		dispatch(resetAdditionalBlockList())
		dispatch(resetDateChangeUnitPrice())
		dispatch(resetDateChangeUnitPriceArr())
		dispatch(setDateChangeUnitGroup(""))
		dispatch(setDateChangeUnitGroupAmount(""))
		dispatch(setDateChangeUnitGroupVolume(""))
	}

	useEffect(() => {
		if (!edit) {
			setTimeout(() => {
				setForm(initialState)
				dispatch(showChangeTermsModal(false))
				dispatch(resetDateChangeTerms())
				dispatch(showChangeUnitPriceModal(false))
				dispatch(resetDateChangeUnitPrice())
				dispatch(showChangeUnitAmountModal(false))
				dispatch(resetDateChangeUnitAmount())
				dispatch(showChangeUnitModalTemplates(false))
				dispatch(resetDateChangeUnitTemplates())
				dispatch(resetDateChangeUnitTemplatesList())
				dispatch(showChangeTemplateContentTemplates(false))
				dispatch(resetDateChangeUnitTemplatesListArr())
				dispatch(showChangeMark(false))
				dispatch(resetDateChangeMark())
				dispatch(showChangeTransportCategory(false))
				dispatch(resetDateChangeTransportCategory())
				dispatch(showChangeBodyType(false))
				dispatch(resetDateChangeBodyType())

				dispatch(resetMainBlockList())
				dispatch(resetDetailInfoBlockList())
				dispatch(resetAdditionalBlockList())
				dispatch(resetDateChangeUnitPrice())
				dispatch(resetDateChangeUnitPriceArr())
				dispatch(setDateChangeUnitGroup(""))
				dispatch(setDateChangeUnitGroupAmount(""))
				dispatch(setDateChangeUnitGroupVolume(""))
			}, 300)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const removeItemInBlock = (title, item) => {
		setBlockListView(prev => {
			return prev.filter(el => el.id !== item.id)
		})
		setMainBlockListView(prev => {
			return prev.filter(el => el.id !== item.id)
		})
		setdetailInfoBlockListView(prev => {
			return prev.filter(el => el.id !== item.id)
		})
		setadditionalBlockListView(prev => {
			return prev.filter(el => el.id !== item.id)
		})
	
		setAnchorForChangeContentBlock(true)
		if (title === t('c_mainBlock')) {
			dispatch(deleteMainBlockList(item?.id))
			dispatch(setDateChangeUnitTemplatesListArr(item))
		} else if (title === t('c_detailed_information')) {
			dispatch(deleteDetailInfoBlockList(item?.id))
			dispatch(setDateChangeUnitTemplatesListArr(item))
		} else if (title === t('c_additional_block')) {
			dispatch(deleteAdditionalBlockList(item?.id))
			dispatch(setDateChangeUnitTemplatesListArr(item))
		}
	}

	const MiddleCategoryCheckedTemplateComponent = useMemo(()=>{
		return(
			<CategoryCheckedTemplate
				formDateUpdateHandler={formDateUpdateHandler}
				categories={categories}
				templateType={templateType}
				value={edit? editDateForModal?.subcategory[editDateForModal?.subcategory.length -1]?.id : false}
				edit={edit}
				form={form}
			/>
		)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[form.subcategory, edit])
	
	return (
		<>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			{
				confirmModal ? 
				<ConfirmModal 
					title={t('migrate_templates')}
					text={t('migrate_warning')}
					close={()=>{setConfirmModal(false); clearFormHideModal()}}
					success={handleSubmit}
					isLoading={isUpdateTemplateLoading}
				/> : null
			}
			<Formik
				initialValues={initialState?.name}
				onSubmit={handleSubmit}
				style={{opacity: 0}}
			>
				{
					({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty,
					}) => (
						<Form
							className='add-edit-terms'
							onSubmit={handleSubmit}
						>
							<NameBlockTemplate
								handleBlur={handleBlur}
								values={form.name}
								touched={touched}
								errors={errors}
								setForm={setForm}
								handleChange={handleChange}
								formDateUpdateHandler={formDateUpdateHandler}
							/>
							<TypesParameters
								types={rulesFunction.rules_function}
								handleChange={handleChange}
								form={form}
								formDateUpdateHandler={setForm}
								edit={edit}
							/>
							{
								MiddleCategoryCheckedTemplateComponent
							}
							<Suspense fallback={<Loader />}>
								<ChangePrices
									toggleShowTemplatesContentModal={toggleShowPricesModal}
									formDateUpdateHandler={formDateUpdateHandler}
									form={form}
									edit={edit}
									handleBlur={handleBlur}
									touched={touched}
									errors={errors}
								/>
							</Suspense >
							<Suspense fallback={<Loader />}>
								<ChangeTemplateContentForTemplate
									toggleShowTemplatesContentModal={toggleShowTemplatesContentModal}
									formDateUpdateHandler={formDateUpdateHandler}
									form={form}
									edit={edit}
									handleBlur={handleBlur}
									touched={touched}
									errors={errors}
								/>
							</Suspense >
							<Suspense fallback={<Loader />}>
								{
									mainBlockList?.length ?
									<BlockWithTemplateContentItems
									num={4}
									title={t('c_mainBlock')}
									arr={mainBlockList}
									remove={removeItemInBlock}
									/> : null
								}
								{
									detailInfoBlockList?.length ?
									<BlockWithTemplateContentItems
										num={5}
										title={t('c_detailed_information')}
										arr={detailInfoBlockList}
										remove={removeItemInBlock}
									/> : null
								}
								{
									additionalBlockList?.length ?
									<BlockWithTemplateContentItems
										num={6}
										title={t('c_additional_block')}
										arr={additionalBlockList}
										remove={removeItemInBlock}
									/> : null
								}
							</Suspense >
							<Suspense fallback={<Loader />}>
								{
									blockListView.length?
									<ChangeTemplateContentForTemplateView
										toggleShowTemplatesContentModal={toggleShowTemplatesContentModal}
										formDateUpdateHandler={formDateUpdateHandler}
										form={form}
										edit={edit}
										handleBlur={handleBlur}
										touched={touched}
										errors={errors}
										firstSortViewBlocks={firstSortViewBlocks}
										setFirstSortViewBlocks={setFirstSortViewBlocks}
										blockListView={blockListView}
										blocksData={editDateForModal?.blocksView}
										setMainBlockListView={setMainBlockListView}
										setdetailInfoBlockListView={setdetailInfoBlockListView}
										setadditionalBlockListView={setadditionalBlockListView}
										setBlockListView={setBlockListView}
										style={{height: blockListView?.length ? "auto" : 0, overflow: blockListView?.length ? "visible" : "hidden", padding: blockListView?.length ? 30 : 0, opacity: blockListView?.length? 1 : 0}}
									/> : null
								}
								</Suspense >
							<Suspense fallback={<Loader />}>
								{
									mainBlockListView?.length ?
									<BlockWithTemplateContentItems
										num={7}
										title={`${t('c_mainBlock')} View`}
										arr={mainBlockListView}
										remove={removeItemInBlock}
										view={true}
										setMainBlockListView={setMainBlockListView}
										setdetailInfoBlockListView={setdetailInfoBlockListView}
										setadditionalBlockListView={setadditionalBlockListView}
										setBlockListView={setBlockListView}
									/> : null
								}
								{
									detailInfoBlockListView?.length ?
									<BlockWithTemplateContentItems
										num={8}
										title={`${t('c_detailed_information')} View`}
										arr={detailInfoBlockListView}
										remove={removeItemInBlock}
										view={true}
										setMainBlockListView={setMainBlockListView}
										setdetailInfoBlockListView={setdetailInfoBlockListView}
										setadditionalBlockListView={setadditionalBlockListView}
										setBlockListView={setBlockListView}
									/> : null
								}
								{
									additionalBlockListView?.length ?
									<BlockWithTemplateContentItems
										num={9}
										title={`${t('c_additional_block')} View`}
										arr={additionalBlockListView}
										remove={removeItemInBlock}
										view={true}
										setMainBlockListView={setMainBlockListView}
										setdetailInfoBlockListView={setdetailInfoBlockListView}
										setadditionalBlockListView={setadditionalBlockListView}
										setBlockListView={setBlockListView}
									/> : null
								}
							</Suspense >
							<Suspense fallback={<Loader />}>
								{
									blockListFilter.length?
									<ChangeTemplateContentForTemplateFilter
										data={blockListFilter}
										setData={setBlockListFilter}
									/> : null
								}
								</Suspense >
							{
								detailInfoBlockList.length > 0
								&&
								<Suspense fallback={<Loader />}>
									<FacialParameters
										arrList={detailInfoBlockList}
										formDateUpdateHandler={formDateUpdateHandler}
										form={form}
										edit={edit}
										anchorForChangeContentBlock={anchorForChangeContentBlock}
									/>
								</Suspense >
							}
							{
								titleComponentsArr?.length > 0
								&&
								<Suspense fallback={<Loader />}>
									<TitleComponents
										arrList={titleComponentsArr}
										formDateUpdateHandler={formDateUpdateHandler}
										form={form}
										edit={edit}
										anchorForChangeContentBlock={anchorForChangeContentBlock}
									/>
								</Suspense >
							}
							{
								marksComponentsArr?.length > 0
								&&
								<Suspense fallback={<Loader />}>
									<MarksComponents
										arrList={marksComponentsArr}
										formDateUpdateHandler={formDateUpdateHandler}
										form={form}
										edit={edit}
										anchorForChangeContentBlock={anchorForChangeContentBlock}
									/>
							</Suspense >
							}
							{
								dashbordDateComponentsArr?.length > 0
								&&
								<Suspense fallback={<Loader />}>
									<DashbordDateComponents
										arrList={dashbordDateComponentsArr}
										formDateUpdateHandler={formDateUpdateHandler}
										form={form}
										edit={edit}
										anchorForChangeContentBlock={anchorForChangeContentBlock}
									/>
							</Suspense >
							}
							<Suspense fallback={<Loader />}>
								<OptionsParameters
									formDateUpdateHandler={formDateUpdateHandler}
									form={form}
									setForm={setForm}
									handleChange={handleChange}
									handleBlur={handleBlur}
									edit={edit}
									getTerms={getTerms}
									getUnitGroups={getUnitGroups}
									isLoading={isLoading}
									blocks={[...mainBlockList, ...detailInfoBlockList, ...additionalBlockList, ...mainBlockListView, ...detailInfoBlockListView, ...additionalBlockListView, ...blockListView]}
								/>
							</Suspense >
							<Suspense fallback={<Loader />}>
								<DiscountsBuilder
									handleBlur={handleBlur}
									values={values}
									touched={touched}
									errors={errors}
									handleChange={handleChange}
									form={form}
									formDateUpdateHandler={formDateUpdateHandler}
									edit={edit}
									isLoading={isLoading}
									terms={terms}
								/>
							</Suspense >
							<Suspense fallback={<Loader />}>
								<CommissionBuilder
									handleBlur={handleBlur}
									values={values}
									touched={touched}
									errors={errors}
									handleChange={handleChange}
									form={form}
									formDateUpdateHandler={formDateUpdateHandler}
									edit={edit}
								/>
							</Suspense >
							{
								form?.prices?.length !== 0
								&&
								<Suspense fallback={<Loader />}>
									<PriceShiftBuilder
										handleBlur={handleBlur}
										values={values}
										touched={touched}
										errors={errors}
										handleChange={handleChange}
										form={form}
										formDateUpdateHandler={formDateUpdateHandler}
										edit={edit}
										isLoading={isLoading}
									/>
								</Suspense >
							}
							<div className='template-content_buttons'>
								<Button
									type='submit'
									className='template-content_buttons-save'
									disabled={!isValid && dirty}
								>
									{
										isLoading ? <Loader /> : t('save')
									}
								</Button >
								<Button
									type='button'
									className='template-content_buttons-close'
									onClick={clearFormHideModal}
								>
									{t('back')}
								</Button >
							</div >
						</Form >
					)}
			</Formik>
		</>
	)
}

export default AddEditBuilderTemplate