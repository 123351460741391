import React, {useRef, useState} from 'react'
import s from './contactList.module.scss'
import MenuIcon from '../../../../utils/icons/vertical-dots.svg'
import { useNavigate } from "react-router-dom"
import { ModalNewBook } from "../modalNewBook/modalNewBook"
import { transformDate } from "../../../../utils/helpers/TransformDate"
import { useTranslation } from 'react-i18next'
// import { ReactComponent as WarningIcon } from "../../../../utils/icons/warning-icon.svg"

export const ContactList = ({id, name, contacts, emails, date, phones, toggleDeleteModal, item, validationEmailDate}) => {
	const navigate = useNavigate()
	const modal = useRef()
	const [ nameModal, setNameModal ] = useState( false )
	const {t} = useTranslation()

	// const [checkGroup] = useEmailVerificationGroupMutation()

	function toggleMenu() {
		modal.current.classList.toggle( s.active )
	}

	function editName() {
		toggleMenu()
		setNameModal( true )
	}

	return (
		<div className={ s.list_wrapper }>
			<div
				className={ s.menu_icon }
				onClick={ toggleMenu }
			>
				<img
					src={ MenuIcon }
					alt='dots'
				/>
			</div >
			<div
				className={ s.tools_modal }
				ref={ modal }
			>
				<div className={ s.item }>
					<span
						onClick={ () => navigate( `/address-book/new?name=${ name }&id=${ id }` ) }
					>
						{ t('add_addresses') }
					</span >
				</div >
				<div className={ s.item }>
					<span onClick={ editName }>{t('edit_name')}</span >
				</div >
				<div className={ `${ s.item } ${ s.delete }` }>
					<span onClick={ () => toggleDeleteModal(item) }>{t('delete')}</span >
				</div >
				<div
					className={ s.modal_wrapper }
					onClick={ toggleMenu }
				>
				</div >
			</div >
			<div className={ s.left } onClick={ () => navigate( `/address-book/book?id=${ id }` ) }>
				<div className={ s.date }>
					<span >{ transformDate( date ) }</span >
					{/* {!validationEmailDate ?
					<span className={s.validation} onClick={()=>navigate("/notifications/validation")}><WarningIcon/>  Розсилка не можлива! Адресна книга не валiдована. <span className={s.validation_link}>Валiдацiя</span></span>
					: null
					} */}
				</div >
				<div className={ s.name } >
					<h1>{ name }</h1 >
				</div >
				<div className={ s.contacts }>
					<span >{t('contacts')} { contacts }</span >
				</div >
			</div >
			<div className={ s.block }  onClick={ () => navigate( `/address-book/book?id=${ id }` )} >
				<h1 >{ emails }</h1 >
				<span >{t('email')}</span >
			</div >
			<div className={ s.block } onClick={ () => navigate( `/address-book/book?id=${ id }` )}>
				<h1 >{ phones }</h1 >
				<span >{t('phone')}</span >
			</div >
			{
				nameModal
					?
					<ModalNewBook
						name={ name }
						data={ { id: id, name: name, contacts: contacts, emails: emails, date: date, phones: phones } }
						close={ setNameModal }
					/>
					:
					null
			}
		</div >
	)
}