import React, { useState } from 'react';
import ControlPanelsV2 from '../../components/environmentTabl/ControlPanelsV2';
import { ReactComponent as EditIcon } from '../../../utils/icons/edit-pencil.svg'
import { ReactComponent as DeleteIcon } from '../../../utils/icons/delete-icon.svg'
import DeleteModal from '../../components/DeleteModal';
import { useSelector } from 'react-redux';
import { PricesInfoModal } from './PricesInfoModal/PricesInfoModal';
import AddEditPriceItem from '../../components/modalsAddEdit/AddEditPriceItem';
import { useTranslation } from 'react-i18next';

export const Prices = ({ isModal, modalType, changedItems, setItems, close }) => {

    const [showAddModal, setShowAddModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [query, setQuery] = useState({search: undefined, type: undefined})
	const [editPrice, setEditPrice] = useState(false)
	// const [trigger, setTrigger] = useState(false)

	const prices = useSelector(store => store.priceStore.price)
	const unitlist = useSelector(store => store.unitsMeasurementStore.unitsMeasurement)
	// const unitGroupsList = useSelector(store => store.unitGroupsStore.unitGroups)
	const {showModalChangeUnitPrice} = useSelector((state) => state.priceStore)
	const {t, i18n} = useTranslation()

    const table_columns = [isModal? '' : "ID", "Name", "UnitList", "Info", ""]

    const table_rows = prices?.map(el => {
		const current_unit = unitlist?.find(unit => unit.id === el.unit)?.term?.ua
		return [
			{type: isModal? modalType === "checkbox"? 'checkbox' : 'radio' : "default", value: el.id},
			{type: "default", value: el?.tag?.[i18n.language]},
			{type: "default", value: current_unit},
			{type: "modal", value: <PricesInfoModal item={el} />},
			{type: "icons", value: [<EditIcon onClick={()=>setEditPrice(el)}/>, <DeleteIcon onClick={()=>setShowDeleteModal(el.id)}/>]}
		]
	})

    // if(isLoading) return <Loader/>
    
    return(
        <div className='terms'>
			<>
				<h1 className='terms-title'>{t('s_prices')}</h1 >
			</>
            <DeleteModal
				show={ showDeleteModal }
				onHide={ setShowDeleteModal }
				value={ showDeleteModal }
				fromPage={ 'dealsbuttons' }
			/>
            <ControlPanelsV2 
					rows={table_rows} 
					columns={table_columns}
					totalCount={prices?.length} 
					setShowAddModal={()=> isModal? setShowAddModal(true) : setEditPrice(true)}
					showAddModal={showAddModal}
					// addElementComponent = {<CreateDealsMessages isModal={true} close={setShowAddModal}/>}
					isLoading={false}
					changeItem={()=>{}}
					isModal={isModal}
					query={query}
					setQuery={setQuery}
					selectedItems={[]}
					setItemsFromModal={()=>{}}
				/>
				{ editPrice ? 
				<AddEditPriceItem 
					show={editPrice} 
					editDateForModal={editPrice?.id ? editPrice : false} 
					edit={editPrice?.id ? true : false} 
					onHide={()=>setEditPrice(false)} 
					trigger={true} 
					setModalTrigger={()=>{}}
					showModalChangeUnitPrice={showModalChangeUnitPrice}/> 
				: null}
		</div >
    )
}