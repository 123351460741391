import { useEffect, useState } from 'react';
import s from './createVerificationMessage.module.scss';
import LoaderIcon from '../../../components/Loader'
import { HTMLFieldsBlock } from '../../environmentTabl/HTMLFieldsBlock copy/HTMLFieldsBlock';
import Loader from '../../LoaderContent';
import { useTranslation } from 'react-i18next';
import { TextFieldsBlock } from '../../environmentTabl/TextFieldsBlock/TextFieldsBlock';

const tabs = [
    { id: 0, title: "All" },
    { id: 1, title: "User" },
    { id: 2, title: "Verification" },
    { id: 3, title: "Passport/Driver's license" },
    { id: 4, title: "Advertising Companies" },
    { id: 5, title: "Adverts" }
]

const initialState = {
    title: {},
    message: {},
    type: null
}

export const CreateVirificationMessage = ({close, values, update, type, create, isLoading}) => {
    // ### State
    const [form, setForm] = useState(initialState)
    const {t} = useTranslation()

    // ### Effects
    useEffect(()=>{
        console.log("################ from", form);
    },[form]);
    useEffect(()=>{
        if(values?.id || type){
            setForm(prev => { return {...prev, ...values, type: type? type : values.type} })
        }
    },[values, type]);

    // ### Functions

    // ### Views

    return(
        <div className={s.modal_wrapper}>
            <div className={s.modal}>
                <h1 className={s.title}>{t('moderation_messages_title')}</h1>
                <div className={s.content}>
                    <TextFieldsBlock values={form.title} title={"Title"} changeValue={(value) => {setForm(prev => { return {...prev, title: {...prev.title, ...value}}})}}/>
                    <HTMLFieldsBlock values={form.message} title={"Message"} changeValue={(value) => {setForm(prev => { return {...prev, message: {...prev.message, ...value}}})}}/>
                </div>
                <div className={s.type_wrapper}>
                    <span className="title">Type:</span>
                    <select onChange={(el)=>{ setForm(prev => { return {...prev, type: parseInt(el.target.value)} }) }} value={form.type}>
                        {
                            tabs?.slice(1)?.map(el => (
                                <option value={el.id}>{el.title}</option>
                            ))
                        }
                    </select>
                </div>
                <footer className={s.footer}>
                    <button
                        type='submit'
                        className='template-content_buttons-save btn btn-primary'
                        onClick={()=>{values?.id? update(form) : create(form)}}
                    >
                            {false? <LoaderIcon/> : t('confirm')}
                    </button >
                    <button
                        type='button'
                        className='template-content_buttons-close btn btn-primary me-3'
                        onClick={close}
                    >
                        {t('reject')}
                    </button >
                </footer>
                {isLoading? <Loader/> : null}
            </div>
        </div>
    )
}