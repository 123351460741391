import {createSlice} from "@reduxjs/toolkit"
import {unitsMeasurementApi} from "../apis/unitsMeasurementApi"

const initialState = {
	unitsMeasurement: [],
	showModalChangeUnit: false,
	dateModalChangeUnit: ''
}

const unitsMeasurementSlice = createSlice({
	name: "unitsMeasurement",
	initialState,
	reducers: {
		setUnitsMeasurementList: (state, action) => {
			state.unitsMeasurement = action.payload
		},
		createNewUnitsMeasurementItem: (state, action) => {
			state.unitsMeasurement = [action.payload, ...state.unitsMeasurement]
		},
		editUnitsMeasurementItem: (state, action) => {
			const index = state.unitsMeasurement.findIndex(
				(term) => {
					return term.id === action.payload.id
				}
			)
			state.unitsMeasurement[index] = action.payload
		},
		deleteUnitsMeasurementItem: (state, action) => {
			const termId = action.payload
			const termID = state.unitsMeasurement.filter(term => term.id !== termId)
			state.unitsMeasurement = termID
		},
		showChangeUnitModal: (state, action) => {
			state.showModalChangeUnit = action.payload
		},
		setDateChangeUnit: (state, action) => {
			state.dateModalChangeUnit = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(unitsMeasurementApi.endpoints.getUnitsMeasurement.matchFulfilled, (
					state,
					action
				) => {
					state.unitsMeasurement = action.payload.data
				}
			)
	}
})

const {
	actions,
	reducer
} = unitsMeasurementSlice
export const {
	setUnitsMeasurementList,
	createNewUnitsMeasurementItem,
	deleteUnitsMeasurementItem,
	editUnitsMeasurementItem,
	showChangeUnitModal,
	setDateChangeUnit
} = actions
export default reducer
