import React, { useState } from 'react'
import eyeClose from "../../../../../utils/icons/eye-close.svg"
import eyeOpen from "../../../../../utils/icons/eye-open.svg"
import { Form } from "react-bootstrap"
import { ARE_COMMON } from "../../../../../utils/textsContent";

const RbacAdminInfoItemPassword = () => {

	const [ passwordType, setPasswordType ] = useState( "password" )

	const reversePasswordType = () => {

		if ( passwordType === "password" ) {
			setPasswordType( "text" )
		} else {
			setPasswordType( "password" )
		}
	}
	return (
		<div className='rbac-wrapper_info'>
						<div className='rbac-wrapper_info-title'>
							Пароль
						</div >
								<div className='rbac-wrapper_info-contacts_li rbac-wrapper_info-password'>
									<div className='rbac-wrapper_info-password_wrapper'>
										<span
											onClick={ reversePasswordType }
											className='login-container_form-inputs_password-eye rbac-wrapper_info-password_eye'
										>
										{
											passwordType === 'password'
											&& (
												<img
													src={ eyeClose }
													alt='Eye Close'
												/>
											)
										}
											{
												passwordType === 'text'
												&& (
													<img
														src={ eyeOpen }
														alt='Eye Close'
													/>
												)
											}
									</span >

									<Form.Control
										type={ passwordType }
										name='password'
										className='login-container_form-inputs_password rbac-wrapper_info-password_inp'
										placeholder='********'
										onKeyDown={ e => {
											e.key === 'Enter' && e.preventDefault()
										} }
									/>
									</div >
									<button className='add-edit-terms_close rbac-wrapper_info-password_button'>
										{ARE_COMMON?.CAST} пароль
									</button >
								</div >
					</div >
	)
}

export default RbacAdminInfoItemPassword