import React, {
	useState
} from "react"
import { useSelector } from "react-redux"
import AddEditUnitsMeasurementItem from "../../components/modalsAddEdit/AddEditUnitsMeasurementItem"
import ControlPanels from "../../components/environmentTabl/ControlPanels"

const ChangeUnitsForPrice = () => {
	const { unitsMeasurement } = useSelector( ( state ) => state.unitsMeasurementStore )
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ addUnitFromPriceShowModal, setAddUnitFromPriceShowModal ] = useState( false )

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}
	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
	}

	const openEditModalHandler = ( value ) => {
		setAddEditModalShow( true )
	}

	const showModalAddUnitForPriceHandler = () => {
		setAddUnitFromPriceShowModal( true )
	}
	const hideModalAddUnitForPriceHandler = () => {
		setAddUnitFromPriceShowModal( false )
	}

	return (
		<div className='changeUnitForPrice'>
			<div className='changeUnitForPrice-wrapper changeUnitForTemplates'>
				<ControlPanels
					bigArrElements={ unitsMeasurement }
					showModalHandler={ showModalHandler }
					openEditModalHandler={ openEditModalHandler }
					fromPage={ 'changeUnitForPrice' }
					showModalAddUnitHandler={ showModalAddUnitForPriceHandler }
					searchTermValue={ searchTermValue }
					loaderSorting={ loaderSorting }
					setLoaderSorting={ setLoaderSorting }
					toggleModalSearch={ toggleModalSearch }
					showModalSearch={ showModalSearch }
					showSearchBeforeDel={ showSearchBeforeDel }
					setShowSearchBeforeDel={ setShowSearchBeforeDel }
					valueSearchInputHandler={ valueSearchInputHandler }
				/>
				{
					addUnitFromPriceShowModal && <AddEditUnitsMeasurementItem
						show={ addUnitFromPriceShowModal }
						onHide={ hideModalAddUnitForPriceHandler }
					/>
				}
			</div >
		</div >
	)
}

export default ChangeUnitsForPrice
