import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import s from './NotificationSMSPage.module.scss';
import { Form } from 'react-bootstrap'
import { Formik } from 'formik'
import AlertModal from '../../../components/AlertModal'
import DatePicker from 'react-datepicker'
import CloseIcon from '../../../../utils/icons/close-classik-icon-violet.svg'
import SelectForm from '../NotificationComponents/SelectForm'
import { createNewTerm } from '../../../store/slices/termsSlice'
import { useDispatch } from 'react-redux'
import { useCreateNotificationMutation } from '../../../store/apis/notificationApi'
import {
	ARE_COMMON,
	PAGES_NAME
} from "../../../../utils/textsContent";
import { createNewNotification } from "../../../store/slices/notificationSlice";
import { useRef } from 'react'
import Loader from '../../../components/Loader'
import { useGetLangsQuery } from '../../../store/apis/langApi'
import { useNavigate } from 'react-router-dom';
import { useCreateHtmlsMutation } from '../../../store/apis/htmlsApi';
import { useTranslation } from 'react-i18next';


const NotificationSMSPage = ({toggleAddEditViberListPage, groupsList, dataNotification, isDoubleNotification, setIsDoubleNotification, setIsLoading}) => {
	
	const initialState = {
		type: 0,
		title: null,
		audienceCustom: dataNotification?.audienceCustom || [],
		audienceSystem: dataNotification?.audienceSystem || [],
		repeat: dataNotification?.startRepeat? dataNotification.startRepeat : null,
		start: '',
		typeNotification: dataNotification?.typeNotification? dataNotification.typeNotification : 0,
		repeatCode: dataNotification?.repeatCode? dataNotification.repeatCode : 0,
		mark: [],
		body: dataNotification?.title[0].body? dataNotification?.title[0].body : {}
	}

	const [form, setForm] = useState(initialState)
	const [errorMessage, setErrorMessage] = useState('')
	const [showDatePicker, setShowDatePicker] = useState(dataNotification? true : false)
	const [checkDeliveryTime, setCheckDeliveryTime] = useState(dataNotification? true : false)
	const [date, setDate] = useState(dataNotification? new Date(dataNotification.firstSend): null)
	const [timeLifeMessage, setTimeLifeMessage] = useState(dataNotification?.startRepeat? dataNotification.startRepeat : 0)
	const [defaultLang, setdefaultLang] = useState();
	const {t} = useTranslation()

	const selectAddressBookRef = useRef()
	const typeNotification = useRef();
	const repeatCodeRef = useRef()
	const navigate = useNavigate()

	const [createHtmls, {isLoading: isCreateHtmls}] = useCreateHtmlsMutation()
	const [createNotification, {isLoading: isCreateNotificationLoading}] = useCreateNotificationMutation()
	const {data: langs, isLoading: isLangsLoading} = useGetLangsQuery();

	const dispatch = useDispatch()

	const isLoading = isCreateHtmls || isCreateNotificationLoading || isLangsLoading
	const formDateUpdateHandler = useCallback((opt) => {setForm({...form, ...opt})}, [form])
	const formDateUpdateBodyHandler = (opt) => setForm(prev => {return {...form, body: {...prev.body, ...opt}}})
	const showDatePickerHandler = (value) => setShowDatePicker(value)

	const plusTimeLifeMessage = () => setTimeLifeMessage(timeLifeMessage + 1)
	const minusTimeLifeMessage = () => setTimeLifeMessage(timeLifeMessage - 1)


	useEffect(()=>{
		console.log("form", form);
	},[form]);

	useEffect(()=>{
		if(langs){
			if(dataNotification) setdefaultLang(dataNotification.defaultLang)
			else setdefaultLang(langs[0].langIndex)
		}
		if(!dataNotification){
			langs?.forEach(el => {
				setForm(prev => {
					const newState = JSON.parse(JSON.stringify(prev))
					newState.body[`${el.langIndex}`] = ''
					return newState
				})
			})
		}
	},[groupsList, langs, dataNotification]);

	useEffect(() => {
		!checkDeliveryTime && setDate(null)
	}, [checkDeliveryTime])

	useEffect(() => {
		form?.typeNotification === 0 && formDateUpdateHandler({repeatCode: 0})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form?.typeNotification])

	const handleSubmit = async (values, {resetForm}) => {
		const audienceCustomGroupArr = [Number(form?.audienceCustom) || groupsList?.[0]?.id]
		let htmlID = false
		let error = false

		for(let message in form.body){
			if(!form.body[message].length){
				error = 'Будь ласка, заповнiть всi повiдомлення'
			}
		}
		
		if(error){
			setErrorMessage(error)
			return
		}

		const formDateBody = {body: {}}
		langs.forEach(el => {
			formDateBody.body[`${el.langIndex}`] = form.body?.[el.langIndex]
		});
		
		try {
			const {data, error} = await createHtmls(formDateBody)
			htmlID = data?.id
			setErrorMessage(error?.data?.message)
			!error && dispatch(createNewTerm({
				...formDateBody,
				id: data?.id
			}))
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}

		const formDate = {
			type: 1,
			title: htmlID,
			audienceCustom: audienceCustomGroupArr,
			audienceSystem: [],
			repeat: timeLifeMessage,
			start: date !== null ? date.getTime() : '',
			typeNotification: form?.typeNotification,
			repeatCode: form?.repeatCode,
			mark: [],
			notification_id: dataNotification?.id || false,
			isDoubleNotification: isDoubleNotification || false,
			sender_type: 'sms',
			defaultLang
		}
		
		try {
			const {data, error} = htmlID !== null && await createNotification(formDate)
			!error && dispatch(createNewNotification({
				...formDate,
				id: data?.id
			}))
			if(!error) navigate(`/notification?id=${data.id}`)
			else setErrorMessage(error?.data?.message)
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}
	}

	const clearFormHideModal = async () => {
		setIsDoubleNotification(false)
		setIsLoading(true)
		setForm({})
		toggleAddEditViberListPage()
	}

	return (
		<div className='terms1'>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<h1 className='terms-title d-flex align-items-center justify-content-between'>
				{(dataNotification && !isDoubleNotification)? t("ns_edit_sms") : null}
				{isDoubleNotification? t('ns_dublicate_sms') : null}
				{(!dataNotification ?? !isDoubleNotification)? t('ns_new_sms') : null}
				<button
					className='terms-wrapper_addButton rbac-wrapper_backButton mb-0'
					onClick={() => clearFormHideModal()}
				>
				<img
					src={CloseIcon}
					alt='close'
				/>
			</button >
			</h1 >
			<Formik
				validateOnChange
				initialValues={form}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					isValid,
					handleSubmit,
					dirty
				}) => (
					<Form
						className='add-edit-terms'
						onSubmit={handleSubmit}
					>
					<div className='terms-wrapper'>
						<div className='notification-email'>
							<div className='notification-email_content'>
								<h2 className='notification-email_content-title'>
								{t('ns_info_text')}
								</h2 >
								<SelectForm
									title={t('ns_address_book')}
									name={'audienceCustom'}
									arr={groupsList}
									formDateUpdateHandler={formDateUpdateHandler}
									refLink={selectAddressBookRef}
									defaultValue={dataNotification?.audienceCustom?.length? dataNotification.audienceCustom[0] : false}
								/>

								<div className='notification-email_content-inputs'>
									
									<div className='notification-email_content-textarea'>
									<h2 className='notification-email_content-title mt-4'>{t('message')}</h2 >
									{langs?.map((el, index) => {
										return(
											<div key={index} className={s.textarea}>
											<p>{`${t('message')} ${el.langIndex}`}</p>
											<textarea
												rows={10}
												defaultValue={dataNotification? form?.body[el.langIndex]:""}
												onChange={(e) => formDateUpdateBodyHandler({[`${el.langIndex}`]: e.target.value})}
											/>
											</div>
											
										)
									})}
								</div >
									<div className='notification-email_content-deliveryTime'>
										<div style={{margin: '20px 0px'}}>
											<h2 className='notification-email_content-title'>
												{t('default_language')}
											</h2 >
											<select
												style={{textTransform: 'uppercase'}}
												aria-label='Default select example'
												className='template-content_typeBlock-select notification-viber_counter-wrapper_select m-0'
												name='repeatCode'
												value={defaultLang}
												ref={repeatCodeRef}
												onChange={(e) => {setdefaultLang(e.target.value)}}
											>
												{langs?.map((el, index) => (<option key={index} style={{textTransform: 'uppercase'}} value={el.langIndex}>{el.langIndex}</option >))}											
											</select >
										</div>
										<h2 className='notification-email_content-title'>
											{t('ns_send_time')}
										</h2 >
										<Form.Group className='notification-email_content-deliveryTime_label'>
											<Form.Check
												type='radio'
												name='deliveryTime'
												id='deliveryTime'
												defaultChecked={dataNotification? false : true}
												className='notification-email_content-deliveryTime_radio'
												onKeyDown={e => {
													e.key === 'Enter' && e.preventDefault()
												}}
												onChange={() => {
													showDatePickerHandler(false)
													setCheckDeliveryTime(false)
												}}
											/>
											<label htmlFor='deliveryTime'>{t('ns_send_now')}</label >
										</Form.Group >
										<Form.Group className='notification-email_content-deliveryTime_label'>
											<Form.Check
												type='radio'
												name='deliveryTime'
												id='deliveryTime1'
												defaultChecked={dataNotification? true : false}
												className='notification-email_content-deliveryTime_radio'
												onKeyDown={e => {
													e.key === 'Enter' && e.preventDefault()
												}}
												onChange={() => {
													showDatePickerHandler(true)
													setCheckDeliveryTime(true)
												}}
											/>
											<label htmlFor='deliveryTime1'>{t('ns_send_delay')}</label >
										</Form.Group >
										{
											showDatePicker
											&&
											<div className='notification-email_content-deliveryTime_datepicekr'>
												<DatePicker
													selected={date}
													onChange={(date) => setDate(date)}
													showTimeSelect
													timeFormat='p'
													timeIntervals='15'
													dateFormat='Pp'
													isClearable
												/>
											</div >
										}
										<div className='notification-email_content-deliveryTime mb-4'>
										<h2 className='notification-email_content-title'>
											Тип розсилки
										</h2 >
										<Form.Group className='template-content_typeBlock-label w-100 mb-4'>
											<select
												aria-label='Default select example'
												className='template-content_typeBlock-select'
												name='typeNotification'
												defaultValue={form?.typeNotification}
												ref={typeNotification}
												onChange={(e) => formDateUpdateHandler({[e.target.name]: Number(e?.target.value)})}
											>
												<option value={'0'}>{t('ns_onetime')}</option >
												<option value={'1'}>{t('ns_cyclic')}</option >
											</select >
										</Form.Group >
											<div className='notification-viber_counter-wrapper'>
												{
													form?.typeNotification === 1
													&&
													<Form.Group className='template-content_typeBlock-label w-100'>
														<p className='mt-4'>
															{t('ns_options')}
														</p >
														<div className='notification-viber_counter'>
															<div className='notification-viber_counter-wrapper'>
																<select
																	aria-label='Default select example'
																	className='template-content_typeBlock-select notification-viber_counter-wrapper_select m-0'
																	name='repeatCode'
																	defaultValue={dataNotification?.repeatCode? dataNotification.repeatCode : "0"}
																	ref={repeatCodeRef}
																	onChange={(e) => formDateUpdateHandler(
																		{[e.target.name]: Number(e?.target.value)})}
																>
																		<option value={'0'}>{t('6hours')}</option >
																		<option value={'1'}>{t('12hours')}</option >
																		<option value={'2'}>{t('24hours')}</option >
																		<option value={'3'}>{t('48hours')}</option >
																		<option value={'4'}>{t('72hours')}</option >
																		<option value={'5'}>{t('5days')}</option >
																		<option value={'6'}>{t('6days')}</option >
																		<option value={'7'}>{t('7days')}</option >
																		<option value={'8'}>{t('2weeks')}</option >
																		<option value={'9'}>{t('1month')}</option >
																		<option value={'10'}>{t('3month')}</option >
																		<option value={'11'}>{t('half_year')}</option >
																		<option value={'12'}>{t('1year')}</option >
																</select >
																<div className='notification-viber_counter-wrapper_block ms-2'>
																	<button
																		type='button'
																		className='notification-viber_counter-wrapper_minus'
																		onClick={minusTimeLifeMessage}
																	>
																		-
																	</button >
																	<Form.Control
																		type='number'
																		name='repeat '
																		onBlur={handleBlur}
																		value={timeLifeMessage}
																		onKeyDown={e => {
																			e.key === 'Enter' && e.preventDefault()
																		}}
																		className={`notification-viber_counter-wrapper_input`}
																	/>
																	<button
																		type='button'
																		className='notification-viber_counter-wrapper_plus'
																		onClick={plusTimeLifeMessage}
																	>
																		+
																	</button >
																</div >
															</div >
														</div >
													</Form.Group >
												}
											</div >
										</div >
									</div >
								</div >
							</div >

							<div className='categories-create_block-buttons'>
								<button
									type='button'
									className='template-content_buttons-close btn btn-primary me-3'
									onClick={clearFormHideModal}
								>
									{t('cancel')}
								</button >
								<button
									type='submit'
									className='template-content_buttons-save btn btn-primary'
									onClick={handleSubmit}
								>
										{isLoading? <Loader/> : t('save')}
								</button >
							</div >
						</div >
					</div >
					</Form >
				)}
			</Formik >
		</div >
	)
}

export default NotificationSMSPage
