import EditPencil from "../../../utils/icons/edit-pencil.svg"
import DeleteIcon from "../../../utils/icons/delete-icon.svg"
import { useState } from "react"
import {
	useDispatch
} from "react-redux"
import { setDateChangeUnitPrice } from "../../store/slices/priceSlice"
import { setDateChangeUnitAmount } from "../../store/slices/amountSlice"
import {
	setDateChangeUnitTemplates,
	setDateChangeUnitTemplatesList
} from "../../store/slices/temlateContentSlice"

const VersionsTablItem = ( {
	setOpenEditModal,
	item,
	toggleDeleteModal,
	fromPage,
} ) => {
	const {
		id,
		editable,
		psevdo,
		title
	} = item
	const dispatch = useDispatch()
	const [ checkedUnitForPrice, setCheckedUnitForPrice ] = useState( false )
	const [ checkedUnitForAmount, setCheckedUnitForAmount ] = useState( false )
	const [ checkedUnitForTemplate, setCheckedUnitForTemplate ] = useState( false )

	const toggleCheckedUnit = ( value ) => {
		if ( fromPage === 'changeUnitForPrice' ) {
			setCheckedUnitForPrice( value )
		} else if ( fromPage === 'price' ) {
			setCheckedUnitForAmount( value )
		} else if ( fromPage === 'changeUnitForAmount' ) {
			setCheckedUnitForAmount( value )
		} else if ( fromPage === 'changeUnitForTemplates' ) {
			setCheckedUnitForTemplate( value )
		}
	}
	const saveChangeDateUnit = ( value ) => {
		if ( fromPage === 'changeUnitForPrice' ) {
			dispatch( setDateChangeUnitPrice( value ) )
		} else if ( fromPage === 'price' ) {
			dispatch( setDateChangeUnitPrice( value ) )
		} else if ( fromPage === 'changeUnitForAmount' ) {
			dispatch( setDateChangeUnitAmount( value ) )
		} else if ( fromPage === 'amount' ) {
			dispatch( setDateChangeUnitAmount( value ) )
		} else if ( fromPage === 'changeUnitForTemplates' ) {
			dispatch( setDateChangeUnitTemplates( value ) )
		} else if ( fromPage === 'changeUnitForTemplatesSelect' ) {
			dispatch( setDateChangeUnitTemplatesList( value ) )
		}
	}
	return (
		<tr >
			{
				fromPage === 'changeUnitForPrice' ||
				fromPage === 'changeUnitForAmount' ||
				fromPage === 'changeUnitForTemplates' ||
				fromPage === 'changeUnitForTemplatesSelect'
					?
					<>
						<td className='terms-wrapper_tabl-body_item col-1'>
							<div className='terms-wrapper_tabl-body_item-wrapper'>
								<input
									type={ fromPage === 'changeUnitForTemplatesSelect' ? 'checkbox' : 'radio' }
									defaultChecked={
										checkedUnitForPrice ||
										checkedUnitForAmount ||
										checkedUnitForTemplate
									}
									name='check'
									value={ id }
									id={ id }
									onKeyDown={ e => {
										e.key === 'Enter' && e.preventDefault()
									} }
									className='terms-wrapper_tabl-body_item-wrapper_input'
									onChange={ e => {
										toggleCheckedUnit( e.target.checked )
										saveChangeDateUnit( e.target.value )
									} }
								/>
								<label htmlFor={ id }>{ id }</label >
							</div >
						</td >
						<td className={ `terms-wrapper_tabl-body_item ${ fromPage === 'term' ? 'col-7' : 'col-10' }` }>
							<p >
								{
									title?.ua ||
									item?.term?.ua ||
									item?.tag?.ua ||
									item?.body?.ua ||
									item?.url
								}
							</p >
						</td >
					</>
					:
					<>
						<td className='terms-wrapper_tabl-body_item col-1'>
							<div className='terms-wrapper_tabl-body_item-wrapper'>
								<span >{ id }</span >
							</div >
						</td >
						<td
							className={ `terms-wrapper_tabl-body_item ${ (
								fromPage === 'term' ||
								fromPage === 'htmls' ||
								fromPage === 'pages'
							) ? 'col-7' : 'col-10' }` }
						>
							<p >
								{
									title?.ua ||
									item?.term?.ua ||
									item?.tag?.ua ||
									item?.name?.ua ||
									item?.body?.ua ||
									item?.url
								}
								{
									fromPage === 'price' ? item?.altValue?.ua ? ` (${ item?.altValue?.ua })` : '' : ``
								}
							</p >
						</td >
						{
							fromPage === 'term'
								?
								<>
									<td className='terms-wrapper_tabl-body_item col-2'>
										<div className='terms-wrapper_tabl-body_item-wrapper'>
											<span >{ editable }</span >
										</div >
									</td >
									<td className='terms-wrapper_tabl-body_item col-2'>
										<div className='terms-wrapper_tabl-body_item-wrapper'>
											<span >{ psevdo }</span >
										</div >
									</td >
								</>
								:
								<></>
						}
						{
							fromPage === 'htmls'
							&&
							<td className='terms-wrapper_tabl-body_item col-2 terms-wrapper_tabl-body_item-end'>
								<div className='terms-wrapper_tabl-body_item-wrapper'>
									<span >{ psevdo }</span >
								</div >
							</td >
						}
						{
							fromPage === 'pages'
							&&
							<td className='terms-wrapper_tabl-body_item col-2 terms-wrapper_tabl-body_item-end'>
								<div className='terms-wrapper_tabl-body_item-wrapper'>
									<span >{ item.screen }</span >
								</div >
							</td >
						}
						<td className='terms-wrapper_tabl-body_item col-2'>
							<div className='terms-wrapper_tabl-body_item-wrapper_icons'>
								<button
									type='button'
									onClick={ () => {
										saveChangeDateUnit( item?.unit )
										setOpenEditModal( item )
									} }
								>
									<img
										src={ EditPencil }
										alt='Edit Pencil'
									/>
								</button >
								<button
									type='button'
									onClick={ () => {
										toggleDeleteModal( item )
									} }
								>
									<img
										src={ DeleteIcon }
										alt='Delete'
									/>
								</button >
							</div >
						</td >
					</>
			}
		</tr >
	)
}

export default VersionsTablItem
