import React, { useState } from 'react';
import s from './referralStatistics.module.scss';
import CloseIcon from '../../../utils/icons/close-classik-icon-violet.svg'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { useGetReferenceQuery } from '../../store/apis/referralLinksApi';
import Loader from '../LoaderContent';
import { configureDashboardDate } from '../../../utils/helpers/configureDashboardDate';
import { getTimestamp24HoursAgo, getTimestampOneMonthAgo, getTimestampWeekAgo } from '../../../utils/helpers/TransformDate';
import PaginationCustom from '../PaginationCustom';
import SelectShowRecords from '../SelectShowRecords';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ReferralStatisticsModal = ({data, close})=>{

    const [timeRangeState, setTimeRangeState] = useState(0)
    const [dateRange, setDateRange] = useState({startDate: 0, endDate: new Date().getTime()})
    const [countShow, setCountShow] = useState(20);
	const [currentPage, setCurrentPage] = useState(1);
    const start_view = currentPage === 1? 0 : (currentPage * countShow - countShow)
    const [graphicData, setGraphicData] = useState(false)
    const {t} = useTranslation()

    const {data: reference, isLoading: isReferenceLoading} = useGetReferenceQuery({id: data.id, startDate: dateRange.startDate, endDate: dateRange.endDate})
    
    
    useEffect(()=>{
        if(reference) setGraphicData(configureDashboardDate({referal_click: reference?.data?.referal_click, referal_registration: reference?.data?.referal_registration}, dateRange, setDateRange))
    },[reference, dateRange]);
   
    function changeTimeRange(variant){
        setTimeRangeState(variant)
        if(variant === 0){
            setDateRange({startDate: 0, endDate: new Date().getTime()})
        }
        if(variant === 1){
            setDateRange({startDate: getTimestamp24HoursAgo(), endDate: new Date().getTime()})
        }
        if(variant === 2){
            setDateRange({startDate: getTimestampWeekAgo(), endDate: new Date().getTime()})
        }
        if(variant === 3){
            setDateRange({startDate: getTimestampOneMonthAgo(), endDate: new Date().getTime()})
        }
    }

    return(
        <div className={s.wrapper}>
            <div className={s.modal}>
                <div className={s.header}>
                    <p className={s.title}>{data.name}</p>
                    <button
                        className='terms-wrapper_addButton rbac-wrapper_backButton mb-0'
                        onClick={close}
                    >
                    <img
                        src={CloseIcon}
                        alt='close'
                    />
                    </button >
                </div>
                
                <div className={s.timerange_wrapper}>
                    <div className={`${s.timerange} ${timeRangeState === 0? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(0)}>
                            {t('AllTime')}
                    </div>
                    <div className={`${s.timerange} ${timeRangeState === 1? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(1)}>
                            {t('24hours')}
                    </div>
                    <div className={`${s.timerange} ${timeRangeState === 2? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(2)}>
                            {t('week')}
                    </div>
                    <div className={`${s.timerange} ${timeRangeState === 3? s.timerange_active:''}`}
                        onClick={()=>changeTimeRange(3)}>
                            {t('month')}
                    </div>
                </div>
                {isReferenceLoading && <Loader/>}
                <div className={`statistic-analytics ${s.statistics}`}>
                    <div className={`statistic-analytics_graph_wrapper ${s.graph}`}>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={graphicData}
                            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                                    <XAxis dataKey="date"/>
                                    <CartesianGrid/>
                                    <Line type="linear" name={t('_m_regToLink')} dataKey='referal_registration' stroke="green" strokeWidth={3} strokeOpacity={1} dot={false} style={{filter: `drop-shadow(0px 0px 4px green)`}}/>
                                    <Line type="linear" name={t('_m_linkClicks')} dataKey='referal_click' stroke="#FF6600" strokeWidth={3} strokeOpacity={1} dot={false} style={{filter: `drop-shadow(0px 0px 4px #FF6600)`}}/>
                                    <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div >

                <div className={s.statistic_table}>
                    <div className={s.tabs_wrapper}>
                        
                    </div>
                    <div className={s.pagination}>
                        {
                            graphicData &&
                            <PaginationCustom
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                itemsCount={countShow}
                                counterAllItems={graphicData?.filter(el => el.referal_click || el.referal_registration).length}
                            />}
                        <SelectShowRecords setCountShow={setCountShow} />
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <td>Дата</td>
                                <td>Кількість переходів</td>
                                <td>Кількість реєстрацій</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                graphicData &&
                                graphicData?.filter(el => el.referal_click || el.referal_registration)
                                .map((el, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{el.date}</td>
                                            <td>{el.referal_click}</td>
                                            <td>{el.referal_registration}</td>
                                        </tr>
                                    )
                                }).slice(start_view, start_view + countShow).reverse()
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}