import {
	Button,
	Form,
	Modal
} from "react-bootstrap"
import { Formik } from "formik"
import React, {
	useCallback,
	useEffect,
	useState
} from "react"
import Loader from "../Loader"
import {
	useDispatch,
	useSelector
} from "react-redux"
import arrowDownIcon from '../../../utils/icons/arrow-full-down.svg'
import {
	createNewPriceItem,
	editPriceItem,
	resetDateChangeUnitPrice,
	showChangeUnitPriceModal, showModalSubChangeUnitPrice
} from "../../store/slices/priceSlice"
import ChangeUnitsForPrice from "../../pages/Prices/ChangeUnitsForPrice"
import {
	useCreatePriceMutation,
	useEditPriceMutation,
	useGetPriceMutation
} from "../../store/apis/priceApi"
import AlertModal from "../AlertModal";
import { setUnitsMeasurementList } from "../../store/slices/unitsMeasurementSlice";
import {
	ARE_COMMON
} from "../../../utils/textsContent";
import { TextFieldsBlock } from "../environmentTabl/TextFieldsBlock/TextFieldsBlock"
import { useTranslation } from "react-i18next"

const AddEditPriceItem = ({
	show,
	onHide,
	edit,
	editDateForModal,
	showModalChangeUnitPrice,
	trigger
}) => {
	const [form, setForm] = useState({})
	const {dateModalChangeUnitPrice} = useSelector((state) => state.priceStore)
	const {unitsMeasurement} = useSelector((state) => state.unitsMeasurementStore)
	const [createPrice, {isLoading: isCreatePriceLoader}] = useCreatePriceMutation()
	const [editPrice, {isLoading: iseEditPriceLoader}] = useEditPriceMutation()
	const [getPrice, {isLoading: iseGetPriceLoader}] = useGetPriceMutation()
	const [valueChangeUnitForPrice, setValueChangeUnitForPrice] = useState(null)
	const [valueChangeUnitForTitle, setValueChangeUnitForTitle] = useState(null)
	const [anchorChangeUnit, setAnchorChangeUnit] = useState(null)
	const [errorMessage, setErrorMessage] = useState(false)
	const dispatch = useDispatch()
	const isLoading = isCreatePriceLoader || iseEditPriceLoader || iseGetPriceLoader
	const langs = useSelector(store => store.langStore.langs)
	const unitlist = useSelector(store => store.unitsMeasurementStore.unitsMeasurement)
	const {t} = useTranslation()
	
	const getAllUnitsMeasurement = useCallback(async () => {
		try {
			await getUnitList()
		} catch (e) {
			console.log(e)
		}
	},
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[])

	useEffect(()=>{
		setAnchorChangeUnit(null)
		const tags = {}
		langs?.forEach(el => {
			tags[`${el.langIndex}`] = ""
		})
		setForm({...form, tag: tags})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[show]);

	useEffect(() => {
		getAllUnitsMeasurement()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getUnitList = async () => {
		dispatch(setUnitsMeasurementList(unitlist))
	}

	useEffect(() => {
		const data = unitsMeasurement.find(item => item.id === Number(editDateForModal?.unit))
		setValueChangeUnitForPrice(data)
		const dataV = unitsMeasurement.find(item => item.id === Number(editDateForModal?.altValue))
		setValueChangeUnitForTitle(dataV)
	},
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[edit, editDateForModal])

	useEffect(() => {
		if (trigger) {
			if ((
				anchorChangeUnit === 'unit'
			)) {
				const data = unitsMeasurement.find(item => item.id === Number(dateModalChangeUnitPrice))
				setValueChangeUnitForPrice(data)
			}
			if ((
				anchorChangeUnit === 'altValue'
			)) {
				const data = unitsMeasurement.find(item => item.id === Number(dateModalChangeUnitPrice))
				setValueChangeUnitForTitle(data)
			}
		}
	},
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[dateModalChangeUnitPrice, trigger])

	useEffect(()=>{
	},[dateModalChangeUnitPrice]);
	const openModalChangeUnitForPrice = () => {
		console.log("trigger", trigger);
		if (trigger === true) {
			dispatch(showChangeUnitPriceModal(true))
		}
		else dispatch(showModalSubChangeUnitPrice(true))
	}

	useEffect(() => {
		edit && setForm(editDateForModal)
	}, [edit, editDateForModal])

	const formDateUpdateHandler = (opt) => {
		setForm({...form, ...opt})
	}

	const handleSubmit = async (values) => {
		let error = false

		// ### VALIDATION

		if(!Object.entries(form.tag) || Object.entries(form.tag)?.length < langs.length){
			error = 'Please fill in the "Name"'
		}
		
		if(!!form.disableTitle && Object.entries(form.disableTitle)?.length < langs.length){
			error = 'Please fill in the "DisableTitle"'
		}

		if(!!form.activeTitle && Object.entries(form.activeTitle)?.length < langs.length){
			error = 'Please fill in the "ActiveTitle"'
		}

		if(error){
            setErrorMessage(error)
            return
        }

		const tags = {}
		const activeTitle = {}
		const disableTitle = {}

		for(let key in form.tag){
			if(key !== "id") tags[key] = form.tag[key]
		}
		for(let key in form.activeTitle){
			if(key !== "id") activeTitle[key] = form.activeTitle[key]
		}
		for(let key in form.disableTitle){
			if(key !== "id") disableTitle[key] = form.disableTitle[key]
		}

		const formDate = {
			tag: tags || undefined,
			activeTitle: activeTitle || undefined,
			disableTitle: disableTitle || undefined,
			precision: Number(form.precision) || 2,
			isnum: true,
			unit: Number(valueChangeUnitForPrice?.id),
			altValue: Number(valueChangeUnitForTitle?.id),
			required: values?.requiredStatus
		}
		try {
			if (edit) {
				const {error} = await editPrice({
					...formDate,
					id: editDateForModal.id
				})
				getPrice()
				if(!error){
					dispatch(editPriceItem({
						...formDate,
						id: editDateForModal.id
					}))
				}
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()
			} else {
				const {
					data,
					error
				} = await createPrice(formDate)
				!error && dispatch(createNewPriceItem({
					...formDate,
					id: data?.id
				}))
				getPrice()
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()
			}
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}
	}

	const clearFormHideModal = () => {
		onHide()
		setForm({})
		setValueChangeUnitForPrice(null)
		setValueChangeUnitForTitle(null)
		dispatch(resetDateChangeUnitPrice())
	}

	
	return (
		<Modal
			show={show}
			onHide={clearFormHideModal}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			className='add-edit-terms_modal'
			style={{zIndex: '99999'}}
		>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			{
				showModalChangeUnitPrice && <ChangeUnitsForPrice />
			}
			<Formik
				initialValues={{
					altValue: form?.altValue?.ua || valueChangeUnitForTitle?.id,
					precision: form?.precision || 2,
					unit: form?.unit || valueChangeUnitForPrice?.id,
					requiredStatus: form?.requiredStatus || false
				}}
				onSubmit={handleSubmit}
			>
				{
					({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty
					}) => (
						<>
							<Modal.Header
								closeButton
							>
								<Modal.Title
									id='contained-modal-title-vcenter'
									className='add-edit-terms_title'
								>
									{edit ? t('cp_edit_title') : t('cp_create_title')}
								</Modal.Title >
							</Modal.Header >
							<Modal.Body >
								<Form
									className='add-edit-terms'
									onSubmit={handleSubmit}
								>
									<div style={{marginBottom: 20, borderBottom: '1px #CCC solid', width: '100%', padding: 20}}>
										<TextFieldsBlock title="Name" 
											values={form.tag} 
											changeValue={(value)=>{setForm(prev =>{
												return {...prev, tag: {...prev.tag, ...value}}
											})}}
										/>
									</div>

									<div style={{marginBottom: 20, borderBottom: '1px #CCC solid', width: '100%', padding: 20}}>
										<TextFieldsBlock title="ActiveTitle" 
											values={form.activeTitle} 
											changeValue={(value)=>{setForm(prev =>{
												return {...prev, activeTitle: {...prev.activeTitle, ...value}}
											})}}
										/>
									</div>

									<div style={{marginBottom: 20, borderBottom: '1px #CCC solid', width: '100%', padding: 20}}>
										<TextFieldsBlock title="DisableTitle" 
											values={form.disableTitle} 
											changeValue={(value)=>{setForm(prev =>{
												return {...prev, disableTitle: {...prev.disableTitle, ...value}}
											})}}
										/>
									</div>
									
									<div className='add-edit-terms_block'>
										<Form.Group className='add-edit-terms_center mb-0'>
											Valute
											<button
												type='button'
												className={`add-edit-terms_block-dropdown ${touched?.unit ? "is-touch " : ""} ${
													errors?.unit && touched?.unit ? " is-invalid" : ""
												}`}
												onBlur={handleBlur}
												onClick={() => {
													openModalChangeUnitForPrice()
													setAnchorChangeUnit('unit')
												}}
											>
												{
													valueChangeUnitForPrice ? valueChangeUnitForPrice?.term?.ua : 'Оберіть одиницю валюти'
												}
												<img
													src={arrowDownIcon}
													alt='arrow down'
												/>
											</button >
											{errors.unit && touched.unit && (
												<Form.Control.Feedback type='invalid'>
													{errors.unit}
												</Form.Control.Feedback >
											)}
										</Form.Group >
									</div >
									<div className='add-edit-terms_block'>
										<Form.Group className='add-edit-terms_center mb-0'>
											Unit
											<button
												type='button'
												className={`add-edit-terms_block-dropdown ${touched?.altValue ? "is-touch " : ""} ${
													errors?.altValue && touched?.altValue ? " is-invalid" : ""
												}`}
												onBlur={handleBlur}
												onClick={() => {
													openModalChangeUnitForPrice()
													setAnchorChangeUnit('altValue')
												}}
											>
												{
													(valueChangeUnitForTitle ? valueChangeUnitForTitle?.term?.ua : !form?.altValue?.ua ? `Оберіть oдиницю виміру` : form?.altValue?.ua)
												}
												<img
													src={arrowDownIcon}
													alt='arrow down'
												/>
											</button >
											{errors.altValue && touched.altValue && (
												<Form.Control.Feedback type='invalid'>
													{errors.altValue}
												</Form.Control.Feedback >
											)}
										</Form.Group >
									</div >
									<Form.Group
										className='add-edit-terms_center'
									>
										Value after the decimal
										<Form.Control
											type='number'
											name='precision'
											onBlur={handleBlur}
											onKeyDown={e => {
												e.key === 'Enter' && e.preventDefault()
											}}
											className={`add-edit-terms_center-inp ${touched?.precision ? "is-touch " : ""} ${
												errors?.precision && touched?.precision ? " is-invalid" : ""
											}\``}
											value={form.precision}
											placeholder='2'
											onChange={(e) => {
												handleChange(e)
												formDateUpdateHandler({
													[e.target.name]: e.target.value
												})
											}}
										/>
										{errors.precision && touched.precision && (
											<Form.Control.Feedback type='invalid'>
												{errors.precision}
											</Form.Control.Feedback >
										)}
									</Form.Group >
									<Form.Check
										className='noticeItem-wrapper_status-moderation_item-body_block-checkbox p-0'
										type='checkbox'
										label={"Required"}
										id={'requiredStatus'}
										onKeyDown={e => {
											e.key === 'Enter' && e.preventDefault()
										}}
										defaultChecked={form?.required}
										name={'requiredStatus'}
										onChange={e => {
											handleChange(e)
											formDateUpdateHandler({
												[e.target.name]: e.target.checked
											})
										}}
									/>
								</Form >
							</Modal.Body >
							<Modal.Footer >
								<Button
									type='button'
									className='add-edit-terms_close'
									onClick={clearFormHideModal}
								>
									{t('cancel')}
								</Button >
								<Button
									type='submit'
									className='add-edit-terms_save d-inline-flex'
									style={{
										maxHeight: '33px',
										display: 'flex',
										alignItems: 'center'
									}}
									disabled={(
										!isValid && dirty
									)}
									onClick={handleSubmit}
								>
									{
										isLoading ? <Loader /> : t('save')
									}
								</Button >
							</Modal.Footer >
						</>
					)
				}
			</Formik >
		</Modal >
	)
}

export default AddEditPriceItem
