import React, { useState } from "react"
import { PAGES_NAME } from "../../../utils/textsContent"
import { useSelector } from "react-redux"
import AlertModal from "../../components/AlertModal"
import ControlPanelsForModerationPayment from "../../components/environmentTabl/ControlPanelsForModerationPayment"
import { useTranslation } from "react-i18next"

const TransactionsPage = () => {
	const { moderationPayment, amount } = useSelector( state => state.moderationPaymentStore )

	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )
	const [ errorMessage, setErrorMessage ] = useState( '' )
	let isTransactionsPage = true
	const {t} = useTranslation()

	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<h1 className='terms-title'>
				{t('s_trans')}
			</h1 >
			<ControlPanelsForModerationPayment
				bigArrElements={ errorMessage === '' ? moderationPayment : [] }
				fromPage={ 'users' }
				toggleModalSearch={ toggleModalSearch }
				showModalSearch={ showModalSearch }
				showSearchBeforeDel={ showSearchBeforeDel }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				setErrorMessage={ setErrorMessage }
				isTransactionsPage={isTransactionsPage}
				amount={amount}
			/>
		</div >
	)
}

export default TransactionsPage