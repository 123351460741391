import React, { useEffect, useRef } from 'react';
import s from './middleCategoryDropdownList.module.scss'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const CategoryDropdownList = ({category, subcategories, currentCategory, setCurrentCategory, templateType, form, edit})=>{
	return(
		<div>
			<Category category={category} subcategories={subcategories} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory} templateType={templateType} subcategory={form?.subcategory} edit={edit} form={form}/>
		</div>
	)
}

const Category = ({category, subcategories, currentCategory, setCurrentCategory, templateType, subcategory, edit, form}) => {
	const {i18n} = useTranslation()
	const categoriesRef = useRef([])
	useEffect(()=>{
		if(subcategory && subcategory.length && subcategory.length > 1 && edit){
			let parent = false
			subcategory.forEach((el) => {
				if(el.id === category.id){
					enterCategory(el.id)
					parent = subcategories.find(item => item.id === el.id)?.category
				}
			})
			if(parent){
				const element = document.getElementById(`category_${parent}`)
				if(element){
					const currentHeight = element
					element.style.cssText = `height : ${currentHeight}px`
					setTimeout(()=>{
						element.style.cssText = `height : auto`
					},500)
				}
			}
		} else if(subcategory && subcategory.length === 1){
			const parent = subcategories.find(item => item.id === subcategory[0]?.id)?.category
			if(parent){
				const element = document.getElementById(`category_${parent}`)
				const currentHeight = element
				element.style.cssText = `height : ${currentHeight}px`
				setTimeout(()=>{
					element.style.cssText = `height : auto`
				},500)
			}
		}
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[subcategory]);

	const children = subcategories.filter(cat => cat.parent === category.id)

	function enterCategory(id){
		const currentHeight = categoriesRef.current[id]?.scrollHeight
		if(categoriesRef.current[id]){
			if(categoriesRef.current[id].offsetHeight){
				categoriesRef.current[id].style.cssText = `height : ${categoriesRef.current[id].offsetHeight}px`
				setTimeout(() => {
					categoriesRef.current[id].style.cssText = `height : 0px`
				},10)
			}
			else{
				if(categoriesRef.current[id]){
					categoriesRef.current[id].style.cssText = `height : ${currentHeight}px`
					setTimeout(()=>{
						categoriesRef.current[id].style.cssText = `height : auto`
					},500)
				}
			}
		}
	}
	
	return(
		<div className={s.category_item}>
			<div className={s.header}>
				{
					templateType === "middle" ?
						<Form.Check
							type='checkbox'
							id={category.id}
							checked={currentCategory === category.id}
							onChange={()=>setCurrentCategory(category.id)}
						/>
					: null
				}
				<span className={s.title} onClick={()=>enterCategory(category.id)}>{category?.name?.[i18n.language] || category?.title?.[i18n.language]}</span>
				<span className={s.number} onClick={()=>enterCategory(category.id)}>({children.length})</span>
			</div>
			<div className={s.subcategories_wrapper} id={`category_${category.id}`} ref={(el) => categoriesRef.current[category.id] = el}>
				{
					children?.map((el, index) => {
						const children = subcategories.filter(cat => cat.parent === el.id)
						if(children.length) return <Category category={el} subcategories={subcategories} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory} templateType={templateType} subcategory={subcategory} edit={edit} form={form}/>
						else return <SubCategory key={index} subcategory={el} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory} templateType={templateType} editSubcategory={subcategory} edit={edit}/>
						
					})
				}
			</div>
		</div>
	)
}

const SubCategory = ({subcategory, currentCategory, setCurrentCategory, templateType, editSubcategory, edit}) => {
	const {i18n} = useTranslation()
	useEffect(()=>{
		if(editSubcategory && editSubcategory.length && edit){
			editSubcategory.forEach((el) => {
				   if(el.id === subcategory.id){
					setCurrentCategory(el.id)
				   }
			   })
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[editSubcategory]);

	return(
		<div className={s.subcategory_wrapper}>
			
				<div className={s.subcategory_header}>
				{
					templateType === "main" ?
					<Form.Check
						type='checkbox'
						id={subcategory.id}
						checked={currentCategory === subcategory.id}
						onChange={()=>{setCurrentCategory(subcategory.id)}}
					/>
					: null
				}
					<span className={s.subcategory_title}>{subcategory?.title?.[i18n.language]}</span>
				</div>
			
		</div>
	)
}