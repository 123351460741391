import React, {
	useState
} from 'react'
import ControlPanelForRBAC from "../../components/environmentTabl/ControlPanelForRBAC"
import AlertModal from "../../components/AlertModal"
import AddEditRbacItemModal from "../../components/modalsAddEdit/AddEditRbacItemModal"
import { useNavigate } from "react-router-dom";
import { APP_ROUTE_PAGES } from "../../../utils/constants"
import { RBACRoleEditModal } from '../../components/rbacRoleEditModal/rbacRoleEditModal';
import { useGetAllPermissionsQuery } from '../../store/apis/usersApi';
import { useTranslation } from 'react-i18next';

const RbacPage = () => {

	const {data: allPermissions} = useGetAllPermissionsQuery()

	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ roleModal, setRoleModal ] = useState( false )
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const [ currentUser, setCurrentUser ] = useState(false)

	const navigate = useNavigate()

	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
	}

	const navigateRbacAdminInfoPage = ( item ) => {
		navigate( `${ APP_ROUTE_PAGES.RBAC }/${ item }`, {
			state: {}
		} )
	}

	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			{
				addEditModalShow || currentUser?
				<AddEditRbacItemModal
					show={ addEditModalShow || currentUser }
					onHide={ showModalHandler }
					userData={currentUser}
					setCurrentUser={setCurrentUser}
				/> : null
			}
			<h1 className='terms-title'>
				RBAC
			</h1 >
			<ControlPanelForRBAC
				showModalHandler={ showModalHandler }
				navigateRbacAdminInfoPage={ navigateRbacAdminInfoPage }
				setCurrentUser={setCurrentUser}
				setRoleModal={setRoleModal}
			/>
			{roleModal && <RBACRoleEditModal allPermissions={allPermissions} hide={setRoleModal} roleData={roleModal}/>}
		</div >
	)
}

export default RbacPage
