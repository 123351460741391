import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	HTMLS_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const htmlsApi = createApi({
	reducerPath: "htmlsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getHtmls: builder.mutation({
			query: () => (
				{
					url: HTMLS_API.GET_HTMLS,
					method: "GET",
				})
		}),
		createHtmls: builder.mutation({
			query: (body) => (
				{
					url: HTMLS_API.CREATE_HTMLS,
					method: "POST",
					body
				})
		}),
		editHtmls: builder.mutation({
			query: (body) => (
				{
					url: HTMLS_API.EDIT_HTMLS,
					method: "POST",
					body
				})
		}),
		deleteHtmls: builder.mutation({
			query: (body) => (
				{
					url: HTMLS_API.DELETE_HTMLS,
					method: "POST",
					body
				})
		}),
		migrationHtmls: builder.mutation({
			query: (body) => (
				{
					url: HTMLS_API.MIGRATION_HTMLS,
					method: "POST",
					body
				})
		}),
	})
});

export const {
	useGetHtmlsMutation,
	useCreateHtmlsMutation,
	useEditHtmlsMutation,
	useDeleteHtmlsMutation,
	useMigrationHtmlsMutation
} = htmlsApi
