import {
	useEffect,
	useMemo,
	useState
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { transformDate } from "../../../utils/helpers/TransformTime"
import { statusUsersSwitchHelper, verificationSwitchHelper } from "../../../utils/helpers/VerificationAndStatusSwitch"
import { useNavigate } from "react-router-dom"
import { APP_ROUTE_PAGES } from "../../../utils/constants"
import {
	removeDateChangeUsersForAddressBookArr,
	setDateChangeUserAdvertising,
	setDateChangeUsersForAddressBookArr
} from '../../store/slices/advertisingSlice'
import VerificationStatus from '../../../utils/icons/verification-icon.svg'
import NotVerificationStatus from '../../../utils/icons/not-verification-icon.svg'
import ModerationVerificationStatus from '../../../utils/icons/on-moderation-user-status.svg'
import ActiveUserStatus from '../../../utils/icons/user-active-icon.svg'
import ActiveOnRegistrationStatus from '../../../utils/icons/user-on-registr-icon.svg'
import BlockStatus from '../../../utils/icons/user-block-icon.svg'
import OnRemoveStatus from '../../../utils/icons/user-on-delete-icon.svg'
import RemoveStatus from '../../../utils/icons/user-delete-icon.svg'
import { ARE_COMMON } from "../../../utils/textsContent"

const UsersTablItem = ({item, fromPage, isActive}) => {
	const dispatch = useDispatch()
	const navigation = useNavigate()
	const [convertDate, setConvertDate] = useState(null)
	const [verificationSwitch, setVerificationSwitch] = useState(null)
	const [statusSwitch, setStatusSwitch] = useState(null)
	const [isChecked, setIsChecked] = useState(false)
	
	const {
		dateModalChangeUsersForAddressBook,
		dateModalChangeUserAdvertising
	} = useSelector((state) => state.advertisingStore)

	useEffect(() => {
		if (fromPage === 'changeUserForAdvertising') {
			if (dateModalChangeUserAdvertising?.id === item?.id) {
				setIsChecked(true)
			} else {
				setIsChecked(false)
			}
		} else {
			if (dateModalChangeUsersForAddressBook.filter(el => el?.id === item?.id)?.length) {
				setIsChecked(true)
			} else {
				setIsChecked(false)
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateModalChangeUsersForAddressBook, dateModalChangeUserAdvertising])

	useEffect(() => {
		setConvertDate(transformDate(item?.dateRegiter))
		setVerificationSwitch(verificationSwitchHelper(item?.verification))
		setStatusSwitch(statusUsersSwitchHelper(item?.status))
	}, [item])

	const saveChangeIdUser = (id, action) => {
		if (fromPage === 'changeUserForAdvertising') {
				dispatch(setDateChangeUserAdvertising(id))
		}
		if (fromPage === 'changeUsersListForAddressBook') {
			if (action) {
				dispatch(setDateChangeUsersForAddressBookArr(id))
			} else {
				dispatch(removeDateChangeUsersForAddressBookArr(id?.id))
			}
		}
	}

	const statusResult = useMemo(() => {
		if (item?.status === 1) {
			return <img
				src={ActiveUserStatus}
				alt='Active'
				title={` ${item?.pib ? item?.pib : ''} Active`}
			/>
		}
		if (item?.status === 0) {
			return <img
				src={ActiveOnRegistrationStatus}
				alt='on regirst'
				title={`Користувач ${item?.pib ? item?.pib : ''} Pending registration`}
			/>
		}
		if (item?.status === 4) {
			return <img
				src={BlockStatus}
				alt='block'
				title={`Користувач ${item?.pib ? item?.pib : ''} Blocked`}
			/>
		}
		if (item?.status === 3) {
			return <img
				src={OnRemoveStatus}
				alt='remove'
				title={`Користувач ${item?.pib ? item?.pib : ''} Pending deletion`}
			/>
		}
		if (item?.status === 2) {
			return <img
				src={RemoveStatus}
				alt='remove'
				title={`Користувач ${item?.pib ? item?.pib : ''} Deleted`}
			/>
		}
		return
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statusSwitch?.title])

	return (
		<tr >
			<td className='users-wrapper_tabl-body_item col-1 id-size'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					{
						fromPage !== 'users'
						&&
						<>
							<input
								type={fromPage === 'changeUsersListForAddressBook' ? 'checkbox' : 'radio'}
								name='check'
								value={item?.id}
								checked={isChecked}
								disabled={!isActive}
								id={item?.id}
								onKeyDown={e => {
									e.key === 'Enter' && e.preventDefault()
								}}
								onChange={(ev) => saveChangeIdUser(item, ev.target.checked)}
								className='users-wrapper_tabl-body_item-wrapper_input'
							/>
						</>
					}
					<label htmlFor={item?.id}>{item?.id}</label >
				</div >
			</td >
			<td className={`users-wrapper_tabl-body_item col-1 login-size`}>
				<p
					title={item?.pib}
					onClick={() => navigation(`${APP_ROUTE_PAGES.USER}?id=${item?.id}`, {state: item})}
				>{item?.pib}</p >
			</td >
			<td
				className='users-wrapper_tabl-body_item col-1 login-size'
			>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span title={item?.email}>{item?.email}</span >
				</div >
			</td >
			<td
				className='users-wrapper_tabl-body_item col-1 login-size'
			>
				<div className='users-wrapper_tabl-body_item-wrapper '>
					<span title={item?.login}>{item?.login}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1 phone-size'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span title={item?.phone}>{item?.phone}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1 id-size'>
				<div className='users-wrapper_tabl-body_item-wrapper text-center'>
					<span
						className='w-100'
						title={item?.adverts}
					>{item?.adverts}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1 id-size'>
				<div className='users-wrapper_tabl-body_item-wrapper text-center'>
					<span
						className='w-100'
						title={item?.deals}
					>{item?.deals}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1 phone-size'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{convertDate}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1 id-size'>
				<div
					className='users-wrapper_tabl-body_item-wrapper justify-content-center'
				>
					{/*<span*/}
					{/*	style={ {*/}
					{/*		backgroundColor: `${ statusSwitch?.bg }`,*/}
					{/*		color: `${ statusSwitch?.color }`*/}
					{/*	} }*/}
					{/*>*/}
					{/*	{ statusSwitch?.title }*/}
					{/*</span >*/}
					{
						statusResult
					}
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1 id-size'>
				<div className='users-wrapper_tabl-body_item-wrapper justify-content-center'>
					{
						item.verification?.docs === false ? 
							<img
								src={NotVerificationStatus}
								alt='not verification'
								title={`${item?.pib ? item?.pib : ''} Pending verification`}
							/> : 
							item.verification?.docs === true ? 
							<img
								src={VerificationStatus}
								alt='verification'
								title={`${item?.pib ? item?.pib : ''} Verified`}
							/> :
							<img
								src={ModerationVerificationStatus}
								alt='not verification'
								title={`${item?.pib ? item?.pib : ''} Unverified`}
							/> 
					}
				</div >
			</td >
		</tr >
	)
}

export default UsersTablItem
