import {
	Button,
	Form,
	Modal
} from "react-bootstrap"
import { Formik } from "formik"
import React, {
	useEffect,
	useState
} from "react"
import Loader from "../Loader"
import {
	useDispatch,
	useSelector
} from "react-redux"
import arrowDownIcon from '../../../utils/icons/arrow-full-down.svg'
import AlertModal from "../AlertModal"
import {
	useGetMarkMutation,
} from "../../store/apis/markApi"
import {
	createNewModelItem,
	editModelItem,
	resetDateChangeMarkForModel,
	setDateChangeMarkForModel,
	showChangeMarkForModelModel
} from "../../store/slices/modelSlice"
import ChangeMarkForModel from "../../pages/Model/ChangeMarkForModel"
import {
	useCreateModelMutation,
	useEditModelMutation
} from "../../store/apis/modelApi"
import { ARE_COMMON } from "../../../utils/textsContent";

const initialState = {
	name: ''
}

const AddEditModelItem = ( {
	show,
	onHide,
	edit,
	editDateForModal
} ) => {
	const [ form, setForm ] = useState( edit ? editDateForModal : initialState )
	const { dateModalChangeMarkForModel, showModalChangeMarkForModel } = useSelector( ( state ) => state.modelStore )
	const { mark, showModalChangeMark } = useSelector( state => state.markStore )
	const [ valueChangeSubcategoryForMark, setValueChangeSubcategoryForMark ] = useState( null )
	const [ createModel, { isLoading: isCreateModelLoader } ] = useCreateModelMutation()
	const [ editModel, { isLoading: iseEditModelLoader } ] = useEditModelMutation()
	const [ getMark, { isLoading: isGetMarkLoading } ] = useGetMarkMutation()
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const dispatch = useDispatch()

	const isLoading = isCreateModelLoader || iseEditModelLoader ||isGetMarkLoading

	useEffect( () => {
		const getMarkList = async () => {
			await getMark()
		}
		edit && getMarkList()
	}, [edit, editDateForModal?.mark] )

	const openModalChangeSubcategoryForMark = () => {
		dispatch(showChangeMarkForModelModel(true))
	}

	const formDateUpdateHandler = ( opt ) => {
		setForm( { ...form, ...opt } )
	}

	useEffect( () => {
		setForm(editDateForModal)
		edit && dispatch(setDateChangeMarkForModel(editDateForModal?.mark))
	}, [edit, editDateForModal] )

	useEffect( () => {
		const data = mark?.find( item => item.id === Number( dateModalChangeMarkForModel ) )
		setValueChangeSubcategoryForMark( data )
	}, [ dateModalChangeMarkForModel, edit, editDateForModal?.mark, mark ] )

	const handleSubmit = async ( values ) => {
		const formDate = {
			name: form?.name || values?.name,
			mark: Number(dateModalChangeMarkForModel)
		}
		try {
			if ( edit ) {
				const {error} = await editModel({
					...formDate,
					id: editDateForModal.id
				})
				!error && dispatch(editModelItem({
					...formDate,
					id: editDateForModal.id
				}))
				error && setErrorMessage(error?.data?.mes)
				!error && await clearFormHideModal()
			} else {
				const {
					data,
					error
				} = await createModel( formDate )
				!error && dispatch( createNewModelItem( {
					...formDate,
					id: data?.id
				} ) )
				error && setErrorMessage( error?.data?.mes )
				!error && await clearFormHideModal()
			}
		} catch ( e ) {
			console.log( e )
			setErrorMessage( e )
		}
	}

	const clearFormHideModal = async () => {
		onHide()
		setForm( {} )
		setValueChangeSubcategoryForMark(null)
		await dispatch( resetDateChangeMarkForModel() )
	}

	return (
		<Modal
			show={ show }
			onHide={ clearFormHideModal }
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			className='add-edit-terms_modal'
			style={ { zIndex: '999999' } }
		>
			{
				showModalChangeMarkForModel
				&&
				<ChangeMarkForModel fromTypeMark={ 'changeMarKForModalCreate' }/>
			}
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<Formik
				validateOnChange
				initialValues={ {
					name: form?.name || editDateForModal?.name
				} }
				onSubmit={ handleSubmit }
				enableReinitialize
			>
				{
					( {
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty
					} ) => (
						<>
							<Modal.Header closeButton>
								<Modal.Title
									id='contained-modal-title-vcenter'
									className='add-edit-terms_title'
								>
									{ edit ? `${ARE_COMMON?.EDITING} Model` : `${ARE_COMMON?.CREATED} Model` }
								</Modal.Title >
							</Modal.Header >
							<Modal.Body >
								<Form
									className='add-edit-terms'
									onSubmit={ handleSubmit }
								>
									<div className='add-edit-terms_top'>
										<Form.Group
											className='add-edit-terms_label w-100'
										>
											{ARE_COMMON?.NAME}
											<Form.Control
												type='text'
												name='name'
												onBlur={ handleBlur }
												defaultValue={ values.name }
												autoFocus
												onKeyDown={ e => {
													e.key === 'Enter' && e.preventDefault()
												} }
												className={ `add-edit-terms_center-inp  ${ touched?.name ? "is-touch " : "" } ${
													errors?.name && touched?.name ? " is-invalid" : ""
												} ` }
												onChange={ ( e ) => {
													handleChange( e )
													formDateUpdateHandler( {
														[e.target.name]: e.target.value
													} )
												} }
											/>
											{ errors.name && touched.name && (
												<Form.Control.Feedback type='invalid'>
													{ errors.name }
												</Form.Control.Feedback >
											) }
										</Form.Group >
									</div >
									<div className='add-edit-terms_block'>
										<Form.Group className='add-edit-terms_center'>
											Mark
											<button
												type='button'
												className='add-edit-terms_block-dropdown'
												onClick={ openModalChangeSubcategoryForMark }
											>
												{
													valueChangeSubcategoryForMark ? valueChangeSubcategoryForMark?.name : 'Оберіть mark'
												}
												<img
													src={ arrowDownIcon }
													alt='arrow down'
												/>
											</button >
										</Form.Group >
									</div >
								</Form >
							</Modal.Body >
							<Modal.Footer >
								<Button
									type='button'
									className='add-edit-terms_close'
									onClick={ clearFormHideModal }
								>
									{ARE_COMMON?.ABOLITION}
								</Button >
								<Button
									type='submit'
									className='add-edit-terms_save d-inline-flex'
									style={ {
										maxHeight: '33px',
										display: 'flex',
										alignItems: 'center'
									} }
									disabled={ (
										!isValid &&
										dirty ||
										form?.name === ( '' || undefined )
									) }
									onClick={ handleSubmit }
								>
									{
										isLoading ? <Loader /> : (
											edit ? ARE_COMMON?.SAVE : `${ARE_COMMON?.CREATED} Mark`
										)
									}
								</Button >
							</Modal.Footer >
						</>
					)
				}
			</Formik >
		</Modal >
	)
}

export default AddEditModelItem
