import React, {useState} from 'react'
import AlertModal from "../../components/AlertModal"
import { useSelector } from 'react-redux'
import ControlPanelsV2 from '../../components/environmentTabl/ControlPanelsV2'
import { AddEditLanguageItem } from '../../components/modalsAddEdit/AddEdditLanguageItem'
import { ReactComponent as EditIcon } from '../../../utils/icons/edit-pencil.svg'
import { ReactComponent as DeleteIcon } from '../../../utils/icons/delete-icon.svg'
import DeleteModal from '../../components/DeleteModal'
import { useTranslation } from 'react-i18next'

const LanguagesPage = () => {

	// ### State

	const [ errorMessage, setErrorMessage ] = useState( '' )
	const [showAddModal, setShowAddModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const langs = useSelector(store => store.langStore.langs)
	const {t} = useTranslation()
	
	const table_columns = ["ID", "Назва", "Iндекс", "Статус", ""]

	const table_rows = langs.map(el => {
		return [
			{type: "default", value: el.id},
			{type: "default", value: el.lang},
			{type: "default", value: el.langIndex},
			{type: "status", value: el.active},
			{type: "icons", value: [<EditIcon onClick={()=>setShowAddModal(el)}/>, <DeleteIcon onClick={()=>setShowDeleteModal(el.id)}/>]}
		]
	})
	
	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<DeleteModal
				show={ showDeleteModal }
				onHide={ setShowDeleteModal }
				value={ showDeleteModal }
				fromPage={ 'languages' }
			/>
			<>
				<h1 className='terms-title'>{t('s_langs')}</h1 >
				<ControlPanelsV2 rows={table_rows} columns={table_columns} setShowAddModal={setShowAddModal}/>
			</>
			{showAddModal ? <AddEditLanguageItem setShowAddModal={setShowAddModal} formData={showAddModal?.id? showAddModal : false}/> : null}
		</div >
	)
}

export default LanguagesPage
