import {createSlice} from "@reduxjs/toolkit"
import { deliveryApi } from "../apis/deliveryApi"

const initialState = {
	deliveryes: []
}

const deliverySlice = createSlice({
	name: "delivery",
	initialState,
	reducers: {
		setDeliveryes: (state, action) => {
			state.deliveryes = action.payload
		}
	},
    extraReducers: ( builder ) => {
		builder
			.addMatcher( deliveryApi.endpoints.getDelivery.matchFulfilled, (
					state,
					action
				) => {
					state.delideliveryesvery = action.payload
				}
			)
	}
})

const {
	actions,
	reducer
} = deliverySlice
export const { setDeliveryes } = actions
export default reducer
