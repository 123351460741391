import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	PRICE_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const priceApi = createApi({
	reducerPath: "priceApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getPrice: builder.mutation({
			query: () => (
				{
					url: PRICE_API.GET_PRICE,
					method: "GET",
				})
		}),
		createPrice: builder.mutation({
			query: (body) => (
				{
					url: PRICE_API.CREATE_PRICE,
					method: "POST",
					body
				})
		}),
		editPrice: builder.mutation({
			query: (body) => (
				{
					url: PRICE_API.EDIT_PRICE,
					method: "POST",
					body
				})
		}),
		deletePrice: builder.mutation({
			query: (body) => (
				{
					url: PRICE_API.DELETE_PRICE,
					method: "POST",
					body
				})
		}),
	})
});

export const {
	useGetPriceMutation,
	useCreatePriceMutation,
	useEditPriceMutation,
	useDeletePriceMutation,


} = priceApi
