import axios from "axios";
const API_KEY = process.env.REACT_APP_GOOGLE_API;

export async function TranslateText(text, variables){
    try {
        const translations = {}
        await Promise.all(
            variables.map(async lang => {
                let currentLang = lang
                if(lang === "ua") currentLang = "uk"
                if(lang === "cn") currentLang = "zh"
                const url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}&q=${text}&target=${currentLang}`
                const response = await axios.get(url)
                translations[lang] = decodeHtmlEntities(response?.data?.data?.translations?.[0]?.translatedText)
                return {[lang] : response?.data?.data?.translations?.[0]?.translatedText}
            })
        )
        console.log("translations", translations);
        return translations
    } catch(e){
        console.log("Translate text fetch error", e);
    }
}

function decodeHtmlEntities(text) {
    const entityMap = {
      '&quot;': '"',
      '&#39;': "'",
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      // добавьте другие сущности, если необходимо
    };
  
    return text.replace(/&quot;|&#39;|&amp;|&lt;|&gt;/g, function(match) {
      return entityMap[match];
    });
}