import {createSlice} from "@reduxjs/toolkit"
import {pagesApi} from "../apis/pagesApi"

const initialState = {
	pages: [],
	alert: null
}

const pagesSlice = createSlice({
	name: "pages",
	initialState,
	reducers: {
		createNewPagesItem: (state, action) => {
			state.pages = [action.payload, ...state.pages]
		},
		editPagesItem: (state, action) => {
			const index = state.pages.findIndex(
				(pages) => {
					return pages.id === action.payload.id
				}
			)
			state.pages[index] = action.payload
		},
		deletePagesItem: (state, action) => {
			const pagesId = action.payload
			const pagesID = state.pages.filter(pages => pages.id !== pagesId)
			state.pages = pagesID
		},
		setAlertPages: (state, action) => {
			state.alert = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(pagesApi.endpoints.getPages.matchFulfilled, (
					state,
					action
				) => {
					state.pages = action.payload.data
				}
			)
	}
})

const {
	actions,
	reducer
} = pagesSlice
export const {
	createNewPagesItem,
	deletePagesItem,
	editPagesItem,
	setAlertPages
} = actions
export default reducer
