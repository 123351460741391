import React from 'react'
import BlockWithTranslationFieldsTemplatesDesc from "../../../components/BlockWithTranslationFieldsTemplatesDesc";
import BlockWithTranslationFieldsTemplatesPlaceholder from "../../../components/BlockWithTranslationFieldsTemplatesPlaceholder";
import { useTranslation } from 'react-i18next';

const FourBlockTemplateContent = ({
	handleBlur,
	valuesPlaceholder,
	valuesDescription,
	touched,
	errors,
	setForm,
	handleChange,
	formDateUpdateHandler
}) => {

	const {t} = useTranslation()

	return (
		<div className='template-content_nameBlock mt-4'>
			<h2 className='template-content_nameBlock-title'>
				4. {t('tf_descriptionAndPlaceholder')}
			</h2 >
			<div className='template-content_nameBlock-wrapper mb-4'>
				<BlockWithTranslationFieldsTemplatesDesc
					handleChange={handleChange}
					values={valuesDescription}
					errors={errors}
					setForm={setForm}
					touched={touched}
					handleBlur={handleBlur}
					formDateUpdateHandler={formDateUpdateHandler}
					name={'Description'}
					title={'description'}
				/>
			</div >
		<div className='template-content_nameBlock-wrapper' style={{borderTop: '1px solid rgba(171, 167, 175, 0.7)'}}>
			<BlockWithTranslationFieldsTemplatesPlaceholder
				handleChange={handleChange}
				values={valuesPlaceholder}
				errors={errors}
				setForm={setForm}
				touched={touched}
				handleBlur={handleBlur}
				formDateUpdateHandler={formDateUpdateHandler}
				name={'Placeholder'}
				title={'placeholder'}
			/>
			</div >
		</div >
	)
}

export default FourBlockTemplateContent