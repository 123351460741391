import { useTranslation } from "react-i18next";
import BlockWithTranslationFieldsTemplates from "../../../components/BlockWithTranslationFieldsTemplates";
import React from "react";

const NameBlockTemplateContent = ( {
	handleBlur,
	values,
	touched,
	errors,
	handleChange,
	formDateUpdateHandler,
	setForm
	} ) => {
		const {t} = useTranslation()
	return (
		<div className='template-content_nameBlock'>
			<h2 className='template-content_nameBlock-title'>
				{t('c_add_enterTitle')}
			</h2 >
			<BlockWithTranslationFieldsTemplates
				handleChange={handleChange}
				values={values}
				errors={errors}
				touched={touched}
				handleBlur={handleBlur}
				formDateUpdateHandler={formDateUpdateHandler}
				name={'Title'}
				title={'name'}
				setForm={setForm}
			/>
		</div >
	)
}

export default NameBlockTemplateContent