import { createSlice } from "@reduxjs/toolkit"
import { amountApi } from "../apis/amountApi"

const initialState = {
	amount: [],
	showModalChangeUnitAmount: false,
	dateModalChangeUnitAmount: ''
}

const amountSlice = createSlice({
	name: "amount",
	initialState,
	reducers: {
		setAmountList: (state, action) => {
			state.amount = action.payload
		},
		createNewAmountItem: (state, action) => {
			state.amount = [action.payload, ...state.amount]
		},
		editAmountItem: (state, action) => {
			const index = state.amount.findIndex(
				(term) => {
					return term.id === action.payload.id
				}
			)
			state.amount[index] = action.payload
		},
		deleteAmountItem: (state, action) => {
			const termId = action.payload
			const termID = state.amount.filter(term => term.id !== termId)
			state.amount = termID
		},
		showChangeUnitAmountModal: (state, action) => {
			state.showModalChangeUnitAmount = action.payload
		},
		setDateChangeUnitAmount: (state, action) => {
			state.dateModalChangeUnitAmount = action.payload
		},
		resetDateChangeUnitAmount: (state, action) => {
			state.dateModalChangeUnitAmount = ''
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(amountApi.endpoints.getAmount.matchFulfilled, (
					state,
					action
				) => {
					state.amount = action.payload.data
				}
			)
	}
})

const {
	actions,
	reducer
} = amountSlice
export const {
	setAmountList,
	createNewAmountItem,
	deleteAmountItem,
	editAmountItem,
	showChangeUnitAmountModal,
	setDateChangeUnitAmount,
	resetDateChangeUnitAmount
} = actions
export default reducer
