import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from "react-bootstrap"
import { useTranslation } from 'react-i18next'

const TypesParameters = ({types, handleChange, formDateUpdateHandler, form, edit}) => {
	const [changeType, setChangeType] = useState('Product rules')
	const {t} = useTranslation()

	useEffect(() => {
		let typeNum = false
		if (changeType === 'Product rules') typeNum = 1
		else if (changeType === 'Stocks rent rules') typeNum = 2
		else if (changeType === 'Transport rules') typeNum = 3
		else if (changeType === 'Services rules') typeNum = 4
		else if (changeType === 'Technical rules') typeNum = 5
		formDateUpdateHandler((prev => {
			if(!typeNum) typeNum = edit? 1 : prev.type
			return {...prev, type: typeNum}
		}))
	}, [changeType, formDateUpdateHandler, edit])

	const typesNames = useMemo(() => {
		const res = types?.map(item => item?.name)
		return res
	}, [types])

	useEffect(() => {
		if (edit){
			if (form?.type === 1) setChangeType('Product rules')
			else if (form?.type === 2) setChangeType('Stocks rent rules')
			else if (form?.type === 3) setChangeType('Transport rules')
			else if (form?.type === 4) setChangeType('Services rules')
			else if (form?.type === 5) setChangeType('Technical rules')
		}
	}, [edit, form?.type])

	return (
		<div className='template-content_category'>
			<h2 className='template-content_category-title'>
				{t('c_add_chooseType')}
			</h2 >
			<Form.Group
				className='add-edit-terms_center'
			>
				{`Type`}
				<select
					className=' is-touch form-select mt-1'
					defaultValue={changeType}
					name={'type'}
					disabled={edit && !form?.dublicate}
					onKeyDown={e => {
						e.key === 'Enter' && e.preventDefault()
					}}
					onChange={(e) => {
						handleChange(e)
						setChangeType(e.target.value)
					}}
				>
					{
						typesNames && typesNames.map(type => (
							<option
								value={type?.id}
								key={type}
								selected={edit && changeType === type}
							>
								{type}
							</option >
						))
					}
				</select >
			</Form.Group >
		</div >
	)
}

export default TypesParameters