import React, { useState } from 'react'
import AddIcon from '../../../utils/icons/addPlus.svg'
import SearchItemsInput from '../../components/SearchItemsInput'
import { Form } from 'react-bootstrap'
import PollPageItemList from './PollPageItemList'
import PaginationCustom from '../../components/PaginationCustom'
import CreatePollPage from './CreatePoll/CreatePollPage'
import { ARE_COMMON } from "../../../utils/textsContent"
import SelectShowRecords from "../../components/SelectShowRecords"
import { useTranslation } from 'react-i18next'

const PollPage = () => {
	const [ showCreatePollPage, setShowCreatePollPage ] = useState( false )
	const toggleShowCreatePollPageHandler = () => setShowCreatePollPage( !showCreatePollPage )
	const {t} = useTranslation()

	return (
		<div className='terms'>
			{
				showCreatePollPage
					?
					<CreatePollPage toggleShowCreatePollPageHandler={toggleShowCreatePollPageHandler}/>
					:
					<>
						<h1 className='terms-title'>{t('_m_Pools')}</h1 >
						<div className='terms-wrapper poll'>
							<div className='poll-button'>
								<button
									className='poll-button_add terms-wrapper_addButton'
									onClick={ toggleShowCreatePollPageHandler }
								>
									<img
										src={ AddIcon }
										alt='AddIcon'
									/>
									{t('_m_createSurveyForm')}
								</button >
							</div >

							<div className='poll-tools'>
								<div className='poll-tools_left'>
									<div className='poll-tools_left-search'>
										<h2 className='poll-tools_left-title'>
										{t('_m_listOfPolls')}
										</h2 >
										<SearchItemsInput
											// toggleModalSearch={ toggleModalSearch }
											// searchTermValue={ searchTermValue }
											// valueSearchInputHandler={ valueSearchInputHandler }
											// searchHandler={ searchHandler }
											// clearSearchHandler={ clearSearchHandler }
										/>
									</div >
									<div className='poll-tools_center'>
										<button className='poll-tools_center-button template-content_buttons-close btn btn-primary'>
											{ `Розширений ${ARE_COMMON?.SEARCH}` }
										</button >
										{/* <Form.Group className='template-content_typeBlock-label poll-tools_center-label'>
											<h2 className='notification-email_content-title poll-tools_center-title'>
												{t('sort')}
											</h2 >
											<select
												aria-label='Default select example'
												className='template-content_typeBlock-select poll-tools_center-select'
												name='typeNotification'
												// onChange={ ( e ) => formDateUpdateHandler( { [ e.target.name ]: Number( e?.target.value ) } ) }
											>
												<option value={ 0 }>{ `Дата ${ ARE_COMMON?.CREATED }`}</option >
												<option value={ 1 }>{ `Дата ${ ARE_COMMON?.CREATED }`}</option >
											</select >
										</Form.Group > */}
									</div >
								</div >
								<div className='poll-tools_right'>
										<SelectShowRecords
											setCountShow={20}
										/>
								</div >
							</div >

							<ul className='poll-content'>
								<PollPageItemList />
								<PollPageItemList />
								<PollPageItemList />
							</ul >
							<div className='poll-pagination'>
								<PaginationCustom
									currentPage={ 1 }
									// setCurrentPage={ refetch }
									itemsCount={ 1 }
									// counterAllItems={ groups.totalGroups }
								/>
							</div >


						</div >
					</>
			}

		</div >
	)
}

export default PollPage