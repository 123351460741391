import { createSlice } from "@reduxjs/toolkit"
import { templateApi } from "../apis/templateApi"

const initialState = {
	template: [],
	dateModalChangeUnitTemplatesArr: [],
	showModalChangeUnitTemplates: false,

	showModalEditTemplateContent: false,
	showModalEditDataTemplateContent: {},

	allElementsChangeInContentBlocks: [],
	mainBlockList: [],
	detailInfoBlockList: [],
	additionalBlockList: [],
}

const templateSlice = createSlice({
	name: "template",
	initialState,
	reducers: {
		createNewTemplateItem: (state, action) => {
			state.template = [action.payload, ...state.template]
		},
		editTemplateItem: (state, action) => {
			const index = state.template.findIndex(
				(term) => {
					return term.id === action.payload.id
				}
			)
			state.template[index] = action.payload
		},
		deleteTemplateItem: (state, action) => {
			const termId = action.payload
			const termID = state.template.filter(term => term.id !== termId)
			state.template = termID
		},
		setDateChangeUnitTemplatesListArr: (state, action) => {
			// const uniq = UniqueArrItems([...state.dateModalChangeUnitTemplatesArr, Number(action.payload)])
			// state.dateModalChangeUnitTemplatesArr = uniq
			state.dateModalChangeUnitTemplatesArr = [...state.dateModalChangeUnitTemplatesArr, action.payload]
		},
		resetDateChangeUnitTemplatesListArr: (state, action) => {
			state.dateModalChangeUnitTemplatesArr = []
		},
		showChangeTemplateContentTemplates: (state, action) => {
			state.showModalChangeUnitTemplates = action.payload
		},
		showModalEditTemplateContentTemplates: (state, action) => {
			state.showModalEditTemplateContent = action.payload
		},
		showModalEditDataTemplateContentTemplates: (state, action) => {
			state.showModalEditDataTemplateContent = action.payload
		},
		deleteDateChangeUnitTemplatesList: (state, action) => {
			const dateModalChangeUnitTemplatesArrId = Number(action.payload)
			const dateModalChangeUnitTemplatesArrID = state.dateModalChangeUnitTemplatesArr.filter(item => Number(item?.id) !== dateModalChangeUnitTemplatesArrId)
			state.dateModalChangeUnitTemplatesArr = dateModalChangeUnitTemplatesArrID
		},

		// <<<<<<<--------------- ContentBlockLis --------------- >>>>>>
		setContentBlockList: (state, action) => {
			state.allElementsChangeInContentBlocks = action.payload
		},
		resetContentBlockList: (state, action) => {
			state.allElementsChangeInContentBlocks = []
		},

		// <<<<<<<--------------- MainBlockList --------------- >>>>>>
		addMainBlockList: (state, action) => {
			const uniq = [...state.mainBlockList, action.payload]
			state.mainBlockList = uniq
		},
		pushMainBlockList: (state, action) => {
			state.mainBlockList = action.payload
		},
		deleteMainBlockList: (state, action) => {
			const mainBlockListId = action.payload
			const mainBlockListID = state.mainBlockList.filter(item => item?.id !== mainBlockListId)
			state.mainBlockList = mainBlockListID
		},
		resetMainBlockList: (state, action) => {
			state.mainBlockList = []
		},
		changeMainBlock: (state, action) => {
			state.mainBlockList = action.payload
		},

		// <<<<<<<--------------- DetailInfoBlockList --------------- >>>>>>
		addDetailInfoBlockList: (state, action) => {
			const uniq = [...state.detailInfoBlockList, action.payload]
			state.detailInfoBlockList = uniq
		},
		pushDetailInfoBlockList: (state, action) => {
			state.detailInfoBlockList = action.payload
		},
		deleteDetailInfoBlockList: (state, action) => {
			const detailInfoBlockListId = action.payload
			const detailInfoBlockListID = state.detailInfoBlockList.filter(item => item?.id !== detailInfoBlockListId)
			state.detailInfoBlockList = detailInfoBlockListID
		},
		resetDetailInfoBlockList: (state, action) => {
			state.detailInfoBlockList = []
		},
		changeDetailInfoBlockList: (state, action) => {
			state.detailInfoBlockList = action.payload
		},

		// <<<<<<<--------------- AdditionalBlockList --------------- >>>>>>
		addAdditionalBlockList: (state, action) => {
			const uniq = [...state.additionalBlockList, action.payload]
			state.additionalBlockList = uniq
		},
		pushAdditionalBlockList: (state, action) => {
			state.additionalBlockList = action.payload
		},
		deleteAdditionalBlockList: (state, action) => {
			const additionalBlockListId = action.payload
			const additionalBlockListID = state.additionalBlockList.filter(item => item?.id !== additionalBlockListId)
			state.additionalBlockList = additionalBlockListID
		},
		resetAdditionalBlockList: (state, action) => {
			state.additionalBlockList = []
		},
		changeAdditionalBlockList: (state, action) => {
			state.additionalBlockList = action.payload
		},

	},
	extraReducers: (builder) => {
		builder
			.addMatcher(templateApi.endpoints.getTemplate.matchFulfilled, (
					state,
					action
				) => {
					state.template = action.payload.data
				}
			)
			.addMatcher(templateApi.endpoints.getTemplateV2.matchFulfilled, (
					state,
					action
				) => {
					state.template = action.payload.templates
				}
			)
	}
})

const {
	actions,
	reducer
} = templateSlice
export const {
	createNewTemplateItem,
	deleteTemplateItem,
	editTemplateItem,
	setDateChangeUnitTemplatesListArr,
	showChangeTemplateContentTemplates,
	deleteDateChangeUnitTemplatesList,
	resetDateChangeUnitTemplatesListArr,
	showModalEditTemplateContentTemplates,
	showModalEditDataTemplateContentTemplates,


	setContentBlockList,
	resetContentBlockList,
	addMainBlockList,
	deleteMainBlockList,
	resetMainBlockList,
	changeMainBlock,
	addDetailInfoBlockList,
	deleteDetailInfoBlockList,
	resetDetailInfoBlockList,
	changeDetailInfoBlockList,
	addAdditionalBlockList,
	deleteAdditionalBlockList,
	resetAdditionalBlockList,
	changeAdditionalBlockList,

	pushMainBlockList,
	pushDetailInfoBlockList,
	pushAdditionalBlockList

} = actions
export default reducer
