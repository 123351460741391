import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ChangeTemplateContentForTemplateView = ({data, setData}) => {

	const {t, i18n} = useTranslation()
	
	return (
		<div className='template-content_category'>
			<h2 className='template-content_category-title'>
      {t('c_stftf')}
    </h2 >
    <div className='template-content_category-body'>
	{
		data?.map((el, index) => (
			<li
				className='template-content_valueBlock-list_li'
				key={index}
			>
				<div className='template-content_valueBlock-list_li-text justify-content-between'>
					<Form.Check
						className='noticeItem-wrapper_status-moderation_item-body_block-checkbox p-0'
						type='checkbox'
						label={el.el.name?.[i18n.language] || el.el.title?.[i18n.language]}
						checked={el.checked}
						onKeyDown={e => {
							e.key === 'Enter' && e.preventDefault()
						}}
						onChange={e => {
							setData(prev => {
								return prev.map(item =>{
									if(item.el.id === el.el.id) item.checked = !item.checked
									return item
								})
							})
						}}
					/>
				</div >
			</li >
		))
	}
    </div >
  </div >
	)
}

export default ChangeTemplateContentForTemplateView