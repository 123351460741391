export function getMarkerStatus(schedule) {
    const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const now = new Date();
    const todayIndex = now.getDay();
    const currentDay = daysOfWeek[todayIndex];
    const currentTime = now.getHours() * 60 + now.getMinutes(); // Текущее время в минутах с начала дня

    function timeInMinutes(timeStr) {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return hours * 60 + minutes;
    }

    function getNextDayIndex(index) {
        return (index + 1) % 7;
    }

    function getNextTime(dayIndex, type) {
        const nextDay = daysOfWeek[dayIndex];
        const nextDaySchedule = schedule[nextDay];
        if (nextDaySchedule && nextDaySchedule[type] && nextDaySchedule[type].from) {
            return {
                day: nextDay,
                time: nextDaySchedule[type].from.time
            };
        }
        else if(nextDaySchedule === true){
            return {
                day: nextDay,
                time: "00:00"
            };
        }
        return null;
    }

    // Проверяем расписание на текущий день
    const todaySchedule = schedule[currentDay];
    if (todaySchedule && todaySchedule.open) {
        const openFrom = timeInMinutes(todaySchedule.open.from.time);
        const openTo = timeInMinutes(todaySchedule.open.to.time);
        let breakFrom = null;
        let breakTo = null;

        // Если есть информация о перерыве
        if (todaySchedule.break) {
            breakFrom = timeInMinutes(todaySchedule.break.from.time);
            breakTo = timeInMinutes(todaySchedule.break.to.time);
        }

        // Магазин еще не открылся
        if (currentTime < openFrom) {
            return {
                isOpen: false,
                nextEvent: { day: currentDay, time: todaySchedule.open.from.time }
            };
        }

        // Магазин открыт, но нужно проверить перерыв
        if (currentTime >= openFrom && currentTime < openTo) {
            if (breakFrom && breakTo && currentTime >= breakFrom && currentTime < breakTo) {
                // Магазин закрыт на перерыв
                return {
                    isOpen: false,
                    nextEvent: { day: currentDay, time: todaySchedule.break.to.time }
                };
            } else {
                // Магазин открыт и не находится в перерыве
                return {
                    isOpen: true,
                    nextEvent: { day: currentDay, time: todaySchedule.open.to.time }
                };
            }
        }
    }
    else if(todaySchedule === true){
        return {
            isOpen: "around"
        };
    }

    // Магазин закрыт, ищем ближайшее время открытия
    let dayIndex = todayIndex;
    for (let i = 0; i < 7; i++) {
        dayIndex = getNextDayIndex(dayIndex);
        const nextEvent = getNextTime(dayIndex, 'open');
        if (nextEvent) {
            return {
                isOpen: false,
                nextEvent
            };
        }
    }

    // Если не удалось найти следующее время открытия
    return {
        isOpen: false,
        nextEvent: null
    };
}