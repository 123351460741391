import React, {
	useEffect,
	useState
} from 'react'
import arrowDownIcon from "../../../../utils/icons/arrow-full-down.svg"
import { Form } from "react-bootstrap"
import Remove from '../../../../utils/icons/delete-red-icon.svg'
import ChangeUnitForTemplates from "./ChangeUnitForTemplates"
import {Button} from 'react-bootstrap'
import { ConfirmModal } from '../../../components/ConfirmModal/ConfirmModal'
import { useFixTemplateLanguageToEnglishMutation, useGetTemplateContentV2Mutation } from '../../../store/apis/temlateContentApi'
import { useGetTermsMutation } from '../../../store/apis/termsApi'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux'
import { resetDateChangeUnitTemplatesList, setDateChangeUnitTemplatesList } from '../../../store/slices/temlateContentSlice'
import { useTranslation } from 'react-i18next'
const ValueBlockTemplateContent = ( {
	touched,
	errors,
	type,
	showModalChangeUnitTemplates,
	showModalPr,
	changeItemsArr,
	removeChangeItemArr,
	changeItem,
	removeChangeItem,
	toggleTypeChange,
	dateModalChangeUnitTemplatesList,
	setUpdatedState,
	form,
	edit
} ) => {
	const [confirmModal, setConfirmModal] = useState(false)
	const [confirmModalSuccess, setСonfirmModalSuccess] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [dragableChangeItemsArr, setDragableChangeItemsArr] = useState(changeItemsArr)
	const [fixLanguageQuery] = useFixTemplateLanguageToEnglishMutation()
	const [getTerms] = useGetTermsMutation()
	const [getTemplateContentV2] = useGetTemplateContentV2Mutation()

	const dispatch = useDispatch()
	const {t} = useTranslation()

	useEffect(()=>{
		setDragableChangeItemsArr(changeItemsArr)
	},[changeItemsArr]);

	useEffect( () => { 
		toggleTypeChange( type )
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ type ] )

	async function fixLanguage(){
		setIsLoading(true)
		const response = await fixLanguageQuery({id: form.id})
		await getTerms({list: form.values})
		await getTemplateContentV2({})
		
		setIsLoading(false)
		if(response.data?.value){
			setСonfirmModalSuccess(response.data?.value)
			setUpdatedState(prev => prev + 1)
		}
	}

	function onDragEnd(result){
		if (!result.destination) {
			return
		}
		const newOrder = reorder(
			dragableChangeItemsArr,
			result.source.index,
			result.destination.index
		)

		dispatch(resetDateChangeUnitTemplatesList())
		newOrder.forEach(el => dispatch(setDateChangeUnitTemplatesList(Number(el.id))))
	}

	function reorder(list, startIndex, endIndex){
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	  };

	return (
		<div className='template-content_valueBlock'>
			{
				confirmModal ? 
				<ConfirmModal 
					title="Change all translations to eng?"
					text="If you are unsure of your actions, do not proceed"
					close={()=>{setConfirmModal(false)}}
					success={fixLanguage}
					isLoading={isLoading}
				/> : null
			}
			{
				confirmModalSuccess ? 
				<ConfirmModal 
					title="Success"
					text={`Changed ${confirmModalSuccess} terms`}
					close={()=>{setСonfirmModalSuccess(false)}}
					
					isLoading={isLoading}
				/> : null
			}
			{
				showModalChangeUnitTemplates && <ChangeUnitForTemplates type={ type } />
			}
			<h2 className='template-content_defaultValueBlock-title'>
				{ type === 1 ? t('tf_choose_unitOfMeasurement') : t('tf_choose_value') }
			</h2 >
			<div style={{display: "flex", gap: '20px', width: '100%'}}>
			<Form.Group className='template-content_valueBlock-dropdown'>
				<button
					type='button'
					className={ `template-content_valueBlock-dropdown_button ${ touched?.unit ? "is-touch " : "" } ${
						errors?.unit && touched?.unit ? " is-invalid" : ""
					}` }
					onClick={ showModalPr }
				>
					{
						type === 1
							?
							t('tf_choose_unitOfMeasurement')
							:
							// dateModalChangeUnitTemplatesList?.length > 0
							dragableChangeItemsArr?.length > 0
								?
								`${t('selected')} ${ dragableChangeItemsArr?.length }`
								:
								t('tf_choose_value')
					}
					<img
						className='template-content_valueBlock-dropdown_button-img'
						src={ arrowDownIcon }
						alt='arrow down'
					/>
				</button >
				{ errors.unit && touched.unit && (
					<Form.Control.Feedback type='invalid'>
						{ errors.unit }
					</Form.Control.Feedback >
				) }
			</Form.Group >
			{
				(type === 2 || type === 3) ? edit ? <Button onClick={()=>setConfirmModal(true)}>Fix to English</Button> : null : null
			}
			</div>

				{
				dragableChangeItemsArr
				&&
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId={"droppable"}>
							{(provided) => (
								<ul className='template-content_valueBlock-list'>
								<div ref={provided.innerRef} {...provided.droppableProps}>
								{
									dragableChangeItemsArr?.map( (item, index) => (
										<Draggable key={item.id} draggableId={`${item?.id}`} index={index}>
											{(provided) => (
												<li
													id={item.id}
													className='template-content_valueBlock-list_li'
													key={ item?.id }
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<p className='template-content_valueBlock-list_li-text'>
														{ item?.title?.ua || item?.name }
													</p >
													<img
														src={ Remove }
														alt='remove item'
														className='template-content_valueBlock-list_li-remove'
														onClick={ () => removeChangeItemArr( item?.id ) }
													/>
												</li >
											)}
										</Draggable>
									) )
								}
								{provided.placeholder}
								</div>
								</ul >
							)}
						</Droppable>
					</DragDropContext>
				}
				{
					(changeItem?.term?.ua || changeItem?.name)
					&&
					<li
						className='template-content_valueBlock-list_li'
					>
						<p className='template-content_valueBlock-list_li-text'>
							{ changeItem?.term?.ua || changeItem?.name }
						</p >
						<img
							src={ Remove }
							alt='remove item'
							className='template-content_valueBlock-list_li-remove'
							onClick={ removeChangeItem }
						/>
					</li >
				}
		</div >
	)
}

export default ValueBlockTemplateContent