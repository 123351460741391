import React, { useEffect, useMemo, useRef, useState } from 'react';
import s from './aboutNotification.module.scss';
import { useGetNotificationQuery, useGetNotificationStatisticsQuery } from '../../store/apis/notificationApi';
import { Link, useSearchParams } from 'react-router-dom';
import { Tabs } from '../../components/Tabs/Tabs';
import { transformDate, transformDateMini } from '../../../utils/helpers/TransformDate';
import { ReactComponent as TickIcon } from '../../../utils/icons/tick_icon.svg';
import { ProgressBar } from 'react-bootstrap';
import { ReactComponent as Loader } from '../../../utils/icons/loading-icon.svg';
import SelectShowRecords from '../../components/SelectShowRecords';
import PaginationCustom from '../../components/PaginationCustom';
import { useGetLangsQuery } from '../../store/apis/langApi';
import mobile_viber from '../../../utils/assets/images/mobile-viber.webp'
import {ReactComponent as ViberLogo} from '../../../utils/assets/images/viber-logo.svg'
import { useTranslation } from 'react-i18next';


export const AboutNotification = ()=>{
    
    // eslint-disable-next-line no-unused-vars
    const [params, setParams] = useSearchParams()
    const notification_id = params.get("id")
    
    const [tabs, setTabs] = useState([])
    const [activeTab, setActiveTab] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [currentStatistics, setCurrentStatistics] = useState('successful')

    const iFrame = useRef();
    const table = useRef();
    const {t} = useTranslation()

    const {data, isLoading: isLoadingNotification} = useGetNotificationQuery(notification_id);
    const {data: statisticsData, isFetching: isLoadingStatisticsNotification} = useGetNotificationStatisticsQuery({id: notification_id, limit: limit, page: currentPage})
    const {data: langs, isLoading: isLoadingLangs} = useGetLangsQuery()
    
    useEffect(()=>{
        if(langs) setActiveTab(langs[0]?.langIndex)
        langs?.forEach(el => {
            setTabs(prev => [...prev, {type: el.langIndex, title: el.langIndex}])
        })
    },[langs]);

    let body = data?.template.replaceAll('<li>', '<li><span class="list_style"></span>');
    let template = body?.replace('$body', data.body? data?.body[activeTab] : null)
    template = template?.replace('$title', data.title? data?.title[activeTab] : null)
    template = template?.replace('$footer_text1', footer_text.footer1[activeTab])
    template = template?.replace('$footer_text2', footer_text.footer1[activeTab])
    template = template?.replace('$copyrates', footer_text.copyrates[activeTab])
    template = template?.replace('$unsubscribe', footer_text.unsubscribe[activeTab])
   
    useEffect(()=>{
        if(iFrame.current){
            iFrame.current.width  = iFrame.current.contentWindow.document.body.scrollWidth;
            iFrame.current.height = iFrame.current.contentWindow.document.body.scrollHeight+100;
        }
    },[iFrame.current?.contentWindow?.document?.body?.scrollHeight]);

    const resProcent = useMemo(() => {
		const procent = (data?.statistics?.conversion / (data?.customGroups[0]?.users.length + data?.customGroups[0]?.contacts.length)) * 100
		return procent
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.statistics])
    function getIntervalValue(){
        switch(data?.repeatCode){
            case 0:
                return "6 hours"
            case 1:
                return "12 hours"
            case 2:
                return "24 hours"
            case 3:
                return "48 hours"
            case 4:
                return "72 hours"
            case 5:
                return "5 days"
            case 6:
                return "6 days"
            case 7:
                return "7 days"
            case 8:
                return "2 weeks"
            case 9:
                return "1 month"
            case 10:
                return "3 months"
            case 11:
                return "6 months"
            case 12:
                return "year"            
            default:
                return ""
        }
    }

    function changeStatistics(){
        table?.current?.scrollIntoView()
    }

    if(isLoadingNotification || isLoadingLangs || isLoadingStatisticsNotification ) return <div className={s.loader_wrapper}><Loader/></div>


    const table_success = statisticsData?.statistics[currentStatistics]?.map((el, index) => {
        return (
          <tr key={index}>
                <td>{data.senderType === 'email'? el?.user?.email : el?.user?.phone}</td>
                <td>{el?.user?.name}</td>
                <td>{el?.user?.lang}</td>
            </tr>
        )
    })
    if(!data && !isLoadingStatisticsNotification && !isLoadingNotification) return 
    return(
        <div className='terms'> 
            <h1 className='terms-title'>
                {!data.senderType === "sms"?
                    data?.title[activeTab]
                    :
                    data?.senderType === "sms" ? (<h1>{t('ns_sms')}</h1>) : (<h1>{t('ns_email')}</h1>)
                }
            </h1 >
            <div className={`terms-wrapper ${s.content}`}>
                <header>
                    <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                </header>
                <div className={s.row}>
                    <div className={s.message_wrapper}>
                        {
                            data.senderType === "sms" || data.senderType === "viber"? 
                            <div className={s.viber_image_wrapper}>
                                <img src={mobile_viber} alt="mobile" />
                                <div className={s.content_wrapper}>
                                    <div className={s.content}>
                                        <div className={s.left}>
                                        <ViberLogo />
                                        </div>
                                        <div className={s.right}>
                                            <span className={s.text}>
                                                {data?.title[activeTab]}
                                            </span>
                                            {
                                                data?.images ?
                                                <div className={s.image} style={{
                                                    background: `url(${data.images[activeTab]})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center center'
                                                }}>
                                                </div> : null
                                            }
                                            {
                                                data?.actionButton? <div className={s.button}><span>{data?.actionButton?.caption[activeTab]}</span></div> : null
                                            }
                                            <div className={s.time}>
                                                <span>16:00</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <iframe srcDoc={template} title="message" name="message" width="100%" frameBorder="0" ref={iFrame}></iframe>
                        }
                    </div>
                    <div className={s.info_wrapper}>
                        <span className={s.row_title}>{t('newsletter')}</span>
                        {/* {
                            !data.customGroups[0].validationEmailDate ?
                            <div className={s.item}>
                                <span className={s.validation} onClick={()=>navigate("/notifications/validation")}><WarningIcon/>Адресна книга не валiдована. <span className={s.validation_link}>Валiдацiя</span></span>
                            </div> : null
                        } */}
                        <div className={s.item}>
                            <span className={s.title}>{t('date_created')}:</span>
                            <span className={s.value}><span className={s.green}>{transformDate(data?.created)}</span></span>
                        </div>
                        <div className={s.item}>
                            <span className={s.title}>{t('s_adb')}:</span>
                            <span className={s.value}>{!data?.customGroups[0]? 'Address book was deleted' : data?.customGroups.map((group, index) => (<Link key={index} to={`/address-book/book?id=${group.id}`}>{`${group.name} (${t('ns_recipients')}: ${group.users.length + group.contacts.length})`}</Link>))}</span>
                        </div>
                        {data.senderType !== "email"? null :
                        (<>
                        <div className={s.item}>
                            <span className={s.title}>{t('ns_sender_email')}:</span>
                            <span className={s.value}><span className={s.green}>{data?.sender.email}</span></span>
                        </div>
                        <div className={s.item}>
                            <span className={s.title}><span className={s.green}>{t('ns_sender_name')}:</span></span>
                            <span className={s.value}>{data?.sender.name}</span>
                        </div>
                        </>)
                        }
                        <div className={s.item}>
                            <span className={s.title}>{t('ns_time')}:</span>
                            <span className={s.value}>{!data?.startType? <span>{t('ns_send_now')} <span className={s.green}>{transformDate(data?.created)}</span></span> : <span>{t('ns_send_delay')} <span className={s.green}>{transformDate(data?.start)}</span></span>}</span>
                        </div>
                        <div className={s.item}>
                            <span className={s.title}>{t('sended')}:</span>
                            <span className={s.value}><span>{t('sended')} <span className={s.green}>{!data?.type? data?.status? '1/1': '0/1' : `${(data?.startRepeat - data?.repeat)}/${data?.startRepeat}`}</span></span></span>
                        </div>
                        <div className={s.item}>
                            <span className={s.title}>{t('ns_type')}:</span>
                            <span className={s.value}>{!data?.type? t('ns_onetime') : t('ns_cyclic')}</span>
                        </div>

                        <div className={s.statistic_item}>
                            <span className={s.row_title}>{t('statistic')}</span>
                            <div className={s.statistic_row}>
                                <div className={s.statistic_item}>
                                    <span className={s.statistic_value}>{!data?.customGroups[0]? "---" : data?.customGroups[0]?.users.length + data?.customGroups[0]?.contacts.length}</span>
                                    <span className={s.statistic_title}>{t('count')}</span>
                                </div>
                                <div className={`${s.statistic_item} ${s.statistic_item_clickable}`} onClick={()=>{setCurrentStatistics('successful'); changeStatistics()}}>
                                    <span className={s.statistic_value}>{data?.statistics.successful}</span>
                                    <span className={s.statistic_title}>{t('sended')}</span>
                                </div>
                                <div className={`${s.statistic_item} ${s.statistic_item_clickable}`} onClick={()=>{setCurrentStatistics('errors'); changeStatistics()}}>
                                    <span className={s.statistic_value}>{data?.statistics.errors}</span>
                                    <span className={s.statistic_title}>{t('errors')}</span>
                                </div>
                                {
                                    data.senderType === "sms"? null :

                                    <div className={s.statistic_item}>
                                        <span className={s.statistic_value}>{data?.statistics.conversion}</span>
                                        <span className={s.statistic_title}>{t('transitions')}</span>
                                    </div>
                                }
                            </div>
                            {
                                data.senderType === "sms"? null :
                                <div className={s.bottom}>
                                    <div className={s.progress_bar}>
                                        <ProgressBar
                                            variant={resProcent < 100 ? resProcent < 30 ? 'danger' : 'warning' : 'success'}
                                            now={resProcent}
                                        />
                                    </div >
                                    <div className={s.result}>
                                        {
                                            resProcent < 100
                                                ?
                                                <span >{`${parseInt(resProcent)}%`}</span >
                                                :
                                                resProcent === Number('Infinity')
                                                    ?
                                                    <>
                                                        <span >{`${parseInt('0')}%`}</span >
                                                    </>
                                                    :
                                                    <TickIcon />
                                        }
                                    </div >
                                </div >
                            }
                        </div>

                        {typeof data?.repeatCode === 'number'?  <>
                        <div className={s.item}>
                            <span className={s.title}>{t('ns_interval')} :</span>
                            <span className={s.value}>{t('every')} <span className={s.green}>{getIntervalValue()}</span></span>
                        </div>
                        <div className={s.item}>
                            <span className={s.title}>{t('ns_next')}: </span>
                            <span className={s.value}><span className={s.green}>{transformDate(data?.start)}</span></span>
                        </div>
                        <span className={s.row_title_cicle}>{t('ns_cycle')}</span>
                        <div className={s.steps_wrapper}>
                            {data?.steps.map((el, index) => <span key={index} className={`${s.step} ${index < data?.startRepeat - data?.repeat? s.active : ''}`}>{transformDateMini(el)}</span>)}
                        </div>
                        </>: null
                        }
                    </div>
                </div>
                {statisticsData[`total_${currentStatistics}`]? 
                <div className={s.table_wrapper} ref={table}>
                    <div className={s.tools_wrapper}>
                        {statisticsData[`total_${currentStatistics}`] > limit ?
                        <PaginationCustom
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            itemsCount={limit}
                            counterAllItems={currentStatistics === "successful"? statisticsData.total_successful : statisticsData.total_errors}
                            alwaysShown={()=>{}}
                        />:null}
                        <div className={s.title}>
                            <h3>{currentStatistics === "successful"? 'Вiдправленi' : 'Помилки'}</h3>
                        </div>
                        <SelectShowRecords setCountShow={setLimit}  />
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <td>Email</td>
                                <td>Name</td>
                                <td>Language</td>
                            </tr> 
                        </thead>
                        <tbody>
                        {isLoadingStatisticsNotification? 
                        <div className={s.loader_wrapper}><Loader/></div> : 
                        table_success}
                        </tbody>
                    </table>
                </div> : null}
            </div>
        </div>
    )
}


const footer_text = {
    footer1: {
        en: "Best regards,",
        ua: "З повагою,",
        ru: "С уважением,",
        fr: "Cordialement,",
        cn: "致敬,",
        th: "ด้วยความเคารพ"
    },
    footer2: {
        en: "TENTAI Marketplace Team",
        ua: "Команда TENTAI Marketplace",
        ru: "Команда TENTAI Marketplace",
        fr: "Équipe de TENTAI Marketplace",
        cn: "TENTAI Marketplace团队",
        th: "ทีม TENTAI Marketplace"
    },
    copyrates: {
        en: "© 2024 Tentai - Book, rent, buy, sell with just one click!",
        ua: "© 2024 Tentai - Бронюйте, орендуйте, купуйте, продавайте одним кліком!",
        ru: "© 2024 Tentai - Бронируйте, арендуйте, покупайте, продавайте одним кликом!",
        fr: "© 2024 Tentai - Réservez, louez, achetez, vendez en un clic !",
        cn: "© 2024 Tentai - 一键预订、租赁、购买、销售！",
        th: "© 2024 Tentai - จอง, เช่า, ซื้อ, ขาย ด้วยคลิกเดียว!"
    },
    unsubscribe: {
        en: "Unsubscribe",
        ua: "Відписатись",
        ru: "Отписаться",
        fr: "Se désabonner",
        cn: "退订",
        th: "ยกเลิกการสมัคร"
    }
}