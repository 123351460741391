import React, { useState } from "react";
import { Form } from "react-bootstrap";
import eyeClose from "../../../utils/icons/eye-close.svg";
import eyeOpen from "../../../utils/icons/eye-open.svg";
import loginLogo from "../../../utils/icons/Logo.png";
import { useLoginMutation } from "../../store/apis/authApi";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { setFirstLogin } from "../../store/slices/userSlice";
import AlertModal from "../../components/AlertModal";
import { useTranslation } from "react-i18next";

const Login = () => {
  const [loginValue, setLoginValue] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const {t} = useTranslation()

  const reversePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const loginHandler = async () => {
    const tehDate = {
      login: loginValue,
      password,
    };
    try {
      const { data, error } = await login(tehDate);
      data?.token
        ? dispatch(setFirstLogin(true))
        : dispatch(setFirstLogin(false));
      error && setErrorMessage(error?.data?.message);
    } catch (e) {
      setErrorMessage(e);
    }
  };

  return (
    <div className="login">
      {errorMessage && (
        <AlertModal content={errorMessage} setErrorMessage={setErrorMessage} />
      )}
      {/* <div className="login-wallpaper">
        <img src={crm} alt="crm"></img>
      </div> */}
      <div className="login-wrapper">
        <div className="login-container">
          <img src={loginLogo} alt="logo" className="login-container_logo" />
          <h1 className="login-container_title">{t('welcome')}</h1>
          <div className="login-container_form">
            {/* <h2 className="login-container_form-subtitle">{t('enter')}</h2> */}
            <div className="login-container_form-inputs">
              {/**********************************************************Login*/}
              <Form.Group className="login-container_form-inputs_inp">
                <span className="login-container_form-inputs_label">{t('login')}</span>
                <Form.Control
                  type="text"
                  name="login"
                  className="login-container_form-inputs_login"
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  placeholder="login"
                  onChange={(e) => setLoginValue(e.target.value)}
                />
              </Form.Group>
              {/***********************************************************Password*/}
              <Form.Group className="login-container_form-inputs_inp">
                <span className="login-container_form-inputs_label">
                  {t('password')}
                </span>
                <div className="login-container_form-inputs_password-wrapper">
                  <span
                    onClick={reversePasswordType}
                    className="login-container_form-inputs_password-eye"
                    style={{ top: passwordType === "text" ? "15px" : "20px" }}
                  >
                    {passwordType === "password" && (
                      <img src={eyeClose} alt="Eye Close" />
                    )}
                    {passwordType === "text" && (
                      <img src={eyeOpen} alt="Eye Close" />
                    )}
                  </span>

                  <Form.Control
                    type={passwordType}
                    name="password"
                    className="login-container_form-inputs_password"
                    placeholder="********"
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </Form.Group>
            </div>
            <button
              type="button"
              className="login-container_form-button"
              onClick={loginHandler}
              disabled={isLoginLoading}
            >
              {isLoginLoading ? <Loader /> : t('log_in')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
