import React, {
	useEffect,
	useState
} from "react";
import { useSelector } from "react-redux"
import ControlPanelsForVersions from "../../components/environmentTabl/ControlPanelsForVersions"
import { PAGES_NAME } from "../../../utils/textsContent";

const VersionsPage = ( ) => {
	const { versions } = useSelector( state => state.versionsStore )
	// const [addEditModalShow, setAddEditModalShow] = useState(false)
	const [ versionsListArr, setVersionsListArr ] = useState( [] )

	useEffect( () => {
		setVersionsListArr( versions )
	}, [ versions ] )
	return (
		<div className='terms'>
			<h1 className='terms-title'>
				{PAGES_NAME?.VERSIONS}
			</h1 >
			<ControlPanelsForVersions
				bigArrElements={ versionsListArr }
				fromPage={ 'versions' }
			/>
		</div >
	)
}

export default VersionsPage
