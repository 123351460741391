import {
	Button,
	Form,
	Modal
} from "react-bootstrap"
import { Formik } from "formik"
import React, {
	useEffect,
	useState
} from "react"
import Loader from "../Loader"
import {
	useCreateUnitsMeasurementMutation,
	useEditUnitsMeasurementMutation,
	useGetUnitsMeasurementMutation
} from "../../store/apis/unitsMeasurementApi"

import AlertModal from "../AlertModal";
import {
	ARE_COMMON
} from "../../../utils/textsContent";
import BlockWithTranslationFieldsUnit from "../BlockWithTranslationFieldsUnit";
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const AddEditUnitsMeasurementItem = ({
	show,
	onHide,
	edit,
	editDateForModal
}) => {
	const [form, setForm] = useState({})
	const [createUnitsMeasurement, {isLoading: isCreateUnitsMeasurement}] = useCreateUnitsMeasurementMutation()
	const [editUnitsMeasurement, {isLoading: isEditUnitsMeasurement}] = useEditUnitsMeasurementMutation()
	const [getUnitsMeasurement, {isLoading: isUnitsMeasurementLoading}] = useGetUnitsMeasurementMutation()
	const [errorMessage, setErrorMessage] = useState('')
	const langs = useSelector(store => store.langStore.langs)
	const {t} = useTranslation()

	const isLoading = isCreateUnitsMeasurement || isEditUnitsMeasurement || isUnitsMeasurementLoading

	useEffect(() => {
		
		if(edit){
			setForm({...form, ...editDateForModal})
			langs?.forEach(el => {
				setForm(prev => {
					const newState = {...prev}
					newState[`${el.langIndex}Title`] = form.uaTitle || editDateForModal?.term?.[el.langIndex] || '';
					return newState
				})
				// initialState[`${el.langIndex}Title`] = form.uaTitle || form?.term?.[el.langIndex] || '';
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit, langs])
	
	const formDateUpdateHandler = (opt) => {
		setForm({...form, ...opt})
	}

	const handleSubmit = async (values) => {
		const terms = {}
		langs.forEach(el => {
			terms[el.langIndex] = form?.[`${el.langIndex}Title`]
		})
		const formDate = {
			term: terms,
		}
		
		try {
			if (edit) {
				const {error} = await editUnitsMeasurement({ ...formDate, id: editDateForModal.id })
				await getUnitsMeasurement()
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()

			} else {
				const { error } = await createUnitsMeasurement(formDate)
				await getUnitsMeasurement()
				error && setErrorMessage(error?.data?.message)
				!error && clearFormHideModal()

			}
		} catch (e) {
			setErrorMessage(e)
		}
	}

	const clearFormHideModal = () => {
		onHide()
		setForm({})
	}

	return (
		<Modal
			show={show}
			onHide={clearFormHideModal}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			className='add-edit-terms_modal'
			style={{zIndex: '99999'}}
		>
		{
			errorMessage && <AlertModal
				content={errorMessage}
				setErrorMessage={setErrorMessage}
			/>
		}
			<Formik
				initialValues={form}
				onSubmit={handleSubmit}
			>
			{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				isValid,
				handleSubmit,
				dirty
			}) => (<>
				<Modal.Header
					closeButton
				>
					<Modal.Title
						id='contained-modal-title-vcenter'
						className='add-edit-terms_title'
					>
						{edit
							? t('c_edit_unit')
							: t('c_create_unit') }
					</Modal.Title >
				</Modal.Header >
				<Modal.Body >
					<Form
						className='add-edit-terms'
						onSubmit={handleSubmit}
					>
						<div className='add-edit-terms_top'>
							<BlockWithTranslationFieldsUnit
								handleChange={handleChange}
								values={form}
								errors={errors}
								touched={touched}
								handleBlur={handleBlur}
								formDateUpdateHandler={formDateUpdateHandler}
								name={'Title'}
								title={'title'}
							/>
						</div >
					</Form >
				</Modal.Body >
				<Modal.Footer >
					<Button
						type='button'
						className='add-edit-terms_close'
						onClick={clearFormHideModal}
					>
						{t('cancel')}
					</Button >
					<Button
						type='submit'
						className='add-edit-terms_save d-inline-flex'
						style={{
							maxHeight: '33px',
							display: 'flex',
							alignItems: 'center'
						}}
						disabled={(!isValid && dirty)}
						onClick={handleSubmit}
					>
						{isLoading
							? <Loader />
							: t('save')}
					</Button >
				</Modal.Footer >
			</>)}
		</Formik >
	</Modal >
	)
}

export default AddEditUnitsMeasurementItem
