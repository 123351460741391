import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import AlertModal from "../../../components/AlertModal"
import {
	Form,
	Spinner
} from "react-bootstrap"
import { Formik } from "formik"
import DefaultImage from '../../../../utils/assets/images/defaultImage.png'
import PaperClipIcon from '../../../../utils/icons/paper-clip-icon.svg'
import Remove from "../../../../utils/icons/delete-red-icon.svg"
import Resizer from "react-image-file-resizer"
import {
	useCreateSubcategoryMutation,
	useEditSubcategoryMutation,
	useGetSubcategoryMutation
} from "../../../store/apis/subcategoryApi"
import {
	useAddImageCategoryMutation,
} from "../../../store/apis/categoryApi"
import { squeezeFiles } from "../../../../utils/helpers/CompressorFiles"
import ChangeItemDropdownList from "./ChangeItemInDropdownList"
import Loader from "../../../components/Loader"
import {
	ARE_COMMON
} from "../../../../utils/textsContent"
import BlockWithTranslationFieldsCategories from "../../../components/BlockWithTranslationFieldsCategories";
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { base64ToBlob } from '../../../../utils/helpers/base64ToBlob'

const initialState = {
	image: {},
	parent: '',
	category: '',
	status: false,
	title: {}
}

const CreateSubcategoryPage = ({onHide, edit, editDateForModal}) => {
	const [form, setForm] = useState(edit ? editDateForModal : initialState)
	const [errorMessage, setErrorMessage] = useState('')
	
	const [image, setImage] = useState(null)
	const [imageFile, setImageFile] = useState(null)

	const [compressorImageLoading, setCompressorImageLoading] = useState(false)

	const [arrSubcategoriesList, setArrSubcategoriesList] = useState([])
	const [subSubCategoriesList, setSubSubCategoriesList] = useState([])

	// const [categoriesAllList, setCategoriesAllList] = useState([])
	// const [subcategoriesAllList, setSubcategoriesAllList] = useState([])

	const [addImageCategory, {isLoading: isAddImageCategoryLoading}] = useAddImageCategoryMutation()
	const [getSubcategory, {isLoading: isGetSubcategoryLoading}] = useGetSubcategoryMutation()
	const [createSubcategory, {isLoading: isCreateSubcategoryLoading}] = useCreateSubcategoryMutation()
	const [editSubcategory, {isLoading: isEditSubcategoryLoading}] = useEditSubcategoryMutation()
	const dir = 'system'
	const {t, i18n} = useTranslation()

	const [changeBreadcrumbsValue, setChangeBreadcrumbsValue] = useState('')
	const [changeSubParentObj, setChangeSubParentObj] = useState({})
	const [changeParentObj, setChangeParentObj] = useState({})
	const [changeParentObj2, setChangeParentObj2] = useState({})
	const [changeParentObj3, setChangeParentObj3] = useState({})

	const langs = useSelector(store => store.langStore.langs)
	const categoriesAllList = useSelector(store => store.categoryStore.category)
	const subcategoriesAllList = useSelector(store => store.subcategoryStore.subcategory)

	const isLoading =
		isAddImageCategoryLoading ||
		isGetSubcategoryLoading ||
		isCreateSubcategoryLoading ||
		isEditSubcategoryLoading


	useEffect(() => {
			const changeItem = form?.parent
			resetBreadcrumbs()
			searchSub(changeItem)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[form?.parent, categoriesAllList, subcategoriesAllList])

	useEffect(() => {
			if (edit) {
				const changeItem = form?.id
				resetBreadcrumbs()
				searchSub(changeItem)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[edit, categoriesAllList, subcategoriesAllList])

	const searchSub = (id) => {
		for (let i = 0; i < subcategoriesAllList.length; i++) {
			if (subcategoriesAllList[i]?.id === id) {
				setChangeSubParentObj(subcategoriesAllList[i])
				searchSub1(subcategoriesAllList[i].parent)
			}
		}
	}
	const searchSub1 = (id) => {
		for (let i = 0; i < subcategoriesAllList.length; i++) {
			if (subcategoriesAllList[i]?.id === id) {
				setChangeParentObj(subcategoriesAllList[i])
				searchSub2(subcategoriesAllList[i].parent)
			}
		}
	}
	const searchSub2 = (id) => {
		for (let i = 0; i < subcategoriesAllList.length; i++) {
			if (subcategoriesAllList[i]?.id === id) {
				setChangeParentObj2(subcategoriesAllList[i])
				searchSub3(subcategoriesAllList[i].parent)
			}
		}
	}
	const searchSub3 = (id) => {
		for (let i = 0; i < categoriesAllList?.length; i++) {
			if (categoriesAllList[i]?.id === id) {
				setChangeParentObj3(categoriesAllList[i])
				searchSub(categoriesAllList[i].parent)
			}
		}
	}

	useEffect(() => {
		setChangeBreadcrumbsValue(
			`
			${changeParentObj3?.name?.ua ? changeParentObj3?.name?.ua : ''}
			${changeParentObj3?.name?.ua ? '/' : ''}
			${changeParentObj2?.title?.ua ? changeParentObj2?.title?.ua : ''}
			${changeParentObj2?.title?.ua ? '/' : ''}
			${changeParentObj?.title?.ua ? changeParentObj?.title?.ua : ''}
			${changeParentObj?.title?.ua ? '/' : ''}
			${changeSubParentObj?.title?.ua ? changeSubParentObj?.title?.ua : ''}
			`
		)
	}, [changeParentObj3, changeParentObj2, changeParentObj, changeSubParentObj, form, edit])

	const resetBreadcrumbs = () => {
		setChangeBreadcrumbsValue('')
		setChangeSubParentObj({})
		setChangeParentObj({})
		setChangeParentObj2({})
		setChangeParentObj3({})
	}

	const saveImageHandler = async (file) => {

		const newFormIcon = new FormData()
		newFormIcon.append('dir', dir)
		newFormIcon.append('image', file, `${file.name}`)

		const dataIcon = await addImageCategory(newFormIcon)
		return dataIcon
	}

	useEffect(() => {
		if (edit) {
			setImage(form?.image?.path)
		}
	}, [edit, form?.image?.path])

	const handleSubmit = async (values, {resetForm}) => {
		let error = false
		let idImage = null
		try {
			if (imageFile) {
				const response = await saveImageHandler(imageFile)
				if(response.error){
					setErrorMessage(response.error?.data?.status)
					error = response.error?.data?.status
					return
				}
				idImage = response.data?.data[0]?.id
			}
		} catch (e) {
			console.log(e)
		}

		if(error) return

		const names = {}
		langs?.forEach(el => {
			names[el.langIndex] = form?.title[`${el.langIndex}`];
		})

		const formDate = {
			title: names,
			image: idImage || null,
			category: form?.category,
			parent: form?.parent ? form?.parent : form?.category,
			status: form?.status || false
		}

		try {
			if (edit) {
				const {error} = await editSubcategory({
					...formDate,
					id: editDateForModal.id
				})
				error && setErrorMessage(error?.data?.message)
				!error && resetForm()
				!error && clearFormHideModal()
				!error && getSubcategory({sortingBy: '?orderBy=asc&sortingBy=natural'})
			} else {
				const {
					error
				} = await createSubcategory(formDate)
				error && setErrorMessage(error?.data?.message)
				!error && resetForm()
				!error && clearFormHideModal()
				!error && getSubcategory({sortingBy: '?orderBy=asc&sortingBy=natural'})
			}
		} catch (e) {
			setErrorMessage(e)
		}
	}
	const formDateUpdateHandler = (opt) => setForm({...form, ...opt})
	const clearFormHideModal = () => {
		onHide()
		setForm(initialState)
	}

	const resizeFile = async (file, type) => {
		Resizer.imageFileResizer(
			file,
			250,
			180,
			"JPEG",
			100,
			0,
			(uri) => {
				type === 'image' && setImage(uri)
			},
			"base64",
			50,
			50
		)
	}

	const compressorFileImage = async (file) => {
		setCompressorImageLoading(true)
		const tehFile = await squeezeFiles(file)
		setImageFile(tehFile)
		setCompressorImageLoading(false)
	}


	const subCategorySearch = useCallback((id) => {
		const resultSub = subcategoriesAllList.filter(sub => sub.parent === id)
		return resultSub
	},[subcategoriesAllList])

	useEffect(() => {
			let arrAll = []
			let subArrAll = []
			let res = []
			let subRes = []
			for (let i = 0; i < categoriesAllList?.length; i++) {
				res = subCategorySearch(categoriesAllList[i]?.id)
				arrAll = [...arrAll, res]

				for (let i = 0; i < res.length; i++) {
					subRes = subCategorySearch(res[i].id)
					subArrAll = [...subArrAll, subRes]
				}
				setSubSubCategoriesList(subArrAll)
			}
			setArrSubcategoriesList(arrAll)
		},
		[categoriesAllList, subcategoriesAllList, subCategorySearch]
	)

	const removeImage = () => {
		setImage(null)
		setImageFile(null)
		setForm({...form, image: null})
	}

	function setInitialValues(){
		const values = {}
		langs.forEach(el => {values[`${el.langIndex}Title`] = form?.[`${el.langIndex}Title`] || form?.title?.[`${el.langIndex}`] || ''})
		return values
	}

	return (
		<div className='template-content'>
			{
				errorMessage
				&& <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}

			<Formik
				initialValues={setInitialValues()}
				onSubmit={handleSubmit}
			>
				{
					({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty
					}) => (
						<Form
							className='add-edit-terms categories-create'
							onSubmit={handleSubmit}
						>
							<div className='categories-create_block'>
								<BlockWithTranslationFieldsCategories
									handleChange={handleChange}
									values={form.title}
									setForm={setForm}
									errors={errors}
									touched={touched}
									handleBlur={handleBlur}
									formDateUpdateHandler={formDateUpdateHandler}
									name={'Title'}
									title={'title'}
								/>
							</div >

							<div className='categories-create_block'>
								<Form.Group
									className='categories-create_block-wrapper'
								>
									<p className='categories-create_block-title'>{t('cc_choose_image')}</p >
									<div className={`${image ? 'categories-create_block-showImage' : 'categories-create_block-addImage'}`}>
										{
											compressorImageLoading
												?
												<Spinner
													animation='grow'
													variant='secondary'
												/>
												:
												image
													?
													<div className='categories-create_block-addImage_photo'>
												<img
													src={edit ? form?.image === null ? 
														image : URL.createObjectURL(base64ToBlob(form.image.file, form.image.mimetype)) : image}
													alt='default_image'
													className='categories-create_block-addImage_photo-img'
												/>
													<button
														type='button'
														className='categories-create_block-addImage_photo-del'
														onClick={removeImage}
													>
													<img
														src={Remove}
														alt='delete_Image'
														title='delete image'
													/>
												</button >
												</div >
													:
													<>
													<Form.Control
														type='file'
														name='image_category'
														accept='image/png, image/jpeg, image/webp, image/jpg, image/svg, image/pdf, image/xls, image/xls'
														autoFocus
														className='categories-create_block-addImage_file'
														onChange={e => {
															resizeFile(e.target.files[0], 'image')
															compressorFileImage(e.target.files[0])
														}}
													/>
														<img
															src={DefaultImage}
															alt='default_image'
															className='categories-create_block-addImage_img'
														/>
														<div className='categories-create_block-addImage_button terms-wrapper_addButton'>
															<img
																src={PaperClipIcon}
																alt='paper clip'
															/>
															{t('select_file')}
														</div >
														<p className='categories-create_block-addImage_subtext'>
															{t('supports_format')}: WEBP, PNG, JPG
														</p >
												</>
										}

									</div >
								</Form.Group >

							</div >

							<div className='categories-create_block'>
								<Form.Group
									className='template-content_nameBlock-label'
								>
									<p className='categories-create_block-title'>{t('c_add_chooseCategory')}</p >
									{
										<select
											name='category'
											onBlur={handleBlur}
											defaultValue={form?.category}
											className={`template-content_typeBlock-select ${errors?.category && touched?.category ? "is-touch " : ""} ${
												errors?.category && touched?.category ? " is-invalid" : ""
											} `}
											onChange={e => {
												handleChange(e)
												formDateUpdateHandler({
													[e.target.name]: Number(e.target.value)
												})
											}}
										>
											<option ></option >
											{categoriesAllList && categoriesAllList?.map(item => (
													<option
														value={item?.id}
														key={item?.id}
														selected={edit && form?.category === item?.id}
													>
												{item?.name?.[i18n.language]}
											</option >
												)
											)}
									</select >
									}
									{errors.category && touched.category && (
										<Form.Control.Feedback type='invalid'>
											{errors.category}
										</Form.Control.Feedback >
									)}
							</Form.Group >
							</div >

							<Form.Group className='template-content_category-body_breadcrumbs'>
								<p className='template-content_category-body_breadcrumbs-label'>
									{t('category')}
								</p >
								<p
									className={`template-content_category-body_breadcrumbs-text ${form?.subcategory?.length === 0 && errors?.subcategory ? "is-touch border-danger" : ""} ${
										errors?.subcategory ? "is-invalid" : ""}`}
									onBlur={handleBlur}
									style={{minWidth: '300px', backgroundColor: edit ? !form?.dublicate && '#e9ecef' : ''}}
								>
									{changeBreadcrumbsValue?.length > 0 ? changeBreadcrumbsValue : t('c_add_chooseCategory')}
								</p >
							</Form.Group >

							<div className='categories-create_block'>
								<p className='categories-create_block-title'>{t('cs_nesting_subcategories')}</p >
								<div className='template-content_category-body_list categories-create_block-body_list '>
									{
										categoriesAllList
										&&
										categoriesAllList?.map(category =>
											(
												<ChangeItemDropdownList
													handleBlur={handleBlur}
													handleChange={handleChange}
													values={values}
													touched={touched}
													errors={errors}
													category={category}
													key={category?.id}
													idCategory={category?.id}
													arrSubcategoriesList={arrSubcategoriesList}
													subSubCategoriesList={subSubCategoriesList}
													subcategory={subcategoriesAllList}
													formDateUpdateHandler={formDateUpdateHandler}
													form={form}
													currentCategory={editDateForModal.id}
												/>
											))
									}
								</div >
								<Form.Check
									className='noticeItem-wrapper_status-moderation_item-body_block-checkbox p-0'
									type='checkbox'
									label={'Status'}
									id={'Status'}
									onKeyDown={e => {
										e.key === 'Enter' && e.preventDefault()
									}}
									defaultChecked={form?.status}
									name={'status'}
									onChange={e => {
										handleChange(e)
										formDateUpdateHandler({
											[e.target.name]: e.target.checked
										})
									}}
								/>
							</div >
							<div className='categories-create_block-buttons'>
								<button
									className='template-content_buttons-save btn btn-primary'
									disabled={isLoading}
									type='submit'
								>
									{isLoading ? <Loader /> : t('save')}
								</button >
								<button
									className='template-content_buttons-close btn btn-primary'
									onClick={onHide}
								>
									{t('back')}
								</button >
							</div >
						</Form >
					)}
			</Formik >
		</div >
	)
}

export default CreateSubcategoryPage