import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	SUBCATEGORY_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const subcategoryApi = createApi( {
	reducerPath: "subcategoryApi",
	baseQuery: fetchBaseQuery( {
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{ getState }
		) => {
			const { token } = getState().userStore;
			if ( token ) {
				headers.set( "Authorization", `Bearer ${ token }` )
			}
			return headers
		}
	} ),
	endpoints: ( builder ) => (
		{
			getSubcategory: builder.mutation( {
				query: ( { sortingBy, orderBy, list, parent } ) => (
					{
						url: `${ SUBCATEGORY_API.GET_SUBCATEGORY }
					${ sortingBy ? sortingBy : '' }
					${ orderBy ? orderBy : '' }
					${ list ? list : '' }
					${ parent ? parent : '' }
					`,
						method: "GET",
					}
				)
			} ),
			createSubcategory: builder.mutation( {
				query: ( body ) => (
					{
						url: SUBCATEGORY_API.CREATE_SUBCATEGORY,
						method: "POST",
						body
					}
				)
			} ),
			changePositionSubcategoryElement: builder.mutation( {
				query: ( body ) => (
					{
						url: SUBCATEGORY_API.CHANGE_POSITION_SUBCATEGORY_ELEMENT,
						method: "POST",
						body
					}
				)
			} ),
			editSubcategory: builder.mutation( {
				query: ( body ) => (
					{
						url: SUBCATEGORY_API.EDIT_SUBCATEGORY,
						method: "POST",
						body
					}
				)
			} ),
			deleteSubcategory: builder.mutation( {
				query: ( body ) => (
					{
						url: SUBCATEGORY_API.DELETE_SUBCATEGORY,
						method: "POST",
						body
					}
				)
			} ),
			migrationSubcategoriesToProd: builder.mutation( {
				query: ( body ) => (
					{
						url: SUBCATEGORY_API.MIGRATION_TO_PROD_SUBCATEGORIES,
						method: "POST",
						body
					}
				)
			} ),
		}
	)
} )

export const {
	useGetSubcategoryMutation,
	useCreateSubcategoryMutation,
	useEditSubcategoryMutation,
	useDeleteSubcategoryMutation,
	useChangePositionSubcategoryElementMutation,
	useMigrationSubcategoriesToProdMutation
} = subcategoryApi
