import { useLocation, useNavigate } from "react-router-dom"
import { ARE_COMMON } from "../../../../utils/textsContent"
import { Button, Form } from "react-bootstrap"
import { useState } from "react"
import { APP_ROUTE_PAGES } from "../../../../utils/constants"
import { useDispatch } from "react-redux"
import { resetDateChangeTerms, showChangeTermsModal } from "../../../store/slices/termsSlice"
import { resetDateChangeUnitPrice, resetDateChangeUnitPriceArr, showChangeUnitPriceModal } from "../../../store/slices/priceSlice"
import { resetDateChangeUnitAmount, showChangeUnitAmountModal } from "../../../store/slices/amountSlice"
import { resetDateChangeUnitTemplates, resetDateChangeUnitTemplatesList, showChangeUnitModalTemplates } from "../../../store/slices/temlateContentSlice"
import { resetAdditionalBlockList, resetContentBlockList, resetDateChangeUnitTemplatesListArr, resetDetailInfoBlockList, resetMainBlockList, showChangeTemplateContentTemplates } from "../../../store/slices/templateSlice"
import { resetDateChangeMark, showChangeMark } from "../../../store/slices/markSlice"
import { resetDateChangeTransportCategory, showChangeTransportCategory } from "../../../store/slices/transportCategorySlice"
import { resetDateChangeBodyType, showChangeBodyType } from "../../../store/slices/bodyTypeSlice"
import { setDateChangeUnitGroup, setDateChangeUnitGroupAmount, setDateChangeUnitGroupVolume } from "../../../store/slices/unitGroupsSlice"
import AddEditBuilderTemplate from "./AddEditBuilderTemplate"
import AddEditBuilderMiddleTemplate from "./AddEditBuilderMiddleTemplate"
import { useTranslation } from "react-i18next"

const AddEditBuilderHeaderTemplate = () => {

    const location = useLocation()
    const editDateForModal = location?.state
	const edit = location?.state === null ? false : true
    const navigate = useNavigate()
    const dispatch = useDispatch()
	const {t} = useTranslation()

    const [templateType, setTemplateType] = useState(editDateForModal?.isMiddle ? "middle" : "main")

    const clearFormHideModal = () => {
		navigate(`${APP_ROUTE_PAGES.TEMPLATES}`)
		dispatch(showChangeTermsModal(false))
		dispatch(resetDateChangeTerms())
		dispatch(showChangeUnitPriceModal(false))
		dispatch(resetDateChangeUnitPrice())
		dispatch(showChangeUnitAmountModal(false))
		dispatch(resetDateChangeUnitAmount())
		dispatch(showChangeUnitModalTemplates(false))
		dispatch(resetDateChangeUnitTemplates())
		dispatch(resetDateChangeUnitTemplatesList())
		dispatch(showChangeTemplateContentTemplates(false))
		dispatch(resetDateChangeUnitTemplatesListArr())
		dispatch(showChangeMark(false))
		dispatch(resetDateChangeMark())
		dispatch(showChangeTransportCategory(false))
		dispatch(resetDateChangeTransportCategory())
		dispatch(showChangeBodyType(false))
		dispatch(resetDateChangeBodyType())
		dispatch(resetContentBlockList())

		dispatch(resetMainBlockList())
		dispatch(resetDetailInfoBlockList())
		dispatch(resetAdditionalBlockList())
		dispatch(resetDateChangeUnitPrice())
		dispatch(resetDateChangeUnitPriceArr())
		dispatch(setDateChangeUnitGroup(""))
		dispatch(setDateChangeUnitGroupAmount(""))
		dispatch(setDateChangeUnitGroupVolume(""))
	}
    
	return (
		<div className="terms">
			<div className='template-content'>
				<h1 className='terms-title d-flex justify-content-between' style={{marginBottom: 20}}>
					{edit ? t('c_edit_title') : t('c_add_title')}
					<Button
						type='button'
						className='template-content_buttons-close'
						onClick={clearFormHideModal}
					>
						{t('cancel')}
					</Button >
				</h1 >
                <p style={{marginBottom: 10}}>{t('c_add_chooseTemplateType')}</p>
                <Form.Select 
                    onChange={(el)=>setTemplateType(el.target.value)}
                    style={{marginBottom: 30}}
                    disabled={edit}
                >
                    <option value="main">Main</option>
                    <option selected={editDateForModal?.isMiddle} value="middle">Middle</option>
                </Form.Select>
                { templateType === "main" && <AddEditBuilderTemplate templateType={templateType}/> }
                { templateType === "middle" && <AddEditBuilderMiddleTemplate templateType={templateType}/> }
			</div >
		</div>
	)
}

export default AddEditBuilderHeaderTemplate