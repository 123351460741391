import { createSlice } from "@reduxjs/toolkit"
import { transportCategoryApi } from "../apis/transportCategoryApi"
import { UniqueArrItems } from "../../../utils/helpers/UniqueArrItems";

const initialState = {
	transportCategory: [],
	howModalChangeTransportCategory: false,
	dateModalChangeTransportCategory: []
}

const transportCategorySlice = createSlice( {
	name: "transportCategory",
	initialState,
	reducers: {
		setTransportCategoryList: ( state, action ) => {
			state.transportCategory = action.payload
		},
		createNewTransportCategoryItem: ( state, action ) => {
			state.transportCategory = [ action.payload, ...state.transportCategory ]
		},
		editTransportCategoryItem: ( state, action ) => {
			const index = state.transportCategory.findIndex(
				( term ) => {
					return term.id === action.payload.id
				}
			)
			state.transportCategory[index] = action.payload
		},
		deleteTransportCategoryItem: ( state, action ) => {
			const termId = action.payload
			const termID = state.transportCategory.filter( term => term.id !== termId )
			state.transportCategory = termID
		},
		showChangeTransportCategory: ( state, action ) => {
			state.showModalChangeTransportCategory = action.payload
		},
		setDateChangeTransportCategory: ( state, action ) => {
			const uniq = UniqueArrItems( [ ...state.dateModalChangeTransportCategory, action.payload ] )
			state.dateModalChangeTransportCategory = uniq
		},
		resetDateChangeTransportCategory: ( state, action ) => {
			state.dateModalChangeTransportCategory = []
		},
		deleteDateChangeTransportCategoryArr: ( state, action ) => {
			const Id = Number( action.payload )
			const ID = state.dateModalChangeTransportCategory.filter( item => Number( item ) !== Id )
			state.dateModalChangeTransportCategory = ID
		},
	},
	extraReducers: ( builder ) => {
		builder
			.addMatcher( transportCategoryApi.endpoints.getTransportCategory.matchFulfilled, (
					state,
					action
				) => {
					state.transportCategory = action.payload.data
				}
			)
	}
} )

const {
	actions,
	reducer
} = transportCategorySlice

export const {
	setTransportCategoryList,
	createNewTransportCategoryItem,
	deleteTransportCategoryItem,
	editTransportCategoryItem,
	resetDateChangeTransportCategory,
	setDateChangeTransportCategory,
	showChangeTransportCategory,
	deleteDateChangeTransportCategoryArr
} = actions
export default reducer
