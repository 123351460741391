import { createSlice } from "@reduxjs/toolkit"
import { modelApi } from "../apis/modelApi"

const initialState = {
	model: [],
	showModalChangeMarkForModel: false,
	dateModalChangeMarkForModel: ''
}

const modelSlice = createSlice({
	name: "model",
	initialState,
	reducers: {
		setModelList: (state, action) => {
			state.model = action.payload
		},
		createNewModelItem: (state, action) => {
			state.model = [action.payload, ...state.model]
		},
		editModelItem: (state, action) => {
			const index = state.model.findIndex(
				(term) => {
					return term.id === action.payload.id
				}
			)
			state.model[index] = action.payload
		},
		deleteModelItem: (state, action) => {
			const termId = action.payload
			const termID = state.model.filter(term => term.id !== termId)
			state.model = termID
		},
		showChangeMarkForModelModel: (state, action) => {
			state.showModalChangeMarkForModel = action.payload
		},
		setDateChangeMarkForModel: (state, action) => {
			state.dateModalChangeMarkForModel = action.payload
		},
		resetDateChangeMarkForModel: (state, action) => {
			state.dateModalChangeMarkForModel = ''
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(modelApi.endpoints.getModel.matchFulfilled, (
					state,
					action
				) => {
					state.model = action.payload.data
				}
			)
	}
})

const {
	actions,
	reducer
} = modelSlice
export const {
	setModelList,
	createNewModelItem,
	deleteModelItem,
	editModelItem,
	showChangeMarkForModelModel,
	setDateChangeMarkForModel,
	resetDateChangeMarkForModel
} = actions
export default reducer
