import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	RECALCULATION_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;


export const recalculationApi = createApi({
	reducerPath: "recalculationApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		getRecalculation: builder.mutation({
			query: () => (
				{
					url: RECALCULATION_API.GET_RECALCULATION,
					method: "GET",
				})
		}),
	})
});

export const {
	useGetRecalculationMutation,


} = recalculationApi
