import React from 'react'
import { Table } from "react-bootstrap"
import RbacTablWorkerItem from "./RbacTablWorkerItem";
import SearchItemsInput from '../../../components/SearchItemsInput';
import { useState } from 'react';
import { useGetAdminsQuery } from '../../../store/apis/rbacApi';
import Loader from '../../../components/LoaderContent';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';

const RbacTablWorker = ( {setCurrentUser, setRoleModal} ) => {

	const [search, setSearch] = useState('')
	const [searchTemp, setSearchTemp] = useState('')
	const [deleteModalShow, setDeleteModalShow] = useState(false)
	const [deleteTerm, setDeleteTerm] = useState({})
	const {t} = useTranslation()

	const {data: users, isFetching: isUsersLoading} = useGetAdminsQuery({search: search})

	const toggleDeleteModal = (value) => {
		setDeleteTerm(value)
		setDeleteModalShow(!deleteModalShow)
	}
	
	return (
		<>
			<div className='terms-wrapper_search'>
				<SearchItemsInput
					searchHandler={()=>setSearch(searchTemp)}
					valueSearchInputHandler={(value)=>setSearchTemp(value)}
					toggleModalSearch={()=>{}}
				/>
				
			</div >

			<DeleteModal
				show={deleteModalShow}
				onHide={toggleDeleteModal}
				value={deleteTerm}
				fromPage={'rbacUsers'}
			/>

			<Table
				responsive
				className='users-wrapper_tabl'
				striped={ true }
			>
				<thead className='users-wrapper_tabl-head'>
				<tr className='users-wrapper_tabl-trow'>
					<th
						className='users-wrapper_tabl-trow_item col-4'
						// onClick={ () => sortedForColumn( 2 ) }
					>
						<span >
							Employee
						</span >
					</th >
					<th
						className={ `users-wrapper_tabl-trow_item col-2` }
						// onClick={ () => sortedForColumn( 1 ) }
					>
						<span >
							Position
						</span >
					</th >
					<th
						className='users-wrapper_tabl-trow_item col-3'
						// onClick={() => sortedForColumnAnother('category')}
					>
						<span >
							Еmail
						</span >
					</th >
					<th
						className='users-wrapper_tabl-trow_item col-2'
						// onClick={() => sortedForColumnAnother('subcategory')}
					>
						<span >
							Login
						</span >
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1 text-center'
						// onClick={() => sortedForColumnAnother('template')}
					>
						<span >
							Action
						</span >
					</th >
				</tr >
				</thead >
				<tbody className='users-wrapper_tabl-body'>
				{
					isUsersLoading? <Loader/> :
					users?.map((el, index) => (<RbacTablWorkerItem key={index} user={ el } setCurrentUser={setCurrentUser} toggleDeleteModal={toggleDeleteModal} setRoleModal={setRoleModal}/>))
				}
				</tbody >
			</Table >
		</>
	)
}

export default RbacTablWorker