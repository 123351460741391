import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import AlertModal from '../AlertModal';
import { ARE_COMMON } from '../../../utils/textsContent';
import { useCreateLanguageMutation, useUpdateLanguageMutation } from '../../store/apis/langApi';
import Loader from '../../components/Loader'
import { useTranslation } from 'react-i18next';

export const AddEditLanguageItem = ({formData, setShowAddModal})=>{

    const [form, setForm] = useState(formData ? formData : {})
	const [errorMessage, setErrorMessage] = useState('')
	const {t} = useTranslation()

	const [createLanguage, {isLoading: isCreateLoading}] = useCreateLanguageMutation()
	const [updateLanguage, {isLoading: isUpdateLoading}] = useUpdateLanguageMutation()

	async function handleSubmit(){
		let error = false
		
		if(!form.lang || !form.lang?.length || !form.langIndex || !form.langIndex?.length) error = 'Будь ласка, заповнiть всi поля.'

		if(error) {
			setErrorMessage(error)
			return
		}

		let response
		if(formData){
			response = await updateLanguage({id: formData.id, lang: form.lang, langIndex: form.langIndex, active: form.active})
		}
		else{
			response = await createLanguage({lang: form.lang, langIndex: form.langIndex, active: form.active})
		}

		if(response.error){
			setErrorMessage(response.error?.data?.message)
		}
		else if(response.data?.message === 'success'){
			setShowAddModal(false)
		}
		else{
			setErrorMessage(response.data?.message)
		}
	}

    return(
		<>
		{
			errorMessage
			&&
			<AlertModal
				content={ errorMessage }
				setErrorMessage={ setErrorMessage }
			/>
		}
        <Modal
			show={true}
			onHide={()=>{setShowAddModal(false)}}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className='add-edit-terms_modal'
			style={{ zIndex: '99999' }}
		>
			{
				errorMessage && <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}	
			<Modal.Header
				closeButton
			>
				<Modal.Title
					id="contained-modal-title-vcenter"
					className='add-edit-terms_title'
				>
					{formData ? t('_c_edit_lang') : t('_c_create_lang')}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					className="add-edit-terms"
				>
					<div className="add-edit-terms_top">
                        <Form.Group
                                className='add-edit-terms_label'
                            >
                                <h3 className='subscription-create_wrapper-date_block-title'>Title</h3>
                                <Form.Control
                                    type='text'
                                    name={`Title`}
                                    value={form?.lang}
                                    onKeyDown={e => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    className={`add-edit-terms_inp`}
                                    onChange={(e) => {
										setForm(prev => {return {...prev, lang: e.target.value}})
									}}
                                />
						</Form.Group >
                        <Form.Group
                                className='add-edit-terms_label'
                            >
                                <h3 className='subscription-create_wrapper-date_block-title'>Index</h3>
                                <Form.Control
                                    type='text'
                                    name={`Index`}
                                    value={form?.langIndex}
                                    onKeyDown={e => {
                                        e.key === 'Enter' && e.preventDefault()
                                    }}
                                    className={`add-edit-terms_inp`}
                                    onChange={(e) => {
										setForm(prev => {return {...prev, langIndex: e.target.value}})
									}}
                                />
						</Form.Group >
                        <Form.Check
                            className='noticeItem-wrapper_status-moderation_item-body_block-checkbox p-0'
                            type='checkbox'
                            label={"Active"}
                            id={'requiredStatus'}
                            onKeyDown={e => {
                                e.key === 'Enter' && e.preventDefault()
                            }}
                            defaultChecked={form?.active}
                            name={'requiredStatus'}
                            onChange={e => {
								setForm(prev => {return {...prev, active: e.target.checked}})
							}}
                        />
					</div>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button
					type='button'
					className='add-edit-terms_close'
					onClick={()=>setShowAddModal(false)}
				>
					{t('cancel')}
				</Button>
				<Button
					type='submit'
					className='add-edit-terms_save d-inline-flex'
					style={{
						maxHeight: '33px',
						display: 'flex',
						alignItems: 'center'
					}}
					onClick={handleSubmit}
				>
                    {isCreateLoading || isUpdateLoading ? <Loader/> : t('save')}
				</Button>
			</Modal.Footer>
		</Modal>
		</>
    )
}