import React, { useEffect, useRef, useState } from 'react';
import s from './editEmailTemplate.module.scss';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateEmailTemplateMutation, useEditEmailTemplatesMutation, useGetEmailTemplateV2Query, useGetEmailTemplatesQuery } from '../../../store/apis/notificationApi';
import { ConfigureEmail } from '../../../../utils/helpers/configureEmail';
import AlertModal from '../../../components/AlertModal';
import Loader from '../../../components/LoaderContent';
import Form from 'react-bootstrap/Form';
import { TextFieldsBlock } from '../../../components/environmentTabl/TextFieldsBlock/TextFieldsBlock';
import { checkTextFields } from '../../../../utils/helpers/checkTextFilelds';
import { useTranslation } from 'react-i18next';

const initialState = {
    description: "",
    title: {},
    body: {},
    inboxTitle: {},
    inboxBody: {},
    emailSend: true,
    inboxSend: true,
}

export const EditEmailTemplate = ()=>{

    const [template, setTemplate] = useState()
    // const [data, setData] = useState()
    const [currentTemplate, setCurrentTemplate] = useState(false)
    const [lang, setLang] = useState(false)
    const [form, setForm] = useState(initialState)
    const [errorMessage, setErrorMessage] = useState(false)

    const langs = useSelector(store => store.langStore.langs)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [searchParams] = useSearchParams()
    const templateId = searchParams.get('id')

    const iFrame = useRef()

    const {data: dataQuery, isLoading: isTemplatesLoading} = useGetEmailTemplatesQuery({id: `id=${templateId}`})
    const {data: templateQuery, isLoading: isTemplateLoading} = useGetEmailTemplateV2Query({})
    const [updateQuery, {isLoading: isUpdateLoading}] = useEditEmailTemplatesMutation()
    const [createQuery, {isLoading: isCreateLoading}] = useCreateEmailTemplateMutation()

    useEffect(()=>{
        if(langs) setLang(langs?.[0]?.langIndex)
    },[langs]);

    useEffect(()=>{
        if(templateQuery) setTemplate(templateQuery)
    },[templateQuery]);

    useEffect(()=>{
        if(dataQuery){
            // setData(dataQuery?.templates?.[0]?.template)
            setForm({...form, ...dataQuery?.templates?.[0]})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dataQuery]);

    useEffect(()=>{
        if(template || lang){
            const newTemplate = ConfigureEmail({
                template, 
                data: {
                    body: form?.body?.[lang],
                    title: form?.title?.[lang]
                },
                lang
            })
            setCurrentTemplate(newTemplate)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[form, lang]);

    function changeInboxAndEmailStatuses(target ,value){
        setForm({...form, [target]: value})
    }

    function changeData(value){
        setForm({...form, body: {...form.body, [lang]: value}})
    }

    function changeDescription(value){
        setForm({...form, description: value})
    }

    function changeTitle(value){
        setForm(prev => {return {...prev, title: {...prev.title, ...value}}})
    }

    function changeInboxTitle(value){
        console.log("value", value)
        setForm(prev => {return {...prev, inboxTitle: {...prev.inboxTitle, ...value}}})
    }

    function changeInboxBody(value){
        setForm(prev => {return {...prev, inboxBody: {...prev.inboxBody, ...value}}})
    }

    async function handleSubmit(){
        if(!form.description || !form.description.length){
            setErrorMessage("Please fill in the Description")
            return
        }
        // ### Check Email data
        if(form.emailSend){
            if(!checkTextFields(form.title, langs)){
                setErrorMessage("Please fill in the Email Title")
                return
            }
            if(!checkTextFields(form.body, langs)){
                setErrorMessage("Please fill in the template body")
                return
            }
        }
        // ### Check Inbox data
        if(form.inboxSend){
            if(!checkTextFields(form.inboxTitle, langs)){
                setErrorMessage("Please fill in the template title")
                return
            }
            if(!checkTextFields(form.inboxBody, langs)){
                setErrorMessage("Please fill in the inbox body")
                return
            }
        }
        
        let response
        if(templateId){
            response = await updateQuery({
                title: form.title,
                body: form.body,
                description: form.description,
                id: templateId,
                inboxTitle: form.inboxTitle,
                inboxBody: form.inboxBody,
                emailSend: form.emailSend,
                inboxSend: form.inboxSend
            })
        }else{
            response = await createQuery({
                title: form.title,
                body: form.body,
                description: form.description,
                inboxTitle: form.inboxTitle,
                inboxBody: form.inboxBody,
                emailSend: form.emailSend,
                inboxSend: form.inboxSend
            })
        }
        if(response.error){
            setErrorMessage(response.error.data?.message)
        }else{
            navigate('/email-templates')
        }
    }

    if(isTemplatesLoading || isTemplateLoading || isUpdateLoading || isCreateLoading) return <Loader/>
    return(
        <div className={`terms`}>
            {
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
            <h1 className='terms-title'>{ templateId ? t('_c_editEmailTemplate') : t('_c_createEmailTemplate') }</h1 >
            <div className={s.page}>
                <div className={s.header}>
                    <div className={s.buttons}>
                        <button
                            type='button'
                            className='template-content_buttons-save btn btn-primary'
                            onClick={handleSubmit}
                            >
                                {t('save')}
                        </button >
                        <button
                            type='button'
                            className='template-content_buttons-save btn btn-primary'
                            onClick={()=> navigate('/email-templates')}
                            >
                                {t('back')}
                        </button >
                    </div>
                </div>

                <div class="add-edit-htmls_center">
                    Desciption
                    <input placeholder="Template name" type="text" class="add-edit-htmls_center-inp form-control" value={form.description} onChange={(e)=>changeDescription(e.target.value)}></input>
                </div>

                <Form.Check
                    checked={form.inboxSend}
                    type={"checkbox"}
                    label={`Inbox`}
                    onChange={(ev) => changeInboxAndEmailStatuses('inboxSend', ev.target.checked)}
                />
                <div className={s.content_wrapper}>
                    <div className={s.text_fileds_block}>
                        <TextFieldsBlock values={form.inboxTitle} title="Inbox title" changeValue={changeInboxTitle}/>
                    </div>
                    
                    <div className={s.text_fileds_block}>
                        <TextFieldsBlock values={form.inboxBody} title="Inbox Body" changeValue={changeInboxBody}/>
                    </div>
                </div>

                <Form.Check
                    checked={form.emailSend}
                    type={"checkbox"}
                    label={`Email`}
                    onChange={(ev) => changeInboxAndEmailStatuses('emailSend', ev.target.checked)}
                />
                <div className={s.content_wrapper}>
                    <div className={s.text_fileds_block}>
                        <TextFieldsBlock values={form.title} title="Email title" changeValue={changeTitle} setLang={setLang}/>
                    </div>
                    <div className={s.content}>
                        <div className={s.left}>
                            <textarea value={form?.body?.[lang]} className={s.textarea} onChange={(e)=>changeData(e.target.value)}></textarea>
                        </div>
                        <div className={s.right}>
                            {
                            (currentTemplate) ?
                                <iframe srcDoc={currentTemplate} title="message" name="message" width="100%" height="1000px" frameBorder="0" ref={iFrame}></iframe> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}