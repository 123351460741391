import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	CATEGORY_API,
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const categoryApi = createApi( {
	reducerPath: "categoryApi",
	baseQuery: fetchBaseQuery( {
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{ getState }
		) => {
			const { token } = getState().userStore;
			if ( token ) {
				headers.set( "Authorization", `Bearer ${ token }` )
			}
			return headers
		}
	} ),
	endpoints: ( builder ) => (
		{
			getCategory: builder.mutation( {
				query: ( sortingBy, orderBy, list ) => (
					{
						url: `${ CATEGORY_API.GET_CATEGORY }?
					${ sortingBy ? sortingBy : '' }
					${ orderBy ? orderBy : '' }
					${ list ? list : '' }
					`,
						method: "GET",
					}
				)
			} ),
			createCategory: builder.mutation( {
				query: ( body ) => (
					{
						url: CATEGORY_API.CREATE_CATEGORY,
						method: "POST",
						body
					}
				)
			} ),
			editCategory: builder.mutation( {
				query: ( body ) => (
					{
						url: CATEGORY_API.EDIT_CATEGORY,
						method: "POST",
						body
					}
				)
			} ),
			deleteCategory: builder.mutation( {
				query: ( body ) => (
					{
						url: CATEGORY_API.DELETE_CATEGORY,
						method: "POST",
						body
					}
				)
			} ),
			changePositionElement: builder.mutation( {
				query: ( body ) => (
					{
						url: CATEGORY_API.CHANGE_POSITION_CATEGORY_ELEMENT,
						method: "POST",
						body
					}
				)
			} ),
			addImageCategory: builder.mutation( {
				query: ( body, bound ) => (
					{
						url: CATEGORY_API.ADD_IMAGE_CATEGORY,
						method: "POST",
						headers: {
							"Access-Control-Allow-Origin": "*",
						},
						body: body
					}
				)
			} ),
			migrationCategoriesToProd: builder.mutation( {
				query: ( body ) => (
					{
						url: CATEGORY_API.MIGRATION_TO_PROD_SUBCATEGORIES,
						method: "POST",
						body
					}
				)
			} ),
		}
	)
} );

export const {
	useGetCategoryMutation,
	useCreateCategoryMutation,
	useEditCategoryMutation,
	useDeleteCategoryMutation,
	useAddImageCategoryMutation,
	useChangePositionElementMutation,
	useMigrationCategoriesToProdMutation
} = categoryApi
