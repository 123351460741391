import addPlus from "../../../utils/icons/addPlus.svg"

const AddButton = ({handler, title}) => {
	return (
		<button
			className="terms-wrapper_addButton"
			type='button'
			onClick={handler}
		>
			<img
				src={addPlus}
				alt="Add Plus"
			/>
			{title}
		</button>
	);
};

export default AddButton
