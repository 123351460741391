import React, { useEffect, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const EditorTextarea = ({title, name, handleChange, formDateUpdateHandler, refLink, initialValue, setMediaModalState}) => {
	
	const editor = useRef()
	const {t} = useTranslation()

	useEffect(()=>{
		const inv = setInterval(() => {
			const frame = editor.current.querySelector('iframe')
			if(frame){
				const warning_list = document.querySelectorAll('.tox-notifications-container')
				for(const element of warning_list){
					element.style.cssText = "display: none !important;"
				}
				clearInterval(inv)
			}
		}, 1000);
	},[]);

	return (
		<div>
			<Form.Group
			className='add-edit-htmls_label'
			style={{overflow: 'auto'}}
			ref={editor}
			>
				<div className='add-edit-htmls_header'>
					<p>{title}</p>
					<span onClick={()=>setMediaModalState(true)}>{t('ns_files')}</span>
				</div>
				<Editor
					apiKey='pqlcpkst4t1wdlmftmwhkezy4lrgsb0lbacpnz7rjp2a73bf'
					initialValue={initialValue}
					init={{
						branding: false,
						width: 'inherit',
						height: 500,
						menubar: false,
						plugins: "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media image template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
						toolbar:
							"styleselect undo redo | h1 h2 h3 | bold italic underline strikethrough | image link alignleft aligncenter alignright alignjustify | bullist outdent indent | removeformat",
						image_advtab: false,
						image_class_list: [
							{ title: 'None', value: 'none' },
							{ title: 'Left', value: 'image_left' },
							{ title: 'Right', value: 'image_right' }
						],
						style_formats: [
							{title: 'image_left', selector: 'image_left', styles: {
							  'float' : 'left',
							  'margin': '0 10px 0 10px'
							}}
						  ]
						
					}}
					onChange={e => {
						handleChange(e)
						formDateUpdateHandler({
							[`messageText${name}`]: e.target.getContent()
						})
					}}
				/>
			</Form.Group >
		</div>
	)
}

export default EditorTextarea