import { useState } from 'react';
import s from './userDeals.module.scss';
import Loader from '../../../../components/LoaderContent'
import PaginationCustom from '../../../../components/PaginationCustom';
import { useTranslation } from 'react-i18next';
import { useGetUserDealsQuery } from '../../../../store/apis/usersApi';

const moderation_statuses = [
	{id: 0, title: "Не подтверждена"},
	{id: 101, title: "Предоставление документов"},
	{id: 1, title: "В процессе"},
	{id: 2, title: "Отменена"},
	{id: 3, title: "Оплачена"},
	{id: 401, title: "Завершение"},
	{id: 4, title: "Завершена"},
]

export const UserDeals = ({userId}) => {

	// ### State
	const [query, setQuery] = useState({
		limit: 20,
		page: 1,
		userId: userId
	})

	const {i18n} = useTranslation()

	// ### Effects
	const {data: adverts, isFetching: isAdvertsLoading} = useGetUserDealsQuery(query)
	
	// ### Functions
	function changeSort(sort){
		setQuery(prev => { return {...prev, sort: sort, order: prev.order === 'desc' ? 'asc' : 'desc'}})
	}

	// ### Views
	const adverts_view = adverts?.data?.map((el, index) => (
		<tr key={index}>
			<td className={s.id}>{el.data?.id}</td>
			<td tyle={{width: '30%'}}>{el.data?.title}</td>
			<td>{el.data?.category?.[i18n.language]}</td>
			<td>{el.data?.subcategory?.[i18n.language]}</td>
			<td>{new Date(parseInt(el.data?.date)).toLocaleString()}</td>
			<td style={{width: '5%'}}>
				<span className={`${s.status}
					${el.status === 0? s.start : ''}
					${el.status === 1? s.active : ''}
					${el.status === 101? s.wait_docs : ''}
					${el.status === 2? s.rejected : ''}
					${el.status === 3? s.payed : ''}
					${el.status === 401? s.wait_end : ''}
					${el.status === 4? s.completed : ''}
				`}>{(moderation_statuses.find(status => status.id === el.status))?.title}</span>
			</td>
		</tr>
	))
	if(!adverts_view?.length && !isAdvertsLoading) return <h1 className={s.no_data}>Not found</h1>
	return(
		<div className={s.wrapper}>
			<header className={s.header}>
				<PaginationCustom
                    itemsCount={query.limit}
                    currentPage={query.page}
                    setCurrentPage={(page)=>{setQuery(prev => {return{...prev, page}})}}
                    counterAllItems={adverts?.totalDeals}
                />
			</header>
			
			<table className={s.table}>
				<thead>
					<tr>
						<td className={`${s.id}`} onClick={()=>changeSort("id")}>ID</td>
						<td style={{width: '20%'}} onClick={()=>changeSort("title")}>Назва</td>
						<td onClick={()=>changeSort(`category.${i18n.language}`)}>Категорія</td>
						<td onClick={()=>changeSort(`subcategory.${i18n.language}`)}>Підкатегорія</td>
						<td onClick={()=>changeSort("date")}>Створення</td>
						<td style={{width: '5%'}}></td>
					</tr>
				</thead>
				<tbody>
				{ isAdvertsLoading ? <Loader /> : adverts_view }
				</tbody>
			</table>
			
		</div>
	)
}