import React, {
	useEffect,
	useState,
} from "react"
import arrow from '../../../../utils/icons/arrowViolet.svg'
import {
	FormCheck,
} from "react-bootstrap"
import { useTranslation } from "react-i18next"

const ChangeItemDropdownList = ( {
	errors,
	category,
	subcategory,
	arrSubcategoriesList,
	subSubCategoriesList,
	formDateUpdateHandler,
	handleBlur,
	form,
	handleChange,
	idCategory,
	currentCategory
} ) => {
	const [ showMenu, setShowMenu ] = useState( false )
	const [ showItemParent, setShowItemParent ] = useState( [] )
	const idSubcategoryWithEdit = form?.subcategory
	const toggleShowMenu = () => setShowMenu( !showMenu )
	const {i18n} = useTranslation()
	useEffect( () => {
			const teh = arrSubcategoriesList.map( item => item.filter( it => it.parent === category?.id ) )
			teh.forEach( item => {
				if ( item.length > 0 ) {
					setShowItemParent( item )
				} else {
					return
				}
			} )
		},
		[ arrSubcategoriesList, category?.id ]
	)

	return (
		<div
			className={ `template-content_category-body_list-dropdown ${ errors?.subcategory ? "is-touch " : "" } ${
				errors?.subcategory ? " is-invalid" : "" }` }
		>
			<button
				type='button'
				className={
					`template-content_category-body_list-dropdown_toggle ml-2
			${ showMenu ? 'showDropdown' : '' }
			` }
				disabled={ form?.category !== idCategory }
				name={ 'subcategory' }
				onBlur={ handleBlur }
				onClick={ ( e ) => {
					e.stopPropagation()
					toggleShowMenu()
					handleChange( e )
				} }
			>
				<label
					className='template-content_category-body_list-dropdown_menu-item_inp'
				>
					<FormCheck
						onBlur={ handleBlur }
						type={ "radio" }
						onKeyDown={ e => {
							e.key === 'Enter' && e.preventDefault()
						} }
						// defaultChecked={ item?.id === idSubcategoryWithEdit }
						name={ 'radioCheckedCategory' }
						onChange={ ( e ) => {
							// console.log('item', item)
							e.stopPropagation()
							formDateUpdateHandler( { parent: form?.category } )
						} }
					/>
				</label >
				{
					category?.name?.[i18n.language]
				}
				<div className='template-content_category-body_list-dropdown_toggle-counter'>
					<p >{ showItemParent.length }</p >
					<img
						src={ arrow }
						alt='Arrow'
					/>
				</div >
			</button >
			{
				showMenu
				&&
				<ul
					className='template-content_category-body_list-dropdown_menu'
				>
					<ChangeItemDropdownListItem
						showItemParent={ showItemParent }
						subSubCategoriesList={ subSubCategoriesList }
						arrSubcategoriesList={ arrSubcategoriesList }
						subcategory={ subcategory }
						formDateUpdateHandler={ formDateUpdateHandler }
						handleBlur={ handleBlur }
						idSubcategoryWithEdit={ idSubcategoryWithEdit }
						form={ form }
						idCategory={ idCategory }
						currentCategory={currentCategory}
					/>
				</ul >
			}
		</div >
	)
}

export default ChangeItemDropdownList


export const ChangeItemDropdownListItem = ( {
	showItemParent,
	subSubCategoriesList,
	subcategory,
	formDateUpdateHandler,
	handleBlur,
	idSubcategoryWithEdit,
	form,
	idCategory,
	currentCategory
} ) => {
	const [ showItemSub, setShowItemSub ] = useState( [] )
	const [ arrItemSub, setArrItemSub ] = useState( [] )
	const [ changeItemList, setChangeItemList ] = useState( [] )
	const [ changeItemsArrLength, setChangeItemsArrLength ] = useState( [] )
	const [ showMenu, setShowMenu ] = useState( false )

	useEffect( () => {
			const te = subSubCategoriesList.filter( item => {
				return item.filter( it => {
						showItemParent.filter( i => {
							return i.id === it.parent
						} )
						return item
					},
				)
			} )
			setShowItemSub( te )
		},
		[ subSubCategoriesList, showItemParent ]
	)


	useEffect( () => {
			let re = []
			showItemParent?.map( item => {
				const t = showItemSub.filter( it => {
					const y = it.filter( i => i.parent === item.id )
					re = [ ...re, y ]
					return re
				} )

				return t
			} )
			let arr = []
			re.forEach( item => {
				if ( item.length > 0 ) {
					arr = [ ...arr, item ]
				} else {
					return
				}
			} )
			setArrItemSub( arr )
		},
		[ showItemSub, showItemParent ]
	)

	const changeItemShow = ( id ) => {
		let arrChange = []
		arrItemSub.filter( it => {
			const ga = it.filter( i => {
				return i.parent === id
			} )
			arrChange = [ ...arrChange, ga ]
			return ga
		} )

		let arr = []
		arrChange.forEach( item => {
			if ( item.length > 0 ) {
				arr = [ ...arr, item ]
			} else {
				return
			}
		} )
		setChangeItemList( arr[ 0 ] )
	}

	useEffect( () => {
			let arr = []
			changeItemList?.forEach( item => {
				const h = subcategory.filter( it => {
					return it.parent === item.id
				} )
				arr = [ ...arr, h ]
				return h
			} )
			setChangeItemsArrLength( arr )
		},
		[ subcategory, changeItemList ]
	)

	return (
		<>
			{
				showItemParent?.map( ( item, index ) => (
					<button
						type='button'
						className={ `template-content_category-body_list-dropdown_menu-item ` }
						key={ item?.id }
						disabled={ form?.category !== idCategory }
						onClick={ ( e ) => {
							e.stopPropagation()
							setShowMenu( !showMenu )
							changeItemShow( item?.id )
						} }
						style={{display: item.id === currentCategory? 'none' : 'flex'}}
					>
						<label
							className='template-content_category-body_list-dropdown_menu-item_inp'
						>
								<FormCheck
									onBlur={ handleBlur }
									type={ "radio" }
									onKeyDown={ e => {
										e.key === 'Enter' && e.preventDefault()
									} }
									defaultChecked={ item?.id === idSubcategoryWithEdit }
									name={ 'radioCheckedCategory' }
									onChange={ ( e ) => {
										// console.log('item', item)
										e.stopPropagation()
										formDateUpdateHandler( { parent: item?.id } )
										// formDateUpdateHandler( { parent: item?.parent } )
									} }
								/>
							{ item?.title?.ua }
						</label >
						{
							arrItemSub[ index ]?.length &&
							<div className='template-content_category-body_list-dropdown_toggle-counter'>
								<p >{ arrItemSub[ index ]?.length }</p >
								<img
									src={ arrow }
									alt='Arrow'
									style={ { transform: showMenu ? 'rotate(-90deg)' : 'rotate(0deg)' } }
								/>
							</div >
						}
						{ showMenu &&
							changeItemList?.map( ( sub, index ) => (
								item?.id === sub?.parent && <ChangeItemDropdownListSubItem
									item={ sub }
									key={ sub?.id + index }
									subcategory={ subcategory }
									length={ changeItemsArrLength[ index ]?.length > 0 ? changeItemsArrLength[ index ]?.length : null }
									formDateUpdateHandler={ formDateUpdateHandler }
									handleBlur={ handleBlur }
									idSubcategoryWithEdit={ idSubcategoryWithEdit }
									currentCategory={currentCategory}
								/>
							) )
						}
					</button >
				) )
			}
			{
			}
		</>
	)
}

export const ChangeItemDropdownListSubItem = ( {
	subcategory,
	item,
	length,
	formDateUpdateHandler,
	handleBlur,
	idSubcategoryWithEdit,
	currentCategory
} ) => {
	const [ changeItemsArr, setChangeItemsArr ] = useState( [] )
	const [ showMenu, setShowMenu ] = useState( false )

	const changeSubItem = ( id ) => {
		setChangeItemsArr( subcategory?.filter( item => item?.parent === id ) )
	}

	return (
		<div
			className='template-content_category-body_list-dropdown_menu-item'
			style={ {
				paddingLeft: '20px',
				paddingRight: '0',
				paddingTop: '10px',
				display: item.id === currentCategory? "none" : "flex"
			} }
			key={ item?.id }
			onClick={ ( e ) => {
				e.stopPropagation()
				changeSubItem( item?.id )
				setShowMenu( true )
			} }
		>
			<label className='template-content_category-body_list-dropdown_menu-item_inp'>
				<FormCheck
					onBlur={ handleBlur }
					type={ "radio" }
					onKeyDown={ e => {
						e.key === 'Enter' && e.preventDefault()
					} }
					name={ 'radioCheckedCategory' }
					defaultChecked={ item?.id === idSubcategoryWithEdit }
					onChange={ ( e ) => {
						e.stopPropagation()
						formDateUpdateHandler( { parent: item?.id } )
					} }
				/>
				{ item?.title?.ua }
			</label >
			{
				length && <div className='template-content_category-body_list-dropdown_toggle-counter'>
					<p >{ length ? length : '' }</p >
					<img
						src={ arrow }
						alt='Arrow'
						style={ { transform: showMenu ? 'rotate(-90deg)' : 'rotate(0deg)' } }
					/>
				</div >
			}

			{ showMenu &&
				changeItemsArr?.map( ( item, index ) => (
					<ChangeItemDropdownListSubItemSub
						formDateUpdateHandler={ formDateUpdateHandler }
						item={ item }
						key={ item?.id + index }
						handleBlur={ handleBlur }
						idSubcategoryWithEdit={ idSubcategoryWithEdit }
						currentCategory={currentCategory}
					/>
				) )
			}
		</div >
	)
}
export const ChangeItemDropdownListSubItemSub = ( {
	item,
	formDateUpdateHandler,
	handleBlur,
	idSubcategoryWithEdit,
	currentCategory
} ) => {
	return (
		<div
			className='template-content_category-body_list-dropdown_menu-item'
			style={ {
				paddingLeft: '20px',
				paddingRight: '0',
				paddingTop: '10px',
				display: item.id === currentCategory? "none" : "flex"
			} }
			key={ item?.id }
		>
			<label
				className='template-content_category-body_list-dropdown_menu-item_inp'
				htmlFor={ item?.id }
			>
				<FormCheck
					onBlur={ handleBlur }
					type={ "radio" }
					onKeyDown={ e => {
						e.key === 'Enter' && e.preventDefault()
					} }
					name={ 'radioCheckedCategory' }
					id={ item?.id }
					defaultChecked={ item?.id === idSubcategoryWithEdit }
					onChange={ ( e ) => {
						e.stopPropagation()
						formDateUpdateHandler( { parent: item?.id } )
					} }
				/>
				{ item?.title?.ua }
			</label >


		</div >
	)
}
