import { Alert } from "react-bootstrap";
import AlertIcon from "../../utils/icons/alert-icon.svg";
import { useEffect } from "react";

const AlertModal = ({ content, setErrorMessage, position }) => {
  useEffect(() => {
    content !== "" && setTimeout(() => setErrorMessage(""), 5000);
  }, [content]); // eslint-disable-line
  return (
    <Alert
      className="alertProjeck"
      style={{ position: position ? "static" : "fixed" }}
    >
      <img src={AlertIcon} alt="Alert" />
      <div className="alertProjeck-block">
        <h3 className="alertProjeck-block_h3">Увага</h3>
        <p className="alertProjeck-block_text">{content}</p>
      </div>
    </Alert>
  );
};

export default AlertModal;
