import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import { DELIVERY_API } from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const deliveryApi = createApi( {
	reducerPath: "deliveryApi",
    tagTypes: ['Delivery'],
	baseQuery: fetchBaseQuery( {
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{ getState }
		) => {
			const { token } = getState().userStore;
			if ( token ) {
				headers.set( "Authorization", `Bearer ${ token }` )
			}
			return headers
		}
	} ),
	endpoints: ( builder ) => (
		{
			getDeliveries: builder.query( {
				query: ({search}) => (
					{
						url: `${DELIVERY_API.GET_DELIVERIES}
						${search ? '?search='+search : ''}
						`,
						method: "GET",
					}
				),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Delivery', id })),
					{ type: 'Delivery', id: 'LIST' },
					]
				: [{ type: 'Delivery', id: 'LIST' }],
			} ),

			getDelivery: builder.mutation( {
				query: (id) => (
					{
						url: `${DELIVERY_API.GET_DELIVERY}?id=${id}`,
						method: "GET",
					}
				),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Delivery', id })),
					{ type: 'Delivery', id: 'LIST' },
					]
				: [{ type: 'Delivery', id: 'LIST' }],
			} ),

            createDelivery: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ DELIVERY_API.CREATE_DELIVERY }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Delivery', id: 'LIST' }],
			} ),

			updateDelivery: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ DELIVERY_API.UPDATE_DELIVETY }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Delivery', id: 'LIST' }],
			} ),

			deleteDelivery: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ DELIVERY_API.DELETE_DELIVETY }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Delivery', id: 'LIST' }],
			} ),
		}
	)
} );

export const {
    useGetDeliveriesQuery,
	useGetDeliveryMutation,
    useCreateDeliveryMutation,
	useDeleteDeliveryMutation,
	useUpdateDeliveryMutation
} = deliveryApi
