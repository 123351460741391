import React from 'react';
import s from './notificationsSenderList.module.scss';
import { PAGES_NAME } from '../../../utils/textsContent';
import { useState } from 'react';
import AddButton from '../../components/environmentTabl/AddButton';
import { AddEmailSenderModal } from './AddEmailSenderModal/AddEmailSenderModal';
import { useGetEmailNotificationSenderQuery } from '../../store/apis/notificationApi';
import Loader from '../../components/LoaderContent';
import { transformDate } from '../../../utils/helpers/TransformDate';
import { ReactComponent as MenuIcon } from '../../../utils/icons/vertical-dots.svg'
import { useRef } from 'react';
import { EditEmailSenderModal } from './EditEmailSenderModal/EditEmailSenderModal';
import DeleteModal from '../../components/DeleteModal';
import { useTranslation } from 'react-i18next';

export const NotificationsSenderList = ()=>{

    const [activeType, setActiveType] = useState('all')
    const [addModal, setAddModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [editModalData, setEditModalData] = useState({});
    const [deleteModalShow, setDeleteModalShow] = useState(false)
    const [deleteId, setDeleteId] = useState(false)
    const {t} = useTranslation()

    const {data, isLoading} = useGetEmailNotificationSenderQuery();

    const modal = useRef([])

    if(isLoading) return <Loader/>

    function toggleMenu(el){
        modal.current[el].classList.toggle(s.active)
    }

    function editSender(el){
        let namesObj = {}
        el.value.names.forEach((el, index) => {
            namesObj[`name${index}`] = el
        })
        setEditModalData({id: el.id, host: el.value.host, port: el.value.port, email: el.value.email, names: namesObj})
        setEditModal(true)
        modal.current[el.value.email].classList.remove(s.active)
    }

    const senders_view = data?.map((el, index) => {
        const names_list_view = el.value.names.map((el, index) => {
            return <p key={index}>{el}</p>
        })
        return (
            <tr key={index}>
                <td>{el.value.email}</td>
                <td>
                    <div className={s.tr_names}>
                        {el.value.names[0]}
                        <div className={s.names_list}>{names_list_view}</div>
                        <div className={s.names_arrow}></div>
                    </div>
                </td>
                <td>{el.value.host}</td>
                <td>{el.value.port}</td>
                <td>{transformDate(el.dateCreate)}</td>
                <td><span className={s.shape} style={{background: el.status? '#009B33' : '#FF7157'}}></span></td>
                <td className={s.td_menu}>
                    <div className={s.dots_wrapper} onClick={()=>modal.current[el.value.email].classList.add(s.active)}>
                        <MenuIcon/>
                    </div>
                    <div className={ s.tools_modal } ref={(element) => modal.current[el.value.email] = element}>
                        <div className={s.item }>
                                <span onClick={ () => {editSender(el)} }>{t('edit')}</span >
                        </div >
                        <div className={ `${ s.item } ${ s.delete }` }>
                            <span onClick={() => {
                                setDeleteId({id: el.id, title: el.value.email})
                                setDeleteModalShow(true)
                            }}>{t('delete')}</span >
                        </div >
                        <div className={ s.modal_wrapper } onClick={()=>toggleMenu(el.value.email)}></div >
                    </div >
                </td>
            </tr>
        )
    })

    return(
        <div className='terms'>
            <DeleteModal
				show={deleteModalShow}
				onHide={()=>setDeleteModalShow(false)}
				value={deleteId}
				fromPage={'notificationSender'}
			/>
            <h1 className='terms-title'>
                {t('senders')}
            </h1 >
            <div className={`terms-wrapper ${s.content}`}>
                <div className={s.top_button_wrapper}>
                    <span 
                        style={{borderBottom: activeType === 'all'? '3px #6D63D3 solid':'3px transparent solid'}}
                        onClick={()=>setActiveType('all')}
                    >{t('all')}</span>
                    <span 
                        style={{borderBottom: activeType === 'email'? '3px #6D63D3 solid':'3px transparent solid'}}
                        onClick={()=>setActiveType('email')}
                    >{t('email')}</span>
                </div>
                <div className={s.tools_wrapper}>
                    <AddButton title={t('add')} handler={()=>setAddModal(true)}/>
                </div>
                <div className={s.content_wrapper}>
                    <table>
                        <thead>
                            <tr>
                                <td>{t('email')}</td>
                                <td>{t('name')}</td>
                                <td>HOST</td>
                                <td>PORT</td>
                                <td>{t('_m_createdDate')}</td>
                                <td>{t('status')}</td>
                            </tr> 
                        </thead>
                        <tbody>
                            {senders_view}
                        </tbody>
                    </table>
                </div>
            </div>
            {addModal && <AddEmailSenderModal hide={setAddModal}/>}
            {editModal && <EditEmailSenderModal data={editModalData} hide={setEditModal}/>}
        </div>
    )
}