import React, { useEffect, useState } from 'react';
import s from './confirmModal.module.scss';
import RejectButton from '../environmentTabl/RejectButton';
import { useTranslation } from 'react-i18next';

export const ConfirmModal = ({ close, title, text, success, isLoading })=>{

    const [closed, setClosed] = useState(false)
    const {t} = useTranslation()

    useEffect(()=>{
        if(!isLoading && closed) close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isLoading, closed]);

    function clickHandler(){
        success()
        setClosed(true)
    }

    return(
        <div className={s.confirm_wrapper}>
            <div className={s.confirm_modal}>
                <p className={s.title}>{title}</p>
                <p>{text}</p>
                <div className={s.buttons}>
                    {success && <RejectButton title={t('confirm')} handler={clickHandler} isLoading={isLoading}/>}
                    {!isLoading? <RejectButton title={t('close')} handler={close}/> : null}
                </div>
            </div>
        </div>
    )
}