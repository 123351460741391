import { createSlice } from "@reduxjs/toolkit"
import { langApi } from "../apis/langApi"

const initialState = {
	langs: [],
}

const langSlice = createSlice( {
	name: "lang",
	initialState,
	reducers: {
        getLangs: (state, action)=>{
            state.langs = action.payload
        }
    },
	extraReducers: ( builder ) => {
		builder
			.addMatcher( langApi.endpoints.getLangs.matchFulfilled, (
					state,
					action
				) => {
					const sorted_langs = action.payload.sort((a, b) => a.id - b.id)
					state.langs = sorted_langs
				}
			)
	}
} )

const {
	actions,
	reducer
} = langSlice
export const {getLangs} = actions
export default reducer
