import React, {
	useEffect,
	useState
} from 'react'
import AlertModal from "../../components/AlertModal"
import DeleteModal from "../../components/DeleteModal"
import ControlPanels from "../../components/environmentTabl/ControlPanels"
import CreateSubcategoryPage from "./CreateSubcategory/CreateSubcategoryPage"
import {
	useSelector
} from "react-redux"
import {
	ARE_COMMON,
	PAGES_NAME
} from "../../../utils/textsContent"
import { useGetSubcategoryMutation } from "../../store/apis/subcategoryApi"
import { useTranslation } from 'react-i18next'

const SubcategoriesPage = () => {
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )
	const [ openEditModal, setOpenEditModal ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const { subcategory } = useSelector( state => state.subcategoryStore )
	const [getSubcategory, {isLoading: isGetSubcategoryLoading}] = useGetSubcategoryMutation()
	const {t} = useTranslation()

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )

	useEffect( () => {
		const getSubcategoriesList = async () => {
			await getSubcategory({sortingBy: '?orderBy=asc&sortingBy=natural'})
		}
		getSubcategoriesList()
	}, [getSubcategory] )

	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const showModalHandler = () => {
		setAddEditModalShow( true )
		setOpenEditModal( false )
	}
	const hideModalHandler = () => {
		setAddEditModalShow( false )
		setOpenEditModal( false )
		setEditDateForModal( {} )
	}
	const toggleDeleteModal = ( value ) => {
		setDeleteTerm( value )
		setDeleteModalShow( !deleteModalShow )
	}


	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const openEditModalHandler = ( value ) => {
		setEditDateForModal( value )
		setAddEditModalShow( true )
		setOpenEditModal( true )
	}

	return (
		<div className='terms'>
			<div className='userInfo-tabs'>
				{
					errorMessage
					&&
					<AlertModal
						content={ errorMessage }
						setErrorMessage={ setErrorMessage }
					/>
				}
				<DeleteModal
					show={ deleteModalShow }
					onHide={ toggleDeleteModal }
					value={ deleteTerm }
					fromPage={ 'subcategory' }
					setShowSearchBeforeDel={ setShowSearchBeforeDel }
					returnErrorForDel={ returnErrorForDel }
				/>
				{
					addEditModalShow
						?
						<>
							<h1 className='terms-title  mt-3'>
								{ openEditModal ? t('cs_edit_title') : t('cs_create_title') }
							</h1 >
							<CreateSubcategoryPage
								onHide={ hideModalHandler }
								edit={ openEditModal }
								editDateForModal={ editDateForModal }
							/>
						</>
						:
						<>
							<h1 className='terms-title'>
								{t('s_subcat')}
							</h1 >
							<ControlPanels
								bigArrElements={ subcategory }
								showModalHandler={ showModalHandler }
								fromPage={ 'subcategory' }
								isLoading={ isGetSubcategoryLoading }
								toggleDeleteModal={ toggleDeleteModal }
								showSearchBeforeDel={ showSearchBeforeDel }
								openEditModalHandler={ openEditModalHandler }

								searchTermValue={ searchTermValue }
								loaderSorting={ loaderSorting }
								setLoaderSorting={ setLoaderSorting }
								toggleModalSearch={ toggleModalSearch }
								showModalSearch={ showModalSearch }
								setShowSearchBeforeDel={ setShowSearchBeforeDel }
								valueSearchInputHandler={ valueSearchInputHandler }
							/>
						</>
				}
			</div >
		</div >
	)
}

export default SubcategoriesPage
