import { Alert } from "react-bootstrap"
import AlertIcon from '../../utils/icons/active-moderation-icon.svg'
import { useEffect } from "react"

const AlertSuccessfullyModal = ({content, setErrorMessage, position}) => {
	useEffect(() => {
		content !== '' && setTimeout(() => setErrorMessage(''), 3000)
	}, [content])
	return (
		<Alert className='alertProjeck-successfully' style={{position: position === true ? 'static' : 'fixed'}}>
			<img
				src={AlertIcon}
				alt="Alert"
			/>
			<div className="alertProjeck-successfully-block">
				<h3 className='alertProjeck-successfully-block_h3'>Вдало змінено позицію елемента</h3>
			</div>
		</Alert>
	)
}

export default AlertSuccessfullyModal
