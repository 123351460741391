import React from 'react'
import {ARE_COMMON} from "../../utils/textsContent";
import Remove from "../../utils/icons/delete-red-icon.svg";
import { useTranslation } from 'react-i18next';

const FilterModerationPayment = ({
                                     changeMinPrice,
                                     setChangeMinPrice,
                                     changeMaxPrice,
                                     setChangeMaxPrice,
                                     handleChange,
                                     resetFilters,
                                     handleChangeSelect,
                                     statements,
                                     selectValue,
                                     changeId,
                                     changeType,
                                     setChangeId,
                                     setChangeType

}) => {

    const {t} = useTranslation()

    return (
        <div >
            <div className='noticeItem-filtered moderation-payment_filter'>
                <div className='noticeItem-filtered_wrapper-block'>
                    <p className='noticeItem-filtered_title'>
                        { t('f_filter_minPrice') }
                    </p >
                    <input
                        type='number'
                        name='min'
                        className='noticeItem-filtered_select'
                        required
                        value={changeMinPrice}
                        onChange={(event) => setChangeMinPrice(event.target.value)}
                    />
                </div >

                <div className='noticeItem-filtered_wrapper-block'>
                    <p className='noticeItem-filtered_title'>
                        { t('f_filter_maxPrice') }
                    </p >
                    <input
                        type='number'
                        name='max'
                        className='noticeItem-filtered_select'
                        value={changeMaxPrice}
                        onChange={(event) => setChangeMaxPrice(event.target.value)}
                        required
                    />
                </div >

                {/*<div className='noticeItem-filtered_wrapper-block'>
                    <p className='noticeItem-filtered_title'>
                        { ARE_COMMON?.METHODS }
                    </p >
                    <select
                        className='noticeItem-filtered_select'
                    >
                        <option> Підписки </option >
                        <option> Депозити </option >
                        <option> Пакети </option >
                        <option> Угоди </option >
                    </select >
                </div >*/}

                <div className='noticeItem-filtered_wrapper-block'>
                    <p className='noticeItem-filtered_title'>
                        { t('f_filter_extracts') }
                    </p >
                    <select
                        className='noticeItem-filtered_select'
                        onChange={handleChangeSelect}
                        value={selectValue}
                    >
                        <option hidden value=''>Всі</option>
                        <option> {t('f_expenses')} </option >
                        <option> {t('f_income')} </option >
                    </select >
                </div >

                <div className='noticeItem-filtered_wrapper-block'>
                    <p className='noticeItem-filtered_title'>
                        { t('user') } ID
                    </p >
                    <input
                        type='number'
                        name='id'
                        className='noticeItem-filtered_select'
                        value={changeId}
                        onChange={(event) => setChangeId(event.target.value)}
                        required
                    />
                </div >
                <div className='noticeItem-filtered_wrapper-block'>
                    <p className='noticeItem-filtered_title'>
                        { t('f_filter_payment_direction') }
                    </p >
                    <input
                        type='number'
                        name='type'
                        className='noticeItem-filtered_select'
                        value={changeType}
                        onChange={(event) => setChangeType(event.target.value)}
                        required
                    />
                </div >

            </div >
            <div className='moderation-payment_filter'>
                <button
                    className={ `terms-wrapper_addButton` }
                    onClick={ handleChange }
                    disabled={!changeMinPrice && !changeMaxPrice && statements === 0 && !changeId && !changeType}
                >
                    {t('apply')}
                </button >
                <button
                    className='template-content_buttons-close btn btn-primary'
                    onClick={ resetFilters }
                    disabled={!changeMinPrice && !changeMaxPrice && statements === 0 && !changeId && !changeType}
                >
                    <img
                        src={ Remove }
                        alt='remove filter'
                    />
                </button >
            </div >
        </div >
    )
}

export default FilterModerationPayment