import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ua from './utils/translations/ua.json';
import ru from './utils/translations/ru.json';
import en from './utils/translations/en.json';
import th from './utils/translations/th.json';

function languageDefault(){
    const languageSystem = navigator.language.slice(0, 2); // Виправлення: використання slice замість split

    switch (languageSystem) {
        case "en":
            return "en";
        case "uk":
            return "ua";
        case "ru":
            return "ru";
        case "th":
            return "th";
        default:
            return "en";
    }
}

const storedLanguage = localStorage.getItem("i18nextLng");

if (!storedLanguage) {
    // const defaultLanguage = languageDefault();
    const defaultLanguage = 'en';
    localStorage.setItem("i18nextLng", defaultLanguage);
}

i18n.use(initReactI18next)
    .init({
        react: {
            useSuspense: false
        },
        lng: localStorage.getItem("i18nextLng") ?? languageDefault(),
        fallbackLng: "en",
        resources: {
            en: {translation: en},
            ua: {translation: ua},
            ru: {translation: ru},
            th: {translation: th},
        },
        debug: true, // Виправлення: увімкнення дебагу лише в режимі розробки
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
