import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import AddButton from "./AddButton"
import { Form } from "react-bootstrap"
import LoaderContent from "../LoaderContent"
import TermsTabl from "../../pages/Terms/TermsTabl"
import PaginationCustom from "../PaginationCustom"
import { arrayBreakdownHelper } from "../../../utils/helpers/ArrayBreakdownHelper"
import { searchItemsHelper } from "../../../utils/helpers/SearchItemsHelper"
import {
	useDispatch
} from "react-redux"
import { showChangeUnitPriceModal, showModalSubChangeUnitPrice } from "../../store/slices/priceSlice"
import { showChangeUnitAmountModal } from "../../store/slices/amountSlice"
import { showChangeUnitModalTemplates } from "../../store/slices/temlateContentSlice"
import SearchItemsInput from "../SearchItemsInput"
import { useGetTermsMutation } from "../../store/apis/termsApi"
import {
	setUnitsMeasurementList,
	showChangeUnitModal
} from "../../store/slices/unitsMeasurementSlice"
import {
	getTermsList,
	showChangeTermsModal
} from "../../store/slices/termsSlice"
import TemplateTabl from "../../pages/Templates/TemplateTabl"
import { useGetHtmlsMutation } from "../../store/apis/htmlsApi"
import { showChangeHTMLSModal } from "../../store/slices/htmlsSlice"
import {
	getUnitGroupsList,
	showChangeUnitGroupModalHandler,
	showChangeUnitGroupsModal
} from "../../store/slices/unitGroupsSlice"
import {
	showChangeMark
} from "../../store/slices/markSlice"
import { showChangeMarkForModelModel } from "../../store/slices/modelSlice"
import { useGetMarkMutation } from "../../store/apis/markApi"
import { useGetTransportCategoryMutation } from "../../store/apis/transportCategoryApi"
import { useGetBodyTypeMutation } from "../../store/apis/bodyTypeApi"
import { showChangeBodyType } from "../../store/slices/bodyTypeSlice"
import { showChangeTransportCategory } from "../../store/slices/transportCategorySlice"
import { ARE_COMMON } from "../../../utils/textsContent"
import SelectShowRecords from "../SelectShowRecords";
import {
	filteredTemplateContentType,
	filteredTermsType
} from "../../../utils/helpers/FilteredTemplatesContentType";
import AlertModal from "../AlertModal";
import { useSelector } from 'react-redux'

const ControlPanelsModal = ({
	bigArrElements,
	showModalHandler,
	isLoading,
	openEditModalHandler,
	toggleDeleteModal,
	showModalAddUnitHandler,
	fromPage,
	addEditModalShow,
	searchTermValue,
	loaderSorting,
	setLoaderSorting,
	toggleModalSearch,
	showModalSearch,
	showSearchBeforeDel,
	setShowSearchBeforeDel,
	valueSearchInputHandler,
	setShowModalPrice,
	setShowModalAmount
}) => {
	const [smallAllArrTerms, setSmallAllArrTerms] = useState([])
	const [showSmallTermArr, setShowSmallTermArr] = useState([])
	const [countShowTerms, setCountShowTerms] = useState(20)
	const [currentPage, setCurrentPage] = useState(1)
	const [countShowPage, setCountShowPage] = useState(0)
	const [counterAllItems, setCounterAllItems] = useState(0)
	const [showPaginationBoard, setShowPaginationBoard] = useState(true)
	const [filteredChange, setFilteredChange] = useState(false)
	const [markerChangeTab, setMarkerChangeTab] = useState('all')
	const [arrForSearchInChangeTab, setArrForSearchInChangeTab] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	const [getTerms, {isLoading: isTermsLoading}] = useGetTermsMutation()
	const [getHtmls, {isLoading: isHtmlsLoading}] = useGetHtmlsMutation()
	const [getMark, {isLoading: isGetMarkLoading}] = useGetMarkMutation()
	const [getTransportCategory, {isLoading: isGetTransportCategoryLoading}] = useGetTransportCategoryMutation()
	const [getBodyType, {isLoading: isGetBodyTypeLoading}] = useGetBodyTypeMutation()
	const allButtonsType = document.querySelectorAll('.terms-wrapper_search-types_button')
	const dispatch = useDispatch()

	const unitlist = useSelector(store => store.unitsMeasurementStore.unitsMeasurement)
	const unitgroups = useSelector(store => store.unitGroupsStore.unitGroups)

	const isLoadingReq =
		loaderSorting ||
		isTermsLoading ||
		isHtmlsLoading ||
		isGetMarkLoading ||
		isGetTransportCategoryLoading ||
		isGetBodyTypeLoading

	const getAllUnitsMeasurement = useCallback(async () => {
			try {
				(
					(
						fromPage === 'price' ||
						fromPage === 'amount' ||
						fromPage === 'unit' ||
						fromPage === 'changeUnitForPrice' ||
						fromPage === 'changeUnitForPricesArr' ||
						fromPage === 'changeUnitForPricesArrTemplate' ||
						fromPage === 'changeUnitForAmount' ||
						fromPage === 'changeUnitForTemplates' ||
						fromPage === 'unitGroups' ||
						fromPage === 'ChangeUnitGroups'
					)
					&& addEditModalShow
				)
				&& await getLists()

				if (fromPage === 'changeTermsForTemplate') await getTerms()
				else if (fromPage === 'changeUnitForUnit' || fromPage === 'ChangeUnitGroups') await getLists()
				else if (fromPage === 'changeUnitForAmount') await getLists()
				else if (fromPage === 'changeHTMLSForTemplate') await getHtmls()
				else if (fromPage === 'changeMarkForMark' || fromPage === 'changeMarKForModalCreate') await getMark()
				else if (fromPage === 'changeTransportCategoryForModel') await getTransportCategory()
				else if (fromPage === 'changeBodyTypeForModel') await getBodyType()
			} catch (e) {
				setErrorMessage(e)
			}
		}, [addEditModalShow]
	)

	const getLists = async () => {
		dispatch(setUnitsMeasurementList(unitlist))
		dispatch(getUnitGroupsList(unitgroups))

		// const {terms} = await db.terms.get('terms')
		// dispatch(getTermsList(terms))
	}

	useEffect(() => {
		getAllUnitsMeasurement()
	}, [addEditModalShow, fromPage === 'price'])

	const closeModalChangeUnitForPrice = () => {
		fromPage === 'changeMarkForModel' && dispatch(showChangeMark(false))
		fromPage === 'changeMarKForModalCreate' && dispatch(showChangeMarkForModelModel(false))
		fromPage === 'changeTransportCategoryForModel' && dispatch(showChangeTransportCategory(false))
		setShowModalAmount !== undefined && setShowModalAmount(false)
		setShowModalPrice !== undefined && setShowModalPrice()
	}

	useEffect(() => {
		const teh = arrayBreakdownHelper(bigArrElements, countShowTerms)
		setSmallAllArrTerms([[], ...teh])
		setCountShowPage(teh?.length)
		setShowSmallTermArr(teh[0])
	}, [bigArrElements, countShowTerms, fromPage])

	useEffect(() => {
	}, [fromPage])

	useEffect(() => {
		showNextPagePagination()
	}, [currentPage])

	const showNextPagePagination = () => {
		setShowSmallTermArr(smallAllArrTerms[currentPage])
	}

	const searchHandler = () => {
		setLoaderSorting(true)
		if (fromPage === 'term') {
			const itemsDate = markerChangeTab === 'all' ? searchItemsHelper(bigArrElements, 'title', searchTermValue) : searchItemsHelper(arrForSearchInChangeTab, 'title', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'changeMarKForModalCreate') {
			const itemsDate = searchItemsHelper(bigArrElements, 'nameModal', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		}
		timerLoaderHandler()
		setShowPaginationBoard(false)
	}

	const clearSearchHandler = () => {
		toggleModalSearch(false)
		setLoaderSorting(true)
		valueSearchInputHandler('')
		const teh = arrayBreakdownHelper(bigArrElements, countShowTerms)
		setSmallAllArrTerms([[], ...teh])
		setShowSmallTermArr(teh[0])
		setCounterAllItems(bigArrElements?.length)
		setCurrentPage(1)
		timerLoaderHandler()
		setShowPaginationBoard(true)
		filteredTypeHandler(markerChangeTab)
	}

	const sortedForColumn = (column) => {
		setLoaderSorting(true)
		if (column === 'id') {
			const te = [...bigArrElements].sort((a, b) => a.id > b.id ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'editor') {
			const te = [...bigArrElements].sort((a, b) => a.editor > b.editor ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'psevdo') {
			const te = [...bigArrElements].sort((a, b) => a.psevdo > b.psevdo ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'termin') {
			const teh = arrayBreakdownHelper(bigArrElements, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'name') {
			const te = [...bigArrElements].sort((a, b) => a.name.ua > b.name.ua ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'type') {
			const te = [...bigArrElements].sort((a, b) => a.type > b.type ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'value') {
			const te = [...bigArrElements].sort((a, b) => a.value > b.value ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		}
		timerLoaderHandler()
	}
	const timerLoaderHandler = () => {
		setTimeout(() => setLoaderSorting(false), 1000)
	}
	useEffect(() => {
		setCounterAllItems(bigArrElements?.length)
	}, [bigArrElements])

	useEffect(() => {
		if (searchTermValue !== '' && showModalSearch) {
			showModalSearch && searchHandler()
			setShowSearchBeforeDel(false)
		}
		if (searchTermValue === '' && !showModalSearch) {
			clearSearchHandler()
		}
	}, [showModalSearch, showSearchBeforeDel])

	const filteredTypeHandler = (changeType) => {
		setFilteredChange(true)
		setLoaderSorting(true)
		timerLoaderHandler()
		const teh = filteredTermsType(bigArrElements, changeType)
		setArrForSearchInChangeTab(teh)
		setCounterAllItems(teh?.length)
		const tehArrs = arrayBreakdownHelper(teh, countShowTerms)
		setSmallAllArrTerms([[], ...tehArrs])
		setCountShowPage(tehArrs?.length)
		setShowSmallTermArr(tehArrs[0])
		setCurrentPage(1)
		setMarkerChangeTab(changeType)
	}

	useEffect(() => {
		markerChangeTab === 'all' && setCounterAllItems(arrForSearchInChangeTab?.length)
	}, [arrForSearchInChangeTab])

	const toggleButtonsType = (name) => {
		for (let i = 0; i < allButtonsType.length; i++) {
			if (allButtonsType[i].innerText === name) {
				allButtonsType[i].classList.add('term-types_active')
			} else {
				allButtonsType[i].classList.remove('term-types_active')
			}
		}
	}

	return (
		<div className='terms-wrapper'>
			{
				errorMessage
				&& <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			{
				(
					fromPage !== 'changeUnitForPrice' &&
					fromPage !== 'changeUnitForPricesArr' &&
					fromPage !== 'changeUnitForAmount' &&
					fromPage !== 'changeUnitForTemplates' &&
					fromPage !== 'changeUnitForTemplatesSelect' &&
					fromPage !== 'changeUnitForUnit' &&
					fromPage !== 'changeTermsForTemplate' &&
					fromPage !== 'changeHTMLSForTemplate' &&
					fromPage !== 'ChangeUnitGroups' &&
					fromPage !== 'changeMarkForModel' &&
					fromPage !== 'changeMarKForModalCreate' &&
					fromPage !== 'changeBodyTypeForModel' &&
					fromPage !== 'changeUnitForPricesArrTemplate' &&
					fromPage !== 'changeTransportCategoryForModel'
				) && <AddButton
					title={`${ARE_COMMON?.ADD}`}
					handler={showModalHandler}
				/>
			}
			<div className='d-flex align-items-center'>
				{
					(
						fromPage === 'changeUnitForPrice' ||
						fromPage === 'changeUnitForPricesArr' ||
						fromPage === 'changeUnitForAmount' ||
						fromPage === 'changeUnitForTemplates' ||
						fromPage === 'changeUnitForTemplatesSelect' ||
						fromPage === 'changeUnitForUnit' ||
						fromPage === 'changeTermsForTemplate' ||
						fromPage === 'changeHTMLSForTemplate' ||
						fromPage === 'ChangeUnitGroups' ||
						fromPage === 'changeMarkForModel' ||
						fromPage === 'changeMarKForModalCreate' ||
						fromPage === 'changeBodyTypeForModel' ||
						fromPage === 'changeUnitForPricesArrTemplate' ||
						fromPage === 'changeTransportCategoryForModel'
					)
					&&
					<AddButton
						title={'Створити '}
						handler={showModalAddUnitHandler}
					/>
				}
				{
					(
						fromPage === 'changeUnitForPrice' ||
						fromPage === 'changeUnitForAmount' ||
						fromPage === 'changeUnitForPricesArr' ||
						fromPage === 'changeUnitForTemplates' ||
						fromPage === 'changeUnitForTemplatesSelect' ||
						fromPage === 'changeUnitForUnit' ||
						fromPage === 'changeTermsForTemplate' ||
						fromPage === 'changeHTMLSForTemplate' ||
						fromPage === 'ChangeUnitGroups' ||
						fromPage === 'changeMarkForModel' ||
						fromPage === 'changeMarKForModalCreate' ||
						fromPage === 'changeBodyTypeForModel' ||
						fromPage === 'changeUnitForPricesArrTemplate' ||
						fromPage === 'changeTransportCategoryForModel'
					) && <AddButton
						title={`${ARE_COMMON?.ADD}`}
						handler={closeModalChangeUnitForPrice}
					/>
				}
			</div >
			{
				fromPage === 'term'
				&&
				<div className='terms-wrapper_search-types'>
				<button
					type='button'
					className='terms-wrapper_search-types_button term-types_active'
					onClick={() => {
						clearSearchHandler()
						filteredTypeHandler('all')
						toggleButtonsType(`${ARE_COMMON?.ALL}`)
					}}
				>
					{ARE_COMMON?.ALL}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						filteredTypeHandler('interface')
						toggleButtonsType(`${ARE_COMMON?.INTERFACE}`)
					}}
				>
					{ARE_COMMON?.INTERFACE}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						filteredTypeHandler('select')
						toggleButtonsType(`${ARE_COMMON?.SELECT}`)
					}}
				>
					{ARE_COMMON?.SELECT}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						filteredTypeHandler('email')
						toggleButtonsType(`${ARE_COMMON?.EMAIL}`)
					}}
				>
					{ARE_COMMON?.EMAIL}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						filteredTypeHandler('deals_notification')
						toggleButtonsType(`${ARE_COMMON?.DEALS_NOTIFICATION}`)
					}}
				>
					{ARE_COMMON?.DEALS_NOTIFICATION}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						filteredTypeHandler('system')
						toggleButtonsType(`${ARE_COMMON?.SYSTEM}`)
					}}
				>
					{ARE_COMMON?.SYSTEM}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						filteredTypeHandler('error')
						toggleButtonsType(`${ARE_COMMON?.ERROR}`)
					}}
				>
					{ARE_COMMON?.ERROR}
				</button >
			</div >
			}
			<div className='terms-wrapper_search'>

				<SearchItemsInput
					toggleModalSearch={toggleModalSearch}
					searchTermValue={searchTermValue}
					valueSearchInputHandler={valueSearchInputHandler}
					searchHandler={searchHandler}
					clearSearchHandler={clearSearchHandler}
				/>

				<SelectShowRecords setCountShow={setCountShowTerms} />

			</div >
			{
				countShowPage > 1
				&&
				showPaginationBoard && <PaginationCustom
					itemsCount={countShowPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					alwaysShown={showPaginationBoard}
				/>
			}
			{
				isLoading || isLoadingReq
					?
					<LoaderContent />
					:
					<>
						{
							(
								fromPage === 'template'
							)
								?
								<TemplateTabl
									countShowPage={counterAllItems}
									setOpenEditModal={openEditModalHandler}
									toggleDeleteModal={toggleDeleteModal}
									sortedForColumn={sortedForColumn}
									countShowTerms={countShowTerms}
									showSmallTermArr={showSmallTermArr}
									fromPage={fromPage}
								/>
								:
								counterAllItems !== 0
									?
									<TermsTabl
										countShowPage={counterAllItems}
										setOpenEditModal={openEditModalHandler}
										toggleDeleteModal={toggleDeleteModal}
										sortedForColumn={sortedForColumn}
										countShowTerms={countShowTerms}
										showSmallTermArr={showSmallTermArr}
										fromPage={fromPage}
									/>
									:
									<p className='userInfo-wrapper_block-pib_name mt-5'>За запросом нічого немає</p >
						}
					</>
			}
		</div >
	)
}

export default ControlPanelsModal
