import { createSlice } from "@reduxjs/toolkit"
import { markApi } from "../apis/markApi"
import { UniqueArrItems } from "../../../utils/helpers/UniqueArrItems";

const initialState = {
	mark: [],
	showModalChangeMark: false,
	dateModalChangeMark: []
}

const markSlice = createSlice( {
	name: "mark",
	initialState,
	reducers: {
		setMarkList: ( state, action ) => {
			state.mark = action.payload
		},
		createNewMarkItem: ( state, action ) => {
			state.mark = [ action.payload, ...state.mark ]
		},
		editMarkItem: ( state, action ) => {
			const index = state.mark.findIndex(
				( term ) => {
					return term.id === action.payload.id
				}
			)
			state.mark[index] = action.payload
		},
		deleteMarkItem: ( state, action ) => {
			const termId = action.payload
			const termID = state.mark.filter( term => term.id !== termId )
			state.mark = termID
		},
		showChangeMark: ( state, action ) => {
			state.showModalChangeMark = action.payload
		},
		setDateChangeMark: ( state, action ) => {
			const uniq = UniqueArrItems( [ ...state.dateModalChangeMark, action.payload ] )
			state.dateModalChangeMark = uniq
		},
		resetDateChangeMark: ( state, action ) => {
			state.dateModalChangeMark = []
		},
		deleteDateChangeMarkArr: ( state, action ) => {
			const Id = Number( action.payload )
			const ID = state.dateModalChangeMark.filter( item => Number( item ) !== Id )
			state.dateModalChangeMark = ID
		},
	},
	extraReducers: ( builder ) => {
		builder
			.addMatcher( markApi.endpoints.getMark.matchFulfilled, (
					state,
					action
				) => {
					state.mark = action.payload.data
				}
			)
	}
} )

const {
	actions,
	reducer
} = markSlice
export const {
	setMarkList,
	createNewMarkItem,
	deleteMarkItem,
	editMarkItem,
	showChangeMark,
	setDateChangeMark,
	resetDateChangeMark,
	deleteDateChangeMarkArr
} = actions
export default reducer
