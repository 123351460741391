import React, {
	useEffect,
	useState
} from 'react'
import { useSelector } from "react-redux"
import DeleteModal from "../../components/DeleteModal"
import ControlPanels from "../../components/environmentTabl/ControlPanels"
import AddEditAmountItem from "../../components/modalsAddEdit/AddEditAmountItem"
import AlertModal from "../../components/AlertModal"
import { PAGES_NAME } from "../../../utils/textsContent"

const AmountsPage = () => {
	const {
		showModalChangeUnitAmount
	} = useSelector( ( state ) => state.amountStore )
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ openEditModal, setOpenEditModal ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )
	const [ amountListArr, setAmountListArr ] = useState( [] )
	const [ errorMessage, setErrorMessage ] = useState( '' )

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )

	useEffect( () => {
		const getAmountList = async () => {
			setAmountListArr([])
		}
		getAmountList()
	}, [ addEditModalShow, deleteModalShow, editDateForModal ] )

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	useEffect( () => {
	}, [ showModalChangeUnitAmount ] )

	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
		setOpenEditModal( false )
	}
	const hideModalHandler = () => {
		setAddEditModalShow( false )
		setOpenEditModal( false )
		setEditDateForModal( {} )
	}
	const toggleDeleteModal = ( value ) => {
		setDeleteTerm( value )
		setDeleteModalShow( !deleteModalShow )
	}

	const openEditModalHandler = ( value ) => {
		setEditDateForModal( value )
		setAddEditModalShow( true )
		setOpenEditModal( true )
	}

	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}
	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<AddEditAmountItem
				show={ addEditModalShow }
				onHide={ hideModalHandler }
				edit={ openEditModal }
				editDateForModal={ editDateForModal }
				showModalChangeUnitAmount={ showModalChangeUnitAmount }
			/>
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ deleteTerm }
				fromPage={ 'amount' }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				returnErrorForDel={ returnErrorForDel }
			/>
			<h1 className='terms-title'>
				{PAGES_NAME?.AMOUNT}
			</h1 >
			<ControlPanels
				bigArrElements={ amountListArr }
				addEditModalShow={ addEditModalShow }
				showModalHandler={ showModalHandler }
				openEditModalHandler={ openEditModalHandler }
				toggleDeleteModal={ toggleDeleteModal }
				fromPage={ 'amount' }
				searchTermValue={ searchTermValue }
				loaderSorting={ loaderSorting }
				setLoaderSorting={ setLoaderSorting }
				toggleModalSearch={ toggleModalSearch }
				showModalSearch={ showModalSearch }
				showSearchBeforeDel={ showSearchBeforeDel }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				valueSearchInputHandler={ valueSearchInputHandler }
			/>
		</div >
	)
}



export default AmountsPage
