import React from 'react';
import s from './stars.module.scss';
import { ReactComponent as Star } from '../../../../../../../../utils/icons/star_feedback.svg'
import { useRef } from 'react';
import { useEffect } from 'react';

export const Stars = ({number})=>{

    const stars = useRef()

    useEffect(()=>{
        const stars_views = stars.current.children
        for(let i=0;i<number;i++){
            stars_views[i].style.cssText= `fill: #FF6600;`

        }
    },[number]);

    return(
        <div className={s.stars} ref={stars}>
            <Star/>
            <Star/>
            <Star/>
            <Star/>
            <Star/>
        </div>
    )
}