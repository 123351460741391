import React, { useEffect, useRef, useState } from 'react';
import s from './mediaFilesPage.module.scss';
import Resizer from 'react-image-file-resizer'
import Loader from '../../components/Loader';
import copy_icon from '../../../utils/icons/copy-icon.svg';
import {ReactComponent as DeleteIcon} from '../../../utils/icons/delete-icon.svg';
import {ReactComponent as Arrow} from '../../../utils/icons/arrow-right.svg'
import { useGetNotificationsMediaQuery, useUploadNotificationImageMutation } from '../../store/apis/notificationApi';
import AlertModal from '../../components/AlertModal';
import { squeezeFiles } from '../../../utils/helpers/CompressorFiles';
import DeleteModal from '../../components/DeleteModal';
import PaginationCustom from '../../components/PaginationCustom';
import SelectShowRecords from '../../components/SelectShowRecords';
import { useTranslation } from 'react-i18next';

const MediaFilesPage = ({isModal, close}) => {

	const [images, setImages] = useState([])
	const [imageFiles, setImageFiles] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [imagesIsLoading, setImagesIsLoading] = useState(false)
	const [imagesCompressorIsLoading, setImagesCompressorIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)
	const [deleteModalShow, setDeleteModalShow] = useState(false)
	const [deleteTerm, setDeleteTerm] = useState(false)
	const [countShow, setCountShow] = useState(20)
	const [currentPage, setCurrentPage] = useState(1)
	const {t} = useTranslation()

	const input = useRef()

	const [uploadImage, {isFetching: isUploadImagesLoading}] = useUploadNotificationImageMutation();
	const {data: media, isFetching: isLoadingMedia} = useGetNotificationsMediaQuery({currentPage, countShow});

	useEffect(()=>{
		if(isModal) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			  });
		}
	},[isModal]);

	useEffect(()=>{
		if(imagesIsLoading || imagesCompressorIsLoading) setIsLoading(true)
		else setIsLoading(false)
	},[imagesCompressorIsLoading, imagesIsLoading]);

	const resizeFile = async ( file, type, name, full_length ) => {
		const file_type = file.type.replace('image/','');
		setImagesIsLoading(true)
		Resizer.imageFileResizer(
			file,
			1920,
			1080,
			file_type,
			100,
			0,
			( uri ) => {
				const newState = {[name]: uri}
				type === 'image' && setImages( prev => {return[...prev, newState]} )
				if(name + 1 === full_length) setImagesIsLoading(false)
			},
			"base64",
			50,
			50
		)
	}

	const compressorFileImage = async ( file, type, name, full_length ) => {
		if ( type === 'image' ) {
			setImagesCompressorIsLoading(true)
			const tehFile = await squeezeFiles( file )
			const newState = {[name]: tehFile}
			setImageFiles( prev => {return[...prev, newState]} )
			if(name + 1 === full_length) setImagesCompressorIsLoading(false)
		}
	}


	async function sendImages(){
		if(!images.length) {
			setErrorMessage('Не вибрано жодного файлу')
			return
		}
		setIsLoading(true)
		const uploaded_files = await Promise.all(imageFiles.map(async (el) => {
			const file = el[Object.keys(el)[0]]
			const formData = new FormData();
			formData.append('dir', 'notifications')
			formData.append('image', file, `${file.name}`)
			return await uploadImage(formData)
		}))
		uploaded_files.forEach(el => {
			if(el.error){
				setErrorMessage(el.error?.data?.status)
				setIsLoading(false)
			}
			else{
				setImages([])
				setImageFiles([])
				setIsLoading(false)
			}
		})
	}

	function deletePreviewImage(index){
		setImages(prev => {
			const newState = prev.filter(el => {
				if(Object.keys(el)[0] === index) return false
				else return true
			})
			return newState
		})
		setImageFiles(prev => {
			const newState = prev.filter(el => {
				if(Object.keys(el)[0] === index) return false
				else return true
			})
			return newState
		})
	}

	function copyLink(el, link){
		const made_link = link.replace(/\\/g, "/");
		navigator.clipboard.writeText(made_link).then(el.innerHTML = `<span style="color: #008000">Success!</span>`)
		setTimeout(()=>{
			el.innerHTML = `Copy link <img src=${copy_icon} alt=''copy />`
		},500)
	}

	const toggleDeleteModal = (value) => {
		setDeleteTerm(value)
		setDeleteModalShow(!deleteModalShow)
	}

	const images_preview = images?.map((el, index) => {
		const key = Object.keys(el)
		return(
			<div key={index} className={s.image_wrapper}>
				<div className={s.tools_wrapper} onClick={()=>deletePreviewImage(key[0])}>
					<DeleteIcon />
				</div>
				<div className={s.image}>
					<img src={el[key[0]]} alt="preview" />
				</div>
			</div>
		)
	})

	async function deleteImageHandler(id){
		setDeleteTerm(id)
		setDeleteModalShow(true)
	}
	
	const images_view = media?.data?.map((el, index) => {
		const made_link = el.path.replace(/\\/g, "/");
		return(
			<div key={index} className={s.image_wrapper}>
				<div className={s.header}>
					<div className={s.link_wrapper} onClick={(e)=>{copyLink(e.target, el.path)}}>{t('cm_copyMedia')} <img src={copy_icon} alt="copy" /></div>
					<div className={s.delete_wrapper} onClick={()=>deleteImageHandler(el.id)}>
						<DeleteIcon />
					</div>
				</div>
				<div className={s.image}>
					<img src={made_link} alt="media" />
				</div>
			</div>
		)
	})

	return (
		<div className={`${s.terms} terms`}>
			<h1 className='terms-title'>{t('s_media')}</h1 >
				<div className={`${s.terms_wrapper} terms-wrapper`}>
					<div className={s.header}>
						<button
							type='submit'
							className='template-content_buttons-save btn btn-primary'
							onClick={()=>{input.current.click()}}
						>{t('c_button_addFiles')}</button>
						<input type="file" ref={input}
						accept='image/png, image/jpeg, image/webp, image/jpg'
						multiple 
						onChange={ e => {
							Object.entries(e.target.files).forEach((el, index) => {
								compressorFileImage( el[1], 'image', index, Object.entries(e.target.files).length)
								resizeFile( el[1], 'image', index, Object.entries(e.target.files).length)
							})
						} }/>
						{ isModal &&
							<button
							type='submit'
							className={`template-content_buttons-save btn btn-primary ${s.back_button}`}
							onClick={()=>{close(false)}}
							><Arrow/>{t('back')}</button>
						}
					</div>

					{images.length ?
					<div className={s.new_files_images_wrapper}>
						<h2>{t('ns_new_files')}</h2>
						<div className={s.content}>
							{images_preview}
						</div>

						<div className={s.footer}>
							{images_preview.length && !isLoading? <button
								type='submit'
								className='template-content_buttons-save btn btn-primary'
								onClick={sendImages}
							><span>{t('upload_files')}</span></button> : null}
						</div>
					</div> : null}

					<div className={s.media_wrapper}>
						<h2>{t('ns_files')}</h2>
						{media?.totalCount ? 
							<div className={s.pagination}>
							<PaginationCustom
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								itemsCount={countShow}
								counterAllItems={media?.totalCount}
							/>
							<SelectShowRecords setCountShow={setCountShow} />
							</div> : <p style={{textAlign: 'center'}}>{t('ns_files_missing')}</p>
						}
						<div className={s.content}>
							{images_view}
						</div>
					</div>
					{isLoading || isLoadingMedia || isUploadImagesLoading? <div className={s.loader_wrapper}><Loader/></div>:null}
				</div>
				{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
				}
				<DeleteModal
				show={deleteModalShow}
				onHide={toggleDeleteModal}
				value={deleteTerm}
				fromPage={'notificationMedia'}
				/>
		</div >
	);
};

export default MediaFilesPage;
