import { useState } from 'react';
import DeleteModal from '../../components/DeleteModal';
import s from './advertisersList.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAdvertiserGetUsersQuery } from '../../store/apis/advertiserApi';
import SearchItemsInput from '../../components/SearchItemsInput';
import PaginationCustom from '../../components/PaginationCustom';
import SelectShowRecords from '../../components/SelectShowRecords';
import LoaderContent from '../../components/LoaderContent';
import {ReactComponent as WarningIcon} from '../../../utils/icons/warning-icon.svg'
import { useTranslation } from 'react-i18next';

const statuses = {
    0 : "Moderation",
    1 : "Active",
    2 : "Rejected",
    3 : "Waiting for payment",
    4 : "Suspended"
}

export const AdvertisersList = () => {

    // ### State
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [query, setQuery] = useState({search: '', page: 1, limit: 20, status: false})
    const [search, setSearch] = useState('')
    const navigate = useNavigate()
    const { t } = useTranslation()

    const {data: companies, isLoading: isGetUserLoading} = useAdvertiserGetUsersQuery({isList: true})
    
    // ### Functions

    function getWarningCompany(company){
        let warningFlag
        if(company?.status === 0) warningFlag = true
        if(company?.markers?.moderationStatus === 0) warningFlag = true
        if(company.warningAnnouncements) warningFlag = true
        company?.markers?.markers?.forEach(marker => {
            if(marker.moderationStatus === 0) warningFlag = true
        })
        return warningFlag
    }

    // ### Views

    const companis_view = companies?.companies?.map((el, index) => {
        const warning = getWarningCompany(el)
        return(
            <tr key={index} className={s.company} onClick={()=>{navigate(`/advertiser/view?id=${el.id}`)}}>
                <td><div className={`${s.td_wrapper} ${s.td_name}`}>{el.companyName}{warning? <div className={s.warning_wrapper}><WarningIcon/></div> : null}</div></td>
                <td><div className={s.td_wrapper}>{el.email}</div></td>
                <td><div className={s.td_wrapper}>{el.announcements}</div></td>
                <td><div className={s.td_wrapper}>{new Date(el.dateCreated).toLocaleDateString()}</div></td>
                <td><div className={s.td_wrapper}><span className={el.status === 0? s.status_moderation : el.status === 1? s.status_active : el.status === 2? s.status_reject : el.status === 3? s.status_payment : el.status === 4? s.status_suspended : ""}>{statuses[el.status]}</span></div></td>
            </tr>
        )
    })

    return(
        <div className="terms">
            <h1 className='terms-title'>{t('a_list')}</h1 >
            <DeleteModal
				show={ showDeleteModal }
				onHide={ setShowDeleteModal }
				value={ showDeleteModal }
				fromPage={ 'dealsbuttons' }
			/>

            <div className='terms-wrapper_search'>
				<SearchItemsInput
					searchHandler={()=>{}}
					toggleModalSearch={()=>{ setQuery(prev => {return{...prev, search: search}})}}
					setSearch={()=>{}}
					valueSearchInputHandler={(value)=>{setSearch(value)}}
				/>
			</div >
            
            <div className='terms-wrapper_search'>
                <PaginationCustom
                    itemsCount={query.limit}
                    currentPage={query.page}
                    setCurrentPage={(page)=>{setQuery(prev => {return{...prev, page}})}}
                    counterAllItems={(query.search.length || query.status !== false)? companies?.allCount : companies?.allCount}
                />
                <SelectShowRecords setCountShow={(limit)=>{setQuery(prev => {return{...prev, limit}})}} />
            </div>
            {
                isGetUserLoading ? <LoaderContent /> : 
                <table className={s.table}>
                    <thead>
                        <tr>
                            <td className={s.company_td}>{t('admin')}</td>
                            <td>{t('email')}</td>
                            <td>{t('a_nac')}</td>
                            <td>{t('registration')}</td>
                            <td>{t('verification')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {companis_view}
                    </tbody>
                </table>
            }
        </div>
    )
}