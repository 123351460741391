import { useState } from 'react';
import { base64ToBlob } from '../../../../../utils/helpers/base64ToBlob';
import s from './userDocuments.module.scss';
import { ViewImageModal } from '../../../../components/ViewImageModal/ViewImageModal';
import { ReactComponent as SuccessIcon } from '../../../../../utils/icons/success-verification.svg'
import { ReactComponent as RejectIcon } from '../../../../../utils/icons/close-classik-icon-violet.svg'
import { ConfirmModal } from '../../../../components/ConfirmModal/ConfirmModal';
import { useVerdictVerificationDriverLicenseMutation, useVerdictVerificationPassportMutation } from '../../../../store/apis/usersApi';
import AlertModal from '../../../../components/AlertModal';
import Loader from '../../../../components/LoaderContent';
import { useTranslation } from 'react-i18next';
import { VerificationMessages } from '../../../../components/verificationMessagesModal/verificationMessagesModal';

export const UserDocuments = ({ passport, driverLicense }) => {
    
    // ### State
    const { t } = useTranslation()
    const verificationStatuses = [
        { id: 0, title: t('nver') },
        { id: 1, title: t('ver') },
        { id: 2, title: t('dnpm') },
    ]

    const [viewImage, setViewImage] = useState(false)
    const [confirmPassportModal, setConfirmPassportModal] = useState(false)
    const [rejectPassportModal, setRejectPassportModal] = useState(false)
    const [confirmDriverLicenseModal, setConfirmDriverLicenseModal] = useState(false)
    const [rejectDriverLicenseModal, setRejectDriverLicenseModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [passportMessagesModal, setPassportMessagesModal] = useState(false)
    const [driverMessagesModal, setDriverMessagesModal] = useState(false)

    // ### Effects
    const [verificationPassport, {isLoading: isLoadingPassport}] = useVerdictVerificationPassportMutation()
    const [verificationDriverLicense, {isLoading: isDriverLicenseLoading}] = useVerdictVerificationDriverLicenseMutation()

    // ### Functions
    async function handlePassportVerification(status, message){
        const response = await verificationPassport({
            type: status,
            message: message,
            passportId: passport?.id
        })
        if(response.error) setErrorMessage(response.error.data?.message)
    }
    
    async function handleDriverLicenseVerification(status, message){
        const response = await verificationDriverLicense({
            type: status,
            message: message,
            driverLicenseId: driverLicense?.id
        })
        if(response.error) setErrorMessage(response.error.data?.message)
    }

    if(isLoadingPassport || isDriverLicenseLoading) return <Loader/>
    return(
        <div className={s.wrapper}>
            {passport? 
                <div className={s.passport}>

                <div className={`${s.status} 
                    ${
                        passport?.moderationStatus === 0 ? s.moderation :
                        passport?.moderationStatus === 1 ? s.active :
                        passport?.moderationStatus === 2 ? s.rejected : ""
                    }`}
                >
                    {t(verificationStatuses.find(el => el.id === passport?.moderationStatus)?.title)}
                </div>

                <span className={s.title}>Passport or ID</span>
                <div className={s.block}>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Name:</span>
                            <span className={s.input}>{passport?.name || "- - -"}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Surname:</span>
                            <span className={s.input}>{passport?.surname || "- - -"}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Valid until:</span>
                            <span className={s.input}>{passport?.validUntil || "- - -"}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Country:</span>
                            <span className={s.input}>{passport?.country || "- - -"}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Passport ID:</span>
                            <span className={s.input}>{passport?.passport || "- - -"}</span>
                        </div>
                    </div>
                </div>

                <div className={s.images_wrapper}>
                {
                    passport?.photos?.map((el, index) => (
                        <div key={index} className={s.img_wrapper} onClick={()=>setViewImage(URL.createObjectURL(base64ToBlob(el.file, el.mimetype)))}>
                            <img src={URL.createObjectURL(base64ToBlob(el.file, el.mimetype))} alt="passport" />
                        </div>
                    ))
                }
                </div>

                <div className={s.footer}>
                    {passport?.moderationStatus !== 1 ? 
                        <div className={`${s.success_button}`} onClick={()=>setConfirmPassportModal(passport?.name)}>
                            <SuccessIcon/>
                            <span>{t('confirm_password')}</span>
                        </div> : null
                    }
                    {passport?.moderationStatus !== 2 ? 
                    <div className={`${s.success_button} ${s.reject_button}`} onClick={()=>setPassportMessagesModal({type: 3, status: 2})}>
                        <RejectIcon/>
                        <span>{t('reject')}</span>
                    </div> : null
                    }
                </div>
                </div> : <h2 style={{textAlign: "center", width: "48%"}}>Passport is missing</h2>
            }
            {driverLicense? 
                <div className={s.driverLicense}>
                <span className={s.title}>Driver license</span>

                <div className={`${s.status} 
                    ${
                        driverLicense?.moderationStatus === 0 ? s.moderation :
                        driverLicense?.moderationStatus === 1 ? s.active :
                        driverLicense?.moderationStatus === 2 ? s.rejected : ""
                    }`}
                >
                    {t(verificationStatuses.find(el => el.id === driverLicense?.moderationStatus)?.title)}
                </div>

                <div className={s.block}>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Name:</span>
                            <span className={s.input}>{driverLicense?.name || "- - -"}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Surname:</span>
                            <span className={s.input}>{driverLicense?.surname || "- - -"}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Valid until:</span>
                            <span className={s.input}>{driverLicense?.validUntil || "- - -"}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Driving experience:</span>
                            <span className={s.input}>{driverLicense?.drivingExperience? driverLicense?.drivingExperience+' года' : "- - -"}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Country:</span>
                            <span className={s.input}>{driverLicense?.country || "- - -"}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>Driver license:</span>
                            <span className={s.input}>{driverLicense?.driverLicense || "- - -"}</span>
                        </div>
                    </div>
                </div>

                <div className={s.images_wrapper}>
                {
                    driverLicense?.photos?.map((el, index) => {
                        if(el) return(
                            <div key={index} className={s.img_wrapper} onClick={()=>setViewImage(URL.createObjectURL(base64ToBlob(el.file, el.mimetype)))}>
                                <img src={URL.createObjectURL(base64ToBlob(el?.file, el?.mimetype))} alt="passport" />
                            </div>
                        )
                        else return ''
                    })
                }
                </div>

                <div className={s.footer}>
                    {driverLicense?.moderationStatus !== 1 ? 
                        <div className={`${s.success_button}`} onClick={()=>setConfirmDriverLicenseModal(passport?.name)}>
                            <SuccessIcon/>
                            <span>{t('confirm_license')}</span>
                        </div> : null
                    }
                    {driverLicense?.moderationStatus !== 2 ? 
                    <div className={`${s.success_button} ${s.reject_button}`} onClick={()=>setDriverMessagesModal({type: 3, status: 2})}>
                        <RejectIcon/>
                        <span>{t('reject')}</span>
                    </div> : null
                    }
                </div>
                </div>
                : <h2 style={{textAlign: "center", width: "48%"}}>Driver license is missing</h2>
            }

            {viewImage ? <ViewImageModal link={viewImage} close={()=>setViewImage(false)}/> : null}
            {errorMessage ? <AlertModal content={errorMessage} setErrorMessage={setErrorMessage} /> : null}
            {confirmPassportModal? <ConfirmModal title={t('confirm_verification_passport')} text={`${t('success_verification_passport')} ${confirmPassportModal}?`} close={()=>setConfirmPassportModal(false)} success={()=>handlePassportVerification(1)} /> : null}
            {rejectPassportModal? <ConfirmModal title={t('confirm_verification_passport')} text={`${t('reject_verification_passport')} ${rejectPassportModal}?`} close={()=>setRejectPassportModal(false)} success={()=>setPassportMessagesModal({type: 2, status: 2})} /> : null}
            {confirmDriverLicenseModal? <ConfirmModal title={t('confirm_verification_driver_license')} text={`${t('success_verification_driver_license')} ${confirmDriverLicenseModal}?`} close={()=>setConfirmDriverLicenseModal(false)} success={()=>handleDriverLicenseVerification(1)} /> : null}
            {rejectDriverLicenseModal? <ConfirmModal title={t('confirm_verification_driver_license')} text={`${t('reject_verification_driver_license')} ${rejectDriverLicenseModal}?`} close={()=>setRejectDriverLicenseModal(false)} success={()=>handleDriverLicenseVerification(2)} /> : null}
            {passportMessagesModal ? <VerificationMessages type={passportMessagesModal.type} close={()=>setPassportMessagesModal(false)} confirm={(type, message)=>handlePassportVerification(passportMessagesModal.status, message.message)}/> : null}
            {driverMessagesModal ? <VerificationMessages type={driverMessagesModal.type} close={()=>setDriverMessagesModal(false)} confirm={(type, message)=>handleDriverLicenseVerification(driverMessagesModal.status, message.message)}/> : null}
        </div>
    )
}