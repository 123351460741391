import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { configureDashboardDate } from "../../../../utils/helpers/configureDashboardDate";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { ReactComponent as Loading } from "../../../../utils/icons/loading-icon.svg";

const AnalyticsAllPeriodGraph = ({
  dateRange,
  setDateRange,
  activeLines,
  isLoading,
}) => {
  const data = useSelector((store) => store.statisticsStore.statistics);
  const graphic_data = useMemo(
    () => configureDashboardDate(data.data, dateRange, setDateRange),
    [data.data, dateRange, setDateRange]
  );

  const lines = activeLines.map((el, index) => {
    return (
      <Line
        key={index}
        type="linear"
        name={el.title}
        dataKey={el.name}
        stroke={el.color}
        strokeWidth={3}
        strokeOpacity={1}
        dot={false}
        style={{ filter: `drop-shadow(0px 0px 4px ${el.color})` }}
      />
    );
  });

  return (
    <div className="statistic-analytics">
      <div className="statistic-analytics_graph_wrapper">
        {!isLoading ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={graphic_data}
              margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis dataKey="date" />
              <CartesianGrid />
              {lines}
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div className="statistic-analytics_graph_wrapper_loading_wrapper">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalyticsAllPeriodGraph;
