import React, {
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import {
	Form,
	FormCheck
} from "react-bootstrap"

const SubscriptionPreference = ( {
	arrPreference,
	handleBlur,
	values,
	handleChange,
	prefDateDateUpdateHandler,
	edit,
	form
} ) => {
	const [ preferenceForm, setPreferenceForm ] = useState( {} )

	const formDatePreferenceUpdateHandler = useCallback(( opt ) => {
		setPreferenceForm( { ...preferenceForm, ...opt } )
	}, [preferenceForm])

	useEffect( () => {
		prefDateDateUpdateHandler( preferenceForm )
	}, [ preferenceForm, formDatePreferenceUpdateHandler, prefDateDateUpdateHandler ] )

	useEffect( () => {
		let other = []
		let tehObj = {}
		let tehObjNumber = {}
		let arr = []
		arrPreference?.forEach( item => {
			if ( item?.type === 'boolean' ) {
				arr = [ ...arr, item?.key ]
			} else {

				other = [ ...other, item?.key ]
			}
		} )

		tehObj = arr?.reduce( ( a, i ) => (
			// eslint-disable-next-line no-sequences
			a[ i ] = false, a
		), {} )
		tehObjNumber = other?.reduce( ( a, i ) => (
			// eslint-disable-next-line no-sequences
			a[ i ] = '', a
		), {} )

		setPreferenceForm( { ...tehObjNumber, ...tehObj } )
	}, [ arrPreference, form ] )

	const arrPref = useMemo( () => {
		if ( edit ) return form?.prefInfoAff
		else return arrPreference
	}, [ arrPreference, edit, form?.prefInfoAff ] )

	return (
		<div className='subscription-create_wrapper-preference'>
			<h3 className='subscription-create_wrapper-date_block-title'>
				Переваги
			</h3 >
			<ul className='subscription-create_wrapper-preference_wrapper'>
				{
					arrPref?.map( ( item, index ) => (
							<li
								className='subscription-create_wrapper-preference_wrapper-li'
								key={ !edit ? item?.key + index + '94' : String( index + '94' ) }
							>
								<>
										<p className='subscription-create_wrapper-preference_wrapper-li_title'>
								{ !edit ? item?.key?.replaceAll( /_/gi, ' ' ) : item?.title }
								</p >
									{
										edit
											?
											item?.value === 'true' || item?.value === 'false'
												?
												<FormCheck
													type='checkbox'
													name={ item?.title }
													id={ item?.title }
													onBlur={ handleBlur }
													defaultChecked={ values?.preferenceForm?.item || item?.value === "true" }
													label={ (preferenceForm[ item?.title ] || item?.value === "true") ? "Tak" : 'Hi' }
													// label={item?.title}
													onKeyDown={ e => e.title === 'Enter' && e.preventDefault() }
													// placeholder='Введіть ціну'
													className='subscription-create_wrapper-preference_wrapper-li_input'
													onChange={ ( e ) => {
														// e.preventDefault()
														handleChange( e )
														formDatePreferenceUpdateHandler( {
															[ e.target.name ]: e.target.checked
														} )
														// setCheckedCheckboxValue(!checkedCheckboxValue)
													} }
												/>
												:
												<Form.Control
													type='number'
													name={ item?.title }
													onBlur={ handleBlur }
													defaultValue={ preferenceForm[ item?.title ] || item?.value }
													onKeyDown={ e => e.title === 'Enter' && e.preventDefault() }
													placeholder='Введіть число'
													className='subscription-create_wrapper-preference_wrapper-li_input'
													onChange={ ( e ) => {
														e.preventDefault()
														handleChange( e )
														formDatePreferenceUpdateHandler( {
															[ e.target.name ]: e.target.value
														} )
													} }
												/>
											:
											<>
											{
												item?.type === 'boolean'
													?
													<FormCheck
														type='checkbox'
														name={ item?.key }
														id={ item?.key }
														onBlur={ handleBlur }
														defaultChecked={ values?.preferenceForm?.item }
														label={ preferenceForm[ item?.key ] ? "Tak" : 'Hi' }
														// label={item?.key}
														onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
														// placeholder='Введіть ціну'
														className='subscription-create_wrapper-preference_wrapper-li_input'
														onChange={ ( e ) => {
															// e.preventDefault()
															handleChange( e )
															formDatePreferenceUpdateHandler( {
																[ e.target.name ]: e.target.checked
															} )
															// setCheckedCheckboxValue(!checkedCheckboxValue)
														} }
													/>
													:
													<Form.Control
														type='number'
														name={ item?.key }
														onBlur={ handleBlur }
														defaultValue={ preferenceForm[ item?.key ] }
														onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
														placeholder='Введіть число'
														className='subscription-create_wrapper-preference_wrapper-li_input'
														onChange={ ( e ) => {
															e.preventDefault()
															handleChange( e )
															formDatePreferenceUpdateHandler( {
																[ e.target.name ]: e.target.value
															} )
														} }
													/>
											}
									</>
									}
							</>
						</li >
						)
					)
				}
</ul >
</div >
	)
}

export default SubscriptionPreference