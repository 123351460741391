import { useEffect, useState } from 'react';
import { useCreateVerivicationMessageMutation, useDeleteVerivicationMessageMutation, useGetVerificationMessagesQuery, useUpdateVerivicationMessageMutation } from '../../store/apis/usersApi';
import s from './verificationMessagesModal.module.scss';
import Loader from '../LoaderContent';
import { NewTabs } from '../newTabs/NewTabs';
import LoaderIcon from '../../components/Loader'
import { ReactComponent as DeleteIcon } from '../../../utils/icons/delete-icon.svg'
import { ReactComponent as EditIcon } from '../../../utils/icons/edit-pencil.svg'
import addPlus from "../../../utils/icons/addPlus.svg"
import { CreateVirificationMessage } from './createVerificationMessage/createVerificationMessage';
import AlertModal from '../AlertModal';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';

const tabs = [
    { id: 0, title: "All" },
    { id: 1, title: "User" },
    { id: 2, title: "Verification" },
    { id: 3, title: "Passport/Driver's license" },
    { id: 4, title: "Advertising Companies" },
    { id: 5, title: "Adverts" }
]

export const VerificationMessages = ({confirm, status, type, close}) => {

// ### State
const [currentType, setCurrentType] = useState(type)
const [tab, setTab] = useState(0)
const [currentMessage, setCurrentMessage] = useState(false)
const [addMessageModal, setAddMessageModal] = useState(false)
const [errorMessage, setErrorMessage] = useState(false)
const [confirmModal, setConfirmModal] = useState(false)
const [confirmEmptyModal, setConfirmEmptyModal] = useState(false)

const {t, i18n} = useTranslation()

const {data: messages, isLoading: isMessagesLoading} = useGetVerificationMessagesQuery()
const [createVerificationMessage, {isLoading: isCreateLoading}] = useCreateVerivicationMessageMutation()
const [updateVerificationMessage, {isLoading: isUpdateLoading}] = useUpdateVerivicationMessageMutation()
const [deleteVerificationMessage, {isLoading: isDeleteLoading}] = useDeleteVerivicationMessageMutation()

// ### Effects
useEffect(()=>{
    if(type){
        setCurrentType(type)
        setTab(type)
    }
},[type]);

// ### Functions
function changeMessage(el){
    if(currentMessage.id === el.id) setCurrentMessage(false)
    else setCurrentMessage(el)
}

async function CreateMessage(data){
    setAddMessageModal(false)
    const response = await createVerificationMessage(data)
    if(response.error) setErrorMessage(response.error.data.message)
}

async function UpdateMessage(data){
    setAddMessageModal(false)
    const response = await updateVerificationMessage(data)
    if(response.error) setErrorMessage(response.error.data.message)
}

async function DeleteMessage(id){
    setAddMessageModal(false)
    const response = await deleteVerificationMessage({id})
    if(response.error) setErrorMessage(response.error.data.message)
}

// ### Views
const messageViews = messages?.filter(el => currentType === 0? true : el.type === currentType)?.map((el, index) => {
    return (
        <div key={index} className={`${s.message_wrapper} ${el.id === currentMessage.id? s.active : ''}`} onClick={()=>changeMessage(el)}>
            <div className={s.left}>
                <div className={s.checkbox_wrapper}>
                    <span className={s.checkbox}>&#10004;</span>
                </div>
                <p className={s.title}>{el.title?.[i18n.language]}</p>
                <div className={s.menu}>
                    <span className={s.edit} onClick={()=>{setAddMessageModal({values: el, type: false})}}><EditIcon/></span>
                    <span className={s.delete} onClick={()=>{setConfirmModal(el.id)}}><DeleteIcon/></span>
                </div>
            </div>
            <p className={s.message}>{el.message[i18n.language]}</p>
        </div>
    )
})
   
    return(
        <div className={s.modal_wrapper}>
            {
                errorMessage
                &&
                <AlertModal
                    content={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            }
            <div className={s.modal}>
                <div className={s.header}>
                    <h1 className={s.title}>{t('moderation_messages_title')}</h1>
                    <button
                        className='terms-wrapper_addButton'
                        style={{whiteSpace: 'nowrap', padding: 5, width: "fit-content", height: "fit-content"}}
                        type='button'
                        onClick={()=>{setAddMessageModal({type: tab})}}
                    >
                        <img
                            src={ addPlus }
                            alt='Add Plus'
                        />
                        {t('add')}
                    </button >
                </div>
                {
                    (isMessagesLoading || isCreateLoading || isUpdateLoading || isDeleteLoading) ? <Loader /> :
                    <>
                        <NewTabs items={tabs} tab={tab} setTab={(value)=>{setTab(value); setCurrentType(value)}}/>

                        <div className={s.content}>
                            {messageViews}
                        </div>

                        <footer className={s.footer}>
                            <button
                                type='submit'
                                className='template-content_buttons-save btn btn-primary'
                                onClick={()=>{
                                    if(currentMessage){
                                        alert(status)
                                        confirm(status || 2, currentMessage)
                                        close()
                                    }
                                    else{
                                        setConfirmEmptyModal(true)
                                    }
                                    
                                }}
                            >
                                    {false? <LoaderIcon/> : t('confirm')}
                            </button >
                            <button
                                type='button'
                                className='template-content_buttons-close btn btn-primary me-3'
                                onClick={close}
                            >
                                {t('back')}
                            </button >
                        </footer>
                    </>
                }
            </div>
            { addMessageModal? <CreateVirificationMessage close={()=>setAddMessageModal(false)} values={addMessageModal.values} update={UpdateMessage} create={CreateMessage} type={addMessageModal.type}/> : null }
            { confirmModal? <ConfirmModal title={t('moderation_delete_title')} text={t('moderation_confirm_delete')} close={()=>setConfirmModal(false)} success={()=>DeleteMessage(confirmModal)} /> : null }
            { confirmEmptyModal? <ConfirmModal title={t('moderation_not_change_message')} text={t('moderation_not_message_text')} close={()=>setConfirmEmptyModal(false)} success={()=>{confirm(status || 2, false); close()}} /> : null }
        </div>
    )
}