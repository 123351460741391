import React from 'react';
import s from './dealsMessagesModal.module.scss';
import { useTranslation } from 'react-i18next';

export const DealsMessagesModal = ({data})=>{

    const {t} = useTranslation()

    const DEALS_BUTTONS_TYPES = [
        {type: 1, title: t('confirm')},
        {type: 2, title: t('reject')},
        {type: 3, title: t('pay')},
        {type: 4, title: t('track_p')},
        {type: 5, title: t('track_t')},
        {type: 6, title: t('add_ttn')},
        {type: 7, title: t('complete_deal')},
        {type: 8, title: t('write_review')},
        {type: 9, title: t('arrival_time')},
        {type: 10, title: t('add_your_price')},
    ]
    
    const DEALS_BUTTONS_COLORS = [
        {type: 1, title: 'Accept'},
        {type: 2, title: 'Cancel'},
        {type: 3, title: 'Info'}
    ]

    const color = DEALS_BUTTONS_COLORS.find(color => color.type === data.color)
    const type = DEALS_BUTTONS_TYPES.find(type => type.type === data.type)
   
    return(
        <div className={s.modal_wrapper}>
            <div className={s.message_wrapper}>
                <div className={s.header}>
                    <div className={s.recipient_wrapper}>
                        <span>Color:</span>
                        <span className={s.recipient}>{color?.title}</span>
                    </div>
                    <div className={s.recipient_wrapper}>
                        <span>Type:</span>
                        <span className={s.recipient}>{type?.title}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}