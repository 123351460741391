import s from './infoIcon.module.scss';

export const InfoIcon = ({ text }) => {

    return(
        <div className={s.warpper_absolute}>
            <div className={s.wrapper_relative}>
                <div className={s.icon}>i</div>
                <div className={`${s.message_wrapper}`}>
                    <span className={s.message}>{text}</span>
                    <span className={s.arrow}></span>
                </div>
            </div>
        </div>
    )
}