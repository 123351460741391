import React, { useCallback } from 'react';
import s from './editEmailSenderModal.module.scss';
import { useRef } from 'react';
import AddButton from '../../../components/environmentTabl/AddButton';
import RejectButton from '../../../components/environmentTabl/RejectButton';
import { ReactComponent as CloseIcon } from '../../../../utils/icons/close-classik-icon.svg';
import { useState } from 'react';
import { useUpdateEmailNotificationSenderMutation } from '../../../store/apis/notificationApi';
import { EMAIL_REGEXP } from '../../../../utils/constants';
import AlertModal from '../../../components/AlertModal';
import { ConfirmModal } from '../../../components/ConfirmModal/ConfirmModal';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const EditEmailSenderModal = ({data, hide})=>{

    const modal = useRef()
    const modal_wrapper = useRef()
    const inputs = useRef({names: {}})
    const {t} = useTranslation()

    const [values, setValues] = useState(data);
    const [alertModal, setAlertModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('')
    const [status, setStatus] = useState(false)
    const [names, setNames] = useState([])

    const addName = useCallback((value) => {
        setNames(prev => {
            const num = prev.length
            const newState = [...prev]
            newState.push(
                <div key={num} className={s.row} id={num}>
                    {num? <div onClick={()=>removeName(num)}><CloseIcon /></div> : null}
                    <label>{t('ns_sender_name')}</label>
                    <input type="text" name={`name${num}`} value={values.names[`name${num}`]} onChange={(ev)=>changeNameValue(ev.target)} ref={(el)=>inputs.current.names[value[0]] = el}/>
                </div>
            )
            return newState
        })
    }, [values.names])

    useEffect(()=>{
        setNames([])
        Object.entries(values.names).forEach((el, index) => {
            addName(el)
        })
    },[values, addName]);

    const [updateSender, {isLoading: isUpdateLoading}] = useUpdateEmailNotificationSenderMutation()

    function hideModal(){
        modal.current.classList.add(s.hide_modal)
        modal_wrapper.current.classList.add(s.hide_wrapper)
        setTimeout(()=>{
            hide(false)
        },500)
    }

    function changeValue(el){
        setValues(prev => {
            const newState = {...prev}
            newState[el.name] = el.value;
            return newState
        })
    }

    function changeNameValue(el){
        setValues(prev => {
            const newState = {...prev}
            newState.names[el.name] = el.value;
            return newState
        })
    }

    async function success(){
        if(isUpdateLoading) return
    
        setAlertModal(false)
        setErrorMessage('')
        for(const key in inputs.current){
            inputs.current[key].classList?.remove(s.error)
        }

        if(!EMAIL_REGEXP.test(values.email)){
            inputs.current['email'].classList.add(s.error)
            setErrorMessage(t('_m_email_not_valid'))
            setAlertModal(true)
            return
        }
        
        if(!values.host || values.host?.length < 2
            || !values.port || values.port?.length < 2)
        {
            setErrorMessage(t('_m_form_not_valid'))
            setAlertModal(true)
            return
        }
        
        for(let key in inputs.current.names){
            
            if(inputs.current.names[key]?.value.length < 2){
                setErrorMessage(t('_m_form_not_valid'))
                setAlertModal(true)
                return
            }
        }
        const namesArr = names.map(el => values.names[`name${el.props.id}`])
        const data = {...values}
        data.names = namesArr
        const result = await updateSender(data)
        
        if(result.data?.code === 2020){
            setStatus(true)
            setConfirmMessage(`${t('sender')} ${result.data.message} ${t('successfully_updated')}`)
            setConfirmModal(true)
        }
        else if(result.error?.data?.code === 2021){
            setConfirmMessage(`${t('email')} ${result.error?.data?.message} ${t('_m_already_exist')}`)
            setConfirmModal(true)
        }
        else{
            setConfirmMessage(t('_m_sender_error'))
            setConfirmModal(true)
        }
    }


    function removeName(num){
        const new_inputs = {}
        for(let key in inputs.current.names){
            if(key !== `name${num}`) new_inputs[key] = inputs.current.names[key]
        }
        inputs.current.names = new_inputs;

        setNames(prev => {
            const newState = prev.filter(el => el.props.id !== num)
            return newState
        })

        setValues(prev => {
            const newState = {...prev}
            const newNames = {}
            Object.entries(prev.names).forEach(el => {
                if(el[0] !== `name${num}`) newNames[el[0]] = el[1]
            })
            newState.names = newNames
            return newState
        })
    }

    function closeModal(){
        if(status) hide(false)
        else setConfirmModal(false)
    }

    return(
        <div className={s.modal_wrapper} ref={modal_wrapper}>
            <div className={s.modal} ref={modal}>
                <p className={s.title}>{t('_m_add_sender')}</p>
                <div className={s.row}>
                    <label>{t('email')}</label>
                    <input type="text" name="email" disabled value={values.email} onChange={(ev)=>changeValue(ev.target)} ref={(el)=>inputs.current['email'] = el}/>
                </div>

                {names}

                <p className={s.add_name_button} onClick={addName}>{t('_m_add_name')}</p>

                <div className={s.row}>
                    <label>Host</label>
                    <input type="text" name="host" value={values.host} onChange={(ev)=>changeValue(ev.target)} ref={(el)=>inputs.current['host'] = el}/>
                </div>
                <div className={s.row}>
                    <label>Port</label>
                    <input type="text" name="port" value={values.port} onChange={(ev)=>changeValue(ev.target)} ref={(el)=>inputs.current['port'] = el}/>
                </div>
                <div className={s.row}>
                    <label>{t('new_password')} ({t('optional')})</label>
                    <input type="text" name="password" value={values.password} onChange={(ev)=>changeValue(ev.target)} ref={(el)=>inputs.current['password'] = el}/>
                </div>
                <div className={s.row_buttons}>
                    <div className={`${s.button_wrapper} ${isUpdateLoading? s.loading : ''}`} >
                        <AddButton title={t('add')} handler={success}/>
                    </div>
                    <RejectButton title={t('cancel')} handler={hideModal}/>
                </div>
            </div>
            {alertModal && <AlertModal content={errorMessage} setErrorMessage={()=>{}}/>}
            {confirmModal && <ConfirmModal title={confirmMessage} close={closeModal}/>}
        </div>
    )
}