import React from "react";
import s from "./feedbackItem.module.scss";
import { Stars } from "./stars/stars";
import { transformDate } from "../../../../../../../utils/helpers/TransformDate";

export const FeedbackItem = ({ title, comment, rating, date, icon }) => {
  return (
    <div className={s.feedback_wrapper} date={date}>
      <div className={s.feedback_header}>
        <div className={s.left}>
          <img src={icon} alt="service_icon" />
          <p className={s.title}>{title}</p>
        </div>
        <Stars number={rating} />
      </div>
      <div className={s.feedback_content}>
        <p>{comment}</p>
      </div>
      <p className={s.feedback_footer}>{transformDate(date)}</p>
    </div>
  );
};
