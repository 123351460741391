import React, {
	useEffect,
	useState
} from 'react'
import AlertModal from "../AlertModal"
import { Formik } from "formik"
import {
	Button,
	Form
} from "react-bootstrap"
import {
	useCreateSubscriptionMutation,
	useEditSubscriptionMutation
} from "../../store/apis/subscriptionApi"
import LoaderContent from "../LoaderContent";
import SubscriptionPreference from "../../pages/Subscriptions/Tabs/SubscriptionPreference";
import {useDispatch} from "react-redux";
import {
	createNewSubscriptionItem,
	editSubscriptionItem
} from "../../store/slices/subscriptionSlice";
import { addSubscription1Schema } from "../../../utils/validation/YupSubscription";
import { ARE_COMMON } from "../../../utils/textsContent";
import BlockWithTranslationFields from "../BlockWithTranslationFields";

const initialState = {
	uaSubscriptionName: '',
	enSubscriptionName: '',
	ruSubscriptionName: '',
	preference: {},
	tariff: [ { period: '', price: '' }, { period: '', price: '' } ],
	date: ''
}

const arrPreference = [
	{
		"key": "free_adverts",
		"type": "number"
	},
	{
		"key": "public_href",
		"type": "boolean"
	},
	{
		"key": "about_myself",
		"type": "number"
	},
	{
		"key": "media_descriptions",
		"type": "boolean"
	},
	{
		"key": "voice_in_text",
		"type": "number"
	},
	{
		"key": "voice_message",
		"type": "boolean"
	},
	{
		"key": "translate_in_real_time",
		"type": "boolean"
	},
	{
		"key": "delete_google_ads",
		"type": "boolean"
	},
	{
		"key": "delete_investor_ads",
		"type": "boolean"
	},
	{
		"key": "premium_mark",
		"type": "boolean"
	},
	{
		"key": "top_adverts_per_month",
		"type": "number"
	},
	{
		"key": "business_page",
		"type": "boolean"
	},
	{
		"key": "watching_statistic_per_days",
		"type": "number"
	},
	{
		"key": "adverts_remake",
		"type": "number"
	},
	{
		"key": "business_page_premium",
		"type": "boolean"
	},
	{
		"key": "recommend_adverts_in_market_page",
		"type": "boolean"
	},
	{
		"key": "adverts_filter_in_profile",
		"type": "boolean"
	},
	{
		"key": "personal_design_of_page",
		"type": "boolean"
	}
]


const AddEditSubscription = ( { onHide, edit, editDateForModal } ) => {
	const [ form, setForm ] = useState( edit ? editDateForModal : initialState )
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const [ prefDate, setPrefDate ] = useState( {} )
	const dispatch = useDispatch()

	const [ createSubscription, { isLoading: isCreateSubscriptionLoader } ] = useCreateSubscriptionMutation()
	const [ editSubscription, { isLoading: isEditSubscriptionLoader } ] = useEditSubscriptionMutation()
	const isLoading = isCreateSubscriptionLoader || isEditSubscriptionLoader

	const formDateUpdateHandler = ( opt ) => {
		setForm( { ...form, ...opt } )
	}
	const prefDateDateUpdateHandler = ( opt ) => {
		setPrefDate( { ...opt } )
	}
	// console.log('prefDate', prefDate)
	const handleSubmit = async ( values, { resetForm } ) => {
		const tariffMonth = values?.priceMonth ? { period: 'month', price: Number( values?.priceMonth ) } : null
		const tariffYear = values?.priceYear ? { period: 'year', price: Number( values?.priceYear ) } : null
		let arrTariff = []

		if ( tariffMonth ) arrTariff = [ ...arrTariff, tariffMonth ]
		if ( tariffYear ) arrTariff = [ ...arrTariff, tariffYear ]

		const formDate = {
			name: {
				ua: values?.uaSubscriptionName,
				en: values?.enSubscriptionName,
				ru: values?.ruSubscriptionName,
				pl: values?.plSubscriptionName,
			},
			preference: prefDate,
			tariff: arrTariff,
			date: Date.now()
		}
		try {
			// console.log( "formDate", formDate )
			if ( edit ) {
				const {
					error
				} = await editSubscription( { ...formDate, id: editDateForModal?.id } )
				!error && dispatch( editSubscriptionItem( { ...formDate, id: editDateForModal?.id } ) )
				!error && clearFormHideModal()
				error && setErrorMessage( error?.data?.message )
				resetForm( {} )
			} else {
				const {
					data,
					error
				} = await createSubscription( formDate )
				!error && dispatch( createNewSubscriptionItem( { ...formDate, id: data?.id } ) )
				!error && clearFormHideModal()
				error && setErrorMessage( error?.data?.message )
				resetForm( {} )
			}
		} catch ( e ) {
			console.log( e )
		}
	}

	const clearFormHideModal = () => {
		onHide()
		setPrefDate( {} )
		setForm( {} )
	}

	useEffect( () => {
		edit &&
		setForm( {
			...editDateForModal,
			priceMonth: editDateForModal?.tariff[0]?.price,
			priceYear: editDateForModal?.tariff[1]?.price
		} )
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ edit ] )

	// console.log( 'FORM', form )

	return (
		<div className='template-content'>
			<h1 className='terms-title'>
				{ edit ? `${ARE_COMMON?.EDITING} нової підписки` : `${ARE_COMMON?.CREATED} нової підписки` }
			</h1 >
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<Formik
				validateOnChange
				initialValues={ {
					uaSubscriptionName: !edit ? form?.uaSubscriptionName : editDateForModal?.name?.ua,
					enSubscriptionName: !edit ? form?.enSubscriptionName : editDateForModal?.name?.en,
					ruSubscriptionName: !edit ? form?.ruSubscriptionName : editDateForModal?.name?.ru,
					plSubscriptionName: !edit ? form?.plSubscriptionName : editDateForModal?.name?.pl,
					priceMonth: form?.priceMonth,
					priceYear: form?.priceYear
				} }
				validationSchema={ addSubscription1Schema }
				onSubmit={ handleSubmit }
				enableReinitialize
			>
				{
					( {
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty,
					} ) => (
						<Form
							className='add-edit-terms'
							onSubmit={ handleSubmit }
						>
						<div className='subscription-create_wrapper'>
							<BlockWithTranslationFields
								handleChange={handleChange}
								values={values}
								errors={errors}
								touched={touched}
								handleBlur={handleBlur}
								formDateUpdateHandler={formDateUpdateHandler}
								name={'SubscriptionName'}
								title={'subscription'}
							/>

							<div className='subscription-create_wrapper-date mt-3'>
								<div className='subscription-create_wrapper-date_block'>
									<h3 className='subscription-create_wrapper-date_block-title'>
										Price
									</h3 >
									<Form.Control
										type='number'
										name='priceMonth'
										onBlur={ handleBlur }
										defaultValue={ values?.priceMonth }
										onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
										placeholder='Введіть ціну'
										className={ `subscription-create_inp  ${ touched?.priceMonth ? "is-touch " : "" } ${
											errors?.priceMonth && touched?.priceMonth ? " is-invalid" : ""
										} ` }
										onChange={ ( e ) => {
											e.preventDefault()
											handleChange( e )
											formDateUpdateHandler( {
												[e.target.name]: e.target.value,
											} )
										} }
									/>
								</div >
								<div className='subscription-create_wrapper-date_block'>
									<h3 className='subscription-create_wrapper-date_block-title'>
										Period
									</h3 >

									<p className='subscription-create_wrapper-date_block-title'>
										Місяць
									</p >

								</div >
							</div >

							<div className='subscription-create_wrapper-date'>
								<div className='subscription-create_wrapper-date_block'>
									<h3 className='subscription-create_wrapper-date_block-title'>
										Price
									</h3 >
									<Form.Control
										type='number'
										name='priceYear'
										onBlur={ handleBlur }
										defaultValue={ values?.priceYear }
										onKeyDown={ e => e.key === 'Enter' && e.preventDefault() }
										placeholder='Введіть ціну'
										className={ `subscription-create_inp  ${ touched?.priceYear ? "is-touch " : "" } ${
											errors?.priceYear && touched?.priceYear ? " is-invalid" : ""
										} ` }
										onChange={ ( e ) => {
											e.preventDefault()
											handleChange( e )
											formDateUpdateHandler( {
												[e.target.name]: e.target.value,
											} )
										} }
									/>
								</div >
								<div className='subscription-create_wrapper-date_block'>
									<h3 className='subscription-create_wrapper-date_block-title'>
										Period
									</h3 >
									<p className='subscription-create_wrapper-date_block-title'>
										Рік
									</p >
								</div >
							</div >

							<SubscriptionPreference
								arrPreference={ arrPreference }
								handleBlur={ handleBlur }
								values={ values }
								handleChange={ handleChange }
								prefDateDateUpdateHandler={ prefDateDateUpdateHandler }
								edit={ edit }
								form={ form }
							/>


							<div className='d-flex align-items-center mt-3'>
								<Button
									type='submit'
									className='add-edit-htmls_save d-flex align-items-center subscription-create_button'
									style={ {
										maxHeight: '33px',
										display: 'flex',
										alignItems: 'center'
									} }
									// disabled={!isValid && dirty}
									onClick={ handleSubmit }
								>
									{ARE_COMMON?.SAVE} { isLoading && <LoaderContent /> }
									</Button >
									<Button
										type='button'
										className='add-edit-htmls_close subscription-create_button'
										style={ {
											maxHeight: '33px',
											display: 'flex',
											alignItems: 'center'
										} }
										onClick={ () => {
											onHide()
											clearFormHideModal()
										} }
									>
									{ARE_COMMON?.ABOLITION}
								</Button >
							</div >
						</div >
					</Form >
					) }
			</Formik >
    </div >
	)
}

export default AddEditSubscription