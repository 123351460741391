import React, {
	useEffect,
	useState
} from 'react'
import { useSelector } from "react-redux"
import DeleteModal from "../../components/DeleteModal"
import ControlPanels from "../../components/environmentTabl/ControlPanels"
import AlertModal from "../../components/AlertModal"
import { useGetBodyTypeMutation } from "../../store/apis/bodyTypeApi"
import AddEditBodyTypeItem from "../../components/modalsAddEdit/AddEditBodyTypeItem"

const BodyTypePage = () => {
	const { bodyType } = useSelector( ( state ) => state.bodyTypeStore )
	const [ addEditModalShow, setAddEditModalShow ] = useState( false )
	const [ deleteModalShow, setDeleteModalShow ] = useState( false )
	const [ openEditModal, setOpenEditModal ] = useState( false )
	const [ editDateForModal, setEditDateForModal ] = useState( {} )
	const [ deleteTerm, setDeleteTerm ] = useState( {} )
	const [ errorMessage, setErrorMessage ] = useState( '' )

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )

	const [ getBodyType, { isLoading: isGetBodyTypeLoading } ] = useGetBodyTypeMutation()

	useEffect( () => {
		const getBodyTypeList = async () => {
			await getBodyType()
		}
		getBodyTypeList()
	}, [] )


	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const showModalHandler = () => {
		setAddEditModalShow( !addEditModalShow )
		setOpenEditModal( false )
	}
	const hideModalHandler = () => {
		setAddEditModalShow( false )
		setOpenEditModal( false )
		setEditDateForModal( {} )
	}
	const toggleDeleteModal = ( value ) => {
		setDeleteTerm( value )
		setDeleteModalShow( !deleteModalShow )
	}

	const openEditModalHandler = ( value ) => {
		setEditDateForModal( value )
		setAddEditModalShow( true )
		setOpenEditModal( true )
	}

	const returnErrorForDel = ( mes ) => {
		setErrorMessage( mes )
	}
	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<AddEditBodyTypeItem
				show={ addEditModalShow }
				onHide={ hideModalHandler }
				edit={ openEditModal }
				editDateForModal={ editDateForModal }
			/>
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ deleteTerm }
				fromPage={ 'bodyType' }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				returnErrorForDel={ returnErrorForDel }
			/>
			<h1 className='terms-title'>
				Body Type
			</h1 >
			<ControlPanels
				isLoading={ isGetBodyTypeLoading }
				bigArrElements={ bodyType }
				addEditModalShow={ addEditModalShow }
				showModalHandler={ showModalHandler }
				openEditModalHandler={ openEditModalHandler }
				toggleDeleteModal={ toggleDeleteModal }
				fromPage={ 'bodyType' }
				searchTermValue={ searchTermValue }
				loaderSorting={ loaderSorting }
				setLoaderSorting={ setLoaderSorting }
				toggleModalSearch={ toggleModalSearch }
				showModalSearch={ showModalSearch }
				showSearchBeforeDel={ showSearchBeforeDel }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				valueSearchInputHandler={ valueSearchInputHandler }
			/>
		</div >
	)
}

export default BodyTypePage
