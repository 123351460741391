import React, {useEffect, useRef, useState} from 'react'
import s from './AddContactsOne.module.scss'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const AddContactsOne = ({name, sendGroup}) => {

    const button = useRef()
    const [values, setValues] = useState({"pib": '', "email": '', "phone": ''})
    const [error, setError] = useState(true)
	const navigate = useNavigate()
	const {t} = useTranslation()

    useEffect(()=>{
			if ( error ) button.current.style.opacity = 0.5
			else button.current.style.opacity = 1
    },[error])

    useEffect(()=>{
			if ( !values.pib
				|| values.pib.length < 3
				|| !values.email
				|| values.email.length < 3
				|| !values.phone
				|| values.phone.length < 3
			) setError( true )
			else setError( false )
    },[values])

    function changeValues(el){
			setValues( prev => {
				const value = el.value
				const type = el.getAttribute( 'name' )
				const newState = { ...prev }
				newState[ type ] = value
				return newState
			} )
    }

    async function makeData(){
			if ( error ) return
			const body = {
				name: name,
				users: [],
				contacts: [ values ]
			}
			sendGroup( body )
    }

    return (
			<div className={ s.content }>
				<h2 >{t('_m_enterData')}</h2 >
				<div className={ s.input_wrapper }>
					<label >{t('name')}</label >
					<input
						type='text'
						name='pib'
						value={ values.pib }
						onChange={ ev => changeValues( ev.target ) }
					/>
				</div >
				<div className={ s.input_wrapper }>
					<label >{t('email')}</label >
					<input
						type='text'
						name='email'
						value={ values.email }
						onChange={ ev => changeValues( ev.target ) }
					/>
				</div >
				<div className={ s.input_wrapper }>
					<label >{t('phone')}</label >
					<input
						type='text'
						name='phone'
						value={ values.phone }
						onChange={ ev => changeValues( ev.target ) }
					/>
				</div >
				<button
					className='terms-wrapper_addButton'
					ref={ button }
					onClick={ makeData }
				>{t('download')}</button >
			</div >
    )
}