import React, { useEffect, useState } from 'react'
import ValidationRulesRegular from "./ValidationRulesRegular"
import { Button } from "react-bootstrap"
import { useSelector } from 'react-redux'

const validationFormDefault = {
	type: 'Regular',
	validationValue: 1,
}

const ValidationRulesRegularBuilder = ({
	form,
	edit,
	handleBlur,
	values,
	touched,
	errors,
	handleChange,
	formDateUpdateHandler
}) => {
	const [arrValidations, setArrValidations] = useState([values])
	const [validationForm, setValidationForm] = useState(validationFormDefault)
	const [addNewBlockValidationForm, setAddNewBlockValidationForm] = useState(true)
	const [disabledBlock, setDisabledBlock] = useState(false)
	const langs = useSelector(store => store.langStore.langs)

	useEffect(()=>{
		langs.forEach(el => {
			validationFormDefault[`${el.langIndex}DescriptionValidation`] = ''
		})
	},[langs]);

	const formDateValidationUpdateHandler = (opt) => {
		setValidationForm({...validationForm, ...opt})
	}

	const toggleValidationBlockHandler = (value) => {
		setAddNewBlockValidationForm(value)
		setDisabledBlock(false)
	}

	const addValidationForm = () => {
		toggleValidationBlockHandler(false)
		setDisabledBlock(true)
		let valueRexExp = ''

		if (validationForm?.validationValue === 1) {
			// valueRexExp = `^(?=.{1,${validationForm?.quantity}}$)`
			valueRexExp = `^.{0,${validationForm?.quantity}}$`
		} else if (validationForm?.validationValue === 2) {
			// valueRexExp = `^(?=.{${validationForm?.quantity}}$)`
			valueRexExp = `^.{${validationForm?.quantity},}$`
		} else if (validationForm?.validationValue === 3) {
			// valueRexExp = `/[^${validationForm?.string?.trim().replaceAll(' ', '')}]/`
			valueRexExp = `^(?!.*${validationForm?.string?.trim().replaceAll(' ', '')}).*`
		} else if (validationForm?.validationValue === 4) {
			valueRexExp = `^.{${validationForm?.quantityStart}-1}[A-Z]{${validationForm?.quantityEnd}+1}`
		} else if (validationForm?.validationValue === 5) {
			valueRexExp = `^.{${validationForm?.quantityStart}-1}[a-z]{${validationForm?.quantityEnd}+1}`
		} else if (validationForm?.validationValue === 6) {
			// eslint-disable-next-line no-useless-escape
			valueRexExp = `^.{${validationForm?.quantityStart - 1}}\d{${validationForm?.quantityEnd + 1}}`
		} else if (validationForm?.validationValue === 7) {
			// eslint-disable-next-line no-useless-escape
			valueRexExp = `^(http|https)://[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}`
		} else if (validationForm?.validationValue === 8) {
			// eslint-disable-next-line no-useless-escape
			valueRexExp = `^\+?(\d[\d\- ]{7,}\d)$`
		} else if (validationForm?.validationValue === 9) {
			// eslint-disable-next-line no-useless-escape
			valueRexExp = `^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$`
		} else if (validationForm?.validationValue === 10) {
			// eslint-disable-next-line no-useless-escape
			valueRexExp = `^\b(\w+\b\W*){0,${validationForm?.quantity}}$`
		} else if (validationForm?.validationValue === 11) {
			// eslint-disable-next-line no-useless-escape
			valueRexExp = `^\b(\w+\b\W*){${validationForm?.quantity},}$`
		}

		const descriptions = {}

		langs.forEach(el => {
			descriptions[el.langIndex] = validationForm?.[`${el.langIndex}DescriptionValidation`]
		})

		const tehForm = {
			tehId: Date.now(),
			type: validationForm?.type,
			value: valueRexExp,
			validationValue: validationForm?.validationValue,
			quantity: validationForm?.quantity,
			string: validationForm?.string,
			quantityStart: validationForm?.quantityStart,
			quantityEnd: validationForm?.quantityEnd,
			description: descriptions
		}
		setArrValidations([...arrValidations, tehForm])
		setValidationForm(validationFormDefault)
		const tehFormDate = {
			tehId: Date.now(),
			type: validationForm?.type?.toLocaleLowerCase(),
			value: valueRexExp,
			description: descriptions
		}
		const arr = form?.validationRules?.length !== 0 ? [...form?.validationRules, tehFormDate] : [tehFormDate]
		formDateUpdateHandler({...form, validationRules: arr})
	}

	const removeBlockHandler = (id) => {
		const res = arrValidations?.filter(i => i?.tehId !== id)
		setArrValidations(res)
		const arr = form?.validationRules?.filter(i => i?.tehId !== id)
		formDateUpdateHandler({...form, validationRules: arr})
	}

	const hideBlockHandler = (status) => {
		setAddNewBlockValidationForm(status)
	}

	return (
		<div className='template-content_nameBlock mt-4'>
			<h2 className='template-content_nameBlock-title'>
				5. Validation
			</h2 >
			{
				arrValidations?.map((val, index) => (
					<ValidationRulesRegular
						key={val?.tehId}
						form={form}
						edit={edit}
						handleBlur={handleBlur}
						touched={touched}
						errors={errors}
						handleChange={handleChange}
						formDateUpdateHandler={formDateValidationUpdateHandler}
						validationForm={val}
						addValidationForm={addValidationForm}
						toggleValidationBlockHandler={toggleValidationBlockHandler}
						disabled={disabledBlock}
						number={arrValidations?.length > 0 ? index + 1 : 0}
						removeBlockHandler={removeBlockHandler}
						hideBlockHandler={hideBlockHandler}
						// editValidationFormHandler={editValidationFormHandler}
					/>
				))
			}
			{
				addNewBlockValidationForm
				&&
				<ValidationRulesRegular
					form={form}
					edit={edit}
					handleBlur={handleBlur}
					values={values}
					touched={touched}
					errors={errors}
					handleChange={handleChange}
					formDateUpdateHandler={formDateValidationUpdateHandler}
					validationForm={validationForm}
					addValidationForm={addValidationForm}
					toggleValidationBlockHandler={toggleValidationBlockHandler}
					hideBlockHandler={hideBlockHandler}
				/>
			}
			<Button
				variant='light'
				className='ms-2 bg-transparent'
				type='button'
				onClick={() => hideBlockHandler(true)}
			>
				Додати валідацію
				</Button >
		</div >
	)
}
export default ValidationRulesRegularBuilder