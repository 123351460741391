import React from 'react';
import s from './tabs.module.scss';

export const Tabs = ({activeTab, setActiveTab, tabs})=>{
   
    const items_view = tabs.map((el, index) => {
        return(
            <span 
                key={index}
                style={{borderBottom: activeTab === el.type? '3px #6D63D3 solid':'3px transparent solid'}}
                onClick={()=>setActiveTab(el.type)}
            >
                {el.title}
            </span>
        )
    })

    return(
        <div className={s.tabs_wrapper}>
            {items_view}
        </div>
    )
}