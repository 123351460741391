import React, {useEffect, useState} from 'react';
import {ARE_COMMON, PAGES_NAME} from "../../../utils/textsContent";
import s from "./ReferralLinks.module.scss";
import {useSelector} from "react-redux";
import {
    useChangeReferenceArchiveMutation,
    useGetReferralLinksMutation,
} from "../../store/apis/referralLinksApi";
import CopyIcon from "../../../utils/icons/copy-icon.svg";
import ArchiveIcon from '../../../utils/icons/archive-icon.svg';
import {ReactComponent as EyeIcon} from '../../../utils/icons/eye-open.svg';
import AddEditReferralLinks from "../../components/modalsAddEdit/AddEditReferralLinks";
import AddButton from "../../components/environmentTabl/AddButton";
import LoaderContent from "../../components/LoaderContent";
import clipboard from "clipboardy";
import { Tabs } from '../../components/Tabs/Tabs';
import { ConfirmModal } from '../../components/ConfirmModal/ConfirmModal';
import AlertModal from '../../components/AlertModal';
import { transformDate } from '../../../utils/helpers/TransformDate';
import { ReferralStatisticsModal } from '../../components/ReferralStatisticsModal/referralStatistics';
import { useTranslation } from 'react-i18next';

export const ReferralLinks = ()=> {
    const { referralLinks } = useSelector(state => state.referralLinksStore)
    const [ addEditModalShow, setAddEditModalShow ] = useState( false )
    const [ editDateForModal, setEditDateForModal ] = useState( {} )
    const [activeTab, setActiveTab] = useState(0);
    const [confirmModal, setConfirmModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false);
    const [statistics, setStatistics] = useState(false)
    const {t} = useTranslation()

    const [ getReferralLinksMutation, {isLoading: getReferralLinksLoading} ] = useGetReferralLinksMutation();
    const [ changeReferenceArchive, {isLoading: ChangeArchiveLoading} ] = useChangeReferenceArchiveMutation()

    useEffect(() => {
        getReferralLinksMutation(activeTab);
    }, [activeTab, getReferralLinksMutation]);

    const handleClick = (text) => {
        clipboard.write(text).then(r => {
            alert("Скопійовано силку");
        });
    };

    const showModalHandler = () => {
        setAddEditModalShow( !addEditModalShow )
    }

    const hideModalHandler = () => {
        setAddEditModalShow( false )
        setEditDateForModal( {} )
    }

    async function addToArchive(el){
        const new_type = el.type? 0 : 1
        const {error} = await changeReferenceArchive({id: el.id, type: new_type})
        if(error) setErrorMessage(error.data.error)
        else{
            getReferralLinksMutation(activeTab);
        }
    }

    if (getReferralLinksLoading || ChangeArchiveLoading) {
        return <LoaderContent />
    }

        return (
            <div className='terms'>
                <AddEditReferralLinks
                    show={ addEditModalShow }
                    onHide={ hideModalHandler }
                    editDateForModal={ editDateForModal }
                    getNewLinks={()=>getReferralLinksMutation(activeTab)}
                />
                {
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
				}
                <h1 className='terms-title'>
                    {t('_m_referal_links')}
                </h1 >
                <AddButton
                    title={t('add')}
                    handler={showModalHandler}
                />
                <div className={`terms-wrapper ${s.content}`}>
                    <div className={s.tabs_wrapper}>
                        <Tabs tabs={[{type: 0, title: t('active')}, {type: 1, title: t('archive')}]} activeTab={activeTab} setActiveTab={setActiveTab} />
                    </div>
                    <div className={s.content_wrapper}>
                        <table>
                            <thead>
                            <tr>
                                <td>ID</td>
                                <td>{t('name')}</td>
                                <td>{t('_m_numberOfTransitions')}</td>
                                <td>{t('_m_numberOfRegistrations')}</td>
                                <td>{t('date_created')}</td>
                                <td>{t('link')}</td>
                                <th className='terms-wrapper_tabl-trow_item col-1'></th >
                            </tr>
                            </thead>
                            <tbody>
                              {referralLinks?.map((el, index) => (
                                  <tr key={index}>
                                      <td>{el.id}</td>
                                      <td>{el.name}</td>
                                      <td>{el.numberOfClicks}</td>
                                      <td>{el.numberOfRegistrations}</td>
                                      <td>{transformDate(el.dateCreated)}</td>
                                      <td>{el.link}</td>
                                      <td className='users-wrapper_tabl-body_item col-1'>
                                          <div className='users-wrapper_tabl-body_item-wrapper_icons'>
                                            <button
                                                className={el.statistics? s.statistics_button_active : ''}
                                                type='button'
                                                title='Статистика'
                                                onClick={() => el.statistics? setStatistics(el) : null}
                                            ><EyeIcon/></button >
                                            <button
                                                type='button'
                                                title='Copy'
                                                onClick={() => handleClick(el.referralLink)}
                                            >
                                                <img
                                                    src={CopyIcon}
                                                    alt='Copy'
                                                />
                                            </button >
                                            <button
                                                type='button'
                                                title='add to archive'
                                                onClick={() => setConfirmModal(el)}
                                            >
                                                <img
                                                    src={ArchiveIcon}
                                                    alt='add to archive'
                                                />
                                            </button >
                                          </div >
                                      </td >
                                  </tr>
                              ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {confirmModal && <ConfirmModal title={confirmModal.type? t('restore_from_archive') : t('add_to_archive')} text={confirmModal.type? t('_m_confirmRestoreArchive') : t('_m_confirmAddArchive')} success={()=>addToArchive(confirmModal)} close={()=>setConfirmModal(false)}/>}
                {statistics? <ReferralStatisticsModal data={statistics} close={()=>setStatistics(false)} /> : null}
            </div>
        )
}