import React, {
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import s from './aboutList.module.scss'
import AddIconWhite from "../../../../utils/icons/addPlusWhite.svg"
import AddIconColor from "../../../../utils/icons/addPlusColor.svg"
import {ReactComponent as EmailIcon} from "../../../../utils/icons/email_icon.svg"
import {ReactComponent as CmcIcon} from "../../../../utils/icons/sms_icon.svg"
import {ReactComponent as ViberIcon} from "../../../../utils/icons/viber_icon.svg"
import { ReactComponent as UserIcon } from '../../../../utils/icons/avatar-default.svg'
import {
	addressBookApi,
	useDeleteGroupUsersMutation,
	useGetGroupMutation,
	useGetGroupsMutation
} from "../../../store/apis/addressBookApi"
import LoaderContent from "../../../components/LoaderContent"
import {
	useNavigate,
	useSearchParams
} from "react-router-dom"
import Remove from '../../../../utils/icons/delete-red-icon.svg'
import CloseIcon from '../../../../utils/icons/close-classik-icon-violet.svg'
import NotificationEmailPage from '../../NotificationsPage/NotificationEmail/NotificationEmalePage'
import NotificationViberPage from '../../NotificationsPage/NotificationViber/NotificationViberPage'
import { useSelector } from 'react-redux'
import SearchItemsInput from '../../../components/SearchItemsInput'
import { searchItemsHelper } from '../../../../utils/helpers/SearchItemsHelper'
import { arrayBreakdownHelper } from '../../../../utils/helpers/ArrayBreakdownHelper'
import SelectShowRecords from "../../../components/SelectShowRecords";
import { transformDate } from '../../../../utils/helpers/TransformDate'
import DeleteModal from '../../../components/DeleteModal'
import PaginationCustom from '../../../components/PaginationCustom'
import NotificationSMSPage from '../../NotificationsPage/NotificationSMS/NotificationSMSPage'
import { useTranslation } from 'react-i18next'

export const AboutList = () => {
	const { groups } = useSelector( state => state.addressBookStore )
	const [ searchParams ] = useSearchParams()
	const group_id = searchParams.get( "id" )
	const navigate = useNavigate()
	const modal = useRef( [] )
	const selected_menu = useRef()
	const selected_inputs = useRef( {} )
	const checkbox_all = useRef()
	const [countInPage, setCountInPage] = useState(20)
	const [currentPage, setCurrentPage] = useState(1)
	const [deleteModalShow, setDeleteModalShow] = useState(false);

	const [ selected, setSelected ] = useState( [] )
	const [ openAddEditEmailList, setOpenAddEditEmailList ] = useState( false )
	const [ openAddEditViberList, setOpenAddEditViberList ] = useState( false )
	const [openAddEditSMSList, setOpenAddEditSMSList] = useState(false)

	const [ groupUsersList, setGroupUsersList ] = useState( [] )
	const [ groupName, setGroupName ] = useState( '' )
	const [ dateCreate, setDateCteate ] = useState( '' );
	const [ totalUsers, setTotalUsers ] = useState(0)
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showSmallTermArr, setShowSmallTermArr ] = useState( [] )
	const { t } = useTranslation()

	const [ getGroup, { isLoading: isGetGroupLoading } ] = useGetGroupMutation()

	const isLoader = isGetGroupLoading

	const toggleAddEditEmailListPage = () => setOpenAddEditEmailList( !openAddEditEmailList )
	const toggleAddEditViberListPage = () => setOpenAddEditViberList( !openAddEditViberList )
	const toggleAddEditSMSListPage = () => setOpenAddEditSMSList( !openAddEditSMSList )

	useEffect( () => {
		const getNotificationList = async () => {
			const { data } = await getGroup( { id: group_id, page: currentPage, limit: countInPage } )
			setGroupName( data?.name )
			setGroupUsersList( data?.data )
			setTotalUsers(data.totalUsers)
			setDateCteate( transformDate(new Date(data?.dateCreate)) )
			setSelected( [] )
			selected_inputs.current = {}
			checkbox_all.current.checked = false
		}
		group_id !== undefined && getNotificationList()
	}, [ group_id, currentPage, countInPage ] )

	useEffect( () => {
		if ( selected_menu.current ) {
			if ( selected.length ) selected_menu.current.style.cssText = `height: 30px`
			else selected_menu.current.style.cssText = `height: 0px`
		}
	}, [ selected ] )


	function toggleMenu( id ) {
		modal[ `${ id }` ].classList.toggle( s.active )
	}

	function changeSelected( el, event ) {
		if ( event ) {
			setSelected( prev => {
				const newState = [...prev]
				newState.push( el )
				return newState
			} )
		} else {
			setSelected( prev => {
				const newState = prev.filter( item => {
					if ( el === item ) return false
					else return true
				} )
				return newState
			} )
			if(selected.length === 1) checkbox_all.current.checked = false
		}
	}

	function changeAllSelected( event ) {
		for ( let el in selected_inputs.current ) {
			if ( event ) {
				if(selected_inputs.current[ el ]){
					selected_inputs.current[ el ].checked = true
					const newSelectedItems = [];
					for(let key in selected_inputs.current){
						if(selected_inputs.current[key]) newSelectedItems.push(selected_inputs.current[key])
					}
					setSelected(newSelectedItems)
				}
			} else {
				if(selected_inputs.current[ el ]){
					selected_inputs.current[ el ].checked = false
					setSelected( [] )
				}
			}
		}
	}

	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}
	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}

	const searchHandler = ( e ) => {
		setLoaderSorting( true )
		const itemsDate = searchItemsHelper( groupUsersList, 'userGroup', searchTermValue )
		setShowSmallTermArr( itemsDate )
		timerLoaderHandler()
	}

	const clearSearchHandler = () => {
		toggleModalSearch( false )
		setLoaderSorting( true )
		valueSearchInputHandler( '' )
		const teh = arrayBreakdownHelper( groupUsersList, 1 )
		setShowSmallTermArr( [ [], ...teh ] )
		timerLoaderHandler()
	}

	const timerLoaderHandler = () => {
		setTimeout( () => setLoaderSorting( false ), 1000
		)
	}

	const memoArrSearch = useMemo( () => {
		if ( searchTermValue === '' ){
			return groupUsersList
		} else {
			return showSmallTermArr
		}
	}, [groupUsersList, showSmallTermArr] )

	function toggleDeleteModal(){
		if(selected.length === 1) {
			setSelected([])
			for(let key in selected_inputs.current){
				selected_inputs.current[key].checked = false
			}
		}
		setDeleteModalShow(!deleteModalShow)
	}

	return (
		<div className='terms'>
			<DeleteModal
				show={ deleteModalShow }
				onHide={ toggleDeleteModal }
				value={ {users: selected, group_id: group_id, title: `${selected.length} ${t('items')}`} }
				fromPage={ 'aboutAddressBook' }
				setShowSearchBeforeDel={async ()=>{
					setSelected([])
					checkbox_all.current.checked = false
					setDeleteModalShow(!deleteModalShow)
					const { data } = await getGroup( { id: group_id, page: currentPage, limit: countInPage } )
					setGroupUsersList( data?.data )
				} }
				returnErrorForDel={ ()=>{} }
			/>
			{
				(openAddEditEmailList || openAddEditViberList || openAddEditSMSList)
					?
					(
						openAddEditEmailList
						&&
						<NotificationEmailPage
							toggleAddEditEmailListPage={ toggleAddEditEmailListPage }
							groupsList={ groups?.data }
							dataNotification={undefined}
							isDoubleNotification={false}
							setIsDoubleNotification={()=>{}}
							setIsLoading={()=>{}}
						/>
					)
					||
					(
						openAddEditViberList
						&&
						<NotificationViberPage
							toggleAddEditViberListPage={ toggleAddEditViberListPage }
							groupsList={ groups?.data }
							dataNotification={undefined}
							isDoubleNotification={false}
							setIsDoubleNotification={()=>{}}
							setIsLoading={()=>{}}
						/>
					)
					||
					(
						openAddEditSMSList
						&&
						<NotificationSMSPage
							toggleAddEditViberListPage={toggleAddEditSMSListPage}
							groupsList={groups?.data}
							dataNotification={undefined}
							isDoubleNotification={false}
							setIsDoubleNotification={()=>{}}
							setIsLoading={()=>{}}
						/>)
					:
					<>
						<h1 className='terms-title d-flex align-items-center justify-content-between'>{ groupName }
							<button
								className='terms-wrapper_addButton rbac-wrapper_backButton mb-0'
								onClick={ () => navigate( -1 ) }
							>
							<img
								src={ CloseIcon }
								alt='close'
							/>
						</button >
						</h1 >
						<div className={ s.info_wrapper }>
							<span className={ s.status_dot }></span >
							<span className={ s.status_text }>{t('active')}</span >
							<span className={ s.status_date }>{`${t('created')} ${dateCreate}`}</span >
						</div >
						<div className='terms-wrapper'>
							<header className={ s.header }>
								<div className={ s.row }>
									<button
										className={ `terms-wrapper_addButton ${ s.add_button }` }
										onClick={ () => navigate( `/address-book/new?name=${ groupName }&id=${ group_id }` ) }
									>
										<img
											src={ AddIconWhite }
											alt='AddIcon'
										/>
										{t('_m_addContact')}
									</button >
									<button
										className={ `terms-wrapper_addButton add_button_white template-content_buttons-close` }
										onClick={ toggleAddEditEmailListPage }
									>
										<EmailIcon/>
										{t('_m_createMailings')}
									</button >
									
									<button
										className={ `terms-wrapper_addButton add_button_w template-content_buttons-close` }
										onClick={ toggleAddEditViberListPage }
									>
										<ViberIcon/>
										{t('_m_send_viber')}
									</button >
									<button
										className={ `terms-wrapper_addButton add_button_w template-content_buttons-close` }
										onClick={ toggleAddEditSMSListPage }
									>
										<CmcIcon/>
										{t('_m_send_sms')}
									</button >
								</div >
								<div className={ `${ s.search_wrapper } d-flex align-items-center justify-content-between` }>
										<div className='search_wrapper d-flex'>
												<SearchItemsInput
													toggleModalSearch={ toggleModalSearch }
													searchTermValue={ searchTermValue }
													valueSearchInputHandler={ valueSearchInputHandler }
													searchHandler={ searchHandler }
													clearSearchHandler={ clearSearchHandler }
												/>
											<div className={ `${ s.tools_wrapper } ms-2` }>
											<div className={s.pagination_wrapper}>
												<PaginationCustom 
													itemsCount={countInPage}
													counterAllItems={totalUsers}
													currentPage={currentPage}
													setCurrentPage={setCurrentPage}
												/>
											</div>
											</div >
										</div >
									<div className={ s.right_block }>
										<SelectShowRecords setCountShow={setCountInPage}/>
									</div >
								</div >
								<div
									className={ s.selected_menu }
									ref={ selected_menu }
								>
									<p >{t('selected_records')}: <span >{selected.length}</span ></p >
									<button className={ `terms-wrapper_addButton ${ s.add_button_white }`} 
									onClick={()=>setDeleteModalShow(true)}>
										<span>{t('delete')}</span >
									</button >
								</div >
							</header >
							<div className={ s.content }>
								<table className={ s.table }>
									<thead >
										<tr >
											<td className={ s.checkbox }>
												<input
													type='checkbox'
													ref={checkbox_all}
													onChange={ ( ev ) => changeAllSelected( ev.target.checked ) }
												/>
											</td >
											<td >
												<span >
													{t('email')}
												</span >
											</td >
											<td >
												<span >
													{t('phone')}
												</span >
											</td >
											<td >
												<span >
													{t('name')}
												</span >
											</td >
											<td >
												<span >
													{t('_m_lastActivity')}
												</span >
											</td >
											<td >
												<span >
													{t('_m_createdDate')}
												</span >
											</td >
											<td >
											</td >
										</tr >
									</thead >
									<tbody >
									{
										isLoader
											?
											<LoaderContent />
											:
											memoArrSearch && memoArrSearch?.map( el => (
												<tr key={ el.email }>
													<td>
														<input
															type='checkbox'
															id={el.id}
															email={el.email}
															onChange={ ( ev ) => changeSelected( ev.target, ev.target.checked ) }
															ref={ ( ev ) => selected_inputs.current[ `${ el.email }` ] = ev }
														/>
													</td >
													<td className={s.t_user_name}>
														{el.id? <UserIcon/> : null}
														<span className={ s.name } 
														onClick={()=> el.id? navigate(`/users/${el.id}`, { state: el.id }):null}
														style={{cursor: el.id? 'pointer':''}}
														>
															{ el.email }
														</span >
													</td >
													<td >
														<span >
															{ el.phone }
														</span >
													</td >
													<td >
														<span >
															{ el.pib }
														</span >
													</td >
													<td >
														<span >
															-
														</span >
													</td >
													<td >
														<span >
															-
														</span >
													</td >
													<td className={ s.td_menu }>
														<div
															className='tools_modal'
														>
															<div className='item delete'>
																<span
																	id={ el.email }
																	onClick={ ()=>{
																		setSelected([el])
																		setDeleteModalShow(true)
																	} }
																>
																	{/*Видалити контакт*/ }
																	<img
																		src={ Remove }
																		alt='remove item'
																		className='template-content_valueBlock-list_li-remove'
																	/>
																</span >
															</div >
															<div
																className='modal_wrapper'
																onClick={ () => toggleMenu( el.email ) }
															>
															</div >
														</div >
													</td >
													</tr >
											) )
									}
									</tbody >
								</table >
							</div >
						</div >
					</>
			}
		</div >
	)
}