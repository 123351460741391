import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	MODERATION_PAYMENT_API,
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const moderationPaymentApi = createApi( {
	reducerPath: "moderationPaymentApi",
	baseQuery: fetchBaseQuery( {
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{ getState }
		) => {
			const { token } = getState().userStore;
			if ( token ) {
				headers.set( "Authorization", `Bearer ${ token }` )
			}
			return headers
		}
	} ),
	endpoints: ( builder ) => ({
		getModerationPayments: builder.mutation( {
			query: ( { page, limit, sortBy, methods, prices, dealSide, member } ) => (
				{
					url: `${MODERATION_PAYMENT_API.GET_MODERATION_PAYMENT}?
						${page}
						${limit}
						${sortBy?.field || ''}
						${sortBy?.way || ''}
						${prices?.min || ''}
						${prices?.max || ''}
						${methods || ''}
						${dealSide || ''}
						${member?.id || ''}
						${member?.type || ''}
					`,
					method: "GET"
				})
		} ),
		getModerationPaymentsStatus: builder.mutation( {
			query: ( body ) => (
				{
					url: `${ MODERATION_PAYMENT_API.GET_MODERATION_PAYMENT_STATUS }`,
					method: "POST",
					body
				})
		} ),
	})
} )

export const {
	useGetModerationPaymentsMutation,
	useGetModerationPaymentsStatusMutation,
} = moderationPaymentApi
