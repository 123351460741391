import React, {
	useEffect,
	useState
} from 'react'
import LoaderContent from "../LoaderContent"
import PaginationCustom from "../PaginationCustom"
import UsersTabl from "../../pages/Users/UsersTabl"
import SearchItemsInput from "../SearchItemsInput"
import { useGetUsersMutation } from "../../store/apis/usersApi"
import AddButton from './AddButton'
import { useDispatch } from 'react-redux'
import {
	resetDateChangeUserAdvertising,
	resetDateChangeUsersForAddressBookArr,
	showChangeUserAdvertisingModal
} from '../../store/slices/advertisingSlice'
import { LogoutUserHandler } from '../../../utils/helpers/LoqoutUserHelper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ARE_COMMON } from "../../../utils/textsContent";
import SelectShowRecords from "../SelectShowRecords";
import RejectButton from './RejectButton'
import { useTranslation } from 'react-i18next'

const ControlPanelsForUsers = ({
	bigArrElements,
	fromPage,
	toggleModalSearch,
	showModalSearch,
	showSearchBeforeDel,
	setShowSearchBeforeDel,
	setErrorMessage,
	setGroupUsers,
	groupUsers
}) => {
	const [params, setParams] = useSearchParams()
    const query_page = params.get('page')
    const query_limit = params.get('limit')

	const [countShowTerms, setCountShowTerms] = useState(query_limit || 20)
	const [currentPage, setCurrentPage] = useState(query_page || 1)
	const [sortColumnTable, setSortColumnTable] = useState()
	const [changeTypeStatus, setChangeTypeStatus] = useState('all')
	const [changeOrderSort, setChangeOrderSort] = useState(-1)
	const [searchTermValue, setSearchTermValue] = useState('')
	const [searchChangeTermValue, setSearchChangeTermValue] = useState('')
	const [groupLength, setGroupLength] = useState(null)
	const [currentGroupName, setCurrentGroupName] = useState('Всi');
	const [moderationLength, setModerationLength] = useState(false)
	const [docsLength, setDocsLength] = useState(false)
	const [getUsers, {isLoading: isGetUsersLoading}] = useGetUsersMutation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { t } = useTranslation()

	const getAllUsersList = async () => {
		const limit = `&limit=${countShowTerms}`
		const page = `&page=${Number(currentPage)}`
		const sort = sortColumnTable && {sort: `&sort=${sortColumnTable}`, order: `&order=${changeOrderSort}`}
		const search = searchChangeTermValue && {search: `&search=${searchChangeTermValue}`}
		const status = changeTypeStatus && {status: `&status=${changeTypeStatus}`}
		const tehFormData = {limit: limit, page: page, sort: `&sort=dateRegiter`, order: `&order=${changeOrderSort}`}

		try {
			const {data, error} = await getUsers({...tehFormData, ...status, ...sort, ...search})
			setGroupLength(data.data.group_length)
			setModerationLength(data?.data?.moderation_length)
			setDocsLength(data?.data?.docs_length)
			setErrorMessage(error?.data?.message === undefined ? '' : error?.data?.message)
			if (error?.data?.code === 1 && error?.data?.message === 'Incorrect token') LogoutUserHandler(dispatch, navigate)

		} catch (e) {
			setErrorMessage(e)
		}
	}

	useEffect(()=>{
        if(query_page) setCurrentPage(query_page)
        if(query_limit) setCountShowTerms(query_limit)
    },[query_page, query_limit]);

	useEffect(() => {
		switch (changeTypeStatus) {
			case "all":
				setCurrentGroupName(t('all'))
				break
			case "active":
				setCurrentGroupName(t('active'))
				break
			case "dontregister":
				setCurrentGroupName('Не закінчили реєстрацію')
				break
			case "deleteStage":
				setCurrentGroupName('На єтапі Видалення')
				break
			case "delete":
				setCurrentGroupName('Видалені')
				break
			case "moderation":
				setCurrentGroupName('На модерації')
				break
			case "docs":
				setCurrentGroupName('Docs')
				break
			case "diya":
				setCurrentGroupName('Diya')
				break
			default:
				setCurrentGroupName('Всi')
		}
	}, [changeTypeStatus]);

	useEffect(() => {
		// const getPageNumber = localStorage.getItem('paginationPageUser')
		// const getPageNumberTab = localStorage.getItem('paginationPageTabUser')
		// const getPageNumberTabName = localStorage.getItem('paginationPageTabNameUser')
		// getPageNumberTabName && toggleButtonsType(getPageNumberTabName.replace(/"/g, ''))
		// setCurrentPage(getPageNumber !== null ? Number(getPageNumber) : 1)
		// setChangeTypeStatus(getPageNumberTab !== null ? getPageNumberTab.replace(/"/g, '') : "all")
		getAllUsersList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [changeTypeStatus, countShowTerms, sortColumnTable, changeOrderSort, searchChangeTermValue, currentPage])

	const valueSearchInputHandler = (value) => {
		setSearchTermValue(value)
	}

	const changeGroupUsers = async (enter) => {
		if (enter) {
			dispatch(resetDateChangeUsersForAddressBookArr())
			setGroupUsers(prev => {
				const newState = JSON.parse(JSON.stringify(prev))
				newState.push(
					{
						group: changeTypeStatus ? changeTypeStatus : 'all',
						title: currentGroupName,
						length: groupLength,
						filters: {
							search: searchChangeTermValue && {search: `&search=${searchChangeTermValue}`},
							status: changeTypeStatus && {status: `&status=${changeTypeStatus}`}
						}
					})
				return newState
			})
		} else {
			setGroupUsers(groupUsers?.filter(el => el.group !== changeTypeStatus))
		}
	}

	const toggleButtonsType = (name) => {
		const allButtonsType = document.querySelectorAll('.terms-wrapper_search-types_button')
		for (let i = 0; i < allButtonsType.length; i++) {
			console.log("allButtonsType[i].id", allButtonsType[i].id);
			console.log("name", name);
			if (allButtonsType[i].id === name) {
				allButtonsType[i].classList.add('term-types_active')
				localStorage.setItem('paginationPageTabNameUser', JSON?.stringify(name))
			} else {
				allButtonsType[i].classList.remove('term-types_active')
			}
		}
	}

	const searchHandler = () => {
		setSearchChangeTermValue(searchTermValue)
	}

	const filteredTypeHandler = (changeType) => {
		setChangeTypeStatus(changeType)
		changeType && localStorage.setItem('paginationPageUser', JSON?.stringify(1))
		localStorage.setItem('paginationPageTabUser', JSON?.stringify(changeType))
		// setCurrentPage(1)
	}

	const sortedForColumn = (sort) => {
		if (sort === sortColumnTable) {
			if (changeOrderSort === 1) setChangeOrderSort(-1)
			else setChangeOrderSort(1)
		} else {
			setChangeOrderSort(1)
		}
		setSortColumnTable(sort)
	}

	const clearSearchHandler = () => {
		toggleModalSearch(false)
		setSearchTermValue('')
		setSearchChangeTermValue('')
		setChangeTypeStatus()
	}

	useEffect(() => {
		if (searchTermValue !== '' && showModalSearch) {
			showModalSearch && searchHandler()
			setShowSearchBeforeDel(false)
		}
		if (searchTermValue === '' && !showModalSearch) {
			clearSearchHandler()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModalSearch, showSearchBeforeDel])

	const closeModalChangeUnitForPrice = () => {
		if (fromPage === 'changeUserForAdvertising') dispatch(showChangeUserAdvertisingModal(false))
		if (fromPage === 'changeUsersListForAddressBook') dispatch(showChangeUserAdvertisingModal(false))
	}

	const rejectModalChangeUnitForPrice = () => {
		if (fromPage === 'changeUserForAdvertising') {
			dispatch(showChangeUserAdvertisingModal(false))
			dispatch(resetDateChangeUserAdvertising())
		}
		if (fromPage === 'changeUsersListForAddressBook') {
			dispatch(resetDateChangeUsersForAddressBookArr())
			dispatch(showChangeUserAdvertisingModal(false))
			setGroupUsers([])
		}
	}

	return (
		<div className='terms-wrapper'>
			<div className='d-flex align-items-center'>
				{
					(
						fromPage === 'changeUserForAdvertising'
						||
						fromPage === 'changeUsersListForAddressBook'
					) && <><AddButton
						title={`${ARE_COMMON?.ADD}`}
						handler={closeModalChangeUnitForPrice}
					/>
					<RejectButton
						title={`Скасувати`}
						handler={rejectModalChangeUnitForPrice}
					/></>
				}
			</div >
			<div className='terms-wrapper_search-types'>
				<button
					type='button'
					id='all'
					className='terms-wrapper_search-types_button term-types_active'
					onClick={() => {
						filteredTypeHandler('all')
						toggleButtonsType(`${'all'}`)
					}}
				>
					{`${t('all')}`}
				</button >
				<button
					type='button'
					id='active'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						filteredTypeHandler('active')
						toggleButtonsType('active')
					}}
				>
					{t('active')}
				</button >
				<button
					type='button'
					id='u_nfr'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						filteredTypeHandler('dontregister')
						toggleButtonsType('u_nfr')
					}}
				>
					{t('u_nfr')}
				</button >
				<button
					type='button'
					id='u_dlst'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						filteredTypeHandler('deleteStage')
						toggleButtonsType('u_dlst')
					}}
				>
					{t('u_dlst')}
				</button >
				<button
					type='button'
					id='deleted'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						filteredTypeHandler('delete')
						toggleButtonsType('deleted')
					}}
				>
					{t('deleted')}
				</button >
				<button
					type='button'
					id='u_om'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						filteredTypeHandler('moderation')
						toggleButtonsType('u_om')
					}}
				>
					{t('u_om')}
					{moderationLength ? <div className="terms-wrapper_search-types_button_dot">{moderationLength}</div> : null}
				</button >
				<button
					type='button'
					id='u_docs'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						filteredTypeHandler('docs')
						toggleButtonsType(`u_docs`)
					}}
				>
					{t(`u_docs`)}
						<div className="terms-wrapper_search-types_button_dot">{docsLength}</div> 
				</button >
			</div >
			<div className='terms-wrapper_search'>
				<SearchItemsInput
					toggleModalSearch={toggleModalSearch}
					searchTermValue={searchTermValue}
					valueSearchInputHandler={valueSearchInputHandler}
					searchHandler={searchHandler}
					clearSearchHandler={clearSearchHandler}
				/>
				<SelectShowRecords countShowTemplates={params.get('limit')} setCountShow={(limit) => setParams({limit, page: params.get('page') || currentPage})} />
			</div >
			{
				groupLength > countShowTerms
				&&
				<PaginationCustom
					itemsCount={countShowTerms}
					currentPage={currentPage}
					setCurrentPage={(page)=>{setParams({limit: params.get('limit') || countShowTerms, page})}}
					counterAllItems={groupLength}
					pageName='paginationPageUser'
				/>
			}
			{
				(fromPage !== 'changeUserForAdvertising' && fromPage !== 'users')
				&&
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: 20,
						background: "#F2F2F2",
						borderRadius: 10,
						padding: 10
					}}
				>
			<input
				type={'checkbox'}
				name='check'
				onKeyDown={e => {
					e.key === 'Enter' && e.preventDefault()
				}}
				checked={groupUsers?.filter(el => el.group === changeTypeStatus).length}
				onChange={(e) => {
					changeGroupUsers(e.target.checked)
				}}
				className='users-wrapper_tabl-body_item-wrapper_input'
			/>
				<p >{`Вибрати "${currentGroupName}" - ${groupLength} користувачiв`}</p >
			</div >
			}
			{
				isGetUsersLoading
					?
					<LoaderContent />
					:

					<UsersTabl
						counterAllItems={groupLength}
						sortedForColumn={sortedForColumn}
						countShowTerms={countShowTerms}
						showSmallTermArr={bigArrElements}
						fromPage={fromPage}
						isActive={!groupUsers?.filter(el => el.group === changeTypeStatus).length}
					/>
			}
		</div >
	)
}

export default ControlPanelsForUsers
