export function checkTextFields(values, langs){
    let result = true
    if(!values || !langs || Object.values(values).length < langs?.length){
        result = false
    }
    if(typeof values !== "object") result = false
    Object.values(values).forEach(value => {
        if(!value.length) result = false
    })
    return result
}