export const filteredTemplateContentType = (arr, changeType) => {
	return arr?.filter(item => item?.type === changeType)
}
export const filteredTermsType = (arr, changeType) => {
	const filteredArr = arr?.filter(item => {
		switch (changeType) {
			case 'all':
					return item
			case 'interface':
					return item?.systemGroup === changeType
			case 'select':
					return item?.systemGroup === changeType
			case 'email':
					return item?.systemGroup === changeType
			case 'deals_notification':
					return item?.systemGroup === changeType
			case 'system':
					return item?.systemGroup === changeType
			case 'error':
					return item?.systemGroup === changeType
			default:
				return false
		}
	})
	return filteredArr
}

