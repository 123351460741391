import React, { useRef } from 'react'
import Search from "../../utils/icons/search-inp.svg"
import DelForInp from "../../utils/icons/del-for-input.svg"
import { ARE_COMMON } from "../../utils/textsContent";
import { useTranslation } from 'react-i18next';

const SearchItemsInput = ({
	toggleModalSearch,
	searchTermValue,
	valueSearchInputHandler,
	searchHandler,
	clearSearchHandler,
	setSearch
}) => {

	const input = useRef()
	const {t} = useTranslation()

	return (
		<div className='terms-wrapper_search-block me-2'>
					<input
						type='text'
						placeholder={t('search')}
						className='terms-wrapper_search-inp'
						ref={input}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								e.preventDefault()
								setSearch(e.target.value)
							}
						}}
					/>
					<button
						type='button'
						className='terms-wrapper_search-button'
						onClick={() => {
							setSearch(input.current.value)
						}}
					>
						<img
							src={Search}
							alt='Search'
							className='terms-wrapper_search-img'
						/>
					</button >
			{
				searchTermValue?.length > 0
				&&
				<button
					type='button'
					className='terms-wrapper_search-button_clear'
					onClick={clearSearchHandler}
				>
							<img
								src={DelForInp}
								alt='Del'
								className='terms-wrapper_search-img'
							/>
						</button >
			}
				</div >
	)
}

export default SearchItemsInput