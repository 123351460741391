import React, {
	useEffect,
	useState
} from 'react'
import AlertModal from "../../../components/AlertModal"
import {
	Form,
	Spinner
} from "react-bootstrap"
import { Formik } from "formik"
import Resizer from "react-image-file-resizer"
import DefaultImage from '../../../../utils/assets/images/defaultImage.png'
import PaperClipIcon from '../../../../utils/icons/paper-clip-icon.svg'
import Remove from "../../../../utils/icons/delete-red-icon.svg"
import { squeezeFiles } from "../../../../utils/helpers/CompressorFiles"
import Loader from "../../../components/Loader"
import {
	useAddImageCategoryMutation,
	useCreateCategoryMutation,
	useEditCategoryMutation,
	useGetCategoryMutation
} from "../../../store/apis/categoryApi"
import { UploadingImageHelper } from '../../../../utils/helpers/UploadingImageHelper'
import {ARE_COMMON} from "../../../../utils/textsContent"
import BlockWithTranslationFieldsCategories from "../../../components/BlockWithTranslationFieldsCategories";
import { useSelector } from 'react-redux'
import { MainCategoryForm } from './MainCategoryForm/MainCategoryForm'
import { useTranslation } from 'react-i18next'
import { base64ToBlob } from '../../../../utils/helpers/base64ToBlob'

const initialState = {
	title: {},
	image: {},
	icon: {},
	mainType: {
		values: {},
		title: {},
		description: {},
		imageType: ""
	}
}

const CreateCategoryPage = ({onHide, edit, editDateForModal}) => {
	const [form, setForm] = useState(edit ? editDateForModal : initialState)
	const [errorMessage, setErrorMessage] = useState('')

	const [image, setImage] = useState(null)
	const [imageFile, setImageFile] = useState(null)

	const [icon, setIcon] = useState(null)
	const [iconFile, setIconFile] = useState(null)
	const [compressorImageLoading, setCompressorImageLoading] = useState(false)
	const [compressorIconLoading, setCompressorIconLoading] = useState(false)

	const [createCategory, {isLoading: isCreateCategoryLoading}] = useCreateCategoryMutation()
	const [editCategory, {isLoading: isEditCategoryLoading}] = useEditCategoryMutation()
	const [addImageCategory, {isLoading: isAddImageCategoryLoading}] = useAddImageCategoryMutation()
	const [getCategory, {isLoading: isGetCategoryLoading}] = useGetCategoryMutation()
	const langs = useSelector(store => store.langStore.langs)
	const dir = 'system'
	const {t} = useTranslation()

	const isLoading =
		isCreateCategoryLoading ||
		isAddImageCategoryLoading ||
		isGetCategoryLoading ||
		isEditCategoryLoading

	useEffect(()=>{
		console.log("############### FORM", form);
	},[form]);

	useEffect(() => {
		if (edit) {
			setImage(form?.image?.path)
			setIcon(form?.icon?.path)
			setForm(prev => {return{...prev, title: prev.name}})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit])


	const handleSubmit = async (values, {resetForm}) => {
		let idImage = null
		let idIcon = null
		let error = false

		try {
			if (imageFile !== null) {
				const response = await UploadingImageHelper(dir, imageFile, addImageCategory)
				if(response.error){
					setErrorMessage(response.error?.data?.status)
					error = response.error?.data?.status
					return
				}
				idImage = response.data?.data[0]?.id
			}
			if (iconFile !== null) {
				const response = await UploadingImageHelper(dir, iconFile, addImageCategory)
				if(response.error){
					setErrorMessage(response.error?.data?.status)
					error = response.error?.data?.status
					return
				}
				idIcon = response.data?.data[0]?.id
			}
		} catch (e) {
			console.log(e)
		}
		const names = {}
		langs?.forEach(el => {
			names[el.langIndex] = form?.title[`${el.langIndex}`];
		})

		if(form?.isMain){
			if(!Object.entries(form?.mainType.title).length || Object.entries(form?.mainType.title).length < langs.length) error = t('cc_error1')
			if(!Object.entries(form?.mainType.description).length || Object.entries(form?.mainType.description).length < langs.length) error = t('cc_error2')
			if(!Object.entries(form?.mainType.values).length) error = t('cc_error3')

			Object.entries(form?.mainType.title).forEach(el => {
				if(!el[1] || !el[1].length) error = t('cc_error1')
			})
			Object.entries(form?.mainType.description).forEach(el => {
				if(!el[1] || !el[1].length) error = t('cc_erro2')
			})

			if(!form.mainType?.imageType.length) error = t('cc_error5')
		}

		Object.entries(names).forEach(el => {
			if(!el[1] || !el[1].length) error = t('cc_error5')
		})

		if(error){
			setErrorMessage(error)
			return
		}
		
		const formDate = {
			name: names,
			image: idImage,
			icon: idIcon,
			status: form?.status || false,
			isMain: form?.isMain || false,
			mainType: form?.mainType
		}
		
		try {
			if (edit) {
				const {error} = await editCategory({
					...formDate,
					id: editDateForModal.id,
				})
				!error && await getCategory(`&list=null&orderBy=desc&sortingBy=position&isAll=true`)
				error && setErrorMessage(error?.data?.message)
				!error && resetForm()
				!error && clearFormHideModal()
			} else {
				const {
					error
				} = await createCategory(formDate)
				!error && await getCategory(`&list=null&orderBy=desc&sortingBy=position&isAll=true`)
				error && setErrorMessage(error?.data?.message)
				!error && resetForm()
				!error && clearFormHideModal()
			}
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}
	}
	
	const formDateUpdateHandler = (opt) => setForm({...form, ...opt})
	const clearFormHideModal = () => {
		onHide()
		setForm(initialState)
	}
	const resizeFile = async (file, type) => {
		Resizer.imageFileResizer(
			file,
			250,
			180,
			"JPEG",
			100,
			0,
			(uri) => {
				type === 'image' && setImage(uri)
				type === 'icon' && setIcon(uri)
			},
			"base64",
			50,
			50
		)
	}

	const compressorFileImage = async (file) => {
		setCompressorImageLoading(true)
		const tehFile = await squeezeFiles(file)
		setImageFile(tehFile)
		setCompressorImageLoading(false)
	}
	const compressorFileIIcon = async (file) => {
		setCompressorIconLoading(true)
		const tehFile = await squeezeFiles(file)
		setIconFile(tehFile)
		setCompressorIconLoading(false)
	}

	

	const removeIcons = () => {
		setIcon(null)
		setIconFile(null)
		setForm({...form, icon: null})
	}

	const removeImage = () => {
		setImage(null)
		setImageFile(null)
		setForm({...form, image: null})
	}
	
	function setInitialValues(){
		const values = {}
		langs.forEach(el => {values[`${el.langIndex}Title`] = form?.[`${el.langIndex}Title`] || form?.name?.[`${el.langIndex}`] || ''})
		return values
	}

	return (
		<div className='template-content'>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}

			<Formik
				initialValues={{
					...setInitialValues(),
					imgId: form?.imgId || '',
					iconId: form?.iconId || '',
				}}
				onSubmit={handleSubmit}
			>
				{
					({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty
					}) => (
						<Form
							className='add-edit-terms categories-create'
							onSubmit={handleSubmit}
							style={{ width: '100%', maxWidth: '100%' }}
						>
							<div className='categories-create_block'>
								<BlockWithTranslationFieldsCategories
									handleChange={handleChange}
									values={form.title}
									setForm={setForm}
									errors={errors}
									touched={touched}
									handleBlur={handleBlur}
									formDateUpdateHandler={formDateUpdateHandler}
									name={'Title'}
									title={'title'}
								/>
							</div >

							<div className='categories-create_block'>
								<Form.Group
									className='categories-create_block-wrapper'
								>
									<p className='categories-create_block-title'>{t('cc_choose_icon')}</p >
									<div
										className={`${icon ? 'categories-create_block-showImage' : 'categories-create_block-addImage'}`}
									>
										{
											compressorIconLoading
												?
												<Spinner
													animation='grow'
													variant='secondary'
												/>
												:
												icon
													?
													<div className='categories-create_block-addImage_photo'>
														<img
															src={edit ? !form.icon?.file ? icon : URL.createObjectURL(base64ToBlob(form.icon?.file, form.icon?.mimetype)) : icon}
															alt='default_image'
															className='categories-create_block-addImage_photo-img'
														/>
															<button
																type='button'
																className='categories-create_block-addImage_photo-del'
																onClick={removeIcons}
															>
															<img
																src={Remove}
																alt='delete icon'
																title='delete icon'
															/>
														</button >
													</div >
													:
													<>
														<Form.Control
															type='file'
															name='icon-category'
															accept='image/png, image/jpeg, image/webp, image/jpg, image/svg, image/pdf, image/xls, image/xls'
															autoFocus
															className='categories-create_block-addImage_file'
															onChange={e => {
																resizeFile(e.target.files[0], 'icon')
																compressorFileIIcon(e.target.files[0])
															}}
														/>
														<img
															src={DefaultImage}
															alt='default_image'
															className='categories-create_block-addImage_img'
														/>
														<div className='categories-create_block-addImage_button terms-wrapper_addButton'>
															<img
																src={PaperClipIcon}
																alt='paper clip'
															/>
															{t('select_file')}
														</div >
														<p className='categories-create_block-addImage_subtext'>
															{t('supports_format')}: WEBP, PNG, JPG
														</p >
													</>
										}

									</div >
								</Form.Group >

							</div >

							<div className='categories-create_block'>
								<Form.Group
									className='categories-create_block-wrapper'
								>
									<p className='categories-create_block-title'>{t('cc_choose_image')}</p >
									<div
										className={`${image ? 'categories-create_block-showImage' : 'categories-create_block-addImage'}`}
									>
										{
											compressorImageLoading
												?
												<Spinner
													animation='grow'
													variant='secondary'
												/>
												:
												image
													?
													<div className='categories-create_block-addImage_photo'>
												<img
													src={edit ? !form?.image?.file ? image : URL.createObjectURL(base64ToBlob(form.image?.file, form.image?.mimetype)) : image}
													alt='default_image'
													className='categories-create_block-addImage_photo-img'
												/>
													<button
														type='button'
														className='categories-create_block-addImage_photo-del'
														onClick={removeImage}
													>
													<img
														src={Remove}
														alt='delete_Image'
														title='delete image'
													/>
												</button >
												</div >
													:
													<>
													<Form.Control
														type='file'
														name='image_category'
														accept='image/png, image/jpeg, image/webp, image/jpg, image/svg, image/pdf, image/xls, image/xls'
														autoFocus
														className='categories-create_block-addImage_file'
														onChange={e => {
															resizeFile(e.target.files[0], 'image')
															compressorFileImage(e.target.files[0])
														}}
													/>
														<img
															src={DefaultImage}
															alt='default_image'
															className='categories-create_block-addImage_img'
														/>
														<div className='categories-create_block-addImage_button terms-wrapper_addButton'>
															<img
																src={PaperClipIcon}
																alt='paper clip'
															/>
															{t('select_file')}
														</div >
														<p className='categories-create_block-addImage_subtext'>
															{t('supports_format')}: WEBP, PNG, JPG
														</p >
												</>
										}

									</div >
								</Form.Group >

							</div >
							<Form.Check
								className='noticeItem-wrapper_status-moderation_item-body_block-checkbox p-0'
								type='checkbox'
								label={'Status'}
								id={'Status'}
								onKeyDown={e => {
									e.key === 'Enter' && e.preventDefault()
								}}
								defaultChecked={form?.status}
								name={'status'}
								onChange={e => {
									handleChange(e)
									formDateUpdateHandler({
										[e.target.name]: e.target.checked
									})
								}}
							/>
							<Form.Check
								className='noticeItem-wrapper_status-moderation_item-body_block-checkbox p-0'
								type='checkbox'
								label={'Main block'}
								id={'isMain'}
								onKeyDown={e => {
									e.key === 'Enter' && e.preventDefault()
								}}
								defaultChecked={form?.isMain}
								name={'isMain'}
								onChange={e => {
									handleChange(e)
									formDateUpdateHandler({
										[e.target.name]: e.target.checked
									})
								}}
							/>

							{
								form?.isMain ? <MainCategoryForm
									form={form}
									setForm={setForm}
								/> : null
							}

								<div className='categories-create_block-buttons'>
									<button
										type='submit'
										className='template-content_buttons-save btn btn-primary'
										disabled={isLoading }
									>
										{isLoading ? <Loader /> : t('save')}
									</button >
									<button
										type='button'
										className='template-content_buttons-close btn btn-primary'
										onClick={onHide}
									>
										{t('cancel')}
									</button >
								</div >

						</Form >
					)}
			</Formik >
		</div >
	)
}

export default CreateCategoryPage