import {
	Route,
	Routes,
	useLocation
} from "react-router-dom"
import {
	APP_ROUTE,
	APP_ROUTE_PAGES
} from "./utils/constants"
import Login from "./App/pages/Login/Login"
import StatisticPage from "./App/pages/Statistic/StatisticPage"
import TermsPage from "./App/pages/Terms/TermsPage"
import VersionsPage from "./App/pages/Versions/VersionsPage"
import UnitsMeasurementPage from "./App/pages/UnitsMeasurement/UnitsMeasurementPage"
// import PricesPage from "./App/pages/Prices/PricesPage"
import AmountPage from "./App/pages/Amount/AmountPage"
import TmlsPage from "./App/pages/HTMLS/НtmlsPage"
import PagesPage from "./App/pages/Pages/PagesPage"
import RbacPage from "./App/pages/RBACPage/RbacPage"
import TemplatesPage from "./App/pages/Templates/TemplatesPage"
import TemplateContentPage from "./App/pages/TemlateContent/TemplateContentPage"
import UsersPage from "./App/pages/Users/UsersPage"
import MediaFilesPage from "./App/pages/MediaFiles/MediaFilesPage"
import CategoriesPage from "./App/pages/Categories/CategoriesPage"
import SubcategoriesPage from "./App/pages/Subcategories/SubcategoriesPage"
import NoticesPage from "./App/pages/Notices/NoticesPage"

import LoaderContent from "./App/components/LoaderContent"
import ModerationPaymentPage from "./App/pages/ModerationPayment/ModerationPaymentPage"
import TransactionsPage from "./App/pages/Transactions/TransactionsPage"
import ModerationPaymentItemPage from "./App/pages/ModerationPayment/ModerationPaymentItemPage"
import UserInfoPage from "./App/pages/Users/UserInfoPage/UserInfoPage"
import UnitGroupPage from "./App/pages/UnitGroup/UnitGroupPage"
import AddressBookPage from "./App/pages/AddressBookPage/AddressBookPage"
import { AddContactsList } from "./App/pages/AddressBookPage/addContactsList/addContactsList"
import { AboutList } from "./App/pages/AddressBookPage/aboutList/aboutList"
import RbacAdminInfoPage from "./App/pages/RBACPage/RbacAdminInfo/RbacAdminInfoPage"
import MarkPage from "./App/pages/Mark/Mark"
import SubscriptionsPage from "./App/pages/Subscriptions/SubscriptionsPage"
import ModelPage from "./App/pages/Model/ModelPage"
import TransportCategoryPage from "./App/pages/TransportCategory/TransportCategoryPage"
import BodyTypePage from "./App/pages/BodyType/BodyTypePage"
import AdvertisingPage from './App/pages/AdvertisingPage/AdvertisingPage'
import NotificationPage from './App/pages/NotificationsPage/NotificationPage'
import PollPage from './App/pages/Poll/PollPage'
import { NotificationsSenderList } from "./App/pages/NotificationsSenderList/NotificationsSenderList"
import { AboutNotification } from "./App/pages/AboutNotification/AboutNotification"
import { ReferralLinks } from "./App/pages/ReferralLinks/ReferralLinks"
import SortingCategoriesPage from "./App/pages/SortingCategories/SortingCategoriesPage";
import { PermissionError } from "./App/pages/permissionError/permissionError"
import { useCheckPermission } from "./utils/helpers/checkPermission"
import { AddressBookVerification } from "./App/pages/AddressBookVerification/addressBookVerification"
import NoticeItemPageV2 from "./App/pages/Notices/NoticeItem/NoticeItemPageV2"
import AddEditBuilderHeaderTemplate from "./App/pages/Templates/CreateTemplate/AddEditBulderHeaderTemplate"
import LanguagesPage from "./App/pages/Language/LanguagesPage"
import { Delivery } from "./App/pages/Delivery/Delivery"
import { CreateDelivery } from "./App/pages/Delivery/CreateDelivery/CreateDelivery"
import { DealsMessages } from "./App/pages/DealsMessages/DealsMessages"
import { CreateDealsMessages } from "./App/pages/DealsMessages/CreateDealsMessages/CreateDealsMessages"
import { DealsMessagesTemplates } from "./App/pages/DealsMessagesTemplates/DealsMessagesTemplates"
import { CreateMessagesTemplate } from "./App/pages/DealsMessagesTemplates/CreateDealsMessagesTemplate/CreateMessagesTemplate"
// import { CreateTemplate } from "./App/pages/TemplatesV2/CreateTemplate/CreateTemplate"
import { Prices } from "./App/pages/Prices/Prices"
import { EmailsTemplatesPage } from "./App/pages/EmailsTemplates/EmailsTemplatesPage"
import { EditEmailTemplate } from "./App/pages/EmailsTemplates/EditEmailTemplate/EditEmailTemplate"
import { AdvertiserCompany } from "./App/pages/AdvertiserCompany/AdvertiserCompany"
import { EditAdvertiserCompany } from "./App/pages/AdvertiserCompany/EditAdvertiserCompany/EditAdvertiserCompany"
import { Advertiser } from "./App/pages/Advertiser/Advertiser"
import { AdvertisersList } from "./App/pages/AdvertisersList/AdvertisersList"
import { User } from "./App/pages/Users/User/User"
import { AdvertsPage } from "./App/pages/AdvertsPage/AdvertsPage"
import { AdvertPage } from "./App/pages/AdvertsPage/AdvertPage/AdvertPage"

export const RoutesLinksLogin = () => {
	return (
		<Routes >
			<Route
				path={APP_ROUTE.ENTRY}
				element={<Login />}
			/>
			<Route
				path={APP_ROUTE.LOGIN}
				element={<Login />}
			/>
		</Routes >
	)
}

export const RoutesLink = () => {
	const versionsLoaderHandler = false
	const location = useLocation()
	const access = useCheckPermission()

	!location?.pathname?.includes('/adverts') && localStorage.setItem('paginationPage', JSON?.stringify(1))
	!location?.pathname?.includes('/users') && localStorage.setItem('paginationPageUser', JSON?.stringify(1))
	!location?.pathname?.includes('/users') && localStorage.removeItem('paginationPageTabUser')
	!location?.pathname?.includes('/users') && localStorage.removeItem('paginationPageTabNameUser')

	!location?.pathname?.includes(APP_ROUTE_PAGES.TEMPLATES) && localStorage.setItem('templateParametersPage', JSON?.stringify({
		"limit": "&limit=20",
		"page": "&page=1",
		"order": "&order=-1",
		"date": "&date=-1"
	}))
	!location?.pathname?.includes(APP_ROUTE_PAGES.TEMPLATE_CONTENT) && localStorage.setItem('templateContentParametersPage', JSON?.stringify({
		"limit": "&limit=20",
		"page": "&page=1",
		"order": "&order=1"
	}))

	if (versionsLoaderHandler) return <LoaderContent />

	return (
		<Routes >
			{/* #################################  DASHBORD ################################## */}
			<Route
				path={APP_ROUTE.DEFAULT}
				element={<StatisticPage />}
			/>
			<Route
				path={APP_ROUTE.ENTRY}
				element={<StatisticPage />}
			/>
			<Route
				path={APP_ROUTE_PAGES.STATISTIC}
				element={<StatisticPage />}
			/>
			{/* #################################  USERS ################################## */}
			<Route
				path={APP_ROUTE_PAGES.USERS}
				element={access(2000)? <UsersPage /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.USER}`}
				// element={access(2000)? <UserInfoPage /> : <PermissionError/>}
				element={access(2000)? <User /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.ADVERTISER_VIEW}`}
				element={access(2033)? <Advertiser /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.ADVERTISER}`}
				element={access(2033)? <AdvertisersList /> : <PermissionError/>}
			/>
			{/* #################################  ADVERTS ################################## */}
			<Route
				path={`${APP_ROUTE_PAGES.NOTICE}`}
				element={access(2001)? <AdvertsPage /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.NOTICE_DETAIL}`}
				element={access(2001)? <AdvertPage /> : <PermissionError/>}
				// element={access(2001)? <NoticeItemPageV2 /> : <PermissionError/>}
			/>
			{/* #################################  DEALS ################################## */}
			<Route
				path={`${APP_ROUTE_PAGES.DEALS_MESSAGES_TEMPLATE}`}
				element={access(2030)? <DealsMessagesTemplates /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.DEALS_MESSAGES_TEMPLATE_CREATE}`}
				element={access(2030)? <CreateMessagesTemplate /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.DEALS_MESSAGES}`}
				element={access(2029)? <DealsMessages /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.DEALS_MESSAGES_CREATE}`}
				element={access(2029)? <CreateDealsMessages /> : <PermissionError/>}
			/>
			{/* #################################  MARKETING ################################## */}
			<Route
				path={APP_ROUTE_PAGES.ADVERTISING}
				element={access(2002)? <AdvertisingPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.ADVERTISER_COMPANY}
				element={access(2032)? <AdvertiserCompany /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.ADVERTISER_COMPANY_VIEW}
				element={access(2032)? <EditAdvertiserCompany /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.NOTIFICATIONS}
				element={access(2003)? <NotificationPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.NOTIFICATION}
				element={access(2003)? <AboutNotification /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.ADDRESS_BOOK}
				element={access(2004)? <AddressBookPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.ABOUT_BOOK}
				element={access(2004)? <AboutList /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.NEW_BOOK}
				element={access(2004)? <AddContactsList /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.NOTIFICATIONS_SENDER_LIST}
				element={access(2005)? <NotificationsSenderList /> : <PermissionError/>}
			/>
			<Route
				path={ APP_ROUTE_PAGES.REFERRAL_LINKS }
				element={access(2006)? <ReferralLinks /> : <PermissionError/>}
			/>
			<Route
				path={ APP_ROUTE_PAGES.NOTIFICATIONS_VALIDATION }
				element={access(2026)? <AddressBookVerification /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.POLL}`}
				element={access(2007)? <PollPage /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.SUBSCRIPTIONS}`}
				element={access(2008)? <SubscriptionsPage /> : <PermissionError/>}
			/>
			{/* #################################  FINANCES ################################## */}
			<Route
				path={APP_ROUTE_PAGES.TRANSACTIONS}
				element={access(2009)? <TransactionsPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.MODERATION_PAYMENT}
				element={access(2010)? <ModerationPaymentPage /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.MODERATION_PAYMENT}/:id`}
				element={access(2010)? <ModerationPaymentItemPage /> : <PermissionError/>}
			/>
			{/* #################################  CONTENT ################################## */}
			<Route
				path={APP_ROUTE_PAGES.TEMPLATES}
				element={access(2011)? <TemplatesPage /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.BODY_TYPE}`}
				element={access(2011)? <BodyTypePage /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.MARK}`}
				element={access(2011)? <MarkPage /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.MODEL}`}
				element={access(2011)? <ModelPage /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.TRANSPORT_CATEGORY}`}
				element={access(2011)? <TransportCategoryPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.TEMPLATES_CREATE}
				element={access(2012)? <AddEditBuilderHeaderTemplate /> : <PermissionError/>}
			/>
			{/* <Route
				path={APP_ROUTE_PAGES.TEMPLATES_CREATE}
				element={access(2012)? <CreateTemplate /> : <PermissionError/>}
			/> */}
			<Route
				path={`${APP_ROUTE_PAGES.TEMPLATES_EDIT}/:id`}
				element={access(2012)? <AddEditBuilderHeaderTemplate /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.TEMPLATE_CONTENT}
				element={access(2012)? <TemplateContentPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.TERMS}
				element={access(2013)? <TermsPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.CATEGORIES}
				element={access(2014)? <CategoriesPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.SUBCATEGORIES}
				element={access(2015)? <SubcategoriesPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.SORTING_CATEGORIES}
				element={access(2016)? <SortingCategoriesPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.DELIVERY}
				element={access(2028)? <Delivery /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.DELIVERY_CREATE}
				element={access(2028)? <CreateDelivery /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.MEDIA_FILES}
				element={access(2017)? <MediaFilesPage /> : <PermissionError/>}
			/>
			{/* <Route
				path={APP_ROUTE_PAGES.PRICES}
				element={access(2018)? <PricesPage /> : <PermissionError/>}
			/> */}
			<Route
				path={APP_ROUTE_PAGES.PRICES}
				element={access(2018)? <Prices /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.UNITS_OF_MEASUREMENT}
				element={access(2019)? <UnitsMeasurementPage /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.UNITS_GROUPS}`}
				element={access(2020)? <UnitGroupPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.EMAIL_TEMPLATES}
				element={access(2031)? <EmailsTemplatesPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.CREATE_EMAIL_TEMPLATES}
				element={access(2031)? <EditEmailTemplate /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.AMOUNT}
				element={access(2021)? <AmountPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.НTMLS}
				element={access(2022)? <TmlsPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.PAGES}
				element={access(2023)? <PagesPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.LANGUAGES}
				element={access(2027)? <LanguagesPage /> : <PermissionError/>}
			/>
			{/* #################################  TECH ################################## */}
			<Route
				path={APP_ROUTE_PAGES.VERSIONS}
				element={access(2024)? <VersionsPage /> : <PermissionError/>}
			/>
			<Route
				path={APP_ROUTE_PAGES.RBAC}
				element={access(2025)? <RbacPage /> : <PermissionError/>}
			/>
			<Route
				path={`${APP_ROUTE_PAGES.RBAC}/:id`}
				element={access(2025)? <RbacAdminInfoPage /> : <PermissionError/>}
			/>







			
			
			{/* <Route
				path={APP_ROUTE_PAGES.LANGUAGE}
				element={<LanguagePage />}
			/> */}
			{/* <Route
				path={APP_ROUTE_PAGES.FAQ}
				element={<FaqPage />}
			/> */}
			{/* <Route
				path={APP_ROUTE_PAGES.VERIFICATIONS}
				element={<VerificationsPage />}
			/> */}
			{/* <Route
				path={APP_ROUTE_PAGES.CHATS}
				element={<ChatsPage />}
			/> */}
			{/* <Route
				path={APP_ROUTE_PAGES.SESSIONS}
				element={<SessionsPage />}
			/> */}
			{/* <Route
				path={APP_ROUTE_PAGES.TAGS}
				element={<TagsPage />}
			/> */}
			{/* <Route
				path={APP_ROUTE_PAGES.DISCOUNTS}
				element={<DiscountsPage />}
			/> */}
			{/* <Route
				path={APP_ROUTE_PAGES.VALUE_OF_PRICES}
				element={<ValuePricesPage />}
			/> */}
		</Routes >
	)
}
