import React, { useState } from 'react';
import { PAGES_NAME } from '../../../utils/textsContent';
import AlertModal from '../../components/AlertModal';
import DeleteModal from '../../components/DeleteModal';
import ControlPanelsV2 from '../../components/environmentTabl/ControlPanelsV2';
import { ReactComponent as EditIcon } from '../../../utils/icons/edit-pencil.svg'
import { ReactComponent as DeleteIcon } from '../../../utils/icons/delete-icon.svg'
import { useNavigate } from 'react-router-dom';
import { useGetDeliveriesQuery } from '../../store/apis/deliveryApi';
import s from './delivery.module.scss'
import { CreateDelivery } from './CreateDelivery/CreateDelivery';
import { useTranslation } from 'react-i18next';

export const Delivery = ({isModal, modalType, setItems, close, changedItems})=>{

    // ### State

	const [ errorMessage, setErrorMessage ] = useState( '' )
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [selectedItems, setSelectedItems] = useState(changedItems || [])
	const [showAddModal, setShowAddModal] = useState(false)
	const [query, setQuery] = useState({search: undefined})
    const navigate = useNavigate()
	const {t, i18n} = useTranslation()

	const {data: deliveries, isFetching: isDeliveryLoading} = useGetDeliveriesQuery(query)

	const table_columns = [isModal? '' : "ID", "Name", "Type", ""]

	// ### FUNCTIONS

	function setItemsFromModal(){
		setItems(selectedItems)
		close()
	}

	function changeItem(id, action){
		const item = deliveries.find(el => el.id === id)
		if(modalType === "checkbox"){
			if(action) setSelectedItems(prev => {return [...prev, item]})
			else{
				setSelectedItems(prev => {
					let newState = JSON.parse(JSON.stringify(prev))
					newState = newState.filter(el => el.id !== id)
					return newState
				})
			}
		}
		if(modalType === "radio"){
			const item = deliveries.find(el => el.id === id)
			setSelectedItems([item])
		}
	}

	// ### VIEWS

	const table_rows = deliveries?.map(el => {
		return [
			{type: isModal? modalType === "checkbox"? 'checkbox' : 'radio' : "default", value: el.id},
			{type: "default", value: el.name?.[i18n.language]},
			{type: "default", value: el.type},
			{type: "icons", value: [<EditIcon onClick={()=>navigate(`/delivery/create?id=${el.id}`)}/>, <DeleteIcon onClick={()=>setShowDeleteModal(el.id)}/>]}
		]
	})

    return(
        <div className={`${isModal ? s.modal_type : ''} terms`}>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<DeleteModal
				show={ showDeleteModal }
				onHide={ setShowDeleteModal }
				value={ showDeleteModal }
				fromPage={ 'delivery' }
			/>
			<>
				{isModal? null : <h1 className='terms-title'>{t('delivery')}</h1 >}
				<ControlPanelsV2 
					rows={table_rows} 
					columns={table_columns} 
					totalCount={deliveries?.length} 
					setShowAddModal={()=> isModal? setShowAddModal(true) : navigate('/delivery/create')}
					showAddModal={showAddModal}
					addElementComponent = {<CreateDelivery isModal={true} close={setShowAddModal}/>}
					isLoading={isDeliveryLoading}
					changeItem={changeItem}
					isModal={isModal}
					query={query}
					setQuery={setQuery}
					selectedItems={selectedItems.map(el => {return el.id? el.id : el})}
					setItemsFromModal={setItemsFromModal}
				/>
			</>
		</div >
    )
}