import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import { DEALS_API } from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const dealsApi = createApi( {
	reducerPath: "dealsApi",
    tagTypes: ['Button', 'Condition', 'Template'],
	baseQuery: fetchBaseQuery( {
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{ getState }
		) => {
			const { token } = getState().userStore;
			if ( token ) {
				headers.set( "Authorization", `Bearer ${ token }` )
			}
			return headers
		}
	} ),
	endpoints: ( builder ) => (
		{
			getButtonsConditions: builder.query( {
				query: (body) => (
					{
						url: `${DEALS_API.GET_BUTTONS_CONDITIONS}`,
						method: "GET"
					}
				),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Condition', id })),
					{ type: 'Condition', id: 'LIST' },
					]
				: [{ type: 'Condition', id: 'LIST' }],
			} ),

			getDealsButtons: builder.query( {
				query: (query) => (
					{
						url: `${DEALS_API.GET_BUTTONS}?
						${query.search? 'search='+query.search : ''}
						${query.type? '&type='+query.type : ''}`,
						method: "GET",
					}
				),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Button', id })),
					{ type: 'Button', id: 'LIST' },
					]
				: [{ type: 'Button', id: 'LIST' }],
			} ),

			getDealsButton: builder.mutation( {
				query: (id) => (
					{
						url: `${DEALS_API.GET_BUTTONS}?id=${id}`,
						method: "GET",
					}
				)
			} ),

            createDealsButton: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ DEALS_API.CREATE_DEAL_BUTTON }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Button', id: 'LIST' }],
			} ),

			updateDealsButton: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ DEALS_API.UPDATE_DEAL_BUTTON }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Button', id: 'LIST' }],
			} ),

			deleteDealsButton: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ DEALS_API.DELETE_DEAL_BUTTON }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Button', id: 'LIST' }],
			} ),



			createDealsButtonTemplate: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ DEALS_API.CREATE_DEAL_BUTTON_TEMPLATE }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Template', id: 'LIST' }],
			} ),


			getDealsButtonsTemplates: builder.query( {
				query: (query) => (
					{
						url: `${DEALS_API.GET_BUTTONS_TEMPLATES}?
						${query.search? 'search='+query.search : ''}`,
						method: "GET",
					}
				),
				providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Template', id })),
					{ type: 'Template', id: 'LIST' },
					]
				: [{ type: 'Template', id: 'LIST' }],
			} ),

			getDealsButtonTemplate: builder.mutation( {
				query: (id) => (
					{
						url: `${DEALS_API.GET_BUTTONS_TEMPLATES}?id=${id}`,
						method: "GET",
					}
				)
			} ),

			updateDealsButtonTemplate: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ DEALS_API.UPDATE_DEAL_BUTTON_TEMPLATE }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Template', id: 'LIST' }],
			} ),

			deleteDealsButtonTemplate: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ DEALS_API.DELETE_DEAL_BUTTON_TEMPLATE }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Template', id: 'LIST' }],
			} ),

			migrationDealsButtonTemplate: builder.mutation( {
				query: ( body ) => (
					{
						url: `${ DEALS_API.MIGRATION_BUTTONS_TEMPLATES }`,
						method: "POST",
						body
					}
				),
				invalidatesTags: [{ type: 'Template', id: 'LIST' }],
			} ),
		}
	)
} );

export const {
    useGetButtonsConditionsQuery,
	useCreateDealsButtonMutation,
	useUpdateDealsButtonMutation,
	useGetDealsButtonsQuery,
	useGetDealsButtonMutation,
	useDeleteDealsButtonMutation,
	useCreateDealsButtonTemplateMutation,
	useGetDealsButtonsTemplatesQuery,
	useGetDealsButtonTemplateMutation,
	useUpdateDealsButtonTemplateMutation,
	useDeleteDealsButtonTemplateMutation,
	useMigrationDealsButtonTemplateMutation
} = dealsApi
