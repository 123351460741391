import React from 'react'
import { Form } from "react-bootstrap"

const RbacAdminInfoItemContacts = () => {
	return (
		<div className='rbac-wrapper_info'>
						<div className='rbac-wrapper_info-title'>
							Контакти
						</div >
						<ul className='rbac-wrapper_info-contacts'>
							<li className='rbac-wrapper_info-contacts_li'>
								<Form.Check
									className='rbac-wrapper_info-contacts_li-checkbox'
									type='checkbox'
									label='Viber'
									name='viber'
									id='viber'
									onKeyDown={ e => {
										e.key === 'Enter' && e.preventDefault()
									} }
								/>
								<button className='add-edit-terms_close rbac-wrapper_info-contacts_li-button'>
									Повідомлення
								</button >
							</li >
							<li className='rbac-wrapper_info-contacts_li'>
								<Form.Check
									className='rbac-wrapper_info-contacts_li-checkbox'
									type='checkbox'
									label='Telegram'
									name='telegram'
									id='telegram'
									onKeyDown={ e => {
										e.key === 'Enter' && e.preventDefault()
									} }
								/>
								<button className='add-edit-terms_close rbac-wrapper_info-contacts_li-button'>
									Повідомлення
								</button >
							</li >
							<li className='rbac-wrapper_info-contacts_li'>
								<Form.Check
									className='rbac-wrapper_info-contacts_li-checkbox'
									type='checkbox'
									label='WatsApp'
									name='watsApp'
									id='watsApp'
									onKeyDown={ e => {
										e.key === 'Enter' && e.preventDefault()
									} }
								/>
								<button className='add-edit-terms_close rbac-wrapper_info-contacts_li-button'>
									Повідомлення
								</button >
							</li >
							<li className='rbac-wrapper_info-contacts_li'>
								<Form.Check
									className='rbac-wrapper_info-contacts_li-checkbox'
									type='checkbox'
									label='Email'
									name='email'
									id='email'
									onKeyDown={ e => {
										e.key === 'Enter' && e.preventDefault()
									} }
								/>
								<button className='add-edit-terms_close rbac-wrapper_info-contacts_li-button'>
									Повідомлення
								</button >
							</li >
						</ul >
					</div >
	)
}

export default RbacAdminInfoItemContacts