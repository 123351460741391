import React from 'react'
import { Form } from "react-bootstrap";

const RbacAdminInfoItemPersonalInfo = () => {
	return (
		<div className='rbac-wrapper_info'>
						<div className='rbac-wrapper_info-title'>
							Персональна інформація
						</div >
						<div className='rbac-wrapper_info-content'>
							<Form.Group
								className='add-edit-terms_label add-edit-rbac_label'
							>
								Ім’я
									<Form.Control
										type='text'
										name='enTitle'
										// onBlur={ handleBlur }
										onKeyDown={ e => {
											e.key === 'Enter' && e.preventDefault()
										} }
										disabled
										className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
										value='Микола'
									/>
							</Form.Group >
							<Form.Group
								className='add-edit-terms_label add-edit-rbac_label'
							>
								Фамілія
									<Form.Control
										type='text'
										name='enTitle'
										// onBlur={ handleBlur }
										onKeyDown={ e => {
											e.key === 'Enter' && e.preventDefault()
										} }
										disabled
										className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
										value='Миколенко'
									/>
							</Form.Group >
							<Form.Group
								className='add-edit-terms_label add-edit-rbac_label'
							>
								По батькові
									<Form.Control
										type='text'
										name='enTitle'
										// onBlur={ handleBlur }
										onKeyDown={ e => {
											e.key === 'Enter' && e.preventDefault()
										} }
										disabled
										className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
										value='Миколайович'
									/>
							</Form.Group >
							<Form.Group
								className='add-edit-terms_label add-edit-rbac_label'
							>
								Еmail
									<Form.Control
										type='text'
										name='enTitle'
										// onBlur={ handleBlur }
										onKeyDown={ e => {
											e.key === 'Enter' && e.preventDefault()
										} }
										disabled
										className={ `add-edit-terms_center-inp add-edit-rbac_inp` }
										value='exampel@gmail.com'
									/>
							</Form.Group >
						</div >
					</div >
	)
}

export default RbacAdminInfoItemPersonalInfo