import React, {
	useCallback,
	useEffect,
	useState
} from "react"
import {
	useDispatch,
	useSelector
} from "react-redux"
import ControlPanels from "../../../components/environmentTabl/ControlPanels"
import { useGetTermsListQuery, useGetTermsMutation } from "../../../store/apis/termsApi"
import AddEditTermsItem from "../../../components/modalsAddEdit/AddEditTermsItem"
import { ShowFirstSelectedListItems } from "../../../../utils/helpers/ShowFirstSelectedListItems"
import { setUnitGroupsList } from "../../../store/slices/unitGroupsSlice"
import AddEditUnitGroupsItem from "../../../components/modalsAddEdit/AddEditUnitGroupsItem"
import AlertModal from "../../../components/AlertModal"
import { useGetMarkMutation } from "../../../store/apis/markApi"
import { useGetModelMutation } from "../../../store/apis/modelApi"
import AddEditMarkItem from "../../../components/modalsAddEdit/AddEditMarkItem"
import AddEditModelItem from "../../../components/modalsAddEdit/AddEditModelItem"
import { setUnitsMeasurementList } from "../../../store/slices/unitsMeasurementSlice"
import ControlPanelsTerms from "../../../components/environmentTabl/ControlPanelsTerms"

const ChangeUnitForTemplates = ({type}) => {
	const {unitGroups} = useSelector((state) => state.unitGroupsStore)
	const {unitsMeasurement} = useSelector((state) => state.unitsMeasurementStore)
	const {terms} = useSelector((state) => state.termsStore)
	const {mark} = useSelector(state => state.markStore)
	const {model} = useSelector(state => state.modelStore)
	const {
		dateModalChangeUnitTemplatesList,
		showModalChangeUnitTemplates
	} = useSelector(state => state.templateContentStore)
	const [getTerms, {isLoading: isTermsLoading}] = useGetTermsMutation()
	const [getMark, {isLoading: isGetMarkLoading}] = useGetMarkMutation()
	const [getModel, {isLoading: isGetModelLoading}] = useGetModelMutation()
	const [addEditModalShow, setAddEditModalShow] = useState(false)
	const [addUnitFromAmountShowModal, setAddUnitFromAmountShowModal] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const dispatch = useDispatch()
	const isLoading = isTermsLoading || isGetMarkLoading || isGetModelLoading

	const [searchTermValue, setSearchTermValue] = useState('')
	const [loaderSorting, setLoaderSorting] = useState(false)
	const [showModalSearch, setShowModalSearch] = useState(false)
	const [showSearchBeforeDel, setShowSearchBeforeDel] = useState(false)

	const [addCheckedItemsMarkerInList, setAddCheckedItemsMarkerInList] = useState([])
	const [selectListTerms, setSelectListTerms] = useState([])
	const [unitGroupItemsList, setUnitGroupItemsList] = useState([])


	const [ countShow, setCountShow ] = useState( 20 )
	const [page, setPage] = useState(1)
	const [sort, setSort] = useState(3)
	const [order, setOrder] = useState(-1)
	const [search, setSearch] = useState(undefined)
	const [termsQuery, setTermsQuery] = useState({limit: 20, page: 1, sort, order, search, group: (type === 2 || type === 3)? "select" : undefined})
	
	const {data: termsList, isFetching: isFetchingTerms} = useGetTermsListQuery(termsQuery)

	useEffect(()=>{
		if((!termsQuery.search || !termsQuery.search.length) && (search || search?.length)){
			setPage(1)
			setTermsQuery({...termsQuery, limit: countShow, page: 1, sort, order, search})
		}
		else{
			setTermsQuery({...termsQuery, limit: countShow, page, sort, order, search})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[countShow, page, sort, order, search]);

	const getMarkList = useCallback(async () => {
		await getMark()
		await getUnitList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		// ### Scroll to Top
		const el = document.querySelectorAll(".changeUnitForTemplates-modal_wrapper")
		el[el.length - 1]?.scroll(0, 0)

		type === 4 && getMarkList()
		type === 5 && getModel()
		type === 1 && getUnitList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const valueSearchInputHandler = (value) => {
		setSearchTermValue(value)
	}
	const toggleModalSearch = (t) => {
		setShowModalSearch(t)
	}

	const showModalHandler = () => {
		setAddEditModalShow(!addEditModalShow)
	}

	const openEditModalHandler = (value) => {
		setAddEditModalShow(true)
	}

	const showModalAddUnitForAmountHandler = () => {
		setAddUnitFromAmountShowModal(true)
	}
	const hideModalAddUnitForAmountHandler = () => {
		setAddUnitFromAmountShowModal(false)
	}

	const getAllTerms = useCallback(async () => {
		try {
			(type === 2 || type === 3) && await getTerms({group: "select"})
			(type === 1) && await getUnitList()
		} catch (e) {
			// console.log(e)
			// setErrorMessage(e)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addUnitFromAmountShowModal, unitGroups, unitsMeasurement])

	useEffect(() => {
		getAllTerms()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unitGroups])

	const getUnitList = async () => {
		dispatch(setUnitGroupsList(unitGroups))
		dispatch(setUnitsMeasurementList(unitsMeasurement))
	}

	useEffect(() => {
		const res = unitGroups?.map(group => {
			const resR = group?.unitlist?.map(item => {
				const resF = unitsMeasurement?.find(i => i?.id === item?.id)
				return resF
			})
			return {...group, unitlistInfo: resR}
		})
		setUnitGroupItemsList(res)
	}, [unitGroups, unitsMeasurement])

	useEffect(() => {
		setSelectListTerms(terms?.filter(item => item.systemGroup === 'select'))
	}, [terms])

	useEffect(() => {
		const res =
			type === 4
				?
				ShowFirstSelectedListItems(dateModalChangeUnitTemplatesList, mark)
				:
				type === 5
					?
					ShowFirstSelectedListItems(dateModalChangeUnitTemplatesList, model)
					:
					ShowFirstSelectedListItems(dateModalChangeUnitTemplatesList, selectListTerms)

		setAddCheckedItemsMarkerInList(res)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModalChangeUnitTemplates, selectListTerms, mark])
	return (
		<div className='changeUnitForPrice'>
			{
				errorMessage
				&& <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<div className='changeUnitForPrice-wrapper changeUnitForTemplates'>
				{
					(type === 2 || type === 3) ?
					<ControlPanelsTerms
						bigArrElements={ termsList?.data }
						showModalHandler={ showModalHandler }
						openEditModalHandler={ openEditModalHandler }
						isLoading={isFetchingTerms}
						totalPages={termsList?.totalPages}
						totalCount={termsList?.totalCount}
						currentPage={page}
						setCurrentPage={setPage}
						countShow={countShow}
						setCountShow={setCountShow}
						sort={sort}
						setSort={setSort}
						order={order}
						setOrder={setOrder}
						setSearch={setSearch}
						fromPage={ 'changeUnitForTemplatesSelect' }
						searchTermValue={ searchTermValue }
						loaderSorting={ loaderSorting }
						setLoaderSorting={ setLoaderSorting }
						toggleModalSearch={ toggleModalSearch }
						showModalSearch={ showModalSearch }
						showSearchBeforeDel={ showSearchBeforeDel }
						setShowSearchBeforeDel={ setShowSearchBeforeDel }
						valueSearchInputHandler={ valueSearchInputHandler }
						showModalAddUnitHandler={showModalAddUnitForAmountHandler}
					/>
					:
					<ControlPanels
						bigArrElements={
							(type === 1)
								?
								unitGroupItemsList
								:
								addCheckedItemsMarkerInList?.length > 0
									?
									addCheckedItemsMarkerInList
									:
									type !== 4
										?
										type !== 5
											?
											selectListTerms
											:
											model
										:
										mark
						}
						showModalHandler={showModalHandler}
						openEditModalHandler={openEditModalHandler}
						fromPage={(type === 1 ? 'changeUnitForTemplates' : 'changeUnitForTemplatesSelect')}
						showModalAddUnitHandler={showModalAddUnitForAmountHandler}
						isLoading={isLoading || isFetchingTerms}
						searchTermValue={searchTermValue}
						loaderSorting={loaderSorting}
						setLoaderSorting={setLoaderSorting}
						toggleModalSearch={toggleModalSearch}
						showModalSearch={showModalSearch}
						showSearchBeforeDel={showSearchBeforeDel}
						setShowSearchBeforeDel={setShowSearchBeforeDel}
						valueSearchInputHandler={valueSearchInputHandler}
					/>
				}
				{
					(type === 1)
					&&
					addUnitFromAmountShowModal
					&&
					<AddEditUnitGroupsItem
						show={addUnitFromAmountShowModal}
						onHide={hideModalAddUnitForAmountHandler}
					/>
				}
				{
					type === 2
					&&
					addUnitFromAmountShowModal
					&&
					<AddEditTermsItem
						show={addUnitFromAmountShowModal}
						onHide={hideModalAddUnitForAmountHandler}
					/>
				}
				{
					type === 3
					&&
					addUnitFromAmountShowModal
					&&
					<AddEditTermsItem
						show={addUnitFromAmountShowModal}
						onHide={hideModalAddUnitForAmountHandler}
					/>
				}
				{
					type === 4
					&&
					addUnitFromAmountShowModal
					&&
					<AddEditMarkItem
						show={addUnitFromAmountShowModal}
						onHide={hideModalAddUnitForAmountHandler}
					/>
				}
				{
					type === 5
					&&
					addUnitFromAmountShowModal
					&&
					<AddEditModelItem
						show={addUnitFromAmountShowModal}
						onHide={hideModalAddUnitForAmountHandler}
					/>
				}
			</div >
		</div >
	)
}

export default ChangeUnitForTemplates
