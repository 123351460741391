import EyeOpne from "../../../utils/icons/eye-open.svg"
import {
	useEffect,
	useState
} from "react"
import {
	statusSwitchHelper,
} from "../../../utils/helpers/VerificationAndStatusSwitch"
import { useNavigate } from "react-router-dom"
import { APP_ROUTE_PAGES } from '../../../utils/constants'
import {transformDate} from "../../../utils/helpers/TransformDate";

const UsersTablItem = ( { item, fromPage, isTransactionsPage } ) => {
	console.log("item", item.status);
	const navigation = useNavigate()
	const [ convertDate, setConvertDate ] = useState( null )
	const [updateDate, setUpdateDate] = useState(null)
	const [ statusSwitch, setStatusSwitch ] = useState( null )

	useEffect( () => {
		setConvertDate( transformDate( item?.createdAt ))
		setUpdateDate( transformDate( item?.updatedAt ))
		setStatusSwitch( statusSwitchHelper( item?.status ) )
	}, [ item ] )


	return (
		<tr >
			<td className='users-wrapper_tabl-body_item col-1'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<label htmlFor={ item?.id }>{ item?.id }</label >
				</div >
			</td >
			<td
				className={ `users-wrapper_tabl-body_item col-1` }
				onClick={ () => navigation( `${ APP_ROUTE_PAGES.MODERATION_PAYMENT }/${item?.id}`, { state: { item, isTransactionsPage }  } ) }
			>
				<p >{ item?.order_id }</p >
			</td >
			<td
				className='users-wrapper_tabl-body_item col-1'
			>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{ item?.amount } {item?.currency}</span >
				</div >
			</td >
			<td
				className='users-wrapper_tabl-body_item col-1'
			>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{ item?.amount } {item?.currency}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{ item?.transferred_amount } {item?.currency}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span className='w-100'>{ item?.commission } { item?.currency}</span >
				</div >
			</td >

			<td className='users-wrapper_tabl-body_item col-1'>
				<div
					className='users-wrapper_tabl-body_item-wrapper'
				>
					<span
						style={ {
							backgroundColor: `${ statusSwitch?.bg }`,
							color: `${ statusSwitch?.color }`,
							borderRadius: "2px",
							padding: "5px 8px"
						} }
					>{ statusSwitch?.title }</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span className='w-100'>{ convertDate }</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{ updateDate }</span >
				</div >
			</td >

			<td className='users-wrapper_tabl-body_item col-1'>
				<div className='users-wrapper_tabl-body_item-wrapper_icons'>
					<button
						type='button'
						onClick={ () => navigation( `${ APP_ROUTE_PAGES.MODERATION_PAYMENT }/${item?.id}`, { state: { item, isTransactionsPage } } ) }
					>
						<img
							src={ EyeOpne }
							alt='Eye'
						/>
					</button >
				</div >
			</td >
		</tr >
	)
}

export default UsersTablItem
