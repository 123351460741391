import React from 'react'

const Loader = () => {
	return (
		<svg
			width="35"
			height="35"
			viewBox="0 0 210 210"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className='loader-button'
		>
			<path
				d="M104.702 0.00188489C46.2606 0.00188489 0 46.3743 0 104.716C0 163.334 46.2606 210 104.702 210C163.474 210 209.997 163.3 209.997 104.716C210.105 90.9506 207.457 77.3011 202.206 64.5602C196.956 51.8195 189.207 40.2415 179.411 30.4986C169.613 20.756 157.965 13.043 145.139 7.80761C132.315 2.57224 118.568 -0.0810281 104.702 0.00188489ZM160.46 106.861L118.205 119.85L105.19 161.587L103.256 167.762L101.218 161.466L88.2184 119.85L49.345 107.898L40.0237 104.958L49.4497 102.052L88.2882 90.1175L101.218 48.5536L103.187 42.2404L105.121 48.4153L118.135 90.152L160.46 103.228L166.506 105.079L160.46 106.861Z"
				fill="url(#paint0_linear_5809_122597)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_5809_122597"
					x1="-38.7512"
					y1="230.802"
					x2="332.458"
					y2="-82.5388"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff" />
				</linearGradient>
			</defs>
		</svg>

	)
}

export default Loader
