import { useSelector } from "react-redux";
import { PAGES_IDS } from "../constants";

export function useCheckPermission(){
    const permissions = useSelector(store => store.userStore.permissions)
    
    return function(id){
        const access = permissions.find(el => el?.id === id)
        if(access) return true
        else return false
    }
}

export function useCheckPermissionGroup(){
    const permissions = useSelector(store => store.userStore.permissions)

    return function(id){
        let result = []
        for(let page in PAGES_IDS){
            if(PAGES_IDS[page].id === id){
                permissions.forEach(per => {
                    if(PAGES_IDS[page].pages.includes(per?.id)){
                        result.push(per.id)
                    }
                })
            }
        }
        if(result.length) return true
        else return false
    }
}