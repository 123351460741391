import AlertModal from '../../components/AlertModal'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ControlPanelsForModerationPayment from '../../components/environmentTabl/ControlPanelsForModerationPayment'
import { PAGES_NAME } from "../../../utils/textsContent"

const ModerationPaymentPage = () => {
	const { moderationPayment, amount } = useSelector( state => state.moderationPaymentStore )

	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )
	const [ errorMessage, setErrorMessage ] = useState( '' )

	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			<h1 className='terms-title'>
				{PAGES_NAME?.MODERATION_PAYMENT}
			</h1 >
			<ControlPanelsForModerationPayment
				bigArrElements={ errorMessage === '' ? moderationPayment : [] }
				fromPage={ 'users' }
				toggleModalSearch={ toggleModalSearch }
				showModalSearch={ showModalSearch }
				showSearchBeforeDel={ showSearchBeforeDel }
				setShowSearchBeforeDel={ setShowSearchBeforeDel }
				setErrorMessage={ setErrorMessage }
				amount={amount}
			/>
		</div >
	)
}

export default ModerationPaymentPage
