import React, { useEffect, useState } from 'react';
import s from './validationModal.module.scss';
import {ReactComponent as DeleteIcon} from '../../../../utils/icons/delete-icon.svg'
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';
import { useDeleteEmailsFromAddressBookMutation } from '../../../store/apis/addressBookApi';
import { ReactComponent as InfoIcon } from "../../../../utils/icons/info-icon.svg";

export const ValidationModal = ({emails, accept, reject, close, group_id})=>{

    const [groups, setGroups] = useState({});
    const [activeGroup, setActiveGroup] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmModalReject, setConfirmModalReject] = useState(false)
    const [confirmModalSuccess, setConfirmModalSuccess] = useState(false)
    const [infoModal, setInfoModal] = useState(false);

    const [deleteEmailsQuery, {isLoading: isDeleteLoading}] = useDeleteEmailsFromAddressBookMutation()

    useEffect(()=>{
        setGroupsState()
    },[emails]);

    function setGroupsState(){
        emails.forEach(el => {
            const host = gethost(el.email)
            setGroups(prev => {
                const newState = {...prev}
                if(!newState[host]) newState[host] = []
                newState[host].push(el.email)
                return newState
            })
        })
        setGroups(prev => {
            const newState = {...prev}
            let objectEntries = Object.entries(newState);
            objectEntries.sort((a, b) => b[1].length - a[1].length);
            return Object.fromEntries(objectEntries);
        })
    }
    

    function gethost(email){
        if(!email) return false
        const atIndex = email?.indexOf('@');
        if (atIndex !== -1) return email.substring(atIndex + 1);
        else return false
    }

    function removeEmail(email){

        const host = gethost(email)
        setGroups(prev => {
            const newState = {...prev}
            newState[host] = newState[host].filter(el => el !== email)
            if(!newState[host].length) delete newState[host]
            return newState
        })
    }

    function setActiveGroupState(group){
        if(activeGroup === group) setActiveGroup(false)
        else setActiveGroup(group)
    }

    const groups_view = Object.entries(groups).map((el, index) => {
        return(
            <div key={index} className={`${s.group_item} ${activeGroup === el[0]? s.active : ''}`} onClick={()=>setActiveGroupState(gethost(el[1][0]))}>
                <p>{el[0]} ({groups[el[0]]?.length})</p>
            </div>
        )
    })

    async function deleteEmails(){
        const emails = []
        Object.entries(groups).forEach(el => {
            el[1].forEach(el => emails.push(el))
        })
        const result = await deleteEmailsQuery({group_id: group_id, emails: emails})
        if(result.data.message === "success") accept()
    }

    const emails_view = Object.entries(groups).map((el, index) => {
        if(activeGroup){
            if(activeGroup === el[0]){
                return el[1].map((el, index) =>{
                    return(
                        <div key={index} className={s.item}>
                            <p>
                            <span 
                            onMouseEnter={()=>setInfoModal({email: el, response: emails.find(email => email.email === el)?.response?.message? emails.find(email => email.email === el)?.response?.message : emails.find(email => email.email === el)?.response})}
                            onMouseLeave={()=>setInfoModal(false)}
                            ><InfoIcon/></span>
                            {el}
                        </p>
                            <div className={s.delete} onClick={()=>setConfirmModal(el)}>
                                <DeleteIcon/>
                            </div>
                        </div>
                    )
                })
            } else return ''
        } else{
            return el[1].map((el, index) =>{
                return(
                    <div key={index} className={s.item}>
                        <p>
                            <span 
                            onMouseEnter={()=>setInfoModal({email: el, response: emails.find(email => email.email === el)?.response?.message? emails.find(email => email.email === el)?.response?.message : emails.find(email => email.email === el)?.response})}
                            onMouseLeave={()=>setInfoModal(false)}
                            ><InfoIcon/></span>
                            {el}
                        </p>
                        <div className={s.delete} onClick={()=>setConfirmModal(el)}>
                            <DeleteIcon/>
                        </div>
                    </div>
                )
            })
        }
    })

    return(
        <div className={s.wrapper}>
            <div className={s.modal}>
                <div className={s.header}>
                    <p className={s.title}>Email котрi не пройшли валiдацiю ({Object.entries(groups).reduce((acc, el) => {return acc + el[1].length}, 0)})</p>
                </div>
                <div className={s.groups}>
                    {groups_view}
                </div>
                <div className={s.content}>
                    {emails_view}
                </div>
                <div className={s.footer}>
                    <div className={s.button} onClick={()=> setConfirmModalSuccess(true)}>Видалити та завершити валiдацiю</div>
                    <div className={s.button} onClick={()=>setConfirmModalReject(true)}>Скасувати валiдацiю</div>
                    <div className={s.button} onClick={()=>close(false)}>Закрити</div>
                </div>
            </div>
            {infoModal ?
            <div className={s.info_wrapper}>
                <div className={s.header}>{infoModal.email}</div>
                <div className={s.response}>{infoModal.response}</div>
            </div>
            : null
            }
            {confirmModal? <ConfirmModal title='Видалення зi списку' text={`Ви дiйсно бажаєте прибрати ${confirmModal} зi списку видалення?`} close={()=>setConfirmModal(false)} success={()=>removeEmail(confirmModal)} /> : null}
            {confirmModalReject? <ConfirmModal title='Скасування валiдацiї' text={`Ви дiйсно бажаєте скасувати валiдацiю? Процес валiдацiї буде втрачено`} close={()=>setConfirmModalReject(false)} success={reject} /> : null}
            {confirmModalSuccess? <ConfirmModal title='Завершення валiдацiї' text={`Ви дiйсно бажаєте завершити валiдацiю? Вказанi у списку email будуть видаленi iз адресної книги.`} close={()=>setConfirmModalSuccess(false)} success={deleteEmails} /> : null}
        </div>
    )
}