import s from './advertPage.module.scss';
import { ReactComponent as StarIcon } from '../../../../utils/icons/star-moow.svg'
import { ReactComponent as ArrowIcon } from '../../../../utils/icons/arrow-right.svg'
import { ReactComponent as CheckedIcon } from '../../../../utils/icons/checked-icon.svg'
import { ReactComponent as CloseIcon } from '../../../../utils/icons/close-classik-icon.svg'
import { useGetAdvertQuery, useModerationChangeStatusNoticeMutation } from '../../../store/apis/noticeApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AlertModal from '../../../components/AlertModal';
import { useEffect, useState } from 'react';
import { base64ToBlob } from '../../../../utils/helpers/base64ToBlob';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { ReactComponent as SuccessIcon } from '../../../../utils/icons/success-verification.svg'
import { ReactComponent as RejectIcon } from '../../../../utils/icons/close-classik-icon-violet.svg'

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { AdvertStatusLabel } from './AdvertStatusLabel/AdvertStatusLabel';
import Loader from '../../../components/LoaderContent';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../components/ConfirmModal/ConfirmModal';
import { VerificationMessages } from '../../../components/verificationMessagesModal/verificationMessagesModal';
import { ViewImageModal } from '../../../components/ViewImageModal/ViewImageModal';

export const AdvertPage = () => {

    // ### State

    const [errorMessage, setErrorMessage] = useState()
    const [difference, setDifference] = useState(false)
    const [stars, setStars] = useState([])
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    const [messagesModal, setMessagesModal] = useState(false)
    const [viewImage, setViewImage] = useState(false)

    const {t, i18n} = useTranslation()
    const lang = i18n.language
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const [changeStatus, {isLoading: isChangeStatusLoading}] = useModerationChangeStatusNoticeMutation()
    const {data, isLoading: isGetAdvertLoading} = useGetAdvertQuery({id:params.get('id'), forCRM: true, lang})

    // ### Effects

    useEffect(()=>{
        if(data?.advert){
            // ### Вычисляем сколько пользователь на платформе
            if(data?.advert?.creator?.dateRegiter) setDifference(calculateDifference(Number(data?.advert?.creator?.dateRegiter)))
        
            // ### Считаем кол-во звёзд
            if(data?.advert?.creator?.rating){
                for(let i=0;i<=data?.advert?.creator?.rating;i++){
                    setStars(prev => {return [...prev, <StarIcon/>]})
                }
            } else setStars(<StarIcon/>)
        }
    },[data]);
    // ### Functions

    async function changeModerationStatus(status, message){
        if(!status) return
        const response = await changeStatus({type: status, moderationMessage: message.message, advertId: params.get('id')})
		if(response.error) setErrorMessage(response.error?.data?.message)
    }

    function getMainAndDetalItems(block){
        if(!block?.length) return []
        return block?.map((el, index) => {
            let value
            if(el.type === 1) value = <span><CheckedIcon/>{`${el.value} ${getTT(el.unit?.title, lang) ? getTT(el.unit?.title, lang) : ''}`}</span>
            if(el.type === 2) value = <span><CheckedIcon/>{getTT(el.value, lang)}</span> || <span><CheckedIcon/>{el.value}</span>
            if(el.type === 3) value = el.value?.map((el, index) => <span key={index}><CheckedIcon/>{`${getTT(el, lang)} `}</span>)
            if(el.type === 4) value = <span><CheckedIcon/>{el.value}</span>
            if(el.type === 6) value = <span><CheckedIcon/>{`${el.width} x ${el.height} x ${el.length} ${getTT(el.unit?.title, lang)}`}</span>
            if(el.type === 7) value = <span><CheckedIcon/>{el.value?.line}</span>
            if(el.type === 11) value = <span><CheckedIcon/>{el.value ? new Date(el.value).toLocaleString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'}) : ''}</span>
            if(el.type === 12) value = <span><CheckedIcon/>{`${el.value?.from ? new Date(el.value?.from).toLocaleString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'}) : ''}${el.value?.to ? ` - ${new Date(el.value?.to).toLocaleString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'})}` : ''}`}</span>
            if(el.type === 13) value = <span><CheckedIcon/>{`${el.value?.from ? el.value?.from : ''}${el.value?.to ? ` - ${el.value?.to}` : ''}`}</span>
            if(el.type === 101) value = <span><CheckedIcon/>{`${el.value.min} - ${el.value.max}`}</span>
            return(
                <div key={index} className={s.block_item} style={{flexDirection: el.type === 5? 'row-reverse':'column'}}>
                    <p className={s.title}>{el.title?.[lang]}</p>
                    { el.type !== 5 ?
                    <div className={s.value}>{value}</div>
                    :
                    <div className={s.boolean_value}>{el.value === true ? <CheckedIcon/> : <CloseIcon/>}</div>
                    }
                </div>
            )
        })
    }

    function getTT(value, lang){
        return value?.[lang] || value?.['en']
    }
    
    function calculateDifference(timestamp) {
        const now = new Date(); 
        const pastDate = new Date(timestamp); 
      
        let years = now.getFullYear() - pastDate.getFullYear();
        let months = now.getMonth() - pastDate.getMonth();
        let days = now.getDate() - pastDate.getDate();
      
        if (days < 0) {
          months--;
          const previousMonth = new Date(now.getFullYear(), now.getMonth(), 0).getDate();
          days += previousMonth;
        }
      
        if (months < 0) {
          years--;
          months += 12;
        }
      
        return { years, months, days };
      }

    // ### Views

    function getSlides(main){
        return data?.advert?.images?.map((image, index) => {
            const link = image ? URL.createObjectURL(base64ToBlob(image.file, image.mimetype)) : ""
            return(
                <SwiperSlide key={index}>
                    <img src={link} onClick={()=>{if(main) setViewImage(link)}} style={{width: '100%', height: '100%', objectFit: 'contain', cursor: main?'pointer':'auto'}} alt="slider_image"/>
                </SwiperSlide>
            )
        })
    }

    const discountsView = data?.advert?.discounts?.map((discount, index) => {
        return(
            <div key={index} className={s.block_wrapper}>
                <span className={s.discount_title}>{t('discount_type')}</span>
                <div className={s.discount_value}><span>{discount.title?.[lang] || discount.title?.title?.[lang]}</span></div>
                <span className={s.discount_title}>{discount.amountUnit?.title?.[lang]}</span>
                <div className={s.discount_value}><span>{discount.amount}</span></div>
                <span className={s.discount_title}>{t('discount')}</span>
                <div className={s.discount_value}><span>{`${discount.discount} ${discount.type? discount.discountUnit?.title?.[lang] : "%"}`}</span></div>
            </div>
        )
    })

    const pricesView = data?.advert?.prices?.map((price, index) => {
        return(
            <div key={index} className={s.block_wrapper}>
                <p className={s.discount_title}>{price.title?.[lang]}</p>
                <div className={s.discount_value}>{`${price.value} ${price.unit?.title?.[lang]}`}</div>
            </div>
        )
    })

    if((isGetAdvertLoading || isChangeStatusLoading)) return <Loader/>
    
    return(
        <div className={`terms ${s.page}`}>
            <h1 className='terms-title'>
                {data?.advert?.title ? data?.advert?.title : 'No title'}
            </h1 >
            <section className={s.content}>
                <div className={s.left}>
                    <div className={s.user_block}>
                        <p className={s.autor}>{t('author')}</p>
                        <div className={s.user_content}>
                            <div className={s.avatar_wraper}>
                                {data?.advert?.creator?.avatar?.[0]?.file ? <img src={URL.createObjectURL(base64ToBlob(data?.advert?.creator?.avatar?.[0]?.file, data?.advert?.creator?.avatar?.[0]?.mimetype))} alt="user_image" />:null}
                            </div>
                            <div className={s.user_info}>
                                <div className={s.name_wrapper}>
                                    <p className={s.user_name}>{data?.advert?.creator?.pib}</p>
                                    <ArrowIcon/>
                                </div>
                                <p className={s.working_title}>{t('working_time')}</p>
                                <p className={s.working_value}>
                                    { difference.years? `${difference.years} лет` : null }
                                    { difference.months? ` ${difference.months} месяцев` : null }
                                    { difference.days? ` ${difference.days} дней` : null }
                                </p>
                                <p className={s.rating_title}>{t('profile_rating')}</p>
                                <div className={s.rating_value}>{stars}<span className={s.rating_number}>{data?.advert?.creator?.rating || 0}</span></div>
                            </div>
                        </div>
                        <div className={s.user_button}><span onClick={()=>navigate(`/user?id=${data?.advert?.creatorId}`)}>{t('profile_author')}</span></div>
                    </div>
                    <div className={s.block}>
                        <p className={s.block_title}>{t('main')}</p>
                        
                        <div className={s.block_static_item}>
                            <p className={s.title}><b>ID</b></p>
                            <div className={s.value}>{data?.advert?.id}</div>
                        </div>

                        <div className={s.block_static_item}>
                            <p className={s.title}><b>{t('category')}</b></p>
                            <div className={s.value}>{data?.advert?.category?.title[lang]}</div>
                        </div>

                        <div className={s.block_static_item}>
                            <p className={s.title}><b>{t('subcategory')}</b></p>
                            <div className={s.value}>{data?.advert?.subcategory?.[0]?.title?.[lang]}</div>
                        </div>

                        <div className={s.block_static_item}>
                            <p className={s.title}><b>{t('template')}</b></p>
                            <div className={s.value}>{data?.advert?.template?.name?.[lang]}</div>
                        </div>

                        {getMainAndDetalItems(data?.advert?.mainBlock)}

                    </div>
                </div>
                <div className={s.right}>
                    <div className={s.top_button}>
                        <button className='noticeItem-wrapper_buttons-back' onClick={() => navigate(-1)}>{t('back')}</button >
                    </div>
                    <div className={s.block}>
                        <AdvertStatusLabel status={data?.advert?.status} moderationStatus={data?.advert?.moderation_status} title="Активное обьявление" />
                        <div className={s.buttons_wrapper}>
                            
                            {/* ### На модерации */}
                            {data?.advert?.status === 0 && data?.advert?.moderation_status === 0 ? 
                                <>
                                <div className={`${s.success_button}`} onClick={()=>{setConfirmModal(data?.advert?.title)}}>
                                    <SuccessIcon/>
                                    <span>{t('moderation_success')}</span>
                                </div> 
                                <div className={`${s.success_button} ${s.reject_button}`} onClick={()=>{setMessagesModal({type: 5, status: 2})}}>
                                    <RejectIcon/>
                                    <span>{t('moderation_reject')}</span>
                                </div> 
                                <div className={`${s.success_button} ${s.block_button}`} onClick={()=>{setMessagesModal({type: 5, status: 3})}}>
                                    <RejectIcon/>
                                    <span>{t('block')}</span>
                                </div> 
                                </>
                                : null
                            }
                            {/* ### Активно */}
                            {data?.advert?.status === 1 && data?.advert?.moderation_status === 1 ?
                                <div className={`${s.success_button} ${s.block_button}`} onClick={()=>{setMessagesModal({type: 5, status: 3})}}>
                                    <RejectIcon/>
                                    <span>{t('block')}</span>
                                </div>   : null
                            }
                            {/* ### Не прошло модерацию */}
                            {data?.advert?.status === 0 && data?.advert?.moderation_status === 2 ?
                                <>
                                <div className={`${s.success_button}`} onClick={()=>{setConfirmModal(data?.advert?.title)}}>
                                    <SuccessIcon/>
                                    <span>{t('moderation_success')}</span>
                                </div>
                                <div className={`${s.success_button} ${s.block_button}`} onClick={()=>{setConfirmDeleteModal(data?.advert?.title)}}>
                                    <RejectIcon/>
                                    <span>{t('delete')}</span>
                                </div>
                                </>
                                : null
                            }
                            {/* ### Заблокировано */}
                            {data?.advert?.status === 2 && data?.advert?.moderation_status === 2 ?
                                <>
                                <div className={`${s.success_button}`} onClick={()=>{setConfirmModal(data?.advert?.title)}}>
                                    <SuccessIcon/>
                                    <span>{t('moderation_unblock')}</span>
                                </div>
                                <div className={`${s.success_button} ${s.block_button}`} onClick={()=>{setConfirmDeleteModal(data?.advert?.title)}}>
                                    <RejectIcon/>
                                    <span>{t('delete')}</span>
                                </div>
                                </>
                                : null
                            }
                        </div>
                        <div className={s.block_static_item}>
                            <p className={s.title}><b>{t('advert_title')}</b></p>
                            <div className={s.value}>{data?.advert?.title}</div>
                        </div>
                        <div className={s.block_static_item}>
                            <p className={s.title}><b>{t('_m_createdDate')}</b></p>
                            <div className={s.value}>{new Date(Number(data?.advert?.date)).toLocaleString()}</div>
                        </div>
                        {
                            data?.advert?.address ?
                            <div className={s.block_static_item}>
                                <p className={s.title}><b>{t('address')}</b></p>
                                <div className={s.value}>{data?.advert?.address?.line}</div>
                            </div> : null
                        }
                        {/* ### Слайдер */}
                        {
                            data?.advert?.images?.length?
                            <div className={s.slider_wrapper}>
                                <Swiper
                                    style={{
                                    '--swiper-navigation-color': '#fff',
                                    '--swiper-pagination-color': '#fff',
                                    '--swiper-button-next-color': 'red',
                                    height: 340
                                    }}
                                    spaceBetween={10}
                                    navigation={true}
                                    thumbs={thumbsSwiper?.length? { swiper: thumbsSwiper } : false}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper2"
                                >
                                    {getSlides(true)}
                                </Swiper>
                                {
                                    getSlides()?.length > 1 ?
                                    <Swiper
                                        onSwiper={setThumbsSwiper}
                                        spaceBetween={10}
                                        slidesPerView={4}
                                        freeMode={true}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper"
                                        style={{height: 100, marginTop: 20}}
                                    >
                                        {getSlides()}
                                    </Swiper> : null
                                }
                            </div> : null
                        }

                        {/* ### Цены */}
                        {
                            data?.advert?.prices?.length ?
                            <div className={s.block_wrapper}>
                                <span className={s.title}>{t('prices')}</span>
                                {pricesView}
                            </div> : null
                        }
                        {/* ### Описание */}
                        <div className={s.block_static_item}>
                            <p className={s.title}><b>{t('description')}</b></p>
                            <div className={s.value}>{data?.advert?.description}</div>
                        </div>
                        {/* ### Минимальная сумма заказа */}
                        {
                            data?.advert?.minPrice?.value ? 
                            <div className={s.block_wrapper}>
                                <span className={s.title}>{t('min_price')}</span>
                                <div className={s.block_wrapper}>
                                    <div className={s.discount_value}><span>{data?.advert?.minPrice?.value}</span></div>
                                </div>
                            </div> : null
                        }
                        {/* ### Платная отмена */}
                        {
                            data?.advert?.loyalty?.length ?
                            <div className={s.block_wrapper}>
                                <span className={s.title}>{t('cancel_payment')}</span>
                                <div className={s.block_wrapper}>
                                    <span className={s.discount_title}>{t('days_count')}</span>
                                    <div className={s.discount_value}><span>{data?.advert?.loyalty?.[0]?.amount}</span></div>
                                    <span className={s.discount_title}>{t('cancel_payment_type')}</span>
                                    <div className={s.discount_value}><span>{`${data?.advert?.loyalty?.[0]?.percent}%`}</span></div>
                                </div>
                            </div> : null
                        }
                        {/* ### Скидки */}
                        {
                            data?.advert?.discounts?.length ?
                            <div className={s.block_wrapper}>
                                <span className={s.title}>{t('discounts')}</span>
                                {discountsView}
                            </div> : null
                        }
                        {/* ### Детальная информация */}
                        {getMainAndDetalItems(data?.advert?.content)}
                    </div>
                </div>
            </section>
            <div className={s.bottom_button}>
                <button className='noticeItem-wrapper_buttons-back' onClick={() => navigate(-1)}>{t('back')}</button >
            </div>
            {confirmModal? <ConfirmModal title={t('verification_title')} text={`${t('verification_confirm')} ${confirmModal}?`} close={()=>setConfirmModal(false)} success={()=>{changeModerationStatus(1, false); setConfirmModal(false)}} /> : null}
            {confirmDeleteModal? <ConfirmModal title={t('delete')} text={`${t('deletion_confirm')} ${confirmDeleteModal}?`} close={()=>setConfirmDeleteModal(false)} success={()=>{changeModerationStatus(4, false); setConfirmDeleteModal(false)}} /> : null}
			{messagesModal ? <VerificationMessages type={messagesModal.type} status={messagesModal.status} close={()=>setMessagesModal(false)} confirm={changeModerationStatus}/> : null}
            {viewImage ? <ViewImageModal link={viewImage} close={()=>setViewImage(false)}/> : null}
            {errorMessage && <AlertModal content={errorMessage} setErrorMessage={setErrorMessage}/>}
        </div>
    )
}