import { Form } from "react-bootstrap"
import React from "react"
import { useSelector } from "react-redux"
import { TextTranslator } from "./TranslationText/TextTranslator"

const BlockWithTranslationFieldsTemplatesContent = ({
	handleBlur,
	values,
	errors,
	touched,
	handleChange,
	formDateUpdateHandler,
	name,
	setForm
}) => {
	const langs = useSelector(store => store.langStore.langs)

	return (
		<div className='add-edit-terms_top'>
			{
				langs?.map((el, index) => {
					return(
						<Form.Group
							key={index}
							className='add-edit-terms_label'
						>
							<h3 className='subscription-create_wrapper-date_block-title'>
								{name}<span style={{textTransform: 'uppercase'}}>{` ${el.langIndex}`}</span>
								{index? null : <TextTranslator values={values} setForm={setForm} fieldName={"name"}/>}
							</h3>
							<Form.Control
								type='text'
								name={`${el.langIndex}${name}`}
								onBlur={handleBlur}
								value={values?.[`${el.langIndex}`]}
								onKeyDown={e => {
									e.key === 'Enter' && e.preventDefault()
								}}
								className={`add-edit-terms_inp  ${touched[`${el.langIndex}${name}`] ? "is-touch " : ""} ${
									errors[`${el.langIndex}${name}`] && touched[`${el.langIndex}${name}`] ? " is-invalid" : ""
								} `}
								onChange={(e) => {
									setForm(prev => {
										const newState = JSON.parse(JSON.stringify(prev))
										newState.name[`${el.langIndex}`] = e.target.value
										return newState
									})
								}}
							/>
						</Form.Group >
					)
				})
			}
		</div >
	)
}

export default BlockWithTranslationFieldsTemplatesContent