import { useTranslation } from 'react-i18next';
import s from './advertiserDocuments.module.scss';
import { base64ToBlob } from '../../../../utils/helpers/base64ToBlob';

export const AdvertiserDocuments = ({ userData }) => {

    const { t } = useTranslation()
    
    return(
        <div className={s.documents_wrapper}>
            <div className={s.document}>
                <span className={s.title}>{t('a_pc')}</span>
                <div className={s.img_wrapper}>
                    <img src={userData?.companyPhoto ? URL.createObjectURL(base64ToBlob(userData?.companyPhoto.file, userData?.companyPhoto.mimetype)) : null} alt="document" />
                </div>
            </div>
            <div className={s.document}>
                <span className={s.title}>ID</span>
                <div className={s.img_wrapper}>
                    <img src={userData?.passport ? URL.createObjectURL(base64ToBlob(userData?.passport.file, userData?.passport.mimetype)) : null} alt="document" />
                </div>
            </div>
        </div>
    )
}