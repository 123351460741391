import s from './advertStatusLabel.module.scss';
import { ReactComponent as AlertIconActive } from "../../../../../utils/icons/active-moderation-icon.svg"
import { ReactComponent as AlertIconModeration } from "../../../../../utils/icons/info-moderation.svg"
import { ReactComponent as AlertIcon } from "../../../../../utils/icons/del-for-input.svg"
import { ReactComponent as AlertIconInfo } from "../../../../../utils/icons/moderation-icon.svg"
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AdvertStatusLabel = ({status, moderationStatus}) => {

    // ### State
    const {t} = useTranslation()

    const titles = {
        moderation: t('advert_moderation'),
        active: t('advert_active'),
        reject_moderation: t('advert_rejected'),
        banned: t('advert_blocked'),
        deleted: t('advert_deleted')
    }
    const [currentTitle, setCurrentTitle] = useState(titles.active)
    const [currentStatusImage, setImage] = useState(<AlertIconActive/>)
    const [currentClass, setCurrentClass] = useState('active')

    // ### Effects

    useEffect(()=>{
        if(typeof status === 'number'){
            // ### На модерации
            if(status === 0 && moderationStatus === 0){
                setImage(<AlertIconModeration/>)
                setCurrentClass('moderation')
                setCurrentTitle(titles.moderation)
            }
            // ### Активное
            else if(status === 1 && moderationStatus === 1){
                setImage(<AlertIconActive/>)
                setCurrentClass('active')
                setCurrentTitle(titles.active)
            }
            // ### Не прошло модерацию
            else if(status === 0 && moderationStatus === 2){
                setImage(<AlertIconInfo/>)
                setCurrentClass('reject_moderation')
                setCurrentTitle(titles.reject_moderation)
            }
            // ### Заблокировано
            else if(status === 2 && moderationStatus === 2){
                setImage(<AlertIconInfo/>)
                setCurrentClass('banned')
                setCurrentTitle(titles.banned)
            }
            // ### Удалёно
            else if(status === 3 && moderationStatus === 2){
                setImage(<AlertIcon/>)
                setCurrentClass('deleted')
                setCurrentTitle(titles.deleted)
            }
        }
    },[status, moderationStatus]);

    // ### Functions

    // ### Views

    return(
        <div className={`${s.status_wrapper} ${s[`${currentClass}`]}`}>
            <div className={s.img_wrapper}>
                {currentStatusImage}
            </div >
            <div className={s.title_wrapper}>
                <h3 className={s.title}>
                    {currentTitle}
                </h3 >
            </div >
        </div >
    )
}