import EditPencil from "../../../utils/icons/edit-pencil.svg"
import DeleteIcon from "../../../utils/icons/delete-icon.svg"
import {
	useEffect, useMemo,
	useState,
} from "react"
import {
	statusTemplatesSwitchHelper,
} from "../../../utils/helpers/VerificationAndStatusSwitch"
import CopyIcon from "../../../utils/icons/copy-icon.svg";
import MigrationIcon from "../../../utils/icons/arrow_copy.svg"
import { transformDate } from "../../../utils/helpers/TransformTime";
import { useTranslation } from "react-i18next";

const TemplateTablItem = ({
	setOpenEditModal,
	item,
	toggleDeleteModal
}) => {

	const [statusSwitch, setStatusSwitch] = useState(null)
	const {t, i18n} = useTranslation()

	useEffect(() => {
		setStatusSwitch(statusTemplatesSwitchHelper(item?.status, t))
	}, [item])

	const dateTransform = useMemo(() => {
		return transformDate( item?.date )
	}, [item?.date])
	
	return (
		<tr >
			<td className='users-wrapper_tabl-body_item col-1'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<label htmlFor={item?.id}>{item?.id}</label >
				</div >
			</td >
			<td className={`users-wrapper_tabl-body_item col-4`}>
				<p >
					{item?.name?.[i18n.language]}
				</p >
			</td >
			<td className='users-wrapper_tabl-body_item col-4'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{item?.subcategory?.title?.[i18n.language]}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-4'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span >{dateTransform}</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-2'>
				<div className='users-wrapper_tabl-body_item-wrapper'>
					<span
						style={{
							backgroundColor: `${statusSwitch?.bg}`,
							color: `${statusSwitch?.color}`,
						}}
					>
						{statusSwitch?.title}
					</span >
				</div >
			</td >
			<td className='users-wrapper_tabl-body_item col-1'>
				<div className='users-wrapper_tabl-body_item-wrapper_icons'>
					<button
						type='button'
						title='Dublicate'
						onClick={() => {
							setOpenEditModal(item, true)
						}}
					>
						<img
							src={CopyIcon}
							alt='Dublicate'
						/>
					</button >
					<button
						type='button'
						title='Migration'
						onClick={() => {
							setOpenEditModal(item, false, true)
						}}
					>
						<img
							src={MigrationIcon}
							alt='Magration'
						/>
					</button >
					<button
						type='button'
						title='Edit'
						onClick={() => {
							setOpenEditModal(item, false)
						}}
					>
						<img
							src={EditPencil}
							alt='Edit Pencil'
						/>
					</button >
					<button
						type='button'
						title='Delete'
						onClick={() => {
							toggleDeleteModal(item)
						}}
					>
						<img
							src={DeleteIcon}
							alt='Delete'
						/>
					</button >
				</div >
			</td >
		</tr >
	)
}

export default TemplateTablItem
