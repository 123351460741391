import { createSlice } from "@reduxjs/toolkit"
import { subcategoryApi } from "../apis/subcategoryApi"

const initialState = {
	subcategory: [],
}

const subcategorySlice = createSlice( {
	name: "subcategory",
	initialState,
	reducers: {
		createNewSubcategoryItem: ( state, action ) => {
			state.subcategory = [ action.payload, ...state.subcategory ]
		},
		getSubcategoryList: ( state, action ) => {
			state.subcategory = action.payload
		},
		editSubcategoryItem: ( state, action ) => {
			const index = state.subcategory.findIndex(
				( term ) => {
					return term.id === action.payload.id
				}
			)
			state.subcategory[index] = action.payload
		},
		deleteSubcategoryItem: ( state, action ) => {
			const termId = action.payload
			const termID = state.subcategory.filter( term => term.id !== termId )
			state.subcategory = termID
		},
	},
	extraReducers: ( builder ) => {
		builder
			.addMatcher( subcategoryApi.endpoints.getSubcategory.matchFulfilled, (
					state,
					action
				) => {
					state.subcategory = action.payload.data
				}
			)
	}
} )

const {
	actions,
	reducer
} = subcategorySlice
export const {
	getSubcategoryList,
	createNewSubcategoryItem,
	deleteSubcategoryItem,
	editSubcategoryItem,
} = actions
export default reducer
