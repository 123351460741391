import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	STATISTICS_API,
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const statisticsApi = createApi( {
	reducerPath: "statisticsApi",
	baseQuery: fetchBaseQuery( {
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{ getState }
		) => {
			const { token } = getState().userStore;
			if ( token ) {
				headers.set( "Authorization", `Bearer ${ token }` )
			}
			return headers
		}
	} ),
	endpoints: ( builder ) => (
		{
			getStatisticsGraph: builder.mutation( {
				query: ({types, startDate, endDate, returned, date_type}) => (
					{
						url: STATISTICS_API.GET_STATISTICS_GRAPH,
						method: "POST",
						body: {
							types: types,
							startDate: startDate,
							endDate: endDate,
							returned: returned,
							dateType: date_type
						}
					}
				)
			}),

			getOtherStatistics: builder.query( {
				query: ({types, lang}) => (
					{
						url: `${STATISTICS_API.GET_OTHER_STATISTICS}?types=${types}&lang=${lang}`,
						method: "GET",
					}
				)
			})
		}
	)
} );

export const {
	useGetStatisticsGraphMutation,
	useGetOtherStatisticsQuery

} = statisticsApi
