import { Form } from "react-bootstrap"
import React from "react"
import { useSelector } from "react-redux"

const BlockWithTranslationFieldsUnit = ({
	handleBlur,
	values,
	errors,
	touched,
	handleChange,
	formDateUpdateHandler,
	name,
	title
}) => {
	const langs = useSelector(store => store.langStore.langs)
	return (
		<div className='add-edit-terms_top'>
			{
				langs?.map((el, index) => {
					return(
						<Form.Group
							key={index}
							className='add-edit-terms_label'
						>
							<h3 className='subscription-create_wrapper-date_block-title'>Title {el.langIndex}</h3>
							<Form.Control
								type='text'
								name={`${el.langIndex}${name}`}
								onBlur={handleBlur}
								value={values?.[`${el.langIndex}${name}`]}
								onKeyDown={e => {
									e.key === 'Enter' && e.preventDefault()
								}}
								className={`add-edit-terms_inp  ${touched[`${el.langIndex}${name}`] ? "is-touch " : ""} ${
									errors[`${el.langIndex}${name}`] && touched[`${el.langIndex}${name}`] ? " is-invalid" : ""
								} `}
								onChange={(e) => {
									handleChange(e)
									formDateUpdateHandler({
										[e.target.name]: e.target.value
									})
								}}
							/>
						</Form.Group >
					)
				})
			}
		</div >
	)
}

export default BlockWithTranslationFieldsUnit