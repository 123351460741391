import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import s from './NotificationViberPage.module.scss';
import { Form, Spinner } from 'react-bootstrap'
import AlertIconActive from '../../../../utils/icons/active-moderation-icon.svg'
import { Formik } from 'formik'
import AlertModal from '../../../components/AlertModal'
import DatePicker from 'react-datepicker'
import CloseIcon from '../../../../utils/icons/close-classik-icon-violet.svg'
import { useNavigate } from 'react-router-dom'
import SelectForm from '../NotificationComponents/SelectForm'
import { useCreateHtmlsMutation } from '../../../store/apis/htmlsApi'
import { createNewTerm } from '../../../store/slices/termsSlice'
import { useDispatch } from 'react-redux'
import { useCreateNotificationMutation, useUploadNotificationImageMutation } from '../../../store/apis/notificationApi'
import {
	ARE_COMMON,
	PAGES_NAME
} from "../../../../utils/textsContent";
import { useRef } from 'react'
import Loader from '../../../components/Loader'
import { useGetLangsQuery } from '../../../store/apis/langApi'
import Resizer from 'react-image-file-resizer'
import { squeezeFiles } from '../../../../utils/helpers/CompressorFiles'
import PaperClipIcon from '../../../../utils/icons/paper-clip-icon.svg'
import Remove from '../../../../utils/icons/delete-red-icon.svg'
import DefaultImage from '../../../../utils/assets/images/defaultImage.png'
import { createNewNotification } from '../../../store/slices/notificationSlice';

const NotificationEmailPage = ({toggleAddEditViberListPage, groupsList, dataNotification, isDoubleNotification, setIsDoubleNotification, setIsLoading}) => {
	
	const initialState = {
		type: 0,
		title: null,
		audienceCustom: dataNotification?.audienceCustom || [],
		audienceSystem: dataNotification?.audienceSystem || [],
		repeat: dataNotification?.startRepeat? dataNotification.startRepeat : null,
		start: '',
		typeNotification: dataNotification?.typeNotification? dataNotification.typeNotification : 0,
		repeatCode: dataNotification?.repeatCode? dataNotification.repeatCode : 0,
		mark: [],
		body: {},
		actionButton: dataNotification?.actionButton || {caption:{}, url:""},
		smsData: dataNotification?.sms? dataNotification.sms : {},
		images: dataNotification?.images || {}
	}

	const [form, setForm] = useState(initialState)
	const [errorMessage, setErrorMessage] = useState('')
	const [showDatePicker, setShowDatePicker] = useState(dataNotification? true : false)
	const [checkDeliveryTime, setCheckDeliveryTime] = useState(dataNotification? true : false)
	const [date, setDate] = useState(dataNotification? new Date(dataNotification.firstSend): null)
	const [timeLifeMessage, setTimeLifeMessage] = useState(dataNotification?.startRepeat? dataNotification.startRepeat : 0)
	const [actionButton, setActionButton] = useState(false)
	const [smsState, setSmsState] = useState(false)
	const [compressorImageLoading, setCompressorImageLoading] = useState( false )
	const [image, setImage] = useState( {} )
	const [imageFile, setImageFile] = useState( {} )
	

	const selectAddressBookRef = useRef()
	const typeNotification = useRef();
	const repeatCodeRef = useRef()

	const [createHtmls, {isLoading: isCreateHtmls}] = useCreateHtmlsMutation()
	const [uploadImage] = useUploadNotificationImageMutation()
	const [createNotification, {isLoading: isCreateNotificationLoading}] = useCreateNotificationMutation()
	const {data: langs, isLoading: isLangsLoading} = useGetLangsQuery();

	useEffect(()=>{
		if(!dataNotification){
			langs?.forEach(el => {
				setForm(prev => {
					const newState = JSON.parse(JSON.stringify(prev))
					newState.body[`messageText${el.langIndex}`] = ''
					newState.actionButton.caption[`${el.langIndex}`] = ''
					newState.smsData[`${el.langIndex}`] = ''
					return newState
				})
			})
		}
		else{
			langs?.forEach(el => {
				setForm(prev => {
					const newState = JSON.parse(JSON.stringify(prev))
					newState.body[`messageText${el.langIndex}`] = dataNotification?.title[0]?.body[el.langIndex]
					newState.smsData[`${el.langIndex}`] = ''
					return newState
				})
			})
			if(dataNotification.actionButton){
				setActionButton(true)
			}
			if(dataNotification.sms){
				setSmsState(true)
				langs?.forEach(el => {
					setForm(prev => {
						const newState = JSON.parse(JSON.stringify(prev))
						newState.smsData[el.langIndex] = dataNotification.sms[el.langIndex]
						return newState
					})
				})
			}	
		}
	},[langs, groupsList, dataNotification]);

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const isLoading = isCreateHtmls || isCreateNotificationLoading || isLangsLoading
	const formDateUpdateHandler = useCallback((opt) => {setForm({...form, ...opt})},[form])
	const formDateUpdateBodyHandler = (opt) => setForm(prev => {return {...form, body: {...prev.body, ...opt}}})
	const showDatePickerHandler = (value) => setShowDatePicker(value)

	const plusTimeLifeMessage = () => setTimeLifeMessage(timeLifeMessage + 1)
	const minusTimeLifeMessage = () => setTimeLifeMessage(timeLifeMessage - 1)

	useEffect(() => {
		!checkDeliveryTime && setDate(null)
	}, [checkDeliveryTime])

	useEffect(() => {
		form?.typeNotification === 0 && formDateUpdateHandler({repeatCode: 0})
	}, [form?.typeNotification, formDateUpdateHandler])

	const handleSubmit = async (values, {resetForm}) => {
		const audienceCustomGroupArr = [Number(form?.audienceCustom) || groupsList?.[0]?.id]
		let error = false
		let isImage = false
		let uploaded_images = false
		let htmlID = null
		
		if(Object.keys(imageFile).length + Object.keys(form.images).length === langs.length || Object.keys(imageFile).length + Object.keys(form.images).length === 0){
			isImage = true
		}
		else{
			isImage = false
			error = 'Будь ласка, заповнiть всi зображення, або видалiть всi'
		}
		
		for(let message in form.body){
			if(!form.body[message].length){
				error = 'Будь ласка, заповнiть всi повiдомлення'
			}
		}
		if(actionButton){
			if(!form.actionButton.url) error = 'Будь ласка, заповнiть URL в Action кнопцi'
			for(let caption_item in form.actionButton.caption){
				if(!form.actionButton.caption[caption_item].length){
					error = 'Будь ласка, заповнiть всi поля в Action кнопцi'
				}
			}
		}
		
		if(smsState){
			for(let sms in form.smsData){
				if(!form.smsData[sms].length){
					error = 'Будь ласка, заповнiть всi поля в CMC'
				}
			}
		}
		if(error){
			setErrorMessage(error)
			return
		}
		
		if(isImage){
			uploaded_images = {...form.images, ...await uploadViberImages(imageFile) }
		}

		const formDateBody = {
			body: {
				'ua': form.body.messageTextua,
				'ru': form.body.messageTextru,
				'en': form.body.messageTexten,
				'pl': form.body.messageTextpl
			}
		}
		
		try {
			const {data, error} = await createHtmls(formDateBody)
			htmlID = data?.id
			setErrorMessage(error?.data?.message)
			!error && dispatch(createNewTerm({
				...formDateBody,
				id: data?.id
			}))
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}

		const formDate = {
			type: 4,
			title: htmlID,
			audienceCustom: audienceCustomGroupArr,
			audienceSystem: [],
			repeat: timeLifeMessage,
			start: date !== null ? date.getTime() : '',
			typeNotification: form?.typeNotification,
			repeatCode: form?.repeatCode,
			mark: [],
			notification_id: dataNotification?.id || false,
			isDoubleNotification: isDoubleNotification || false,
			actionButton: actionButton? form.actionButton : false,
			sms: smsState? form.smsData : false,
			images: isImage? uploaded_images: false,
			sender_type: 'viber'
		}
		
		try {
			const {data, error} = htmlID !== null && await createNotification(formDate)
			!error && dispatch(createNewNotification({
				...formDate,
				id: data?.id
			}))
			if(!error) navigate(`/notification?id=${data.id}`)
			else setErrorMessage(error?.data?.message)
		} catch (e) {
			console.log(e)
			setErrorMessage(e)
		}
	}
	
	async function uploadViberImages(images){
		const images_result = {}
		await Promise.all(Object.entries(images).map(async (el) => {
			const formData = new FormData();
			const file = el[1]
			formData.append('dir', 'notifications')
			formData.append('image', file, `${file.name}`)
			const response = await uploadImage(formData)
			if(response.data.status === 200){
				images_result[el[0]] = response.data.data[0]?.link
			}
			else{
				setErrorMessage('Виникла помилка запису зображень! Будь ласка, звернiться до адмiнiстратора')
			}
		}))
		return images_result
	}

	const clearFormHideModal = async () => {
		setIsDoubleNotification(false)
		setIsLoading(true)
		setForm({})
		toggleAddEditViberListPage()
	}

	const resizeFile = async ( file, type, name ) => {
		Resizer.imageFileResizer(
			file,
			250,
			180,
			"JPEG",
			100,
			0,
			( uri ) => {
				const newState = {[name]: uri}
				type === 'image' && setImage( prev => {return{...prev, ...newState}} )
			},
			"base64",
			50,
			50
		)
	}
	const compressorFileImage = async ( file, type, name ) => {
		if ( type === 'image' ) {
			setCompressorImageLoading( true )
			const tehFile = await squeezeFiles( file )
			const newState = {[name]: tehFile}
			setImageFile( prev => {return{...prev, ...newState}} )
			setCompressorImageLoading( false )
		}
	}
	const removeImage = ( type, name ) => {
		if ( type === 'image' ){
			setImage( prev => {
				const newState = JSON.parse(JSON.stringify(prev))
				delete newState[name]
				return newState
			})
			setImageFile( prev => {
				const newState = JSON.parse(JSON.stringify(prev))
				delete newState[name]
				return newState
			})
			if(form?.images){
				delete form.images[name]
			}
		}
	}

	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<h1 className='terms-title d-flex align-items-center justify-content-between'>
				{(dataNotification && !isDoubleNotification)? "Редагувати розсилку Viber" : null}
				{isDoubleNotification? "Дублювати розсилку Viber" : null}
				{(!dataNotification ?? !isDoubleNotification)? "Нова розсилка Viber" : null}
				<button
					className='terms-wrapper_addButton rbac-wrapper_backButton mb-0'
					onClick={() => clearFormHideModal()}
				>
				<img
					src={CloseIcon}
					alt='close'
				/>
			</button >
			</h1 >
			<Formik
				validateOnChange
				initialValues={form}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					isValid,
					handleSubmit,
					dirty
				}) => (
					<Form
						className='add-edit-terms'
						onSubmit={handleSubmit}
					>
					<div className='terms-wrapper'>
						<div className='notification-email' style={{maxWidth: '100%', width: '100%'}}>
							<div className='notification-email_content'>
								<h2 className='notification-email_content-title'>
									Інформація про розсилку
								</h2 >

								<SelectForm
									title={PAGES_NAME?.ADDRESS_BOOK}
									name={'audienceCustom'}
									arr={groupsList}
									formDateUpdateHandler={formDateUpdateHandler}
									refLink={selectAddressBookRef}
									defaultValue={dataNotification?.audienceCustom?.length? dataNotification.audienceCustom[0] : false}
								/>

								<div className='notification-email_content-inputs'>
									<div className='notification-email_content-textarea'>

									{langs?.map((el, index) => {
										return(
											
											<div className={s.body_row} key={index}>
												<Form.Group className={`add-edit-htmls_label ${s.left}`} key={index}>
													<p className='title' style={{fontSize: 14}}>{`Повідомлення ${el.langIndex}`}</p> 
													<Form.Control
														as='textarea'
														name={el.langIndex}
														onBlur={ handleBlur }
														value={form?.body[`messageText${el.langIndex}`]}
														className={ `add-edit-htmls_inp` }
														onChange={e => {
																handleChange(e)
																formDateUpdateBodyHandler({
																	[`messageText${el.langIndex}`]: e.target.value
																})
															}}
													/>
												</Form.Group>
											
												{compressorImageLoading
													?
													<Spinner
														animation='grow'
														variant='secondary'
													/>
													:
													image[el.langIndex] || form?.images[el.langIndex]
														?
														<div key={index + 1} className={s.right}>
															<p className='title'>{`Зображення ${el.langIndex}`}</p>
															<div className={`advertising_media-bigImg_image advertising_media-bigImg_image-addImage ${s.image_wrapper}`}>
																<div className='advertising_media-wrapper_image-addImage_photo'>
																	<div
																		style={ {
																			background: `url(${form?.images[el.langIndex]? form?.images[el.langIndex] : image[el.langIndex] } )`,
																			backgroundSize: 'contain',
																			backgroundRepeat: 'no-repeat',
																			backgroundPosition: 'center',
																		} }
																		className='advertising_media-wrapper_image-addImage_photo-img'
																	/>
																	<button
																		type='button'
																		className='advertising_media-wrapper_image-addImage_photo-del'
																		onClick={ () => removeImage( 'image', el.langIndex ) }
																	>
																		<img
																			src={ Remove }
																			alt='delete img'
																			title='delete image'
																		/>
																	</button >
																</div >
															</div >
														</div>
														:
														<div key={index + 1} className={s.right}>
															<p className='title'>{`Зображення ${el.langIndex}`}</p>
															<div className={`advertising_media-bigImg_image advertising_media-bigImg_image-addImage ${s.image_wrapper}`}>
																<Form.Control
																	type='file'
																	name='icon-category'
																	accept='image/png, image/jpeg, image/webp, image/jpg, image/svg'
																	className='advertising_media-bigImg_image-addImage_file'
																	onChange={ e => {
																		resizeFile( e.target.files[ 0 ], 'image', el.langIndex )
																		compressorFileImage( e.target.files[ 0 ], 'image', el.langIndex)
																	} }
																/>
																<img
																	src={ DefaultImage }
																	alt='default img'
																	className='advertising_media-bigImg_image-addImage_img'
																/>
																<div className='advertising_media-bigImg_image-addImage_button terms-wrapper_addButton'>
																	<img
																		src={ PaperClipIcon }
																		alt='paper clip'
																	/>
																	Виберіть файл
																</div >
																<p className='categories-create_block-addImage_subtext'>
																	Підтримує формат: WEBP, JPEG, PNG
																</p >
																<p className='categories-create_block-addImage_subtext'>
																	{/*1560x300*/ }
																</p >
															</div >
														</div>
													}
											</div>
										)
									})}
								</div >


									<h2 className='notification-email_content-title mt-4'>Додаткові опції</h2 >
									<Form.Group className='notification-email_content-deliveryTime_label mt-4'>
                					<Form.Check
										type='checkbox'
										name='deliveryTimeRepeat'
										id='deliveryTimeRepeat1'
										className='notification-email_content-deliveryTime_checkbox'
										checked={actionButton}
										onKeyDown={ e => {
											e.key === 'Enter' && e.preventDefault()
										} }
										onChange={ () => {
											setActionButton(!actionButton);
										} }
									/>
									<label htmlFor='deliveryTimeRepeat1'>Action-кнопка</label >
									</Form.Group >
									{
										actionButton && <>
										{
											langs?.map((el, index) => {
												return(
													<div key={index}>
													<Form.Group
													className='add-edit-htmls_center'
													>
													{`Текст ${el.langIndex}`}
													<Form.Control
														type='text'
														name='caption'
														onBlur={ handleBlur }
														placeholder='Текст на кнопці до 30 знаків'
														onKeyDown={ e => {
															e.key === 'Enter' && e.preventDefault()
														} }
														className={ `add-edit-htmls_center-inp  ${ touched?.screen ? "is-touch " : "" } ${ errors?.screen && touched?.screen ? " is-invalid" : "" } ` }
														value={ form?.actionButton?.caption[el.langIndex] || ""}
														onChange={ ( e ) => {
															handleChange( e )
															setForm(prev => {
																const newState = JSON.parse(JSON.stringify(prev))
																newState.actionButton.caption[el.langIndex] = e.target.value
																return newState
															})
														} }
													/>
													{ errors.screen && touched.screen && (
														<Form.Control.Feedback type='invalid'>
																{ errors.screen }
															</Form.Control.Feedback >
													) }
													</Form.Group >
													</div>
												)
											})
										}
										
										<Form.Group
											className='add-edit-htmls_center'
										>
											URL
											<Form.Control
												type='text'
												name='url'
												onBlur={ handleBlur }
												placeholder='Посилання на кнопці'
												onKeyDown={ e => {
													e.key === 'Enter' && e.preventDefault()
												} }
												className={ `add-edit-htmls_center-inp  ${ touched?.screen ? "is-touch " : "" } ${ errors?.screen && touched?.screen ? " is-invalid" : "" } ` }
												value={ form?.actionButton?.url || ""}
												onChange={ ( e ) => {
													handleChange( e )
													setForm(prev => {
														const newState = {...prev}
														newState.actionButton.url = e.target.value
														return newState
													})
												} }
											/>
											{ errors.screen && touched.screen && (
												<Form.Control.Feedback type='invalid'>
														{ errors.screen }
													</Form.Control.Feedback >
											) }
										</Form.Group >
										</>
									}

									<Form.Group className='notification-email_content-deliveryTime_label mt-4'>
									<Form.Check
											type='checkbox'
											name='deliveryTimeRepeat2'
											id='deliveryTimeRepeat2'
											checked={smsState}
											className='notification-email_content-deliveryTime_checkbox'
											onKeyDown={ e => {
												e.key === 'Enter' && e.preventDefault()
											} }
											onChange={ () => setSmsState(!smsState) }
										/>
										<label htmlFor='deliveryTimeRepeat2'>Повторна відправка СМС</label >
									</Form.Group >
									{smsState? <>
										<div className='noticeItem-wrapper_status-alert statusOne' style={{marginBottom: 20, zIndex: 4}}>
											<div className='noticeItem-wrapper_status-alert_img statusOne_img'>
											<img
												src={ AlertIconActive }
												alt='Alert'
											/>
											</div >
											<div className='noticeItem-wrapper_status-alert_block'>
												<h3 className='noticeItem-wrapper_status-alert_block_h3 statusOne_h3'>
													Увага!
												</h3 >
												<p className='noticeItem-wrapper_status-alert_block_text'>
													Якщо доставка повідомлення через Viber відсутня, буде відправлено SMS повідомлення
												</p >
											</div >
										</div >
										{
											langs?.map((el, index) => {
												return(
													<Form.Group className='add-edit-htmls_label' key={index}> {`Текст SMS ${el.langIndex}:`} 
													<Form.Control
														as='textarea'
														rows='3'
														name='uaTitle'
														onBlur={ handleBlur }
														value={form.smsData[el.langIndex] }
														// maxLength={ 100 }
														className={ `add-edit-htmls_inp` }
														onChange={ ( e ) => {
															handleChange( e )
															handleChange( e )
															setForm(prev => {
																const newState = JSON.parse(JSON.stringify(prev))
																newState.smsData[el.langIndex] = e.target.value
																return newState
															})
														} }
													/>
													</Form.Group>
												)
											})
										}
										</>:null
									}
									


									<div className='notification-email_content-deliveryTime'>
										<h2 className='notification-email_content-title'>
											Час відправки
										</h2 >
										<Form.Group className='notification-email_content-deliveryTime_label'>
											<Form.Check
												type='radio'
												name='deliveryTime'
												id='deliveryTime'
												defaultChecked={dataNotification? false : true}
												className='notification-email_content-deliveryTime_radio'
												onKeyDown={e => {
													e.key === 'Enter' && e.preventDefault()
												}}
												onChange={() => {
													showDatePickerHandler(false)
													setCheckDeliveryTime(false)
												}}
											/>
											<label htmlFor='deliveryTime'>Відправити зараз</label >
										</Form.Group >
										<Form.Group className='notification-email_content-deliveryTime_label'>
											<Form.Check
												type='radio'
												name='deliveryTime'
												id='deliveryTime1'
												defaultChecked={dataNotification? true : false}
												className='notification-email_content-deliveryTime_radio'
												onKeyDown={e => {
													e.key === 'Enter' && e.preventDefault()
												}}
												onChange={() => {
													showDatePickerHandler(true)
													setCheckDeliveryTime(true)
												}}
											/>
											<label htmlFor='deliveryTime1'>Відкласти час відправки</label >
										</Form.Group >
										{
											showDatePicker
											&&
											<div className='notification-email_content-deliveryTime_datepicekr'>
												<DatePicker
													selected={date}
													onChange={(date) => setDate(date)}
													showTimeSelect
													timeFormat='p'
													timeIntervals='15'
													dateFormat='Pp'
													isClearable
												/>
											</div >
										}
										<div className='notification-email_content-deliveryTime mb-4'>
										<h2 className='notification-email_content-title'>
											Тип розсилки
										</h2 >
										<Form.Group className='template-content_typeBlock-label w-100 mb-4'>
											<select
												aria-label='Default select example'
												className='template-content_typeBlock-select'
												name='typeNotification'
												defaultValue={form?.typeNotification}
												ref={typeNotification}
												onChange={(e) => formDateUpdateHandler({[e.target.name]: Number(e?.target.value)})}
											>
												<option value={'0'}>Єдиноразова</option >
												<option value={'1'}>Циклічна</option >
											</select >
										</Form.Group >
											<div className='notification-viber_counter-wrapper'>
												{
													form?.typeNotification === 1
													&&
													<Form.Group className='template-content_typeBlock-label w-100'>
														<p className='mt-4'>
															Варіант цикла та кількість вiдправок
														</p >
														<div className='notification-viber_counter'>
															<div className='notification-viber_counter-wrapper'>
																<select
																	aria-label='Default select example'
																	className='template-content_typeBlock-select notification-viber_counter-wrapper_select m-0'
																	name='repeatCode'
																	defaultValue={'1'}
																	ref={repeatCodeRef}
																	onChange={(e) => formDateUpdateHandler(
																		{[e.target.name]: Number(e?.target.value)})}
																>
																		<option value={'0'}>6 годин</option >
																		<option value={'1'}>12 годин</option >
																		<option value={'2'}>24 години</option >
																		<option value={'3'}>48 годин</option >
																		<option value={'4'}>72 години</option >
																		<option value={'5'}>5 днів</option >
																		<option value={'6'}>6 днів</option >
																		<option value={'7'}>7 днів</option >
																		<option value={'8'}>2 тижні</option >
																		<option value={'9'}>1 місяць</option >
																		<option value={'10'}>3 місяці</option >
																		<option value={'11'}>пів року</option >
																		<option value={'12'}>рік</option >
																		
																</select >
																<div className='notification-viber_counter-wrapper_block ms-2'>
																	<button
																		type='button'
																		className='notification-viber_counter-wrapper_minus'
																		onClick={minusTimeLifeMessage}
																	>
																		-
																	</button >
																	<Form.Control
																		type='number'
																		name='repeat '
																		onBlur={handleBlur}
																		value={timeLifeMessage}
																		onKeyDown={e => {
																			e.key === 'Enter' && e.preventDefault()
																		}}
																		className={`notification-viber_counter-wrapper_input`}
																	/>
																	<button
																		type='button'
																		className='notification-viber_counter-wrapper_plus'
																		onClick={plusTimeLifeMessage}
																	>
																		+
																	</button >
																</div >
															</div >
														</div >
													</Form.Group >
												}
											</div >
										</div >
									</div >
								</div >
							</div >

							<div className='categories-create_block-buttons' style={{width: 'fit-content'}}>
								<button
									type='button'
									className='template-content_buttons-close btn btn-primary me-3'
									onClick={clearFormHideModal}
								>
									{ARE_COMMON?.ABOLITION}
								</button >
								<button
									type='submit'
									className='template-content_buttons-save btn btn-primary'
									onClick={handleSubmit}
								>
										{isLoading? <Loader/> : ARE_COMMON?.SAVE}
								</button >
							</div >
						</div >
					</div >
					</Form >
				)}
			</Formik >
		</div >
	)
}

export default NotificationEmailPage