import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import exit from "../../utils/icons/exit.svg";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { LogoutUserHandler } from "../../utils/helpers/LoqoutUserHelper";
import logo from "../../utils/icons/Logo.png";
import { useGetUsersMutation } from "../store/apis/usersApi";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";

const SidebarItems = React.lazy(() => import("./SidebarItems"));

const Sidebar = () => {
  const [defaultActiveKeySidebar, setDefaultActiveKeySidebar] = useState("0");
  const [counterUsers, setCounterUsers] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getUsers] = useGetUsersMutation();
  const permissions = useSelector(store => store.userStore.permissions)

  useEffect(() => {
    const usersList = async () => {
      const { data, error } = await getUsers({
        limit: "&limit=20",
        page: "&page=1",
      });

      !error && setCounterUsers(data?.data?.totalUsers);
      if (error?.data?.code === 1) logoutHandler();
    };
    usersList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutHandler = () => {
    LogoutUserHandler(dispatch, navigate);
  };

  const navigateHandler = (root) => {
    navigate(root);
  };

  const changeIdHandler = (idItem) => {
    const subElements = document.getElementById(`${idItem}`);
    const allEl = document.querySelectorAll(".sidebare-dashboard_subnav-li");
    const noticesParent = document.querySelector("#adverts");
    const hatsParent = document.querySelector("#chats");
    const nameLinkStr = subElements?.id;

    subElements?.classList.remove("active-sidebar-show");
    noticesParent?.classList.remove("active-sidebar-show");
    hatsParent?.classList.remove("active-sidebar-show");
    removeListsItems(allEl);
    if (nameLinkStr) {
      subElements?.classList.add("active-sidebar-show");
    } else {
      subElements?.classList.remove("active-sidebar-show");
      removeListsItems(allEl);
      noticesParent?.classList.remove("active-sidebar-show");
      hatsParent?.classList.remove("active-sidebar-show");
    }
    sessionStorage.setItem(
      "changeMenu",
      JSON.stringify({ menu: { idItem: idItem } })
    );
  };

  const removeListsItems = (arr) =>
    arr.forEach((item) => item.classList.remove("active-sidebar-show"));

  const getLocalStorageMenuOption = useCallback(async () => {
    const menu = await JSON?.parse(sessionStorage?.getItem("changeMenu"))?.menu;
    const parent = await JSON?.parse(sessionStorage?.getItem("changeMenuPar"))
      ?.parent;

    changeIdHandler(menu?.idItem);
    setDefaultActiveKeySidebar(parent?.idParent);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLocalStorageMenuOption();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeParentAccording = (id) => {
    setDefaultActiveKeySidebar(id);
    sessionStorage.setItem(
      "changeMenuPar",
      JSON.stringify({ parent: { idParent: id } })
    );
  };

  return (
    <div className="sidebare">
      <div className="sidebare-logo_wrapper">
        <img src={logo} alt="logo"></img>
      </div>
      {/*************************Dashboard - start************************/}

      <nav className="sidebare-dashboard">
        <Suspense fallback={<Loader />}>
          <SidebarItems
            defaultActiveKeySidebar={defaultActiveKeySidebar}
            navigateHandler={navigateHandler}
            changeIdHandler={changeIdHandler}
            activeParentAccording={activeParentAccording}
            counterUsers={counterUsers}
            permissions={permissions}
          />
        </Suspense>
      </nav>

      {/*************************Dashboard - end************************/}
      <button
        type="button"
        onClick={logoutHandler}
        className="sidebare-buttonExit"
      >
        <img src={exit} alt="Exit" className="sidebare-buttonExit_img" />
        Вихід
      </button>
    </div>
  );
};

export default Sidebar;
