import s from './advertiserProfile.module.scss';
import { useState } from 'react';
import AlertModal from '../../../components/AlertModal';
import { ConfirmModal } from '../../../components/ConfirmModal/ConfirmModal';
import LoaderContent from '../../../components/LoaderContent';
import { useAdvertiserChangeCompanyStatusMutation } from '../../../store/apis/advertiserApi';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '../../../../utils/icons/success-verification.svg'
import { ReactComponent as RejectIcon } from '../../../../utils/icons/close-classik-icon-violet.svg'
import { VerificationMessages } from '../../../components/verificationMessagesModal/verificationMessagesModal';
import { useGetWarningsMutation } from '../../../store/apis/usersApi';

export const AdvertiserProfile = ({userData, userStatuses}) => {
   
    // ### State
    const [errorMessage, setErrorMessage] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [messagesModal, setMessagesModal] = useState(false)

    const { t } = useTranslation()

    const [changeStatus, {isLoading: isChangeStatus}] = useAdvertiserChangeCompanyStatusMutation()
    const [getWarning, {isLoading: isGetMenuWarnings}] = useGetWarningsMutation()

    // ### Function

    async function handleSubmit(status, message){
        const response = await changeStatus({
            id: parseInt(userData?.[0]?.id),
            status,
            isMessage: message ? true : false,
            message: message?.message
        })
        if(response.error){
            setErrorMessage(response.error.data?.message)
            return
        }
        getWarning()
    }

    return(
        <>
        {
            errorMessage
            &&
            <AlertModal
                content={ errorMessage }
                setErrorMessage={ setErrorMessage }
            />
        }
        <div className={s.profile_wrapper}>
            <div className={s.left}>
                <div className={s.header}>
                    <h2>{t('a_pi')}</h2>
                </div>
                <div className={s.inputs_wrapper}>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>{t('a_cn')}:</span>
                            <span className={s.input}>{userData?.[0]?.companyName}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>{t('a_num')}:</span>
                            <span className={s.input}>{userData?.[0]?.companyNumber}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>{t('phone_number')}:</span>
                            <span className={s.input}>{userData?.[0]?.phone.fullPhone}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>{t('email')}:</span>
                            <span className={s.input}>{userData?.[0]?.email}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>{t('a_no')}:</span>
                            <span className={s.input}>{userData?.[0]?.companyFounder}</span>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.input_wrapper}>
                            <span>{t('a_pa')}:</span>
                            <span className={s.input}>{userData?.[0]?.companyAddress.line}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.footer}>
                {userData?.[0]?.status !== 1 ? 
                    <div className={`${s.success_button}`} onClick={()=>setConfirmModal(userData?.[0]?.companyName)}>
                        <SuccessIcon/>
                        <span>{t('verification_success')}</span>
                    </div> : null
                }
                {userData?.[0]?.status !== 2 ? 
                    <div className={`${s.success_button} ${s.reject_button}`} onClick={()=>setMessagesModal(2)}>
                        <RejectIcon/>
                        <span>{t('reject')}</span>
                    </div> : null
                }
            </div>
        </div>
        
        {confirmModal? <ConfirmModal title={t('verification_title')} text={`${t('verification_confirm')} ${confirmModal}?`} close={()=>setConfirmModal(false)} success={()=>handleSubmit(1, false)} /> : null}
        {messagesModal ? <VerificationMessages type={messagesModal} close={()=>setMessagesModal(false)} confirm={handleSubmit}/> : null}
        
        {(isChangeStatus || isGetMenuWarnings) && <LoaderContent />}
        </>
    )
}