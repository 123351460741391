import { useTranslation } from 'react-i18next';
import s from './userProfile.module.scss';
import LoaderContent from '../../../../components/LoaderContent';
import { ReactComponent as FalseIcon } from '../../../../../utils/icons/on-moderation-user-status.svg'
import { ReactComponent as TrueIcon } from '../../../../../utils/icons/verification-icon.svg'
import { countries } from '../../../../../utils/countries';
import { useEffect, useState } from 'react';
import { ReactComponent as SuccessIcon } from '../../../../../utils/icons/success-verification.svg'
import { ReactComponent as RejectIcon } from '../../../../../utils/icons/close-classik-icon-violet.svg'
import { ViewImageModal } from '../../../../components/ViewImageModal/ViewImageModal';
import { usePostVerificationVerdictMutation } from '../../../../store/apis/usersApi';
import AlertModal from '../../../../components/AlertModal';
import { ConfirmModal } from '../../../../components/ConfirmModal/ConfirmModal';
import { VerificationMessages } from '../../../../components/verificationMessagesModal/verificationMessagesModal';
const URL = process.env.REACT_APP_API_URL

export const UserProfile = ({ userData, userVerification, verificationStatus, verificationStatuses }) => {

    // ### State
    const { t, i18n } = useTranslation()

    const [status, setStatus] = useState(verificationStatus)
    const [isMessage, setIsMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [viewImage, setViewImage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [messagesModal, setMessagesModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)

    const [verification, {isLoading: isVerdictVerification}] = usePostVerificationVerdictMutation()
    
    // ### Effects
    useEffect(()=>{
        setStatus(verificationStatus)
    },[verificationStatus]);

    // ### Functions
    async function handleVerification(status, message){
        setConfirmModal(false)
        const response = await verification({
            id: userVerification.id,
            data: userVerification.data,
            status: status,
            message: message.message
        })
        if(response.error) setErrorMessage(response.error.data.message)
            else setSuccessModal(true)
    }
    if(isVerdictVerification) return <LoaderContent />
    
    return(
        <>
        {
            errorMessage
            &&
            <AlertModal
                content={errorMessage}
                setErrorMessage={setErrorMessage}
            />
        }
        {
            successModal ? 
            <ConfirmModal 
                title={t('u_status')}
                text={t('s_stc')}
                close={()=>setSuccessModal(false)}
            /> : null
        }
        <div className={s.profile_wrapper}>
        {
            userVerification && 
                <div className={`${s.block} ${s.verification_block}`}>
                    <div className={s.row}>
                        <h1>{t('u_verification_account')}</h1>
                        <div className={s.left}>
                            <div className={s.row}>
                                <div className={s.input_wrapper}>
                                    <span>Name:</span>
                                    <span className={s.input}>{userVerification?.data?.name || '---'}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.input_wrapper}>
                                    <span>Surname:</span>
                                    <span className={s.input}>{userVerification?.data?.surname || '---'}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.input_wrapper}>
                                    <span>Age:</span>
                                    <span className={s.input}>{userVerification?.data?.userAge || '---'}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.input_wrapper}>
                                    <span>Gender:</span>
                                    <span className={s.input}>{userVerification?.data?.gender === 'm'? 'Male' : "Female"}</span>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.input_wrapper}>
                                    <span>Country:</span>
                                    <span className={s.input}>{(countries?.en?.find(el => el.alpha2 === userVerification?.data?.country)?.name) || '---'}</span>
                                </div>
                            </div>
                        </div>
                        <div className={s.right}>
                            <div className={`${s.moderation_wrapper} ${s.box_shadow}`}>
                                { (status === 2 || status === 3) &&
                                <div className={s.message_wrapper}>
                                    <div className={s.title}>
                                        <input type="checkbox" checked={isMessage} onChange={(ev)=>setIsMessage(ev.target.checked)}/>
                                        <span>{t('a_message')}</span>
                                    </div>
                                    <textarea onChange={(ev)=>setMessage(ev.target.value)} disabled={!isMessage} value={message}></textarea>
                                </div>
                                }
                            </div>
                            <div className={s.images_wrapper}>
                            {
                                userVerification?.img?.map(el => 
                                    <div className={s.img_wrapper} onClick={()=>setViewImage(`${URL.slice(0, -4)}${el.path}`)}>
                                        <img src={`${URL.slice(0, -4)}${el.path}`} alt="verification" />
                                    </div>
                                )
                            }
                            </div>
                        </div>
                    </div>
                    <div className={s.footer}>
                        {userVerification?.status !== 1 ? 
                            <div className={`${s.success_button}`} onClick={()=>setConfirmModal(userVerification?.data?.name)}>
                                <SuccessIcon/>
                                <span>{t('verification_success')}</span>
                            </div> : null
                        }
                        <div className={`${s.success_button} ${s.reject_button}`} onClick={()=>setMessagesModal(2)}>
                            <RejectIcon/>
                            <span>{t('reject')}</span>
                        </div>
                    </div>
                </div>
            }
            <div className={s.block}>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>ID:</span>
                        <span className={s.input}>{userData?.id}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Full name:</span>
                        <span className={s.input}>{userData?.pib || "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Login:</span>
                        <span className={s.input}>{userData?.login || "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Email:</span>
                        <span className={s.input}>{userData?.email || "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Phone:</span>
                        <span className={s.input}>{userData?.phone || "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Language:</span>
                        <span className={s.input}>{userData?.lang || "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Registration date:</span>
                        <span className={s.input}>{new Date(parseInt(userData?.dateRegiter)).toLocaleDateString()}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Date of birth:</span>
                        <span className={s.input}>{new Date(userData?.dateOfBirth).toLocaleDateString() || "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Age:</span>
                        <span className={s.input}>{userData?.gender ? (userData?.gender === 'm'? 'Чоловiк' : "Жiнка") : "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Location:</span>
                        <span className={s.input}>{userData?.location ? userData?.location.line : "- - -"}</span>
                    </div>
                </div>
            </div>
            <div className={s.block}>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Instagram:</span>
                        <span className={s.input}>{userData?.instagram || "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Facebook:</span>
                        <span className={s.input}>{userData?.facebook || "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Telegram:</span>
                        <span className={s.input}>{userData?.telegram || "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>About:</span>
                        <span className={s.input}>{userData?.description || "- - -"}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Email verification:</span>
                        <span className={s.icon}>{userData?.verification?.email ?<TrueIcon/> : <FalseIcon/>}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Phone verification:</span>
                        <span className={s.icon}>{userData?.verification?.phone ?<TrueIcon/> : <FalseIcon/>}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Apple verification:</span>
                        <span className={s.icon}>{userData?.verification?.apple ?<TrueIcon/> : <FalseIcon/>}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Google verification:</span>
                        <span className={s.icon}>{userData?.verification?.google ?<TrueIcon/> : <FalseIcon/>}</span>
                    </div>
                </div>
                <div className={s.row}>
                    <div className={s.input_wrapper}>
                        <span>Documents verification:</span>
                        <span className={s.icon}>{userData?.verification?.docs ?<TrueIcon/> : <FalseIcon/>}</span>
                    </div>
                </div>
            </div>
        </div>
        {false && <LoaderContent />}
        {viewImage ? <ViewImageModal link={viewImage} close={()=>setViewImage(false)}/> : null}
        {confirmModal? <ConfirmModal title={t('verification_title')} text={`${t('verification_confirm')} ${confirmModal}?`} close={()=>setConfirmModal(false)} success={()=>handleVerification(1, false)} /> : null}
        {messagesModal ? <VerificationMessages type={messagesModal} close={()=>setMessagesModal(false)} confirm={handleVerification}/> : null}
        </>
    )
}