import React, { useState } from 'react'
import RbacTablWorker from "../../pages/RBACPage/RbacTablWorker/RbacTablWorker"
import RbacTablRole from "../../pages/RBACPage/RbacTablRole/RbacTablRole"
import addPlus from "../../../utils/icons/addPlus.svg"
import { useTranslation } from 'react-i18next'

const ControlPanelForRBAC = ( {showModalHandler, setCurrentUser, setRoleModal} ) => {

	const [ tabSwitcher, setTabSwitcher ] = useState( 1 )
	const {t} = useTranslation()

	const toggleButtonsType = ( name, anchor ) => {
		const allButtonsType = document.querySelectorAll( '.terms-wrapper_search-types_button' )
		for ( let i = 0; i < allButtonsType.length; i++ ) {
			if ( allButtonsType[i].innerText === name ) {
				allButtonsType[i].classList.add( 'term-types_active' )
				setTabSwitcher( anchor )
			} else {
				allButtonsType[i].classList.remove( 'term-types_active' )
			}
		}
	}

	return (
		<div className='terms-wrapper'>
			<button
				className='terms-wrapper_addButton'
				style={{whiteSpace: 'nowrap', width: '300px'}}
				type='button'
				onClick={ tabSwitcher === 1 ? showModalHandler : ()=>setRoleModal(true) }
			>
				<img
					src={ addPlus }
					alt='Add Plus'
				/>
				{ tabSwitcher === 1? t('t_rbac_button_addEmployee') : t('t_rbac_button_addPosition') }
			</button >
			<div className='terms-wrapper_search-types'>
				<button
					type='button'
					className='terms-wrapper_search-types_button term-types_active'
					onClick={ () => {
						toggleButtonsType( 'Employees', 1 )
					} }
				>
					Employees
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={ () => {
						toggleButtonsType( 'Positions', 2 )
					} }
				>
					Positions
				</button >
			</div >
			{
				tabSwitcher === 1
				&&
				<RbacTablWorker setCurrentUser={setCurrentUser}/>
			}
			{
				tabSwitcher === 2
				&&
				<RbacTablRole setRoleModal={setRoleModal}/>
			}

		</div >
	)
}

export default ControlPanelForRBAC