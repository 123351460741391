import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	TEMPLATE_API,
} from "../../../utils/constants"

const API = process.env.REACT_APP_API_URL;

export const templateApi = createApi({
	reducerPath: "templateApi",
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${token}`)
			}
			return headers
		}
	}),
	endpoints: (builder) => (
		{
			getTemplateV2: builder.mutation({
				query: ({filters, query, sortBy, order, page, limit}) => (
					{
						url: `${TEMPLATE_API.GET_TEMPLATE_V2}?
					${filters ? filters : ''}
					${query ? query : ''}
					${sortBy ? sortBy : ''}
					${order ? order : ''}
					${page ? page : ''}
					${limit ? limit : ''}
					`,
						method: "GET",
					})
			}),
			getTemplateV2Info: builder.mutation({
				query: (sub) => (
					{
						url: `${TEMPLATE_API.GET_TEMPLATE_V2_INFO}?
						${sub ? sub : ''}`,
						method: "GET",
					})
			}),
			getTemplate: builder.mutation({
				query: () => (
					{
						url: TEMPLATE_API.GET_TEMPLATE,
						method: "GET",
					})
			}),
			createTemplate: builder.mutation({
				query: (body) => (
					{
						url: TEMPLATE_API.CREATE_TEMPLATE,
						method: "POST",
						body
					})
			}),
			updateTemplate: builder.mutation({
				query: (body) => (
					{
						url: TEMPLATE_API.UPDATE_TEMPLATE,
						method: "POST",
						body
					})
			}),
			deleteTemplate: builder.mutation({
				query: (body) => (
					{
						url: TEMPLATE_API.DELETE_TEMPLATE,
						method: "POST",
						body
					})
			}),
			syncTemplatesToProd: builder.mutation({
				query: (body) => (
					{
						url: TEMPLATE_API.SYNC_TO_PROD,
						method: "POST",
						body
					})
			}),
		})
});

export const {
	useGetTemplateMutation,
	useGetTemplateV2Mutation,
	useGetTemplateV2InfoMutation,
	useCreateTemplateMutation,
	useUpdateTemplateMutation,
	useDeleteTemplateMutation,
	useSyncTemplatesToProdMutation


} = templateApi
