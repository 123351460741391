import { base64ToBlob } from '../../../../utils/helpers/base64ToBlob';
import { MarkerItem } from '../../../components/MarkerItem/MarkerItem';
import s from './advertiserMarkers.module.scss';
import { ReactComponent as SuccessIcon } from '../../../../utils/icons/success-verification.svg'
import { ReactComponent as RejectIcon } from '../../../../utils/icons/close-classik-icon-violet.svg'
import { useState } from 'react';
import { ConfirmModal } from '../../../components/ConfirmModal/ConfirmModal';
import { VerificationMessages } from '../../../components/verificationMessagesModal/verificationMessagesModal';
import { useTranslation } from 'react-i18next';
import { useAdvertiserChangeMarkerStatusStatusMutation } from '../../../store/apis/advertiserApi';
import Loader from '../../../components/LoaderContent';
import AlertModal from '../../../components/AlertModal';
import { useGetWarningsMutation } from '../../../store/apis/usersApi';

export const AdvertiserMarkers = ({markers, companyId}) => {
    console.log("markers", markers);
    // ### State
    const [confirmModal, setConfirmModal] = useState(false)
    const [messagesModal, setMessagesModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [changeMarkerStatausQuery, {isLoading: isChangeStatusLoading}] = useAdvertiserChangeMarkerStatusStatusMutation()
    const [getWarning, {isLoading: isGetMenuWarnings}] = useGetWarningsMutation()

    const {t} = useTranslation()

    // ### Effects

    // ### Functions

    async function handleMarkersSubmit(status, message){
        const response = await changeMarkerStatausQuery({companyId, markerProfileStatus: status, moderationMessage: message.message})
        if(response.error) setErrorMessage(response.error?.data?.message)
        else if(response.data === 'success') setSuccessModal(markers.name)
        getWarning()
    }

    // ### Views

    const markersView = markers?.markers? JSON.parse(JSON.stringify(markers?.markers))?.sort((a, b) => a.moderationStatus - b.moderationStatus)?.map((el, index) => {
        return <MarkerItem key={index} marker={el} companyName={markers.name} companyId={companyId}/>
    }) : null
    if(!markers.logo) return(<h2>{t('cd_emptyResponse')}</h2>)
    return(
        <>
            { errorMessage && <AlertModal content={errorMessage} setErrorMessage={setErrorMessage} />}
            <header className={s.header}>
                <div className={s.row}>
                    <div className={s.row_column}>
                        <div className={s.text_wrapper}>
                            <span className={s.title}>{t('title')}</span>
                            <span className={s.value}>{markers.name}</span>
                        </div>
                        <div className={s.text_wrapper}>
                            <span className={s.title}>{t('link')}</span>
                            <span className={s.value}>{markers.link}</span>
                        </div>
                    </div>
                    <div className={s.logo_wrapper} style={{backgroundImage: markers?.logo?.file ? `url(${URL.createObjectURL(base64ToBlob(markers.logo.file, markers.logo.mimetype))})` : ''}}></div>
                </div>
                <div className={s.buttons_wrapper}>
                    {markers.moderationStatus !== 1 ? 
                        <div className={`${s.success_button}`} onClick={()=>setConfirmModal(markers.name)}>
                            <SuccessIcon/>
                            <span>{t('verification_success')}</span>
                        </div> : null
                    }
                    {markers.moderationStatus !== 2 ? 
                        <div className={`${s.success_button} ${s.reject_button}`} onClick={()=>setMessagesModal(2)}>
                            <RejectIcon/>
                            <span>{t('reject')}</span>
                        </div> : null
                    }
                </div>
            </header>
            <div className={s.markers_wrapper}>
                {markersView}
            </div>
            {confirmModal? <ConfirmModal title={t('verification_title')} text={`${t('verification_confirm')} ${confirmModal}?`} close={()=>setConfirmModal(false)} success={()=>handleMarkersSubmit(1, false)} /> : null}
            {successModal? <ConfirmModal title={t('_a_message')} text={`${successModal} ${t('successfully_updated')}`} close={()=>setSuccessModal(false)} /> : null}
            {messagesModal ? <VerificationMessages type={messagesModal} close={()=>setMessagesModal(false)} confirm={handleMarkersSubmit}/> : null}
            { isChangeStatusLoading || isGetMenuWarnings ? <Loader/> : null }
        </>
    )
}