import React from 'react'
import {
	Tab,
	Tabs
} from "react-bootstrap"
import SaveDisk from "../../../../utils/icons/save-disk-icon.svg"
import CloseIcon from "../../../../utils/icons/close-classik-icon-violet.svg"
import RbacAdminInfoItemPersonalInfo from "./RbacAdminInfoItem/RbacAdminInfoItemPersonalInfo"
import RbacAdminInfoItemRole from "./RbacAdminInfoItem/RbacAdminInfoItemRole"
import RbacAdminInfoItemContacts from "./RbacAdminInfoItem/RbacAdminInfoItemContacts"
import RbacAdminInfoItemPassword from "./RbacAdminInfoItem/RbacAdminInfoItemPassword"
import { useNavigate } from "react-router-dom"
import {
	ARE_COMMON,
	PAGES_NAME
} from "../../../../utils/textsContent";

const RbacAdminInfoPage = () => {
	const navigate = useNavigate()
	return (
		<div className='terms'>
			<h1 className='terms-title'>
				{PAGES_NAME?.RBAC}
			</h1 >
			<div className='rbac'>
				<div className='rbac-wrapper'>
					<div className='rbac-wrapper_groupButtons'>
					<button
						className='terms-wrapper_addButton rbac-wrapper_saveButton'
					>
						<img
							src={ SaveDisk }
							alt='save disk'
						/>
						{ARE_COMMON?.SAVE}
					</button >
					<button
						className='terms-wrapper_addButton rbac-wrapper_backButton'
						onClick={ () => navigate( -1 ) }
					>
						<img
							src={ CloseIcon }
							alt='close'
						/>
					</button >
					</div >
					<div className='userInfo-wrapper'>
						<div className='userInfo-wrapper_block'>
							<div className='userInfo-wrapper_block-wrapper'>
								<div className='userInfo-wrapper_block-avatar'>
									{/*<img*/ }
									{/*	src='/'*/ }
									{/*	alt='Avatar User'*/ }
									{/*	className='userInfo-wrapper_block-avatar_img'*/ }
									{/*/>*/ }
									<div className='userInfo-wrapper_block-avatar_img'></div >
								</div >
								<div className='userInfo-wrapper_block-pib'>
									<p
										className='userInfo-wrapper_block-pib_status'
										// style={{
										//  backgroundColor: `${verificationSwitch?.bg}`,
										//  color: `${verificationSwitch?.color}`
										// }}
									>
										Super_admin
									</p >
									<h2 className='userInfo-wrapper_block-pib_name'>
										Микола миколенко
									</h2 >
									<span className='userInfo-wrapper_block-pib_email'>gmail@example.com</span >
								</div >
							</div >
							<div className='userInfo-wrapper_block-buttons rbac-wrapper_block'>
								<p className='rbac-wrapper_block-online'>
									Online
								</p >
								<button className='userInfo-wrapper_block-buttons_message rbac-wrapper_block-message'>
									Повідомлення
								</button >
							</div >
						</div >
					</div >
					<div className='userInfo-tabs'>
						<Tabs
							defaultActiveKey={ 1 }
							className='userInfo-tabs_block'
						>
							<Tab
								title={ 'Профіль' }
								eventKey={ 1 }
								className='userInfo-tabs_block-tab'
							>
								<RbacAdminInfoItemPersonalInfo />
							</Tab >
							<Tab
								title={ 'Роль і посада' }
								eventKey={ 2 }
								className='userInfo-tabs_block-tab'
							>
								<RbacAdminInfoItemRole />
							</Tab >
							<Tab
								title={ 'Контакти ' }
								eventKey={ 3 }
								className='userInfo-tabs_block-tab'
							>
								<RbacAdminInfoItemContacts />
							</Tab >
							<Tab
								title={ 'Пароль' }
								eventKey={ 4 }
								className='userInfo-tabs_block-tab'
							>
								<RbacAdminInfoItemPassword />
							</Tab >
						</Tabs >
					</div >

				</div >
			</div >
		</div >
	)
}

export default RbacAdminInfoPage