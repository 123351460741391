import s from './advertiserWallet.module.scss';
import LoaderContent from '../../../components/LoaderContent';
import { useAdvertiserGetRefillHistoryQuery } from '../../../store/apis/advertiserApi';
import { useTranslation } from 'react-i18next';

export const AdvertiserWallet = ({ userData }) => {

    // ### State
    const {data: history, isLoading: isRefillLoading} = useAdvertiserGetRefillHistoryQuery({id: userData.creator?.id})
    const { t } = useTranslation()

    // ### Views

    const historyView = history?.map((el, index) => {
        return(
            <tr key={index}>
                <td><span className={s.amount}>{el.transferred_amount} {el.currency}</span></td>
                <td>{el.status === "COMPLETED" ? <span className={s.completed}>{t('completed')}</span> : <span className={s.wait}>{t('waiting')}</span>}</td>
                <td>{new Date(el.expired_date).toLocaleDateString()}</td>
            </tr>
        )
    })
    
    return(
        <>
        {
            isRefillLoading ? <LoaderContent /> : 
            <table className={s.table}>
                <thead>
                    <tr>
                        <td>{t('amount')}</td>
                        <td>{t('status')}</td>
                        <td>{t('date')}</td>
                    </tr>
                </thead>
                <tbody>
                    {historyView}
                </tbody>
            </table>
        }
        </>
    )
}