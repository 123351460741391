import React from 'react'
import { Form } from 'react-bootstrap'
import CreatePollTools from './CreatePollTools'
import imageIcon from '../../../../utils/icons/add-image-icon.svg'
import CopyIcon from '../../../../utils/icons/add-copy-icon.svg'
import DeleteICon from '../../../../utils/icons/add-delete-violet-con.svg'
import DotsIcon from '../../../../utils/icons/vertical-dots.svg'

const CreatePollQuestion = ( { handleBlur, form, touched, errors, values, handleChange, formDateUpdateHandler } ) => {
	return (
		<div className='poll-create_content'>
			<div className='poll-create_block'>
				<div className='poll-create_block-question'>
					<Form.Group
						className='add-edit-htmls_center'
					>
						Запитання
						<Form.Control
							type='text'
							name='question'
							onBlur={ handleBlur }
							placeholder='Введіть текст запитання'
							onKeyDown={ e => {
								e.key === 'Enter' && e.preventDefault()
							} }
							className={ `add-edit-htmls_center-inp  ${ touched?.question ? "is-touch " : "" } ${ errors?.question && touched?.question ? " is-invalid" : "" } ` }
							value={ values.question }
							onChange={ ( e ) => {
								handleChange( e )
								// formDateUpdateHandler( { [ e.target.name ]: e.target.value } )
							} }
						/>
						{ errors.question && touched.question && (
							<Form.Control.Feedback type='invalid'>
								{ errors.question }
							</Form.Control.Feedback >
						) }
					</Form.Group >

					<img
						src={imageIcon}
						alt='image_icon'
						className='poll-create_block-question_image'
					/>
					<Form.Group
						className='add-edit-htmls_center'
					>
						<select
							aria-label='Default select example'
							className='template-content_typeBlock-select poll-create_block-question_select'
							name='audienceCustom'
							// onChange={ ( e ) => formDateUpdateHandler( { [ e.target.name ]: e?.target.value } ) }
						>
							<option value={ 0 }>Один зі списку</option >
							<option value={ 1 }>Декілька зі списку</option >
							<option value={ 2 }>Список що розкривається</option >
							<option value={ 3 }>Час</option >
							<option value={ 4 }>Дата</option >
						</select >
					</Form.Group>
				</div >
				<Form.Control
					as='textarea'
					rows='3'
					name='text'
					placeholder='Текстове поле'
					onKeyDown={ e => {
						e.key === 'Enter' && e.preventDefault()
					} }
					onBlur={ handleBlur }
					value={ values.text }
					maxLength={ 100 }
					className={ `add-edit-htmls_inp  ${ touched?.text ? "is-touch " : "" } ${
						errors?.text && touched?.text ? " is-invalid" : ""
					} ` }
					onChange={ ( e ) => {
						handleChange( e )
						formDateUpdateHandler( {
							[e.target.name]: e.target.value
						} )
					} }
				/>
				<div className='poll-create_block-question_bottom'>
					<div className='poll-create_block-question_bottom-wrapper'>
						<div className='poll-create_block-question_bottom-wrapper_block'>
							<img
								className='poll-create_block-question_bottom-wrapper_block-icon'
								src={CopyIcon}
								alt='copy'
							/>
							<img
								className='poll-create_block-question_bottom-wrapper_block-icon'
								src={DeleteICon}
								alt='delete'
							/>
						</div>
						<Form.Group className='poll-create_block-question_bottom-wrapper_switch'>
							Обов’язкове питання
							<Form.Switch
								type='radio'
								name='switch'
								onBlur={ handleBlur }
								placeholder='Введіть текст запитання'
								onKeyDown={ e => {
									e.key === 'Enter' && e.preventDefault()
								} }
								className='poll-create_block-question_bottom-wrapper_switch-inp'
								onChange={ ( e ) => {
									handleChange( e )
									// formDateUpdateHandler( { [ e.target.name ]: e.target.value } )
								} }
							/>
							{ errors.switch && touched.switch && (
								<Form.Control.Feedback type='invalid'>
								{ errors.switch }
								</Form.Control.Feedback >
							) }
						</Form.Group >
						<button className='poll-create_block-question_bottom-wrapper_dots'>
							<img
								src={DotsIcon}
								alt='dots icon'
							/>
						</button>
					</div>
				</div>
			</div >
			<CreatePollTools />
		</div >
	)
}

export default CreatePollQuestion