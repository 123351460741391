import React, { useEffect, useState } from 'react';
import s from './mainCategoryForm.module.scss'
import BlockWithTranslationFieldsCategories from '../../../../components/BlockWithTranslationFieldsCategories';
import { ContentCreator } from '../../../../components/ContentCreator/ContentCreator';

export const MainCategoryForm = ({ form, setForm }) => {

    const [valuesForm, setValuesForm] = useState(form.mainType || {});

    useEffect(()=>{
        setForm(prev => {
            return {...prev, mainType: {...form.mainType, values: {...valuesForm.values}}}
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[valuesForm]);

    useEffect(()=>{
        if(!form.mainType){
            setForm(prev => {return{...prev, mainType: {
                values: {},
                title: {},
                description: {},
                imageType: ""
            }}})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[form]);

    function changeTitle(el, lang){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            if(!newState.mainType.title) newState.mainType.title = {}
            newState.mainType.title[lang] = el.target.value
            return newState
        })
    }

    function changeDescription(el, lang){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            if(!newState.mainType.description) newState.mainType.description = {}
            newState.mainType.description[lang] = el.target.value
            return newState
        })
    }

    function setImageType(type){
        setForm(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            newState.mainType.imageType = type
            return newState
        })
    }

    return(
        <div className={s.wrapper}>
            <div className='categories-create_block' style={{width: '100%'}}>
                <BlockWithTranslationFieldsCategories
                    handleChange={changeTitle}
                    values={form.mainType?.title}
                    touched={false}
                    formDateUpdateHandler={()=>{}}
                    errors={[]}
                    name={'Заголовок категорії'}
                    title={'title'}
                />
            </div >

            <div className='categories-create_block' style={{width: '100%'}}>
                <BlockWithTranslationFieldsCategories
                    handleChange={changeDescription}
                    values={form.mainType?.description}
                    touched={false}
                    formDateUpdateHandler={()=>{}}
                    errors={[]}
                    name={'Пiдзаголовок категорії'}
                    title={'subtitle'}
                />
            </div >

            <ContentCreator form={valuesForm} setForm={setValuesForm}/>

            <div className={`categories-create_block ${s.image_type_wrapper}`} style={{marginBottom: 0, width: '100%'}}>
                <h3>Image type</h3>
                <input type="text" value={form.mainType.imageType} onChange={(e => setImageType(e.target.value))}/>
            </div >
        </div>
    )
}