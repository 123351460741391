import React, {
	useEffect,
	useState
} from 'react'
import ControlPanelsForUsers from '../../components/environmentTabl/ControlPanelsForUsers'
import { useSelector } from 'react-redux'
import { ShowFirstSelectedListItems } from '../../../utils/helpers/ShowFirstSelectedListItems'

const ChangeUserForAdvertising = ( { fromPage, setGroupUsers, groupUsers } ) => {
	const { users } = useSelector( state => state.usersStore )
	const { dateModalChangeUsersForAddressBook } = useSelector( ( state ) => state.advertisingStore )


	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )
	const [ errorMessage, setErrorMessage ] = useState( '' )
	const [ markerListItems, setMarkerListItems ] = useState( [] )

	useEffect( () => {
		const res = ShowFirstSelectedListItems( dateModalChangeUsersForAddressBook, users )
		setMarkerListItems(res)
	}, [ users ] )

	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}
	
	return (
		<div className='changeUnitForPrice'>
			<div className='changeUnitForPrice-wrapper changeUnitForTemplates'>
				<ControlPanelsForUsers
					bigArrElements={ errorMessage === '' ? markerListItems?.length > 0 ? markerListItems : users : [] }
					fromPage={ fromPage === 'changeUserForAdvertising'
						?
						'changeUserForAdvertising'
						:
						fromPage === 'changeUsersListForAddressBook'
							?
							'changeUsersListForAddressBook'
							:
							'users'
					}
					toggleModalSearch={ toggleModalSearch }
					showModalSearch={ showModalSearch }
					showSearchBeforeDel={ showSearchBeforeDel }
					setShowSearchBeforeDel={ setShowSearchBeforeDel }
					setErrorMessage={ setErrorMessage }
					setGroupUsers={setGroupUsers}
					groupUsers={groupUsers}
				/>
			</div >
		</div >
	)
}

export default ChangeUserForAdvertising