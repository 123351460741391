import React, {
	useCallback,
	useEffect,
	useState
} from "react"
import {
	useDispatch,
	useSelector
} from "react-redux"
import ControlPanels from "../../components/environmentTabl/ControlPanels"
import AddEditUnitsMeasurementItem from "../../components/modalsAddEdit/AddEditUnitsMeasurementItem"
import { ShowFirstSelectedListItems } from "../../../utils/helpers/ShowFirstSelectedListItems"
import { UniqueArrItems } from "../../../utils/helpers/UniqueArrItems"
import { setUnitsMeasurementList } from "../../store/slices/unitsMeasurementSlice";

const ChangeUnitForUnitGroups = () => {
	const { unitsMeasurement } = useSelector( ( state ) => state.unitsMeasurementStore )
	const unitlist = useSelector(store => store.unitsMeasurementStore.unitsMeasurement)
	const { dateModalChangeUnitGroups, showModalChangeUnitGroup } = useSelector( ( state ) => state.unitGroupsStore )
	const [ addUnitFromAmountShowModal, setAddUnitFromAmountShowModal ] = useState( false )
	const dispatch = useDispatch()

	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ loaderSorting, setLoaderSorting ] = useState( false )
	const [ showModalSearch, setShowModalSearch ] = useState( false )
	const [ showSearchBeforeDel, setShowSearchBeforeDel ] = useState( false )

	const [ addCheckedItemsMarkerInList, setAddCheckedItemsMarkerInList ] = useState( [] )
	
	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}
	const toggleModalSearch = ( t ) => {
		setShowModalSearch( t )
	}

	const showModalAddUnitForAmountHandler = () => {
		setAddUnitFromAmountShowModal( true )
	}
	const hideModalAddUnitForAmountHandler = () => {
		setAddUnitFromAmountShowModal( false )
	}

	const getUnitList = useCallback(async () => {
		dispatch( setUnitsMeasurementList( unitlist ) )
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const getAllTerms = useCallback( async () => {
		try {
			await getUnitList()
		} catch ( e ) {
			console.log( e )
		}
	}, [getUnitList] )

	useEffect( () => {
		getAllTerms()
	}, [getAllTerms] )

	useEffect( () => {
		const res = ShowFirstSelectedListItems( UniqueArrItems( dateModalChangeUnitGroups ), unitsMeasurement )
		setAddCheckedItemsMarkerInList( res )
	}, [ showModalChangeUnitGroup, unitsMeasurement, dateModalChangeUnitGroups ] )
	
	return (
		<div className='changeUnitForPrice'>
			<div className='changeUnitForPrice-wrapper changeUnitForTemplates'>
				<ControlPanels
					bigArrElements={
						addCheckedItemsMarkerInList?.length > 0
							?
							addCheckedItemsMarkerInList
							: unitsMeasurement
					}
					fromPage={ 'ChangeUnitGroups' }
					showModalAddUnitHandler={ showModalAddUnitForAmountHandler }
					searchTermValue={ searchTermValue }
					loaderSorting={ loaderSorting }
					setLoaderSorting={ setLoaderSorting }
					toggleModalSearch={ toggleModalSearch }
					showModalSearch={ showModalSearch }
					showSearchBeforeDel={ showSearchBeforeDel }
					setShowSearchBeforeDel={ setShowSearchBeforeDel }
					valueSearchInputHandler={ valueSearchInputHandler }
				/>
				{
					addUnitFromAmountShowModal
					&&
					<AddEditUnitsMeasurementItem
						show={ addUnitFromAmountShowModal }
						onHide={ hideModalAddUnitForAmountHandler }
					/>
				}
			</div >
		</div >
	)
}

export default ChangeUnitForUnitGroups
