import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
	ADDRESS_BOOK_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const addressBookApi = createApi( {
	reducerPath: "addressBook",
	tagTypes: [ 'Books' ],
	baseQuery: fetchBaseQuery( {
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{ getState }
		) => {
			const { token } = getState().userStore;
			if ( token ) {
				headers.set( "Authorization", `Bearer ${ token }` )
			}
			return headers
		}
	} ),
	endpoints: ( builder ) => ({
		getGroups: builder.mutation( {
			query: ( body ) => (
				{
					url: `${ ADDRESS_BOOK_API.GET_GROUPS }?
					name=${ body.name ? body.name : '' }
					&email=${ body.email ? body.email : '' }
					&id=${ body.user ? body.user : '' }
					&phone=${ body.phone ? body.phone : '' }
					&limit=${ body.limit ? body.limit : '' }
					&page=${ body.page ? body.page : '' }
					&date=${ body.date ? JSON.stringify( body.date ) : '' }`,
					method: "GET",
				})
		} ),
		getGroup: builder.mutation( {
			query: ( {id, page, limit} ) => (
				{
					url: `${ ADDRESS_BOOK_API.GET_GROUP }?id=${ id }&page=${page}&limit=${limit}`,
					method: "GET",
				})
		} ),
		deleteGroup: builder.mutation( {
			query: ( body ) => (
				{
					url: ADDRESS_BOOK_API.DELETE_GROUP,
					method: "POST",
					body
				}),
			invalidatesTags: [ { type: 'Books', id: 'LIST' } ],
		} ),
		createGroup: builder.mutation( {
			query: ( body ) => (
				{
					url: ADDRESS_BOOK_API.CREATE_GROUP,
					method: "POST",
					body
				})
		} ),
		updateGroup: builder.mutation( {
			query: ( body ) => (
				{
					url: ADDRESS_BOOK_API.UPDATE_GROUP,
					method: "POST",
					body
				})
		} ),
		updateGroupUsers: builder.mutation( {
			query: ( body ) => (
				{
					url: ADDRESS_BOOK_API.UPDATE_GROUP_USERS,
					method: "POST",
					body
				})
		} ),
		deleteGroupUsers: builder.mutation( {
			query: ( body ) => (
				{
					url: ADDRESS_BOOK_API.DELETE_GROUP_USERS,
					method: "POST",
					body
				})
		} ),
		updateUsersWithFilters: builder.mutation( {
			query: ( { status, search, id } ) => (
				{
					url: `${ ADDRESS_BOOK_API.UPDATE_GROUP_USERS_FILTERS }?
					
					${ status ? status : '' }
					${ search ? search : '' }
					${ id ? id : '' }
					`,
					method: "POST",
				})
		} ),
		emailVerificationGroup: builder.mutation( {
			query: ( body ) => (
				{
					url: ADDRESS_BOOK_API.EMAIL_VERIFICATION_GROUP,
					method: "POST",
					headers: {
						"Access-Control-Allow-Origin": "*",
					},
					body: body
				}
			)
		} ),

		emailStopVerificationGroup: builder.mutation( {
			query: ( body ) => (
				{
					url: ADDRESS_BOOK_API.EMAIL_STOP_VERIFICATION_GROUP,
					method: "POST",
					headers: {
						"Access-Control-Allow-Origin": "*",
					},
					body: body
				}
			)
		} ),

		deleteEmailsFromAddressBook: builder.mutation( {
			query: ( body ) => (
				{
					url: ADDRESS_BOOK_API.EMAIL_VERIFICATION_DELETE_USERS,
					method: "POST",
					headers: {
						"Access-Control-Allow-Origin": "*",
					},
					body: body
				}
			),
			invalidatesTags: [ { type: 'Books', id: 'LIST' } ],
		} ),
	})
} );

export const {
	useGetGroupsMutation,
	useCreateGroupMutation,
	useUpdateGroupMutation,
	useUpdateGroupUsersMutation,
	// useGetGroupQuery,
	useGetGroupMutation,
	useDeleteGroupMutation,
	useDeleteGroupUsersMutation,
	useUpdateUsersWithFiltersMutation,
	useEmailVerificationGroupMutation,
	useEmailStopVerificationGroupMutation,
	useDeleteEmailsFromAddressBookMutation
} = addressBookApi
