import React from 'react';
import LoaderContent from "../../../components/LoaderContent";
import { useState } from 'react';
import { useEffect } from 'react';
import AlertModal from '../../../components/AlertModal';
import { useUpdateAdditionalMutation } from '../../../store/apis/subscriptionApi';
import { ConfirmModal } from '../../../components/ConfirmModal/ConfirmModal';
import Loader from '../../../components/Loader';

const AdditionalFunctionsTab = ( {packageArr, isGetSubscriptionPackageLoading} ) => {

	const [values, setValues] = useState({})
	const [errorMessage, setErrorMessage] = useState(false)
	const [modal, setModal] = useState(false)

	const [update, {isLoading}] = useUpdateAdditionalMutation()

	useEffect(()=>{
		packageArr.forEach(el => {
			setValues(prev => {
				return {...prev, [el.key]: el}
			})
		})
	},[packageArr]);

	function changeValue(key, value, item){
		setValues(prev => {
			return {...prev, [key]: {...item, price: value}}
		})
	}

	async function sendAdditional(){
		let error = false
		for(let key in values){
			if(!values[key].price.toString().length) error = "Будь ласка, заповнiть усi поля."
		}
		if(error){
			setErrorMessage(error)
			return
		}
		const data = {
			advertPrice: values['advertPrice'].price,
			remakePrice: values['remakePrice'].price,
			topPrice: values['topPrice'].price
		}
		const result = await update(data)
		if(result.data === "success"){
			setModal('Данi успiшно оновлено.')
		}
		else{
			setErrorMessage('Нажаль виникла помилка')
		}
	}

	return (
		<div className='subscription-option-wrapper'>
			{
				errorMessage &&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
			{
				isGetSubscriptionPackageLoading
					?
					<LoaderContent />
					:
						<div
							className='subscription-option-wrapper_block'
						>
							<div className='subscription-option-wrapper_block-top'>
								<div className='subscription-option-wrapper_block-header'>
									<h3 className='subscription-wrapper_block-header_h3'>
										SET
									</h3 >
									<div className='subscription-wrapper_block-header_line'></div >
								</div >
								<div className='subscription-wrapper_block-period'>
								</div >
								<ul className='subscription-option-wrapper_block-advantages'>
									{packageArr
									&&
									packageArr?.map( (item, index) => (
										<li
											key={index}
											className='subscription-option-wrapper_block-advantages_li'
										>
											<p className='subscription-option-wrapper_block-advantages_li-text'>
												{ item?.title?.ua }
											</p >
											<input type="number" 
											defaultValue={values[item.key]?.price}
											onChange={(ev)=>changeValue(item.key, ev.target.value, item)}
											/>
										</li >
									) )}
								</ul >
							</div >
							<button
								className='subscription-option-wrapper_block-button'
								onClick={sendAdditional}
							>
								{isLoading? <Loader/> : 'Зберегти змiни'}
							</button >
						</div >
			}
			{modal && <ConfirmModal title={modal} close={()=>setModal(false)}/>}
					</div >
	)
}

export default AdditionalFunctionsTab