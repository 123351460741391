import { createSlice } from "@reduxjs/toolkit"
import { recalculationApi } from "../apis/recalculationApi"

const initialState = {
	recalculation: {},
}

const recalculationSlice = createSlice({
	name: "recalculation",
	initialState,
	reducers: {
		createNewRecalculationItem: (state, action) => {
			state.recalculation = [action.payload, ...state.recalculation]
		},
		editRecalculationItem: (state, action) => {
			const index = state.recalculation.findIndex(
				(term) => {
					return term.id === action.payload.id
				}
			)
			state.recalculation[index] = action.payload
		},
		deleteRecalculationItem: (state, action) => {
			const termId = action.payload
			const termID = state.recalculation.filter(term => term.id !== termId)
			state.recalculation = termID
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(recalculationApi.endpoints.getRecalculation.matchFulfilled, (
					state,
					action
				) => {
					state.recalculation = action.payload
				}
			)
	}
})

const {
	actions,
	reducer
} = recalculationSlice
export const {
	createNewRecalculationItem,
	deleteRecalculationItem,
	editRecalculationItem,
} = actions
export default reducer
