import React, { useMemo, useState} from 'react';
import s from './addressBookVerification.module.scss';
import { addressBookApi, useEmailStopVerificationGroupMutation, useEmailVerificationGroupMutation, useGetGroupsMutation } from '../../store/apis/addressBookApi';
import {ReactComponent as Loader} from '../../../utils/icons/loading-icon.svg'
import { ValidationModal } from '../../components/address-book/validationModal/validationModal';
import { useDispatch } from 'react-redux';
import AlertModal from '../../components/AlertModal';
import LoaderContent from "../../components/LoaderContent";
import {ReactComponent as LoaderIcon} from '../../../utils/icons/loading-icon.svg'
import { useTranslation } from 'react-i18next';

export const AddressBookVerification = ()=>{
    
    const dispatch = useDispatch()
    const [groups, setGroups] = useState([])
    const [moderationModal, setModerationModal] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState( '' )
    const {t} = useTranslation()

    const [getGroups, {isLoading: isBooksLoading}] = useGetGroupsMutation()
    const [checkEmails, {isLoading: isCheckEmailsLoading}] = useEmailVerificationGroupMutation()
    const [stopCheckEmails] = useEmailStopVerificationGroupMutation()

    useMemo(async ()=>{
        const groups = await getGroups({})
        setGroups(groups?.data?.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function validationGroup(group_id){
        const result = await checkEmails({group_id})
        console.log(result.data);
        if(result.data?.message === 'Start'){
            setGroups(prev => {
                const newState = JSON.parse(JSON.stringify(prev))
                return newState.map(group => {
                    if(group.id === group_id){
                        group.validationEmailProcess = 1
                        group.validationEmailSuccessful = 0
                        group.validationEmailUnsuccessful = []
                    }
                    return group
                })
            })
        }
        else{
            setErrorMessage(result.error?.data?.message)
        }
    }

    async function stopVerivication(id){
        setModerationModal(false)
        await stopCheckEmails({group_id: id})
        setGroups(prev => {
            const newState = JSON.parse(JSON.stringify(prev))
            return newState.map(group => {
                if(group.id === id){
                    group.validationEmailProcess = 0
                    group.validationEmailDate = 0
                }
                return group
            })
        })
    }

    async function AcceptValidation(){
        setModerationModal(false);
        dispatch(addressBookApi.util.resetApiState())
        const groups = await getGroups({})
        setGroups(groups?.data?.data)
    }

    const book_view = groups?.map((el, index) => {
        return(
            <div key={index} className={s.book}>
                <div className={s.left}>
                    <p className={s.title}>{el.name}</p>
                    <p className={s.date}>{t('created')}: 11.12.13</p>
                </div>
                <div className={s.center}>
                    <div className={s.item}>
                        <div className={s.top}>
                            {
                                el.validationEmailProcess === 1? <Loader/> :
                                <span className={s.value}>{el.contacts}</span >
                            }
                            <span className={s.desc}>{t('_m_email_count')}</span >
                        </div>
                        {
                            el.validationEmailProcess === 1 ?
                            <div className={s.status_check}>
                            {t('verification')}
                            <span className={s.progress}>{`${el.validationEmailSuccessful + el.validationEmailUnsuccessful.length}/${el.emails}`}</span>
                            </div> : null
                        }
                        {
                            !el.validationEmailDate && !el.validationEmailProcess ?
                            <div className={s.status_off}>
                            {t('_m_noValidation')}
                            <span className={s.progress}>0/0</span>
                            </div> 
                            : null
                        }
                        {
                            el.validationEmailDate && !el.validationEmailProcess ?
                            <div className={s.status_on}>
                            {t('_m_Validation')}
                            <span className={s.progress}>{`${el.emails}/${el.emails}`}</span>
                            </div> : null
                        }
                        {
                            el.validationEmailDate && el.validationEmailProcess === 2 ?
                            <div className={s.status_accept}>
                            {t('_m_Validation_upper')}
                            <span className={s.progress}>{`  _`}</span>
                            </div> : null
                        }
                        {
                            !el.validationEmailProcess ?
                            <div className={s.button}
                            onClick={()=>{validationGroup(el.id)}}
                            >{isCheckEmailsLoading? <LoaderIcon/> : t('validation')}</div> : null
                        }
                        {
                            el.validationEmailProcess === 1 ?
                            <div className={s.button_cancel}
                            >{t('validation')}</div> : null
                        }
                        {
                            el.validationEmailProcess === 2 ?
                            <div className={s.button_accept}
                            onClick={() => setModerationModal({emails: el.validationEmailUnsuccessful, group_id: el.id})}
                            >{t('view')}</div> : null
                        }
                    </div >
                    <div className={s.item}>
                        <div className={s.top}>
                            {
                                el.validationEmailStatus? <Loader/> :
                                <span className={s.value}>{el.contacts}</span >
                            }
                            <span className={s.desc}>Number of phones</span >
                        </div>
                        <div className={s.status_check}>
                            Coming soon...
                            <span className={s.progress}>--</span>
                        </div>
                        {
                            !el.validationEmailStatus &&
                            <div className={s.button}
                            // onClick={()=>checkEmails({group_id: el.id})}
                            >{t('validation')}</div>
                        }
                    </div >
                </div>
            </div>
        )
    })

    return(
        <div className='terms'> 
            {
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
            <h1 className='terms-title'>
                {t('_m_adv')}
            </h1 >
            <div className={`terms-wrapper ${s.content}`}>
                {
                    isBooksLoading? <LoaderContent/> : null
                }
                {book_view}
            </div>
            {moderationModal? <ValidationModal emails={moderationModal.emails} group_id={moderationModal.group_id} accept={AcceptValidation} reject={()=>stopVerivication(moderationModal.group_id)} close={setModerationModal}/> : null}
        </div>
    )
}