import {
	Button,
	Form
} from "react-bootstrap"
import { Formik } from "formik"
import React, {
	Suspense,
	useEffect,
	useMemo,
	useState,
} from "react"
import Loader from "../../../components/Loader"
import NameBlockTemplate from "./NameBlockTemplate"
import {
	showChangeTemplateContentTemplates,
	resetDateChangeUnitTemplatesListArr,
	resetMainBlockList,
	resetDetailInfoBlockList,
	resetAdditionalBlockList
} from "../../../store/slices/templateSlice"
import { useDispatch, useSelector } from "react-redux"
import {
	useCreateTemplateMutation,
	useUpdateTemplateMutation,
} from '../../../store/apis/templateApi'
import {
	resetDateChangeTerms,
	showChangeTermsModal,
} from '../../../store/slices/termsSlice'
import {
	resetDateChangeUnitPrice,
	resetDateChangeUnitPriceArr,
	showChangeUnitPriceModal, showModalSubChangeUnitPrice,
} from '../../../store/slices/priceSlice'
import {
	resetDateChangeUnitAmount,
	showChangeUnitAmountModal,
} from '../../../store/slices/amountSlice'
import {
	resetDateChangeUnitTemplates,
	resetDateChangeUnitTemplatesList,
	showChangeUnitModalTemplates,
} from '../../../store/slices/temlateContentSlice'
import AlertModal from "../../../components/AlertModal"
import {
	resetDateChangeMark,
	showChangeMark
} from "../../../store/slices/markSlice"
import {
	resetDateChangeTransportCategory,
	showChangeTransportCategory
} from "../../../store/slices/transportCategorySlice"
import {
	resetDateChangeBodyType,
	showChangeBodyType
} from "../../../store/slices/bodyTypeSlice"
import { ARE_COMMON } from "../../../../utils/textsContent"
import {setDateChangeUnitGroup,
	setDateChangeUnitGroupAmount,
	setDateChangeUnitGroupVolume
} from "../../../store/slices/unitGroupsSlice"
import { useLocation, useNavigate } from "react-router-dom"
import { APP_ROUTE_PAGES } from "../../../../utils/constants"
import CategoryCheckedTemplate from "./CategoryCheckedTemplate"
import { useTranslation } from "react-i18next"

const ChangePrices = React.lazy(() => import('./ChangePrices'))
const ChangeTemplateContentForTemplate = React.lazy(() => import('./ChangeTemplateContentForTemplate'))

const initialState = {
	name: {},
	subcategory: '',
	content: [],
	prices: [],
	status: false,
	specialFunctionality: {
		address: false,
		loyalty: false,
		calendar: false,
		phoneNumber: false,
		delivery: null,
		typeOrderParameter: {},
		transportDimensions: {},
		description: {},
		date: false,
		commission: false,
		order: false,
		writing: false,
		image: false,
		imageRequired: false,
		typeOfCard: 0,
		map: false,
		point: false,
		dateObject: {},
		buttons: {
			inTop: false,
			publish: false,
			remake: false,
			archive: false,
			statistic: false,
			delete: false,
			edit: false,
			open: false,
			close: false,
			finish: false,
			tracking: false,
			point: false
		},
		isPriceRequired: false,
		titleComponents: []
	},
	payment: [],
	discounts: [],
	type: 1,
	commission: [],
}

const AddEditBuilderMiddleTemplate = ({templateType}) => {
	const [form, setForm] = useState(initialState)
	const [blockListFilter, setBlockListFilter] = useState([])
	const [errorMessage, setErrorMessage] = useState('')

	const [createTemplate, {isLoading: isCreateTemplateLoading}] = useCreateTemplateMutation()
	const [updateTemplate, {isLoading: isUpdateTemplateLoading}] = useUpdateTemplateMutation()

	const {terms} = useSelector(state => state.termsStore)
	const {t} = useTranslation()

	const categories = useSelector(store => store.categoryStore.category)
	const subCategories = useSelector(store => store.subcategoryStore.subcategory)
	const langs = useSelector(store => store.langStore.langs)

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const editDateForModal = location?.state
	const edit = location?.state === null ? false : true
	const isLoading =
		isCreateTemplateLoading ||
		isUpdateTemplateLoading

	if(edit){
		langs.forEach(el => {
			initialState.name[`${el.langIndex}Title`] = editDateForModal.name[el.langIndex]
		})
	}else{
		initialState.name = {}
	}

	useEffect(() => {
		if (edit === true) {
			const re = {
				specialFunctionality: {
					...editDateForModal?.specialFunctionality,
					typeOfCard: editDateForModal?.specialFunctionality?.typeOfCard
				}
			}

			const tehResCommission = editDateForModal?.commission?.map((item, index) => {
				return {...item, tehId: (Date.now() + index)}
			})
			const com = {commission: tehResCommission}

			const tehResDiscounts = editDateForModal?.discounts?.map((item, index) => {
				const termTeh = terms?.find(i => i?.id === item?.title)
				return {
					...item,
					tehId: (Date.now() + index),
					title: termTeh?.title !== undefined ? termTeh?.title : item?.title
				}
			})
			const dis = {discounts: tehResDiscounts}
			setForm({...editDateForModal, ...re, ...com, ...dis, prices: editDateForModal.prices.map(el => {return {id: el.id, type: el.type}}) })
		}
	}, [edit, editDateForModal, editDateForModal?.commission, editDateForModal?.discounts, terms])

	const toggleShowTemplatesContentModal = (e) => dispatch(showChangeTemplateContentTemplates(true))

	const toggleShowPricesModal = (e) => dispatch(showModalSubChangeUnitPrice(true))

	const subCategorySearch = (id) => {
		const resultSub = subCategories.filter(sub => sub.parent === id)
		return resultSub
	}

	useEffect(() => {
		let arrAll = []
		let subArrAll = []
		let res = []
		let subRes = []
		for (let i = 0; i < categories?.length; i++) {
			res = subCategorySearch(categories[i]?.id)
			arrAll = [...arrAll, res]

			for (let i = 0; i < res.length; i++) {
				subRes = subCategorySearch(res[i].id)
				subArrAll = [...subArrAll, subRes]
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subCategories, categories])

	const formDateUpdateHandler = (opt) => {
		opt && setForm(prev => { return {...prev, ...opt} })
	}

	const handleSubmit = async (values) => {
		let error = false

		const titles = {}
		langs.forEach(el => {
			titles[el.langIndex] = form.name[`${el.langIndex}`]
		})

		// ===========> VALIDATION
		if(!form.prices?.length) error = t('c_pfip')
		form.prices.forEach(el => {
			if(!el.id || !el.type) error = t('c_pfip')
		})

		Object.entries(titles).forEach(el => {
			if(!el[1] || !el[1].length) error = t('c_pfitmn')
		})

		if(!form?.subcategory) error = 'Please select a category/subcategory'
		
		if(error){
			setErrorMessage(error)
			return
		}
		// ===========> VALIDATION
		const formDate = {
			name: titles,
			subcategory: form?.subcategory || [],
			prices: form?.prices || [],
			status: true,
			content: edit? form.content.map(el => el.content.id) : blockListFilter.map(el => el.id),
			blocksFilter: blockListFilter.map(el => el.id),
			isMiddle: true
		}
		try {
			if (edit && !form?.dublicate) {
				const sub = form?.subcategory?.slice(-1)[0]?.id
				const priceArr = form?.prices?.map(item => {
					return {id: item?.id, type: item?.type}
				})

				const optionsA = typeof form?.specialFunctionality?.typeOrderParameter?.unit === "object" ? form?.specialFunctionality?.typeOrderParameter?.unit?.id : form?.specialFunctionality?.typeOrderParameter?.unit
				const optionsV = typeof form?.specialFunctionality?.transportDimensions?.unit === "object" ? form?.specialFunctionality?.transportDimensions?.unit?.id : form?.specialFunctionality?.transportDimensions?.unit

				const prShift = form?.specialFunctionality?.priceShift?.map((pr, index) => {
					return {...pr, id: index + 1}
				})

				const resSpeshFunction = {
					...form?.specialFunctionality,
					typeOrderParameter: {...form?.specialFunctionality?.typeOrderParameter, unit: optionsA},
					transportDimensions: {...form?.specialFunctionality?.transportDimensions, unit: optionsV},
					priceShift: prShift,
				}

				const discountUpdate = form?.discounts?.map(dis => {
					delete dis?.id
					const res = {...dis, unit: dis?.unit?.id || dis?.unit}
					return res
				})

				delete formDate?.dublicate
				delete (formDate?.subcategory)
				delete (formDate?.prices)
				delete (formDate?.discounts)

				const {error} = await updateTemplate({
					id: editDateForModal?.id, ...formDate,
					subcategory: sub,
					prices: priceArr,
					specialFunctionality: resSpeshFunction,
					discounts: discountUpdate
				})
				error && setErrorMessage(error?.data?.data?.message || error?.data?.message)
				!error && clearFormHideModal()
			} else {
				if (form?.dublicate) {
					const sub = (form?.subcategory?.length >= 1 && form?.subcategory?.slice(-1)[0]?.id) || form?.subcategory
					const priceArr = form?.prices?.map(item => {
						return {id: item?.id, type: item?.type}
					})
					const optionsA = typeof form?.specialFunctionality?.typeOrderParameter?.unit === "object" ? form?.specialFunctionality?.typeOrderParameter?.unit?.id : form?.specialFunctionality?.typeOrderParameter?.unit
					const optionsV = typeof form?.specialFunctionality?.transportDimensions?.unit === "object" ? form?.specialFunctionality?.transportDimensions?.unit?.id : form?.specialFunctionality?.transportDimensions?.unit
					const prShift = form?.specialFunctionality?.priceShift?.map((pr, index) => {
						return {...pr, id: index + 1}
					})

					const resSpeshFunction = {
						...form?.specialFunctionality,
						typeOrderParameter: {...form?.specialFunctionality?.typeOrderParameter, unit: optionsA},
						transportDimensions: {...form?.specialFunctionality?.transportDimensions, unit: optionsV},
						priceShift: prShift,
					}

					const discountUpdate = form?.discounts?.map(dis => {
						delete dis?.id
						const res = {...dis, unit: dis?.unit?.id || dis?.unit}
						return res
					})

					delete formDate?.dublicate
					delete (formDate?.subcategory)
					delete (formDate?.prices)
					delete (formDate?.discounts)

					const {error} = await createTemplate({
						id: editDateForModal?.id, ...formDate,
						subcategory: sub,
						prices: priceArr,
						specialFunctionality: resSpeshFunction,
						discounts: discountUpdate
					})
					error && setErrorMessage(error?.data?.data?.message || error?.data?.message)
					!error && clearFormHideModal()
				} else {
					const {error} = await createTemplate({...formDate, subcategory: form?.subcategory})
					error && setErrorMessage(error?.data?.data?.message || error?.data?.message)
					!error && clearFormHideModal()
				}
			}
		} catch (e) {
			setErrorMessage(e)
		}
	}
	const clearFormHideModal = () => {
		navigate(`${APP_ROUTE_PAGES.TEMPLATES}`)
		setForm(initialState)
		dispatch(showChangeTermsModal(false))
		dispatch(resetDateChangeTerms())
		dispatch(showChangeUnitPriceModal(false))
		dispatch(resetDateChangeUnitPrice())
		dispatch(showChangeUnitAmountModal(false))
		dispatch(resetDateChangeUnitAmount())
		dispatch(showChangeUnitModalTemplates(false))
		dispatch(resetDateChangeUnitTemplates())
		dispatch(resetDateChangeUnitTemplatesList())
		dispatch(showChangeTemplateContentTemplates(false))
		dispatch(resetDateChangeUnitTemplatesListArr())
		dispatch(showChangeMark(false))
		dispatch(resetDateChangeMark())
		dispatch(showChangeTransportCategory(false))
		dispatch(resetDateChangeTransportCategory())
		dispatch(showChangeBodyType(false))
		dispatch(resetDateChangeBodyType())

		dispatch(resetMainBlockList())
		dispatch(resetDetailInfoBlockList())
		dispatch(resetAdditionalBlockList())
		dispatch(resetDateChangeUnitPrice())
		dispatch(resetDateChangeUnitPriceArr())
		dispatch(setDateChangeUnitGroup(""))
		dispatch(setDateChangeUnitGroupAmount(""))
		dispatch(setDateChangeUnitGroupVolume(""))
	}

	useEffect(() => {
		if (!edit) {
			setTimeout(() => {
				setForm(initialState)
				dispatch(showChangeTermsModal(false))
				dispatch(resetDateChangeTerms())
				dispatch(showChangeUnitPriceModal(false))
				dispatch(resetDateChangeUnitPrice())
				dispatch(showChangeUnitAmountModal(false))
				dispatch(resetDateChangeUnitAmount())
				dispatch(showChangeUnitModalTemplates(false))
				dispatch(resetDateChangeUnitTemplates())
				dispatch(resetDateChangeUnitTemplatesList())
				dispatch(showChangeTemplateContentTemplates(false))
				dispatch(resetDateChangeUnitTemplatesListArr())
				dispatch(showChangeMark(false))
				dispatch(resetDateChangeMark())
				dispatch(showChangeTransportCategory(false))
				dispatch(resetDateChangeTransportCategory())
				dispatch(showChangeBodyType(false))
				dispatch(resetDateChangeBodyType())

				dispatch(resetMainBlockList())
				dispatch(resetDetailInfoBlockList())
				dispatch(resetAdditionalBlockList())
				dispatch(resetDateChangeUnitPrice())
				dispatch(resetDateChangeUnitPriceArr())
				dispatch(setDateChangeUnitGroup(""))
				dispatch(setDateChangeUnitGroupAmount(""))
				dispatch(setDateChangeUnitGroupVolume(""))
			}, 300)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const MiddleCategoryCheckedTemplateComponent = useMemo(()=>{
		return(
			<CategoryCheckedTemplate
				formDateUpdateHandler={formDateUpdateHandler}
				categories={categories}
				templateType={templateType}
				value={edit? editDateForModal?.subcategory[editDateForModal?.subcategory.length -1]?.id : false}
				edit={edit}
			/>
		)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])
	
	return (
		<>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<Formik
				initialValues={initialState?.name}
				onSubmit={handleSubmit}
			>
				{
					({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty,
					}) => (
						<Form
							className='add-edit-terms'
							onSubmit={handleSubmit}
						>
							<NameBlockTemplate
								handleBlur={handleBlur}
								setForm={setForm}
								values={form.name}
								touched={touched}
								errors={errors}
								handleChange={handleChange}
								formDateUpdateHandler={formDateUpdateHandler}
							/>
							{
								MiddleCategoryCheckedTemplateComponent
							}
							<Suspense fallback={<Loader />}>
								<ChangePrices
									toggleShowTemplatesContentModal={toggleShowPricesModal}
									formDateUpdateHandler={formDateUpdateHandler}
									form={form}
									edit={edit}
									handleBlur={handleBlur}
									touched={touched}
									errors={errors}
								/>
							</Suspense >
							<Suspense fallback={<Loader />}>
								<ChangeTemplateContentForTemplate
									toggleShowTemplatesContentModal={toggleShowTemplatesContentModal}
									formDateUpdateHandler={formDateUpdateHandler}
									form={form}
									edit={edit}
									handleBlur={handleBlur}
									touched={touched}
									errors={errors}
									templateType={"middle"}
									setBlockListFilter={setBlockListFilter}
								/>
							</Suspense >
							
							<div className='template-content_buttons'>
								<Button
									type='submit'
									className='template-content_buttons-save'
									disabled={!isValid && dirty}
								>
									{
										isLoading ? <Loader /> : t('save')
									}
								</Button >
								<Button
									type='button'
									className='template-content_buttons-close'
									onClick={clearFormHideModal}
								>
									{t('cancel')}
								</Button >
							</div >
						</Form >
					)}
			</Formik>
		</>
	)
}

export default AddEditBuilderMiddleTemplate