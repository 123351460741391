import {
    createApi,
    fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {
    APP_ROUTE_PAGES, REFERRAL_LINKS_API
} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const referralLinksApi = createApi({
    reducerPath: "referralLinksApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API,
        headers: {
            "content-type": "application/json"
        },
        prepareHeaders: (
            headers,
            {getState}
        ) => {
            const {token} = getState().userStore;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        getReferralLinks: builder.mutation({
            query: (body) => (
                {
                    url: `${APP_ROUTE_PAGES.REFERRAL_LINKS}?type=${body}`,
                    method: "GET",
                })
        }),
        getReference: builder.query({
            query: (body) => (
                {
                    url: `${APP_ROUTE_PAGES.REFERRAL_LINK}?id=${body.id}&startDate=${body.startDate}&endDate=${body.endDate}`,
                    method: "GET",
                })
        }),
        createReferralLink: builder.mutation({
            query: (body) => (
                {
                    url: REFERRAL_LINKS_API.CREATE_REFERRAL_LINK,
                    method: "POST",
                    body
                })
        }),
        changeReferenceArchive: builder.mutation({
            query: (body) => (
                {
                    url: REFERRAL_LINKS_API.CHANGE_REFERENCE_ARCHIVE,
                    method: "POST",
                    body
                })
        }),
    })
});

export const {
    useGetReferralLinksMutation,
    useGetReferenceQuery,
    useCreateReferralLinkMutation,
    useChangeReferenceArchiveMutation
} = referralLinksApi
