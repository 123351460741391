export const searchItemsHelper = (
	arr,
	tag,
	searchTermValue
) => {
	const tehSearchTermValue = searchTermValue.trim().replace('/')
	const tehSearchTermValueUrl = searchTermValue.trim().replace(/(?<=.{10}).(?=.{10})/g, '*')
	// console.log(arr)
	// console.log(tag)
	// console.log(searchTermValue)
	const arrStrings = arr?.map(item => {
		if (tag === 'title') {
			return item?.title?.ua
		} else if (tag === 'name') {
			return item?.name?.ua
		} else if (tag === 'id') {
			return item?.id
		} else if (tag === 'term') {
			return item?.term?.ua
		} else if (tag === 'tag') {
			return item?.tag?.ua
		} else if (tag === 'body') {
			return item?.body?.ua
		} else if (tag === 'url') {
			return item?.url
		} else if (tag === 'pib') {
			return item?.pib
		} else if (tag === 'notice') {
			return item?.title
		}else if (tag === 'model') {
			return item?.name
		}else if (tag === 'transport') {
			return item?.value?.ua
		}else if (tag === 'advertising') {
			return item?.title
		}else if (tag === 'userGroup') {
			return item?.email
		}else if (tag === 'nameUnit') {
			return item?.name
		}else if (tag === 'nameModal') {
			return item?.name
		}else if (tag === 'nameModal') {
			return item?.name
		}else if (tag === 'nameSelect') {
			return item?.title?.ua
		}else if (tag === 'adv') {
			return item?.title
		} else return false
	})

	const filterItems = (arr, query) => arrStrings.filter(el => {
		if(typeof el === 'number') return el !== undefined && el.toString().indexOf(query) !== -1
		if(typeof el === 'string') return el !== undefined && el.toLowerCase().indexOf(query.toLowerCase()) !== -1
	})

	const arrStr = (tag === 'url') ? tehSearchTermValueUrl : tehSearchTermValue

	const filteredArrItems = filterItems(arrStrings, arrStr)

	const res = arr?.filter(i => filteredArrItems.find(item => {
		if (tag === 'title') {
			return i?.title?.ua === item
		} else if (tag === 'name') {
			return i?.name?.ua === item
		} else if (tag === 'id') {
			return i?.id === item
		} else if (tag === 'term') {
			return i?.term?.ua === item
		} else if (tag === 'tag') {
			return i?.tag?.ua === item
		} else if (tag === 'body') {
			return i?.body?.ua === item
		} else if (tag === 'url') {
			return i?.url === item
		} else if (tag === 'pib') {
			return i?.pib === item
		} else if (tag === 'notice') {
			return i?.title === item
		}else if (tag === 'model') {
			return  i?.name === item
		}else if (tag === 'transport') {
			return  i?.value?.ua === item
		}else if (tag === 'advertising') {
			return  i?.title === item
		}else if (tag === 'userGroup') {
			return  i?.email === item
		}else if (tag === 'nameUnit') {
			return  i?.name === item
		}else if (tag === 'nameModal') {
			return  i?.name === item
		}else if (tag === 'nameSelect') {
			return i?.title?.ua === item
		}else if (tag === 'adv') {
			return i?.title === item
		} else return false
	}))

	return res
}
