import {
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react"
import {LANG_API} from "../../../utils/constants"
const API = process.env.REACT_APP_API_URL;

export const langApi = createApi({
	reducerPath: "langApi",
	tagTypes: ['Language'],
	baseQuery: fetchBaseQuery({
		baseUrl: API,
		headers: {
			"content-type": "application/json"
		},
		prepareHeaders: (
			headers,
			{getState}
		) => {
			const {token} = getState().userStore;
			if (token) {
				headers.set("Authorization", `Bearer ${ token }`)
			}
			return headers
		}
	}),
	endpoints: (builder) => ({
		
        getLangs: builder.query( {
            query: (types) => (
                {
                    url: LANG_API.GET_LANGS,
                    method: "GET",
                }
            ),
			providesTags: (result = false) =>
				result.data
				? [
					...result.data.map(({ id }) => ({ type: 'Language', id })),
					{ type: 'Language', id: 'LIST' },
					]
				: [{ type: 'Language', id: 'LIST' }],
        }),

		createLanguage: builder.mutation( {
			query: ( body ) => (
				{
					url: `${ LANG_API.CREATE_LANG }`,
					method: "POST",
					body
				}
			),
			invalidatesTags: [{ type: 'Language', id: 'LIST' }],
		} ),

		updateLanguage: builder.mutation( {
			query: ( body ) => (
				{
					url: `${ LANG_API.UPDATE_LANG }`,
					method: "POST",
					body
				}
			),
			invalidatesTags: [{ type: 'Language', id: 'LIST' }],
		} ),

		deleteLanguage: builder.mutation( {
			query: ( body ) => (
				{
					url: `${ LANG_API.DELETE_LANG }`,
					method: "POST",
					body
				}
			),
			invalidatesTags: [{ type: 'Language', id: 'LIST' }],
		} ),
	})
});

export const {
	useGetLangsQuery,
	useCreateLanguageMutation,
	useUpdateLanguageMutation,
	useDeleteLanguageMutation
} = langApi;
