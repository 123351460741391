import React, {
	useEffect,
	useState
} from 'react'
import LoaderContent from "../LoaderContent"
import PaginationCustom from "../PaginationCustom"
import SearchItemsInput from "../SearchItemsInput"
import { useDispatch } from 'react-redux'
import ModerationPaymentTabl from '../../pages/ModerationPayment/ModerationPaymentTabl'
import {
	useGetModerationPaymentsMutation,
	useGetModerationPaymentsStatusMutation
} from '../../store/apis/moderationPaymentApi'
import SelectShowRecords from "../SelectShowRecords";
import FilteredIcon from "../../../utils/icons/filter-icon.svg";
import FilterModerationPayment from "../FilterModerationPayment";
import { useTranslation } from 'react-i18next'

const ControlPanelsForModerationPayment = ( {
	bigArrElements,
	fromPage,
	toggleModalSearch,
	showModalSearch,
	showSearchBeforeDel,
	setShowSearchBeforeDel,
	setErrorMessage,
	isTransactionsPage,
												amount
} ) => {
	const [ countShowTerms, setCountShowTerms ] = useState( 20 )
	const [ currentPage, setCurrentPage ] = useState( 0 )
	const [ countShowPage, setCountShowPage ] = useState( 0 )
	const [ counterAllItems, setCounterAllItems ] = useState( 0 )
	const [ sortColumnTable, setSortColumnTable ] = useState()
	const [ changeTypeStatus, setChangeTypeStatus ] = useState()
	const [ changeOrderSort, setChangeOrderSort ] = useState( 1 )
	const [ searchTermValue, setSearchTermValue ] = useState( '' )
	const [ searchChangeTermValue, setSearchChangeTermValue ] = useState( '' )
	const [ getModerationPayments, { isLoading: isModerationPaymentsLoading } ] = useGetModerationPaymentsMutation()
	const [ getModerationPaymentsStatus, { isLoading: isModerationPaymentsStatusLoading } ] = useGetModerationPaymentsStatusMutation()
	const allButtonsType = document.querySelectorAll( '.terms-wrapper_search-types_button' )
	const dispatch = useDispatch()
	const [showFilterBlock, setShowFilterBlock] = useState(false)
	const {t} = useTranslation()

	const [ changeMinPrice, setChangeMinPrice ] = useState( "" )
	const [ changeMaxPrice, setChangeMaxPrice ] = useState( "" )
	const [changeId, setChangeId] = useState("")
	const [changeType, setChangeType] = useState("")
	const [isFilter, setIsFilter] = useState(false);
	const [statements, setStatements] = useState(0);
	const [selectValue, setSelectValue] = useState('Всі');

	const prices = {
		min: changeMinPrice && `&prices[min]=${changeMinPrice}`,
		max: changeMaxPrice && `&prices[max]=${changeMaxPrice}`
	};

	const limit = `&limit=${ countShowTerms }`
	const page = `&page=${ Number( currentPage ) }`
	const tehFormData = { limit: limit, page: page }

	const dealSide = statements !== 0 && `&dealSide=${statements}`

	const methods = `&methods=["subscription", "deposit", "package", "deal"]`

	const member = {
		id: changeId && `&member[id]=${changeId}`,
		type: changeType && `&member[type]=${changeType}`,
	}

	const handleChange = () => {
		setIsFilter(!isFilter)
		// handleChangeSelect(event)
	}

	const handleChangeSelect = (event) => {
		const value = event.target.value;
		setSelectValue(value);

		switch (value) {
			case "Витрати":
				setStatements(-1);
				break;
			case "Доходи":
				setStatements(1);
				break;
			case "Всі":
				setStatements(0);
				break;
		}
	}

	const resetFilters = () => {
		setChangeMinPrice("");
		setChangeMaxPrice("");
		setChangeId("");
		setChangeType("")
		setStatements(0);
		setSelectValue("Всі");

		setIsFilter(!isFilter);
	}

	useEffect( () => {
		const getAllUsersList = async () => {
			const search = searchChangeTermValue && { search: `&search=${ searchChangeTermValue }` }
			const status = (changeTypeStatus || changeTypeStatus === 0) && { status: `&status=${ Number( changeTypeStatus ) }` }

			const sortBy = sortColumnTable && {
				field: `&sortBy[field]=${sortColumnTable}`,
				way: `&sortBy[way]=${changeOrderSort}`
			};

			try {
				const { error } = await getModerationPayments({ ...tehFormData, sortBy, prices, dealSide, member })
				setErrorMessage( error?.data?.message === undefined ? '' : error?.data?.message )
				// await getModerationPaymentsStatus({ order_id: order_id, payment_id: payment_id });

			} catch ( e ) {
				console.log( e )
			}
		}
		getAllUsersList().then(r => r)
		// setIsFilter(false)
		setCounterAllItems( amount )
	}, [ countShowTerms, sortColumnTable, changeOrderSort, searchChangeTermValue, currentPage, isFilter ] )


	useEffect(() => {
		setCounterAllItems( amount )
	}, [amount])

	const valueSearchInputHandler = ( value ) => {
		setSearchTermValue( value )
	}

	const searchHandler = () => {
		bigArrElements = bigArrElements.filter(el => el.id === Number(searchTermValue))
		setSearchChangeTermValue( searchTermValue )
	}

	const sortedForColumn = ( sort ) => {
		if ( sort === sortColumnTable ) {
			if ( changeOrderSort === 1 ) setChangeOrderSort( -1 )
			else setChangeOrderSort( 1 )
		} else {
			setChangeOrderSort( 1 )
		}
		setSortColumnTable( sort )
	}

	const clearSearchHandler = () => {
		toggleModalSearch( false )
		setSearchTermValue( '' )
		setSearchChangeTermValue( '' )
		setChangeTypeStatus('')
	}

	useEffect( () => {
		if ( searchTermValue !== '' && showModalSearch ) {
			showModalSearch && searchHandler()
			setShowSearchBeforeDel( false )
		}
		if ( searchTermValue === '' && !showModalSearch ) {
			clearSearchHandler()
		}
	}, [ showModalSearch, showSearchBeforeDel ] )

	const toggleFilterBlock = () => setShowFilterBlock(!showFilterBlock)

	return (
		<div className='terms-wrapper'>
			<div className='terms-wrapper_search'>
				<div className='d-flex gap-3'>
					{
						<button
							className='noticeItem-filtered_button'
							onClick={ toggleFilterBlock }
						>
							{t('filters')}
							<img
								src={FilteredIcon}
								alt='filter icon'
							/>
						</button >
					}
					{/*<SearchItemsInput
						toggleModalSearch={ toggleModalSearch }
						searchTermValue={ searchTermValue }
						valueSearchInputHandler={ valueSearchInputHandler }
						searchHandler={ searchHandler }
						clearSearchHandler={ clearSearchHandler }
					/>*/}
					</div >
				<SelectShowRecords setCountShow={setCountShowTerms} />
			</div >

			{ showFilterBlock &&
				<FilterModerationPayment
					getModerationPayments={getModerationPayments}
					changeMinPrice={changeMinPrice}
					setChangeMinPrice={setChangeMinPrice}
					changeMaxPrice={changeMaxPrice}
					setChangeMaxPrice={setChangeMaxPrice}
					handleChange={handleChange}
					resetFilters={resetFilters}
					handleChangeSelect={handleChangeSelect}
					statements={statements}
					selectValue={selectValue}
					changeId={changeId}
					changeType={changeType}
					setChangeId={setChangeId}
					setChangeType={setChangeType}
				/>
			}
			{
				counterAllItems !== 0
				&&
				<PaginationCustom
					itemsCount={ countShowTerms }
					currentPage={ currentPage }
					setCurrentPage={ setCurrentPage }
					counterAllItems={ counterAllItems }
				/>
			}
			{
				isModerationPaymentsLoading
					?
					<LoaderContent />
					:
					<ModerationPaymentTabl
						counterAllItems={ counterAllItems }
						sortedForColumn={ sortedForColumn }
						countShowTerms={ countShowTerms }
						showSmallTermArr={ bigArrElements }
						fromPage={ fromPage }
						isTransactionsPage={isTransactionsPage}
					/>
			}

		</div >
	)
}

export default ControlPanelsForModerationPayment
