import { createSlice } from "@reduxjs/toolkit"
import { categoryApi } from "../apis/categoryApi"

const initialState = {
	category: [],
}

const categorySlice = createSlice( {
	name: "category",
	initialState,
	reducers: {
		getCategoryList: ( state, action ) => {
			state.category = action.payload
		},
		createNewCategoryItem: ( state, action ) => {
			state.category = [ action.payload, ...state.category ]
		},
		editCategoryItem: ( state, action ) => {
			const index = state.category.findIndex(
				( term ) => {
					return term.id === action.payload.id
				}
			)
			state.category[index] = action.payload
		},
		deleteCategoryItem: ( state, action ) => {
			const termId = action.payload
			const termID = state.category.filter( term => term.id !== termId )
			state.category = termID
		},
	},
	extraReducers: ( builder ) => {
		builder
			.addMatcher( categoryApi.endpoints.getCategory.matchFulfilled, (
					state,
					action
				) => {
					state.category = action.payload.data
				}
			)
	}
} )

const {
	actions,
	reducer
} = categorySlice
export const {
	getCategoryList,
	createNewCategoryItem,
	deleteCategoryItem,
	editCategoryItem,
} = actions
export default reducer
