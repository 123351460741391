import { Table } from "react-bootstrap"
import verticalDots from '../../../utils/icons/vertical-dots.svg'
import ArrowSorting from '../../../utils/icons/arrow-sorting.svg'
import ModerationPaymentTablItem from './ModerationPaymentTablItem'
import { ARE_COMMON } from "../../../utils/textsContent"
import { useTranslation } from "react-i18next"

const ModerationPaymentTabl = ( {
	sortedForColumn,
	countShowTerms,
	showSmallTermArr,
	counterAllItems,
	fromPage,
	isTransactionsPage
} ) => {

	const {t} = useTranslation()

	return (
		<>
			{/*<div className='terms-wrapper_itemsInfo'>
					<span className='terms-wrapper_itemsInfo-content'>
						{ARE_COMMON?.SHOWN} { countShowTerms } {ARE_COMMON?.POINTS_FROM} { counterAllItems }
					</span >
			</div >*/}
			<Table
				responsive
				className='users-wrapper_tabl'
				striped={ true }
			>
				<thead className='users-wrapper_tabl-head'>
				<tr className='users-wrapper_tabl-trow'>
					<th
						className='users-wrapper_tabl-trow_item col-1'
						onClick={() => sortedForColumn('id')}
					>
						{/*<input
							type='checkbox'
							onKeyDown={ e => {
								e.key === 'Enter' && e.preventDefault()
							} }
							name='checkAll'
							className='terms-wrapper_tabl-body_item-wrapper_inputAll'
						/>*/}
						<span >
							ID
						</span >
					</th >
					<th
						className={ `users-wrapper_tabl-trow_item col-1'` }
						onClick={ () => sortedForColumn( 'order_id' ) }
					>
						<span >
							Order ID
						</span >
					</th >
					<th
						className='users-wrapper_tabl-trow_item col-1'
						onClick={ () => sortedForColumn( 'amount' ) }
					>
						<span >
							{t('f_transaction_amount')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1'
						onClick={ () => sortedForColumn( 'amount' ) }
					>
						<span >
							{t('f_table_column_send')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item col-1'
						onClick={ () => sortedForColumn( 'transferred_amount' ) }
					>
						<span >
							{t('f_table_column_received')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1'
						onClick={ () => sortedForColumn( 'commission' ) }
					>
						<span >
							{t('f_table_column_commission')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1'
						onClick={ () => sortedForColumn( 'status' ) }
					>
						<span >
							{t('status')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1'
						onClick={ () => sortedForColumn( 'deal_date' ) }
					>
						<span >
							{t('date_created')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th
						className='users-wrapper_tabl-trow_item pointer-event col-1'
						onClick={ () => sortedForColumn( 'deal_date' ) }
					>
						<span >
							{t('f_table_column_finished')}
						</span >
						<img
							src={ ArrowSorting }
							alt='Arrow Sorting'
						/>
					</th >
					<th className='users-wrapper_tabl-trow_item col-1'>
						<img
							src={ verticalDots }
							alt='Dots'
						/>
					</th >
				</tr >
				</thead >
				<tbody
					className='users-wrapper_tabl-body'
				>
				{
					showSmallTermArr && showSmallTermArr?.map( item => <ModerationPaymentTablItem
						key={ item.id }
						item={ item }
						fromPage={ fromPage }
						isTransactionsPage={ isTransactionsPage }
					/> )
				}
				</tbody >
			</Table >
		</>
	)
}

export default ModerationPaymentTabl
