import React, {
	useEffect,
	useRef,
	useState
} from 'react'
import s from './modalNewBook.module.scss';
import {useNavigate} from "react-router-dom";
import {useGetGroupsMutation, useUpdateGroupMutation} from "../../../store/apis/addressBookApi";
import { ARE_COMMON } from "../../../../utils/textsContent";
import { useTranslation } from 'react-i18next';


export const ModalNewBook = ({close, name, data}) => {
	const [ valueInpNameGroup, setValueInpNameGroup ] = useState('')
	const navigate = useNavigate();
	const modal = useRef()
	const wrapper = useRef()
	const input = useRef()
	const [updateGroup] = useUpdateGroupMutation();
	const [getGroups] = useGetGroupsMutation()
	const {t} = useTranslation()

	useEffect(()=>{
		if ( name ) input.current.value = name
	},[])

	function closeModal(){
		wrapper?.current?.classList?.add( s.close_wrapper )
		modal?.current?.classList?.add( s.close_modal )
		setTimeout( () => {
			close( false )
		}, 200 )
	}

	function createBook(){
		const value = input.current.value
		navigate( `/address-book/new?name=${ value }` )
	}

	async function updateBook(){
		const new_name = input.current.value
		const body = { id: data.id, name: new_name }
		const response = await updateGroup( body )
		if ( response.data.status === 200 ) {
			close( false )
			getGroups( {} )
		}
	}

	return (
		<>
			<div
				className={ s.modal_wrapper }
				onClick={ closeModal }
				ref={ wrapper }
			></div >
			<div
				className={ s.modal }
				ref={ modal }
			>
				<header >
					<h3 >{ `${name? t('_a_editAddressBook') : t('_a_createAddressBook')}` }</h3 >
					<div
						className={ s.close_btn }
						onClick={ closeModal }
					>
						&#10006;
					</div >
				</header >
				<div className={ s.content }>
					<span className={ s.title }>
						{t('_a_nameAddressBook')}
					</span >
					<input
						className='template-content_nameBlock-inp  is-touch form-control'
						type='text'
						ref={ input }
						onChange={e => setValueInpNameGroup(e.target?.value)}
					/>
					<div className={ s.row }>
						<button
							className='template-content_buttons-save btn btn-primary'
							onClick={ name ? updateBook : createBook }
							disabled={valueInpNameGroup === ''}
						>
							{t('create')}
						</button >
						<button
							className='template-content_buttons-close btn btn-primary'
							onClick={ () => close( false ) }
						>
							{t('cancel')}
						</button >
					</div >
				</div >
			</div >
		</>
	)
}