import React from 'react';
import s from './rbacPermissionsGroupList.module.scss';

export const RBACPermissionsGroupList = ({ids, permissions})=>{
   
    const list_view = ids.map((el, index) => {
        const title = (permissions.find(per => per.id === el))?.title
        
        return <div key={index} className={s.item}><span>{title}</span></div>
    })

    return(
        <div className={s.list_wrapper}>
            {list_view}
        </div>
    )
}