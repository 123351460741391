import React from 'react';
import { Form, Table } from 'react-bootstrap';
import s from './customTable.module.scss'
import { ReactComponent as InfoIcon } from '../../../../utils/icons/info-icon.svg'
import { useTranslation } from 'react-i18next';

export const CustomTable = ({columns, rows, totalCount, changeItem, selectedItems}) =>{

    const {t, i18n} = useTranslation()

    const table_columns = columns.map((el, index) => {
        return(
            <th key={index}  className={`terms-wrapper_tabl-trow_item ${!index ? 'col-1' : el==="Info"? "col-1" : ''}`}>{el}</th >
        )
    })
    const table_rows = rows.map((el, index) => {
        return(
            <tr key={index} style={{position: "relative"}}>
                {
                    el.map((el, index) => {
                        if(el.type === "checkbox") return(
                            <td key={index} className={`users-wrapper_tabl-body_item col-4`}>
                                <div className="users-wrapper_tabl-body_item-wrapper">
                                    <Form.Check type='checkbox' checked={selectedItems.includes(el.value)} onChange={(e) => changeItem(el.value, e.target.checked)}/>
                                </div>
                            </td >
                        )
                        else if(el.type === "radio") return(
                            <td key={index} className={`users-wrapper_tabl-body_item col-4`}>
                                <div className="users-wrapper_tabl-body_item-wrapper">
                                    <Form.Check type='radio' checked={selectedItems.includes(el.value)} onChange={(e) => changeItem(el.value, e.target.checked)}/>
                                </div>
                            </td >
                        )
                        else if(el.type === "status") return(
                            <td key={index} className={`users-wrapper_tabl-body_item col-4`}>
                                <div className="users-wrapper_tabl-body_item-wrapper">
                                    <span className={`${s.status} ${el.value ? s.status_active : s.status_not_active}`}>{el.value ? "Активно" : "Не активно"}</span>
                                </div>
                            </td >
                        )
                        else if(el.type === 'icons') return(
                            <td key={index} className={`users-wrapper_tabl-body_item col-1 ${s.icons_td}`}>
                                <div className={`users-wrapper_tabl-body_item-wrapper ${s.icons_row}`}>
                                    <div className={s.icons_wrapper}>
                                        {
                                            el.value.map((icon, index) => (
                                                <span style={{padding: 0}} key={index}>{icon}</span>
                                            ))
                                        }
                                    </div>
                                </div>
                            </td >
                        )
                        else if(el.type === 'modal') return(
                            <td key={index} className={`users-wrapper_tabl-body_item col-1`} style={{position: "static"}}>
                                <div className={`users-wrapper_tabl-body_item-wrapper ${s.modal_td}`}>
                                    <div className={s.modal_td_wrapper} onMo>
                                        <InfoIcon/>
                                        <div className={s.modal}>
                                            {el.value}
                                        </div>
                                    </div>
                                </div>
                            </td >
                        )
                        else return(
                            <td key={index} className={`users-wrapper_tabl-body_item col-4`}>
                                <div className="users-wrapper_tabl-body_item-wrapper">
                                    <span className={s.item}>{el.value}</span>
                                </div>
                            </td >
                        )
                    })
                }
            </tr>
        )
    })

    return(
        <>
			<div className='terms-wrapper_itemsInfo'>
					<span className='terms-wrapper_itemsInfo-content'>
						{t('showing')} { rows.length } {t('items_from')} { totalCount }
					</span >
			</div >
			<Table
				responsive
				className='terms-wrapper_tabl'
				striped={ true }
			>
				<thead className='terms-wrapper_tabl-head'>
                    <tr className='terms-wrapper_tabl-trow'>
                        {table_columns}
                    </tr >
				</thead >
				<tbody className='terms-wrapper_tabl-body'>
                    {table_rows}
				</tbody >
			</Table >
		</>
    )
}