import React, { useEffect, useState } from 'react'
import {
	useChangePositionSubcategoryElementMutation,
	useGetSubcategoryMutation
} from "../../../store/apis/subcategoryApi"
import AlertModal from "../../../components/AlertModal"
import { Table } from "react-bootstrap"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import AlertSuccessfullyModal from "../../../components/AlertSuccessfullyModal"
import { useTranslation } from 'react-i18next'

const ShowThirdLevelSubcategory = ({id, setShowSubcategoryThirdLevel}) => {
	const [changeSortSubcategory, setChangeSortSubcategory] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	const [successffullyMessage, setSuccessffullyMessage] = useState('')
	const idChangeElement = null
	const [getSubcategory] = useGetSubcategoryMutation()
	const [changePositionSubcategoryElement] = useChangePositionSubcategoryElementMutation()
	const {i18n} = useTranslation()

	useEffect(() => {
		const getSubcategoriesList = async () => {
			const parentId = `&parent=${id}`
			const {data, error} = await getSubcategory({parent: parentId, orderBy: '&orderBy=desc', sortingBy: '?sortingBy=position'})
			data?.data?.length === 0 ? setErrorMessage('Немає Підкатегорій') : setChangeSortSubcategory(data?.data)
			data?.data?.length === 0 && setTimeout(() => setShowSubcategoryThirdLevel(false), 500)
			error && setErrorMessage(error?.data?.message)
		}
		getSubcategoriesList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list)
		const [removed] = result.splice(startIndex, 1)
		result.splice(endIndex, 0, removed)
		return result
	}

	const onDragEnd = async (result) => {
		if (!result.destination) {
			return
		}
		const modifiersGroupsSortedList = reorder(
			changeSortSubcategory,
			result.source.index,
			result.destination.index
		)

		setChangeSortSubcategory(modifiersGroupsSortedList)
		const {data, error} = await changePositionSubcategoryElement({
			id: Number(result?.draggableId),
			position: (result?.destination?.index + 1)
		})
		data?.status === 200 && setSuccessffullyMessage(data?.message)
		setErrorMessage(error?.data?.message)
	}

	return (
		<>
	{
		errorMessage
		&&
		<AlertModal
			content={errorMessage}
			setErrorMessage={setErrorMessage}
		/>
	}
			{
				successffullyMessage
				&&
				<AlertSuccessfullyModal
					content={successffullyMessage}
					setErrorMessage={setSuccessffullyMessage}
					position={false}
				/>
			}
			<Table
				responsive
				className='terms-wrapper_tabl'
				striped={true}
			>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable'>
					{(provided) => (
						<ul
							className='template-content_valueBlock-list list-items animated-sortingCategoriesPage'
							style={{maxHeight: 'max-content'}}
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{
								changeSortSubcategory && changeSortSubcategory?.map((item, index) => {
									if(!item.status) return ""
									else return(
									<Draggable
										key={item?.id}
										draggableId={item?.id.toString()}
										index={index}
									>
										{(provided) => (
											<li
												className='template-content_valueBlock-list_li'
												key={item?.id}
												id={item?.id}
												ref={provided.innerRef} {...provided.draggableProps}
												{...provided.dragHandleProps}
											>
											<>
												<span className='me-2'>{index + 1}. </span >
												<div className='template-content_valueBlock-list_li-text' style={{backgroundColor: `${idChangeElement === item?.id ? 'rgba(102, 92, 209, 1)' : '#fff'}`}}>
													<div className='template-content_valueBlock-list_li-wrapper'>
														<span style={{color: `${idChangeElement === item?.id ? '#fff' : '#111'}`}}>
															{item?.title?.[i18n.language]}
														</span >
														<div className='template-content_valueBlock-list_li-buttons'>
															</div >
														</div >
													</div >
												</>
											</li >
										)}
									</Draggable >
								)})
							}
							{provided.placeholder}
						</ul >
					)}
				</Droppable >
			</DragDropContext >
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable'>
					{(provided) => (
						<ul
							className='template-content_valueBlock-list list-items animated-sortingCategoriesPage'
							style={{maxHeight: 'max-content'}}
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{
								changeSortSubcategory && changeSortSubcategory?.map((item, index) => {
									if(item.status) return ""
									else return(
									<Draggable
										key={item?.id}
										draggableId={item?.id.toString()}
										index={index}
										isDragDisabled={true}
									>
										{(provided) => (
											<li
												className='template-content_valueBlock-list_li'
												key={item?.id}
												id={item?.id}
												ref={provided.innerRef} {...provided.draggableProps}
												{...provided.dragHandleProps}
											>
											<>
												<div className='template-content_valueBlock-list_li-text' style={{backgroundColor: `${idChangeElement === item?.id ? 'rgba(102, 92, 209, 1)' : '#fff'}`}}>
													<div className='template-content_valueBlock-list_li-wrapper' style={{opacity: 0.3, cursor: 'not-allowed', userSelect: 'none'}}>
														<span style={{color: `${idChangeElement === item?.id ? '#fff' : '#111'}`}}>
															{item?.title?.[i18n.language]}
														</span >
														<div className='template-content_valueBlock-list_li-buttons'>
															</div >
														</div >
													</div >
												</>
											</li >
										)}
									</Draggable >
								)})
							}
							{provided.placeholder}
						</ul >
					)}
				</Droppable >
			</DragDropContext >
		</Table >
	</>
	)
}

export default ShowThirdLevelSubcategory;