import { useAdvertiserGetCompaniesQuery } from '../../../store/apis/advertiserApi';
import s from './advertiserProfileCompanies.module.scss';
import LoaderContent from '../../../components/LoaderContent';
import { useTranslation } from 'react-i18next';
import { AdvertiserCompanyItem } from '../../AdvertiserCompany/AdvertiserCompanyItem/AdvertiserCompanyItem';

export const AdvertiserProfileCompanies = ({ userData }) => {

    // ### State
    const {data: companies, isFetching: isCompaniesLoading} = useAdvertiserGetCompaniesQuery({userId: userData.id})
    const { t } = useTranslation()
    
    // ### Views

    const companis_view = companies?.companies?.map((el, index) => <AdvertiserCompanyItem key={index} company={el} />)

    return(
        <>
        {
            isCompaniesLoading ? <LoaderContent /> : 
            <table className={s.table}>
                <thead>
                    <tr>
                        <td className={s.company_td}>{t('a_acy')}</td>
                        <td>{t('date_created')}</td>
                        <td>{t('views')}</td>
                        <td>{t('transitions')}</td>
                        <td>{t('status')}</td>
                        <td>{t('price')}</td>
                        <td>{t('menu')}</td>
                    </tr>
                </thead>
                <tbody>
                    {companis_view}
                </tbody>
            </table>
        }
        </>
    )
}