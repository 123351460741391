import React, {
	useCallback,
	useEffect, useRef,
	useState,
} from 'react'
import { useSelector } from "react-redux"
import DeleteModal from "../../components/DeleteModal"
import AddEditBuilderTemplate from "./CreateTemplate/AddEditBuilderTemplate"
import {
	useNavigate,
} from "react-router-dom"
import AlertModal from "../../components/AlertModal"
import { APP_ROUTE_PAGES } from "../../../utils/constants"
import { PAGES_NAME } from "../../../utils/textsContent"
import { useGetTemplateV2InfoMutation, useGetTemplateV2Mutation } from "../../store/apis/templateApi"
import ControlPanelsForTemplate from "../../components/environmentTabl/ControlPanelsForTemplate"
import { useTranslation } from 'react-i18next'

const TemplatesPage = () => {
	const {template} = useSelector(state => state.templateStore)
	const parameters = JSON.parse(localStorage.getItem('templateParametersPage'))
	const addEditModalShow = false;
	const [deleteModalShow, setDeleteModalShow] = useState(false)
	const [deleteTemplateItem, setDeleteTemplateItem] = useState({})
	const [errorMessage, setErrorMessage] = useState('')

	const [searchTemplateValue, setSearchTemplateValue] = useState(parameters?.query?.split('=').pop() || '')
	const [sortedColumnValue, setSortedColumnValue] = useState(parameters?.sortBy?.split('=').pop() || 'date')
	const [currentPage, setCurrentPage] = useState(Number(parameters?.page?.split('=').pop()) || Number(1))
	const [countShowTemplates, setCountShowTemplates] = useState(Number(parameters?.limit?.split('=').pop()) || 20)
	const [changeOrderSort, setChangeOrderSort] = useState(Number(parameters?.order?.split('=').pop()) || -1)
	const [countShowPage, setCountShowPage] = useState(1)
	const [counterAllItems, setCounterAllItems] = useState(1)

	const [showSearchBeforeDel, setShowSearchBeforeDel] = useState(false)
	const [getTemplateV2, {isLoading: isGetTemplateV2Loading}] = useGetTemplateV2Mutation()
	const [getTemplateV2Info, {isLoading: isGetTemplateV2InfoLoading}] = useGetTemplateV2InfoMutation()

	const isLoading = isGetTemplateV2Loading || isGetTemplateV2InfoLoading
	const pastNumberCount = useRef(countShowTemplates)
	const navigate = useNavigate()
	const {t} = useTranslation()

	const getTemplates = useCallback(async (search, start) => {
		const limit = `&limit=${countShowTemplates}`
		const page = start === true ? `&page=${Number(1)}` : `&page=${Number(currentPage)}`
		const querySearch = search ? '' : searchTemplateValue ? {query: `&query=${searchTemplateValue.trim()}`} : ''
		const sortByItem = sortedColumnValue ? {sortBy: `&sortBy=${sortedColumnValue}`} : ''

		const tehFormData = {
			limit: limit,
			page: page,
			order: `&order=${changeOrderSort === -1 ? -1 : 1}`
		}
		try {
			if (parameters === null || sortedColumnValue === '') {
				const {data, error} = await getTemplateV2({...tehFormData, ...querySearch, ...sortByItem})
				!error && setCountShowPage(Number(data?.totalPages))
				!error && setCounterAllItems(Number(data?.totalTemplates))
				!error && localStorage.setItem('templateParametersPage', JSON?.stringify({...tehFormData, ...querySearch, ...sortByItem}))
				error && setErrorMessage(error?.data?.message)
			} else {
				if (
					searchTemplateValue !== '' ||
					sortedColumnValue !== '' ||
					countShowTemplates !== 20 ||
					currentPage !== 1
				) {
					const {data, error} = await getTemplateV2({...tehFormData, ...querySearch, ...sortByItem})
					!error && setCountShowPage(Number(data?.totalPages))
					!error && setCounterAllItems(Number(data?.totalTemplates))
					!error && setCurrentPage(Number(data?.page))
					!error && localStorage.setItem('templateParametersPage', JSON?.stringify({...tehFormData, ...querySearch, ...sortByItem}))
					!error && (pastNumberCount.current = countShowTemplates)
					error && setErrorMessage(error?.data?.message)
				}
			}

		} catch (e) {
			setErrorMessage(e)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTemplateValue, sortedColumnValue, changeOrderSort, countShowTemplates, currentPage, getTemplateV2])

	useEffect(() => {
		const search = false
		getTemplates(search)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortedColumnValue, changeOrderSort, countShowTemplates, currentPage])

	const searchHandler = async () => {
		const search = false
		const start = true
		await getTemplates(search, start)
	}
	const toggleModalSearch = () => {}

	const sortedForColumnHandler = (sort) => {
		if (sort === sortedColumnValue) {
			if (changeOrderSort === 1) setChangeOrderSort(-1)
			else setChangeOrderSort(1)
		} else {
			setChangeOrderSort(1)
		}
		setSortedColumnValue(sort)
	}

	const valueSearchInputHandler = (value) => {
		setSearchTemplateValue(value)
	}

	const showModalHandler = (tog) => {
		navigate(`${APP_ROUTE_PAGES.TEMPLATES_CREATE}`)
	}


	const toggleDeleteModal = (value) => {
		console.log("#### VALUE", value);
		setDeleteTemplateItem(value)
		setDeleteModalShow(!deleteModalShow)
	}

	const openEditModalHandler = async (value, dublicate, migrate) => {
		const sub = `&id=${value?.id}`
		const {data, error} = await getTemplateV2Info(sub)
		!error && navigate(`${APP_ROUTE_PAGES.TEMPLATES_EDIT}/${value?.id}`, {
			state: {
				...data?.refactoredTemplate,
				dublicate: dublicate,
				migrate: migrate
			}
		})
		setErrorMessage(error?.data?.message)
		delete value?.content
	}

	const returnErrorForDel = (mes) => {
		setErrorMessage(mes)
	}

	const clearSearchHandler = async () => {
		setSearchTemplateValue('')
		const search = true
		await getTemplates(search)
	}


	return (
		<div className='terms'>
			{
				errorMessage
				&&
				<AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<DeleteModal
				show={deleteModalShow}
				onHide={toggleDeleteModal}
				value={deleteTemplateItem}
				fromPage={'template'}
				returnErrorForDel={returnErrorForDel}
				setShowSearchBeforeDel={setShowSearchBeforeDel}
			/>
			{
				addEditModalShow
					?
					<AddEditBuilderTemplate />
					:
					<>
						<h1 className='terms-title'>
							{t('s_tem')}
						</h1 >
						<ControlPanelsForTemplate
							bigArrElements={template}
							isLoading={isLoading}
							showModalHandler={showModalHandler}
							openEditModalHandler={openEditModalHandler}
							toggleDeleteModal={toggleDeleteModal}
							fromPage={'template'}
							searchTemplateValue={searchTemplateValue}
							showSearchBeforeDel={showSearchBeforeDel}
							setShowSearchBeforeDel={setShowSearchBeforeDel}
							valueSearchInputHandler={valueSearchInputHandler}
							clearSearchHandler={clearSearchHandler}
							sortedForColumn={sortedForColumnHandler}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							setCountShowTemplates={setCountShowTemplates}
							countShowPage={countShowPage}
							counterAllItems={counterAllItems}
							countShowTemplates={countShowTemplates}
							searchHandler={searchHandler}
							toggleModalSearch={toggleModalSearch}
						/>
					</>
			}
		</div >
	)
}


export default TemplatesPage
